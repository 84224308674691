import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontFamilies, fontWeights, fontSizes, lineHeights, palette,
}: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: 32,
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      padding: 40,
    },
  },
  container: {
    marginBottom: 12,
    [breakpoints.up('sm')]: {
      marginBottom: 20,
    },
  },
  sectionTitle: {
    margin: '0 0 12px',
    padding: '0 5px',
    color: palette.text.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      margin: '0 0 8px',
      padding: 0,
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      textAlign: 'left',
    },
  },
  sectionTitlePartial: {
    display: 'block',
    [breakpoints.up('lg')]: {
      display: 'inline',
    },
  },
  description: {
    marginTop: 0,
    marginBottom: 32,
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.regular,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      marginBottom: 0,
      fontSize: fontSizes.md,
      lineHeight: 1.3,
      textAlign: 'left',
    },
  },
  button: {
    position: 'static',
    display: 'flex',
    width: '100%',
    marginTop: 32,
    padding: '18px 25px',
    borderRadius: 60,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.xs,
    textTransform: 'uppercase',
    [breakpoints.up('sm')]: {
      maxWidth: 180,
      padding: '22px 25px',
      lineHeight: lineHeights.md,
    },
    '& span': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      padding: '32px 32px 40px',
      letterSpacing: 'normal',
      [breakpoints.up('sm')]: {
        justifyContent: 'flex-start',
        padding: '40px 40px 51px 64px',
      },
    },
  },
  content: {
    width: '100%',
    [breakpoints.up('sm')]: {
      width: 'calc(100% - 232px)',
    },
  },
  imgContainer: {
    display: 'block',
    width: 200,
    height: 124,
    margin: '0 auto',
    [breakpoints.up('sm')]: {
      margin: 0,
    },
  },
}));
