import { ConsumerRequestUrls } from '../../rest/consumer/consumer.request-urls';
import { OAuthRequestUrls } from '../../rest/oauth/oauth.request-urls';

interface IHandledErrors {
  [key: string]: number[];
}

export const handledErrors: IHandledErrors = {
  [ConsumerRequestUrls.pinCodeSend]: [400],
  [ConsumerRequestUrls.pinCodeConfirm]: [400],
  [ConsumerRequestUrls.updatePin]: [400],
  [ConsumerRequestUrls.updatePhone]: [400],
  [ConsumerRequestUrls.confirmPhone]: [400],
  [ConsumerRequestUrls.register]: [400],
  [ConsumerRequestUrls.disable]: [],
  [OAuthRequestUrls.login]: [401],
  [OAuthRequestUrls.registerMobile]: [400],
  [OAuthRequestUrls.confirmMobile]: [400],
};
