import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ellipse from '../../../../assets/images/backgrounds/ellipse.png';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  root: {
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '50%',
    backgroundPositionY: -460,
    backgroundImage: `url(${ellipse})`,
    paddingTop: 32,
    paddingBottom: 28,
    [breakpoints.up('md')]: {
      paddingTop: 112,
      paddingBottom: 24,
      backgroundPositionY: -220,
    },
    [breakpoints.up('lg')]: {
      paddingTop: 80,
      paddingBottom: 48,
      backgroundPositionY: -270,
    },
  },
  heading: {
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    color: palette.info.dark,
    fontSize: fontSizes.xxl,
    lineHeight: lineHeights.xxl,
    margin: '0 0 32px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxxl,
      lineHeight: lineHeights.xxxl,
      marginBottom: 40,
    },
  },
}));
