import { ReactElement } from 'react';

import { IOrderCardProps } from '../../../order/order-card';

export const limitOrders = (
  orders: ReactElement<IOrderCardProps>[] | [], endIndex: number,
): ReactElement<IOrderCardProps>[] | [] => {
  let result: ReactElement<IOrderCardProps>[] = [];

  if (orders.length) {
    result = orders.slice(0, endIndex);
  }

  return result;
};
