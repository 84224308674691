import { FormattedMessage } from 'react-intl';

import { BonusCardProps } from './bonus-card.models';
import {
  BonusCard as BonusCardBase,
  Icon,
  IconSizes,
  IconTypes,
} from '../../../../../../../../common';
import { useStyles } from './bonus-card.styles';
import { BonusType } from '../../../../../../../../../graphql/bonuses/models/get-bonuses.models';

export const BonusCard = ({
  type, value, id, title, description, onBonusSelect, name,
}: BonusCardProps): JSX.Element => {
  const classes = useStyles();
  const bonusDescription = type === BonusType.discount
    ? (
      <FormattedMessage
        id="userActivities.discounts.discountCard.description"
      />
    ) : description;

  const handleCardSelect = () => {
    onBonusSelect(id, {
      title,
      description,
      type,
      value,
      name,
    });
  };

  return (
    <BonusCardBase
      data-testid="bonus-card"
      isAvailable
      cardTitle={title}
      description={bonusDescription}
      bonusImage={(
        <Icon
          type={IconTypes.percentRoundedBlue}
          size={IconSizes['3xl']}
        />
      )}
      onClick={handleCardSelect}
      titleStyles={classes.title}
      descriptionStyles={classes.description}
      rootStyles={classes.root}
    />
  );
};
