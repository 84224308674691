import { useIntl } from 'react-intl';

import { ChangeButton } from '../../change-button';
import { ILensesChangeButtonProps } from './lenses-change-button.models';

export const LensesChangeButton = ({
  onProductsChange,
  isChangeButton,
}: ILensesChangeButtonProps): JSX.Element => {
  const intl = useIntl();

  return (
    <ChangeButton
      data-testid="lenses-change-button"
      onButtonClick={onProductsChange!}
      ariaLabel={isChangeButton
        ? intl.formatMessage({ id: 'order.create.confirm.lenses.changeButton.ariaLabel' })
        : intl.formatMessage({ id: 'order.create.confirm.lenses.addButton.ariaLabel' })
      }
      labelId={!isChangeButton ? 'order.create.confirm.addButton.label' : undefined}
    />
  );
};
