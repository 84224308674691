import React, { FC } from 'react';
import { useQuery } from '@apollo/client';

import { GET_IS_USER_LOGGED_IN } from '../../../graphql/user/queries';
import { withFittingProps } from '../with-fitting-props/with-fitting-props';
import { FittingProps } from '../with-fitting-props/with-fitting-props.models';

export const withLoggedInProps = <T extends unknown>(Component: FC<T>) => (
  props: T,
): JSX.Element => {
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);

  const WrappedComponent = isLoggedIn
    ? withFittingProps(Component)
    : Component;

  return <WrappedComponent {...props as T & FittingProps} />;
};
