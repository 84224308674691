import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../button-new';
import { useGoBack } from '../../../hooks/use-go-back';
import chevron from '../../../assets/images/icons/arrow/chevron.svg';
import { useStyles } from './back-button.styles';
import { BackButtonProps } from './back-button.models';

export const BackButton = ({ onClick, to, isState = true }: BackButtonProps): JSX.Element => {
  const { goBack } = useGoBack();
  const classes = useStyles();

  const handleBackButtonClick = useCallback(() => goBack(isState), [goBack]);
  const handleCustomClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
      <Button
        rootClasses={classes.root}
        textClasses={classes.text}
        to={to}
        style="text"
        onClick={onClick ? handleCustomClick : handleBackButtonClick}
        startIcon={<img className={classes.icon} src={chevron} alt="" />}
      >
        <FormattedMessage id="common.backButton.label" />
      </Button>
  );
};
