import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
  useState, useEffect, useCallback,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from 'mui-modal-provider';
import { isEqual } from 'lodash';

import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../../common/form';
import {
  IExternalState,
  IFormItemValues,
  IStepsState,
} from '../../order-create-page.models';
import { AddLensesStepForm, IPackageParams } from './add-lenses-step-form';
import { getParamsByPackage } from './utils/get-params-by-package';
import { AddLensesStepTitle } from './add-lenses-step-title/add-lenses-step-title';
import { AddButton } from './add-button';
import { Button } from '../../../../../../common';
import { useStyles } from './add-lenses-step.styles';
import { getIndexedFormValues } from '../../utils/get-form-values';
import { getConcatFormValues } from '../confirm-step/utils/get-concat-form-values';
import { IFormSubmitValues } from '../../../../../../common/form';
import { getConsumerLenses } from './utils/get-consumer-lenses';
import { getFormPackages } from './utils/get-form-packages';
import { LeaveModal } from '../../leave-modal';
import { LeaveSameRouteModal } from '../../leave-same-route-modal';
import {
  getChangedConsumerLenses,
  getRepeatConsumerLenses,
} from './utils/get-repeat-consumer-lenses';
import { compareFormValues } from './utils/compare-form-values';

export const AddLensesStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  stepsState,
  onGoNextSuccess,
  externalState,
  onGoLastSuccess,
}, ref): JSX.Element => {
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const innerState = stepsState as IStepsState;
  const { products: addLensesStepState } = innerState;
  const state = externalState as IExternalState;
  const { lenses, brandsData } = state;
  const consumerLenses = useMemo(() => getParamsByPackage(lenses!), [lenses]);
  const classes = useStyles();
  const [packagesValue, setPackagesValue] = useState(0);
  const [consumerProducts, setConsumerProducts] = useState<IPackageParams[]>(consumerLenses);
  const [isScrollElem, setIsScrollElem] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {},
    goLast() {},
  }));

  const renderProducts = useMemo(() => {
    if (addLensesStepState) {
      return state.isRepeatOrder
        ? getRepeatConsumerLenses(consumerProducts, addLensesStepState?.products as IFormSubmitValues)
        : getChangedConsumerLenses(consumerProducts, addLensesStepState?.products as IFormSubmitValues);
    } else {
      return getChangedConsumerLenses(consumerProducts, formRef.current?.getValues());
    }
  }, [addLensesStepState?.products, consumerProducts]);

  const values: IFormItemValues[] = useMemo(() => {
    return getIndexedFormValues(renderProducts);
  }, [renderProducts, getIndexedFormValues]);

  const defaultValues = useMemo(() => {
    return getConcatFormValues(values);
  }, [values]);

  const handleSubmit = () => {
    state.consumerProducts = consumerProducts;
    onGoNextSuccess({ products: formRef.current!.getValues() });
  };

  const handleIncreasePackageValue = () => {
    setPackagesValue(packagesValue + 1);
  };

  const handleDecreasePackageValue = () => {
    setPackagesValue(packagesValue - 1);
  };

  const handleGoLastSuccess = () => {
    onGoLastSuccess(state.products);
  };

  const { showModal } = useModal();

  const handleGoLastStep = useCallback(() => {
    const isSameValues = innerState.isChange && !state.isRepeatOrder
      ? isEqual(formRef.current!.getValues(), addLensesStepState?.products as IFormSubmitValues)
      : compareFormValues(
        formRef.current!.getValues(), addLensesStepState?.products as IFormSubmitValues,
      );
    innerState.isChange = false;

    if (!isSameValues) {
      showModal(LeaveSameRouteModal, {
        onConfirm: handleGoLastSuccess,
      });
    } else {
      handleGoLastSuccess();
    }
  }, [onGoLastSuccess]);

  const handleSubmitOnGoLast = useCallback(() => {
    state.products = { products: formRef.current!.getValues() };
    innerState.isChange = false;
    state.consumerProducts = consumerProducts;
    onGoLastSuccess({ products: formRef.current!.getValues() });
  }, [
    formRef.current?.getValues(),
    consumerProducts,
    onGoLastSuccess,
  ]);

  const handleAddPackage = (productId: string) => {
    const product = consumerProducts.find((
      consumerProduct,
    ) => consumerProduct.id === productId);

    const idPartial = new Date().getTime();
    const newConsumerProducts = [...consumerProducts, {
      ...product!,
      id: `${productId}-${idPartial}`,
    }];
    setConsumerProducts(newConsumerProducts);
    setIsScrollElem(true);
  };

  useEffect(() => {
    if (state.isChangeARL) {
      const scrollARL = document.querySelector('[data-name="ARL"]');

      if (scrollARL) {
        scrollARL.scrollIntoView();
      }
    }

    return () => {
      state.isChangeARL = false;
    };
  }, [state.isChangeARL]);

  useEffect(() => {
    if (isScrollElem) {
      document.getElementById('consumer-products')!.lastElementChild!.scrollIntoView();
    }

    return () => {
      setIsScrollElem(false);
    };
  }, [isScrollElem]);

  useEffect(() => {
    if (state.consumerProducts) {
      setConsumerProducts(state.consumerProducts);
    }
  }, [state.consumerProducts]);

  useEffect(() => {
    if (addLensesStepState) {
      const orderLenses = getConsumerLenses(addLensesStepState.products as IFormSubmitValues, consumerProducts)!;
      const packages = getFormPackages(orderLenses);

      setPackagesValue(packages);
    } else {
      setPackagesValue(state.productQuantity || 0);
    }
  }, []);

  useEffect(() => {
    return () => {
      state.productQuantity = packagesValue;
    };
  }, [packagesValue]);

  return (
    <>
      {(!!packagesValue || state.isRepeatOrder) ? <LeaveModal /> : null}
      <div
        className={packagesValue ? classes.titleBar : undefined}
        data-testid="title-bar"
      >
        <AddLensesStepTitle
          packagesValue={packagesValue}
          onGoLast={handleGoLastStep}
          isRepeatOrder={state.isRepeatOrder}
          isChangeProducts={innerState.isChange}
        />
        {packagesValue > 0 && (
          <AddButton
            products={consumerLenses}
            onCardClick={handleAddPackage}
          />
        )}
      </div>
      <AddLensesStepForm
        ref={formRef}
        consumerProducts={renderProducts}
        formProps={{
          validationMode: 'onChange',
          onSubmit: handleSubmit,
          defaultValues,
        }}
        brands={brandsData?.brands}
        onIncreasePackageValue={handleIncreasePackageValue}
        onDecreasePackageValue={handleDecreasePackageValue}
        stepState={addLensesStepState?.products as IFormSubmitValues}
      />
      <Button
        variant="contained"
        data-testid="choose-ecp-button"
        fullWidth
        className={classes.button}
        disabled={(packagesValue as number) < 1}
        onClick={(state.isRepeatOrder || innerState.isChange || state.store) ? handleSubmitOnGoLast : handleSubmit}
      >{state.store ?
        <FormattedMessage id="vp.survey.next" /> :
        (state.isRepeatOrder || innerState.isChange) ? (
        <FormattedMessage id="order.create.addLenses.repeat.button.label" />
        ) : (
        <FormattedMessage id="order.create.addLenses.button.label" />
        )}
      </Button>
    </>
  );
});
