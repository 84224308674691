import { FormattedMessage } from 'react-intl';

import { IEyeParametersProps } from './eye-parameters.models';
import { EyeParams } from '../../../../../../../../common/eye-params';

export const EyeParameters = ({ eye }: IEyeParametersProps): JSX.Element => {
  const parameters = {
    title: (
      <FormattedMessage id="order.create.parameters.title" />),
    params: eye,
    isOrder: true,
  };

  return (
    <div
      data-testid="eye-params-wrapper"
    >
      <EyeParams {...parameters} />
    </div>
  );
};
