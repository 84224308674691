import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';

import { PasswordStepForm } from './password-step-form';
import { yup } from '../../../../../../services/yup';
import { usePasswordValidation } from '../../../../../../hooks/use-password-validation';
import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { IStepsState, TPasswordValues } from '../../reset-password.models';
import { passwordFormatRegx } from '../../../../../../constants';
import { useBind } from '../../../../../../hooks/use-bind';
import { consumerApi } from '../../../../../../rest/consumer';
import { useCancellablePromise } from '../../../../../../hooks/use-cancellable-promise';
import { useSnackbar } from '../../../../../../hooks/use-snackbar';
import {
  useActionsInProgress,
} from '../../../../../../graphql/preloader/actions/actions-in-progress';

export const PasswordStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  stepsState,
  onGoNextFail,
  onGoNext,
  isNavigationAllowed,
}, ref): JSX.Element => {
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const intl = useIntl();
  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();
  const { enqueueSnackbar } = useSnackbar();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const { oneTimePin: { code }, username: { username }, password } = stepsState as IStepsState;

  const newPasswordValidation = usePasswordValidation({
    requiredErrorMessageId: 'changePassword.newPin.errors.required',
  });

  const validationSchema = useMemo(() => {
    const passwordConfirmValidation = yup
      .string()
      .required(intl.formatMessage({ id: 'changePassword.newPinConfirm.errors.required' }))
      .test('password-compare',
        intl.formatMessage({ id: 'changePassword.newPinConfirm.errors.notMatch' }), (
          value, { parent: { password: newPasswordValue } },
        ) => !newPasswordValue || newPasswordValue === value)
      .max(6, intl.formatMessage({ id: 'common.errors.password.maxLength' }))
      .min(6, intl.formatMessage({ id: 'common.errors.password.minLength' }))
      .matches(passwordFormatRegx, intl.formatMessage({ id: 'common.errors.password.format' }));

    return yup
      .object({
        password: newPasswordValidation,
        passwordConfirm: passwordConfirmValidation,
      });
  }, [intl, newPasswordValidation]);

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return formRef.current!.getValues();
    },
  }));

  const defaultValues = useMemo(
    () => password || {
      password: '',
      passwordConfirm: '',
    },
    [password],
  );

  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const onGoNextSuccessBind = useBind(onGoNextSuccess);
  const onGoNextFailBind = useBind(onGoNextFail);
  const onGoNextBind = useBind(onGoNext);
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const isNavigationAllowedBind = useBind(isNavigationAllowed);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);

  const handleSubmit = useCallback(async (formValues: TPasswordValues) => {
    if (!isNavigationAllowedBind.current) {
      return;
    }

    onGoNextBind.current();
    addActionInProgressBind.current();

    try {
      await makeCancellablePromiseBind.current(consumerApi.updatePassword({
        ...pick(formValues, ['password']),
        username,
        code,
      }));

      removeActionInProgressBind.current();
      onGoNextSuccessBind.current(formValues);
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        return;
      }

      onGoNextFailBind.current();
    }
  }, [
    onGoNextBind,
    onGoNextSuccessBind,
    onGoNextFailBind,
    isNavigationAllowedBind,
    enqueueSnackbarBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    addActionInProgressBind,
    removeActionInProgressBind,
  ]);

  return (
    <PasswordStepForm
      ref={formRef}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultValues={defaultValues}
    />
  );
});
