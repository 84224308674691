import React from 'react';
import { useQuery } from '@apollo/client';

import { MyLenses as MyLensesView } from './my-lenses';
import { GET_LENSES } from '../../../../graphql/lenses/queries/get-lenses';
import { IConsumerLenses } from '../../../../graphql/lenses/models/get-lenses.models';

export const MyLenses = (): JSX.Element => {
  const data = useQuery<IConsumerLenses>(GET_LENSES);

  return <MyLensesView lensesData={data} />;
};
