import {
  IPointsEdges,
  PointsTransactionType,
} from '../../../../../../../graphql/app-level-modals/models/get-modals-data.model';

export const getBirthdayPointsEdges = (
  pointsEdges: IPointsEdges,
): number | null => {
  const birthdayEdges = pointsEdges.edges
    .filter(({ node: { transactionType } }) => transactionType === PointsTransactionType.BIRTHDAY);

  return birthdayEdges.length ? birthdayEdges[0].node.points : null;
};