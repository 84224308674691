import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from './bonuses-list.styles';
import { List } from '../list';
import { IconSizes, IconTypes } from '../../../../../common';

export const BonusesList = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  const bonuses = [{
    id: 'bonuses-item-1',
    item: <>{intl.formatMessage(
      { id: 'outOfSalesModal.switch.list.item1' },
      {
        span: (chunks: ReactNode) => <span>{chunks}</span>,
      },
    )}</>,
    icon: IconTypes.screenRoundedGreen,
    iconSize: IconSizes['3xl'],
  }, {
    id: 'bonuses-item-2',
    item: <>{intl.formatMessage(
      { id: 'outOfSalesModal.switch.list.item2' },
      {
        span: (chunks: ReactNode) => <span>{chunks}</span>,
        sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
      },
    )}</>,
    icon: IconTypes.clockRoundedGreen,
    iconSize: IconSizes['3xl'],
  }, {
    id: 'bonuses-item-3',
    item: <>{intl.formatMessage(
      { id: 'outOfSalesModal.switch.list.item3' },
      {
        sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
        span: (chunks: ReactNode) => <span>{chunks}</span>,
      },
    )}</>,
    icon: IconTypes.paintRoundedGreen,
    iconSize: IconSizes['3xl'],
  }];

  return (
    <List
      items={bonuses}
      data-testid="bonuses-list"
      listStyles={classes.root}
      itemStyles={classes.item}
    />
  );
};
