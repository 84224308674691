import React from 'react';
import { useReactiveVar } from '@apollo/client';

import { useStyles } from './ecp-stores.styles';
import { EcpStore } from '../ecp-store';
import { IEcpStore } from '../../../../rest/ecp-locator';
import { IEcpStoresProps } from './ecp-stores.models';
import { sortingType } from '../../../../graphql/ecp-locator/ecp-locator.cache';
import { SortingTypes } from '../ecp-sort/ecp-sort.models';
import { sortByRaiting, sortByDistance } from '../../../../utils/ecp-locator';

export const EcpStores = ({
  stores,
  onStoreSelect,
  storeCode,
  onScrollToStore,
}: IEcpStoresProps): JSX.Element => {
  const classes = useStyles();
  const currentSortingType = useReactiveVar(sortingType);
  const isSortByRating = currentSortingType === SortingTypes.rating;
  const sortingCallback = isSortByRating ? sortByRaiting : sortByDistance;
  const resultStores = stores && sortingCallback([...stores]);

  return (
    <ul
      className={classes.root}
      role="list"
      data-testid="stores-list"
    >
      {
        resultStores?.map((store: IEcpStore) => (
          <li
            key={store.code}
            role="listitem"
            className={classes.storeItem}
          >
            <EcpStore
              storeData={store}
              onStoreSelect={onStoreSelect}
              storeCode={storeCode}
              onScrollToStore={onScrollToStore}
              contentPartialStyles={classes.storeContentPartial}
            />
          </li>
        ))
      }
    </ul>
  );
};
