import React, { useEffect, useRef } from 'react';

import { useDocumentVisibility } from '../../../../../../hooks/use-document-visibility';
import { IMyLensesProps } from './my-lenses.models';
import { MyLensesFittedCard } from './my-lenses-fitted-card';
import { MyLensesNotFittedCard } from './my-lenses-not-fitted-card';
import { isUserFitted } from '../../../../../../utils/lenses/is-user-fitted';
import { Preloader } from '../../../../../common/preloader';

export const MyLenses = ({ previewData }: IMyLensesProps): JSX.Element | null => {
  const isFirstRun = useRef(true);
  const isVisible = useDocumentVisibility();
  const {
    data, loading, error, refetch,
  } = previewData;

  const isFitted = isUserFitted(data);

  const renderCard = () => {
    if (loading) {
      return <Preloader />;
    }

    if (error) {
      return null;
    }

    return (
      isFitted
        ? (
          <MyLensesFittedCard
            lensesProfileData={data!.profile}
            lensesData={data!.consumerLensesByAuthID}
            purchasesData={data!.purchasesByAuthId}
            fittingsData={data!.fittingByAuthId}
          />
        ) : <MyLensesNotFittedCard />
    );
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (isVisible) {
      refetch();
    }
  }, [isVisible, refetch]);

  return renderCard();
};
