import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, palette, fontSizes, fontWeights,
}: Theme) => ({
  headerTitle: {
    display: 'block',
    marginTop: 0,
    marginBottom: 16,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: palette.common.white,
    fontSize: fontSizes.xl,
    lineHeight: 1.3,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.xxl,
    },
  },
  headerTitleAdditional: {
    width: '100%',
    maxWidth: 192,
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: fontSizes.xxl,
    [breakpoints.up('sm')]: {
      maxWidth: 'unset',
      fontSize: fontSizes.xxl,
    },
  },
  block: {
    display: 'block',
  },
}));
