import { ProductCard } from './product-card';
import { useStyles } from './add-modal-list.styles';
import { IAddModalListProps } from './add-modal-list.models';

export const AddModalList = ({
  products, onCardClick, onModalClose,
}: IAddModalListProps): JSX.Element => {
  const classes = useStyles();

  return (
    <ul
      data-testid="add-modal-list"
      className={classes.list}
    >
      {products.length ? products.map(({ brandId, id }) => (
        <li
          key={id}
          data-testid="add-modal-list-item"
          className={classes.listItem}
        >
          <ProductCard
            brand={brandId!}
            onCardClick={onCardClick}
            id={id}
            onModalClose={onModalClose}
          />
        </li>
      )) : null}
    </ul>
  );
};
