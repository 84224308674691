import { useState, useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useModal } from 'mui-modal-provider';

import { Status } from '../status';
import { Lenses } from '../lenses';
import { Bonuses } from '../bonuses';
import { Delivery } from '../delivery';
import { CancelOrderButton } from '../cancel-order/cancel-order-button';
import { CheckBonusModal } from '../bonuses/bonus/check-bonus-modal';
import { CallSupportModal } from '../../../order/order-create-page/error-modals/call-support-modal';
import { getOrderPackages } from '../utils';
import {
  IOrderSelectedBonus,
  IProduct,
  OrderStatus,
} from '../../../../../../graphql/order/models/get-orders.models';
import {
  getOrderStatus,
  getIsCancelledOrder,
  getIsFulfilledOrder,
  getIsConfirmedOrder,
} from '../utils/get-order-status';
import { IOrderNoEditProps } from './order-no-edit.models';
import { UPDATE_ORDER } from '../../../../../../graphql/order/mutations/update-order';
import { GET_ORDERS } from '../../../../../../graphql/order/queries/get-orders';
import { GET_VOUCHERS } from '../../../../../../graphql/bonuses/queries/get-vouchers';
import { useActionsInProgress } from '../../../../../../graphql/preloader/actions/actions-in-progress';
import {
  BONUS_RULES,
  CUSTOM_FREE_PACK_IS_ABSENT,
  FREE_PACK_RULES, NO_VOUCHER_IN_CURRENT_STORE,
} from '../../../../../../constants/errors';

export const OrderNoEdit = ({
  storeData, products, points, status, orderId, deliveryDate, bonus, purchaseDate,
  bonusId,
}: IOrderNoEditProps): JSX.Element => {
  const [orderStatus, setOrderStatus] = useState<OrderStatus | undefined>(status);
  const [tempOrderBonus, setTempOrderBonus] = useState<IOrderSelectedBonus | null>(null);
  const [tempOrderBonusId, setTempOrderBonusId] = useState<string>('');
  const [orderBonus, setOrderBonus] = useState<IOrderSelectedBonus | undefined | null>(bonus);
  const [orderBonusId, setOrderBonusId] = useState<string>(bonusId || '');
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const [isBonusError, setIsBonusError] = useState<boolean>(false);

  const updateStatus = useCallback((value: OrderStatus) => {
    setOrderStatus(value);
  }, [setOrderStatus]);

  const [updateOrder, { data }] = useMutation(UPDATE_ORDER, {
    refetchQueries: [{
      query: GET_ORDERS,
    }, {
      query: GET_VOUCHERS,
    }],
  });

  const { showModal } = useModal();

  const handleUpdateOrder = async (id: string) => {
    addActionInProgress();

    try {
      await updateOrder({
        variables: {
          orderId,
          oldVoucher: orderBonusId,
          order: {
            voucherId: id,
          },
        },
      });

      setIsBonusError(false);
    } catch (error: any) {
      setIsBonusError(true);

      if (error.message.includes(BONUS_RULES) || error.message === FREE_PACK_RULES) {
        showModal(CheckBonusModal, {});
      } else if (error.message === CUSTOM_FREE_PACK_IS_ABSENT
        || error.message.includes(NO_VOUCHER_IN_CURRENT_STORE)) {
        showModal(CallSupportModal, {});
      }
    }

    removeActionInProgress();
  };

  const handleBonusSelect = (id: string, newBonus: IOrderSelectedBonus | null) => {
    setTempOrderBonus(newBonus);
    setTempOrderBonusId(id);

    handleUpdateOrder(id);
  };

  useEffect(() => {
    if (data && !isBonusError) {

      setOrderBonusId(tempOrderBonusId);
      setOrderBonus(tempOrderBonus);
    }
  }, [data, isBonusError]);

  return (
    <>
      <Status status={orderStatus!} />
      <Lenses
        products={products}
      />
      <Bonuses
        packageNumber={getOrderPackages(products as IProduct[])}
        points={points!}
        bonus={orderBonus ? orderBonus : undefined}
        isActiveStatus={!getOrderStatus(orderStatus)}
        isCancelledOrder={getIsCancelledOrder(orderStatus)}
        onBonusSelect={handleBonusSelect}
        bonusId={bonusId || orderBonusId}
      />
      <Delivery
        deliveryDate={deliveryDate}
        storeData={storeData}
        isActiveStatus={!getOrderStatus(orderStatus)}
        isCancelledOrder={getIsCancelledOrder(orderStatus)}
        isFulfilledOrder={getIsFulfilledOrder(orderStatus)}
        isConfirmedOrder={getIsConfirmedOrder(orderStatus)}
        purchaseDate={purchaseDate}
      />
      {!getOrderStatus(orderStatus) && (
        <CancelOrderButton
          orderId={orderId}
          updateStatus={updateStatus}
        />
      )}
    </>
  );
};
