import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    height: 1,
    border: 'none',
    backgroundColor: palette.grey['200'],
  },
}));
