import { getIsAppUpdateScheduledFromStorage } from './get-is-app-update-scheduled-from-storage';
import { removeIsAppUpdateScheduledFromStorage } from './remove-is-app-update-scheduled-from-storage';

export const cleanAppUpdate = (): void => {
  const isAppUpdateScheduled = getIsAppUpdateScheduledFromStorage();

  if (isAppUpdateScheduled === false) {
    removeIsAppUpdateScheduledFromStorage();
  }
};
