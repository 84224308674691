import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rootPageLevel: {
    position: 'fixed',
    zIndex: 9999,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: palette.grey['50'],
  },
  circularProgressRoot: {
    margin: '40px 0',
  },
  circularProgressColorPrimary: {
    color: palette.info.dark,
  },
}));
