import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../button';
import { Modal } from '../modal';
import { IVpUpdateModalProps } from './vp-update-modal.models';

export const VpUpdateModal = ({ onVPUpdate, ...props }: IVpUpdateModalProps): JSX.Element => (
  <Modal
    {...props}
    footer={(
      <Button
        fullWidth
        data-testid="vp-update-button"
        variant="contained"
        onClick={onVPUpdate}
      >
        <FormattedMessage
          id="vp.updateModal.updateButton.label"
        />
      </Button>
    )}
  />
);
