import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './find-id-modal.styles';
import { Button, Modal } from '../../../../common';
import { ReactComponent as BarcodeIcon } from '../../../../../assets/images/reg-guide/barcode.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/images/reg-guide/copy.svg';
import topIcon from '../../../../../assets/images/reg-guide/find-id.png';
import { IFindIdModalProps } from './find-id-modal.models';

export const FindIdModal = ({ onClose, ...props }: IFindIdModalProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  const handleClose = () => {
    onClose!();
  };

  const steps = [{
    item: intl.formatMessage(
      { id: 'regGuide.findId.modal.id' },
      { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
    ),
    icon: <BarcodeIcon />,
  }, {
    item: intl.formatMessage(
      { id: 'regGuide.findId.modal.copy' },
      { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
    ),
    icon: <CopyIcon />,
  }];

  return (
    <Modal
      {...props}
      data-testid="find-id-modal"
      propsPaperStyles={classes.root}
      propsContentStyles={classes.content}
      onClose={handleClose}
      topIcon={(
        <img
          src={topIcon}
          alt=""
          className={classes.topIcon}
        />
      )}
      title={intl.formatMessage(
        { id: 'regGuide.findId.modal.title' },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      )}
      titleStyles={classes.title}
      content={(
        <ul
          data-testid="find-id-list"
          className={classes.list}
        >
          {steps.map(({ item, icon }, index) => (
            <li
              data-testid="find-id-list-item"
              className={classes.listItem}
              key={index}
            >
              <span
                aria-hidden="true"
                className={classes.iconWrapper}
                data-testid="icon-wrapper"
              >
                {icon}
              </span>
              <span
                data-testid="find-id-content"
                className={classes.itemContent}
              >{item}</span>
            </li>
          ))}
        </ul>
      )}
      footer={(
        <Button
          variant="contained"
          data-testid="find-id-modal-button"
          onClick={handleClose}
          fullWidth
        >
          <FormattedMessage
            id="regGuide.findId.modal.button"
          />
        </Button>
      )}
    />
  );
};
