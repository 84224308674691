import { FormattedMessage, useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { useStyles } from './out-of-sales-label.styles';
import { Icon, IconSizes, IconTypes } from '../../../../common/icon';
import { IOutOfSalesLabelProps } from './out-of-sales-label.models';
import { Button } from '../../../../common/button';

export const OutOfSalesLabel = ({
  message, onClick, messageStyles,
}: IOutOfSalesLabelProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Button
      className={classes.root}
      data-testid="out-of-sales-label"
      // @ts-ignore
      onClick={onClick}
      variant="text"
    >
      <span
        data-testid="exclamation-icon-wrapper"
        aria-hidden="true"
        className={classes.exclamation}
      >
        <Icon
          size={IconSizes.sm}
          type={IconTypes.exclamationRoundedRed}
        />
      </span>
      <span
        data-testid="label-content-wrapper"
        className={classes.contentWrapper}
      >
        <span
          data-testid="message"
          className={cx(classes.message, messageStyles)}
        >
          {message}
        </span>
        <span
          data-testid="button-inner"
          className={classes.buttonInner}
          aria-label={intl.formatMessage({ id: 'outOfSalesButton.ariaLabel' })}
        >
          <FormattedMessage id="common.outOfSales.button.message" />
        </span>
      </span>
    </Button>
  );
};
