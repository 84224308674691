import { useCallback, useEffect, useState } from 'react';

interface IMyLocation {
  isLocationDefined: boolean;
  coords: IMyCoords;
}

interface IMyCoords {
  latitude?: number;
  longitude?: number;
}

const getCoordsFromLocalStorage = (): IMyCoords | null => JSON.parse(localStorage.getItem('coords')!);

const setCoordsToLocalStorage = (coords: IMyCoords) => {
  localStorage.setItem('coords', JSON.stringify(coords));
};

const getDefaultLocationValues = () => {
  let resultValues: IMyLocation = {
    isLocationDefined: false,
    coords: {
      latitude: undefined,
      longitude: undefined,
    },
  };

  if (getCoordsFromLocalStorage()) {
    resultValues = {
      isLocationDefined: true,
      coords: getCoordsFromLocalStorage()!,
    };
  }

  return resultValues;
};

export const useMyLocation = () => {
  const [resultCoords, setCoords] = useState<IMyLocation>(getDefaultLocationValues());

  const handleNavigationAllowed = useCallback(({ coords }: GeolocationPosition) => {
    const { latitude, longitude } = coords;
    const { latitude: currentLatitude, longitude: currentLongitude } = resultCoords.coords;

    if (latitude !== currentLatitude || longitude !== currentLongitude) {
      setCoords({
        isLocationDefined: true,
        coords: {
          latitude,
          longitude,
        },
      });
      setCoordsToLocalStorage({ latitude, longitude });
    }
  }, [resultCoords.coords]);

  const handleNavigationError = useCallback(() => {
    const { isLocationDefined } = resultCoords;

    if (isLocationDefined) {
      localStorage.removeItem('coords');
      setCoords(getDefaultLocationValues());
    }
  }, [resultCoords]);

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition(handleNavigationAllowed, handleNavigationError);
  }, []);

  return resultCoords;
};
