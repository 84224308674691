import React, { ReactNode, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWrapper } from '../../../common/content-wrapper';
import { useStyles } from './program.styles';
import { BackButton } from '../../../common/back-button-new';
import { ProgramList } from './program-list/program-list';
import { Separator } from '../../../common';
import { DownloadApp } from '../download-app';
import { Button } from '../../../common/button-new';
import { InternalLinks } from '../../../../constants';

export const Program = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const items: string[] = useMemo(() => {
    return [
      'myAcuvueProgram.description-2.listItem-1',
      'myAcuvueProgram.description-2.listItem-2',
      'myAcuvueProgram.description-2.listItem-3',
      'myAcuvueProgram.description-2.listItem-4',
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'myAcuvueProgramPage.title' })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'myAcuvueProgramPage.description' })}
        />
      </Helmet>
      <div className={classes.root}>
        <ContentWrapper>
          <BackButton />
          <h1 className={classes.title}>
            {intl.formatMessage(
              { id: 'myAcuvueProgram.title' },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </h1>
          <p className={classes.description}>
            {intl.formatMessage(
              { id: 'myAcuvueProgram.description-1' },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </p>
          <ul className={classes.list}>
            {items.map((item) => (<li key={item}><FormattedMessage id={item} /></li>))}
          </ul>
          <p className={classes.description}>
            {intl.formatMessage(
              { id: 'myAcuvueProgram.description-3' },
              {
                sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                a: (chunks: ReactNode) => (
                  <Button style="text" isUnderline to={InternalLinks.registration} rootClasses={classes.link}>{chunks}</Button>
                ),
              },
            )}
          </p>
          <p className={classes.description}>
            {intl.formatMessage(
              { id: 'myAcuvueProgram.description-4' },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </p>
          <p className={classes.description}>
            {intl.formatMessage({ id: 'myAcuvueProgram.description-5' })}
          </p>
        </ContentWrapper>
        <Separator />
        <ProgramList />
        <Separator />
        <DownloadApp />
      </div>
    </>
  );
};
