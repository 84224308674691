export interface IEcpLocatorData {
  lat? : number,
  long?: number,
  range: number,
  speciality: string,
  sorting: string,
  type: EcpStoreType | '',
  c2cActivated: boolean,
}

export type TEcpLocatorResponse = IEcpStore[];

export interface IEcpStore {
  appointmentUrl: string | null;
  c2cActivated: boolean;
  city: string;
  code: string;
  country: string;
  distance: number;
  fittingOnly: boolean;
  latitude: number;
  longitude: number;
  name: string;
  openinghours: string;
  phonenumber: string;
  postalCode: string | null;
  rating: number | null;
  state: string | null;
  storeGroupCode: string;
  street: string;
  website: string;
  speciality: string;
  type: EcpStoreType;
  detailedLocation?: string;
}

export enum EcpStoreType {
  fitter = 'fitter',
  clinicHybrid = 'clinic_hybrid',
  clinic = 'clinic',
  retailerHybrid = 'retailer_hybrid',
  retailer = 'retailer',
  hybrid = 'hybrid',
  optics = 'optics',
  vending = 'vending',
  null = '',
  c2cActivated = 'c2cActivated',
}

export enum EcpStoreSpecialty {
  astigmatism = 'astigmatism',
  presbyopia = 'presbyopia',
  children = 'children',
}

export interface InternetShop {
  name: string;
  website: string | null;
}

export type TInternetShopResponse = InternetShop[];
