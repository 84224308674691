import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, palette, fontFamilies, fontSizes, fontWeights, lineHeights,
}: Theme) => ({
  coloredDateTitleWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 108,
    display: 'flex',
    alignItems: 'flex-end',
    padding: '0 0 20px 24px',
    zIndex: 2,
    backgroundColor: palette.primary.main,
    [breakpoints.up('md')]: {
      padding: '0 65px 32px',
      height: 200,
    },
  },
  title: {
    margin: 0,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    color: palette.common.white,
    [breakpoints.up('md')]: {
      textAlign: 'center',
      fontSize: fontSizes.xl,
      lineHeight: lineHeights.xl,
    },
  },
  content: {
    marginLeft: -10,
    paddingTop: 60,
    [breakpoints.up('md')]: {
      marginLeft: 0,
      paddingTop: 136,
    },
  },
}));
