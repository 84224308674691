import { IFormItemValues } from '../../../order-create-page.models';
import { IFormSubmitValues } from '../../../../../../../common/form';

export const getConcatFormValues = (arrValues: IFormItemValues[]): IFormSubmitValues => {
  let result = {};

  for (let i = 0; i < arrValues.length; i++) {
    result = Object.assign(result, arrValues[i]);
  }

  return result;
};
