import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './support-block.styles';
import { Button } from '../button-new';

export const SupportBlock = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div data-testid="footer-support-block">
      <p
        className={classes.description}
        data-testid="footer-support-description"
        id="help"
      >
        <FormattedMessage id="footer.supportService.description" />
      </p>
      <div className={classes.contactWrapper}>
        <Button
          style="light"
          to={intl.formatMessage({ id: 'common.phoneNumber.support.href' })}
          testId="footer-phone-link"
        >
          <FormattedMessage id="common.phoneNumber.support" />
        </Button>
        <a
          href={intl.formatMessage({ id: 'common.email.program' })}
          target="_blank"
          className={classes.social}
        />
      </div>
    </div>
  );
};
