import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from './appendix-cookie-section.styles';
import { Accordion } from '../../../../../common/accordion';
import { getJnjPolicies, getThirdPartyPolicies } from './items';

export const AppendixCookieSection = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <section
      data-testid="cookie-accordion"
      aria-label={intl.formatMessage({ id: 'cookiePolicy.appendix.ariaLabel' })}
    >
      {intl.formatMessage(
        { id: 'cookiePolicy.appendix.header' },
        {
          linebreak: <br />,
          h2: (chunks: ReactNode) => <h2 className={classes.title}>{chunks}</h2>,
        },
      )}
      {intl.formatMessage(
        { id: 'cookiePolicy.appendix.title' },
        { h3: (chunks: ReactNode) => <h3 className={classes.subTitle}>{chunks}</h3> },
      )}
      <Accordion items={getJnjPolicies(classes, intl)} />
      {intl.formatMessage(
        { id: 'cookiePolicy.appendix.thirdParty.title' },
        { h3: (chunks: ReactNode) => <h3 className={classes.subTitle}>{chunks}</h3> },
      )}
      <Accordion items={getThirdPartyPolicies(classes, intl)} />
    </section>
  );
};
