import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, lineHeights,
}: Theme) => ({
  list: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gap: 16,
    listStyle: 'none',
    margin: 0,
    padding: 0,
    [breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridAutoRows: '1fr',
    },
  },
  item: {
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
}));
