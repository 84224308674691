export interface INotification {
  id: string;
  notificationType: NotificationType;
  targetId: NotificationTargetId[];
  sourceObjectIds: string[] | null;
  supportingData: string[] | null;
}

export enum NotificationType {
  SURVEY = 'SURVEY',
  APPOINTMENT_UPDATE = 'APPOINTMENT_UPDATE',
  APPOINTMENT_CANCEL = 'APPOINTMENT_CANCEL',
  UNCONFIRMED_APPOINTMENT_COUNT = 'UNCONFIRMED_APPOINTMENT_COUNT',
  INFO = 'INFO',
  ORDER_NEW = 'ORDER_NEW',
  ORDER_CANCEL = 'ORDER_CANCEL',
  MANUALLY_REMINDER_EXPIRATION_1_MONTH = 'MANUALLY_REMINDER_EXPIRATION_1_MONTH',
  MANUALLY_REMINDER_EXPIRED = 'MANUALLY_REMINDER_EXPIRED',
  REMINDER_ABOUT_NEXT_PURCHASE = 'REMINDER_ABOUT_NEXT_PURCHASE',
  BIRTHDAY_POINT = 'BIRTHDAY_POINT',
  INCORRECT_PURCHASES = 'INCORRECT_PURCHASES',
  USER_BLOCKED = 'USER_BLOCKED',
}

export enum NotificationTargetId {
  AFTERCARE = '103',
  REMINDER = '107',
}

export enum AftercareSurveyResponse {
  CONFIRM = 'CONFIRM',
  NOTCONFIRMED = 'NOTCONFIRMED',
}

export interface SurveyResponse {
  type: string;
  surveyId: number;
  sourceObjectId: string;
  response: string;
  consumerId: string;
}
