import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  borderRadiuses,
  fontSizes,
  lineHeights,
  fontWeights,
  spacing,
}: Theme) => ({
  accordionRoot: {
    border: `1px solid ${palette.common.white}`,
    boxShadow: `0 4px 8px ${palette.grey['300']}`,
    '&&': {
      borderRadius: borderRadiuses.md,
      margin: '0 0 12px',
      [breakpoints.up('md')]: {
        marginBottom: spacing(1),
      },
    },
    '&::before': {
      display: 'none',
    },
  },
  accordionExpanded: {
    borderRadius: borderRadiuses.md,
    borderColor: palette.primary.dark,
  },
  summaryRoot: {
    minHeight: 'auto',
    padding: 12,
    borderRadius: borderRadiuses.md,
    backgroundColor: palette.common.white,
    [breakpoints.up('md')]: {
      padding: spacing(1.5),
    },
  },
  summaryContent: {
    '&$summaryContent': {
      alignItems: 'center',
      margin: 0,
    },
  },
  summaryFocused: {
    '&$summaryFocused': {
      borderRadius: borderRadiuses.md,
      backgroundColor: palette.common.white,
    },
  },
  summaryExpanded: {
    '&$summaryExpanded': {
      margin: 0,
      minHeight: 'auto',
    },
  },
  summaryIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
    marginRight: 8,
    backgroundColor: palette.grey.A100,
    borderRadius: borderRadiuses.xxl,
    [breakpoints.up('sm')]: {
      width: 64,
      height: 64,
      marginRight: 24,
    },
    '& + h2': {
      maxWidth: 'calc(100% - 56px)',
      [breakpoints.up('sm')]: {
        maxWidth: 'calc(100% - 88px)',
      },
    },
  },
  detailsRoot: {
    padding: '0 12px 12px',
    display: 'block',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
      padding: '0 24px 24px',
    },
  },
  expandIcon: {
    padding: 0,
    margin: 0,
  },
  divider: {
    '&$divider': {
      marginBottom: 12,
      [breakpoints.up('md')]: {
        marginBottom: 24,
      },
    },
  },
  title: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    margin: 0,
    padding: 0,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.lg,
      lineHeight: lineHeights.xl,
    },
  },
}));
