import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ fontWeights, palette }: Theme) => ({
  icon: {
    marginRight: 8,
    lineHeight: 0,
    '& svg': {
      width: 18,
      height: 18,
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8,
    color: palette.primary.dark,
    fontWeight: fontWeights.regular,
  },
}));
