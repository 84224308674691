import React from 'react';

import { EcpLocator as EcpLocatorView } from './ecp-locator';
import { ecpLocatorSettings } from '../../../common/ecp-locator-settings';
import { IEcpLocatorProps } from './ecp-locator.models';

const EcpLocatorContainer = (props: IEcpLocatorProps): JSX.Element => (
  <EcpLocatorView
    {...props}
  />
);

export const EcpLocator = ecpLocatorSettings(EcpLocatorContainer);
