import { RefObject, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { cx } from '@emotion/css';

import { useModal } from 'mui-modal-provider';
import { useStyles } from './ecp-locator.styles';
import { EcpMap } from '../ecp-map';
import { EcpStores } from '../ecp-stores';
import { EcpSearch } from '../ecp-search';
import { EcpSort } from '../ecp-sort/ecp-sort';
import { StoreDetail } from './store-detail';
import { useStoreDetailFromCache } from '../../../../graphql/ecp-locator/actions/store-detail';

import {
  ecpStoresCached,
  setAutocompletedSearch,
  setAutocompletedValue,
  setAutoValueSelected,
  setEcpFormSubmitted,
  setStoreCode,
  setStoreDetail,
} from '../../../../graphql/ecp-locator/ecp-locator.cache';
import { IconButton } from '../../../common/icon-button';
import { Icon, IconSizes, IconTypes } from '../../../common/icon';
import { EcpFiltersModal } from '../ecp-filters-modal';
import { IEcpStore } from '../../../../rest/ecp-locator';
import { IEcpLocatorProps } from './ecp-locator.models';
import { Preloader } from '../../../common/preloader';
import { ErrorPlain, ErrorTypes } from '../../../common/error-plain';
import { EcpToggleView } from '../ecp-toggle-view';
import { MobileViewTypes } from '../../../../graphql/ecp-locator/models/map.models';
import { isSingleBrand } from '../../../../utils/lenses/is-single-brand';
import { LensesBrandType } from '../../../../graphql/lenses/models/get-lenses.models';
import { Appointment } from '../user-activity-items/appointment';
import { setC2CMode } from '../../../../graphql/configuration/configuration.cache';
import { FilterButton } from './filter-button';
import { InternalLinks } from '../../../../constants';

export const EcpLocator = ({
  isFitted, isLoading, isError, updateStores, consumerLenses,
}: IEcpLocatorProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const isTablet = !isMobile && !isDesktop;

  const [mobileView, setMobileView] = useState(MobileViewTypes.map);

  const { setStoreDetailToCache } = useStoreDetailFromCache();

  const ecpStores = useReactiveVar(ecpStoresCached);
  const storeDetail = useReactiveVar(setStoreDetail);
  const storeCode = useReactiveVar(setStoreCode);
  const isEcpFormSubmitted = useReactiveVar(setEcpFormSubmitted);
  const c2CMode = useReactiveVar(setC2CMode);

  const { showModal } = useModal();

  const isStoreListShow = !storeDetail;
  const isLayerCard = isTablet || (isMobile && isStoreListShow);
  const isMapHidden = isMobile && (mobileView !== MobileViewTypes.map || !isStoreListShow);
  const isStoresShow = !isMobile || mobileView === MobileViewTypes.list;
  const isMobileButtonsShow = isMobile && isStoreListShow;
  const inputIcon = isMobile ? undefined : <Icon type={IconTypes.map} />;

  // Hide C2C feature from aohm-only fitted users
  const isSingleAohm = isSingleBrand(consumerLenses!, LensesBrandType.aohm);

  const handleStoreSelect = (store: IEcpStore): void => {
    setStoreDetailToCache(store);
  };

  const handleStoreClose = (code: string) => {
    setStoreDetailToCache(null);
    // To manage scroll to store card.
    setStoreCode(code);
  };

  const handleScrollToStore = (
    currentStoreId: string,
    storeTitle: RefObject<HTMLHeadingElement>,
    storeId?: string,
  ) => {
    if (storeId?.length && storeId === currentStoreId) {
      storeTitle.current?.focus();
      storeTitle.current?.scrollIntoView();
      setStoreCode('');
    }
  };

  const handleFilterButtonClick = () => {
    showModal(EcpFiltersModal, {
      isSingleAohm,
      c2CMode,
    });
  };

  const renderStores = () => {
    if (isLoading) {
      return <Preloader />;
    }

    if (isError) {
      return null;
    }

    return (
      <EcpStores
        stores={ecpStores}
        onStoreSelect={handleStoreSelect}
        storeCode={storeCode}
        onScrollToStore={handleScrollToStore}
      />
    );
  };

  useEffect(() => {
    setAutocompletedValue('');

    return () => {
      setStoreDetailToCache(null);
      setStoreCode('');
      setStoreDetail(null);
      setAutocompletedValue('');
      setEcpFormSubmitted(false);
      setAutoValueSelected(false);
      setAutocompletedSearch(false);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'ecpLocatorPage.title' })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'ecpLocatorPage.description' })}
        />
      </Helmet>
      <div
        className={classes.root}
        data-testid="ecp-locator"
      >
        <div
          className={cx(classes.ecpLayerRoot, {
            [classes.ecpLayerRootCard]: isLayerCard,
          })}
        >
          {isStoreListShow && (
            <>
              <div
                className={cx(classes.ecpLayerWrapper, classes.shadowWrapper)}
                data-testid="ecp-stores-container"
              >
                {!isMobile && (
                  <h1
                    className={classes.title}
                    data-testid="ecp-stores-title"
                  >
                    <FormattedMessage
                      id="ecp.title"
                    />
                  </h1>
                )}
                <div className={classes.searchBar}>
                  <EcpSearch
                    updateStores={updateStores!}
                    inputIcon={inputIcon}
                    inputStyles={classes.input}
                    rootInputStyles={classes.inputRoot}
                  />
                  <div className={classes.filterButtonDesktop}>
                    <FilterButton isMobile={isMobile} onClick={handleFilterButtonClick} />
                  </div>
                  <IconButton
                    aria-label={intl.formatMessage({ id: 'onlineShops.title' })}
                    to={InternalLinks.onlineShops}
                    iconProps={{
                      type: isMobile ? IconTypes.internetShops : IconTypes.internetShopsWrapped,
                      size: isMobile ? IconSizes.sm : IconSizes.lg,
                    }}
                  />
                </div>
                {isStoresShow && (
                  <div className={classes.sortButtons}>
                    <EcpSort />
                  </div>
                )}
              </div>
              {(isEcpFormSubmitted && !ecpStores.length && isStoresShow) && (
                <div className={classes.ecpLayerWrapper}>
                  <ErrorPlain
                    content="ecp.error.noResults"
                    isolated
                    variant={ErrorTypes.plainText}
                  />
                </div>
              )}
              {isStoresShow && (
                <div className={cx(classes.ecpLayerWrapper, classes.storesList)}>
                  {renderStores()}
                </div>
              )}
            </>
          )}
          {storeDetail && (
            <div className={classes.ecpLayerWrapper}>
              <StoreDetail
                storeData={storeDetail as IEcpStore}
                onStoreClose={handleStoreClose}
                updateStores={updateStores!}
                isFitted={isFitted!}
                isSingleAohm={isSingleAohm}
                c2CMode={c2CMode}
              />
            </div>
          )}
        </div>
        {!(mobileView === MobileViewTypes.list) && (
          <div
            data-testid="ecp-map-container"
            className={cx(classes.mapLayer,
              { [classes.mapLayerHidden]: isMapHidden },
            )}
          >
            <EcpMap
              ecpStores={ecpStores}
              storeDetail={storeDetail as IEcpStore}
              isMapHidden={isMapHidden}
            />
            <div
              data-testid="appointment-wrapper"
              className={classes.appointmentWrapper}
            >
              <Appointment />
            </div>
          </div>
        )}
        {isMobileButtonsShow && (
          <>
            <div className={classes.filterButtonMobile}>
              <FilterButton isMobile={isMobile} onClick={handleFilterButtonClick} />
            </div>
            <div className={classes.mobileViewButton}>
              <EcpToggleView
                value={mobileView}
                fullWidth
                onChange={setMobileView}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
