import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  fontWeights,
  palette,
  lineHeights,
  fontSizes,
  borderRadiuses,
}: Theme) => ({
  toggleGroupRoot: {
    marginBottom: 12,
  },
  toggleGroupRootWithoutMargins: {
    marginBottom: 0,
  },
  toggleGroupRootStandalone: {
    flexWrap: 'wrap',
  },
  toggleButtonRoot: {
    borderRadius: borderRadiuses.md,
    backgroundColor: palette.grey['200'],
    padding: 12,
    marginBottom: 12,
    fontSize: fontSizes.xs,
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights.sm,
    color: palette.common.black,
    fontFamily: fontFamilies.primary,
    textTransform: 'none',
    border: 'none',
    '&:hover': {
      backgroundColor: palette.grey['200'],
    },
    '&&:not(:last-child)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&&:not(:first-child)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderLeftColor: palette.grey['400'],
    },
    '&&$toggleButtonSelected': {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      '&:hover': {
        backgroundColor: palette.primary.main,
      },
    },
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  toggleButtonRootFullWidth: {
    flexGrow: 1,
  },
  toggleButtonRootStandalone: {
    '&&:not(:first-child)': {
      borderLeft: 'none',
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    '&:not(:last-child)': {
      '&&': {
        marginRight: 12,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
  },
  toggleButtonRootOutlined: {
    backgroundColor: 'transparent',
    border: `1px solid ${palette.grey['500']}`,
    color: palette.grey['700'],
    '&$toggleButtonSelected': {
      borderColor: 'transparent',
      '&&:not(:first-child)': {
        borderLeftColor: 'transparent',
      },
    },
  },
  toggleButtonRootOutlinedLight: {
    backgroundColor: 'transparent',
    border: `1px solid ${palette.grey['500']}`,
    color: palette.text.primary,
    '&$toggleButtonSelected': {
      border: `1px solid ${palette.grey['500']}`,
      '&&': {
        backgroundColor: palette.info.light,
        color: palette.text.primary,
      },
      '&&:not(:first-child)': {
        borderLeft: `1px solid ${palette.grey['500']}`,
      },
      '&:hover': {
        backgroundColor: palette.grey.A200,
      },
    },
  },
  outlinedStandaloneDivider: {
    '&:not(:first-child)': {
      '&&': {
        borderLeft: `1px solid ${palette.grey['500']}`,
      },
    },
  },
  sizeSmall: {
    padding: '6px 8px',
    [breakpoints.up('md')]: {
      padding: '8px 12px',
    },
  },
  sizeMedium: {
    padding: '16px 20px',
  },
  toggleButtonSelected: {},
  groupedVertical: {
    marginBottom: 0,
    textAlign: 'start',
    justifyContent: 'flex-start',
  },
  groupedVerticalStandalone: {
    marginBottom: 9,
    '&:not(:first-child)': {
      '&&': {
        marginTop: 0,
        borderTopRightRadius: 8,
        borderTop: `1px solid ${palette.grey['500']}`,
      },
    },
    '&.Mui-selected:not(:first-child)': {
      '&&': {
        borderTop: `1px solid ${palette.grey['500']}`,
      },
    },
    '&:not(:last-child)': {
      '&&': {
        marginRight: 0,
        borderBottomLeftRadius: 8,
      },
    },
  },
  formHelperTextRoot: {
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontFamily: fontFamilies.primary,
    color: palette.text.secondary,
    margin: '0 0 10px',
    '&$formHelperTextError': {
      color: palette.error.main,
    },
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
      marginBottom: 12,
    },
  },
  formHelperTextError: {},
}));
