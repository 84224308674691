import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ contentWidth }: Theme) => ({
  root: {
    width: '100%',
    maxWidth: contentWidth,
    margin: '0 auto',
    padding: '0 1.5rem',
  },
  rootWithoutPaddings: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
