import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useModal } from 'mui-modal-provider';

import { BirthdayPointsModal } from './birthday-points-modal';
import { GET_APP_LEVEL_MODALS_DATA } from '../../../../../../graphql/app-level-modals/queries/get-modals-data';
import { IAppLevelModalsData, INotification, IPointsEdges } from '../../../../../../graphql/app-level-modals/models/get-modals-data.model';
import { getBirthdayPointsNotifications } from './utils/get-birthday-points-notifications';
import { getBirthdayPointsEdges } from './utils/get-birthday-points-edges';

export const useBirthdayPointsModal = () => {
  const [shouldModalUpdate, setModalUpdateState] = useState(true);
  const { updateQuery } = useQuery<IAppLevelModalsData>(GET_APP_LEVEL_MODALS_DATA);
  const { showModal } = useModal();

  const updateNotifications = useCallback((notificationId: string) => {
    setModalUpdateState(true);

    updateQuery((prevState) => ({
      ...prevState,
      notificationByAuthId: prevState.notificationByAuthId
        .filter(({ id }) => !(notificationId === id)),
    }));
  }, [
    updateQuery,
    setModalUpdateState,
  ]);

  const handleBirthdayPointsModal = useCallback((
    notifications: INotification[], pointsEdges: IPointsEdges,
  ) => {
    if (shouldModalUpdate) {
      const birthdayPointsNotification = getBirthdayPointsNotifications(notifications);
      const birthdayPoints = getBirthdayPointsEdges(pointsEdges);

      if (birthdayPointsNotification.length && birthdayPoints) {
        const { id: notificationId } = birthdayPointsNotification[0];

        showModal(BirthdayPointsModal, {
          notificationId,
          birthdayPoints,
          updateNotifications,
        });
      }

      setModalUpdateState(false);
    }
  }, [
    shouldModalUpdate,
    showModal,
    setModalUpdateState,
  ]);

  return { handleBirthdayPointsModal };
};
