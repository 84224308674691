import { cx } from '@emotion/css';

import { Card, CardTypes } from '../card';
import { IBonusCardHorizontalProps } from './bonus-card-horizontal.models';
import { useStyles } from './bonus-card-horizontal.styles';
import { getThemeProps } from '@mui/styles';

export const BonusCardHorizontal = ({
  cardTitle,
  bonusImage,
  description,
  externalContentClasses,
  titleStyles,
  descriptionStyles,
  rootStyles,
  isCustomTitleTag,
  ...props
}: IBonusCardHorizontalProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Card
      {...props}
      {...getThemeProps}
      className={cx(classes.root, rootStyles)}
      type={CardTypes.bonus}
    >
      {bonusImage && (
        <div
          className={classes.bonusImage}
          data-testid="bonus-card-horizontal-image"
          aria-hidden="true"
        >
          {bonusImage}
        </div>
      )}
      <div data-testid="bonus-card-horizontal-content">
        {(cardTitle && !isCustomTitleTag) ? (
          <h3
            data-testid="bonus-card-horizontal-title"
            className={cx(classes.title, titleStyles)}
          >
            {cardTitle}
          </h3>
        ) : <>{cardTitle}</>}
        {description && (
          <p
            className={cx(classes.description, descriptionStyles)}
            data-testid="bonus-card-horizontal-description"
          >
            {description}
          </p>
        )}
      </div>
    </Card>
  );
};
