import {
  IFittingPreviewData,
  IPurchasesData,
} from '../../../../../../../graphql/lenses/models/get-lenses-preview.models';
import { SolutionsBrandType } from '../../../../../../../graphql/lenses/models/get-lenses.models';

export const filterPurchases = (purchases: IPurchasesData[]) => {
  let result: IPurchasesData[] = purchases;

  if (purchases.length) {
    result = purchases.map(({ createdDateTime, type, scannedProduct }) => ({
      createdDateTime,
      type,
      scannedProduct: scannedProduct.filter(({
        myAcuvueBrandCode,
      }) => myAcuvueBrandCode !== SolutionsBrandType.arl),
    }));
  }

  return result.filter(({ scannedProduct }) => scannedProduct.length);
};

export const filterFittings = (fittings: IFittingPreviewData[]) => {
  let result: IFittingPreviewData[] = fittings;

  if (fittings.length) {
    result = fittings
      .map(({ createdDateTime, type, fittedBrands }) => ({
        createdDateTime,
        type,
        fittedBrands: fittedBrands.filter(({ brand }) => brand?.product !== SolutionsBrandType.arl),
      }));
  }

  return result;
};
