import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontWeights,
  palette,
  fontSizes,
  breakpoints,
  lineHeights,
}: Theme) => ({
  retailerMessage: {
    margin: '24px 0 0',
    [breakpoints.up('xl')]: {
      paddingTop: 20,
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderColor: palette.grey['200'],
    },
  },
  retailerMessageText: {
    margin: 0,
    color: palette.grey['800'],
    fontSize: fontSizes.xxs,
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights.xs,
    [breakpoints.up('sm')]: {
      width: 320,
    },
  },
  retailerMessageLink: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      outline: `1px dotted ${palette.primary.main}`,
    },
  },
  pointsOnly: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 6px',
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '& svg': {
      marginLeft: -2,
      marginRight: 7,
    },
  },
}));
