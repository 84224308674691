import { IEcpStore } from '../../rest/ecp-locator';

export type EcpMapUserLocation = {
  latitude?: number;
  longitude?: number;
};

export const getLatLng = (obj: EcpMapUserLocation | IEcpStore): google.maps.LatLng => {
  const lat = Number(obj.latitude);
  const lng = Number(obj.longitude);
  return window.google && new window.google.maps.LatLng(lat, lng);
};
