import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import appScreens from '../../../../../assets/images/download-app-screens.png';
import appScreensTablet from '../../../../../assets/images/download-app-screens-tablet.png';
import appScreensMobile from '../../../../../assets/images/download-app-screens-mobile.png';

export const useStyles = makeStyles(({
  breakpoints,
  palette,
  fontSizes,
  fontWeights,
}: Theme) => ({
  root: {
    position: 'relative',
    padding: '1.5rem',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    background: 'linear-gradient(286.95deg, #ffe7e9 1.2%, #e6f6ff 66.28%)',
    '& .MuiTabs-root': {
      width: '100%',
      maxWidth: '29rem',
      minHeight: 'unset',
      margin: '0 auto 1.5rem',
      padding: 2,
      borderRadius: '2rem',
      background: palette.common.white,
    },
    '& .MuiTab-fullWidth': {
      minHeight: 'unset',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      borderRadius: '2.25rem',
      color: palette.text.primary,
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.regular,
      lineHeight: 1,
      textTransform: 'none',
      opacity: 1,
      '&.Mui-selected': {
        background: palette.primary.main,
        color: palette.common.white,
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    width: '100%',
    borderRadius: '0.375rem',
    [breakpoints.up('md')]: {
      width: 'auto',
      height: '100%',
    },
  },
  image: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '7rem',
    height: '15.75rem',
    background: `no-repeat url(${appScreensMobile}) center`,
    backgroundSize: '7rem 15.75rem',
    [breakpoints.up('md')]: {
      width: '9.25rem',
      background: `no-repeat url(${appScreensTablet}) center`,
      backgroundSize: '9.25rem 15.75rem',
    },
    [breakpoints.up('xl')]: {
      width: '22.375rem',
      background: `no-repeat url(${appScreens}) center`,
      backgroundSize: '22.375rem 15.75rem',
    },
  },
  panelInner: {
    display: 'flex',
    [breakpoints.up('md')]: {
      gap: '1.5rem',
    },
    [breakpoints.up('lg')]: {

    },
  },
  qrCode: {
    width: '8.75rem',
    height: '8.75rem',
    padding: '0.375rem',
    borderRadius: '0.5rem',
    lineHeight: 0,
    background: palette.common.white,
    '& img': {
      width: '100%',
    },
  },
  link: {
    display: 'flex',
    width: 'fit-content',
    height: 'min-content',
  },
}));
