import { gql } from '@apollo/client';

export const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointmentMutation ($id: String!) {
    cancelAppointment(appointmentId: $id) {
      id
      status
    }
  }
`;
