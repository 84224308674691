import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
  spaceMin,
}: Theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    background: palette.common.white,
    [breakpoints.up('md')]: {
      width: '100%',
      maxWidth: '34.875rem',
      marginTop: '4rem',
      marginBottom: '4rem',
      borderRadius: 12,
      overflow: 'hidden',
      boxShadow: '3px 4px 13px 0 rgba(5, 41, 103, 0.1)',
    },
  },
  paperRoot: {
    position: 'relative',
    padding: `2rem ${spaceMin}`,
    [breakpoints.up('md')]: {
      padding: '3rem',
    },
  },
  title: {
    position: 'relative',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    color: palette.text.secondary,
    margin: '0 0 8px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      marginBottom: 16,
    },
  },
  titleWithoutDescription: {
    marginBottom: 24,
    [breakpoints.up('md')]: {
      marginBottom: 32,
    },
  },
  description: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.secondary,
    margin: '0 0 24px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
      marginBottom: 32,
    },
  },
  footer: {
    padding: '16px 0',
    [breakpoints.up('md')]: {
      paddingTop: '28px',
      paddingBottom: '28px',
    },
  },
  footerDescription: {
    textAlign: 'center',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.secondary,
    margin: 0,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  backButton: {
    position: 'absolute',
    top: 0,
    right: '100%',
    [breakpoints.up('md')]: {
      marginRight: 20,
    },
  },
  rootResponsiveMarginsSm: {
    marginTop: 32,
    marginBottom: 32,
    [breakpoints.up('md')]: {
      marginTop: 64,
      marginBottom: 64,
    },
    [breakpoints.up('lg')]: {
      marginTop: 80,
      marginBottom: 80,
    },
  },
  rootResponsiveMarginsXs: {
    marginTop: 20,
    marginBottom: 32,
    [breakpoints.up('sm')]: {
      marginTop: 32,
      marginBottom: 80,
    },
  },
}));
