import { SyntheticEvent } from 'react';
import { useModal } from 'mui-modal-provider';
import { useQuery } from '@apollo/client';

import { OutOfSalesLabel as OutOfSalesLabelView } from './out-of-sales-label';
import { IOutOfSalesProps } from './out-of-sales-label.models';
import { OutOfSalesModal } from '../out-of-sales-modal';
import {
  IConsumerLenses,
  LensesBrandType,
} from '../../../../../graphql/lenses/models/get-lenses.models';
import { GET_LENSES } from '../../../../../graphql/lenses/queries/get-lenses';

export const OutOfSalesLabel = ({ message, messageStyles }: IOutOfSalesProps): JSX.Element => {
  const { showModal } = useModal();
  const lensesData = useQuery<IConsumerLenses>(GET_LENSES);
  const { data } = lensesData;
  const consumerLenses = data?.consumerLensesByAuthID;
  const isAlternative = !!consumerLenses?.find((brand) => brand.brandId === LensesBrandType.oneDate)
    && !!consumerLenses?.find((brand) => brand.brandId === LensesBrandType.aoh1d);

  const handleLabelClick = (e: SyntheticEvent<HTMLButtonElement, Event>) => {
    e.preventDefault();
    showModal(OutOfSalesModal, {
      isAlternative: isAlternative,
    });
  };

  return (
    <OutOfSalesLabelView
      message={message}
      messageStyles={messageStyles}
      onClick={handleLabelClick}
    />
  );
};
