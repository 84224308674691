import React from 'react';

import { BackButton } from '../../../common/back-button';
import { ContentWrapper } from '../../../common/content-wrapper';
import { HowToAccumulatePoints } from './how-to-accumulate-points';
import { HowToSpendPoints } from './how-to-spend-points';
import { useStyles } from './points-how-to.styles';

export const PointsHowTo = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ContentWrapper>
        <BackButton />
        <HowToAccumulatePoints />
        <HowToSpendPoints />
      </ContentWrapper>
    </div>
  );
};
