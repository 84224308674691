import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, fontWeights, palette,
}: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    '&&': {
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none',
    },
  },
  rootSolutions: {
    display: 'flex',
    alignItems: 'center',
    '&&': {
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none',
    },
  },
  brand: {
    position: 'relative',
    margin: 0,
    paddingLeft: 16,
    color: palette.text.secondary,
    fontSize: fontSizes.xs,
    lineHeight: 1.3,
    fontWeight: fontWeights.regular,
    textAlign: 'left',
    [breakpoints.up('sm')]: {
      paddingLeft: 20,
      fontSize: fontSizes.sm,
    },
  },
  image: {
    objectFit: 'contain',
    width: 80,
    marginTop: 2,
    [breakpoints.up('sm')]: {
      width: 140,
    },
  },
  imageSolutionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: 80,
    marginTop: 2,
    [breakpoints.up('sm')]: {
      width: 140,
    },
  },
  imageSolutions: {
    objectFit: 'contain',
    width: 'auto',
    height: 47,
    [breakpoints.up('sm')]: {
      height: 80,
    },
  },
}));
