import {
  EyeParamsType,
  IEyeParamsOption,
} from '../../../../../../graphql/lenses/models/get-lenses.models';
import {
  FormValues,
} from '../order-create-card/change-parameters/change-parameters-modal/change-parameters-modal.models';

export const getEyeParams = (parameters: FormValues): IEyeParamsOption[] => {
  let eyeParams = [] as IEyeParamsOption[];

  for (const key in parameters) {
    if (parameters[key as keyof FormValues].length) {
      eyeParams = [...eyeParams, {
        property: key as EyeParamsType,
        value: parameters[key as keyof FormValues],
      }];
    }
  }

  return eyeParams;
};
