import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  spacing,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
}: Theme) => ({
  content: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.secondary,
    margin: '0 0 12px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  multiContent: {
    '& p': {
      color: palette.text.secondary,
      fontSize: fontSizes.xs,
      lineHeight: lineHeights.sm,
      [breakpoints.up('md')]: {
        fontSize: fontSizes.sm,
        lineHeight: lineHeights.md,
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  actions: {
    display: 'grid',
    gap: spacing(2),
  },
}));
