import { useQuery } from '@apollo/client';

import { LandingPageLoggedIn } from './landing-page-logged-in';
import { LandingPageNotLoggedIn } from './landing-page-not-logged-in';
import { GET_IS_USER_LOGGED_IN } from '../../../../graphql/user/queries';

export const LandingPage = (): JSX.Element => {
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);

  return (
    <>
      {isLoggedIn ? <LandingPageLoggedIn /> : <LandingPageNotLoggedIn />}
    </>
  );
};
