import { useIntl } from 'react-intl';

import { ChangeButton } from '../../../change-button';
import { IBonusChangeButtonProps } from './bonus-change-button.models';

export const BonusChangeButton = ({ onBonusChange }: IBonusChangeButtonProps): JSX.Element => {
  const intl = useIntl();

  return (
    <ChangeButton
      data-testid="bonus-change-button"
      onButtonClick={onBonusChange!}
      ariaLabel={intl.formatMessage({ id: 'order.create.confirm.delivery.changeButton.ariaLabel' })}
    />
  );
};
