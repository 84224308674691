import { ReactNode } from 'react';

export interface IConfigurationSettingsProps {
  children: ReactNode;
}

export enum C2CMode {
  full = 'FULL',
  partial = 'PARTIAL',
  disabled = 'DISABLED',
}
