import { ContentWrapper } from '../../../../common/content-wrapper';
import { useStyles } from './program-list.styles';
import { items } from '../items';
import { ProgramCard } from '../program-card/program-card';

export const ProgramList = (): JSX.Element => {
  const classes = useStyles();

  return (
    <ContentWrapper>
      <ul className={classes.cards}>
        {items.map((item, index) => (
          <li className={classes.card} key={index}>
            <ProgramCard
              title={item.title}
              content={item.content}
              icon={item.icon}
            />
          </li>
        ))}
      </ul>
    </ContentWrapper>
  );
};
