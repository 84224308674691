import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  filtersTypeTitle: {
    margin: '0 0 16px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.lg,
    [breakpoints.up('md')]: {
      lineHeight: lineHeights.xl,
    },
  },
  c2cButtonStyles: {
    '&:last-of-type': {
      display: 'block',
    },
  },
  buttonStyles: {
    [breakpoints.down('md')]: {
      paddingTop: '0.625rem',
      paddingBottom: '0.625rem',
    },
  },
  c2cGroupStyles: {
    '&&': {
      display: 'block',
    },
  },
  c2cGroupStylesAohm: {
    '&&': {
      display: 'flex',
    },
  },
}));
