import { getTokenValue } from '../user';

export const getBasicAuthorizationHeader = () => {
  const tokenValue = getTokenValue();

  if (tokenValue) {
    const basicAuth = btoa(`pwa:${tokenValue}`);
    return `Basic ${basicAuth}`;
  }

  return '';
};
