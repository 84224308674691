import { gql } from '@apollo/client';

export const GET_BANNERS = gql`
  query GetBanners($id: String) {
    banners(id: $id) {
      id
      pwaCallToActionLink
      url
      tabletUrl
      desktopUrl
    }
  }
`;
