import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  fontWeights, fontSizes, lineHeights, breakpoints, palette,
}: Theme) => ({
  root: {
    paddingTop: 16,
    [breakpoints.up('sm')]: {
      paddingTop: 24,
    },
  },
  title: {
    marginTop: 0,
    marginBottom: 4,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      marginBottom: 8,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  inlineWrapper: {
    '& p': {
      display: 'inline',
      marginTop: 0,
      marginBottom: 0,
      color: palette.custom.greyNeutral,
      fontSize: fontSizes.xxs,
      lineHeight: 1.5,
      [breakpoints.up('sm')]: {
        marginBottom: 8,
      },
    },
  },
  withoutTopIndent: {
    paddingTop: 0,
  },
  withBorder: {
    paddingBottom: 16,
    borderBottom: `1px solid ${palette.grey['200']}`,
    [breakpoints.up('sm')]: {
      paddingBottom: 24,
    },
  },
}));
