import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  image: {
    width: 180,
    [breakpoints.up('sm')]: {
      width: 220,
    },
  },
  block: {
    display: 'block',
  },
}));
