import { useIntl } from 'react-intl';

import { Helmet } from 'react-helmet';
import { Banner } from './banner';
import { ReactNode } from 'react';
import { useStyles } from './arl-page.styles';
import { AppBenefits } from './app-benefits';
import { Benefits } from './benefits';

export const ArlPage = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'arl.page.title' })}
        </title>
      </Helmet>
      <div className={classes.root}>
        <Banner />
        <div className={classes.content}>
          <div className={classes.contentInner}>
            <h1
              className={classes.title}
              data-testid="arl-title"
            >{intl.formatMessage(
              { id: 'arl.benefits.title' },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}</h1>
            <Benefits />
            <AppBenefits />
            <p
              className={classes.footnote}
              data-testid="arl-footnote"
            >{intl.formatMessage(
              { id: 'arl.footnote' },
              {
                sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
              },
            )}</p>
          </div>
        </div>
      </div>
    </>
  );
};
