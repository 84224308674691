import { actionsInProgressAmount } from '../preloader.cache';

const addActionInProgress = () => {
  actionsInProgressAmount(actionsInProgressAmount() + 1);
};

const removeActionInProgress = () => {
  actionsInProgressAmount(actionsInProgressAmount() - 1);
};

export const useActionsInProgress = () => ({
  addActionInProgress,
  removeActionInProgress,
});
