import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  icon: {
    display: 'block',
    width: 145,
    height: 90,
    margin: '0 auto 40px',
    [breakpoints.up('md')]: {
      marginBottom: 60,
    },
  },
}));
