import { useCallback } from 'react';
import { useModal } from 'mui-modal-provider';

import { IFreepackCardProps } from './freepack-card.models';
import { ProductType } from '../../../../../../graphql/lenses/models/get-lenses.models';
import { BonusActivationNote } from '../bonus-activation-note';
import { FreepackBonusModal } from '../../modals/freepack-bonus-modal';
import { FreepackCard as FreepackCardView } from '../../../user-activity-items/freepacks';
import { BonusImage } from './bonus-image';

export const FreepackCard = ({
  brand,
  productType,
  isAvailable,
  isActivationMode,
  balance,
  value,
  packSize,
  noveltyLabel,
  fullName,
}: IFreepackCardProps): JSX.Element => {
  const { showModal } = useModal();
  const isLenses = productType === ProductType.lenses;

  const bonusImage = (
    <BonusImage
      type={productType}
      brand={brand}
      packSize={packSize}
    />
  );

  const handleClick = useCallback(() => {
    if (isAvailable) {
      showModal(FreepackBonusModal, {
        isActivationMode: isActivationMode,
        fullName: fullName,
        image: bonusImage,
        packSize: packSize,
        isLenses: isLenses,
      });
    }
  }, [isActivationMode, showModal, fullName, isAvailable, bonusImage, packSize]);

  return (
    <FreepackCardView
      onClick={handleClick}
      fullName={fullName}
      value={value}
      bonusImage={bonusImage}
      isAvailable={isAvailable}
      noveltyLabel={noveltyLabel}
      footer={(
        <BonusActivationNote
          isActive={isAvailable}
          buttonLabel="myPointsDetailed.bonusCards.freepack.available.text"
          ariaLabel="myPointsDetailed.bonusCards.freepack.available.ariaLabel"
          ariaLabelVariable={fullName}
          value={value}
          balance={balance}
          labelNotEarned="myPointsDetailed.bonusCards.freepack.notEarned.text"
        />
      )}
    />
  );
};
