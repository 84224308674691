import React, { useMemo, useCallback, forwardRef } from 'react';
import { UseFormTrigger } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../common/form';
import { TextField } from '../../../../../common/form/text-field';
import { IChangePasswordForm, IFormData } from './change-password-form.models';

export const ChangePasswordForm = forwardRef<IFormImperativeHandleProps, IChangePasswordForm>(({
  onSubmit, validationSchema,
}, ref): JSX.Element => {
  const defaultValues = useMemo(() => ({
    oldPin: '',
    newPin: '',
    newPinConfirm: '',
  }), []);

  const handleNewPinValidateDependencies = useCallback(
    (trigger: UseFormTrigger<IFormData>) => trigger('newPinConfirm'), [],
  );

  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      <TextField
        name="oldPin"
        type="password"
        fullWidth
        autoFocus
        label={(
          <FormattedMessage
            id="changePassword.oldPin.label"
          />
        )}
      />
      <TextField
        name="newPin"
        type="password"
        fullWidth
        label={(
          <FormattedMessage
            id="changePassword.newPin.label"
          />
        )}
        onValidateDependencies={handleNewPinValidateDependencies}
      />
      <TextField
        name="newPinConfirm"
        type="password"
        fullWidth
        label={(
          <FormattedMessage
            id="changePassword.newPinConfirm.label"
          />
        )}
      />
    </Form>
  );
});
