import {
  ChangeEvent,
  useEffect,
  forwardRef,
  useState,
  useMemo,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { useReactiveVar } from '@apollo/client';
import { throttle } from 'lodash';
import { cx } from '@emotion/css';
import { Autocomplete as AutocompleteBase, TextField, InputAdornment } from '@mui/material';

import {
  IGoogleMapAutocompleteProps,
  IPlace,
  TOptionsRequest,
} from './google-map-autocomplete.models';
import { useStyles } from './google-map-autocomplete.styles';
import { parseMatchedSubstrings } from './utils/parse-matched-substrings';
import { promiseErrorCallbacks } from '../../../utils/promise/set-promise-error-callbacks';
import { setAutocompletedValue, setError } from '../../../graphql/ecp-locator/ecp-locator.cache';
import { IconTypes, Icon } from '../icon';
import { EcpErrors } from '../../../constants/ecp-locator/error-types';

export const GoogleMapAutocomplete = forwardRef<
HTMLDivElement, IGoogleMapAutocompleteProps>(({
  fieldName,
  fullWidth,
  rootStyles,
  inputStyles,
  rootInputStyles,
  onChildInputChange,
  onAutocompletedSearch,
  onAutocompletedValue,
  isOpenPredictions,
  onFormSubmitted,
  variant = 'standard',
  startIcon,
  helperText,
  inputError,
  endAdornmentStyles,
}, ref): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const [autocompleteValue, setAutocompleteValue] = useState<string | IPlace | null>(null);
  const [options, setOptions] = useState<(string | IPlace)[]>([]);
  const autocompleteService = useRef<null | google.maps.places.AutocompleteService>(null);
  const autocompletedValue = useReactiveVar(setAutocompletedValue);
  const searchError = useReactiveVar(setError);
  const [inputValue, setInputValue] = useState('');

  // Check OpenProp.
  const checkOpenPredictions = (): boolean => !(
    inputValue.length < 1
    || (inputValue.length && !isOpenPredictions)
    || autocompleteValue
  );

  const fetchOptions = useMemo(
    () => throttle(
      (
        current: any,
        request: TOptionsRequest,
        callback: (results?: IPlace[]) => void,
      ) => {
        current.getPlacePredictions(request, callback);
      }, 200,
    ),
    [],
  );

  const startAdornment = startIcon
    ? (
      <InputAdornment
        disablePointerEvents
        position="start"
      >
        {startIcon}
      </InputAdornment>
    )
    : undefined;

  useEffect(() => {
    if (!(searchError === EcpErrors.errorValue)) {
      setInputValue(autocompletedValue);
      onChildInputChange(autocompletedValue);
    }
  }, [autocompletedValue, searchError]);

  useEffect(() => {
    let active = true;

    if (window.google && window.google.maps.places) {
      const sessionToken: google.maps.places.AutocompleteSessionToken = new (
        window
      ).google.maps.places.AutocompleteSessionToken();

      if (!autocompleteService.current && window.google) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
      }
      if (!autocompleteService.current) {
        return undefined;
      }

      try {
        fetchOptions(autocompleteService.current, {
          input: inputValue,
          sessionToken,
        }, (results?: IPlace[]) => {
          if (active) {
            let newOptions: (IPlace | string)[] = [];

            if (autocompleteValue) {
              newOptions = [autocompleteValue];
            }

            if (results) {
              newOptions = [...newOptions, ...results];
            }
            setOptions(newOptions);
          }
        });
      } catch (error) {
        // Throw a general app error.
        if (promiseErrorCallbacks.anyError) {
          promiseErrorCallbacks.anyError();
        }
      }
    }

    return () => {
      active = false;
      // Reset all input errors in cache.
      setError('');
    };
  }, [autocompleteValue, inputValue, fetchOptions]);

  return (
    <AutocompleteBase
      data-testid="google-map-autocomplete"
      ref={ref}
      classes={{
        root: cx(classes.root, rootStyles, {
          [classes.fullWidth]: fullWidth,
        }),
        inputRoot: cx(rootInputStyles, {
          [classes.inputError]: inputError,
        }),
        input: inputStyles,
        clearIndicator: inputValue.length ? classes.clearIndicatorShown : undefined,
        paper: classes.paper,
        listbox: classes.list,
        endAdornment: endAdornmentStyles,
      }}
      freeSolo
      getOptionLabel={(option) => typeof option === 'string' ? option : option.description}
      filterOptions={(filterOptions) => filterOptions}
      options={options}
      open={checkOpenPredictions()}
      autoComplete
      includeInputInList
      filterSelectedOptions
      selectOnFocus
      handleHomeEndKeys
      clearIcon={<Icon type={IconTypes.close} className={classes.clearIndicator} />}
      clearOnBlur={false}
      openOnFocus={false}
      forcePopupIcon={false}
      value={autocompleteValue}
      inputValue={inputValue}
      onChange={(event: any, newValue: string | IPlace | null) => {
        setOptions(newValue ? [newValue, ...options as IPlace[]] : options);
        setAutocompleteValue(newValue);
        if (typeof newValue !== 'string') {
          onChildInputChange(newValue?.description);

          if (onAutocompletedValue && newValue && onAutocompletedSearch) {
            onAutocompletedSearch(true);
            onAutocompletedValue(newValue?.description);
          }
        }
      }}
      onInputChange={(event, newInputValue, reason) => {
        // Reset all input errors in cache.
        setError('');
        if (reason === 'clear' && onAutocompletedValue) {
          // Clear cache variable onClear to prevent filling in the input.
          onAutocompletedValue('');
        }
        setInputValue(newInputValue);
        onChildInputChange(newInputValue);
        if (onFormSubmitted) {
          onFormSubmitted(false);
        }
      }}
      renderInput={({
        InputProps, ...restParams
      }) => (
        <TextField
          {...restParams}
          variant={variant}
          InputProps={{
            ...InputProps,
            startAdornment,
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.helperText,
            },
          }}
          name={fieldName}
          helperText={helperText}
          error={inputError}
          placeholder={intl.formatMessage({ id: 'ecp.search.placeholder' })}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChildInputChange(event.target.value);
            if (onAutocompletedValue) {
              onAutocompletedValue(event.target.value);
            }
          }}
        />
      )}
      renderOption={(props, option) => {
        if (typeof option === 'string') {
          return <></>;
        }
        const parsedMatchedSubstrings = parseMatchedSubstrings(option);

        return (
          <li
            {...props}
            className={classes.listItem}
            role="listitem"
          >
            {parsedMatchedSubstrings?.map((part: { text: string, highlight: boolean }) => (
              <span
                key={part.text.repeat(3)}
                className={cx(
                  {
                    [classes.highlighted]: part.highlight,
                  },
                )}
              >
                {part.text}
              </span>
            ))}
            <span className={classes.secondaryText}>
              {(option).structured_formatting.secondary_text}
            </span>
          </li>
        );
      }}
    />
  );
});
