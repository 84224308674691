import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { LayoutRegGuideV1 } from './layout-reg-guide-v1';
import { LayoutPrimary } from './layout-primary';
import { LayoutRegGuide } from './layout-reg-guide/layout-reg-guide';
import { Login } from './login';
import { ResetPassword } from './reset-password';
import { Profile } from './profile';
import { Registration } from './registration';
import { LandingPage } from './landing-page';
import { CookieBanner } from './cookie-banner';
import { MyLenses } from './my-lenses';
import { MyBonuses } from './my-bonuses';
import { PointsHowTo } from './points-how-to';
import { CookiePolicy } from './cookie-policy';
import { PageNotFound } from './page-not-found';
import { Program } from './program';
import { EcpLocator } from './ecp-locator';
import { Appointment } from './appointment/appointment.container';
import { LOCALE } from '../../../constants';
import { InternalLinks } from '../../../constants/internal-links';
import { VisionProfile, VisionProfilePage } from './vision-profile';
import { usePrivateRoute } from '../../../hooks/use-private-route';
import { AppLevelModals } from './app-level-modals';
import { MyOrdersPage } from './order/my-orders-page';
import { OrderCreatePage } from './order/order-create-page';
import { RegGuidePage } from './reg-guide-page';
import { RegGuidePageNew } from './reg-guide-page-new';
import { ProfileDelete } from './profile-delete';
import { EcpLocatorExternal } from './ecp-locator-external';
import { MyAcuvueIdContainer } from './my-acuvue-id/my-acuvue-id.container';
import { ArlPage } from './arl-page';
import { BrandPageVision } from './brand-pages/vision';
import { BrandPageComfort } from './brand-pages/comfort';
import { BrandPageUv } from './brand-pages/uv';
import { BrandPageMaxComfort } from './brand-pages/max-comfort';
import { BrandPageClarity } from './brand-pages/clarity';
import { BrandPageMaxUv } from './brand-pages/max-uv';
import { Winners } from './brand-pages/winners';
import { Brand1 } from './brand-pages/facts-about-brand/brand1';
import { BrandPopularity } from './brand-pages/facts-about-brand/brand-popularity';
import { BrandTrust } from './brand-pages/facts-about-brand/brand-trust';
import { BrandUv } from './brand-pages/facts-about-brand/brand-uv';
import { BrandComfort } from './brand-pages/facts-about-brand/brand-comfort';
import { OnlineShops } from './online-shops';

export const App = (): JSX.Element => {
  const intl = useIntl();
  const { getPrivateElement } = usePrivateRoute();

  return (
    <>
      <Helmet htmlAttributes={{ lang: LOCALE }}>
        <title>
          {intl.formatMessage({ id: 'app.title' })}
        </title>
        <meta name="description" content={intl.formatMessage({ id: 'app.description' })} />
      </Helmet>
      <Routes>
        <Route element={<LayoutPrimary />}>
          <Route path="/" element={<LandingPage />} />
          <Route path={InternalLinks.cookiePage} element={<CookiePolicy />} />
          <Route path={InternalLinks.programPage} element={<Program />} />
          <Route path={InternalLinks.ecpLocator} element={<EcpLocator />} />
          <Route path={InternalLinks.profilePage} element={getPrivateElement(<Profile />)} />
          <Route path={InternalLinks.myLensesPage} element={getPrivateElement(<MyLenses />)} />
          <Route path={InternalLinks.myBonusesPage} element={getPrivateElement(<MyBonuses />)} />
          <Route path={InternalLinks.pointsHowToPage} element={getPrivateElement(<PointsHowTo />)} />
          <Route path={InternalLinks.appointment} element={getPrivateElement(<Appointment />)} />
          <Route path={InternalLinks.myOrdersPage} element={getPrivateElement(<MyOrdersPage />)} />
          <Route path={InternalLinks.orderCreate} element={getPrivateElement(<OrderCreatePage />)} />
          <Route path={InternalLinks.onlineShops} element={<OnlineShops />} />
          <Route path={InternalLinks.deleteAccount} element={<ProfileDelete />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route element={<LayoutPrimary isBackground />}>
          <Route path={InternalLinks.login} element={<Login />} />
          <Route path={InternalLinks.registration} element={<Registration />} />
          <Route path={InternalLinks.resetPassword} element={<ResetPassword />} />
          <Route path={InternalLinks.visionProfileSurvey} element={<VisionProfile />} />
          <Route path={InternalLinks.visionProfile} element={getPrivateElement(<VisionProfilePage />)} />
        </Route>
        <Route element={<LayoutRegGuide />}>
          <Route path={InternalLinks.regGuideNew} element={<RegGuidePageNew />} />
        </Route>
        <Route element={<LayoutRegGuide />}>
          <Route path={InternalLinks.arl} element={<ArlPage />} />
        </Route>
        <Route element={<LayoutRegGuideV1 />}>
          <Route path={InternalLinks.regGuide} element={<RegGuidePage />} />
        </Route>
        <Route path={InternalLinks.ecpLocatorExternal} element={<EcpLocatorExternal />} />
        <Route path={InternalLinks.brandVision} element={<BrandPageVision />} />
        <Route path={InternalLinks.brandMaxComfort} element={<BrandPageMaxComfort />} />
        <Route path={InternalLinks.brandUv} element={<BrandPageUv />} />
        <Route path={InternalLinks.brandComfort} element={<BrandPageComfort />} />
        <Route path={InternalLinks.brandClarity} element={<BrandPageClarity />} />
        <Route path={InternalLinks.brandMaxUv} element={<BrandPageMaxUv />} />
        <Route path={InternalLinks.winnersMax} element={<Winners title={'winners.max.title'} />} />
        <Route path={InternalLinks.winnersMaxMultiFocal} element={<Winners title={'winners.max-multiFocal.title'} />} />
        <Route path={InternalLinks.winnersBrand} element={<Winners title={'winners.main.brand.title'} />} />
        <Route path={InternalLinks.brand1} element={<Brand1 />} />
        <Route path={InternalLinks.brandsComfort} element={<BrandComfort />} />
        <Route path={InternalLinks.brandPopularity} element={<BrandPopularity />} />
        <Route path={InternalLinks.brandTrust} element={<BrandTrust/>} />
        <Route path={InternalLinks.brandsUv} element={<BrandUv />} />
      </Routes>
      {location.pathname !== InternalLinks.ecpLocatorExternal && (
        <>
          <CookieBanner />
          <AppLevelModals />
        </>
      )}
      <MyAcuvueIdContainer />
    </>
  );
};
