import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontFamilies,
  fontSizes,
  palette,
  lineHeights,
}: Theme) => ({
  root: {
    backgroundColor: palette.common.white,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    border: 'none',
    borderRadius: 60,
    padding: '10px 16px',
    cursor: 'pointer',
    '& svg + span': {
      marginLeft: 11,
    },
    '& svg:first-child': {
      float: 'left',
    },
  },
  label: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.lg,
    color: palette.text.primary,
  },
  fullWidth: {
    width: '100%',
  },
}));
