import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
  query OrderQuery {
    ordersByAuthId {
      id
      orderStatus
      orderDate
      systemId
      deliveryDate
      createdDateTime
      products {
        brand
        eye {
          property
          value
        }
        packSize
        productQuantity
      }
      store {
        name
        address
        workingHours
        phone
        code
        storeSubType
        latitude
        longitude
        displayName
        city
      }
      salesforceStoreInfo {
        rating
      }
      voucherId
      voucher {
        type
        title
        description
        value
      }
      purchases {
        purchaseDate
      }
    }
    consumerLensesByAuthID {
      id
      createdManual
      brandId
      brand {
        points {
          packSize
          acuvuePoints
        }
      }
    }
  }
`;
