import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SolutionsErrorModal } from '../solutions-error-modal';
import { useStyles } from './solutions-limit-modal.styles';
import { ISolutionsLimitModalProps } from './solutions-limit-modal.models';

export const SolutionsLimitModal = ({ onChangeARL, ...props }: ISolutionsLimitModalProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <SolutionsErrorModal
      {...props}
      data-testid="solutions-limit-modal"
      title={intl.formatMessage({
        id: 'order.solutions.limit.modal.title',
      }, {
        sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
      })}
      content={(
        <>
          <p
            data-testid="content-first"
            className={classes.content}
          >
            {intl.formatMessage({
              id: 'order.solutions.limit.modal.content1',
            }, {
              sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            })}
          </p>
          <p
            data-testid="content-last"
            className={classes.content}
          >
            <FormattedMessage id="order.solutions.limit.modal.content2" />
          </p>
        </>
      )}
      button={<FormattedMessage id="order.solutions.limit.modal.button" />}
      buttonAriaLabel={intl.formatMessage({ id: 'order.solutions.limit.modal.button.ariaLabel' })}
      onButtonClick={onChangeARL}
    />
  );
};
