import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';

import { useStyles } from './winners.styles';
import { WinnersProps } from './winners.models';
import winnersDesktop from '../../../../../assets/images/brand-pages/winners/winners-desktop.png';
import winnersMobile from '../../../../../assets/images/brand-pages/winners/winners-mobile.png';

export const Winners = ({ title }: WinnersProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Helmet>
        {title && (
          <title>
            {intl.formatMessage({ id: title })}
          </title>
        )}
      </Helmet>
      <section
        className={classes.root}
        data-testid="winners-content"
        aria-label={intl.formatMessage({ id: 'winners.ariaLabel' })}
      >
        <img src={isMobile ? winnersMobile : winnersDesktop} alt={intl.formatMessage({ id: 'winners.ariaDescribedBy' })} />
      </section>
    </>
  );
};
