import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  fontWeights,
  borderRadiuses,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: '1.5rem',
    marginBottom: '2rem',
    [breakpoints.up('md')]: {
      margin: '0 auto 2rem',
      flexDirection: 'row',
      borderRadius: borderRadiuses.md,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    height: '100%',
    [breakpoints.up('md')]: {
      width: 'calc((100% - 1.5rem) / 2)',
    },
  },
  title: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    fontWeight: fontWeights.medium,
    lineHeight: 1.2,
    color: palette.text.primary,
    margin: 0,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
    },
    '& sup': {
      position: 'relative',
      lineHeight: 0,
    },
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [breakpoints.up('md')]: {
      width: 'calc((100% - 1.5rem) / 2)',
    },
    '& img': {
      display: 'block',
      width: '100%',
      maxWidth: '27rem',
      height: 'auto',
      objectFit: 'contain',
    },
  },
  newLine: {
    display: 'block',
  },
}));
