import React, {
  useCallback,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { useBind } from '../../../../../../hooks/use-bind';
import { getAuthorizationHeader } from '../../../../../../utils/request-headers/get-authorization-header';
import { useSnackbar } from '../../../../../../hooks/use-snackbar';
import { useCancellablePromise } from '../../../../../../hooks/use-cancellable-promise';
import { ConfirmationCodeStepForm } from './confirmation-code-step-form';
import { yup } from '../../../../../../services/yup';
import { oauthApi } from '../../../../../../rest/oauth';
import { useOtpCodeValidation } from '../../../../../../hooks/use-otp-code-validation';
import { useActionsInProgress } from '../../../../../../graphql/preloader/actions/actions-in-progress';
import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { IStepsState, TConfirmationCodeValues } from '../../registration.models';
import { IExternalStateProps } from '../../registration.models';
import { RecaptchaActions } from '../../../../../../rest/consumer';
import { ITokenData } from '../../../../../../rest/oauth';
import { useRegistrationError } from '../../hooks/use-registration-error';

export const ConfirmationCodeStep = forwardRef<
IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  onGoNextFail,
  onGoNext,
  isNavigationAllowed,
  stepsState,
  setStepState,
  incrementStepActionsInProgress,
  decrementStepActionsInProgress,
  externalState,
}, ref): JSX.Element => {
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const stepsStateRegistration = stepsState as IStepsState;

  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const { enqueueSnackbar } = useSnackbar();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const incrementStepActionsInProgressBind = useBind(incrementStepActionsInProgress);
  const decrementStepActionsInProgressBind = useBind(decrementStepActionsInProgress);
  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const stepsStateBind = useBind(stepsStateRegistration);
  const setStepStateBind = useBind(setStepState);
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const onGoNextFailBind = useBind(onGoNextFail);
  const onGoNextSuccessBind = useBind(onGoNextSuccess);
  const isNavigationAllowedBind = useBind(isNavigationAllowed);
  const onGoNextBind = useBind(onGoNext);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);

  const { time, start, reset, status: timerStatus, isCaptcha } = externalState as IExternalStateProps;

  const handleAfterRequestCode = ( token: ITokenData) => {
    removeActionInProgressBind.current();

    setStepStateBind.current!('phone', {
      ...stepsStateBind.current.phone,
      token,
    });

    reset();
    start();

    decrementStepActionsInProgressBind.current!();
  };

  const { handleError } = useRegistrationError();

  const handleRequestCode = useCallback(async () => {
    if (!isNavigationAllowedBind.current) {
      return;
    }

    incrementStepActionsInProgressBind.current!();
    addActionInProgressBind.current();

    try {
      if (isCaptcha) {
        const recaptcha = (window as any).grecaptcha.enterprise;

        recaptcha.ready(() => {
          recaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
            action: RecaptchaActions.registration,
          }).then(async (recaptchaToken: any) => {
            const { data: token } = await makeCancellablePromiseBind.current(
              oauthApi.registerPhoneNumberRecaptcha({
                phoneNumber: stepsStateBind.current.phone.formValues.phoneNumber,
              }, recaptchaToken),
            );

            handleAfterRequestCode(token);
          }).catch((error: any) => {
            removeActionInProgressBind.current();

            handleError(error);
          });
        });
      } else {
        const { data: token } = await makeCancellablePromiseBind.current(
          oauthApi.registerPhoneNumber({
            phoneNumber: stepsStateBind.current.phone.formValues.phoneNumber,
          }),
        );

        handleAfterRequestCode(token);
      }
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        // eslint-disable-next-line no-useless-return
        return;
      }

      handleError(error);
      decrementStepActionsInProgressBind.current!();
    }
  }, [
    setStepStateBind,
    stepsStateBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    incrementStepActionsInProgressBind,
    decrementStepActionsInProgressBind,
    isNavigationAllowedBind,
    addActionInProgressBind,
    removeActionInProgressBind,
    isCaptcha,
  ]);

  const otpCodeValidation = useOtpCodeValidation();

  const validationSchema = useMemo(() => yup.object({
    otpCode: otpCodeValidation,
  }), [otpCodeValidation]);

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {},
  }));

  const handleSubmit = useCallback(async (formValues: TConfirmationCodeValues) => {
    if (!isNavigationAllowedBind.current) {
      return;
    }

    onGoNextBind.current();
    addActionInProgressBind.current();

    try {
      await makeCancellablePromiseBind.current(
        oauthApi.confirmPhoneNumber({
          phoneNumber: stepsStateBind.current.phone.formValues.phoneNumber,
          otpCode: formValues.otpCode,
        }, {
          headers: {
            Authorization: getAuthorizationHeader(
              stepsStateBind.current.phone.token.access_token,
            ),
          },
        }),
      );

      removeActionInProgressBind.current();
      onGoNextSuccessBind.current(formValues);
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        return;
      }

      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 400:
            enqueueSnackbarBind.current(
              <FormattedMessage
                id="common.errors.otpCode.invalid"
              />,
              { variant: 'error' },
            );
            break;
          default:
            break;
        }
      }

      onGoNextFailBind.current();
    }
  }, [
    stepsStateBind,
    enqueueSnackbarBind,
    onGoNextFailBind,
    onGoNextSuccessBind,
    onGoNextBind,
    isNavigationAllowedBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    addActionInProgressBind,
    removeActionInProgressBind,
  ]);

  const handleClearCode = () => {
    if ((stepsState as IStepsState).name) {
      formRef.current!.setValue('otpCode', '');
    }
  };

  const defaultValues = useMemo(
    () => stepsStateRegistration.confirmationCode || {
      otpCode: '',
    },
    [stepsStateRegistration.confirmationCode],
  );

  return (
    <ConfirmationCodeStepForm
      ref={formRef}
      onRequestCode={handleRequestCode}
      requestCodeTime={time}
      timerStatus={timerStatus}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultValues={defaultValues}
      onClearCode={handleClearCode}
    />
  );
});
