import React, { useState } from 'react';
import { cx } from '@emotion/css';

import { IToggleButtonOptions, IToggleButtonProps } from './toggle-button.models';
import { useStyles } from './toggle-button.styles';

const getNextItemIndex = (options: IToggleButtonOptions[], item: IToggleButtonOptions) => {
  const currentIndex = options.findIndex(({ value }) => value === item.value);

  return currentIndex < options.length - 1 ? currentIndex + 1 : 0;
};

export const ToggleButton = ({
  options, value: defaultValue, onChange, fullWidth, buttonStyles,
}: IToggleButtonProps): JSX.Element => {
  const classes = useStyles();
  const defaultButton = options.find(({ value }) => value === defaultValue) || options[0];
  const [currentButton, setCurrentButton] = useState(defaultButton);
  const { label, icon } = currentButton;

  const handleClick = () => {
    const button = options[getNextItemIndex(options, currentButton)];
    setCurrentButton(button);
    onChange(button.value);
  };

  return (
    <button
      className={cx(classes.root, buttonStyles, {
        [classes.fullWidth]: fullWidth,
      })}
      type="button"
      data-testid="toggle-button"
      onClick={handleClick}
    >
      {icon}
      {label && (
        <span className={classes.label}>
          {label}
        </span>
      )}
    </button>
  );
};
