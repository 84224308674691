import {
  forwardRef,
  RefObject,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { TextField } from '../../../../../../common/form/text-field';
import { IOneTimePinStepFormProps } from './one-time-pin-step-form.models';
import { useStyles } from './one-time-pin-step-form.styles';
import { OTP_MIN_LENGTH } from '../../../../../../../constants';
import { TimerStatusOptions } from '../../../reset-password.models';
import { SubmitButton } from './submit-button/submit-button';

export const OneTimePinStepForm = forwardRef<IFormImperativeHandleProps, IOneTimePinStepFormProps>(({
  onSubmit,
  defaultValues,
  requestCodeTime,
  onRequestCode,
  timerStatus,
  validationSchema,
}, ref): JSX.Element => {
  const isRequestCodeDisabled = Boolean(requestCodeTime);
  const classes = useStyles();
  const formRef = ref as RefObject<IFormImperativeHandleProps>;
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isValueValid, setIsValueValid] = useState<boolean>(false);

  useEffect(() => {
    setIsDisabled(isRequestCodeDisabled || timerStatus === TimerStatusOptions.running);
  }, [isRequestCodeDisabled, timerStatus]);

  const handleChangeDependencies = () => {
    if ((formRef.current?.getValues()?.code as string)?.length < OTP_MIN_LENGTH) {
      setIsValueValid(false);
    } else {
      setIsValueValid(true);
    }
  };

  useEffect(() => {
    handleChangeDependencies();
  }, []);

  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      <TextField
        onChangeDependecies={handleChangeDependencies}
        name="code"
        type="number"
        fullWidth
        autoFocus
        className={classes.root}
        label={(
          <FormattedMessage
            id="resetPassword.oneTimePinStep.label"
          />
        )}
      />
      <SubmitButton
        isDisabled={isDisabled}
        isValueValid={isValueValid}
        onRequestCode={onRequestCode}
        requestCodeTime={requestCodeTime}
      />
    </Form>
  );
});
