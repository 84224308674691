import React from 'react';
import { cx } from '@emotion/css';
import { FormControl as MuiFormControl } from '@mui/material';
import { FormHelperText as MuiFormHelperText } from '@mui/material';
import { FormControlLabel as MuiFormControlLabel } from '@mui/material';
import { Checkbox as MuiCheckbox } from '@mui/material';

import { Icon, IconTypes } from '../icon';
import { ICheckboxProps } from './checkbox.models';
import { useStyles } from './checkbox.styles';

export const Checkbox = ({
  label,
  error,
  helperText,
  isInGroup,
  icon,
  checkedIcon,
  iconCheckbox,
  ...props
}: ICheckboxProps): JSX.Element => {
  const classes = useStyles();

  const checkbox = (
    <>
      <MuiFormControlLabel
        data-testid="checkbox"
        control={(
          <MuiCheckbox
            {...props}
            classes={{
              root: cx(classes.checkboxRoot, {
                [classes.checkboxRootIcontype]: iconCheckbox,
              }),
            }}
            checkedIcon={checkedIcon || <Icon type={IconTypes.checkboxChecked} />}
            icon={icon || <Icon type={IconTypes.checkboxUnchecked} />}
          />
        )}
        label={label ? label as string : ''}
        classes={{
          root: cx(classes.formControlLabelRoot, {
            [classes.formControlRootIconType]: iconCheckbox,
          }),
          label: classes.formControlLabelLabel,
        }}
      />
      {helperText && (
        <MuiFormHelperText
          classes={{
            root: classes.formHelperTextRoot,
            error: classes.formHelperTextError,
          }}
        >
          {helperText}
        </MuiFormHelperText>
      )}
    </>
  );

  if (isInGroup) {
    return checkbox;
  }

  return (
    <MuiFormControl
      fullWidth={!iconCheckbox}
      error={error}
      classes={{
        root: classes.formControlRoot,
      }}
    >
      {checkbox}
    </MuiFormControl>
  );
};
