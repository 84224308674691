import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  palette,
  fontFamilies,
  fontSizes,
  lineHeights,
}: Theme) => ({
  plainText: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.lg,
  },
  error: {
    fontFamily: fontFamilies.primary,
    color: palette.error.main,
  },
}));
