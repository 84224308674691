import React, { Key } from 'react';

import { IWikiListProps } from './wiki-list.models';
import { useStyles } from './wiki-list.styles';
import { WikiCard } from '../wiki-card';

export const WikiList = ({ items, footer }: IWikiListProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <ul
        className={classes.root}
        data-testid="wiki-list"
      >
        {items.map((item) => (
          <li key={item.title as Key}>
            <WikiCard
              title={item.title}
              description={item.description}
            />
          </li>
        ))}
      </ul>
      {footer && (
        <footer
          className={classes.footer}
          data-testid="wiki-list-footer"
        >
          {footer}
        </footer>
      )}
    </>
  );
};
