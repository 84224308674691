import { useCallback } from 'react';
import { useModal } from 'mui-modal-provider';

import { IDiscountCardProps } from './discount-card.models';
import { BonusActivationNote } from '../bonus-activation-note';
import { DiscountBonusModal } from '../../modals/discount-bonus-modal';
import { DiscountCard as DiscountCardView } from '../../../user-activity-items/discounts';

export const DiscountCard = ({
  isAvailable, isActivationMode, value, balance,
}: IDiscountCardProps): JSX.Element => {
  const { showModal } = useModal();

  const handleClick = useCallback(() => {
    if (isAvailable) {
      showModal(DiscountBonusModal, {
        isActivationMode: isActivationMode,
        value: value,
      });
    }
  }, [isActivationMode, showModal, value, isAvailable]);

  return (
    <DiscountCardView
      onClick={handleClick}
      isAvailable={isAvailable}
      value={value}
      footer={(
        <BonusActivationNote
          isActive={isAvailable}
          buttonLabel="myPointsDetailed.bonusCards.discount.available.text"
          ariaLabel="myPointsDetailed.bonusCards.discount.available.ariaLabel"
          ariaLabelVariable={value}
          labelNotEarned="myPointsDetailed.bonusCards.discount.notEarned.text"
          value={value}
          balance={balance}
        />
      )}
    />
  );
};
