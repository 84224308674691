import React from 'react';
import { cx } from '@emotion/css';
import { CircularProgress as MuiCircularProgress } from '@mui/material';

import { IPreloader } from './preloader.models';
import { useStyles } from './preloader.styles';

export const Preloader = ({ isPageLevel }: IPreloader): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={cx(
        classes.root,
        {
          [classes.rootPageLevel]: isPageLevel,
        },
      )}
    >
      <MuiCircularProgress
        data-testid="preloader"
        classes={{
          root: classes.circularProgressRoot,
          colorPrimary: classes.circularProgressColorPrimary,
        }}
      />
    </div>
  );
};
