import React from 'react';

import { SolutionsBrandType } from '../../../graphql/lenses/models/get-lenses.models';
import { ISolutionsBrandLogoProps } from './solutions-brand-logo.models';
import { useStyles } from './solutions-brand-logo.styles';

import ARLLogo from '../../../assets/images/solutions-brands-packagings/arl.png';

const getImageSourceByBrandType = {
  [SolutionsBrandType.arl]: ARLLogo,
};

export const SolutionsBrandLogo = ({
  brand, packSize, ...props
}: ISolutionsBrandLogoProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      {
        packSize ? (
          <span className={classes.root}>
            <img
              {...props}
              src={getImageSourceByBrandType[brand]}
              alt=""
              data-brand={brand}
              data-testid="solutions-brand-logo"
            />
            {packSize && (
              <span className={classes.packSizeLabel}>
                {`${packSize} ml`}
              </span>
            )}
          </span>
        ) : (
          <img
            {...props}
            src={getImageSourceByBrandType[brand]}
            alt=""
            data-brand={brand}
            data-testid="solutions-brand-logo"
          />
        )
      }
    </>
  );
};
