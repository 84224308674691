import React, { useCallback } from 'react';
import { FormControl as MuiFormControl } from '@mui/material';
import { FormLabel as MuiFormLabel } from '@mui/material';
import { FormGroup as MuiFormGroup } from '@mui/material';
import { FormHelperText as MuiFormHelperText } from '@mui/material';

import { Checkbox } from '../checkbox';
import { ICheckboxGroupProps } from './checkbox-group.models';
import { useStyles } from './checkbox-group.styles';
import { TFormExtendedCommonProps } from '../form';

export const CheckboxGroup = ({
  title,
  options,
  value,
  onChange,
  helperText,
  error,
  ...restProps
}: TFormExtendedCommonProps<ICheckboxGroupProps>): JSX.Element => {
  const classes = useStyles();

  const handleChange = useCallback((isChecked: boolean, optionValue: string) => {
    const newValue = {
      ...value,
    };

    if (isChecked) {
      delete newValue[optionValue];
    } else {
      newValue[optionValue] = true;
    }

    onChange(newValue);
  }, [value, onChange]);

  return (
    <MuiFormControl
      fullWidth
      error={error}
      classes={{
        root: classes.formControlRoot,
      }}
    >
      {title && (
        <MuiFormLabel
          classes={{
            root: classes.formLabelRoot,
            focused: classes.formLabelFocused,
            error: classes.formLabelError,
          }}
        >
          {title}
        </MuiFormLabel>
      )}
      <MuiFormGroup
        {...restProps}
      >
        {options.map(({ label, value: optionValue }) => {
          const isChecked = Boolean(value[optionValue]);

          return (
            <Checkbox
              isInGroup
              key={optionValue}
              label={label}
              checked={isChecked}
              onChange={() => handleChange(isChecked, optionValue)}
            />
          );
        })}
      </MuiFormGroup>
      {helperText && (
        <MuiFormHelperText
          classes={{
            root: classes.formHelperTextRoot,
            error: classes.formHelperTextError,
          }}
        >
          {helperText}
        </MuiFormHelperText>
      )}
    </MuiFormControl>
  );
};
