import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, fontWeights, palette, lineHeights,
}: Theme) => ({
  title: {
    marginTop: 0,
    marginBottom: 12,
    color: palette.custom.greyPrimary,
    fontSize: fontSizes.md,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.lg,
    },
  },
}));
