import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontWeights, fontSizes, fontFamilies, lineHeights, palette,
}: Theme) => ({
  formControl: {
    marginBottom: 24,
    '& .MuiInput-root': {
      minWidth: 'unset',
      [breakpoints.up('md')]: {
        marginTop: 22,
      },
    },
    [breakpoints.up('md')]: {
      marginBottom: 34,
    },
  },
  root: {
    paddingTop: 8,
    paddingBottom: 10,
    height: 20,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.sm,
    color: palette.common.black,
    fontFamily: fontFamilies.primary,
    [breakpoints.up('md')]: {
      lineHeight: lineHeights.md,
    },
    '&.MuiSelect-select': {
      paddingRight: 32,
    },
  },
  label: {
    minWidth: 'unset',
    fontFamily: fontFamilies.primary,
    color: palette.common.black,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    width: '110%', // TODO: change this value
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&.Mui-focused': {
      color: palette.common.black,
    },
    '&.MuiFormLabel-filled': {
      transform: 'translate(0, 0)',
      fontSize: fontSizes.xs,
      [breakpoints.up('md')]: {
        fontSize: fontSizes.sm,
      },
    },
  },
  inputUnderline: {
    '&::after': {
      borderBottom: `1px solid ${palette.primary.main}`,
      borderTop: `1px solid ${palette.primary.main}`,
      borderRadius: '10px',
    },
    '&::before': {
      borderTop: `1px ${palette.grey['400']} solid`,
      borderBottomColor: palette.grey['400'],
      borderBottomWidth: '1px',
      borderRadius: '10px',
      content: '""',
    },
  },
  selectMenu: {
    borderRadius: 12,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)',
  },
  selectMenuItem: {
    fontFamily: fontFamilies.primary,
    color: palette.common.black,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.lg,
    '&.Mui-selected': {
      background: palette.grey['200'],
      '&:hover': {
        background: palette.grey['200'],
      },
    },
  },
  icon: {
    top: 'calc(50% - 0.8em)',
  },
}));
