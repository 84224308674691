import { cx } from '@emotion/css';

import { useGoBack } from '../../../../../../hooks/use-go-back';
import { Button as ButtonNew } from '../../../../../common/button-new';
import { NavLinkProps } from './nav-link.models';
import { useStyles } from './nav-link.styles';

export const NavLink = ({
  rootClasses,
  textClasses,
  testId,
  href,
  startIcon,
  hideText,
  children,
  activePath,
}: NavLinkProps): JSX.Element => {
  const { hasPreviousLocationState } = useGoBack();
  const isActive = href === window.location.pathname || window.location.pathname === activePath;
  const classes = useStyles();

  return (
    <ButtonNew
      style="text"
      rootClasses={cx(rootClasses, classes.root,
        { ['active-link']: isActive },
      )}
      textClasses={textClasses}
      data-testid={testId}
      to={href}
      state={hasPreviousLocationState}
      startIcon={startIcon}
      hideText={hideText}
      testId={testId}
    >
      {children}
    </ButtonNew>
  );
};
