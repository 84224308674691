export const replaceDistanceChar = (
  str: string,
  index: number = -2,
  char: string = ',',
) => {
  let formattedStr;
  if (index > str.length - 1) {
    formattedStr = str;
  } else if (str.charAt(str.length - 1) === '0') {
    formattedStr = str.slice(0, index);
  } else {
    formattedStr = `${str.slice(0, index)}${char}${str.slice(index + 1)}`;
  }
  return formattedStr;
};
