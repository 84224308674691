import { useCallback, useEffect, useState } from 'react';

const getIsDocumentVisible = (): boolean => !document.hidden;

export const useDocumentVisibility = (): boolean => {
  const [isVisible, setIsVisible] = useState<boolean>(getIsDocumentVisible());

  const handleVisibilityChange = useCallback(() => {
    setIsVisible(getIsDocumentVisible());
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  });

  return isVisible;
};
