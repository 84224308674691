import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ fontWeights, breakpoints }: Theme) => ({
  root: {
    listStyle: 'none',
    margin: 0,
    paddingLeft: 0,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    fontWeight: fontWeights.medium,
    [breakpoints.up('md')]: {
      marginBottom: 16,
      maxWidth: 335,
    },
    '& svg': {
      marginRight: 16,
    },
    '& span': {
      width: 'calc(100% - 80px)',
    },
  },
}));
