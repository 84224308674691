import { isOrderModalShown, orderModal } from '../order.cache';
import { getOrderModalFromStorage } from '../../../utils/order/get-order-modal-from-storage';
import { setOrderModalToStorage } from '../../../utils/order/set-order-modal-to-storage';

export const setOrderModalShown = () => {
  setOrderModalToStorage();
  orderModal(getOrderModalFromStorage());
  isOrderModalShown(!!orderModal());
};

export const useOrderModal = () => ({
  setOrderModalShown,
});
