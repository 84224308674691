import {
  IConfigurationParams,
  ConfigurationNames,
} from '../../../../graphql/configuration/models/configuration.models';

export const getFreepacksEnabled = (configuration: IConfigurationParams[]): boolean => {
  let result: boolean = false;

  const freepacksParams = configuration.find((
    params,
  ) => params.name === ConfigurationNames.freepacks);

  if (freepacksParams) {
    result = JSON.parse(freepacksParams.configuration).enabled;
  }

  return result;
};
