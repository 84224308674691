import React, { useCallback } from 'react';
import { useFormContext, useController } from 'react-hook-form';

import { TextField as TextFieldBase, TTextFieldProps } from '../../text-field';
import { TFormExtendedProps, IAdditionalFormProps } from '..';

export const TextField = ({
  name,
  helperText,
  onChangeDependecies,
  onValidateDependencies,
  endAdornment,
  customEndAdornment,
  ...props
}: TFormExtendedProps<TTextFieldProps>): JSX.Element => {
  const {
    control, trigger, formState: { errors, isSubmitted },
  } = useFormContext();
  const {
    field: { ref, onBlur, onChange, ...restField },
    fieldState: { invalid },
  } = useController({
    name,
    control,
  });
  const additionalProps: IAdditionalFormProps = {};

  if (invalid) {
    additionalProps.error = true;
    additionalProps.helperText = errors[name]?.message;
  } else if (helperText) {
    additionalProps.helperText = helperText;
  }

  const handleBlur = useCallback(() => {
    onBlur();

    if (isSubmitted && onValidateDependencies) {
      onValidateDependencies(trigger);
    }
  }, [onBlur, onValidateDependencies, trigger, isSubmitted]);

  const handleChange = useCallback((...values: unknown[]) => {
    onChange(...values);

    if (onChangeDependecies) {
      onChangeDependecies();
    }
  }, [onChange, onChangeDependecies]);

  return (
    <TextFieldBase
      {...props}
      {...restField}
      {...additionalProps}
      onBlur={handleBlur}
      onChange={handleChange}
      endAdornment={endAdornment}
      customEndAdornment={customEndAdornment}
    />
  );
};
