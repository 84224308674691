import React, { forwardRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { TextField } from '../../../../../../common/form/text-field';
import { IEmailStepFormProps } from './email-step-form.models';

export const EmailStepForm = forwardRef<IFormImperativeHandleProps, IEmailStepFormProps>(({
  onSubmit,
  validationSchema,
  defaultValues,
}, ref): JSX.Element => {
  const resultDefaultValues = useMemo(() => defaultValues || {
    email: '',
  }, [defaultValues]);
  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      defaultValues={resultDefaultValues}
    >
      <TextField
        name="email"
        type="email"
        fullWidth
        autoFocus
        label={(
          <FormattedMessage
            id="registration.emailStep.emailTextField.label"
          />
        )}
      />
    </Form>
  );
});
