import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { EyeType, IEyeParamsData } from '../components/common/eye-params';
import {
  EyeParamsType,
  IEyeParamsOption,
} from '../graphql/lenses/models/get-lenses.models';
import { omitEyeParamsByType } from '../utils/lenses/omit-eye-params-by-type';

const getTitleById = (id: EyeType): JSX.Element => (
  <FormattedMessage
    id={`eyeParams.${id}Eye.title`}
  />
);

export const useEyeParamsByEye = (
  leftEyeData: IEyeParamsOption[], rightEyeData: IEyeParamsOption[],
): IEyeParamsData => {
  const rightEyeTitle = useMemo(
    () => (rightEyeData.length
      ? getTitleById(EyeType.right)
      : getTitleById(EyeType.left)),
    [rightEyeData.length],
  );

  const leftEyeTitle = useMemo(
    () => (leftEyeData.length
      ? getTitleById(EyeType.left)
      : null),
    [leftEyeData.length],
  );

  const rightEyeParams = useMemo(
    () => (rightEyeData.length
      ? rightEyeData
      : leftEyeData),
    [leftEyeData, rightEyeData],
  );

  const isOneEye = useMemo(
    () => !leftEyeData.length || !rightEyeData.length,
    [leftEyeData.length, rightEyeData.length],
  );

  return {
    rightEyeColumnData: {
      title: rightEyeTitle,
      params: omitEyeParamsByType(rightEyeParams, EyeParamsType.variant),
    },
    leftEyeColumnData: {
      title: leftEyeTitle,
      params: omitEyeParamsByType(leftEyeData, EyeParamsType.variant),
    },
    isOneEye,
  };
};
