import React from 'react';
import { useReactiveVar } from '@apollo/client';

import { BarcodeModal as BarcodeModalView } from './barcode-modal';
import { IBarcodeModal } from '../barcode.models';
import { setMyAcuvueId } from '../../../../../graphql/configuration/configuration.cache';

export const BarcodeModal = (props: IBarcodeModal): JSX.Element => {
  const myAcuvueId = useReactiveVar(setMyAcuvueId);

  return (
    <BarcodeModalView
      myAcuvueId={myAcuvueId}
      modalProps={props}
    />
  );
};
