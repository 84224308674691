import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Icon, IconTypes } from '../../../../../common';
import { useStyles } from './status.styles';
import { IStatusProps } from './status.models';
import { getOrderStatusTranslation } from '../../../../../../utils/order/get-order-status';
import { OrderStatus } from '../../../../../../graphql/order/models/get-orders.models';
import { statusItems } from './status.items';
import { getActiveItems } from '../utils/get-active-items';

export const Status = ({ status }: IStatusProps): JSX.Element => {
  const classes = useStyles();

  const statusItemsActive = useMemo(() => getActiveItems(statusItems, status), [
    statusItems,
    getActiveItems,
  ]);

  const isStatusInactive = status === OrderStatus.canceledByPatient
    || status === OrderStatus.canceled;

  return (
    <>
      <h3
        data-testid="status-title"
        className={classes.title}
      >
        <FormattedMessage id="order.card.status.title" />
      </h3>
      {isStatusInactive ? (
          <p data-testid="status-item-inactive" className={classes.statusInactive}>
            <span
              className={classes.icon}
            >
              <Icon
                type={IconTypes.checked}
              />
            </span>
            <FormattedMessage id={getOrderStatusTranslation(status)} />
          </p>
      ) : (
        <ul
          data-testid="status-list"
          className={classes.list}
        >
          {statusItemsActive.map((statusItem) => (
            <li
              data-testid="status-item"
              key={statusItem.id}
              className={classes.listItem}
            >
              <span
                className={classes.icon}
              >
                <Icon
                  type={statusItem.active
                    ? IconTypes.checked
                    : IconTypes.unchecked
                  }
                />
              </span>
              <FormattedMessage id={statusItem.id} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
