import {
  Ref,
  useMemo,
  useEffect,
  useLayoutEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { useForm, FormProvider, UseFormProps } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { LeavePrompt } from '../leave-prompt';
import { IFormProps, IUnsavedConfirmProps, IFormImperativeHandleProps } from './form.models';
import { useStyles } from './form.styles';

export const Form = forwardRef<IFormImperativeHandleProps, IFormProps>(({
  onSubmitting,
  onSubmit,
  onError,
  defaultValues,
  children,
  validationSchema,
  validationMode = 'onSubmit',
  reValidateMode = 'onBlur',
  withLeavePrompt,
  unsavedChangesConfirmationModalProps,
  onValidateDependencies,
  onChangeValuesDependencies,
  ...props
}, ref): JSX.Element => {
  const classes = useStyles();

  const formParams: UseFormProps = {
    defaultValues,
    mode: validationMode,
    reValidateMode,
  };

  if (validationSchema) {
    formParams.resolver = yupResolver(validationSchema);
  }

  const methods = useForm(formParams);
  const {
    formState: { isSubmitting, isDirty, isValid },
    reset, watch, setError, getValues, setValue, handleSubmit: formHandleSubmit,
  } = methods;

  const handleSubmit = useMemo(
    () => formHandleSubmit(
      (formValues) => onSubmit(formValues, setError),
      onError,
    ),
    [formHandleSubmit, onSubmit, onError, setError],
  );

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
    getValues() {
      return getValues();
    },
    setValue,
    reset,
    isDirty,
  }));

  useLayoutEffect(() => {
    if (isSubmitting && onSubmitting) {
      onSubmitting();
    }
  }, [isSubmitting, onSubmitting]);

  const shouldResetDefaultValues = useRef(false);

  useEffect(() => {
    if (!shouldResetDefaultValues.current) {
      shouldResetDefaultValues.current = true;
      return;
    }

    reset(defaultValues);
  }, [reset, defaultValues]);

  useEffect(() => {
    if (onValidateDependencies) {
      onValidateDependencies(isValid);
    }
  }, [isValid]);

  useEffect(() => {
    if (onChangeValuesDependencies) {
      const subscription = watch((value) => onChangeValuesDependencies(value));
      return () => subscription.unsubscribe();
    }
  }, [watch]);

  return (
    <FormProvider {...methods}>
      <form
        {...props}
        noValidate
        name="form"
        onSubmit={handleSubmit}
        ref={ref as Ref<HTMLFormElement>}
      >
        {withLeavePrompt && isDirty && (
          <LeavePrompt
            confirmationModalProps={unsavedChangesConfirmationModalProps as IUnsavedConfirmProps}
          />
        )}
        {children}
        <input type="submit" className={classes.submitButton} />
      </form>
    </FormProvider>
  );
});
