import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useCancellablePromise } from './use-cancellable-promise';

export interface IRequest<T> {
  data?: T;
  loading: boolean;
  error?: Error;
}

export type IRequestCallback<T> = {
  (...args: any[]): Promise<AxiosResponse<T>>;
};

export const useRequest = <T>(requestCallback: IRequestCallback<T>, ...args: any[]) => {
  const [resultData, setResultData] = useState<IRequest<T>>({
    data: undefined,
    loading: true,
    error: undefined,
  });
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const handleRequest = useCallback(async () => {
    try {
      const { data } = await makeCancellablePromise(requestCallback(...args));

      setResultData({
        data,
        loading: false,
        error: undefined,
      });
    } catch (error: any) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        // eslint-disable-next-line no-useless-return
        return;
      }

      setResultData({
        data: undefined,
        loading: false,
        error,
      });
    }
  }, [
    args,
    CancelledPromiseOnUnmountError,
    makeCancellablePromise,
    requestCallback,
  ]);

  useEffect(() => {
    handleRequest();
  }, []);

  return resultData;
};
