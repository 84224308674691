import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  spacing, breakpoints, palette, fontFamilies, fontSizes, lineHeights,
}: Theme) => ({
  bonusesList: {
    display: 'grid',
    gap: spacing(1.5),
    listStyleType: 'none',
    padding: 0,
    margin: '0 0 20px',
    [breakpoints.up('sm')]: {
      margin: '0 0 32px',
      gap: spacing(2),
    },
  },
  bonusesItem: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: spacing(1),
    [breakpoints.up('sm')]: {
      gap: spacing(1.5),
    },
  },
  description: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.primary,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.md,
      lineHeight: lineHeights.lg,
    },
  },
}));
