import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppointmentRegistrationModal as AppointmentRegistrationModalView } from './appointment-registration-modal';
import { IAppointmentRegistrationModalContainerProps } from './appointment-registration-modal.models';
import { InternalLinks } from '../../../../constants/internal-links';
import { useGoBack } from '../../../../hooks/use-go-back';

export const AppointmentRegistrationModal = (
  props: IAppointmentRegistrationModalContainerProps,
): JSX.Element => {
  const navigate = useNavigate();
  const { hasPreviousLocationState } = useGoBack();

  const handleRegistrationDone = () => {
    navigate(InternalLinks.appointment, { state: hasPreviousLocationState });
  };

  return (
    <AppointmentRegistrationModalView
      {...props}
      onDone={handleRegistrationDone}
    />
  );
};
