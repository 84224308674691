import axios, { AxiosInstance } from 'axios';

import { getXApiKeyHeader } from '../utils/request-headers/get-x-api-key-header';
import { getXAppVersionHeader } from '../utils/request-headers/get-x-app-version-header';
import { isUnhandledError } from '../utils/response/is-unhandled-error';
import { scheduleAppUpdate } from '../utils/app-update/schedule-app-update';
import { getCmsConfig, isCmsUrl } from './cms/cms.utils';
import { getAuthorizationHeader } from '../utils/request-headers/get-authorization-header';
import { isTokenExpired } from '../utils/response/is-token-expired';

const callbacks: { [key: string]: () => void | Promise<string> } = {};

export const setCallback = (eventName: string, callback: () => void) => {
  callbacks[eventName] = callback;
};

export const restClient = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
  headers: {
    'x-api-key': getXApiKeyHeader(),
    'X-APP-Version': getXAppVersionHeader(),
  },
});

export const restClientRecaptcha = (token: string): AxiosInstance => {
  return axios.create({
    baseURL: process.env.REACT_APP_REST_API_URL,
    headers: {
      'x-api-key': getXApiKeyHeader(),
      'X-APP-Version': getXAppVersionHeader(),
      'x-recaptcha-token': token,
    },
  });
};

restClient.interceptors.request.use((config) => {
  if (isCmsUrl(config.url)) {
    return getCmsConfig(config);
  }
  return config;
}, (error) => Promise.reject(error));

restClient.interceptors.response.use((response) => response, (error) => {
  if (error.response) {
    const { status, data } = error.response;
    switch (true) {
      case status === 401:
        if (callbacks.onTokenExpired && isTokenExpired(data)) {
          return (callbacks.onTokenExpired() as Promise<string>)
            .then((token) => {
              error.config.headers.Authorization = getAuthorizationHeader(token);
              return axios.request(error.config);
            });
        }
        break;
      case status === 426:
        scheduleAppUpdate();
        break;
      case status === 403:
        break;
      // reset-password component errors
      case status === 400:
        break;
      case isUnhandledError(status, error.request):
        if (callbacks.onUnknownErrorReceived) {
          callbacks.onUnknownErrorReceived();
        }
        break;
      default:
        break;
    }
  } else if (callbacks.onUnknownErrorReceived) {
    callbacks.onUnknownErrorReceived();
  }

  return Promise.reject(error);
});
