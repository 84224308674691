import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './my-lenses-not-fitted-card.styles';
import { Card } from '../../../../../../common/card';
import { Button } from '../../../../../../common/button';
import { InternalLinks } from '../../../../../../../constants/internal-links';
import { ReactComponent as LenseIcon } from '../../../../../../../assets/images/icons/vision-profile/lense-rounded-blue.svg';
import { Divider } from '../../../../../../common/divider';

export const MyLensesNotFittedCard = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Card
      to={InternalLinks.ecpLocator}
      className={classes.root}
      data-testid="my-lenses-preview-not-fitted"
    >
      <div className={classes.cardContainer}>
        <div>
          <h3 className={classes.title}>
            <FormattedMessage
              id="myLensesPreview.title"
            />
          </h3>
          <p className={classes.fitDescription}>
            {intl.formatMessage(
              { id: 'myLensesPreview.notFitted.description' },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </p>
        </div>
        <LenseIcon
          className={classes.lenseIcon}
          data-testid="my-lenses-preview-not-fitted-icon"
        />
      </div>
      <Divider />
      <div className={classes.myLensesLink}>
        <Button variant="text">
          <FormattedMessage
            id="myLensesPreview.notFitted.myLensesLink.label"
          />
        </Button>
      </div>
    </Card>
  );
};
