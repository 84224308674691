import { FormattedMessage } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';

import { useStyles } from './brand-pages.styles';
import { Footer } from '../../../common/footer';
import { Header } from '../layout-primary/header';
import { BrandPageProps } from './brand-page.models';
import disclaimer from '../../../../assets/images/brand-pages/disclaimer.svg';

export const BrandPage = ({ title, brandImage, legal, description, topIcon }: BrandPageProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isMobile && <Header />}
      <div className={classes.root}>
        <div className={classes.pageContainer}>
          <h1 className={classes.title}>
            {title}
          </h1>
          <img
            src={brandImage}
            alt=""
            className={classes.productClass}
          />
          <img src={topIcon} alt="" className={classes.pupilImage} />
          <p className={classes.description}>
            {description}
          </p>
        </div>
        <div className={classes.legal}>
          <p className={classes.details}>
            {legal}
          </p>
          {isMobile && (
            <span className={classes.age}>
            <FormattedMessage id="brandpages.footer" />
          </span>
          )}
        </div>
        {!isMobile && <span className={classes.gradient} />}
      </div>
      {isMobile ? (
        <Footer />
      ) : (
        <div className={classes.footer}>
          <img src={disclaimer} alt="" />
        </div>
      )}
    </>
  );
};
