import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
}: Theme) => ({
  card: {
    padding: '24px 16px',
    textAlign: 'center',
    [breakpoints.up('md')]: {
      padding: '32px 24px',
    },
    [breakpoints.up('lg')]: {
      padding: '32px',
    },
  },
  image: {
    display: 'block',
    margin: '0 auto 16px',
    width: 100,
    [breakpoints.up('md')]: {
      marginBottom: 32,
    },
  },
  description: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.primary,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '& a': {
      color: palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));
