import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ellipse from '../../../assets/images/backgrounds/ellipse.png';

export const useStyles = makeStyles(({
  breakpoints, fontFamilies, fontSizes, lineHeights, palette, fontWeights,
}: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '48px 0 64px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '50%',
    backgroundPositionY: -440,
    backgroundImage: `url(${ellipse})`,
    [breakpoints.up('sm')]: {
      padding: '80px 0',
      backgroundPositionY: -266,
    },
    [breakpoints.up('xl')]: {
      paddingBottom: 120,
      backgroundPositionY: -264,
    },
  },
  mainIcon: {
    marginBottom: 40,
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      marginBottom: 64,
    },
    '& svg': {
      width: 200,
      height: 200,
    },
  },
  title: {
    maxWidth: 288,
    margin: '0 auto 16px',
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      maxWidth: 368,
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
    [breakpoints.up('xl')]: {
      maxWidth: 524,
    },
  },
  description: {
    marginBottom: 24,
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    [breakpoints.up('xl')]: {
      maxWidth: 544,
      marginBottom: 32,
    },
  },
  descriptionPartial: {
    display: 'inline',
    margin: 0,
    [breakpoints.up('sm')]: {
      display: 'block',
    },
    [breakpoints.up('xl')]: {
      display: 'inline-block',
    },
  },
  actionButton: {
    display: 'flex',
    maxWidth: 288,
    margin: '0 auto',
  },
}));
