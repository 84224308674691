import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    position: 'fixed',
    zIndex: 100,
    top: 0,
    left: 0,
    width: '100%',
    background: palette.common.white,
    boxShadow: '0px 4px 6px -1px rgba(26, 32, 44, 0.1), 0px 2px 4px -1px rgba(26, 32, 44, 0.06)',
    overflow: 'visible',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    padding: '32px 0 16px',
  },
}));
