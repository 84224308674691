import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReactiveVar } from '@apollo/client';

import checkPointsByPackageUrl from '../../../../../assets/images/how-to/check-points-by-package.svg';
import bonusPackagesUrl from '../../../../../assets/images/how-to/bonus-packages.svg';
import buyFittedLensesUrl from '../../../../../assets/images/how-to/buy-fitted-lenses.svg';
import { HowTo } from '../../../../common/how-to';
import { Button } from '../../../../common/button';
import { setIsFreepacks } from '../../../../../graphql/configuration/configuration.cache';
import { IHowToCard } from '../../../../common/how-to/how-to-card';

export const HowToSpendPoints = (): JSX.Element => {
  const intl = useIntl();
  const isFreepacksEnabled = useReactiveVar(setIsFreepacks);
  const discountsDescription = isFreepacksEnabled
    ? 'pointsHowTo.spendPoints.bonusPackages.description.withFreepacks'
    : 'pointsHowTo.spendPoints.bonusPackages.description';

  const discounts: IHowToCard = {
    imageSource: bonusPackagesUrl,
    description: intl.formatMessage(
      { id: discountsDescription },
      {
        sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
        nonBreakSpace: <>&nbsp;</>,
      },
    ),
  };
  const freepacks: IHowToCard = {
    imageSource: checkPointsByPackageUrl,
    description: intl.formatMessage(
      { id: 'pointsHowTo.spendPoints.checkPointsByPackage.description' },
      {
        sup: (chunks: React.ReactNode) => <sup>{chunks}</sup>,
        pointsAmountLink: (
          chunks: React.ReactNode,
        ) => <a
          href={intl.formatMessage({ id: 'common.myAcuvuePolicy' })}
          target="_blank"
        >{chunks}</a>,
      },
    ),
  };
  const barcode: IHowToCard = {
    imageSource: buyFittedLensesUrl,
    description: <FormattedMessage id="pointsHowTo.spendPoints.spendByBarcode.description" />,
  };

  const howToItems: IHowToCard[] = isFreepacksEnabled
    ? [discounts, freepacks, barcode]
    : [discounts, barcode];

  return (
    <HowTo
      title={<FormattedMessage id="pointsHowTo.spendPoints.title" />}
      items={howToItems}
      footer={(
        <Button
          variant="contained"
          href={intl.formatMessage({ id: 'common.phoneNumber.support.href' })}
        >
          <FormattedMessage id="common.phoneNumber.support" />
        </Button>
      )}
    />
  );
};
