import { IPoint } from '../../graphql/lenses/models/get-lenses.models';

export const getPoints = (packSize: number, points: IPoint[], productQuantity?: number): number => {
  let result: number = 0;
  const pointsObject = points.find((point) => point.packSize === packSize);

  if (pointsObject && productQuantity) {
    result = pointsObject.acuvuePoints * productQuantity;
  } else if (pointsObject) {
    result = pointsObject.acuvuePoints;
  }

  return result;
};
