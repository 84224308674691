import React, { forwardRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import FormattedDuration, { TIMER_FORMAT } from 'react-intl-formatted-duration';

import { Form, IFormImperativeHandleProps } from '../../../../../../../../common/form';
import { TextField } from '../../../../../../../../common/form/text-field';
import { Button } from '../../../../../../../../common/button';
import { useStyles } from './confirmation-code-step-form.styles';
import { IConfirmCodeStepFormProps } from './confirmation-code-step-form.models';

export const ConfirmationCodeStepForm = forwardRef<
IFormImperativeHandleProps, IConfirmCodeStepFormProps>(({
  onRequestCode,
  onSubmit,
  requestCodeTime,
  validationSchema,
  defaultValues,
}, ref): JSX.Element => {
  const classes = useStyles();
  const resultDefaultValues = useMemo(() => defaultValues || {
    otpCode: '',
  }, [defaultValues]);

  const isRequestCodeDisabled = Boolean(requestCodeTime);

  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      defaultValues={resultDefaultValues}
    >
      <TextField
        name="otpCode"
        fullWidth
        autoFocus
        label={(
          <FormattedMessage
            id="changePhone.confirmationCodeStep.confirmationCodeTextField.label"
          />
        )}
      />
      <Button
        variant="outlined"
        data-testid="request-another-code-button"
        disabled={isRequestCodeDisabled}
        fullWidth
        onClick={onRequestCode}
        classes={{
          root: classes.getNewCodeButtonRoot,
        }}
      >
        {isRequestCodeDisabled ? (
          <FormattedMessage
            id="changePhone.confirmationCodeStep.requestNewCodeInButton.label"
            values={{
              timeLeft: <FormattedDuration seconds={requestCodeTime} format={TIMER_FORMAT} />,
            }}
          />
        ) : (
          <FormattedMessage
            id="changePhone.confirmationCodeStep.requestNewCodeButton.label"
          />
        )}
      </Button>
    </Form>
  );
});
