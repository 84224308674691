import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontSizes, lineHeights, breakpoints,
}: Theme) => ({
  title: {
    marginTop: 48,
    textAlign: 'center',
    letterSpacing: 'normal',
  },
  description: {
    marginBottom: 68,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '& p': {
      margin: 0,
      fontSize: fontSizes.xs,
      lineHeight: lineHeights.sm,
      textAlign: 'center',
      [breakpoints.up('md')]: {
        fontSize: fontSizes.sm,
        lineHeight: lineHeights.md,
      },
    },
    '& p:first-child': {
      marginBottom: 20,
    },
  },
}));
