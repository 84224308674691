import React, { FC, useCallback } from 'react';
import { useModal } from 'mui-modal-provider';
import { FormattedMessage } from 'react-intl';

import { useBind } from '../../../../../../../hooks/use-bind';
import { useCancellablePromise } from '../../../../../../../hooks/use-cancellable-promise';
import { consumerApi } from '../../../../../../../rest/consumer';
import { Button } from '../../../../../../common';
import { DeleteProfileModal } from './delete-profile-modal';
import { useActionsInProgress } from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { removeTokenFromStorage } from '../../../../../../../utils/user';
import { isLoggedIn } from '../../../../../../../graphql/user/user.cache';

export const DeleteProfileAction: FC<{
  phoneNumber: string, onConfirm: () => void,
}> = ({ phoneNumber, onConfirm }): JSX.Element => {
  const { showModal } = useModal();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const phoneNumberBind = useBind(phoneNumber);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);

  const handleDeleteConfirmation = useCallback(() => {
    const { hide, update } = showModal(DeleteProfileModal, {
      onConfirm: async () => {
        update({
          isInProgress: true,
        });

        addActionInProgressBind.current();

        try {
          await makeCancellablePromiseBind.current(consumerApi.delete({
            phoneNumber: phoneNumberBind.current,
          }));

          removeActionInProgressBind.current();
          update({
            isInProgress: false,
          });
          hide();

          removeTokenFromStorage();
          isLoggedIn(false);

          onConfirm();
        } catch (error) {
          removeActionInProgressBind.current();

          if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
            // eslint-disable-next-line no-useless-return
            return;
          }

          update({
            isInProgress: false,
          });
        }
      },
    });
  }, [
    showModal,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    isLoggedIn,
    phoneNumberBind,
    addActionInProgressBind,
    removeActionInProgressBind,
    removeTokenFromStorage,
  ]);

  return (
    <Button
      fullWidth
      onClick={handleDeleteConfirmation}
      variant="contained"
    >
      <FormattedMessage id="profile.deleteProfileButton.label" />
    </Button>
  );
};
