import React, { useEffect, useRef } from 'react';
import { cx } from '@emotion/css';

import { DISTANCE_UNIT } from '../../../../../../../../constants/ecp-locator';
import { useStyles } from './store-detail.styles';
import { IStoreDetailProps } from './store-detail.models';
import { getRoundDistance } from '../../../../../../../../utils/order/get-round-distance';
import { useStoreByType } from '../../../../../../../../hooks/use-store-by-type';
import { roundRating } from '../../../../../../../../utils/ecp-locator/round-rating';
import {
  Button,
  Icon,
  IconSizes,
  IconTypes,
} from '../../../../../../../common';
import { scrollToTop } from '../../../../../../../../utils/scroll-to-top';
import { IconButton } from '../../../../../../../common/icon-button';
import { FormattedMessage } from 'react-intl';
import { IOrderStore } from '../../../order-create-page.models';

export const StoreDetail = ({
  storeData,
  onStoreClose,
  onStoreSubmit,
}: IStoreDetailProps): JSX.Element => {
  const classes = useStyles();
  const {
    name,
    rating,
    type,
    distance,
    street,
    openinghours,
    phonenumber,
  } = storeData;

  const titleRef = useRef<HTMLHeadingElement>(null);

  const handleStoreSubmit = () => {
    onStoreSubmit(storeData as IOrderStore);
  };

  useEffect(() => {
    titleRef.current?.focus();
    scrollToTop();

    return () => onStoreClose(storeData.code!);
  }, []);

  return (
    <div
      data-testid="store-detail"
      className={classes.root}
    >
      <IconButton
        iconProps={{
          type: IconTypes.close,
          size: IconSizes.md,
        }}
        onClick={() => onStoreClose(storeData.code!)}
        onKeyPress={() => onStoreClose(storeData.code!)}
        className={classes.closeIconButton}
        data-testid="store-detail-close"
      />
      <h3
        className={classes.title}
        data-testid="store-detail-title"
        ref={titleRef}
      >
        {name}
      </h3>
      <p data-testid="store-detail-rating-bar" className={classes.ratingWrapper}>
        {rating && (
          <span
            data-testid="store-detail-rating"
            className={cx(classes.pointSeparator, classes.rating)}
          >
            {roundRating(Number(rating)!)}
          </span>
        )}
        <span
          data-testid="store-detail-type"
          className={(distance || distance === 0) ? classes.pointSeparator : undefined}
        >
          {useStoreByType(type!)}
        </span>
        {distance && (
          <span data-testid="store-detail-distance">
            {`${getRoundDistance(distance)} ${DISTANCE_UNIT}`}
          </span>
        )}
      </p>
      <p data-testid="store-detail-address" className={classes.street}>
        {street && <Icon type={IconTypes.mapMarkerBlack} aria-hidden="true" />}
        {street}
      </p>
      <p
        data-testid="store-detail-hours"
        className={classes.hours}
      >
        {openinghours && (
          <Icon
            type={IconTypes.clockBlack}
            size={IconSizes.xs}
            aria-hidden="true"
          />
        )}
        {openinghours}
      </p>
      <p className={classes.phoneWrapper}>
        {phonenumber && (
          <Icon
            type={IconTypes.phoneBlack}
            size={IconSizes.xs}
            aria-hidden="true"
          />
        )}
        <a
          data-testid="store-detail-phone"
          href={`tel:${phonenumber}`}
          className={classes.phone}
        >
          {phonenumber}
        </a>
      </p>
      <Button
        type="button"
        variant="contained"
        data-testid="choose-ecp-button"
        fullWidth
        className={classes.submitButton}
        onClick={handleStoreSubmit}
      >
        <FormattedMessage id="order.create.addEcp.button.label" />
      </Button>
    </div>
  );
};
