import { ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IDiscountBonusModalProps } from './discount-bonus-modal.models';
import { useStyles } from './discount-bonus-modal.styles';
import { BonusModal } from '../bonus-modal';
import Discount300  from '../../../../../../assets/images/icons/discount/discount-300.svg';
import Discount500 from '../../../../../../assets/images/icons/discount/discount-500.svg';
import Discount1000 from '../../../../../../assets/images/icons/discount/discount-1000.svg';

export const DiscountBonusModal = ({
  value, isActivationMode, ...props
}: IDiscountBonusModalProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  const discountImages = {
    300: Discount300,
    500: Discount500,
    1000: Discount1000,
  };

  const steps = useMemo(
    () => {
      const idChunk = isActivationMode ? 'Not' : '';
      const id = `myPointsDetailed.modals.discountBonus${idChunk}ActiveModal.step1`;

      const firstStep = intl.formatMessage(
        { id },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      );

      const finalStep = <FormattedMessage
        id="myPointsDetailed.modals.discountBonusModal.step2"
        values={{
          amount: value,
        }}
      />;

      return [firstStep, finalStep];
    }, [isActivationMode, value],
  );

  return (
    <BonusModal
      {...props}
      data-testid={`discount-bonus-${isActivationMode ? '' : 'not-'}active-modal`}
      image={(
        <img
          src={discountImages[String(value) as keyof object]}
          alt=""
          className={classes.image}
        />
      )}
      steps={steps}
      title={intl.formatMessage(
        { id: 'myPointsDetailed.modals.discountBonusModal.title' },
        {
          span: (chunks: ReactNode) => <span className={classes.block}>{chunks}</span>,
          amount: value,
        },
      )}
    />
  );
};
