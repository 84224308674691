import React, { forwardRef, useMemo } from 'react';

import { useActionsInProgress } from '../../../../../../graphql/preloader/actions/actions-in-progress';
import { useCancellablePromise } from '../../../../../../hooks/use-cancellable-promise';
import { useFirstNameValidation } from '../../../../../../hooks/use-first-name-validation';
import { usePhoneNumberValidation } from '../../../../../../hooks/use-phone-number-validation';
import { consumerApi, IConsent } from '../../../../../../rest/consumer';
import { yup } from '../../../../../../services/yup';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { RegisterUserForm as RegisterUserFormView } from './register-user-form';
import { IRegisterUserFormProps, TValues } from './register-user-form.models';

export const RegisterUserForm = forwardRef<IFormImperativeHandleProps, IRegisterUserFormProps>(({
  defaultValues, onRequestSuccess,
}, ref): JSX.Element => {
  const { firstName, mobile, myAcuvueId, consent: consentFullFormat } = defaultValues;
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const firstNameValidation = useFirstNameValidation();
  const phoneNumberValidation = usePhoneNumberValidation();

  const handleSubmit = async (values: TValues) => {
    const consent = consentFullFormat
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .map(({ consent, date, value }: IConsent) => ({ consent, date, value }));

    addActionInProgress();

    try {
      await makeCancellablePromise(consumerApi.callbackRequest({
        ...values,
        myAcuvueId,
        consent,
      }));
      
      removeActionInProgress();
      onRequestSuccess();
    } catch (error) {
      removeActionInProgress();

      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }
    }
  };

  const defaultFormData = useMemo(() => ({
    name: firstName,
    mobile: mobile.slice(1),
  }), [firstName, mobile]);

  const validationSchema = useMemo(() => yup.object({
    name: firstNameValidation,
    mobile: phoneNumberValidation,
  }), [firstNameValidation]);

  return (
    <RegisterUserFormView
      ref={ref}
      defaultValues={defaultFormData}
      validationSchema={validationSchema }
      onSubmit={handleSubmit}
    />
  );
});
