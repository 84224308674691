import { FormattedMessage } from 'react-intl';

import { useStyles } from './activities.styles';
import { IActivitiesProps } from './activities.models';
import { getActivityOptions } from '../utils/get-activity-options';

export const Activities = ({ visionProfileData }: IActivitiesProps): JSX.Element => {
  const classes = useStyles();

  const {
    whatDoYouLikeCustom,
    whatDoYouLikePredefined,
  } = visionProfileData;

  const userActivities = () => {
    const customItem = whatDoYouLikeCustom ? [whatDoYouLikeCustom] : [];
    const predefinedItems = whatDoYouLikePredefined?.map((item) => (
      <FormattedMessage id={getActivityOptions(item)} />
    )) || [];

    return [...predefinedItems, ...customItem];
  };

  return (
    <ul className={classes.list} data-testid="activities-list">
      {
        userActivities().map((activity) => (
          <li
            key={typeof activity === 'string' ? activity : activity.props?.id}
            className={classes.listItem}
            data-testid="activities-list-item"
          >
            {activity}
          </li>
        ))
      }
    </ul>
  );
};
