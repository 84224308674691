import { NotificationType } from '../../../models/notification.models';

export interface IAppLevelModalsData {
  fittingByAuthId: IFittingsForSurvey[];
  notificationByAuthId: INotification[];
  pointsTransactionByAuthIdAndType: IPointsEdges;
}

export interface IFittingsForSurvey {
  id: string;
  isRated: boolean;
  isAutoFitted: boolean;
  consumerId: string;
  createdDateTime: string;
  store: IStore;
  fitType: FittingTypes;
}

export interface IStore {
  displayName: string;
}

export interface INotification {
  id: string;
  notificationType: NotificationType;
  activeOn: string;
  sourceObjectIds: string[];
  data: IData | null;
}

export interface IData {
  lockedUntil: string;
}

export interface IPointsEdges {
  edges: IPointsEdge[];
}

export interface IPointsEdge {
  node: IPointsNode;
}

export interface IPointsNode {
  points: number;
  transactionType: PointsTransactionType;
}

export enum PointsTransactionType {
  BIRTHDAY = 'BIRTHDAY',
}

export enum FittingTypes {
  controlVisit = 'CONTROL_VISIT',
  new = 'NEW',
}
