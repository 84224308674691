import { MouseEvent as MouseEventReact, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useStyles } from './pack-size-modal.styles';
import { IPackSizeModalProps } from './pack-size-modal.models';
import { ToggleButtonGroup } from '../../../../../../../common/toggle-button-group';
import { Modal, ResponsiveWidth } from '../../../../../../../common';
import { packSizeItems } from '../../form-select-items/pack-size-items';

export const PackSizeModal = ({
  packSizeActive,
  packSizes,
  onValueSelected,
  onClose,
  isMobile,
  position,
  isSolutionsBrand,
  ...props }: IPackSizeModalProps): JSX.Element => {
  const classes = useStyles();
  const [packSizeSelected, setPackSizeSelected] = useState<string>(String(packSizeActive));

  const handleChange = (event: MouseEventReact<HTMLElement>, value: string | null) => {
    if (value !== null) {
      setPackSizeSelected(value);
      onValueSelected(value);
    }
    onClose!();
  };

  const handleOnClick = () => {
    onClose!();
  };

  return (
    <Modal
      {...props}
      onClose={onClose}
      data-testid={isMobile ? 'pack-size-modal-mobile' : 'pack-size-modal'}
      responsiveWidth={ResponsiveWidth.lg}
      propsPaperStyles={isMobile ? classes.rootMobile : classes.root}
      propsContentStyles={isMobile ? undefined : classes.content}
      isCloseButtonDisabled={!isMobile}
      PaperProps={ { sx: isMobile ? undefined : { top: position?.top, left: position?.left } }}
      backdropStyles={isMobile ? undefined : classes.backdropRoot}
      content={(
        <>
          {isMobile && (
            <h2
              data-testid="pack-size-modal-title"
              className={classes.title}
            >
              <FormattedMessage id={isSolutionsBrand
                ? 'order.create.solutionsNumber.modal.title'
                : 'order.create.lensesNumber.modal.title'}
              />
            </h2>
          )}
          <ToggleButtonGroup
            withoutMargins
            exclusiveExactly
            standalone
            size="small"
            variant="outlined"
            options={packSizeItems(packSizes, !!isSolutionsBrand)}
            onChange={handleChange}
            value={packSizeSelected}
            selectedStyles={isMobile ? classes.selectedMobile : classes.selected}
            buttonStyles={isMobile ? classes.toggleButtonMobile : classes.toggleButton}
            onClick={handleOnClick}
            data-testid="pack-size-buttons"
            formControlStyles={isMobile ? undefined : classes.formControl}
          />
        </>
      )}
    />
  );
};
