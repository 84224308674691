import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { NameStepForm } from './name-step-form';
import { yup } from '../../../../../../services/yup';
import {
  useFirstNameValidation,
} from '../../../../../../hooks/use-first-name-validation';
import {
  useLastNameValidation,
} from '../../../../../../hooks/use-last-name-validation';
import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { IStepsState } from '../../registration.models';

export const NameStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  stepsState,
}, ref): JSX.Element => {
  const formRef = useRef<IFormImperativeHandleProps>(null);

  const firstNameValidation = useFirstNameValidation();
  const lastNameValidation = useLastNameValidation();

  const validationSchema = useMemo(() => yup.object({
    firstName: firstNameValidation,
    lastName: lastNameValidation,
  }), [firstNameValidation, lastNameValidation]);

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return formRef.current!.getValues();
    },
  }));

  return (
    <NameStepForm
      ref={formRef}
      onSubmit={onGoNextSuccess}
      validationSchema={validationSchema}
      defaultValues={(stepsState as IStepsState).name}
    />
  );
});
