import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { TextField } from '../../../../../../common/form/text-field';
import { IPhoneStepFormProps } from './phone-step-form.models';
import { Button } from '../../../../../../common/button-new';
import FormattedDuration, { TIMER_FORMAT } from 'react-intl-formatted-duration';

export const PhoneStepForm = forwardRef<IFormImperativeHandleProps, IPhoneStepFormProps>(({
  onSubmit,
  validationSchema,
  defaultValues,
  isTimerRunning,
  time,
}, ref): JSX.Element => {
  const buttonLabel = (!!time && isTimerRunning)
    ? <FormattedMessage
      id="registration.confirmationCodeStep.requestNewCodeInButton.label"
      values={{
        timeLeft: <FormattedDuration seconds={time} format={TIMER_FORMAT} />,
      }}
    />
    : <FormattedMessage id="resetPassword.phoneStep.nextButton.label" />;

  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      <TextField
        autoFocus
        name="username"
        type="tel"
        fullWidth
        label={(
          <FormattedMessage
            id="common.phone.label"
          />
        )}
      />
      <Button
        type="submit"
        style="dark"
        testId="request-code-timer-button"
        disabled={!!time && isTimerRunning}
        ariaLabel="resetPassword.phoneStep.nextButton.ariaLabel"
      >
        {buttonLabel}
      </Button>
    </Form>
  );
});

