import {
  IOrderPoints,
  IProduct,
} from '../../graphql/order/models/get-orders.models';
import {
  LensesBrandType,
  SolutionsBrandType,
} from '../../graphql/lenses/models/get-lenses.models';

const getPoints = (
  brandsPoints: IOrderPoints[],
  brandId: LensesBrandType | SolutionsBrandType,
  packSize: number,
): number => {
  let targetPoints: number = 0;
  const brandPoints = brandsPoints.find((brand) => brand.brandId === brandId)?.points;
  const brandPointsObject = brandPoints?.length ? brandPoints.find((points) => points.packSize === packSize) : null;

  if (brandPointsObject) {
    targetPoints = brandPointsObject.acuvuePoints;
  }

  return targetPoints;
};

export const getOrderPoints = (orderProducts: IProduct[], brandsPoints: IOrderPoints[]): number => {
  return orderProducts.reduce((
    acc, item,
  ) => acc + getPoints(brandsPoints, item.brand, item.packSize) * item.productQuantity, 0);
};
