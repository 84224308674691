import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ siteMediumWidth, breakpoints }: Theme) => ({
  root: {
    width: 'calc(100% - 3rem)',
    margin: '0 auto',
    maxWidth: siteMediumWidth,
    [breakpoints.up('sm')]: {
      marginBottom: '2rem',
    },
    '& img': {
      [breakpoints.up('sm')]: {
        borderRadius: '0.5rem',
      },
    },
  },
}));
