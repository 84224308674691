import { forwardRef, useMemo } from 'react';

import { IGadgetsUseDurationFormProps } from './gadgets-use-duration-form.models';
import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { RadioGroup } from '../../../../../../common/form/radio-group';
import {
  gadgetsUseDurationOptions as gadgetsUseDurationOptionsBase,
} from './gadgets-use-duration-options';

export const GadgetsUseDurationForm = forwardRef<
IFormImperativeHandleProps, IGadgetsUseDurationFormProps>(({
  formProps,
}, ref): JSX.Element => {
  const gadgetsUseDurationOptions = useMemo(() => gadgetsUseDurationOptionsBase, []);

  return (
    <Form
      ref={ref}
      {...formProps}
    >
      <RadioGroup
        name="gadgetsUseDuration"
        options={gadgetsUseDurationOptions}
      />
    </Form>
  );
});
