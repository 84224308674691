import React from 'react';

import { HowToCard } from './how-to-card';
import { IHowTo } from './how-to.models';
import { useStyles } from './how-to.styles';

export const HowTo = ({ title, items, footer }: IHowTo): JSX.Element => {
  const classes = useStyles();

  return (
    <section
      className={classes.root}
      data-testid="how-to"
    >
      <h3 className={classes.title}>
        {title}
      </h3>
      <ul className={classes.cardsList}>
        {items.map((item, itemIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className={classes.cardsItem} key={itemIndex}>
            <HowToCard {...item} />
          </li>
        ))}
      </ul>
      {footer && (
        <footer className={classes.footer}>
          {footer}
        </footer>
      )}
    </section>
  );
};
