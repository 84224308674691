import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette }: Theme) => ({
  actions: {
    display: 'flex',
    gap: '0.75rem',
  },
  resetLink: {
    color: palette.text.primary,
  },
}));
