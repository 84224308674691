import { FormattedMessage } from 'react-intl';

import { IParametersModalProps } from './change-parameters-modal.models';
import { Button, Modal, ResponsiveWidth } from '../../../../../../../common';
import { useStyles } from './change-parameters-modal.styles';
import { ChangeParametersForm } from '../change-parameters-form';
import React, { forwardRef } from 'react';
import { IFormImperativeHandleProps } from '../../../../../../../common/form';

export const ChangeParametersModal = forwardRef<IFormImperativeHandleProps, IParametersModalProps>(({
  parameters,
  brandParameters,
  defaultValues,
  onSubmitButtonClick,
  onParametersSubmit,
  onValueChange,
  axisValue,
  cylinderValue,
  ...props
}, ref): JSX.Element => {
  const classes = useStyles();

  return (
    <Modal
      {...props}
      responsiveWidth={ResponsiveWidth.mdWider}
      content={(
        <>
          <h2
            data-testid="change-params-modal-title"
            className={classes.title}
          >
            <FormattedMessage id="order.changeParamsModal.title" />
          </h2>
          <ChangeParametersForm
            ref={ref}
            brandParameters={brandParameters}
            parameters={parameters}
            defaultValues={defaultValues}
            onParametersSubmit={onParametersSubmit}
            onValueChange={onValueChange}
            axisValue={axisValue}
            cylinderValue={cylinderValue}
          />
        </>
      )}
      footer={(
        <Button
          fullWidth
          variant="contained"
          data-testid="change-params-button"
          onClick={onSubmitButtonClick}
        >
          <FormattedMessage
            id="order.changeParamsModal.button"
          />
        </Button>
      )}
    />
  );
});
