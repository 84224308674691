import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { isFunction, capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { cx } from '@emotion/css';

import { TButtonProps, IAdditionalProps } from './button.models';
import { useStyles } from './button.styles';
import { focusTargetId } from '../../../utils/focus-target-id';

export const Button = ({
  children, to, hideText, color, underline, isMarked, labelClassName, state, ...props
}: TButtonProps): JSX.Element => {
  const classes = useStyles(props);
  const additionalProps: IAdditionalProps = {};

  if (to) {
    if (isFunction(to)) {
      additionalProps.onClick = to;
    } else {
      if (typeof to !== 'string' && to?.hash) {
        const targetId = to?.hash?.slice(1);

        additionalProps.onClick = (e) => {
          e.preventDefault();
          focusTargetId(targetId);
        };
      }
      additionalProps.component = Link;
      additionalProps.to = to;
      additionalProps.state = state;
    }
  }

  return (
    <MuiButton
      {...props}
      {...additionalProps}
      disableElevation
      disableFocusRipple
      disableRipple
      classes={{
        root: cx(
          classes.root,
          classes[
            `rootColor${capitalize(color as string)}` as 'rootColorPrimary'
          ],
          { [classes.rootUnderline]: underline },
          { [classes.labelMarked]: isMarked },
          { [classes.normal]: labelClassName === 'normal' },
        ),
        contained: classes.contained,
        outlined: classes.outlined,
        text: classes.text,
        startIcon: cx(classes.startIcon, {
          [classes.startIconWithoutText]: hideText,
        }),
      }}
    >
      {!hideText && children}
    </MuiButton>
  );
};
