import { gql } from '@apollo/client';

export const GET_CALLBACK_VALUES = gql`
  query GetCallbackValuesQuery {
    profile {
      id
      myAcuvueId
      firstName
      mobile
      consent {
        consent
        date
        value
      }
    }
  }
`;
