import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette, fontSizes }: Theme) => ({
  dropDownMenu: {
    '&::after': {
      content: '">"',
      color: palette.common.black,
      fontFamily: 'Arial',
      fontSize: fontSizes.xl,
      fontWeight: 'normal',
      marginLeft: 7,
      transform: 'scale(1, 0.5) rotate(90deg)',
    },
  },
  dropDownMenuOpen: {
    '&::after': {
      transform: 'scale(1, 0.5) rotate(270deg)',
    },
  },
}));
