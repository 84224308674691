import { DeliveryStore } from '../delivery-store';
import { IDeliveryStoreNoEditProps } from './delivery-store-no-edit.models';

export const DeliveryStoreNoEdit = ({
  storeData, withBorder, isFulfilledOrder,
}: IDeliveryStoreNoEditProps): JSX.Element => {
  const {
    name,
    street,
    openinghours,
    phonenumber,
  } = storeData;

  return (
    <DeliveryStore
      name={name!}
      address={street}
      hours={openinghours}
      phoneNumber={phonenumber}
      withBorder={withBorder}
      isFulfilledOrder={isFulfilledOrder}
    />
  );
};
