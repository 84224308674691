import { ProgramItem } from '../program.model';
import rubleIcon from '../../../../../assets/images/gradient-round/ruble.svg';
import perCentIcon from '../../../../../assets/images/gradient-round/per-cent.svg';
import { ExternalLinks, InternalLinks } from '../../../../../constants';
import locationIcon from '../../../../../assets/images/gradient-round/location.svg';
import eyeIcon from '../../../../../assets/images/gradient-round/eye-checked.svg';
import lensesIcon from '../../../../../assets/images/gradient-round/lenses.svg';
import questionIcon from '../../../../../assets/images/gradient-round/question.svg';

export const items: ProgramItem[] = [
  {
    title: 'myAcuvueProgram.getPoints.title',
    content: [
      {
        list: [
          { text: 'myAcuvueProgram.getPoints.listItem-1' },
          { text: 'myAcuvueProgram.getPoints.listItem-2' },
          { text: 'myAcuvueProgram.getPoints.listItem-3' },
          { text: 'myAcuvueProgram.getPoints.listItem-4' },
          { text: 'myAcuvueProgram.getPoints.listItem-5' },
          { text: 'myAcuvueProgram.getPoints.listItem-6' },
        ],
      },
      { paragraph: { text: 'myAcuvueProgram.getPoints.paragraph-1' } },
    ],
    icon: rubleIcon,
  },
  {
    title: 'myAcuvueProgram.buyWithBenefits.title',
    content: [
      {
        list: [
          { text: 'myAcuvueProgram.buyWithBenefits.listItem-1' },
          { text: 'myAcuvueProgram.buyWithBenefits.listItem-2' },
        ],
      },
    ],
    icon: perCentIcon,
  },
  {
    title: 'myAcuvueProgram.buyLenses.title',
    content: [
      {
        list: [
          { text: 'myAcuvueProgram.buyLenses.listItem-1', url: InternalLinks.ecpLocator },
          { text: 'myAcuvueProgram.buyLenses.listItem-2', url: InternalLinks.onlineShops },
          { text: 'myAcuvueProgram.buyLenses.listItem-3' },
          { text: 'myAcuvueProgram.buyLenses.listItem-4' },
        ],
      },
    ],
    icon: locationIcon,
  },
  {
    title: 'myAcuvueProgram.fitLenses.title',
    content: [
      { paragraph: { text: 'myAcuvueProgram.fitLenses.paragraph-1' } },
      { paragraph: { text: 'myAcuvueProgram.fitLenses.paragraph-2', url: InternalLinks.ecpLocator } },
    ],
    icon: eyeIcon,
  },
  {
    title: 'myAcuvueProgram.myLenses.title',
    content: [
      { paragraph: { text: 'myAcuvueProgram.myLenses.paragraph-1' } },
      { paragraph: { text: 'myAcuvueProgram.myLenses.paragraph-2' } },
    ],
    icon: lensesIcon,
  },
  {
    title: 'myAcuvueProgram.faq.title',
    content: [
      { paragraph: { text: 'myAcuvueProgram.faq.paragraph-1' } },
      {
        list: [
          { text: 'myAcuvueProgram.faq.listItem-1' },
          { text: 'myAcuvueProgram.faq.listItem-2' },
          { text: 'myAcuvueProgram.faq.listItem-3', url: ExternalLinks.mailLink },
        ],
      },
    ],
    icon: questionIcon,
  },
];
