import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  policySection: {
    marginBottom: 40,
    [breakpoints.up('md')]: {
      marginBottom: 80,
    },
    [breakpoints.up('lg')]: {
      marginBottom: 128,
    },
  },
  title: {
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    margin: '0 0 12px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      marginBottom: 16,
    },
  },
  subTitle: {
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    margin: '12px 0 12px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.lg,
      lineHeight: lineHeights.xl,
      margin: '32px 0 32px',
    },
  },
  strongTitle: {
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    margin: 0,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  paragraph: {
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.regular,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    margin: '0 0 12px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  unorderedRulesList: {
    '& > li': {
      margin: '0 0 12px',
      '&::before': {
        content: '"– "',
      },
    },
    padding: 0,
    margin: 0,
    listStyleType: 'none',
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.regular,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  orderedRulesList: {
    '& > li': {
      marginBottom: 12,
    },
    listStyleType: 'lower-roman',
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.regular,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  strongText: {
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  link: {
    color: palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  paragraphGroup: {
    marginBottom: 12,
  },
  paragraphInline: {
    display: 'inline',
    margin: 0,
  },
}));
