import { HTMLAttributes, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { subYears } from 'date-fns';

import { IFormImperativeHandleProps } from '../../../../../common/form';
import { VpUpdateModal } from '../../../../../common/vp-update-modal';
import { IBirthdayModalProps } from './birthday-modal.models';
import { UPDATE_VISION_PROFILE } from '../../../../../../graphql/profile/mutations';
import { GET_VISION_PROFILE } from '../../../../../../graphql/profile/queries/get-vision-profile';
import { useCancellablePromise } from '../../../../../../hooks/use-cancellable-promise';
import { yup } from '../../../../../../services/yup';
import { useActionsInProgress } from '../../../../../../graphql/preloader/actions/actions-in-progress';
import { toDate } from '../../../../../../utils/date/to-date';
import { convertDateToString } from '../../../../../../utils/date/convert-date-to-string';
import { TFormValues } from '../../vision-profile-views/birthday-view/birthday-view-form';
import { promiseErrorCallbacks } from '../../../../../../utils/promise/set-promise-error-callbacks';
import { BirthdayTitle, BirthdayView } from '../../vision-profile-views/birthday-view';
import { IVisionProfileData } from '../../../../../../graphql/profile/models/get-vision-profile.models';

export const BirthdayModal = ({
  dateOfBirth: dateOfBirthCurrent, onClose, ...props
}: IBirthdayModalProps): JSX.Element => {
  const changeBirthdayFormRef = useRef<IFormImperativeHandleProps>(null);
  const intl = useIntl();
  const { updateQuery: updateBirthdayQuery } = useQuery<IVisionProfileData>(GET_VISION_PROFILE);
  const [updateBithday] = useMutation(UPDATE_VISION_PROFILE);
  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const maxDateOfBirth = useMemo(() => toDate(subYears(new Date(), 18)), [])!;

  const defaultValues = useMemo(() => ({ dateOfBirth: dateOfBirthCurrent }), []);

  const validationSchema = useMemo(
    () => yup
      .object({
        dateOfBirth: yup
          .date()
          .nullable()
          .required(intl.formatMessage({ id: 'vp.dateOfBirth.birthdayInput.errors.required' }))
          .test('current-birthday-value-compare',
            intl.formatMessage({ id: 'vp.dateOfBirth.birthdayInput.errors.sameDate' }),
            (value) => convertDateToString(value!) !== convertDateToString(dateOfBirthCurrent!),
          )
          .max(
            maxDateOfBirth,
            intl.formatMessage({ id: 'profile.dateOfBirthTextField.errors.max' }),
          ),
      }),
    [intl],
  );

  const handleSubmit = () => {
    if (changeBirthdayFormRef.current) {
      changeBirthdayFormRef.current.submit();
    }
  };

  const handleBirthdayChange = async ({ dateOfBirth }: TFormValues) => {
    if (!dateOfBirth) {
      return;
    }

    addActionInProgress();

    try {
      const { data: { patchVisionProfile } } = await makeCancellablePromise(
        updateBithday({
          variables: {
            visionProfile: {
              dateOfBirth: convertDateToString(dateOfBirth),
            },
          },
        }),
      );

      updateBirthdayQuery((prevState) => ({
        visionProfile: {
          ...prevState.visionProfile,
          ...patchVisionProfile,
        },
      }));

      onClose!();
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    }

    removeActionInProgress();
  };

  return (
    <VpUpdateModal
      {...props}
      PaperProps={{
        'data-testid': 'vp-birthday-modal',
      } as HTMLAttributes<HTMLDivElement>}
      title={<BirthdayTitle />}
      content={(
        <BirthdayView
          ref={changeBirthdayFormRef}
          canUpdateDateOfBirth
          formProps={{
            onSubmit: handleBirthdayChange,
            defaultValues,
            validationSchema,
            reValidateMode: 'onChange',
          }}
        />
      )}
      onVPUpdate={handleSubmit}
      onClose={onClose}
    />
  );
};
