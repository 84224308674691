import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    background: 'linear-gradient(to right, #5bb8fe, #99d1ff)',
    width: '100%',
    minHeight: '100vh',
    [breakpoints.up('sm')]: {
      background: 'linear-gradient(to right, #74c1ff, #cce8ff)',
    },
    '& img': {
      display: 'block',
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
    },
  },
}));
