import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { yup } from '../services/yup';

export const useOtpCodeValidation = () => {
  const intl = useIntl();

  return useMemo(
    () => yup.string()
      .trim()
      .required(intl.formatMessage({ id: 'common.errors.required' })),
    [intl],
  );
};
