export interface ISendPinCodeData {
  username: string;
}

export interface ISendPinCodeResponse {
  code: string;
}

export interface IConfirmPinCodeData {
  username: string;
  code: string;
}

export interface IConfirmPinCodeResponse {
  response: string;
}

export interface IUpdatePasswordData {
  username: string;
  code: string;
  password: string;
}

export interface IUpdatePasswordResponse {
  response: string;
}

export interface IChangePasswordData {
  phoneNumber: string;
  oldPin: string;
  newPin: string;
}

export interface IChangePasswordResponse {
  message: string;
  statusCode: string;
}

export interface IChangePhoneData {
  phoneNumber: string;
  newPhoneNumber: string;
}

export interface IChangePhoneResponse {
  message: string;
}

export interface IChangePhoneConfirmData {
  phoneNumber: string;
  newPhoneNumber: string;
  otpCode: string;
}

export interface IConsent {
  consent: string;
  date: Date;
  value: boolean;
}

export interface IRegistrationData {
  username: string;
  pin: string;
  firstName: string;
  lastName: string;
  email: string;
  clExperience: string;
  consent: IConsent[];
  commPermissions: string[];
}

export interface IRegistrationResponse {
  id: string;
  tags: string[];
  type: string;
  email: string;
  groups: string[];
  mobile: string;
  status: string;
  userId: string;
  consent: IConsent;
  version: number;
  firstName: string;
  lastName: string;
  systemId: string;
  username: string;
  watchers: string[];
  createdBy: string;
  migrations: string[];
  myAcuvueId: number;
  clExperience: string;
  lastModifiedBy: string;
  commPermissions: string[];
  createdDataTime: Date;
  lastModifiedDateTime: Date;
  relatedObjectIds: string[];
}

export interface IDeleteConsumerData {
  phoneNumber: string;
}

export interface IDeleteConsumerResponse {
  status: string;
}

export interface ICallbackRequestData {
  myAcuvueId?: string;
  name: string;
  mobile: string;
  consent: IConsent[];
}

export interface ICallbackRequestResponse {
  status: number;
  statusText: string;
  config: object;
  request: object;
  headerws: object;
}

export enum RecaptchaActions {
  registration = 'registration',
  resetPassword = 'resetPassword',
  login = 'login',
}
