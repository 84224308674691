import { EcpLocatorRequestUrls } from './ecp-locator.request-urls';
import { restClient } from '../rest-client';
import { IEcpLocatorData, TInternetShopResponse, TEcpLocatorResponse } from './ecp-locator.models';

export const ecpLocatorApi = {
  getStores(data: IEcpLocatorData) {
    return restClient.post<TEcpLocatorResponse>(EcpLocatorRequestUrls.ecpLocator, data);
  },
  getInternetShops() {
    return restClient.get<TInternetShopResponse>(EcpLocatorRequestUrls.internetShops);
  },
};
