import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { cx } from '@emotion/css';

import { Header } from './header';
import { Footer } from '../../../common/footer';
import { useStyles } from './layout-primary.styles';
import { scrollIntoView } from '../../../../utils/scroll/scroll-into-view';
import { GET_IS_USER_LOGGED_IN } from '../../../../graphql/user/queries';
import { LayoutPrimaryProps } from './layout-primary.models';

export const LayoutPrimary = ({ isBackground }: LayoutPrimaryProps): JSX.Element => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);

  useEffect(() => {
    scrollIntoView(ref.current);
  }, [pathname]);

  return (
    <div className={classes.root} ref={ref}>
      <div className={cx(classes.headerAndContentWrapper,
        { [classes.secondary]: isBackground },
      )}>
        <Header isLoggedIn={isLoggedIn} />
        <Outlet />
      </div>
      <div className={classes.footerWrapper}>
        <Footer />
      </div>
    </div>
  );
};
