import { useModal } from 'mui-modal-provider';
import { useQuery } from '@apollo/client';

import { VisionProfileBanner } from '../../user-activity-items/vision-profile-banner';
import { IVisionProfileData } from '../../../../../graphql/profile/models/get-vision-profile.models';
import { GET_VISION_PROFILE } from '../../../../../graphql/profile/queries/get-vision-profile';
import { useVisionProfileData, VPStatus } from '../../../../../hooks/use-vision-profile-data';
import { PreSurveyModal } from '../../vision-profile';

export const VisionProfile = (): JSX.Element => {
  const { data } = useQuery<IVisionProfileData>(GET_VISION_PROFILE);
  const { showModal } = useModal();
  const { status, surveySteps } = useVisionProfileData(data);

  const handleButtonClick = () => {
    showModal(PreSurveyModal, { surveySteps });
  };

  return (
    <>
      {status === VPStatus.INCOMPLETE && (
        <VisionProfileBanner
          onButtonClick={handleButtonClick}
        />
      )}
    </>
  );
};
