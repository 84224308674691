import { cx } from '@emotion/css';

import { useStyles } from './campaign-icon.styles';
import { ICampaignIconProps } from './campaign-icon.models';

export const CampaignIcon = ({ externalStyles }: ICampaignIconProps): JSX.Element => {
  const classes = useStyles();

  return (
    <span
      data-testid="campaign-wrapper"
      aria-hidden="true"
      className={cx(classes.campaign, externalStyles)}
    />
  );
};
