import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './complete-survey-modal.styles';
import {
  ReactComponent as SurveyCompletedIcon,
} from '../../../../../../assets/images/icons/vision-profile/survey-done.svg';
import { CompleteStepProps } from './complete-survey-modal.models';
import { Modal } from '../../../../../common';
import { Button } from '../../../../../common/button-new';
import { InternalLinks } from '../../../../../../constants';

export const CompleteSurveyModal = ({ onConfirm, ...props }: CompleteStepProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleSimpleClose = () => {
    onConfirm();
    navigate(InternalLinks.visionProfile);
  };

  return (
    <Modal
      {...props}
      onClose={handleSimpleClose}
      content={(
        <>
          <div
            data-testid="complete-step-icon"
            className={classes.icon}
            aria-hidden="true"
          >
            <SurveyCompletedIcon />
          </div>
          <h2
            data-testid="complete-step-title"
            className={classes.title}
          >
            <FormattedMessage id="vp.surveyDone.title" />
          </h2>
          <div
            data-testid="complete-step-description"
            className={classes.descriptionWrapper}
          >
            {intl.formatMessage(
              { id: 'vp.surveyDone.description' },
              { p: (chunks: ReactNode) => <p className={classes.description}>{chunks}</p> },
            )}
          </div>
          <Button
            style="dark"
            onClick={handleSimpleClose}
          >
            {intl.formatMessage(
              { id: 'vp.surveyDone.button' },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </Button>
        </>
      )}
    />
  );
};
