import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ breakpoints, palette, fontSizes, fontWeights }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    padding: '10px 28px 10px 10px',
    border: `2px dashed ${palette.primary.main}`,
    borderRadius: 16,
    background: palette.common.white,
    boxShadow: `0 0 12px 0 ${palette.primary.main}`,
    [breakpoints.up('md')]: {
      gap: 30,
      padding: '30px 40px 30px 30px',
      borderWidth: 3,
      borderRadius: 32,
      boxShadow: `0 0 16px 0 ${palette.primary.main}`,
    },
  },
  iconWrapper: {
    width: 66,
    height: 66,
    [breakpoints.up('md')]: {
      width: 160,
      height: 160,
    },
    '& img': {
      width: '100%',
      objectFit: 'contain',
    },
  },
  item: {
    color: palette.info.dark,
    fontSize: fontSizes.md,
    fontWeight: fontWeights.medium,
    [breakpoints.up('md')]: {
      fontSize: 44,
    },
    '& sup': {
      fontSize: '60%',
    },
  },
  newLineAll: {
    display: 'block',
  },
  newLine: {
    [breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));
