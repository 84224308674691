import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  palette, fontSizes, fontFamilies, breakpoints,
}: Theme) => ({
  root: {
    position: 'relative',
    marginLeft: -32,
    marginRight: -32,
    marginTop: -12,
    marginBottom: -32,
    overflowY: 'auto',
    [breakpoints.up('md')]: {
      marginLeft: -48,
      marginRight: -48,
      marginTop: 0,
      marginBottom: -64,
    },
  },
  rootMapView: {
    height: 'calc(100vh - 216px)',
    [breakpoints.up('sm')]: {
      height: 'calc(100vh - 232px)',
    },
    [breakpoints.up('md')]: {
      height: 'calc(100vh - 334px)',
    },
    [breakpoints.up('lg')]: {
      height: 'calc(100vh - 370px)',
    },
  },
  rootListView: {
    height: 'calc(100vh - 236px)',
    margin: 0,
    marginTop: -24,
    paddingTop: 0,
    [breakpoints.up('sm')]: {
      height: 'calc(100vh - 252px)',
    },
    [breakpoints.up('md')]: {
      height: 'calc(100vh - 376px)',
    },
    [breakpoints.up('lg')]: {
      height: 'calc(100vh - 410px)',
    },
  },
  rootStoreDetail: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  searchWrapper: {
    position: 'absolute',
    top: 8,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1,
    width: 'calc(100% - 16px)',
  },
  toggleView: {
    position: 'absolute',
    left: '50%',
    bottom: 40,
    transform: 'translateX(-50%)',
    width: 190,
    margin: '0 auto',
    zIndex: 1,
  },
  toggleViewButton: {
    whiteSpace: 'nowrap',
  },
  searchInputRoot: {
    minWidth: 'unset',
    border: `1px solid ${palette.grey['400']}`,
    borderRadius: 60,
    boxShadow: `0 4px 6px -1px ${palette.custom.shadowPrimary},
      0 2px 4px -1px rgba(26, 32, 44, 0.06)`,
    '&&': {
      padding: 0,
    },
    '&::before, &::after': {
      display: 'none',
    },
    '& fieldset': {
      display: 'none',
    },
  },
  searchInput: {
    boxSizing: 'border-box',
    height: 40,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.sm,
    lineHeight: 1,
    '&&&': {
      padding: '10px 16px',
    },
    '&::placeholder': {
      color: palette.grey['700'],
    },
  },
  searchInputEndAdornment: {
    right: 10,
  },
  modalContent: {
    marginBottom: 16,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      paddingLeft: 44,
      paddingRight: 44,
    },
  },
  modalTitle: {
    textAlign: 'center',
    [breakpoints.up('md')]: {
      paddingLeft: 44,
      paddingRight: 44,
    },
  },
  iconWrapper: {
    display: 'block',
    margin: '0 auto',
    '& svg': {
      width: 120,
      height: 120,
    },
  },
}));
