import { ISolutionsErrorModalProps } from './solutions-error-modal.models';
import { Button, Modal, ResponsiveWidth, Icon, IconTypes } from '../../../../../../common';
import { useStyles } from './solutions-error-modal.styles';

export const SolutionsErrorModal = ({
  onClose,
  title,
  content,
  button,
  buttonAriaLabel,
  onButtonClick,
  ...props
}: ISolutionsErrorModalProps): JSX.Element => {
  const classes = useStyles();

  const handleClose = () => {
    onClose!();
  };

  const handleButtonClick = () => {
    onButtonClick!();
  };

  return (
    <Modal
      {...props}
      onClose={handleClose}
      topIcon={(
        <span
          data-testid="icon-wrapper"
          aria-hidden="true"
          className={classes.icon}
        >
         <Icon
           type={IconTypes.solutionsCart}
         />
        </span>
      )}
      title={title}
      titleStyles={classes.title}
      responsiveWidth={ResponsiveWidth.mdWide}
      content={content}
      propsContentStyles={classes.content}
      footer={(
        <Button
          variant="contained"
          fullWidth
          onClick={onButtonClick ? handleButtonClick : handleClose}
          data-testid="solutions-error-button"
          aria-label={buttonAriaLabel}
        >
          {button}
        </Button>
      )}
    />
  );
};
