import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SortingTypes } from '../ecp-sort.models';

export const useSortButtons = () => {
  const intl = useIntl();

  return [{
    value: SortingTypes.distance,
    children: (
      <span aria-label={intl.formatMessage({ id: 'ecp.sortButton.distance.ariaLabel' })}>
        <FormattedMessage
          id="ecp.sortButton.distance.label"
        />
      </span>
    ),
  }, {
    value: SortingTypes.rating,
    children: (
      <span aria-label={intl.formatMessage({ id: 'ecp.sortButton.rating.ariaLabel' })}>
        <FormattedMessage
          id="ecp.sortButton.rating.label"
        />
      </span>
    ),
  }];
};
