import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  palette, fontFamilies, fontSizes, fontWeights, lineHeights, breakpoints,
}: Theme) => ({
  description: {
    margin: '0 0 24px',
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.regular,
    [breakpoints.up('md')]: {
      margin: '0 0 32px',
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
}));
