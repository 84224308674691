import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  fontWeights, fontSizes, lineHeights, breakpoints, palette,
}: Theme) => ({
  root: {
    marginTop: 42,
  },
  rootWithBottomMargin: {
    marginBottom: 24,
    [breakpoints.up('sm')]: {
      marginBottom: 56,
    },
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    [breakpoints.up('sm')]: {
      marginBottom: 24,
    },
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: fontSizes.md,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.lg,
    },
  },
  titleSmall: {
    marginTop: 0,
    marginBottom: 4,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      marginBottom: 8,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  inlineWrapper: {
    '& p': {
      display: 'inline',
      marginTop: 0,
      marginBottom: 0,
      color: palette.custom.greyNeutral,
      fontSise: fontSizes.xxs,
      lineHeight: 1.5,
      [breakpoints.up('sm')]: {
        marginBottom: 8,
      },
    },
  },
  paragraphWithMargin: {
    marginTop: 0,
    marginBottom: 4,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      marginBottom: 8,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  linkNoUnderline: {
    color: palette.primary.dark,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
