import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useStyles } from './personal-info-card.styles';
import { Card } from '../../../../../../common/card';
import { IPersonalInfoCardModels } from './personal-info-card.models';
import { Button, Icon, IconSizes } from '../../../../../../common';

export const PersonalInfoCard = ({
  title, description, icon, buttonLabel, onItemClick,
}: IPersonalInfoCardModels): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card className={classes.root}>
      <div className={classes.content}>
        <h3
          data-testid="vision-profile-item-title"
          className={classes.title}
        >
          <FormattedMessage id={title} />
        </h3>
        <p
          data-testid="vision-profile-item-description"
          className={classes.description}
        >
          {description}
        </p>
      </div>
      <Button
        aria-label={intl.formatMessage({ id: buttonLabel })}
        variant="text"
        data-testid="vision-profile-item-button"
        className={classes.button}
        onClick={onItemClick}
      >
        <span>
          <Icon type={icon} size={isMobile ? IconSizes.md : IconSizes.lg} />
        </span>
      </Button>
    </Card>
  );
};
