import { IconTypes } from '../../components/common';
import { OrderStatus } from '../../graphql/order/models/get-orders.models';

export const getStatusIcon = (
  status: string,
): IconTypes | undefined => {
  let orderIcon: IconTypes;

  switch (status) {
    case OrderStatus.fulfilled:
      orderIcon = IconTypes.checkedBlack;
      break;
    case OrderStatus.confirmed:
      orderIcon = IconTypes.checked;
      break;
    case OrderStatus.processing:
      orderIcon = IconTypes.truck;
      break;
    case OrderStatus.canceled:
    case OrderStatus.canceledByPatient:
      orderIcon = IconTypes.cancel;
      break;
    default:
      orderIcon = IconTypes.clock;
  }

  return orderIcon;
};
