import { AxiosRequestConfig } from 'axios';
import { ConsumerRequestUrls } from './consumer.request-urls';
import { restClient, restClientRecaptcha } from '../rest-client';
import {
  IChangePasswordData,
  IChangePasswordResponse,
  IChangePhoneData,
  IChangePhoneResponse,
  IChangePhoneConfirmData,
  IRegistrationData,
  IRegistrationResponse,
  IDeleteConsumerData,
  IDeleteConsumerResponse,
  ICallbackRequestData,
  ICallbackRequestResponse,
  ISendPinCodeData,
  ISendPinCodeResponse,
  IConfirmPinCodeData,
  IConfirmPinCodeResponse,
  IUpdatePasswordData,
  IUpdatePasswordResponse,
} from './consumer.models';
import { getAuthorizationHeader } from '../../utils/request-headers/get-authorization-header';

export const consumerApi = {
  sendPinCode(data: ISendPinCodeData) {
    return restClient.post<ISendPinCodeResponse>(ConsumerRequestUrls.pinCodeSend, data);
  },
  sendPinCodeRecaptcha(data: ISendPinCodeData, token: string) {
    return restClientRecaptcha(token).post<ISendPinCodeResponse>(ConsumerRequestUrls.pinCodeSend, data);
  },
  confirmPinCode(data: IConfirmPinCodeData) {
    return restClient.post<IConfirmPinCodeResponse>(ConsumerRequestUrls.pinCodeConfirm, data);
  },
  updatePassword(data: IUpdatePasswordData) {
    return restClient.post<IUpdatePasswordResponse>(ConsumerRequestUrls.updatePassword, data);
  },
  changePassword(data: IChangePasswordData) {
    return restClient.post<IChangePasswordResponse>(ConsumerRequestUrls.updatePin, data, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
  },
  changePhoneNumber(data: IChangePhoneData) {
    return restClient.post<IChangePhoneResponse>(ConsumerRequestUrls.updatePhone, data, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
  },
  confirmPhoneNumber(data: IChangePhoneConfirmData) {
    return restClient.post<IRegistrationResponse>(ConsumerRequestUrls.confirmPhone, data, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
  },
  register(data: IRegistrationData, config: AxiosRequestConfig) {
    return restClient.post<IRegistrationResponse>(ConsumerRequestUrls.register, data, config);
  },
  delete(data: IDeleteConsumerData) {
    return restClient.delete<IDeleteConsumerResponse>(ConsumerRequestUrls.disable, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      data,
    });
  },
  callbackRequest(data: ICallbackRequestData) {
    return restClient.post<ICallbackRequestResponse>(ConsumerRequestUrls.callbackRequest, data);
  },
};
