import { useModal } from 'mui-modal-provider';

import { BonusChangeButton as BonusChangeButtonView } from './bonus-change-button';
import { ChangeBonusWarningModal } from '../change-bonus-warning-modal';
import { IBonusChangeButtonContainerProps } from './bonus-change-button.models';

export const BonusChangeButton = ({
  onBonusSelect, bonusId, bonusValue, bonusType, bonusesData, isEditOrder,
}: IBonusChangeButtonContainerProps): JSX.Element => {
  const { showModal } = useModal();

  const handleBonusChange = () => {
    showModal(ChangeBonusWarningModal, {
      onBonusSelect,
      bonusId,
      bonusValue,
      bonusType,
      bonusesData,
      isEditOrder,
    });
  };

  return (
    <BonusChangeButtonView
      onBonusChange={handleBonusChange}
    />
  );
};
