import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import wave from '../../../../assets/images/brand-pages/wave.png';
import waveMobile from '../../../../assets/images/brand-pages/wave-mobile.png';
import gradient from '../../../../assets/images/brand-pages/gradient.png';

export const useStyles = makeStyles(
  ({ fontSizes, fontFamilies, fontWeights, breakpoints, palette }: Theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '1.5rem',
      backgroundColor: '#09114a',
      [breakpoints.up('sm')]: {
        minHeight: '100vh',
        paddingTop: 0,
        background: `no-repeat #09114a url(${wave})`,
        backgroundSize: '100%',
      },
    },
    pageContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '5.75rem',
      paddingBottom: '3rem',
      overflow: 'hidden',
      background: `no-repeat url(${waveMobile})`,
      backgroundPositionY: 0,
      backgroundSize: '100% 100%',
      [breakpoints.up('sm')]: {
        zIndex: 1,
        paddingTop: '8.5rem',
        paddingBottom: '1.25rem',
        background: 'none',
      },
    },
    legal: {
      display: 'flex',
      flexDirection: 'column',
      padding: '2.5rem 1rem',
      [breakpoints.up('sm')]: {
        zIndex: 1,
        padding: '2.5rem 4.5rem',
      },
    },
    productClass: {
      width: '80%',
      height: 'auto',
      [breakpoints.up('sm')]: {
        width: '55%',
      },
    },
    imgBr: {
      width: '100px',
      height: '100px',
      margin: '15px',
      [breakpoints.down('sm')]: {
        width: '50px',
        height: '50px',
        margin: '5px',
      },
    },
    pupilImage: {
      position: 'absolute',
      top: '2%',
      right: '6%',
      width: '60px',
      height: '60px',
      backgroundColor: palette.common.white,
      borderRadius: '50%',
      zIndex: 2,
      [breakpoints.up('sm')]: {
        top: '5%',
        right: '15%',
        width: '126px',
        height: '126px',
      },
    },
    title: {
      fontFamily: fontFamilies.primary,
      maxWidth: '70%',
      margin: '0 0 2.25rem',
      color: palette.common.white,
      fontSize: fontSizes.lg,
      lineHeight: 1.04,
      fontWeight: fontWeights.medium,
      textAlign: 'center',
      [breakpoints.up('sm')]: {
        maxWidth: '735px',
        margin: '0 0 3.875rem',
        fontSize: fontSizes.large,
      },
    },
    description: {
      maxWidth: '80%',
      marginTop: '2.25rem',
      fontFamily: fontFamilies.primary,
      color: palette.common.white,
      fontSize: fontSizes.sm,
      lineHeight: 1.375,
      fontWeight: fontWeights.regular,
      textAlign: 'center',
      [breakpoints.up('sm')]: {
        fontSize: fontSizes.xl,
        maxWidth: '1050px',
      },
    },
    details: {
      margin: 0,
      color: palette.common.white,
      fontWeight: fontWeights.regular,
      fontFamily: fontFamilies.primary,
      fontSize: fontSizes.xxxs,
      textAlign: 'justify',
      [breakpoints.up('sm')]: {
        fontSize: fontSizes.sm,
      },
    },
    age: {
      margin: '1rem 0 0',
      fontWeight: fontWeights.regular,
      fontFamily: fontFamilies.primary,
      color: palette.common.white,
      fontSize: fontSizes.xxxs,
    },
    footer: {
      width: '100%',
      marginTop: 'auto',
      lineHeight: 0,
      backgroundColor: palette.common.white,
      '& img': {
        width: '100%',
      },
    },
    gradient: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '35%',
      background: `no-repeat url(${gradient})`,
      backgroundSize: 'cover',
    },
  }),
);
