import { FormattedMessage, useIntl } from 'react-intl';

import {
  Button,
  Icon,
  IconTypes,
  Modal,
  ResponsiveWidth,
} from '../../../../../../common';
import { ICallSupportProps } from './call-support-modal.models';
import { useStyles } from './call-support-modal.styles';

export const CallSupportModal = ({ onBlockUnload, onClose, ...props }: ICallSupportProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  const handleClose = () => {
    if (onBlockUnload) {
      onBlockUnload(true);
    }

    onClose!();
  };

  return (
    <Modal
      {...props}
      onClose={handleClose}
      data-testid="call-support-modal"
      topIcon={(
        <span
          data-testid="icon-wrapper"
          aria-hidden="true"
          className={classes.icon}
        >
         <Icon
           type={IconTypes.mobile}
         />
        </span>
      )}
      title={<FormattedMessage id="order.callSupport.modal.title" />}
      titleStyles={classes.title}
      responsiveWidth={ResponsiveWidth.mdWide}
      content={(
        <div
          data-testid="call-support-content"
          className={classes.content}
        >
          <p data-testid="call-support-content-first">
            <FormattedMessage id="order.callSupport.modal.description1" />
          </p>
          <p data-testid="call-support-content-second">
            <FormattedMessage id="order.callSupport.modal.description2" />
          </p>
        </div>
      )}
      footer={(
        <>
          <Button
            variant="outlined"
            fullWidth
            onClick={handleClose}
            data-testid="get-button"
            aria-label={intl.formatMessage({ id: 'order.callSupport.modal.getButton.ariaLabel' })}
          >
            <FormattedMessage id="order.callSupport.modal.getButton" />
          </Button>
          <a
            href={intl.formatMessage({ id: 'common.phoneNumber.support.href' })}
            className={classes.callButton}
            data-testid="call-button"
            aria-label={intl.formatMessage({ id: 'order.callSupport.modal.callButton.ariaLabel' })}
          >
            <FormattedMessage id="order.callSupport.modal.callButton" />
          </a>
        </>
      )}
    />
  );
};
