import { FormattedMessage } from 'react-intl';
import { ToggleButtonProps } from '@mui/material';

import { getIsFourEnd } from '../../utils/get-ending';

export const packSizeItems = (packSizes: number[], isSolutionsBrand: boolean): ToggleButtonProps[] => {
  return packSizes.map((packSize) => ({
    children: getIsFourEnd(Number(packSize)) ? (
      <FormattedMessage
        id={isSolutionsBrand ? 'order.create.solutionsNumber.unit' : 'order.create.lensesNumber.unit.four'}
        values={{
          nonBreakSpace: <>&nbsp;</>,
          value: packSize,
        }}
      />
    ) : (
      <FormattedMessage
        id={isSolutionsBrand ? 'order.create.solutionsNumber.unit' : 'order.create.lensesNumber.unit'}
        values={{
          nonBreakSpace: <>&nbsp;</>,
          value: packSize,
        }}
      />
    ),
    value: String(packSize),
  }));
};
