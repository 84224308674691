import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { IconTypes } from '../../../../../common/icon';
import { NoEntriesPlaceholder } from '../../../../../common/no-entries-placeholder';

export const FreepacksPlaceholder = (): JSX.Element => {
  const intl = useIntl();

  return (
    <NoEntriesPlaceholder
      data-testid="no-freepack-bonuses"
      iconType={IconTypes.giftBonus}
      description={intl.formatMessage(
        { id: 'userActivities.freepacks.placeholder.description' },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      )}
    />
  );
};
