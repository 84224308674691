import { isEqual } from 'lodash';

import { IFormSubmitValues } from '../../../../../../../common/form';
import { TFormValue } from '../add-lenses-step-form';

const getProducts = (
  formValues: IFormSubmitValues,
): TFormValue[] => {
  return (Object.values(formValues) as TFormValue[]).filter((product) => {
    return Number(product.productQuantity) > 1;
  });
};

export const compareFormValues = (
  firstFormValues: IFormSubmitValues, secondFormValues: IFormSubmitValues,
) => {
  const firstValues = getProducts(firstFormValues);
  const secondValues = getProducts(secondFormValues);

  return isEqual(firstValues, secondValues);
};
