import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as NotFoundImage } from '../../../../assets/images/not-found-title.svg';
import { useStyles } from './page-not-found.styles';
import { Button } from '../../../common/button';

export const PageNotFound = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      data-testid="not-found-page"
    >
      <div className={classes.contentWrapper}>
        <NotFoundImage
          className={classes.image}
          data-testid="not-found-page-image"
        />
        <p className={classes.primaryDescription}>
          <FormattedMessage
            id="notFoundPage.primaryDescription"
          />
        </p>
        <p className={classes.secondaryDescription}>
          <FormattedMessage
            id="notFoundPage.secondaryDescription"
          />
        </p>
        <Button
          to="/"
          className={classes.returnButton}
          variant="contained"
          data-testid="back-to-home-link"
        >
          <FormattedMessage
            id="notFoundPage.returnButton.label"
          />
        </Button>
      </div>
    </div>
  );
};
