import { IVisionProfileFields } from './get-vision-profile.models';
import { IProfileQueryData, IVisionProfileQueryData } from './profile.models';

export interface IConsumerProfileData extends 
  IProfileQueryData<IProfileData>, IVisionProfileQueryData<IVisionProfileBirthdayData> {}

export interface IVisionProfileBirthdayData extends
  Pick<IVisionProfileFields, 'dateOfBirth' | 'meta'> {}

export interface IProfileData {
  id: string;
  username: string;
  myAcuvueId: string;
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  mobile: string;
  commPermissions: NotificationsType[];
  consent: IConsent[];
}

export interface IConsent {
  consent: string;
  date: string;
  value: boolean;
}

export enum NotificationsType {
  email = 'EMAIL',
  sms = 'SMS',
  push = 'PUSH_NOTIFICATION',
}
