import { Tooltip as TooltipBase } from '@mui/material';
import { cx } from '@emotion/css';

import { ITooltipProps } from './tooltip.models';
import { useStyles } from './tooltip.styles';
import { TOUCH_ENTER_DELAY, LEAVE_TOUCH_DELAY } from '../../../constants/tooltip-options';

export const Tooltip = ({
  title,
  withArrow,
  placement,
  tooltipArrowClass,
  tooltipClass,
  children,
  rootStyles,
}: ITooltipProps): JSX.Element => {
  const classes = useStyles();

  return (
    <TooltipBase
      className={rootStyles}
      title={title}
      arrow={withArrow}
      placement={placement}
      enterTouchDelay={TOUCH_ENTER_DELAY}
      classes={{
        tooltip: cx(classes.tooltip, tooltipClass),
        arrow: cx(classes.tooltipArrow, tooltipArrowClass),
      }}
      leaveTouchDelay={LEAVE_TOUCH_DELAY}
    >
      {children}
    </TooltipBase>
  );
};
