import { ToggleButtonProps } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { WhatDoYouLike } from '../../../../../../../graphql/profile/models/get-vision-profile.models';

export const whatDoYouLikeOptions: ToggleButtonProps[] = [{
  children: (
    <FormattedMessage
      id="vp.whatDoYouLike.swimming"
    />
  ),
  value: WhatDoYouLike.swimming,
}, {
  children: (
    <FormattedMessage
      id="vp.whatDoYouLike.sport"
    />
  ),
  value: WhatDoYouLike.sport,
}, {
  children: (
    <FormattedMessage
      id="vp.whatDoYouLike.reading"
    />
  ),
  value: WhatDoYouLike.reading,
}, {
  children: (
    <FormattedMessage
      id="vp.whatDoYouLike.travel"
    />
  ),
  value: WhatDoYouLike.travel,
}, {
  children: (
    <FormattedMessage
      id="vp.whatDoYouLike.bicycle"
    />
  ),
  value: WhatDoYouLike.bicycle,
}, {
  children: (
    <FormattedMessage
      id="vp.whatDoYouLike.meetingWithFriends"
    />
  ),
  value: WhatDoYouLike.meetingWithFriends,
}, {
  children: (
    <FormattedMessage
      id="vp.whatDoYouLike.pcGames"
    />
  ),
  value: WhatDoYouLike.pcGames,
}, {
  children: (
    <FormattedMessage
      id="vp.whatDoYouLike.skiing"
    />
  ),
  value: WhatDoYouLike.skiing,
}, {
  children: (
    <FormattedMessage
      id="vp.whatDoYouLike.driving"
    />
  ),
  value: WhatDoYouLike.driving,
}, {
  children: (
    <FormattedMessage
      id="vp.whatDoYouLike.running"
    />
  ),
  value: WhatDoYouLike.running,
}, {
  children: (
    <FormattedMessage
      id="vp.whatDoYouLike.playingWithChildren"
    />
  ),
  value: WhatDoYouLike.playingWithChildren,
}];
