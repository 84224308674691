import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import mapWithMarkersMobile from '../../../../../assets/images/backgrounds/ecp-locator/map-with-markers-mobile.png';
import mapWithMarkers from '../../../../../assets/images/backgrounds/ecp-locator/map-with-markers.png';
import mapWithMarkersMobileCampaign from '../../../../../assets/images/backgrounds/ecp-locator/map-with-markers-mobile-campaign.png';
import mapWithMarkersCampaign from '../../../../../assets/images/backgrounds/ecp-locator/map-with-markers-campaign.png';

export const useStyles = makeStyles(({
  breakpoints, fontFamilies, fontWeights, fontSizes, lineHeights, palette,
}: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 32,
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      padding: 40,
    },
    [breakpoints.up('lg')]: {
      maxWidth: 1040,
      margin: '0 auto',
    },
  },
  container: {
    margin: '20px 0 23px',
    [breakpoints.up('sm')]: {
      margin: '40px 0 56px',
    },
  },
  sectionTitle: {
    margin: '0 0 4px',
    padding: '0 5px',
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      margin: '0 0 8px',
      padding: 0,
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      textAlign: 'left',
    },
  },
  description: {
    marginTop: 0,
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.regular,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.md,
      lineHeight: 1.3,
      textAlign: 'left',
    },
    '&:last-of-type': {
      marginBottom: 12,
      [breakpoints.up('sm')]: {
        marginBottom: 0,
      },
    },
  },
  button: {
    position: 'static',
    display: 'flex',
    width: '100%',
    marginTop: 20,
    padding: '18px 25px',
    border: `1px solid ${palette.text.primary}`,
    borderRadius: 60,
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
    textTransform: 'uppercase',
    [breakpoints.up('sm')]: {
      maxWidth: 174,
      marginTop: 24,
      padding: '22px 25px',
    },
    [breakpoints.up(893)]: { // fix absolute text position
      marginTop: 36,
    },
    '& span': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      padding: '32px 32px 40px',
      [breakpoints.up('sm')]: {
        justifyContent: 'flex-start',
        padding: '40px 40px 51px 66px',
      },
    },
  },
  content: {
    width: '100%',
    [breakpoints.up('sm')]: {
      width: 'calc(100% - 232px)',
    },
  },
  imgContainer: {
    width: '100%',
    height: 150,
    background: `no-repeat url(${mapWithMarkersMobile}) center`,
    backgroundSize: 'contain',
    [breakpoints.up(375)]: {
      height: 190,
    },
    [breakpoints.up(376)]: {
      height: 210,
      '@media (orientation: landscape)': {
        height: 350,
      },
    },
    [breakpoints.up('sm')]: {
      width: 152,
      height: 152,
      backgroundImage: `url(${mapWithMarkers})`,
      '@media (orientation: landscape)': {
        height: 152,
      },
    },
  },
  imgContainerCampaign: {
    background: `no-repeat url(${mapWithMarkersMobileCampaign}) center`,
    backgroundSize: 'contain',
    [breakpoints.up('sm')]: {
      backgroundImage: `url(${mapWithMarkersCampaign})`,
    },
  },
}));
