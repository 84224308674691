export interface IConsumerAppointments {
  allAppointmentsByAuthId: IAppointmentEdges;
}

export interface IAppointmentEdges {
  edges: IAppointmentNode[];
}

export interface IAppointmentNode {
  node: IAppointmentParams;
}

export interface IAppointmentParams {
  id: string;
  date: string;
  status: string;
  appointmentType: AppointmentType | null;
  store: IAppointmentStore;
  salesforceStoreInfo: IAppointmentStoreSalesforce;
  time: string | null;
}

export interface IAppointmentStore {
  id: string;
  displayName: string;
  storeType: AppointmentStoreType;
  storeSubType: AppointmentStoreSubType;
  phone: string;
  workingHours: string;
  address: string;
  latitude: string;
  longitude: string;
}

export enum AppointmentStoreType {
  retailer = 'RETAILER',
  fitter = 'FITTER',
  hybrid = 'HYBRID',
  clinic = 'CLINIC',
}

export enum AppointmentStoreSubType {
  retailerHybrid = 'RETAILER_HYBRID',
  clinicHybrid = 'CLINIC_HYBRID',
}

export interface IAppointmentStoreSalesforce {
  rating: string;
}

export enum AppointmentStatusType {
  unconfirmed = 'UNCONFIRMED',
  confirmed = 'CONFIRMED',
  cancelled = 'CANCELLED',
  fitted = 'FITTED',
}

export enum AppointmentType {
  controlVisit = 'CONTROL_VISIT',
  aec = 'ANNUAL_EYE_CHECK',
}
