import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontFamilies, fontWeights, fontSizes, palette,
}: Theme) => ({
  root: {
    '& .MuiTextField-root': {
      marginBottom: 0,
    },
  },
  highlighted: {
    fontWeight: fontWeights.medium,
  },
  clearIndicator: {
    marginTop: -2,
    '& path': {
      fill: palette.grey['600'],
    },
  },
  clearIndicatorShown: {
    visibility: 'visible',
  },
  inputError: {
    '&.MuiInput-underline:after': {
      borderBottomColor: palette.error.main,
    },
    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: palette.error.main,
      },
    },
  },
  helperText: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    color: palette.grey['500'],
  },
  secondaryText: {
    marginLeft: 5,
  },
  paper: {
    minWidth: 'unset',
    margin: '4px 0',
  },
  listItem: {
    listStyle: 'none',
    padding: '6px 16px',
    fontSize: fontSizes.sm,
    lineHeight: 1.5,
    '&:hover': {
      background: palette.grey.A400,
      cursor: 'pointer',
    },
    '&:active': {
      background: palette.grey.A700,
    },
  },
  list: {
    padding: 0,
  },
  fullWidth: {
    width: '100%',
  },
}));
