import { SyntheticEvent } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { useModal } from 'mui-modal-provider';
import { format } from 'date-fns';

import { IconSizes, IconTypes } from '../../../../../../../../common/icon';
import { IconButton } from '../../../../../../../../common/icon-button';
import { useStyles } from './compliance-period-field.styles';
import { CompliancePeriodModal } from './compliance-period-modal';
import { ICompliancePeriodFieldProps } from './compliance-period-field.models';
import { UI_DATE_FORMAT } from '../../../../../../../../../constants';

// TODO: change this constants to props when backend implements compliance period story
const BONUS_AMOUNT = 150;
const ADDITIONAL_BONUS = 500;

export const CompliancePeriodField = ({
  complianceDate, loyaltyPoints,
}: ICompliancePeriodFieldProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showModal } = useModal();
  const bonusAmount = BONUS_AMOUNT;
  const additionalBonus = ADDITIONAL_BONUS;
  const formattedComplianceDate = format(new Date(complianceDate), UI_DATE_FORMAT);

  const handleCPButtonClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    showModal(CompliancePeriodModal, { bonusAmount, additionalBonus, loyaltyPoints });
  };

  return (
    <div
      className={classes.root}
      data-testid="my-lenses-compliance-period"
    >
      <div
        className={classes.content}
        data-testid="compliance-period-content"
      >
        <div
          className={classes.bonusAmount}
          data-testid="compliance-period-bonus"
        >
          +{bonusAmount}
        </div>
        <p
          className={classes.description}
          data-testid="compliance-period-description"
        >
          <FormattedMessage
            id="myLensesPreview.compliancePeriod.description"
            values={{
              bonusAmount,
              endDate: formattedComplianceDate,
            }}
          />
        </p>
      </div>
      <IconButton
        disableFocusRipple
        iconProps={{
          type: IconTypes.info,
          size: isMobile ? IconSizes.sm : IconSizes.md,
        }}
        onClick={handleCPButtonClick}
        className={classes.button}
      />
    </div>
  );
};
