import campaigns from '../../../../../assets/images/reg-guide/discounts.svg';
import percent from '../../../../../assets/images/arl/percent.svg';
import bonuses from '../../../../../assets/images/arl/bonuses.svg';
import instantBonus from '../../../../../assets/images/arl/instant-bonus.svg';
import { ItemWithIcon } from '../../../../common/card';

export const appBenefits: ItemWithIcon[] = [{
  item: 'arl.app.benefits.item1',
  icon: <img alt="" src={percent} />,
}, {
  item: 'arl.app.benefits.item2',
  icon: <img alt="" src={campaigns} />,
}, {
  item: 'arl.app.benefits.item3',
  icon: <img alt="" src={bonuses} />,
}, {
  item: 'arl.app.benefits.item4',
  icon: <img alt="" src={instantBonus} />,
}];
