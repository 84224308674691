import { FormattedMessage } from 'react-intl';

import { Button, TButtonProps } from '../../../../../../../common';
import { useStyles } from './change-parameters-button.styles';

export const ChangeParametersButton = ({ onClick, ...props }: TButtonProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      data-testid="change-params"
      variant="text"
      color="primary"
      className={classes.root}
      onClick={onClick}
    >
      <FormattedMessage id="order.create.changeParams" />
    </Button>
  );
};
