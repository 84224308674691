import { ConfirmationModal } from '../../../../../common/confirmation-modal';
import { useStyles } from './leave-same-route-modal.styles';
import { getLeaveModalProps } from '../utils/get-leave-modal-props';
import { ILeaveModalSameRouteProps } from './leave-same-route-modal.models';

export const LeaveSameRouteModal = ({
  onConfirm,
  ...props
}: ILeaveModalSameRouteProps): JSX.Element => {
  const classes = useStyles();

  const leaveModalProps = getLeaveModalProps(
    classes.iconWrapper,
    classes.modalContent,
    classes.modalTitle,
  );

  const blockSameRouteProps = {
    ...leaveModalProps,
    onConfirm: onConfirm!,
  };

  return (
    <ConfirmationModal
      {...props}
      {...blockSameRouteProps}
      rejectOnClose
    />
  );
};
