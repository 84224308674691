import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { CustomBreakpoints } from '../../../../../../../themes/default';

export const useStyles = makeStyles(({
  breakpoints, spacing, fontFamilies, fontSizes, fontWeights, palette, lineHeights,
}: Theme) => ({
  root: {
    flexDirection: 'column',
    flexGrow: 1,
    padding: '20px 16px 0',
    [breakpoints.up('sm')]: {
      padding: '32px 32px 0',
    },
    '&&': {
      display: 'flex',
    },
  },
  lensesContent: {
    flexGrow: 1,
    marginBottom: 20,
    [breakpoints.up('sm')]: {
      marginBottom: 32,
    },
  },
  title: {
    margin: '0 0 16px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxl,
    lineHeight: lineHeights.xxl,
    fontWeight: fontWeights.medium,
    color: palette.text.primary,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.xxxl,
      lineHeight: lineHeights.xxxl,
      margin: '0 0 18px',
    },
  },
  lensesBrand: {
    display: 'grid',
    gap: spacing(2),
    [breakpoints.up('sm')]: {
      gap: spacing(2.5),
      gridAutoFlow: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  lensesBrandLogo: {
    width: 'auto',
    height: 61,
    [breakpoints.up('sm')]: {
      height: 68,
    },
  },
  lensesBrandLogoAom1d: {
    height: 91,
    [breakpoints.up('sm')]: {
      height: 101,
    },
    [breakpoints.between('lg', CustomBreakpoints.aom1dMyLensesBreakpoint)]: {
      width: '100%',
      height: 'auto',
    },
  },
  lensesBrandName: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: 1.3,
    color: palette.text.primary,
    margin: 0,
    maxWidth: 400,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.md,
    },
  },
  messageStyles: {
    [breakpoints.between(CustomBreakpoints.mobileUp, 'sm')]: {
      maxWidth: 120,
    },
    [breakpoints.between('md', 'lg')]: {
      maxWidth: 'unset',
    },
    [breakpoints.between('lg', 'xl')]: {
      maxWidth: 200,
    },
    [breakpoints.up('xl')]: {
      maxWidth: 260,
    },
  },
}));
