import React, { forwardRef } from 'react';
import { Alert as MuiAlert, AlertProps as IAlertProps } from '@mui/material';

import { Icon, IconTypes } from '../icon';
import { useStyles } from './alert.styles';

export const Alert = forwardRef<HTMLDivElement, IAlertProps>((props, ref): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiAlert
      {...props}
      ref={ref}
      iconMapping={{
        error: <Icon type={IconTypes.error} />,
        warning: <Icon type={IconTypes.warning} />,
        success: <Icon type={IconTypes.success} />,
      }}
      classes={{
        root: classes.root,
        icon: classes.icon,
        message: classes.message,
      }}
    />
  );
});
