import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  palette,
  breakpoints,
  fontFamilies,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 170,
    padding: '20px 16px 0',
    [breakpoints.up('sm')]: {
      padding: '32px 32px 0',
    },
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  title: {
    margin: '0 0 16px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxl,
    lineHeight: lineHeights.xxl,
    fontWeight: fontWeights.medium,
    color: palette.text.primary,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.xxxl,
      lineHeight: lineHeights.xxxl,
      margin: '0 0 18px',
    },
  },
  pointsQuantity: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 60,
    height: 60,
    borderRadius: '50%',
    backgroundColor: palette.primary.main,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    fontFamily: fontFamilies.primary,
    color: palette.common.white,
    margin: 0,
    [breakpoints.up('sm')]: {
      width: 120,
      height: 120,
      fontSize: fontSizes.xxxl,
      lineHeight: lineHeights.xxxl,
    },
  },
  noPointsLabel: {
    maxWidth: 460,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.xl,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    textAlign: 'center',
  },
  pointsLabel: {
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.xl,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    margin: 0,
    textAlign: 'center',
  },
  pointsContainer: {
    textAlign: 'center',
  },
  pointsAndSalesLink: {
    padding: '17px 0',
    textAlign: 'center',
    [breakpoints.up('lg')]: {
      padding: '20px 0',
    },
  },
}));
