import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as LensesImage } from '../../../../../../assets/images/lenses-placeholder.svg';
import { NoEntries } from '../../../../../common/no-entries';

export const CardPlaceholder = (): JSX.Element => {
  const intl = useIntl();

  return (
    <NoEntries
    icon={(
      <LensesImage />
    )}
    description={intl.formatMessage(
      { id: 'myLensesDetailed.notFitted.placeholderCard.description' },
      { p: (chunks: ReactNode) => <p>{chunks}</p> },
    )}
  />
  );
};
