import React, { forwardRef, HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../common/button';
import { IFormImperativeHandleProps } from '../../../common/form';
import { Modal, ResponsiveWidth } from '../../../common/modal';
import { Preloader } from '../../../common/preloader';
import { CallbackModalForm } from './callback-modal-form';
import { ICallbackModalViewProps } from './callback-modal.models';
import { useStyles } from './callback-modal.styles';

export const CallbackModal = forwardRef<IFormImperativeHandleProps, ICallbackModalViewProps>(({
  isLoggedIn, onCallbackRequest, onRequestSuccess, defaultValues, isLoading, isError, ...props
}, ref): JSX.Element => {
  const classes = useStyles();
  const isSumbitButtonShow = !isLoading && !isError;

  const renderContent = () => {
    if (isLoading) {
      return <Preloader />;
    }

    if (isError) {
      return null;
    }

    return (
      <>
        <p className={classes.description}>
          <FormattedMessage
            id="callback.modal.description"
          />
        </p>
        <CallbackModalForm
          ref={ref}
          isLoggedIn={isLoggedIn}
          onRequestSuccess={onRequestSuccess}
          defaultValues={defaultValues?.profile}
        />
      </>
    );
  };

  return (
    <Modal
      {...props}
      responsiveWidth={ResponsiveWidth.md}
      PaperProps={{ 'data-testid': 'callback-request-window' } as HTMLAttributes<HTMLDivElement>}
      title={(
        <FormattedMessage
          id="callback.modal.title"
        />
      )}
      content={renderContent()}
      footer={(
        <>
          {isSumbitButtonShow && (
            <Button
              fullWidth
              variant="contained"
              onClick={onCallbackRequest}
              data-testid="callback-request-button"
            >
              <FormattedMessage
                id="callback.modal.requestButton.label"
              />
            </Button>
          )}
        </>
      )}
    />
  );
});
