import React from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_IS_USER_LOGGED_IN } from '../graphql/user/queries';

export const usePrivateRoute = () => {
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);

  const getPrivateElement = (element: JSX.Element, withoutRedirect?: boolean): JSX.Element | null => {
    if (isLoggedIn) {
      return element;
    } else if (withoutRedirect) {
      return null;
    }
  
    return (
      <Navigate to="/" />
    );
  };

  return { getPrivateElement };
};
