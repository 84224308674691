import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '20px 16px 0',
    [breakpoints.up('sm')]: {
      padding: '32px 32px 0',
    },
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    [breakpoints.up('sm')]: {
      marginBottom: 32,
    },
  },
  title: {
    margin: '0 0 16px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxl,
    lineHeight: lineHeights.xxl,
    fontWeight: fontWeights.medium,
    color: palette.text.primary,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.xxxl,
      lineHeight: lineHeights.xxxl,
      margin: '0 0 18px',
    },
  },
  fitDescription: {
    margin: 0,
    maxWidth: 530,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.primary,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.md,
      lineHeight: lineHeights.xxl,
    },
  },
  lenseIcon: {
    flexShrink: 0,
    marginLeft: 16,
    width: 50,
    height: 50,
    [breakpoints.up('sm')]: {
      width: 100,
      height: 100,
    },
  },
  myLensesLink: {
    padding: '17px 0',
    textAlign: 'center',
    [breakpoints.up('lg')]: {
      padding: '20px 0',
    },
  },
}));
