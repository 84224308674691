import { ReactNode } from 'react';
import { useReactiveVar } from '@apollo/client';
import { cx } from '@emotion/css';

import { IMyLensesFittedCardsListProps } from './my-lenses-fitted-cards-list.models';
import { IConsumerLensesParams, ProductType } from '../../../../../graphql/lenses/models/get-lenses.models';
import { useStyles } from './my-lenses-fitted-cards-list.styles';
import { Card } from '../../../../common/card';
import { LensesCard } from './lenses-card';
import { SolutionsCard } from './solutions-card';
import {
  setIsLocatorIconsCampaign,
} from '../../../../../graphql/configuration/configuration.cache';
import { CampaignIcon } from '../campaign-icon';

export const MyLensesFittedCardsList = ({ cards }: IMyLensesFittedCardsListProps): JSX.Element => {
  const classes = useStyles();
  const isCampaignIcon = useReactiveVar(setIsLocatorIconsCampaign);

  return (
    <Card className={cx(classes.root,
      { [classes.rootCampaign]: isCampaignIcon },
    )}>
      {isCampaignIcon && <CampaignIcon />}
      <ul className={cx(classes.cardsList,
        { [classes.cardsListCampaign]: isCampaignIcon },
      )}>
        {cards.map((card: IConsumerLensesParams) => {
          let fittedCard: ReactNode;

          if (card.brand) {
            switch (card.brand.productType) {
              case ProductType.lenses:
                fittedCard = (
                  <LensesCard
                    key={card.brandId}
                    cardData={card}
                  />
                );
                break;
              case ProductType.solutions:
                fittedCard = (
                  <SolutionsCard
                    key={card.brandId}
                    cardData={card}
                  />
                );
                break;
              default:
                fittedCard = null;
                break;
            }
          }

          return fittedCard;
        })}
      </ul>
    </Card>
  );
};
