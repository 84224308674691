import React, { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Form } from '../../../common/form';
import { GoogleMapAutocomplete } from '../../../common/google-map-autocomplete';
import {
  IEcpSearchProps,
  TSearchFormValues,
} from './ecp-search.models';
import { useSearchLocation } from '../../../../hooks/use-search-location';
import {
  setAutocompletedSearch,
  setAutocompletedValue,
  setAutoValueSelected,
  setError,
  setEcpFormSubmitted,
} from '../../../../graphql/ecp-locator/ecp-locator.cache';
import { ErrorPlain, ErrorTypes } from '../../../common/error-plain';
import { EcpErrors } from '../../../../constants/ecp-locator/error-types';
import { useStyles } from './ecp-search.styles';

export const EcpSearch = ({
  updateStores, inputIcon, inputStyles, rootStyles, rootInputStyles, endAdornmentStyles,
}: IEcpSearchProps): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    register,
    handleSubmit,
    setValue,
  } = useForm<TSearchFormValues>();
  const fieldName = 'searchValue';
  const defaultValues = useMemo(() => ({
    [fieldName]: '',
  }), []);
  register('searchValue');
  const { updateLocationCoordinates } = useSearchLocation();
  const isAutocompletedSearch = useReactiveVar(setAutocompletedSearch);
  const autocompletedValue = useReactiveVar(setAutocompletedValue);
  const isAutoValueSelected = useReactiveVar(setAutoValueSelected);
  const searchError = useReactiveVar(setError);
  const classes = useStyles();

  const handleSetInputValue = (searchValue: string | undefined) => {
    setValue(fieldName, searchValue!);
  };

  // Set boolean to notify autocomplete that predictions list should be closed.
  const handleAutoValueSelected = (isSelected: boolean) => {
    setAutoValueSelected(isSelected);
  };

  // Set boolean to submit form if autocomplete is completed.
  const handleAutocompletedSearch = (isSearch: boolean) => {
    setAutocompletedSearch(isSearch);
  };

  // Set value to fill in the input after component is rendered.
  const handleAutocompletedValue = (searchValue: string) => {
    setAutocompletedValue(searchValue);
  };

  const handleSearch = async ({ searchValue }: TSearchFormValues) => {
    handleAutoValueSelected(true);
    let userLocation;
    try {
      // Check if searchValue is taken from cache.
      if (autocompletedValue.length) {
        userLocation = await updateLocationCoordinates({ searchValue: autocompletedValue });
      } else {
        userLocation = await updateLocationCoordinates({ searchValue });
      }
      await updateStores!({ userLocation });
      // Set value to notify error watcher if error should be shown.
      setEcpFormSubmitted(true);
    } catch {
      if (searchValue?.length) {
        setError(EcpErrors.errorValue);
        // Prevent autofill with errorValue.
        setAutocompletedValue('');
      } else {
        setError(EcpErrors.noSearchValue);
      }
    }
  };

  useEffect(() => {
    // Submit form on autocomplete.
    if (isAutocompletedSearch) {
      handleSearch({ searchValue: autocompletedValue });
      handleAutocompletedSearch(false);
    }
  }, [isAutocompletedSearch]);

  return (
    <Form
      onSubmit={handleSubmit(handleSearch)}
      defaultValues={defaultValues}
    >
      <GoogleMapAutocomplete
        fieldName={fieldName}
        onChildInputChange={handleSetInputValue}
        onAutocompletedSearch={handleAutocompletedSearch}
        onAutocompletedValue={handleAutocompletedValue}
        onFormSubmitted={handleAutoValueSelected}
        isOpenPredictions={!isAutoValueSelected}
        variant={isMobile ? 'outlined' : 'standard'}
        startIcon={inputIcon}
        inputError={!!searchError}
        inputStyles={inputStyles}
        rootStyles={rootStyles}
        rootInputStyles={rootInputStyles}
        endAdornmentStyles={endAdornmentStyles}
      />
      {searchError === EcpErrors.errorValue && (
        <ErrorPlain
          content="ecp.error.errorValue"
          variant={ErrorTypes.error}
        />
      )}
      {searchError === EcpErrors.noSearchValue && (
        <ErrorPlain
          content="ecp.error.noSearchValue"
          variant={ErrorTypes.error}
          externalClass={classes.error}
        />
      )}
    </Form>
  );
};
