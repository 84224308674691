import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    paddingTop: 24,
    [breakpoints.up('md')]: {
      paddingTop: 32,
    },
    [breakpoints.up('lg')]: {
      paddingTop: 40,
    },
  },
}));
