import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, palette }: Theme) => ({
  content: {
    background: palette.grey.A100,
    paddingBottom:32,
    [breakpoints.up('sm')]: {
      paddingBottom: 80,
    },
  },
  box: {
    marginBottom: 0,
  },
  logoLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px 0 16px',
    [breakpoints.up('sm')]: {
      padding: '80px 0 24px',
    },
  },
}));
