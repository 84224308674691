import { getCookiePolicyFromStorage, setCookiePolicyToStorage } from '../../../utils/user';
import { cookiePolicy, isCookiePolicyAgreed } from '../user.cache';

export const setCookiePolicyAgreed = () => {
  setCookiePolicyToStorage();
  cookiePolicy(getCookiePolicyFromStorage());
  isCookiePolicyAgreed(!!cookiePolicy());
};

export const useCookiePolicy = () => ({
  setCookiePolicyAgreed,
});
