import React, { useCallback } from 'react';
import { cx } from '@emotion/css';

import { Button } from '../button';
import { Modal, ResponsiveWidth } from '../modal';
import { IConfirmationModal } from './confirmation-modal.models';

import { useStyles } from './confirmation-modal.styles';

export const ConfirmationModal = ({
  content,
  confirmButtonLabel,
  rejectButtonLabel,
  onClose,
  onConfirm,
  onReject,
  rejectOnClose,
  hasChangedButtonsPriority,
  isInProgress,
  isMultiContent,
  confirmButtonAriaLabel,
  rejectButtonAriaLabel,
  contentStyles,
  responsiveWidth,
  title,
  titleStyles,
  topIcon,
  ...props
}: IConfirmationModal): JSX.Element => {
  const classes = useStyles();

  const handleConfirmButtonClick = useCallback(() => {
    if (isInProgress) {
      return;
    }

    onConfirm();
  }, [onConfirm, isInProgress]);

  const handleRejectButtonClick = useCallback(() => {
    if (isInProgress) {
      return;
    }

    if (onReject) {
      onReject();
    } else {
      onClose!();
    }
  }, [onClose, onReject, isInProgress]);

  const handleClose = useCallback(() => {
    if (rejectOnClose && onReject) {
      onReject();
    } else {
      onClose!();
    }
  }, [onClose, rejectOnClose, onReject]);

  const priorityButton = (
    <Button
      variant="contained"
      fullWidth
      onClick={hasChangedButtonsPriority ? handleRejectButtonClick : handleConfirmButtonClick}
      data-testid={hasChangedButtonsPriority ? 'reject' : 'confirm'}
      aria-label={hasChangedButtonsPriority ? rejectButtonAriaLabel : confirmButtonAriaLabel}
    >
      {hasChangedButtonsPriority ? rejectButtonLabel : confirmButtonLabel}
    </Button>
  );

  const nonPriorityButton = (
    <Button
      variant="outlined"
      fullWidth
      onClick={hasChangedButtonsPriority ? handleConfirmButtonClick : handleRejectButtonClick}
      data-testid={hasChangedButtonsPriority ? 'confirm' : 'reject'}
      aria-label={hasChangedButtonsPriority ? confirmButtonAriaLabel : rejectButtonAriaLabel}
    >
      {hasChangedButtonsPriority ? confirmButtonLabel : rejectButtonLabel}
    </Button>
  );

  return (
    <Modal
      {...props}
      onClose={handleClose}
      isInProgress={isInProgress}
      title={title ? title : undefined}
      topIcon={topIcon}
      content={isMultiContent ? (
        <div className={cx(classes.multiContent, contentStyles)} data-testid="multi-content-wrapper">
          {content}
        </div>
      ) : (
        <p className={cx(classes.content, contentStyles )} data-testid="content-wrapper">
          {content}
        </p>
      )}
      footer={(
        <div className={classes.actions}>
          {priorityButton}
          {(hasChangedButtonsPriority && confirmButtonLabel) && nonPriorityButton}
          {(!hasChangedButtonsPriority && rejectButtonLabel) && nonPriorityButton}
        </div>
      )}
      responsiveWidth={responsiveWidth || ResponsiveWidth.sm}
      titleStyles={titleStyles}
    />
  );
};
