import { gql } from '@apollo/client';

export const GET_CURRENT_PROMOTIONS = gql`
  query GetPromotions {
    voucherByAuthId {
      id
      type
      description
      endDate
      externalLink
      startDate
      voucherType
      value
      name
    }
  }
`;
