import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  palette, fontSizes, lineHeights, fontWeights, breakpoints,
}: Theme) => ({
  root: {
    listStyle: 'none',
    margin: 0,
    paddingLeft: 0,
    [breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateAreas: `
        'a .'
        'b .'
        'c .'`,
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    '&:first-child': {
      [breakpoints.up('md')]: {
        gridArea: 'a',
      },
    },
    '&:nth-child(2)': {
      [breakpoints.up('md')]: {
        gridArea: 'b',
      },
    },
    '&:nth-child(3)': {
      [breakpoints.up('md')]: {
        gridArea: 'c',
        marginBottom: 0,
      },
    },
    '& svg': {
      marginRight: 12,
    },
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    width: '100%',
    color: palette.text.primary,
    textAlign: 'left',
    textDecoration: 'none',
    [breakpoints.up('md')]: {
      width: 'unset',
    },
  },
  strongText: {
    fontWeight: fontWeights.medium,
  },
  listNote: {
    display: 'block',
    color: palette.grey['700'],
    fontSize: fontSizes.xxs,
    lineHeight: lineHeights.xs,
    fontWeight: fontWeights.regular,
  },
  iconWrapper: {
    lineHeight: 0,
  },
}));
