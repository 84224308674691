import { useCallback, useContext } from 'react';

import { oauthApi } from '../rest/oauth/oauth.api';
import { useBind } from '../hooks/use-bind';
import { useCancellablePromise } from '../hooks/use-cancellable-promise';
import { useLogin } from '../graphql/user/actions/login';
import { useLogout } from '../graphql/user/actions/logout';
import { getTokenFromStorage } from '../utils/user';
import { RefreshTokenContext } from '../components/common/refresh-token';
import { IRefreshTokenFailedData, ITokenData } from '../rest/oauth';
import { ACCOUNT_BLOCKED_MESSAGE } from '../constants/errors';

let refreshTokenPromise: Promise<string>;

export const useRefreshToken = () => {
  const { isTokenRefreshing, setRefreshTokenState } = useContext(RefreshTokenContext);

  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();
  const { relogin } = useLogin();
  const { logout } = useLogout();

  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const reloginBind = useBind(relogin);
  const setIsRefreshingInBind = useBind(setRefreshTokenState);
  const isRefreshingInBind = useBind(isTokenRefreshing);

  const refreshToken = useCallback(async () => {
    if (isRefreshingInBind.current) {
      const token = await refreshTokenPromise;
      return token;
    }

    setIsRefreshingInBind.current(true);

    try {
      refreshTokenPromise = new Promise((resolve, reject) => {
        makeCancellablePromiseBind.current(oauthApi.refreshToken(getTokenFromStorage()))
          .then(({ data }) => {
            if ((data as IRefreshTokenFailedData).message === ACCOUNT_BLOCKED_MESSAGE) {
              reject();
              logout();
            }

            reloginBind.current(data as ITokenData);
            resolve((data as ITokenData).access_token);
          })
          .catch(reject);
      });

      const token = await refreshTokenPromise;
      setIsRefreshingInBind.current(false);
      return token;

    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        return;
      }

      setIsRefreshingInBind.current(false);
    }
  }, [
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    reloginBind,
    isRefreshingInBind,
    setIsRefreshingInBind,
  ]);

  return {
    refreshToken,
    refreshTokenPromise,
    isTokenRefreshing,
  };
};
