import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  headerAndContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
  },
  footerWrapper: {
    flex: '0 0 auto',
  },
}));
