import { isClinicStore, isVendingMachine } from './define-store-types';
import markerClinicIcon from '../../assets/images/map-markers/clinic.png';
import markerOpticsIcon from '../../assets/images/map-markers/optic.png';
import markerVendingIcon from '../../assets/images/map-markers/vending.png';
import {
  MARKER_WIDTH,
  MARKER_HEIGHT,
  MARKER_WIDTH_ACTIVE,
  MARKER_HEIGHT_ACTIVE,
} from '../../constants/ecp-locator';

export const getMarkerIcon = (type: string, code?: string, codeToCheck?: string) => {
  let url: string;
  const isMarkerActive = codeToCheck === code;
  const scaledSize = isMarkerActive
    ? new window.google.maps.Size(MARKER_WIDTH_ACTIVE, MARKER_HEIGHT_ACTIVE)
    : new window.google.maps.Size(MARKER_WIDTH, MARKER_HEIGHT);

  switch (true) {
    case isClinicStore(type):
      url = markerClinicIcon;
      break;
    case isVendingMachine(type):
      url = markerVendingIcon;
      break;
    default:
      url = markerOpticsIcon;
      break;
  }

  return {
    url,
    scaledSize,
  };
};
