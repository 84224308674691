import { useQuery, useReactiveVar } from '@apollo/client';

import {
  IVisionProfileData,
} from '../../../../../../graphql/profile/models/get-vision-profile.models';
import { GET_VISION_PROFILE } from '../../../../../../graphql/profile/queries/get-vision-profile';
import { UserMenu as UserMenuView } from './user-menu';
import {
  useVisionProfileData,
  VPStatus,
} from '../../../../../../hooks/use-vision-profile-data';
import { IOrders } from '../../../../../../graphql/order/models/get-orders.models';
import { GET_ORDERS } from '../../../../../../graphql/order/queries/get-orders';
import { setC2CMode } from '../../../../../../graphql/configuration/configuration.cache';
import { C2CMode } from '../../../../../common/configuration-settings/configuration-settings.models';

export const UserMenu = () => {
  const { data } = useQuery<IVisionProfileData>(GET_VISION_PROFILE);
  const { data: ordersData } = useQuery<IOrders>(GET_ORDERS);
  const { status } = useVisionProfileData(data);
  const c2CMode = useReactiveVar(setC2CMode);
  const isOrders = c2CMode !== C2CMode.disabled;

  return (
    <>
      {
        (status === VPStatus.INCOMPLETE || status === VPStatus.COMPLETE) && (
          <UserMenuView
            visionProfileStatus={status}
            orders={ordersData}
            isOrders={isOrders}
          />
        )
      }
    </>
  );
};
