import React, {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
} from 'react';
import {
  TextField as MuiTextField,
  InputAdornment as MuiInputAdornment,
} from '@mui/material';
import IMask, { InputMask } from 'imask';
import { isString } from 'lodash';

import { Button } from '../button';
import { IconButton } from '../icon-button';
import { useStyles } from './text-field.styles';
import {
  IMaskedOptions,
  TTextFieldProps,
  IMuiTextFieldStandardProps,
  IMuiTextFieldOutlinedProps,
} from './text-field.models';
import { IconTypes } from '../icon';
import { TFormExtendedCommonProps } from '../form';
import { Button as ButtonNew } from '../button-new';

export const TextField = ({
  type,
  value = '',
  onChange,
  helperText,
  startIcon,
  actionProps,
  footerActionProps,
  variant = 'standard',
  InputProps,
  inputProps,
  InputLabelProps,
  endAdornment,
  customEndAdornment,
  ...props
}: TFormExtendedCommonProps<TTextFieldProps>): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const mask = useRef<InputMask<IMaskedOptions> | null>(null);
  const [activeType, setActiveType] = useState(type);
  const classes = useStyles();

  useEffect(() => {
    if (type !== 'tel') {
      return;
    }

    if (!mask.current) {
      const newMask = IMask(inputRef.current as HTMLInputElement, {
        mask: '+{7} (000) 000 00 00',
        lazy: false,
        placeholderChar: ' ',
      });

      mask.current = newMask;
    }

    if (mask.current.unmaskedValue !== value) {
      mask.current.unmaskedValue = value;
    }

    const handleAccept = () => {
      if (mask.current) {
        onChange(mask.current.unmaskedValue === '7' ? '' : mask.current.unmaskedValue);
      }
    };

    mask.current.on('accept', handleAccept);

    // eslint-disable-next-line consistent-return
    return () => {
      if (mask.current) {
        mask.current.off('accept', handleAccept);
      }
    };
  }, [type, value, onChange]);

  useEffect(() => () => {
    if (mask.current) {
      mask.current.destroy();
    }
  }, []);

  const textFieldCommonProps: IMuiTextFieldStandardProps | IMuiTextFieldOutlinedProps = {
    ...props,
    InputProps: {
      ...InputProps,
      classes: {
        root: classes.inputRoot,
        focused: classes.inputFocused,
        input: classes.inputInput,
        disabled: classes.inputDisabled,
        error: classes.inputError,
      },
    },
    FormHelperTextProps: {
      classes: {
        root: classes.helperTextRoot,
        error: classes.helperTextError,
      },
      component: 'div',
    },
    classes: {
      root: classes.textFieldRoot,
    },
    type: activeType,
    InputLabelProps: {
      ...InputLabelProps,
      classes: {
        root: classes.labelRoot,
        focused: classes.labelFocused,
        shrink: classes.labelShrink,
        error: classes.labelError,
      },
    },
  };

  if (variant === 'standard') {
    textFieldCommonProps.InputProps!.classes!.underline = classes.inputUnderline;
  }

  if (helperText || footerActionProps) {
    textFieldCommonProps.helperText = (
      <>
        {helperText && (
          <p className={classes.helperTextContent}>
            {isString(helperText) ? ( // eslint-disable-next-line react/no-danger
              <span dangerouslySetInnerHTML={{ __html: helperText }} />
            ) : helperText}
          </p>
        )}
        {footerActionProps && (
          <p className={classes.footer}>
            <ButtonNew
              {...footerActionProps}
              isUnderline
              rootClasses={classes.footerActionButtonRoot}
            />
          </p>
        )}
      </>
    );
  }

  const handleShowPasswordButtonClick = () => {
    setActiveType(activeType === 'password' ? 'text' : 'password');
  };

  if (startIcon) {
    textFieldCommonProps.InputProps!.startAdornment = (
      <MuiInputAdornment
        classes={{
          root: classes.inputAdornmentRoot,
        }}
        disablePointerEvents
        position="start"
      >
        {startIcon}
      </MuiInputAdornment>
    );
  }

  if (actionProps || type === 'password') {
    textFieldCommonProps.InputProps!.endAdornment = (
      <MuiInputAdornment
        classes={{
          root: classes.inputAdornmentRoot,
        }}
        position="end"
      >
        {actionProps ? (
          <Button
            variant="text"
            data-testid="text-field-action"
            classes={{
              root: classes.actionButtonRoot,
            }}
            {...actionProps}
          />
        ) : (
          <IconButton
            onClick={handleShowPasswordButtonClick}
            iconProps={{
              type: activeType === 'password' ? IconTypes.hiddenSecret : IconTypes.visibleSecret,
            }}
          />
        )}
      </MuiInputAdornment>
    );
  }

  if (type === 'tel') {
    /*
      fix safari. shrink is not working on focus with imask so
      set shink true always not only on focus
    */

    textFieldCommonProps.InputLabelProps!.shrink = true;

    return (
      <MuiTextField
        {...textFieldCommonProps}
        variant={variant}
        inputRef={inputRef}
        inputProps={inputProps}
        data-testid="text-field"
      />
    );
  }

  if (type === 'number') {
    const handleChange = ({ target: { value: number } }: ChangeEvent<HTMLInputElement>) => {

      if (inputProps && number) {
        const { min, max } = inputProps as Partial<HTMLInputElement>;

        if (min && +min > +number) {
          return;
        }

        if (max && +max < +number) {
          return;
        }
      }

      onChange(number);
    };

    return (
      <MuiTextField
        {...textFieldCommonProps}
        value={value}
        variant={variant}
        inputProps={inputProps}
        onChange={handleChange}
        data-testid="text-field"
      />
    );
  }

  if (customEndAdornment) {
    textFieldCommonProps.InputProps!.endAdornment = customEndAdornment;
  }

  return (
    <MuiTextField
      {...textFieldCommonProps}
      value={value}
      variant={variant}
      inputProps={inputProps}
      onChange={onChange}
      data-testid="text-field"
    />
  );
};
