import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ToggleButtonProps } from '@mui/material';

import { Genders } from './gender-toggle-group.models';
import { ToggleButtonGroup } from '../../../../common/form/toggle-button-group';

const genderOptions: ToggleButtonProps[] = [{
  children: (
    <FormattedMessage
      id="profile.gender.male"
    />
  ),
  value: Genders.male,
}, {
  children: (
    <FormattedMessage
      id="profile.gender.female"
    />
  ),
  value: Genders.female,
}];

export const GenderToggleGroup = (): JSX.Element => (
  <ToggleButtonGroup
    name="gender"
    options={genderOptions}
    fullWidth
    exclusive
  />
);
