import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';

import { useStyles } from './download-app.styles';
import { TabsContent } from './tabs/tabs-content';
import { ContentWrapper } from '../../../common/content-wrapper';

export const DownloadApp = () => {
  const benefits = [
    'downloadApp.benefit-1',
    'downloadApp.benefit-2',
    'downloadApp.benefit-3',
  ];
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ContentWrapper>
      <section
        className={classes.root}
        aria-label={intl.formatMessage({ id: 'downloadApp.ariaLabel' })}
      >
        <div className={classes.left}>
          <h2 className={classes.title} data-testid="download-title">
            {intl.formatMessage(
              { id: 'downloadApp.title' },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </h2>
          {isMobile && <TabsContent />}
          <p className={classes.description}><FormattedMessage id="downloadApp.description" /></p>
          <ul className={classes.list}>
            {benefits.map(item => (
              <li key={item} className={classes.item}><FormattedMessage id={item} /></li>
            ))}
          </ul>
        </div>
        <div className={classes.right}>
          {!isMobile && <TabsContent />}
        </div>
      </section>
    </ContentWrapper>
  );
};
