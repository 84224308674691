import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import { OnlineShopsProps } from './online-shops.models';
import { useStyles } from './online-shops.styles';
import { PointsDescriptionCard } from './points-description-card';
import { ShopsList } from './shops-list';
import { Preloader } from '../../../common/preloader';
import { BackButton } from '../../../common/back-button-new';
import { FittingProps } from '../../../common/with-fitting-props/with-fitting-props.models';

export const OnlineShops = ({ shops, isLoading, ...props }: OnlineShopsProps & FittingProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const isFitted = props?.isFitted || false;

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'onlineShops.title' })} | {intl.formatMessage({ id: 'common.app.title' })}
        </title>
      </Helmet>
      <div data-testid="internet-shops-page" className={classes.root}>
        <BackButton />
        <h1
          className={classes.title}
          data-testid="internet-shops-title"
        >
          <FormattedMessage id="onlineShops.title" />
        </h1>
        <PointsDescriptionCard isFitted={isFitted} />
        {isLoading && <Preloader />}
        {shops && <ShopsList shops={shops!} />}
      </div>
    </>
  );
};
