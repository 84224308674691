import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const mobileWidth = 234;
const desktopWidth = 274;

export const useStyles = makeStyles(({
  breakpoints, fontSizes, lineHeights, palette, fontWeights,
}: Theme) => ({
  innerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pointsWrapper: {
    marginBottom: 16,
  },
  tooltipWrapper: {
    margin: 0,
  },
  tooltipButton: {
    marginLeft: 8,
  },
  tooltip: {
    marginTop: 0,
    minWidth: 'unset',
    width: mobileWidth,
    [breakpoints.up('sm')]: {
      width: desktopWidth,
    },
  },
  tooltipContent: {
    margin: 0,
  },
  infoIcon: {
    '& svg': {
      width: 16,
      height: 16,
      [breakpoints.up('sm')]: {
        width: 18,
        height: 18,
      },
    },
  },
  plusIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  plusIcon: {
    marginRight: 8,
    '& svg': {
      width: 20,
      height: 20,
      [breakpoints.up('sm')]: {
        width: 24,
        height: 24,
      },
    },
  },
  addBonusButton: {
    display: 'flex',
    alignItems: 'center',
    color: palette.text.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.regular,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  points: {
    fontWeight: fontWeights.medium,
  },
}));
