import React, { useCallback } from 'react';
import { cx } from '@emotion/css';
import { Dialog } from '@mui/material';
import { DialogContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { capitalize } from 'lodash';

import { IconButton } from '../icon-button';
import { IModalProps, ResponsiveWidth } from './modal.models';
import { useStyles } from './modal.styles';
import { IconTypes, IconSizes } from '../icon';

export const Modal = ({
  title,
  header,
  content,
  footer,
  footerSeparateLine,
  onClose,
  responsiveWidth = ResponsiveWidth.default,
  isInProgress,
  propsPaperStyles,
  propsContentStyles,
  disableClosing,
  isCloseButtonDisabled,
  rootStyles,
  titleStyles,
  topIcon,
  backdropStyles,
  ...props
}: IModalProps): JSX.Element => {
  const classes = useStyles(props);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  const paperResponsiveWidthClass = classes[
    `dialogPaperResponsiveWidth${capitalize(responsiveWidth)}` as 'dialogPaperResponsiveWidthXs'
    | 'dialogPaperResponsiveWidthSm' | 'dialogPaperResponsiveWidthMd'
    | 'dialogPaperResponsiveWidthLg' | 'dialogPaperResponsiveWidthMdfixed'
    | 'dialogPaperResponsiveWidthDefault' | 'dialogPaperResponsiveWidthMdwide'
    | 'dialogPaperResponsiveWidthMdwider' | 'dialogPaperResponsiveWidthXsfull'
    | 'dialogPaperResponsiveWidthLgwide'
  ];


  const addClassesIf = (
    currentClass: string, propsClass: string | undefined,
  ): string => (propsClass ? `${propsClass} ${currentClass}` : currentClass);

  const handleClose = useCallback((event: Event, reason: string) => {
    if (isInProgress) {
      return;
    }

    if (disableClosing && reason === 'backdropClick') {
      return;
    }

    onClose!();
  }, [onClose, isInProgress]);

  const hasTitle = Boolean(title);

  return (
    <Dialog
      {...props}
      onClose={handleClose}
      disableEscapeKeyDown={disableClosing}
      classes={{
        paper: cx(addClassesIf(classes.dialogPaper, propsPaperStyles),
          { [paperResponsiveWidthClass]: !!responsiveWidth },
          { [classes.dialogPaperWithoutTitle]: !hasTitle },
        ),
        root: rootStyles,
      }}
      BackdropProps={{
        classes: {
          root: cx(classes.backdropRoot, backdropStyles),
        },
      }}
    >
      {
        (!disableClosing && !isCloseButtonDisabled) && (
          <IconButton
            iconProps={{
              type: IconTypes.close,
              size: isTablet ? IconSizes.md : IconSizes.sm,
            }}
            onClick={handleClose as () => void}
            className={classes.closeIconButton}
          />
        )
      }
      {topIcon}
      {(hasTitle || header) && (
        <div className={cx(classes.header, titleStyles)}>
          {hasTitle && <h2 className={classes.title} data-testid="modal-title">{title}</h2>}
          {header}
        </div>
      )}
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        <div className={addClassesIf(classes.content, propsContentStyles)}>
          {content}
        </div>
      </DialogContent>
      {footer && (
        <div
          className={cx(classes.footer, {
            [classes.footerWithLine]: footerSeparateLine,
          })}
        >
          {footer}
        </div>
      )}
    </Dialog>
  );
};
