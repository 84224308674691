import React from 'react';
import { useFormContext, useController } from 'react-hook-form';

import { RadioGroup as RadioGroupBase, IRadioGroupProps } from '../../radio-group';
import { TFormExtendedProps, IAdditionalFormProps } from '..';

export const RadioGroup = ({
  name,
  helperText,
  ...props
}: TFormExtendedProps<IRadioGroupProps>): JSX.Element => {
  const { control, formState: { errors } } = useFormContext();
  const {
    field: { ref, ...restField },
    fieldState: { invalid },
  } = useController({
    name,
    control,
  });
  const additionalProps: IAdditionalFormProps = {};

  if (invalid) {
    additionalProps.error = true;
    additionalProps.helperText = errors[name]?.message;
  } else if (helperText) {
    additionalProps.helperText = helperText;
  }

  return (
    <RadioGroupBase
      {...props}
      {...restField}
      {...additionalProps}
    />
  );
};
