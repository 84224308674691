import { useMemo, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { ILastVisionCheckModalProps } from './last-vision-check-modal.models';
import { TFormValues } from '../../vision-profile-views/last-vision-check-view/last-vision-check-form';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { IVisionProfileData } from '../../../../../../graphql/profile/models/get-vision-profile.models';
import { useCancellablePromise } from '../../../../../../hooks/use-cancellable-promise';
import { useActionsInProgress } from '../../../../../../graphql/preloader/actions/actions-in-progress';
import { GET_VISION_PROFILE } from '../../../../../../graphql/profile/queries/get-vision-profile';
import { UPDATE_VISION_PROFILE } from '../../../../../../graphql/profile/mutations';
import { promiseErrorCallbacks } from '../../../../../../utils/promise/set-promise-error-callbacks';
import { VpUpdateModal } from '../../../../../common/vp-update-modal';
import { LastVisionCheckTitle } from '../../vision-profile-views/last-vision-check-view/last-vision-check-title';
import { LastVisionCheckView } from '../../vision-profile-views/last-vision-check-view';
import { convertDateToString } from '../../../../../../utils/date/convert-date-to-string';

export const LastVisionCheckModal = ({
  lastVisionCheck, dateOfBirth, onClose, ...props
}: ILastVisionCheckModalProps): JSX.Element => {
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const { updateQuery } = useQuery<IVisionProfileData>(GET_VISION_PROFILE);
  const [updateVisionProfile] = useMutation(UPDATE_VISION_PROFILE);
  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const defaultValues = useMemo(() => ({ lastVisionCheck }), []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const handleLastVisionCheckChange = async ({
    lastVisionCheck: lastVisionCheckCurrent,
  }: TFormValues) => {
    addActionInProgress();

    try {
      const { data: { patchVisionProfile } } = await makeCancellablePromise(
        updateVisionProfile({
          variables: {
            visionProfile: {
              lastVisionCheck: convertDateToString(lastVisionCheckCurrent)!.slice(0, 7),
            },
          },
        }),
      );

      updateQuery((prevState) => ({
        visionProfile: {
          ...prevState.visionProfile,
          ...patchVisionProfile,
        },
      }));

      onClose!();
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    }

    removeActionInProgress();
  };

  return (
    <VpUpdateModal
      {...props}
      title={<LastVisionCheckTitle />}
      content={(
        <LastVisionCheckView
          ref={formRef}
          dateOfBirth={dateOfBirth}
          formProps={{
            onSubmit: handleLastVisionCheckChange,
            defaultValues,
          }}
        />
      )}
      onVPUpdate={handleSubmit}
      onClose={onClose}
    />
  );
};
