import parametersBenefits from '../../../../../assets/images/reg-guide/parameters.png';
import location from '../../../../../assets/images/reg-guide/location.png';
import lensesCare from '../../../../../assets/images/reg-guide/lenses-care.png';
import learning from '../../../../../assets/images/reg-guide/learning.png';
import { IBenefitProps } from './benefit';

export const benefits: IBenefitProps[] = [{
  item: 'regGuide.benefits.list.benefit1.old',
  icon: <img alt="" src={parametersBenefits} />,
}, {
  item: 'regGuide.benefits.list.benefit2.old',
  icon: <img alt="" src={location} />,
}, {
  item: 'regGuide.benefits.list.benefit3.old',
  icon: <img alt="" src={lensesCare} />,
}, {
  item: 'regGuide.benefits.list.benefit4.old',
  icon: <img alt="" src={learning} />,
}];