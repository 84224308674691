import { useIntl } from 'react-intl';

import { useStyles } from './benefit.styles';
import { IBenefitProps } from './benefit.models';
import { ReactNode } from 'react';

export const Benefit = ({ icon, item }: IBenefitProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.root}>
      <span
        aria-hidden="true"
        className={classes.iconWrapper}
        data-testid="benefit-icon-wrapper"
      >
        {icon}
      </span>
      <span
        data-testid="benefit-item"
        className={classes.item}
      >
        {intl.formatMessage(
          { id: item },
          {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            span: (chunks: ReactNode) => <span className={classes.newLine}>{chunks}</span>,
            em: (chunks: ReactNode) => <span className={classes.newLineAll}>{chunks}</span>,
          },
        )}
      </span>
    </div>
  );
};
