import { FC } from 'react';

import { useStyles } from './separator.styles';
import { ContentWrapper } from '../content-wrapper';

export const Separator: FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <ContentWrapper>
      <div data-testid="separator" className={classes.root} />
    </ContentWrapper>
  );
};
