import { useReactiveVar } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { FilterButtonProps } from './filter-button.models';

import { filterParams } from '../../../../../graphql/ecp-locator/ecp-locator.cache';
import { isFilterStateDefault } from '../../ecp-filters-modal/utils/is-filter-state-default';
import { Button, IconSizes, IconTypes } from '../../../../common';
import { IconButton } from '../../../../common/icon-button';

export const FilterButton = ({ isMobile, onClick }: FilterButtonProps): JSX.Element => {
  const currentFilters = useReactiveVar(filterParams);
  const { isFiltersChanged } = currentFilters;
  const isFilterApplied = !isFilterStateDefault(currentFilters) && isFiltersChanged;

  return (
    <>
      {isMobile ? (
        <IconButton
          to={onClick}
          isMarked={isFilterApplied}
          iconProps={{
            type: IconTypes.filter,
            size: IconSizes.xs,
          }}
        />
      ) : (
        <Button
          data-testid="filter-stores-button"
          fullWidth
          variant="outlined"
          onClick={onClick}
          isMarked={isFilterApplied}
        >
          <span data-testid="button-marked-icon">
            <FormattedMessage
              id="ecp.filterButton.label"
            />
          </span>
        </Button>
      )}
    </>
  );
};
