import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette, borderRadiuses, breakpoints }: Theme) => ({
  cardRoot: {
    display: 'block',
    textDecoration: 'none',
    border: '1px solid',
    borderColor: palette.grey['200'],
  },
  cardRootTypeDefault: {
    '&&': {
      borderRadius: 16,
      boxShadow: `0 4px 8px ${palette.grey['300']}`,
    },
  },
  cardRootTypePrimary: {
    borderWidth: 2,
    borderRadius: borderRadiuses.sm,
    boxShadow: 'none',
  },
  cardRootTypeSecondary: {
    position: 'relative',
    marginBottom: 0,
    border: 'none',
    borderRadius: borderRadiuses.sm,
    background: palette.grey.A200,
    boxShadow: 'none',
  },
  cardRootTypeTertiary: {
    position: 'relative',
    borderRadius: borderRadiuses.md,
    border: `1px solid ${palette.secondary.main}`,
    borderLeftWidth: 4,
  },
  cardRootTypeQuaternary: {
    borderRadius: borderRadiuses.md,
    border: `2px solid ${palette.grey['200']}`,
    boxShadow: 'none',
    [breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  cardRootTypeBonus: {
    display: 'block',
    position: 'relative',
    padding: '24px 24px 36px',
    border: `1px dashed ${palette.grey['400']}`,
    borderRadius: borderRadiuses.md,
    backgroundColor: palette.common.white,
    boxShadow: 'none',
    [breakpoints.up('sm')]: {
      padding: '32px 32px 40px',
    },
  },
}));
