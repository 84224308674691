import { cx } from '@emotion/css';

import { IDeliverySectionProps } from './delivery-section.models';
import { useStyles } from './delivery-section.styles';

export const DeliverySection = ({
  name, children, title, inlineContent, withBorder, withoutTopIndent,
  rootStyles,
}: IDeliverySectionProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.root, rootStyles,
        { [classes.withBorder]: withBorder },
        { [classes.withoutTopIndent]: withoutTopIndent },
      )}
      data-testid={`${name}-delivery-section`}
    >
      <h4
        data-testid="delivery-message"
        className={classes.title}
      >
        {title}
      </h4>
      {children}
      {inlineContent && (
        <div
          data-testid={`${name}-description`}
          className={classes.inlineWrapper}
        >
          {inlineContent?.map((item) => (
            <p data-testid="{`${name}-description-partial`}" key={item.id}>{item.content}</p>
          ))}
        </div>
      )}
    </div>
  );
};
