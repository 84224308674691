import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { useStyles } from './policy-links.styles';
import { ExternalLinks, InternalLinks } from '../../../constants';
import { useGoBack } from '../../../hooks/use-go-back';

export const PolicyLinks = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const { hasPreviousLocationState } = useGoBack();

  return (
    <ul className={classes.policyList}>
      <li>
        <a
          href={ExternalLinks.legalNoticeLink}
          target="_blank"
          className={classes.link}
        >
          <FormattedMessage id="footer.companyPolicy.programTerms" />
        </a>
      </li>
      <li>
        <a
          href={ExternalLinks.privacyPolicyLink}
          target="_blank"
          className={classes.link}
        >
          <FormattedMessage id="footer.companyPolicy.privacyPolicy" />
        </a>
      </li>
      <li>
        <Link
          className={classes.link}
          to={InternalLinks.cookiePage}
          state={hasPreviousLocationState}
        >
          <FormattedMessage id="footer.companyPolicy.cookiePolicy" />
        </Link>
      </li>
      <li>
        <a
          href={ExternalLinks.myAcuvueProgramPolicyLink}
          target="_blank"
          className={classes.link}
        >
          {intl.formatMessage(
            { id: 'footer.companyPolicy.programRules' },
            { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
          )}
        </a>
      </li>
      <li>
        <a
          href={ExternalLinks.personalDataPolicyLink}
          target="_blank"
          className={classes.link}
        >
          {intl.formatMessage(
            { id: 'footer.companyPolicy.usingPersonalData' },
            { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
          )}
        </a>
      </li>
    </ul>
  );
};
