import React from 'react';

import { Modal, ResponsiveWidth } from '../../../common';
import { Stepper } from '../../../common/stepper';
import { IAppointmentRegistrationModalProps } from './appointment-registration-modal.models';
import { appointmentRegistrationSteps } from './appointment-registration-modal.steps';

export const AppointmentRegistrationModal = ({
  updateAppointments, onDone, ...props
}: IAppointmentRegistrationModalProps): JSX.Element => (
  <Modal
    {...props}
    responsiveWidth={ResponsiveWidth.mdFixed}
    content={(
      <Stepper
        disableStepIndicators
        steps={appointmentRegistrationSteps}
        externalState={{ updateAppointments }}
        onDone={onDone}
      />
    )}
  />
);
