import React, { ReactNode, SyntheticEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'mui-modal-provider';

import { useStyles } from './order-modal.styles';
import { IconTypes, Icon, Modal, Button } from '../../../../common';
import { NotFittedUserModal } from '../../order/not-fitted-user-modal';
import { useOrderModal } from '../../../../../graphql/order/actions/orderModal';
import { IOrderModalProps } from './order-modal.models';
import { InternalLinks } from '../../../../../constants';

export const OrderModal = ({ isFitted, onClose, ...props }: IOrderModalProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const navigate = useNavigate();

  const { setOrderModalShown } = useOrderModal();

  const handleModalClose = () => {
    setOrderModalShown();
    onClose!();
  };

  const { showModal } = useModal();

  const handleButtonClick = (event: SyntheticEvent) => {
    event.preventDefault();

    setOrderModalShown();

    if (isFitted) {
      navigate(InternalLinks.orderCreate);
    } else {
      showModal(NotFittedUserModal, {});
    }
  };

  const benefitItems = [
    {
      title: 'order.preview.modal.option1',
      icon: IconTypes.lensesRoundedBlue,
    },
    {
      title: 'order.preview.modal.option2',
      icon: IconTypes.checkedRoundedBlue,
    },
    {
      title: 'order.preview.modal.option3',
      icon: IconTypes.clockRoundedBlue,
    },
    {
      title: 'order.preview.modal.option4',
      icon: IconTypes.giftRoundedBlue,
    },
  ];

  return (
    <Modal
      {...props}
      topIcon={(
        <Icon
          type={IconTypes.marker3d}
          className={classes.icon}
          aria-hidden="true"
        />
      )}
      data-testid="landing-order-modal"
      title={<FormattedMessage id="order.preview.modal.title" />}
      content={(
        <ul
          data-testid="order-modal-list"
          className={classes.list}
        >
          {
            benefitItems.map((benefit) => (
              <li
                className={classes.item}
                data-testid="order-modal-list-item"
                key={benefit.title}
              >
                <Icon
                  type={benefit.icon}
                  className={classes.itemIcon}
                  aria-hidden="true"
                />
                <span className={classes.itemText}>
                {intl.formatMessage(
                  { id: benefit.title },
                  { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
                )}
              </span>
              </li>
            ))
          }
        </ul>
      )}
      footer={(
        <Button
          variant="contained"
          data-testid="order-lenses-button"
          fullWidth
          onClick={handleButtonClick}
          to={InternalLinks.orderCreate}
        >
          <FormattedMessage
            id="order.preview.modal.button.label"
          />
        </Button>
      )}
      onClose={handleModalClose}
      titleStyles={classes.title}
    />
  );
};
