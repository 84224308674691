import React from 'react';
import { useQuery } from '@apollo/client';

import { IPreloaderProvider } from './preloader-provider.models';
import { Preloader } from '../preloader';
import {
  GET_PRELOADER_ACTIONS_IN_PROGRESS,
} from '../../../graphql/preloader/queries/get-preloader-actions-in-progress-amount';

export const PreloaderProvider = ({ children }: IPreloaderProvider): JSX.Element => {
  const {
    data: { preloader: { actionsInProgressAmount } },
  } = useQuery(GET_PRELOADER_ACTIONS_IN_PROGRESS);

  return (
    <>
      {!!actionsInProgressAmount && <Preloader isPageLevel />}
      {children}
    </>
  );
};
