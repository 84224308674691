import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
}: Theme) => ({
  formControlRoot: {
    marginBottom: 22,
    [breakpoints.up('md')]: {
      paddingTop: 24,
      marginBottom: 36,
    },
  },
  formControlLabelRoot: {
    padding: '18px 16px',
    border: `1px solid ${palette.grey['400']}`,
    borderRadius: 12,
    margin: '0 0 10px',
    [breakpoints.up('md')]: {
      padding: 20,
      marginBottom: 12,
    },
  },
  formControlLabelLabel: {
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontFamily: fontFamilies.primary,
    color: palette.text.secondary,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  radioRoot: {
    padding: 0,
    marginRight: 4,
    '&$radioRoot:hover': {
      backgroundColor: 'transparent',
    },
    [breakpoints.up('md')]: {
      marginRight: 12,
    },
  },
  formHelperTextRoot: {
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontFamily: fontFamilies.primary,
    color: palette.text.secondary,
    margin: '0 0 10px',
    '&$formHelperTextError': {
      color: palette.error.main,
    },
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
      marginBottom: 12,
    },
  },
  formHelperTextError: {},
}));
