import React, { FC, useEffect, useState } from 'react';

import { setEcpFormSubmitted } from '../../../../graphql/ecp-locator/ecp-locator.cache';
import { useMyLocation } from '../../../../hooks/use-my-location';
import { useReactiveVar } from '@apollo/client';
import {
  setDeliveryStore,
  setIsRepeatOrder,
} from '../../../../graphql/order/order.cache';
import { useUpdateOrderStores } from '../../../../hooks/use-update-order-stores';

export const withOrderFiltersAndStoresList = <T extends unknown>(Component: FC<T>) => (
  props: T,
): JSX.Element => {
  const { isLocationDefined, coords } = useMyLocation();
  const [initLocationValue, setInitLocationValue] = useState(isLocationDefined);
  const { updateStores } = useUpdateOrderStores();
  const isRepeatOrder = useReactiveVar(setIsRepeatOrder);
  const storeDetail = useReactiveVar(setDeliveryStore);

  useEffect(() => {
    const isLocationAllowedClick = isLocationDefined && !initLocationValue;
    const isLocationDeniedByUser = !isLocationDefined && initLocationValue;

    if (isLocationDefined) {
      updateStores({ userLocation: coords });
      // Set value to notify error watcher if error should be shown.
      setEcpFormSubmitted(true);
    } else {
      if (isRepeatOrder) {
        updateStores({ userLocation: {
          latitude: storeDetail?.latitude, longitude: storeDetail?.longitude,
        } });
      }
    }


    // set stores if user click allow button on location browser popup
    if (isLocationAllowedClick) {
      updateStores({ userLocation: coords });
      setInitLocationValue(true);
      // Set value to notify error watcher if error should be shown.
      setEcpFormSubmitted(true);
    }

    // set default state if user deny location access in browser
    if (isLocationDeniedByUser) {
      setInitLocationValue(false);
      // todo: set map default state
    }
  }, [isLocationDefined]);

  return (
    <Component
      {...props}
      updateStores={updateStores}
    />
  );
};
