import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, lineHeights, fontWeights, borderRadiuses,
}: Theme) => ({
  root: {
    padding: '24px 16px 16px',
    borderRadius: borderRadiuses.lg,
    [breakpoints.up('sm')]: {
      padding: 32,
    },
    [breakpoints.up('lg')]: {
      padding: '32px 32px 48px',
    },
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  list: {
    listStyle: 'none',
    display: 'grid',
    columnGap: 16,
    rowGap: 16,
    padding: 0,
    margin: 0,
    [breakpoints.up('sm')]: {
      gridColumnGap: 24,
      gridRowGap: 24,
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      columnGap: 16,
      rowGap: 16,
    },
  },
  title: {
    marginTop: 0,
    marginBottom: 16,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      marginBottom: 24,
    },
  },
  bonusCardsItem: {
    margin: 0,
    padding: 0,
  },
  titleSizeMd: {
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
  },
  titleSizeLg: {
    fontSize: fontSizes.xxl,
    lineHeight: lineHeights.xxl,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.xxxl,
      lineHeight: lineHeights.xxxl,
    },
  },
  footer: {
    marginTop: 12,
    [breakpoints.up('sm')]: {
      marginTop: 24,
    },
  },
  stickyFooter: {
    position: 'sticky',
    bottom: 40,
    zIndex: 1,
    [breakpoints.up('sm')]: {
      position: 'static',
    },
  },
}));
