import { getOrderStatusTranslation } from '../../../../../../utils/order/get-order-status';
import { OrderStatus } from '../../../../../../graphql/order/models/get-orders.models';

interface IStatusItem {
  id: string;
}

export const getActiveItems = (statusItems: IStatusItem[], status: OrderStatus) => {
  const activeItem = statusItems.find((
    statusItem,
  ) => statusItem.id === getOrderStatusTranslation(status));
  const activeIndex = statusItems.indexOf(activeItem!);

  return statusItems.map((statusItem, index) => ({
    id: statusItem.id,
    active: index < activeIndex || index === activeIndex,
  }));
};
