import React, { forwardRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { ILensesExperienceStepFormProps } from './lenses-experience-step-form.models';
import { LensesExperience } from '../../../registration.models';
import { RadioGroup } from '../../../../../../common/form/radio-group';

export const LensesExperienceStepForm = forwardRef<
IFormImperativeHandleProps, ILensesExperienceStepFormProps>(({
  onSubmit,
  validationSchema,
  defaultValues,
}, ref): JSX.Element => {
  const lensesExperienceOptions = useMemo(() => ([
    {
      label: (
        <FormattedMessage
          id="lenses.wearingExperience.newwear"
        />
      ),
      value: LensesExperience.newWear,
    },
    {
      label: (
        <FormattedMessage
          id="lenses.wearingExperience.lessThanYear"
        />
      ),
      value: LensesExperience.lessThanOneYear,
    },
    {
      label: (
        <FormattedMessage
          id="lenses.wearingExperience.moreThanYear"
        />
      ),
      value: LensesExperience.moreThanOneYear,
    },
    {
      label: (
        <FormattedMessage
          id="lenses.wearingExperience.dropout"
        />
      ),
      value: LensesExperience.dropOut,
    },
  ]), []);

  const resultDefaultValues = useMemo(() => defaultValues || {
    clExperience: '',
  }, [defaultValues]);

  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      defaultValues={resultDefaultValues}
    >
      <RadioGroup
        name="clExperience"
        options={lensesExperienceOptions}
      />
    </Form>
  );
});
