import { getLensesTutorialFromStorage, setLensesTutorialToStorage } from '../../../utils/user';
import { isLensesTutorialShown, lensesTutorial } from '../user.cache';

export const setLensesTutorialShown = () => {
  setLensesTutorialToStorage();
  lensesTutorial(getLensesTutorialFromStorage());
  isLensesTutorialShown(!!lensesTutorial());
};

export const useLensesTutorial = () => ({
  setLensesTutorialShown,
});
