import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ISolutionsCardProps } from './solutions-card.models';
import { useStyles } from './solutions-card.styles';
import { SolutionsBrandLogo } from '../../../../../common/solutions-brand-logo';
import { NoveltyLabel } from '../../../../../common/novelty-label';
import { SolutionsBrandType } from '../../../../../../graphql/lenses/models/get-lenses.models';
import { checkLabelEnabled } from '../utils/check-label-enabled';

export const SolutionsCard = ({ cardData }: ISolutionsCardProps): JSX.Element => {
  const classes = useStyles();
  const { brandId, brand: { fullName, labels } } = cardData;
  const [labelName, setLabelName] = useState<string>('');
  const [isLabelEnabled, setIsLabelEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (labels.length) {
      const label = labels.find(checkLabelEnabled);
      if (label && label.name.length) {
        setLabelName(label.name);
        setIsLabelEnabled(true);
      }
    }
  }, [labels]);

  return (
    <li className={classes.root} data-testid="solutions-card">
      <div className={classes.cardContent}>
        <SolutionsBrandLogo
          className={classes.solutionsBrandLogo}
          brand={brandId as SolutionsBrandType}
        />
        <div>
          <h4 className={classes.brandName}>
            {fullName}
          </h4>
          <p className={classes.description}>
            <FormattedMessage
              id="myLensesDetailed.solutions.description"
            />
          </p>
        </div>
      </div>
      {isLabelEnabled && (
        <div>
          <NoveltyLabel
            className={classes.noveltyLabel}
            label={labelName}
          />
        </div>
      )}
    </li>
  );
};
