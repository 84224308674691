import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  Observable,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { user } from './user/user.field';
import { cms } from './cms/cms.field';
import { preloader } from './preloader/preloader.field';
import { ecpLocator } from './ecp-locator/ecp-locator.field';
import { order } from './order/order.field';
import { getXApiKeyHeader } from '../utils/request-headers/get-x-api-key-header';
import { getXAppVersionHeader } from '../utils/request-headers/get-x-app-version-header';
import { getAuthorizationHeader } from '../utils/request-headers/get-authorization-header';
import { scheduleAppUpdate } from '../utils/app-update/schedule-app-update';
import { isTokenExpired } from '../utils/response/is-token-expired';

const callbacks: { [key: string]: () => void | Promise<string> } = {};

export const setCallback = (eventName: string, callback: () => void) => {
  callbacks[eventName] = callback;
};

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
  headers: {
    'x-api-key': getXApiKeyHeader(),
    'X-APP-Version': getXAppVersionHeader(),
  },
});

const httpLinkAnonymous = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ANONYMOUS_API_URL,
  headers: {
    'x-api-key': getXApiKeyHeader(),
    'X-APP-Version': getXAppVersionHeader(),
  },
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: getAuthorizationHeader(),
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ networkError, forward, operation }) => {
  if (networkError) {
    const { statusCode, result: response } = networkError as ServerError;

    switch (true) {
      case statusCode === 401:
        if (callbacks.onTokenExpired && isTokenExpired(response)) {
          return new Observable((observer) => {
            (callbacks.onTokenExpired() as Promise<string>)
              .then((token) => {
                let oldHeaders = operation.getContext().headers;

                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    Authorization: getAuthorizationHeader(token),
                  },
                });

                forward(operation).subscribe((result) => observer.next(result));
              });
          });
        }
        break;
      case statusCode === 426:
        scheduleAppUpdate();
        break;
      default:
        if (callbacks.onUnknownErrorReceived) {
          callbacks.onUnknownErrorReceived();
        }
    }
  }
});

const linkAuthorized = ApolloLink.from([authLink, errorLink, httpLink]);
const linkAnonymous = ApolloLink.from([errorLink, httpLinkAnonymous]);

export const graphqlClient = new ApolloClient({
  link: ApolloLink.split(
    operation => operation.getContext().clientName === 'anonymous',
    linkAnonymous,
    linkAuthorized,
  ),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          user,
          cms,
          preloader,
          ecpLocator,
          order,
        },
      },
    },
  }),
});
