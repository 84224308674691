import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { EcpStoreType } from '../../../../../../rest/ecp-locator';
import { Button } from '../../../../../common';
import { IAppointmentProps } from './appointment.models';
import {
  AppointmentRequestButton,
} from '../../../appointment-request-button/appointment-request-button.container';
import { useStyles } from './appointment.styles';

export const Appointment = ({ appointmentUrl, type, handleFilterOptics }: IAppointmentProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      {type === EcpStoreType.retailer ? (
        <div className={classes.retailerMessage}>
          <p
            className={classes.retailerMessageText}
            data-testid="filter-message"
          >
            {intl.formatMessage(
              { id: 'ecp.retailerMessage.description' },
              { link: (chunk: ReactNode) => (
                  <Button
                    to={handleFilterOptics}
                    variant="text"
                    labelClassName="normal"
                    data-testid="filter-message-link"
                    className={classes.retailerMessageLink}
                  >
                    {chunk}
                  </Button>
              ) },
            )}
          </p>
        </div>
        // @ts-ignore
      ) : <AppointmentRequestButton appointmentUrl={appointmentUrl} />}
    </>
  );
};
