import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    [breakpoints.up('sm')]: {
      padding: '32px 32px 44px',
    },
  },
  title: {
    height: 'auto',
    margin: 0,
  },
}));
