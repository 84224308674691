import { FormattedMessage } from 'react-intl';

import { IUnsavedChangesConfirmationModal } from './unsaved-changes-confirmation-modal.models';
import { ConfirmationModal } from '../confirmation-modal';

export const UnsavedChangesConfirmationModal = ({
  pageName, title, content, rejectButtonLabel, rejectButtonAriaLabel, confirmButtonLabel,
  confirmButtonAriaLabel, topIcon, hasChangedButtonsPriority, responsiveWidth, titleStyles,
  contentStyles, ...props
}: IUnsavedChangesConfirmationModal,
): JSX.Element => {
  const typeId = pageName ? 'withPageName' : 'default';

  return (
    <ConfirmationModal
      {...props}
      data-testid="unsaved-changes-confirmation-modal"
      title={title ? title : <FormattedMessage id="unsavedChanges.modal.title" />}
      topIcon={topIcon}
      content={content ? content : (
        <FormattedMessage
          id={`unsavedChanges.modal.description.${typeId}`}
          values={{
            pageName,
          }}
        />
      )}
      confirmButtonLabel={confirmButtonLabel
        ? confirmButtonLabel
        : <FormattedMessage id="unsavedChanges.modal.confirmButton.label" />}
      rejectButtonLabel={rejectButtonLabel
        ? rejectButtonLabel
        : <FormattedMessage id="unsavedChanges.modal.rejectButton.label" />}
      rejectOnClose
      rejectButtonAriaLabel={rejectButtonAriaLabel}
      confirmButtonAriaLabel={confirmButtonAriaLabel}
      hasChangedButtonsPriority={hasChangedButtonsPriority}
      responsiveWidth={responsiveWidth}
      contentStyles={contentStyles}
      titleStyles={titleStyles}
    />
  );
};
