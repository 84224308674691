import { FC, ReactNode } from 'react';
import { differenceInCalendarDays, format } from 'date-fns';
import { cx } from '@emotion/css';

import { useStyles } from './date-expired-label.styles';
import { FormattedMessage } from 'react-intl';
import { Icon, IconSizes, IconTypes } from '../icon';
import { UI_DATE_FORMAT } from '../../../constants';

const LABEL_ALERT_TIME = 7;

export const DateExpiredLabel: FC<{ endDate: string | ReactNode }> = ({ endDate }): JSX.Element => {
  const classes = useStyles();
  const daysLeft = (endDate && typeof endDate === 'string') ? differenceInCalendarDays(new Date(endDate), new Date()) : null;
  const isAlertTime = typeof daysLeft === 'number' && (daysLeft < LABEL_ALERT_TIME || daysLeft === LABEL_ALERT_TIME);
  const formattedDate = (endDate && typeof endDate === 'string') ? format(new Date(endDate), UI_DATE_FORMAT) : endDate;

  const renderLabelMessage = () => {
    switch (true) {
      case daysLeft === 0:
        return (
          <span data-testid="endDate-today-message">
            <FormattedMessage
              id="common.endDate.today.label"
            />
          </span>
        );
      case Number(daysLeft) > LABEL_ALERT_TIME:
        return (
          <span data-testid="endDate-moreThanWeek-message">
            <FormattedMessage
              id="common.endDate.moreThanWeek.label"
              values={{ endDate: formattedDate }}
            />
          </span>
        );
      default:
        return (
          <span data-testid="endDate-lessThanWeek-message">
            <FormattedMessage
              id="common.endDate.lessThanWeek.label"
              values={{ daysLeft }}
            />
          </span>
        );
    }
  };

  return (
    <div
      className={cx(classes.root, {
        [classes.alert]: isAlertTime,
      })}
      data-testid="date-expired-label"
    >
      <Icon
        className={cx(classes.icon, {
          [classes.iconAlert]: isAlertTime,
        })}
        type={IconTypes.clockBlack}
        size={IconSizes.xs}
      />
      {daysLeft || daysLeft === 0 ? renderLabelMessage() : endDate}
    </div>
  );
};
