import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  fontWeights,
}: Theme) => ({
  root: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    [breakpoints.up('md')]: {
      paddingTop: '4rem',
      paddingBottom: '4rem',
    },
  },
  title: {
    margin: '0 0 2rem',
    fontFamily: fontFamilies.primary,
    fontSize: '1.75rem',
    fontWeight: fontWeights.medium,
    lineHeight: 1.35,
  },
  description: {
    margin: '0 0 0.5rem',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  list: {
    paddingLeft: '1.25rem',
    margin: '0.5rem 0',
    '& li': {
      marginBottom: '0.5rem',
    },
    '& li:last-child': {
      marginBottom: 0,
    },
  },
  link: {
    display: 'inline',
  },
}));
