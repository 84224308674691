export enum ConsumerRequestUrls {
  updatePin = 'consumer/update/pin',
  updatePhone = 'consumer/update/phone',
  confirmPhone = 'consumer/update/phone/confirm',
  register = 'consumer/registration',
  disable = 'consumer/disable',
  callbackRequest = 'consumer/callback-request',
  pinCodeSend = 'consumer/pin-code/send',
  pinCodeConfirm = 'consumer/pin-code/confirm',
  updatePassword = 'consumer/update-password',
}
