import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  fontSizes,
  fontWeights,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  title: {
    margin: 0,
    fontFamily: fontFamilies.primary,
    fontSize: '1.25rem',
    fontWeight: fontWeights.medium,
    lineHeight: 1.4,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '4rem',
    height: '4rem',
    '& img': {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
    },
  },
  list: {
    listStyleType: 'disc',
    paddingLeft: '1.25rem',
    '& li': {
      marginBottom: '0.5rem',
    },
    '& li:last-child': {
      marginBottom: 0,
    },
    '& + p': {
      marginTop: '0.5rem',
    },
  },
  link: {
    display: 'inline',
    lineHeight: 1.5,
  },
  paragraph: {
    margin: '0 0 0.5rem',
    '&:last-of-type': {
      marginBottom: 0,
    },
    '& + ul': {
      marginTop: '0.5rem',
    },
  },
}));
