import { FormattedMessage } from 'react-intl';
import { useModal } from 'mui-modal-provider';

import { BonusCardProps } from './points-card.models';
import {
  BonusCard as BonusCardBase,
  Icon,
  IconSizes,
  IconTypes,
} from '../../../../../../../../common';
import { useStyles } from './points-card.styles';
import { DeleteBonusModal } from './delete-bonus-modal';

export const PointsCard = ({ onBonusSelect }: BonusCardProps): JSX.Element => {
  const classes = useStyles();
  const { showModal } = useModal();

  const handleCardSelect = () => {
    showModal(DeleteBonusModal, {
      onDeleteConfirm: onBonusSelect,
    });
  };

  return (
    <BonusCardBase
      data-testid="points-card"
      isAvailable
      cardTitle={<FormattedMessage id="order.bonuses.points" />}
      bonusImage={(
        <Icon
          type={IconTypes.accumulatePoints}
          size={IconSizes['3xl']}
        />
      )}
      onClick={handleCardSelect}
      titleStyles={classes.title}
      rootStyles={classes.root}
    />
  );
};
