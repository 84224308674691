import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  icon: {
    marginBottom: 20,
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      marginBottom: 0,
    },
    '& svg': {
      width: 180,
      height: 100,
    },
  },
}));
