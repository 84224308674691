import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { yup } from '../services/yup';
import { passwordFormatRegx } from '../constants';

export interface IPasswordErrors {
  requiredErrorMessageId?: string;
}

export const usePasswordValidation = (errors?: IPasswordErrors) => {
  const intl = useIntl();

  return useMemo(
    () => yup
      .string()
      .required(intl.formatMessage({ id: errors?.requiredErrorMessageId || 'common.errors.required' }))
      .max(6, intl.formatMessage({ id: 'common.errors.password.maxLength' }))
      .min(6, intl.formatMessage({ id: 'common.errors.password.minLength' }))
      .matches(passwordFormatRegx, intl.formatMessage({ id: 'common.errors.password.format' })),
    [intl, errors],
  );
};
