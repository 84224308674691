import { gql } from '@apollo/client';

export const UPDATE_VISION_PROFILE = gql`
  mutation UpdateBirthday($visionProfile: GQLVisionProfileInput) {
    patchVisionProfile(visionProfile: $visionProfile) {
      dateOfBirth
      gadgetsUseDuration
      lastVisionCheck
      meta {
        canUpdateDateOfBirth
      }
      timeOutsideDuration
      wearRegularity
      whatDoYouLikeCustom
      whatDoYouLikePredefined
    }
  }
`;
