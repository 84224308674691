import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, palette, fontSizes, lineHeights, fontWeights,
}: Theme) => ({
  root: {
    padding: '17px 0',
    textAlign: 'center',
    [breakpoints.up('lg')]: {
      padding: '20px 0',
    },
  },
  button: {
    margin: 0,
    color: palette.primary.main,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
}));
