import React from 'react';

import { EcpLocatorExternal as EcpLocatorView } from './ecp-locator-external';
import { IEcpLocatorProps } from './ecp-locator-external.models';
import { withDefaultFiltersAndStoresList } from './ecp-locator-settings';

const EcpLocatorExternalContainer = (props: IEcpLocatorProps): JSX.Element => (
  <EcpLocatorView
    {...props}
  />
);

export const EcpLocatorExternal = withDefaultFiltersAndStoresList(EcpLocatorExternalContainer);
