import { FormattedMessage } from 'react-intl';

import { WearRegularityOptions } from '../../../../../../../graphql/profile/models/get-vision-profile.models';

export const wearRegularityOptions = [
  {
    label: (
      <FormattedMessage
        id="vp.wearRegularity.options.lessThanOneDayAWeek"
      />
    ),
    value: WearRegularityOptions.lessThanOneDayAWeek,
  },
  {
    label: (
      <FormattedMessage
        id="vp.wearRegularity.options.fromTwoToThreeDays"
      />
    ),
    value: WearRegularityOptions.fromTwoToThreeDays,
  },
  {
    label: (
      <FormattedMessage
        id="vp.wearRegularity.options.fromThreeToFourDays"
      />
    ),
    value: WearRegularityOptions.fromThreeToFourDays,
  },
  {
    label: (
      <FormattedMessage
        id="vp.wearRegularity.options.fromFiveToSixDays"
      />
    ),
    value: WearRegularityOptions.fromFiveToSixDays,
  },
  {
    label: (
      <FormattedMessage
        id="vp.wearRegularity.options.allDays"
      />
    ),
    value: WearRegularityOptions.allDays,
  },
];
