import { FormattedMessage } from 'react-intl';

import { TimeOutsideDuration } from '../../../../../../../graphql/profile/models/get-vision-profile.models';

export const timeOutsideDurationOptions = [
  {
    label: (
      <FormattedMessage
        id="vp.timeOutsideDuration.options.lessThanOneHour"
      />
    ),
    value: TimeOutsideDuration.lessThanOneHour,
  },
  {
    label: (
      <FormattedMessage
        id="vp.timeOutsideDuration.options.fromOneToThreeHours"
      />
    ),
    value: TimeOutsideDuration.fromOneToThreeHours,
  },
  {
    label: (
      <FormattedMessage
        id="vp.timeOutsideDuration.options.moreThanThreeHours"
      />
    ),
    value: TimeOutsideDuration.moreThanThreeHours,
  },
];
