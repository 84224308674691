import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../common/button-new';
import { ICreateButtonProps } from './order-create-button.models';

export const OrderCreateButton = ({ handleClick, isFitted, onClick, to }: ICreateButtonProps): JSX.Element => (
  <>
    {
      isFitted ? (
        <Button
          data-testid="order-create-link"
          style='outlined'
          onClick={onClick}
          to={to}
        >
          <FormattedMessage id="order.create.button.label"/>
        </Button>
      ) : (
        <Button
          onClick={handleClick}
          data-testid="order-create-button"
          style="outlined"
        >
          <FormattedMessage id="order.create.button.label"/>
        </Button>
      )
    }
  </>
);
