import { IMyBonusesSectionProps } from './my-bonuses-section.models';
import { useStyles } from './my-bonuses-section.styles';
import { UserActivitiesList } from '../../../../common/user-activities-list';

export const MyBonusesSection = <T extends { id: string | number }>({
  title: label,
  items,
  ariaLabel,
  children,
  placeholder,
  ...props
}: IMyBonusesSectionProps<T>): JSX.Element => {
  const classes = useStyles();

  return (
    <section
      {...props}
      aria-label={ariaLabel}
      className={classes.section}
    >
      <UserActivitiesList
        title={{ label }}
        activities={items}
        placeholder={placeholder}
      />
    </section>
  );
};
