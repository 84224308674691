import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  spacing,
  lineHeights,
  fontSizes,
}: Theme) => ({
  actions: {
    display: 'grid',
    gap: spacing(3),
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
      gap: spacing(3.5),
    },
  },
}));
