import {
  IEyeParamsOption,
} from '../../../../../../../../graphql/lenses/models/get-lenses.models';

export const compareEyeParams = (
  leftEye: IEyeParamsOption[], rightEye: IEyeParamsOption[],
): boolean => {
  let isSameParams: boolean = true;

  if (leftEye.length === rightEye.length) {
    for (let i = 0; i < leftEye.length; i++) {
      for (const property in leftEye[i]) {
        if (leftEye[i][property as keyof IEyeParamsOption] !== rightEye[i][property as keyof IEyeParamsOption]) {
          isSameParams = false;
          break;
        }
      }
    }
  } else {
    isSameParams = false;
  }

  return isSameParams;
};
