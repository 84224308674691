import { ReactNode } from 'react';

import arlBottle from '../../../../../assets/images/arl/arl-bottle.svg';
import eye from '../../../../../assets/images/arl/eye-with-eyelashes.svg';
import lenses from '../../../../../assets/images/arl/contact-lenses.svg';

export const benefits: { item: string, icon: ReactNode }[] = [{
  item: 'arl.benefits.item1',
  icon: <img alt="" src={arlBottle} />,
}, {
  item: 'arl.benefits.item2',
  icon: <img alt="" src={eye} />,
}, {
  item: 'arl.benefits.item3',
  icon: <img alt="" src={lenses} />,
}];
