import { IVisionProfileQueryData } from './profile.models';

export interface IVisionProfileData extends IVisionProfileQueryData<IVisionProfileFields> {}

export interface IVisionProfileFields {
  dateOfBirth: string | null;
  meta: IVisionProfileMeta;
  gadgetsUseDuration: GadgetsUseDuration | null;
  lastVisionCheck: string | null;
  timeOutsideDuration: TimeOutsideDuration | null;
  wearRegularity: WearRegularityOptions | null;
  whatDoYouLikeCustom: string;
  whatDoYouLikePredefined: WhatDoYouLike[] | null;
}

export interface IVisionProfileMeta {
  canUpdateDateOfBirth: boolean;
}


export enum WearRegularityOptions {
  lessThanOneDayAWeek = 'LESS_THAN_1_DAY_A_WEEK',
  fromTwoToThreeDays = 'FROM_2_TO_3_DAYS_A_WEEK',
  fromThreeToFourDays = 'FROM_3_TO_4_DAYS_A_WEEK',
  fromFiveToSixDays = 'FROM_5_TO_6_DAYS_A_WEEK',
  allDays = 'ALL_7_DAYS_A_WEEK',
}

export enum TimeOutsideDuration {
  lessThanOneHour = 'LESS_THAN_1_HOUR_A_DAY',
  fromOneToThreeHours = 'FROM_1_TO_3_HOURS_A_DAY',
  moreThanThreeHours = 'MORE_THAN_3_HOURS_A_DAY',
}

export enum GadgetsUseDuration {
  lessThanOneHour = 'LESS_THAN_1_HOUR_A_DAY',
  fromOneToThreeHours = 'FROM_1_TO_3_HOURS_A_DAY',
  moreThanThreeHours = 'MORE_THAN_3_HOURS_A_DAY',
}

export enum WhatDoYouLike {
  bicycle = 'BICYCLE',
  driving = 'DRIVING',
  meetingWithFriends = 'MEETING_WITH_FRIENDS',
  pcGames = 'PC_GAMES',
  playingWithChildren = 'PLAYING_WITH_CHILDREN',
  reading = 'READING',
  travel = 'TRAVEL',
  running = 'RUNNING',
  skiing = 'SKIING',
  sport = 'SPORT',
  swimming = 'SWIMMING',
}
