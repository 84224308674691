import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  modal: {
    width: 482,
    maxWidth: '90vw',
    [breakpoints.down('sm')]: {
      height: '90vh',
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
    '& svg': {
      width: 90,
      height: 90,
      [breakpoints.up('sm')]: {
        width: 120,
        height: 120,
      },
    },
  },
  title: {
    textAlign: 'center',
  },
}));
