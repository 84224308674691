import { format } from 'date-fns';

import { BACKEND_DATE_FORMAT, BACKEND_DATE_SHORT_FORMAT } from '../../constants';
import { toDate } from './to-date';

export const convertStringToDate = (stringDate?: string | null): Date | null => (
  stringDate ? toDate(format(new Date(stringDate), BACKEND_DATE_FORMAT)) : null
);

export const convertShortStringToDate = (stringDate?: string | null): Date | null => (
  stringDate ? toDate(format(new Date(stringDate), BACKEND_DATE_SHORT_FORMAT)) : null
);
