import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import arrow from '../../../../../assets/images/reg-guide/arrow.svg';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    position: 'relative',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 64,
    maxWidth: '100%',
    margin: '0 auto 44px',
    padding: 0,
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'stretch',
      gap: 16,
    },
    [breakpoints.up('lg')]: {
      justifyContent: 'space-between',
      gap: 'unset',
      width: 992,
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 260,
    margin: 0,
    padding: '24px 16px 20px',
    borderRadius: 24,
    background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0, rgba(166, 231, 255, 0.5) 100%)',
    boxShadow: '2px 4px 6px 1px rgba(0, 0, 0, 0.25)',
    [breakpoints.up('md')]: {
      width: 360,
      padding: '44px 16px 20px 20px',
      borderRadius: 44,
    },
    [breakpoints.up('lg')]: {
      width: 428,
    },
  },
  arrow: {
    position: 'absolute',
    top: '49%',
    left: '65%',
    width: 90,
    height: 30,
    transform: 'translate(-50%, -50%) rotate(90deg)',
    display: 'block',
    backgroundImage: `url(${arrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    [breakpoints.up('md')]: {
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    [breakpoints.up('lg')]: {
      width: 180,
      height: 50,
    },
  },
}));
