import React from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from '../../../common/footer';
import { useStyles } from './layout-reg-guide.styles';

export const LayoutRegGuide = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      data-testid="layout-reg-guide"
    >
      <div className={classes.headerAndContentWrapper}>
        <Outlet />
      </div>
      <div className={classes.footerWrapper}>
        <Footer legal="regGuide.terms" />
      </div>
    </div>
  );
};
