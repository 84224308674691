import { forwardRef } from 'react';

import { IFormImperativeHandleProps } from '../../../../../common/form';
import { WearRegularityForm } from './wear-regularity-form';
import { IWearRegularityViewProps } from './wear-regularity-view.models';

export const WearRegularityView = forwardRef<
IFormImperativeHandleProps, IWearRegularityViewProps>(({
  formProps,
}, ref): JSX.Element => (
  <WearRegularityForm
    ref={ref}
    formProps={formProps}
  />
));
