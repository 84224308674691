import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { Button } from '../../../../../../common';
import { IProductQuantityProps } from './product-quantity.models';
import { useStyles } from './product-quantity.styles';
import { getBrandFullName } from '../../../../../../../utils/lenses/get-brand-full-name';

export const ProductQuantity = ({
  onRemoveLenses, onAddLenses, brand, isAddActive, isRemoveActive, productQuantity,
}: IProductQuantityProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const brandName = intl.formatMessage({
    id: getBrandFullName(brand),
  }, {
    'sup': (chunks: ReactNode) => chunks,
  });

  return (
    <div
      className={classes.root}
      data-testid="product-quantity"
    >
      <Button
        className={classes.addPackButton}
        onClick={onRemoveLenses}
        data-testid="remove-lenses-button"
        aria-label={intl.formatMessage(
          { id: 'order.create.removePackage.button.ariaLabel' },
          { brand: brandName as string },
        )}
        disabled={!isRemoveActive}
      >
        <FormattedMessage
          id="order.create.removePackage.button.label"
          values={{
            ndash: <>&ndash;</>,
          }}
        />
      </Button>
      <div
        className={cx(classes.inputWrapper)}
        data-testid="input-wrapper"
      >
        <span
          data-testid="product-quantity-field"
          className={classes.packField}
        >
          {productQuantity}
        </span>
        <span
          data-testid="package-number"
          aria-live="polite"
          className={classes.adornment}
        >
          <FormattedMessage
            id="order.create.package.unit"
          />
        </span>
      </div>
      <Button
        className={classes.addPackButton}
        onClick={onAddLenses}
        data-testid="add-lenses-button"
        aria-label={intl.formatMessage(
          { id: 'order.create.addPackage.button.ariaLabel' },
          { brand: brandName as string },
        )}
        disabled={!isAddActive}
      >
        <FormattedMessage
          id="order.create.addPackage.button.label"
        />
      </Button>
    </div>
  );
};
