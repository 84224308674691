import { forwardRef } from 'react';
import { cx } from '@emotion/css';
import { Link } from 'react-router-dom';
import { IconButton as MuiIconButton } from '@mui/material';
import { isFunction } from 'lodash';

import { Icon } from '../icon';
import { IIconButtonProps, IAdditionalProps } from './icon-button.models';
import { useStyles } from './icon-button.styles';

export const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>(({
  iconProps, to, isMarked, ...props
}, ref) => {
  const classes = useStyles();
  const additionalProps: IAdditionalProps = {};

  if (to) {
    if (isFunction(to)) {
      additionalProps.onClick = to;
    } else {
      additionalProps.component = Link;
      additionalProps.to = to;
    }
  }

  return (
    <MuiIconButton
      {...props}
      {...additionalProps}
      ref={ref}
      classes={{
        root: cx(classes.iconButtonRoot, {
          [classes.marked]: isMarked,
        }),
      }}
    >
      <Icon
        {...iconProps}
      />
    </MuiIconButton>
  );
});
