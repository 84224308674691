import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  borderRadiuses, breakpoints, fontSizes, lineHeights, fontWeights,
}: Theme) => ({
  root: {
    padding: '24px 16px 16px',
    borderRadius: borderRadiuses.lg,
    [breakpoints.up('sm')]: {
      padding: 32,
    },
    [breakpoints.up('lg')]: {
      padding: '32px 32px 48px',
    },
  },
  section: {
    width: '100%',
    padding: '0 16px',
    marginBottom: 16,
    [breakpoints.up('sm')]: {
      marginBottom: 32,
    },
    [breakpoints.up('lg')]: {
      maxWidth: 1140,
    },
  },
  title: {
    marginTop: 0,
    marginBottom: 16,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      marginBottom: 24,
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
  },
}));
