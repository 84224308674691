import {
  useRef,
  forwardRef,
  useMemo,
  ComponentType,
} from 'react';
import { useQuery } from '@apollo/client';

import { ICallbackModalProps } from './callback-modal.models';
import { CallbackModal as CallbackModalView } from './callback-modal';
import { GET_IS_USER_LOGGED_IN } from '../../../../graphql/user/queries';
import { IFormImperativeHandleProps } from '../../../common/form';
import { GET_CALLBACK_VALUES } from '../../../../graphql/profile/queries/get-callback-values';
import { ICallbackValuesData } from '../../../../graphql/profile/models/get-callback-values.models';
import { useSnackbar } from '../../../../hooks/use-snackbar';
import { FormattedMessage } from 'react-intl';

const withDefaultFormValues = <T extends object>(
  WrappedComponent: ComponentType<T>,
) => forwardRef<IFormImperativeHandleProps, T>((props, ref): JSX.Element => {
    const { data, loading, error } = useQuery<ICallbackValuesData>(GET_CALLBACK_VALUES);

    return (
      <WrappedComponent
        {...props}
        ref={ref}
        defaultValues={data}
        isLoading={loading}
        isError={error}
      />
    );
  });

export const CallbackModal = ({ onClose, ...props }: ICallbackModalProps): JSX.Element => {
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);
  const callbackFormRef = useRef<IFormImperativeHandleProps>(null);
  const { enqueueSnackbar } = useSnackbar();

  const ViewComponent = useMemo(() => isLoggedIn
    ? withDefaultFormValues(CallbackModalView)
    : CallbackModalView,
  [isLoggedIn]);

  const handleCallbackRequest = () => {
    if (callbackFormRef.current) {
      callbackFormRef.current.submit();
    }
  };

  const handleRequestSucces = () => {
    onClose!();
    enqueueSnackbar(
      <FormattedMessage
        id="callback.modal.successRequest"
      />,
      { variant: 'success' },
    );
  };

  return (
    <ViewComponent
      {...props}
      ref={callbackFormRef}
      isLoggedIn={isLoggedIn}
      onClose={onClose}
      onCallbackRequest={handleCallbackRequest}
      onRequestSuccess={handleRequestSucces}
    />
  );
};
