import React, {
  forwardRef,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { TextField } from '../../../../../../common/form/text-field';
import { IConfirmationCodeStepFormProps } from './confirmation-code-step-form.models';
import { TimerStatusOptions } from '../../../../reset-password/reset-password.models';
import { OTP_MIN_LENGTH } from '../../../../../../../constants';
import { SubmitButton } from '../submit-button/submit-button';

export const ConfirmationCodeStepForm = forwardRef<
IFormImperativeHandleProps, IConfirmationCodeStepFormProps>(({
  onRequestCode,
  onSubmit,
  requestCodeTime,
  timerStatus,
  validationSchema,
  defaultValues,
  onClearCode,
}, ref): JSX.Element => {
  const resultDefaultValues = useMemo(() => defaultValues || {
    otpCode: '',
  }, [defaultValues]);

  const isRequestCodeDisabled = Boolean(requestCodeTime);
  const formRef = ref as RefObject<IFormImperativeHandleProps>;
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isValueValid, setIsValueValid] = useState<boolean>(false);

  useEffect(() => {
    setIsDisabled(isRequestCodeDisabled || timerStatus === TimerStatusOptions.running);
  }, [isRequestCodeDisabled, timerStatus]);

  const handleChangeDependencies = () => {
    if ((formRef.current?.getValues()?.otpCode as string)?.length < OTP_MIN_LENGTH) {
      setIsValueValid(false);
    } else {
      setIsValueValid(true);
    }
  };

  useEffect(() => {
    handleChangeDependencies();
    onClearCode();
  }, []);

  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      defaultValues={resultDefaultValues}
    >
      <TextField
        onChangeDependecies={handleChangeDependencies}
        name="otpCode"
        fullWidth
        autoFocus
        label={(
          <FormattedMessage
            id="registration.confirmationCodeStep.confirmationCodeTextField.label"
          />
        )}
        inputProps={{
          maxLength: OTP_MIN_LENGTH,
        }}
      />
      <SubmitButton
        isDisabled={isDisabled}
        isValueValid={isValueValid}
        onRequestCode={onRequestCode}
        requestCodeTime={requestCodeTime}
      />
    </Form>
  );
});
