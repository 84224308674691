import { useStyles } from './personal-info.styles';
import { IPersonalInfoViewProps } from './personal-info.models';
import { PersonalInfoCard } from './personal-info-card';

export const PersonalInfo = ({ personalInfoCards }: IPersonalInfoViewProps): JSX.Element => {
  const classes = useStyles();

  return (
    <ul className={classes.list} data-testid="personal-info-list">
      {
        personalInfoCards.map(({ title, icon, buttonLabel, description, onItemClick }) => (
          <li
            key={title}
            className={classes.listItem}
            data-testid="personal-info-list-item"
          >
            <PersonalInfoCard
              title={title}
              icon={icon}
              buttonLabel={buttonLabel}
              description={description!}
              onItemClick={onItemClick}
            />
          </li>
        ))
      }
    </ul>
  );
};
