import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  siteMediumWidth, breakpoints, palette, fontFamilies, fontSizes, lineHeights, fontWeights,
}: Theme) => ({
  root: {
    [breakpoints.up('sm')]: {
      maxWidth: siteMediumWidth,
      margin: '0 auto',
    },
  },
  title: {
    marginTop: 0,
    marginBottom: 32,
    color: palette.text.secondary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxl,
    lineHeight: 1.35,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
    [breakpoints.up('lg')]: {
      fontSize: fontSizes.xxxl,
      lineHeight: 1.25,
    },
  },
}));
