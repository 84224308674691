import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontSizes, lineHeights, palette,
}: Theme) => ({
  root: {
    display: 'block',
    margin: '0 auto',
    padding: 0,
    color: palette.primary.dark,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
  },
}));
