import React from 'react';
import { FormattedMessage } from 'react-intl';

import { LoginStep } from './profile-delete-steps/login-step';
import { DeleteStep } from './profile-delete-steps/delete-step/delete-step';
import { NotificationStep } from './profile-delete-steps/notification-step';

export const profileDeleteSteps = [
  {
    StepComponent: LoginStep,
    id: 'login',
    disableNextButton: true,
    title: <FormattedMessage id="myAcuvueProgram.registration.popup.loginButton.label" />,
  },
  {
    StepComponent: DeleteStep,
    id: 'delete',
    disableNextButton: true,
  },
  {
    StepComponent: NotificationStep,
    id: 'notification',
    disableNextButton: true,
    disableBackButton: true,
  },
];
