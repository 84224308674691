import { ExternalLinks } from '../../../../../constants';

export const wikiItems = [
  {
    title: 'wiki.card.everyDay.title',
    description: [
      {
        name: 'wiki.card.everyDay.description.gadgets',
        link: ExternalLinks.visionAndGadgets,
      },
      {
        name: 'wiki.card.everyDay.work',
        link: ExternalLinks.visionAndWork,
      },
      {
        name: 'wiki.card.everyDay.uvProtection',
        link: ExternalLinks.visionAndUV,
      },
      {
        name: 'wiki.card.everyDay.popularProblems',
        link: ExternalLinks.popularProblems,
      },
      {
        name: 'wiki.card.everyDay.astigmatism',
        link: ExternalLinks.astigmatism,
      },
      {
        name: 'wiki.card.everyDay.presbyopia',
        link: ExternalLinks.presbyopia,
      },
    ],
  },
  {
    title: 'wiki.card.types.title',
    description: [
      {
        name: 'wiki.card.types.description.daily',
        link: ExternalLinks.dailyLenses,
      },
      {
        name: 'wiki.card.types.description.weekly',
        link: ExternalLinks.weeklyLenses,
      },
      {
        name: 'wiki.card.types.types',
        link: ExternalLinks.typesOfLenses,
      },
    ],
  },
  {
    title: 'wiki.card.care.title',
    description: [
      {
        name: 'wiki.card.care.description.clean',
        link: ExternalLinks.cleanAndCare,
      },
      {
        name: 'wiki.card.care.description.putIn',
        link: ExternalLinks.putInLenses,
      },
      {
        name: 'wiki.card.care.description.takeOut',
        link: ExternalLinks.takeOutLenses,
      },
      {
        name: 'wiki.card.care.description.tips',
        link: ExternalLinks.usefulTips,
      },
    ],
    horizontal: true,
  },
  {
    title: 'wiki.card.fit.title',
    description: [
      {
        name: 'wiki.card.fit.preparation',
        link: ExternalLinks.fittingPreparation,
      },
      {
        name: 'wiki.card.fit.description.fitting',
        link: ExternalLinks.lensesFitting,
      },
    ],
  },
  {
    title: 'wiki.card.facts.title',
    description: [
      {
        name: 'wiki.card.fit.description.myths',
        link: ExternalLinks.commonMyths,
      },
      {
        name: 'wiki.card.fit.description.benefits',
        link: ExternalLinks.lensesBenefits,
      },
      {
        name: 'wiki.card.facts.contactLenses',
        link: ExternalLinks.contactLensesFacts,
      },
    ],
  },
];
