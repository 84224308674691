import { forwardRef } from 'react';

import { IFormImperativeHandleProps } from '../../../../../common/form';
import { WhatDoYouLikeForm } from './what-do-you-like-form';
import { IWhatDoYouLikeViewProps } from './what-do-you-like-view.models';

export const WhatDoYouLikeView = forwardRef<
IFormImperativeHandleProps, IWhatDoYouLikeViewProps>(({
  formProps, onActivitiesValidateDependencies,
}, ref): JSX.Element => (
  <WhatDoYouLikeForm
    ref={ref}
    formProps={formProps}
    onActivitiesValidateDependencies={onActivitiesValidateDependencies}
  />
));
