import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from './notes-list.styles';
import { INotesListItem } from './notes-list.models';

export const NotesList = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  const notes: INotesListItem[] = [{
    item: 'outOfSalesModal.contactUs.notes.note1',
  }, {
    item: 'outOfSalesModal.contactUs.notes.note2',
  }, {
    item: 'outOfSalesModal.contactUs.notes.note3',
  }, {
    item: 'outOfSalesModal.contactUs.notes.note4',
  }, {
    item: 'outOfSalesModal.contactUs.notes.note6',
  }, {
    item: 'outOfSalesModal.contactUs.notes.note7',
  }, {
    item: 'outOfSalesModal.contactUs.notes.note8',
  }, {
    item: 'outOfSalesModal.contactUs.notes.note9',
  }, {
    item: 'outOfSalesModal.contactUs.notes.note10',
  }];

  return (
    <ul
      className={classes.root}
      data-testid="notes-list"
    >
      {notes.map(({ item }, index) => (
        <li
          className={classes.item}
          data-testid="notes-list-item"
          key={`notes-item-${index}`}
        >
          {intl.formatMessage(
            { id: item },
            {
              sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            },
          )}
        </li>
      ))}
    </ul>
  );
};
