import {
  forwardRef,
  ReactNode,
  useImperativeHandle, useMemo,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';

import { PasswordStepForm } from './password-step-form';
import { yup } from '../../../../../../services/yup';
import { usePasswordValidation } from '../../../../../../hooks/use-password-validation';
import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { IStepsState } from '../../registration.models';

export const PasswordStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  stepsState,
}, ref): JSX.Element => {
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const intl = useIntl();

  const passwordValidation = usePasswordValidation();

  const validationSchema = useMemo(() => yup.object({
    pin: passwordValidation,
    authoriseJnj: yup.bool()
      .oneOf([true], intl.formatMessage({ id: 'registration.passwordStep.errors.authoriseJnj.required' })),
    tnc: yup.bool()
      .oneOf([true], () => (
        <>
          {intl.formatMessage(
            { id: 'registration.passwordStep.errors.tnc.required' },
            { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
          )}
        </>
      )),
  }), [intl, passwordValidation]);

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return formRef.current!.getValues();
    },
  }));

  return (
    <PasswordStepForm
      ref={formRef}
      onSubmit={onGoNextSuccess}
      validationSchema={validationSchema}
      defaultValues={(stepsState as IStepsState).password}
    />
  );
});
