import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import emailIcon from '../../../assets/images/icons/social-media/email.svg';

export const useStyles = makeStyles(({
  palette,
  fontSizes,
}: Theme) => ({
  description: {
    fontSize: fontSizes.xs,
    maxWidth: 'unset',
    marginTop: 0,
    marginBottom: '0.75rem',
    color: palette.grey.A100,
  },
  contactWrapper: {
    display: 'flex',
    gap: 12,
  },
  social: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '3rem',
    height: '3rem',
    padding: '0.5rem',
    borderRadius: '1rem',
    background: `${palette.common.white} no-repeat url(${emailIcon}) center`,
    backgroundSize: '2rem auto',
  },
}));
