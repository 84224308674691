import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import benefitBg from '../../../../../assets/images/reg-guide/benefit-bg.png';

export const useStyles = makeStyles(({
  fontSizes, fontWeights, palette, lineHeights, breakpoints,
}: Theme) => ({
  root: {
    padding: '30px 50px 50px',
    backgroundColor: palette.primary.dark,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '200px 150px',
    backgroundPosition: 'center bottom',
    [breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  title: {
    marginTop: 0,
    marginBottom: 20,
    color: palette.common.white,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    textAlign: 'center',
  },
  list: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 180,
    width: 200,
    padding: '24px 10px',
    backgroundImage: `url(${benefitBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    color: palette.common.white,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
    '&:not(:last-child)': {
      marginBottom: 16,
      [breakpoints.up('md')]: {
        marginBottom: 0,
        marginRight: 16,
      },
    },
    '&:nth-of-type(2)': {
      '& img': {
        width: 140,
        height: 100,
      },
    },
    '&:nth-of-type(3)': {
      '& span': {
        maxWidth: '100%',
      },
    },
  },
}));