import { HTMLAttributes, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { IOutOfSalesModalProps } from './out-of-sales-modal.models';
import { useStyles } from './out-of-sales-modal.styles';
import {
  Button,
  Icon,
  IconTypes,
  Modal,
  ResponsiveWidth,
} from '../../../../common';
import { Title } from './title';
import { BonusesList } from './bonuses-list';
import { ConditionsList } from './conditions-list';
import { HowToSwitchList } from './how-to-switch-list';
import { InternalLinks } from '../../../../../constants';
import { ContactUsList } from './contact-us-list';
import { NotesList } from './notes-list';
import { Breakpoints } from '../../../../../themes/default';
import packageImageSource from '../../../../../assets/images/out-of-sales-true-eye/switch-to-new.png';

export const OutOfSalesModal = ({ isAlternative, ...props }: IOutOfSalesModalProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  const width = window.innerWidth;
  const isMobileWidth = width < Breakpoints.md;
  const newBrandDescription = isAlternative
    ? 'outOfSalesModal.newBrand.description'
    : 'outOfSalesModal.newBrand.description.try';
  const ecpButtonLabel = isAlternative
    ? 'outOfSalesModal.switch.button.label.find'
    : 'outOfSalesModal.switch.button.label';
  const titleSwitch = isAlternative
    ? 'outOfSalesModal.title.switch.choose'
    : 'outOfSalesModal.title.switch';

  return (
    <Modal
      {...props}
      responsiveWidth={ResponsiveWidth.lg}
      propsPaperStyles={classes.propsPaperStyles}
      propsContentStyles={classes.propsContentStyles}
      data-testid="out-of-sales-modal"
      PaperProps={{ 'data-testid': 'out-of-sales-modal-paper' } as HTMLAttributes<HTMLDivElement>}
      content={(
        <>
          <div
            className={isAlternative ? classes.header : cx(classes.header, classes.headerAdditional)}
            data-testid="header-section"
          >
            <Title isAlternative={isAlternative} />
            <img
              className={isAlternative
                ? classes.packageImage
                : cx(classes.packageImage, classes.packageImageLarger)}
              data-testid="switch-package-image"
              src={packageImageSource}
              alt=""
            />
          </div>
          <h3
            className={classes.title}
            data-testid="title-conditions"
          >
            {intl.formatMessage(
              { id: 'outOfSalesModal.title.conditions' },
              {
                sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                noBreak: (chunks: ReactNode) => (
                  <span className={classes.noBreak} data-testid="no-break">{chunks}</span>
                ),
              },
            )}
          </h3>
          <ConditionsList />
          <div>
            {intl.formatMessage(
              { id: newBrandDescription },
              {
                p: (chunks: ReactNode) => (
                  <p
                    data-testid="partial-new-brand"
                    className={cx(classes.partial, classes.inline)}
                  >{chunks}</p>
                ),
                strong: (chunks: ReactNode) => <span className={classes.strongText}>{chunks}</span>,
                sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                noBreak: (chunks: ReactNode) => (
                  <span className={classes.noBreak} data-testid="no-break">{chunks}</span>
                ),
              },
            )}
          </div>
          <h3
            className={cx(classes.title, classes.titleSwitch)}
            data-testid="title-switch"
          >
            {intl.formatMessage(
              { id: titleSwitch },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </h3>
          <div
            data-testid="switch-section-wrapper"
            className={classes.switchSection}
          >
            <div
              data-testid="switch-section-inner"
              className={classes.switchSectionInner}
            >
              <p className={classes.switchGeneral}>
                <FormattedMessage id="outOfSalesModal.switch.description.general" />
              </p>
              <BonusesList />
            </div>
            <div
              data-testid="switch-section-image-wrapper"
              aria-hidden="true"
              className={classes.newBrand}
            >
              {isMobileWidth ? (
                <Icon
                  type={IconTypes.aoh1dMobile}
                />
              ) : (
                <Icon
                  type={IconTypes.aoh1d}
                />
              )}
            </div>
          </div>
          {!isAlternative && (
            <div data-testid="switch-section-fitting">
              {intl.formatMessage(
                { id: 'outOfSalesModal.switch.description.fitting' },
                {
                  p: (chunks: ReactNode) => (
                    <p
                      data-testid="partial-switch"
                      className={cx(classes.partial, classes.inline)}
                    >{chunks}</p>
                  ),
                  sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                },
              )}
            </div>
          )}
          <Button
            data-testid="ecp-link"
            to={InternalLinks.ecpLocator}
            variant="contained"
            className={classes.ecpLink}
          >
            <FormattedMessage id={ecpButtonLabel} />
          </Button>
          {!isAlternative && (
            <>
              <h3
                className={classes.title}
                data-testid="title-how-to-switch"
              >
                <FormattedMessage id="outOfSalesModal.title.howToSwitch" />
              </h3>
              <HowToSwitchList />
            </>
          )}
          <h3
            className={classes.title}
            data-testid="title-contact-us"
          >
            <FormattedMessage
              id="outOfSalesModal.title.contactUs"
            />
          </h3>
          <p
            className={classes.contactDescription}
            data-testid="contact-us-description"
          >
            <FormattedMessage id="outOfSalesModal.contactUs.description" />
          </p>
          <ContactUsList />
          <NotesList />
        </>
      )}
    />
  );
};
