import { ReactNode } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { useStyles } from './reg-step.styles';
import { IRegStepProps } from './reg-step.models';
import { Button } from '../../../../../common';
import { ReactComponent as InfoLogo } from '../../../../../../assets/images/info-logo.svg';
import { InternalLinks } from '../../../../../../constants';


export const RegStep = ({
  icon, title, description, actionHref, handleAction, actionContent,
}: IRegStepProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const handleActionClick = () => {
    handleAction!();
  };

  return (
    <>
      <span
        aria-hidden="true"
        className={classes.iconWrapper}
        data-testid="step-icon-wrapper"
      >
        {icon}
      </span>
      <h2
        data-testid="step-title"
        className={classes.title}
      >
        {intl.formatMessage(
          { id: title },
          {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
          },
        )}
      </h2>
      <p
        data-testid="step-description"
        className={classes.description}
      >
        {intl.formatMessage(
          { id: description },
          {
            a: (chunks: ReactNode) => <a className={classes.link} href="mailto:acuvue@jnj.ru">{chunks}</a>,
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
          },
        )}
      </p>
      {actionHref && (
        <a
          data-testid="action-link"
          href={isMobile ? intl.formatMessage({ id: actionHref }) : InternalLinks.home}
          className={classes.actionButton}
        >
          <FormattedMessage id={actionContent} />
        </a>
      )}

      {handleAction && (
      <>
        <Button
          data-testid="action-button"
          color="secondary"
          variant="contained"
          className={`${classes.actionButton} ${classes.buttonStyle}`}
          onClick={handleActionClick}
         >
          <span className={classes.buttonContent}>
            <InfoLogo className={classes.logo} />
             <span>
              {intl.formatMessage(
                { id: actionContent },
                {
                  sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                },
              )}
             </span>
          </span>
        </Button>
      </>
      )}
        </>
  );
};