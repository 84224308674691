import { IScreenState } from '../models/screen-block.model';
import { screenState } from '../user.cache';

export const setStateToBlocked = (unblock: IScreenState['unblock']) => {
  screenState({ isScreenBlocked: true, unblock });
};

export const setStateToUnblock = () => {
  screenState({ isScreenBlocked: false, unblock: null });
};

export const useScreenState = () => ({
  setStateToBlocked,
  setStateToUnblock,
});
