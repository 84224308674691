import React, { useCallback } from 'react';
import { useFormContext, useController } from 'react-hook-form';

import { DatePicker as DatePickerBase, IDatePickerProps } from '../../date-picker';
import { TFormExtendedProps, IAdditionalFormProps } from '..';

export const DatePicker = ({
  name,
  helperText,
  onChangeDependecies,
  ...props
}: TFormExtendedProps<IDatePickerProps>): JSX.Element => {
  const {
    control, formState: { errors },
  } = useFormContext();
  const {
    field: { ref, onChange, ...restField },
    fieldState: { invalid },
  } = useController({
    name,
    control,
  });
  const additionalProps: IAdditionalFormProps = {};

  if (invalid) {
    additionalProps.error = true;
    additionalProps.helperText = errors[name]?.message;
  } else if (helperText) {
    additionalProps.helperText = helperText;
  }

  const handleChange = useCallback((...values: unknown[]) => {
    onChange(...values);

    if (onChangeDependecies) {
      onChangeDependecies();
    }
  }, [onChange, onChangeDependecies]);

  return (
    <DatePickerBase
      {...props}
      {...restField}
      {...additionalProps}
      onChange={handleChange}
    />
  );
};
