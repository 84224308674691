import { ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { LensesBrandLogo, Button } from '../../../../../../../../../common';
import { IProductCardProps } from './product-card.models';
import { useStyles } from './product-card.styles';
import { getBrandFullName } from '../../../../../../../../../../utils/lenses/get-brand-full-name';
import {
  LensesBrandType,
  SolutionsBrandType,
} from '../../../../../../../../../../graphql/lenses/models/get-lenses.models';
import { SolutionsBrandLogo } from '../../../../../../../../../common/solutions-brand-logo';

export const ProductCard = ({
  brand, id, onCardClick, onModalClose,
}: IProductCardProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  const fullNameId = useMemo(() => {
    return getBrandFullName(brand);
  }, [brand]);

  const handleCardClick = () => {
    onCardClick!(id);
    onModalClose();
  };

  const isSolutionsBrand = brand === SolutionsBrandType.arl;

  return (
    <Button
      className={isSolutionsBrand ? classes.rootSolutions : classes.root}
      data-testid="product-card"
      onClick={handleCardClick}
    >
      {isSolutionsBrand ? (
        <span className={classes.imageSolutionsWrapper}>
          <SolutionsBrandLogo
            brand={brand as SolutionsBrandType}
            className={classes.imageSolutions}
          />
        </span>
      ) : (
        <LensesBrandLogo
          brand={brand as LensesBrandType}
          className={classes.image}
        />
      )}
      <h3
        className={classes.brand}
        data-testid="brand-wrapper"
      >
        <span data-testid="brand-name">
          {intl.formatMessage(
            { id: fullNameId },
            { 'sup': (chunks: ReactNode) => <sup>{chunks}</sup> },
          )}
        </span>
      </h3>
    </Button>
  );
};
