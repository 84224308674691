import { OrderStatus } from '../../../../../../graphql/order/models/get-orders.models';

export const getOrderStatus = (status?: OrderStatus) => {
  return status === OrderStatus.canceled
    || status === OrderStatus.canceledByPatient
    || status === OrderStatus.fulfilled;
};

export const getIsCancelledOrder = (status?: OrderStatus) => {
  return status === OrderStatus.canceled
    || status === OrderStatus.canceledByPatient;
};

export const getIsFulfilledOrder = (status?: OrderStatus) => {
  return status === OrderStatus.fulfilled;
};

export const getIsConfirmedOrder = (status?: OrderStatus) => {
  return status === OrderStatus.confirmed;
};
