import { EyeParamsType } from '../../../../graphql/lenses/models/get-lenses.models';

export const getFormattedValue = (
  value: string, property: EyeParamsType, isOrder?: boolean,
): string => {
  let resultValue: string;

  switch (property) {
    case EyeParamsType.baseCurve:
    case EyeParamsType.power:
    case EyeParamsType.cylinder:
      if (property === EyeParamsType.power && value.slice(0, 1) !== '-' && value !== '0') {
        resultValue = `+${Intl.NumberFormat('ru-Ru', { minimumFractionDigits: 1 })
          .format(Number(value))}`;
      } else {
        resultValue = Intl.NumberFormat('ru-Ru', { minimumFractionDigits: 1 })
          .format(Number(value));
      }
      break;
    case EyeParamsType.addition:
      if (value === 'Средняя (MID)' && isOrder) {
        resultValue = 'MID';
      } else if (value === 'Слабая (LOW)' && isOrder) {
        resultValue = 'LOW';
      } else if (value === 'Высокая (HIGH)' && isOrder) {
        resultValue = 'HIGH';
      } else {
        resultValue = value;
      }
      break;
    default:
      resultValue = value;
      break;
  }

  return resultValue;
};
