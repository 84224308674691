import { ReactNode, HTMLAttributes } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReactiveVar } from '@apollo/client';

import { Button } from '../../../../../../common/button';
import { Modal } from '../../../../../../common/modal';
import { IBirthdayPointsModalViewProps } from './birthday-points-modal.models';
import { useStyles } from './birthday-points-modal.styles';
import { InternalLinks } from '../../../../../../../constants';
import { ReactComponent as BirthdayPieImage } from '../../../../../../../assets/images/birthday-pie.svg';
import { setIsFreepacks } from '../../../../../../../graphql/configuration/configuration.cache';

export const BirthdayPointsModal = ({
  birthdayPoints, onClose, ...props
}: IBirthdayPointsModalViewProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const isFreepacksEnabled = useReactiveVar(setIsFreepacks);
  const description = isFreepacksEnabled
    ? 'birthdayPoints.description.withFreepacks'
    : 'birthdayPoints.description';

  return (
    <Modal
      {...props}
      onClose={onClose}
      PaperProps={{
        'data-testid': 'birthday-points-modal',
      } as HTMLAttributes<HTMLDivElement>}
      content={(
        <>
          <div
            className={classes.pieImage}
            data-testid="birthday-pie-image"
          >
            <BirthdayPieImage />
          </div>
          <h2 className={classes.title}>
            <FormattedMessage
              id="birthdayPoints.title"
            />
          </h2>
          <div className={classes.content}>
            {intl.formatMessage(
              { id: description },
              {
                p: (chunks: ReactNode) => <p data-testid="partial" className={classes.partial}>{chunks}</p>,
                sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                birthdayPoints,
              },
            )}
          </div>
        </>
      )}
      footer={(
        <Button
          to={InternalLinks.myBonusesPage}
          fullWidth
          variant="contained"
          data-testid="view-points-button"
          onClick={onClose as () => Promise<void>}
        >
          <FormattedMessage
            id="birthdayPoints.button.label"
          />
        </Button>
      )}
    />
  );
};
