import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  mapWrapper: {
    width: '100%',
    height: 'calc(100vh - 129px)',
    maxHeight: '100%',
    [breakpoints.up('xl')]: {
      height: 'calc(100vh - 120px)',
      maxHeight: '100vh',
    },
  },
  mapWrapperScrolled: {
    [breakpoints.up('xl')]: {
      height: '100vh',
    },
  },
  mapContainerStyle: {
    width: '100%',
    height: '100%',
  },
}));
