import { setUserLocation } from '../ecp-locator.cache';

type TEcpMapUserLocation = {
  latitude?: number;
  longitude?: number;
};

const setEcpUserLocationToCache = (location: TEcpMapUserLocation) => {
  setUserLocation(location);
};

export const useEcpUserLocation = () => ({
  setEcpUserLocationToCache,
});
