import { gql } from '@apollo/client';

export const GET_APPOINTMENTS = gql`
  query GetAppointmentsQuery {
    allAppointmentsByAuthId(after: "0", sort: [{sort: "DESC", name: "createdDateTime"}], first: 10) {
      edges {
        node {
          id
          date
          status
          appointmentType
          store {
            id
            displayName
            storeType
            storeSubType
            phone
            workingHours
            address
            latitude
            longitude
          }
          salesforceStoreInfo {
            rating
          }
          time
        }
      }
    }
  }
`;
