import React, { forwardRef } from 'react';

import { IFormImperativeHandleProps } from '../../../../common/form';
import { ICallbackModalFormProps } from './callback-modal-form.models';
import { RegisterUserForm } from './register-user-form';
import { UnregisteredUserForm } from './unregistered-user-form';

export const CallbackModalForm = forwardRef<IFormImperativeHandleProps, ICallbackModalFormProps>(
  ({ isLoggedIn, defaultValues, onRequestSuccess }, ref): JSX.Element => (
    <>
      {isLoggedIn ? (
        <RegisterUserForm
          ref={ref}
          defaultValues={defaultValues!}
          onRequestSuccess={onRequestSuccess}
        />
      ) : (
        <UnregisteredUserForm
          ref={ref}
          onRequestSuccess={onRequestSuccess}
        />
      )}
    </>
  ),
);
