import { gql } from '@apollo/client';

export const GET_BONUSES_PREVIEW = gql`
  query GetBonusesPreviewQuery {
    pointByAuthId {
      id
      eligibleBalance
    }
    voucherByAuthId {
      id
      type
      name
    }
  }
`;
