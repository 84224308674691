import { EyeParamsType } from '../../../../graphql/lenses/models/get-lenses.models';

export const getUnits = (property: EyeParamsType) => {
  switch (property) {
    case EyeParamsType.axis:
      return '°';
    case EyeParamsType.baseCurve:
      return ' mm';
    default:
      return '';
  }
};
