import { format } from 'date-fns';

import { BACKEND_DATE_FORMAT, UI_DATE_FORMAT } from '../../constants';

export const convertDateToString = (date?: Date | string | null): string | null => {
  const formattedDate = typeof date === 'string' ? new Date(date) : date;
  return date ? format(formattedDate as Date, BACKEND_DATE_FORMAT) : null;
};

export const convertDateToUIString = (date?: Date | string | null): string | null => {
  const formattedDate = typeof date === 'string' ? new Date(date) : date;
  return date ? format(formattedDate as Date, UI_DATE_FORMAT) : null;
};
