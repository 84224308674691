export interface IConfiguration {
  configuration: IConfigurationParams[];
}

export interface IConfigurationParams {
  configuration: string;
  name: string;
}

export enum ConfigurationNames {
  locatorIcons = 'locator-icons',
  freepacks = 'freepacks',
  captcha = 'captcha',
  c2c = 'c2c-phases',
}
