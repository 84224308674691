import { getBonusesTutorialFromStorage, setBonusesTutorialToStorage } from '../../../utils/user';
import { isBonusesTutorialShown, bonusesTutorial } from '../user.cache';

export const setPointsTutorialShown = () => {
  setBonusesTutorialToStorage();
  bonusesTutorial(getBonusesTutorialFromStorage());
  isBonusesTutorialShown(!!bonusesTutorial());
};

export const usePointsTutorial = () => ({
  setPointsTutorialShown,
});
