import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CheckboxGroup } from '../../../../common/form/checkbox-group';
import { ICheckboxGroupItem } from '../../../../common/checkbox-group';
import { IProfileNotificationsProps } from './profile-notifications.models';
import { NotificationsType } from '../../../../../graphql/profile/models/get-profile.models';

const checkboxGroupItems: ICheckboxGroupItem[] = [{
  label: (
    <FormattedMessage
      id="profile.checkboxesItem.email"
    />
  ),
  value: NotificationsType.email,
}, {
  label: (
    <FormattedMessage
      id="profile.checkboxesItem.sms"
    />
  ),
  value: NotificationsType.sms,
}, {
  label: (
    <FormattedMessage
      id="profile.checkboxesItem.push"
    />
  ),
  value: NotificationsType.push,
}];

export const ProfileNotifications = ({ name }: IProfileNotificationsProps): JSX.Element => (
  <CheckboxGroup
    title={(
      <FormattedMessage
        id="profile.checkboxes.title"
      />
    )}
    options={checkboxGroupItems}
    name={name}
  />
);
