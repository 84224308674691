import React from 'react';
import { Divider as MuiDivider, DividerProps as IDividerProps } from '@mui/material';

import { useStyles } from './divider.styles';

export const Divider = (props: IDividerProps): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiDivider
      classes={{
        root: classes.root,
      }}
      {...props}
    />
  );
};
