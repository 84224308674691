import { useStyles } from './products.styles';
import { IProductsProps } from './products.models';
import { IProductCardProps, ProductCard } from '../product-card';

export const Products = ({ products }: IProductsProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      {products.length && (
        <ul
          className={classes.list}
          data-testid="products"
          role="list"
        >
          {products.map(({
            brand, packSize, productQuantity,
          }: IProductCardProps, index) => (
            <li
              key={index}
              className={classes.item}
              role="listitem"
            >
              <ProductCard
                brand={brand}
                packSize={packSize}
                productQuantity={productQuantity}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
