import { ToggleButtonProps } from '@mui/material';

import { useFormattedValue } from '../../../../../../common/eye-params/hooks';
import { EyeParamsType } from '../../../../../../../graphql/lenses/models/get-lenses.models';
import { IOptions } from '../../../../../../common/select';

interface IParamItemsProps {
  paramValues: number[];
  property: EyeParamsType;
  isOrder: boolean;
}

export const getParamItems = ({ paramValues, property, isOrder }: IParamItemsProps): ToggleButtonProps[] => {
  const { getFormattedValue } = useFormattedValue();

  return paramValues.map((paramValue) => ({
    children: getFormattedValue(String(paramValue), property, isOrder),
    value: String(paramValue),
  }));
};

export const getParamSelectItems = ({ paramValues, property, isOrder }: IParamItemsProps): IOptions[] => {
  const { getFormattedValue } = useFormattedValue();

  return paramValues.map((paramValue) => ({
    value: String(paramValue),
    label: getFormattedValue(String(paramValue), property, isOrder),
  }));
};
