import { TFormValue } from '../../add-lenses-step/add-lenses-step-form';

export const getSubmitValues = (values: TFormValue[]) => {
  const filteredValues = values.filter(({ productQuantity }) => Number(productQuantity) > 0);

  return filteredValues!.map(({ brand, productQuantity, packSize, eye }) => ({
    brand,
    productQuantity: Number(productQuantity),
    packSize: Number(packSize),
    eye,
  }));
};
