import {
  IEyeParamsOption,
  IPoint,
  LensesBrandType,
  SolutionsBrandType,
} from '../../lenses/models/get-lenses.models';
import { BonusType } from '../../bonuses/models/get-bonuses.models';
import { AppointmentStoreSubType } from '../../appointments/models/get-appointments.models';

export interface IOrders {
  ordersByAuthId: IOrdersFields[];
  consumerLensesByAuthID: IFittingFields[];
}

export interface IOrdersFields {
  id: string;
  orderStatus: OrderStatus;
  orderDate: string;
  systemId: string;
  deliveryDate: string | null;
  products: IProduct[];
  store: IStore;
  salesforceStoreInfo: IStoreSalesforce;
  createdDateTime: string;
  voucherId: string | null;
  voucher: IOrderSelectedBonus | null;
  purchases: IOrderPurchase[];
}

export interface IOrderPurchase {
  purchaseDate: string;
}

export interface IProduct {
  brand: LensesBrandType | SolutionsBrandType;
  eye: IEyeParamsOption[];
  packSize: number;
  productQuantity: number;
  id?: string;
}

export interface IStore {
  name: string;
  address: string;
  workingHours: string;
  phone: string;
  code: string;
  storeSubType: AppointmentStoreSubType;
  latitude?: number;
  longitude?: number;
  displayName?: string;
  city?: string;
}

export interface IFittingFields {
  id: string;
  createdManual: boolean;
  brandId: LensesBrandType;
  brand: IOrderBrand;
}

export enum OrderStatus {
  canceledByPatient = 'CANCELED_BY_PATIENT',
  canceled = 'CANCELED',
  fulfilled = 'FULFILLED',
  confirmed = 'CONFIRMED',
  processing = 'PROCESSING',
  contactPatient = 'CONTACT_PATIENT',
  new = 'NEW',
}

export interface IOrderPoints {
  brandId: LensesBrandType;
  points: IPoint[];
}

export interface IOrderBrand {
  points: IPoint[];
}

export interface IOrderSelectedBonus {
  title: string;
  description: string | null;
  type: BonusType;
  value: number;
  name: string;
}

export interface IStoreSalesforce {
  rating: string;
}
