import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { IVisionProfileProps } from './vision-profile-banner.models';
import { useStyles } from './vision-profile-banner.styles';
import { ContentWrapper } from '../../../../common/content-wrapper';
import { Card } from '../../../../common/card';
import { Button, Icon, IconTypes } from '../../../../common';

export const VisionProfileBanner = ({ onButtonClick }: IVisionProfileProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <section
      data-testid="vision-profile-landing-wrapper"
      aria-label={intl.formatMessage({ id: 'vp.preview.ariaLabel' })}
      className={classes.container}
    >
      <ContentWrapper>
        <Card
          className={classes.root}
          data-testid="vision-profile-landing-card"
        >
          <div className={classes.content}>
            <h2
              data-testid="vision-profile-landing-title"
              className={classes.sectionTitle}
            >
              {intl.formatMessage(
                { id: 'vp.preview.title' },
                {
                  sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                  span: (chunks: ReactNode) => (
                    <span className={classes.sectionTitlePartial}>
                      {chunks}
                    </span>
                  ),
                },
              )}
            </h2>
            <p
              data-testid="vision-profile-landing-description"
              className={classes.description}
            >
              <FormattedMessage
                id="vp.preview.description"
              />
            </p>
            {!isTablet && (
              <Icon
                type={IconTypes.giftBox}
                className={classes.imgContainer}
              />
            )}
            <Button
              variant="contained"
              data-testid="vision-profile-landing-button"
              className={classes.button}
              onClick={onButtonClick}
            >
              <span>
                <FormattedMessage id="vp.preview.button.label" />
              </span>
            </Button>
          </div>
          {isTablet && (
            <Icon
              type={IconTypes.giftBox}
              className={classes.imgContainer}
            />
          )}
        </Card>
      </ContentWrapper>
    </section>
  );
};
