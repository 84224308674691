import { IVisionProfileFields } from '../../graphql/profile/models/get-vision-profile.models';

export const isChangeDateAllowed = <T extends Pick<IVisionProfileFields, 'meta'>>(
  data?: T | null,
): boolean => {
  let result = true;

  if (data) {
    result = data.meta.canUpdateDateOfBirth;
  }
    
  return result;
};