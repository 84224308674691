import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { cx } from '@emotion/css';

import { IEyeParamsOption } from '../../../graphql/lenses/models/get-lenses.models';
import { IEyeParamsProps } from './eye-params.models';
import { useStyles } from './eye-params.styles';
import { getParamLabelTranslationId, getUnits } from './utils';
import { useFormattedValue } from './hooks';

export const EyeParams = ({
  params, title, isOrder,
}: IEyeParamsProps): JSX.Element => {
  const classes = useStyles();
  const { getFormattedValue } = useFormattedValue();

  const formattedParams = useMemo(
    () => params.map(({ value, property }: IEyeParamsOption) => ({
      property,
      value: getFormattedValue(value, property, isOrder),
    })), [getFormattedValue, params],
  );

  return (
    <>
      {isOrder ? (
        <>
          <h4
            data-testid="order-params-title"
            className={cx(classes.titleOrder, classes.inline)}
          >
            {title}
          </h4>
          <p
            data-testid="order-params-wrapper"
            className={cx(classes.paramOrder, classes.inline)}
          >
            {formattedParams.map(({ value, property }: IEyeParamsOption) => (
              <span
                key={property}
                data-testid="eye-params-item"
                className={classes.paramItemOrder}
              >
                <span
                  data-testid="eye-property"
                  className={classes.paramPropertyOrder}
                >
                  <FormattedMessage
                    id={getParamLabelTranslationId(property)}
                  />
                  {' '}
                </span>
                <span data-testid="eye-value">
                  {value}
                  {getUnits(property)}
                </span>
                <span data-testid="comma">{', '}</span>
              </span>
            ))}
          </p>
        </>
      ) : (
        <div>
          <h4 className={classes.title}>
            {title}
          </h4>
          {formattedParams.map(({ value, property }: IEyeParamsOption) => (
            <p
              className={classes.param}
              key={property}
              data-testid="eye-params-item"
            >
              <FormattedMessage
                id={getParamLabelTranslationId(property)}
              />
              {': '}
              <span className={classes.paramValue}>
                {value}
                {getUnits(property)}
              </span>
            </p>
          ))}
        </div>
      )}
    </>
  );
};
