import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IconTypes } from '../../../../../../common/icon';
import { ConsumerBanModal } from '../../../../../../common/consumer-ban-modal';
import { IPurchasesWarningModalViewProps } from './purchases-warning-modal.models';

export const PurchasesWarningModal = ({
  onActionButtonClick, onClose, ...props
}: IPurchasesWarningModalViewProps): JSX.Element => {
  const intl = useIntl();

  return (
    <ConsumerBanModal
      {...props}
      data-testid="purchases-warning-modal"
      onClose={onClose}
      icon={IconTypes.puschasesConsumerBan}
      title={(
        <FormattedMessage
          id="purchasesConsumerBan.warningModal.title"
        />
      )}
      description={intl.formatMessage(
        { id: 'purchasesConsumerBan.warningModal.description' },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      )}
      actionButtonProps={{
        onClick: onActionButtonClick,
        children: (
          <FormattedMessage
            id="purchasesConsumerBan.warningModal.actionButton.label"
          />
        ),
      }}
    />
  );
};
