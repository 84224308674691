import { sortByDateDesc } from '../../../../../../../utils/date/sort-by-date';
import {
  IFittingPreviewData,
  IPurchasesData,
} from '../../../../../../../graphql/lenses/models/get-lenses-preview.models';
import { filterPurchases, filterFittings } from './filter-by-removing-brand';

export const getLatestEvent = (purchases: IPurchasesData[], fittings: IFittingPreviewData[]) => {
  const allUserEvents = [
    ...filterFittings(fittings),
    ...filterPurchases(purchases),
  ];
  let result: IPurchasesData | IFittingPreviewData | undefined;

  if (allUserEvents.length) {
    const sortedEvents = sortByDateDesc(allUserEvents, 'createdDateTime');

    result = sortedEvents[0];
  }

  return result;
};
