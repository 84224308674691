import { makeVar } from '@apollo/client';

import ruTranslations from '../../translations/ru.json';
import { getTranslationsFromStorage } from '../../utils/cms/get-translations-from-storage';

const cmsTranslationsEnabled = process.env.REACT_APP_CMS_TRANSLATIONS_ENABLED === 'true';

export const translations = makeVar<string | object>(
  cmsTranslationsEnabled
    ? (getTranslationsFromStorage() || ruTranslations)
    : ruTranslations,
);
