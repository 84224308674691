import {
  setAutocompletedSearch,
  setAutocompletedValue,
  setAutoValueSelected,
  setEcpFormSubmitted,
  setUserLocation,
} from '../../../../../../graphql/ecp-locator/ecp-locator.cache';
import {
  setIsRepeatOrder,
  setOrderBonus,
  setOrderBonusId,
  setOrderStoreCode,
  setOrderStores,
} from '../../../../../../graphql/order/order.cache';
import { DEFAULT_CENTER } from '../../../../../../constants/ecp-locator';

export const cleanupState = () => {
  setOrderStoreCode('');
  setAutocompletedValue('');
  setEcpFormSubmitted(false);
  setAutoValueSelected(false);
  setAutocompletedSearch(false);
  setOrderBonus(null);
  setOrderBonusId('');
  setIsRepeatOrder(false);
  setOrderStores([]);
  setUserLocation(DEFAULT_CENTER);
};
