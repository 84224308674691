import {
  isCookiePolicyAgreed,
  isLoggedIn,
  isLensesTutorialShown,
  isBonusesTutorialShown,
} from './user.cache';

export const user = {
  read() {
    return {
      isLoggedIn: isLoggedIn(),
      isCookiePolicyAgreed: isCookiePolicyAgreed(),
      isLensesTutorialShown: isLensesTutorialShown(),
      isBonusesTutorialShown: isBonusesTutorialShown(),
    };
  },
};
