import { useIntl, FormattedMessage } from 'react-intl';
import { cx } from '@emotion/css';

import { Helmet } from 'react-helmet';
import { SignUpSlide } from './sign-up-slide';
import { ReactNode } from 'react';
import { useStyles } from './reg-guide-page.styles';
import { RegSteps } from './reg-steps';
import { Benefits } from './benefits';

export const RegGuidePage = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'regGuide.title' })}
        </title>
      </Helmet>
      <SignUpSlide />
      <div className={classes.root}>
        <p
          className={classes.paragraph}
          data-testid="happy-fitting"
        >
          <FormattedMessage id="regGuide.happyFitting" />
        </p>
        <p
          className={cx(classes.strong, classes.paragraph)}
          data-testid="save-fitting"
        >{intl.formatMessage(
          { id: 'regGuide.saveFitting' },
          {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
          },
        )}</p>
        <p
          className={cx(classes.strong, classes.paragraph)}
          data-testid="bonus-notice"
        >{intl.formatMessage(
          { id: 'regGuide.bonusNotice' },
          {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
          },
        )}</p>
        <RegSteps />
      </div>
      <Benefits />
    </>
  );
};
