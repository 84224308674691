import { ShopTypeFilters } from '../../graphql/ecp-locator/models/filter-params.models';
import { IEcpStore } from '../../rest/ecp-locator';
import { isClinicStore, isOpticsStore, isVendingMachine } from './define-store-types';

export const filteringByStores = (stores: IEcpStore[], criteria: ShopTypeFilters[], allItemsLength: number) => {
  const isUnfiltered = criteria.length === 0
    || criteria.length === allItemsLength;

  return isUnfiltered
    ? [...stores]
    : stores.filter(({ type }) => {
      switch (true) {
        case isOpticsStore(type):
          return criteria.includes(ShopTypeFilters.optics);
        case isClinicStore(type):
          return criteria.includes(ShopTypeFilters.clinics);
        case isVendingMachine(type):
          return criteria.includes(ShopTypeFilters.vendings);
        default:
          return false;
      }
    });
};
