import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontSizes, lineHeights, fontWeights, breakpoints,
}: Theme) => ({
  title: {
    margin: '0 0 16px',
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.regular,
    [breakpoints.up('sm')]: {
      marginBottom: 0,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
}));
