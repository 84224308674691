import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
  useCallback,
  ReactNode,
} from 'react';
import { useIntl } from 'react-intl';

import { useBind } from '../../../../../../../../hooks/use-bind';
import { useSnackbar } from '../../../../../../../../hooks/use-snackbar';
import { useCancellablePromise } from '../../../../../../../../hooks/use-cancellable-promise';
import { PhoneStepForm } from './phone-step-form';
import { yup } from '../../../../../../../../services/yup';
import {
  usePhoneNumberValidation,
} from '../../../../../../../../hooks/use-phone-number-validation';
import { consumerApi } from '../../../../../../../../rest/consumer';
import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../../../common/form';
import { IStepsState, IExternalState, TPhoneValues } from '../../change-phone-modal.models';
import { useActionsInProgress } from '../../../../../../../../graphql/preloader/actions/actions-in-progress';

export const PhoneStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  stepsState,
  onGoNextSuccess,
  onGoNextFail,
  onGoNext,
  isNavigationAllowed,
  externalState,
}, ref): JSX.Element => {
  const intl = useIntl();
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const { phone: defaultValues } = stepsState as IStepsState;
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const { enqueueSnackbar } = useSnackbar();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const newPhoneNumberValidation = usePhoneNumberValidation();

  const validationSchema = useMemo(() => yup.object({
    newPhoneNumber: newPhoneNumberValidation,
  }), [newPhoneNumberValidation]);

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return formRef.current!.getValues();
    },
  }));

  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const onGoNextSuccessBind = useBind(onGoNextSuccess);
  const onGoNextFailBind = useBind(onGoNextFail);
  const onGoNextBind = useBind(onGoNext);
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const isNavigationAllowedBind = useBind(isNavigationAllowed);
  const externalStateBind = useBind(externalState as IExternalState);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);

  const handleSubmit = useCallback(async (formValues: TPhoneValues) => {
    if (!isNavigationAllowedBind.current) {
      return;
    }

    onGoNextBind.current();
    addActionInProgressBind.current();

    try {
      await makeCancellablePromiseBind.current(
        consumerApi.changePhoneNumber({
          phoneNumber: externalStateBind.current.phoneNumber,
          newPhoneNumber: formValues.newPhoneNumber,
        }),
      );

      removeActionInProgressBind.current();
      onGoNextSuccessBind.current(formValues);
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        return;
      }

      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 400:
            enqueueSnackbarBind.current(
              intl.formatMessage(
                { id: 'common.errors.phoneNumber.exist' },
                { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
              ),
              { variant: 'error' },
            );
            break;
          default:
            break;
        }
      }

      onGoNextFailBind.current();
    }
  }, [
    onGoNextBind,
    onGoNextSuccessBind,
    onGoNextFailBind,
    isNavigationAllowedBind,
    enqueueSnackbarBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    externalStateBind,
    addActionInProgressBind,
    removeActionInProgressBind,
  ]);

  return (
    <PhoneStepForm
      ref={formRef}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultValues={defaultValues}
    />
  );
});
