import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import ellipse from '../../../../assets/images/backgrounds/ellipse.png';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  root: {
    padding: '80px 16px 0',
    background: `url(${ellipse}) 50% -450px no-repeat`,
    [breakpoints.up('md')]: {
      paddingTop: 120,
      background: `url(${ellipse}) 50% -270px no-repeat`,
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    marginBottom: 60,
    width: 274,
    height: 100,
    [breakpoints.up('md')]: {
      width: 411,
      height: 150,
    },
    [breakpoints.up('lg')]: {
      marginBottom: 76,
    },
  },
  primaryDescription: {
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    color: palette.text.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    textAlign: 'center',
    margin: '0 0 16px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
    [breakpoints.up('lg')]: {
      marginBottom: 12,
    },
  },
  secondaryDescription: {
    fontFamily: fontFamilies.primary,
    color: palette.text.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.md,
    textAlign: 'center',
    margin: '0 0 24px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      marginBottom: 32,
    },
  },
  returnButton: {
    marginBottom: 80,
    [breakpoints.up('md')]: {
      marginBottom: 170,
    },
    [breakpoints.up('lg')]: {
      marginBottom: 142,
    },
  },
}));
