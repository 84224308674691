import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import star from '../../../../assets/images/backgrounds/star.svg';

export const useStyles = makeStyles(({
  fontWeights,
  fontFamilies,
  palette,
  fontSizes,
  breakpoints,
  lineHeights,
  borderRadiuses,
}: Theme) => ({
  root: {
    position: 'relative',
    padding: '32px 32px 32px 0',
  },
  arrowRight: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    '& svg': {
      width: 32,
      height: 32,
    },
  },
  title: {
    marginTop: 0,
    marginBottom: 8,
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      fontSize: '21px',
      lineHeight: lineHeights.xl,
    },
  },
  content: {
    [breakpoints.up('sm')]: {
      display: 'flex',
    },
    [breakpoints.up('xl')]: {
      flexDirection: 'column',
    },
  },
  ratingWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '0 0 8px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  rating: {
    paddingLeft: 20,
    backgroundImage: `url(${star})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '16px 16px',
    backgroundPosition: 'left center',
  },
  pointSeparator: {
    position: 'relative',
    paddingRight: 10,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: 3,
      top: '50%',
      transform: 'translateY(-50%)',
      height: 2,
      width: 2,
      borderRadius: borderRadiuses.xxl,
      background: palette.text.primary,
    },
  },
  street: {
    margin: '0 0 6px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  phone: {
    margin: 0,
    fontFamily: fontFamilies.primary,
    color: palette.primary.main,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    textDecoration: 'none',
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      outline: '1px dotted',
      outlineColor: palette.primary.main,
    },
  },
  hours: {
    maxWidth: 135,
    margin: '0 0 6px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      maxWidth: 165,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
}));
