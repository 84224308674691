import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const halfWidth = 'calc((100% - 1.5rem) / 2)';

export const useStyles = makeStyles(({
  spacing,
  breakpoints,
  palette,
  fontSizes,
  siteMediumWidth,
  fontWeights,
}: Theme) => ({
  footer: {
    backgroundColor: palette.primary.dark,
    padding: '2rem 1.5rem',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1.5rem',
    width: '100%',
    maxWidth: siteMediumWidth,
    margin: '0 auto',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    [breakpoints.up('xl')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  supportContainer: {
    display: 'grid',
    rowGap: spacing(2),
  },
  supportMotto: {
    maxWidth: 'unset',
    marginTop: 0,
    marginBottom: '1.25rem',
    color: palette.grey.A100,
    fontSize: fontSizes.xs,
  },
  phoneNumber: {
    fontWeight: fontWeights.medium,
  },
  logoGroup: {
    [breakpoints.up('md')]: {
      width: halfWidth,
    },
    [breakpoints.up('xl')]: {
      display: 'flex',
      gap: '1.5rem',
      width: 'unset',
    },
  },
  logoWrapper: {
    width: '4rem',
    height: '4rem',
    [breakpoints.up('xl')]: {
      display: 'flex',
      alignSelf: 'flex-start',
      height: '100%',
    },
    '& img': {
      display: 'block',
      width: '100%',
      objectFit: 'contain',
    },
  },
  policy: {
    [breakpoints.up('md')]: {
      position: 'relative',
      width: halfWidth,
      marginTop: '-1rem',
    },
    [breakpoints.up('xl')]: {
      width: 'unset',
      marginTop: 0,
    },
  },
  legal: {
    width: '100%',
    '& p': {
      margin: '0 0 0.5rem',
      color: palette.common.white,
      fontSize: fontSizes.xs,
      lineHeight: 1.4,
    },
    '& p:last-child': {
      marginBottom: 0,
    },
  },
  link: {
    color: palette.common.white,
    fontSize: fontSizes.xs,
    lineHeight: 1.4,
  },
  separator: {
    width: '100%',
    height: 1,
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
  },
}));
