import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  backButton: {
    position: 'absolute',
    top: 0,
    right: '100%',
  },
  title: {
    position: 'relative',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    color: palette.text.secondary,
    margin: '0 0 8px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      marginBottom: 16,
    },
  },
  titleWithoutDescription: {
    marginBottom: 24,
    [breakpoints.up('md')]: {
      marginBottom: 32,
    },
  },
  description: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.lg,
    color: palette.custom.greyNeutral,
    margin: '0 0 24px',
    [breakpoints.up('md')]: {
      lineHeight: lineHeights.md,
      marginBottom: 32,
    },
  },
}));
