import { isSameDay } from 'date-fns';
import { NoveltyLabelType } from '../../../../../../graphql/lenses/models/get-lenses.models';

export const checkLabelEnabled = (label: NoveltyLabelType): boolean => {
  const { labelStartDate, labelEndDate } = label;

  const checkExpiredLabel = (endDate: string) => {
    const currentDate = new Date();
    const formattedEndDate = new Date(endDate);

    return currentDate < formattedEndDate || isSameDay(formattedEndDate, currentDate);
  };

  const checkStartedLabel = (startDate: string) => {
    const currentDate = new Date();
    const formattedStartDate = new Date(startDate);

    return currentDate > formattedStartDate || isSameDay(formattedStartDate, currentDate);
  };

  switch (true) {
    case !!labelEndDate && !!labelStartDate:
      return checkStartedLabel(labelStartDate) && checkExpiredLabel(labelEndDate);
    case !!labelEndDate && !labelStartDate:
      return checkExpiredLabel(labelEndDate);
    case !labelEndDate && !!labelStartDate:
      return checkStartedLabel(labelStartDate);
    default:
      return false;
  }
};
