import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, palette }: Theme) => ({
  coloredSection: {
    padding: '2rem 0',
    backgroundColor: palette.secondary.light,
    [breakpoints.up('sm')]: {
      padding: '4rem 0',
    },
  },
}));
