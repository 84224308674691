import { useImperativeHandle, FC, forwardRef, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { StepsState } from '../../profile-delete.models';
import { DeleteProfileAction } from './delete-profile-action';
import { IStepComponentProps, IStepperImperativeHandleProps } from '../../../../../common/stepper';
import { Icon, IconTypes } from '../../../../../common';
import { useStyles } from './delete-step.styles';
import { ScrollToTop } from '../../../../../common/scroll-to-top';

export const DeleteStep: FC = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  stepsState,
  onGoNextSuccess,
}, ref): JSX.Element => {
  const { login } = stepsState as StepsState;
  const classes = useStyles();
  const intl = useIntl();

  const benefits = [
    {
      text: 'profileDelete.bonuses.one',
      icon: IconTypes.benefitOne,
    },
    {
      text: 'profileDelete.bonuses.two',
      icon: IconTypes.benefitTwo,
    },
    {
      text: 'profileDelete.bonuses.three',
      icon: IconTypes.benefitThree,
    },
    {
      text: 'profileDelete.bonuses.four',
      icon: IconTypes.benefitFour,
    },
  ];

  useImperativeHandle(ref, () => ({
    goNext() {},
    goBack() {},
  }));

  return (
    <>
      <ScrollToTop />
      <h2 className={classes.title}>{intl.formatMessage(
        { id: 'profileDelete.delete.title' },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      )}</h2>
      <p className={classes.description}>{intl.formatMessage(
        { id: 'profileDelete.delete.description' },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      )}</p>
      <ul className={classes.list}>
        {benefits.map(({ text, icon }) => (
          <li key={text} className={classes.item}>
            <span aria-hidden="true" className={classes.icon}><Icon type={icon} /></span>
            <span className={classes.text}>{intl.formatMessage(
              { id: text },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}</span>
          </li>
        ))}
      </ul>
      <DeleteProfileAction phoneNumber={login} onConfirm={onGoNextSuccess} />
    </>
  );
});
