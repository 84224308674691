import { forwardRef, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Modal, ResponsiveWidth } from '../../../common/modal';
import { EcpFiltersModalForm } from './ecp-filters-modal-form';
import { useStyles } from './ecp-filters-modal.styles';
import { IEcpFiltersModalViewProps } from './ecp-filters-modal.models';
import { Button } from '../../../common/button';
import { IFormImperativeHandleProps } from '../../../common/form';

export const EcpFiltersModal = forwardRef<IFormImperativeHandleProps, IEcpFiltersModalViewProps>(({
  defaultValues,
  isResetButtonDisabled,
  onApplyFilters,
  onSubmitButtonClick,
  onResetFilters,
  isSingleAohm,
  c2CMode,
  ...props
}, ref): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Modal
      {...props}
      responsiveWidth={ResponsiveWidth.lg}
      content={(
        <>
          <EcpFiltersModalForm
            ref={ref}
            defaultValues={defaultValues}
            onApplyFilters={onApplyFilters}
            isSingleAohm={isSingleAohm} // Hide C2C feature from aohm-only fitted users
            c2CMode={c2CMode}
          />
          <div className={classes.descriptionsWrapper}>
            <p className={classes.description}>
              <FormattedMessage
                id="ecp.filters.modal.presbyopiaDefinition.description"
              />
            </p>
            <p className={classes.description}>
              {intl.formatMessage(
                { id: 'ecp.filters.modal.authorizePartners.description' },
                { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
              )}
            </p>
          </div>
        </>
      )}
      propsContentStyles={classes.content}
      footer={(
        <div className={classes.actions}>
          <div className={classes.resetButton}>
            <Button
              variant="text"
              disabled={isResetButtonDisabled}
              onClick={onResetFilters}
              data-testid="filters-modal-reset-button"
            >
              <FormattedMessage
                id="ecp.filters.modal.resetFiltersButton.label"
              />
            </Button>
          </div>
          <Button
            fullWidth
            variant="contained"
            onClick={onSubmitButtonClick}
            data-testid="filters-modal-submit-button"
          >
            <FormattedMessage
              id="ecp.filters.modal.applyFiltersButton.label"
            />
          </Button>
        </div>
      )}
      footerSeparateLine
    />
  );
});
