import { FormattedMessage, useIntl } from 'react-intl';

import {
  CONSUMER_NOT_FOUND,
  INVALID_RECAPTCHA,
  PIN_CODE_BLOCKED,
  RECAPTCHA_REQUEST_DENIED,
} from '../../../../../constants/errors';
import { useBind } from '../../../../../hooks/use-bind';
import { useSnackbar } from '../../../../../hooks/use-snackbar';

export const useResetPasswordError = () => {
  const { enqueueSnackbar } = useSnackbar();
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const intl = useIntl();

  const handleError = (error: any) => {
    if (error.response) {
      const { status, data: { errors } } = error.response;
      const { code } = errors[0];

      switch (status) {
        case 400:
          if (code === PIN_CODE_BLOCKED) {
            enqueueSnackbarBind.current(
              intl.formatMessage(
                { id: 'resetPassword.errors.oneInMinute' },
              ),
              { variant: 'error' },
            );
          } else if (code === CONSUMER_NOT_FOUND) {
            enqueueSnackbarBind.current(
              intl.formatMessage(
                { id: 'resetPassword.errors.wrongNumber' },
              ),
              { variant: 'error' },
            );
          }  else if (code === INVALID_RECAPTCHA || code === RECAPTCHA_REQUEST_DENIED) {
            enqueueSnackbarBind.current(
              <FormattedMessage id="common.errors.unknownError" />,
              { variant: 'error' },
            );
          }
          break;
        default:
          enqueueSnackbarBind.current(
            <FormattedMessage id="common.errors.unknownError" />,
            { variant: 'error' },
          );
          break;
      }
    } else {
      enqueueSnackbarBind.current(
        <FormattedMessage id="common.errors.unknownError" />,
        { variant: 'error' },
      );
    }
  };

  return { handleError };
};
