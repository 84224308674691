import { useIntl } from 'react-intl';

import { ChangeButton } from '../../change-button';
import { IDeliveryChangeButtonProps } from './delivery-change-button.models';

export const DeliveryChangeButton = ({ onStoreChange }: IDeliveryChangeButtonProps): JSX.Element => {
  const intl = useIntl();

  return (
    <ChangeButton
      data-testid="delivery-change-button"
      onButtonClick={onStoreChange!}
      ariaLabel={intl.formatMessage({ id: 'order.create.confirm.delivery.changeButton.ariaLabel' })}
    />
  );
};
