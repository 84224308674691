import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, lineHeights, fontWeights, borderRadiuses, palette,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    margin: '20px 0 16px',
    fontSize: fontSizes.lg,
    lineHeight: 1.2,
    fontWeight: fontWeights.medium,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
    },
  },
  description: {
    display: 'flex',
    flexGrow: 1,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      flexGrow: 0,
      marginBottom: 28,
    },
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0',
    padding: '12px 16px',
    borderRadius: borderRadiuses.xl,
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.sm,
    lineHeight: 1,
    textDecoration: 'none',
    [breakpoints.up('md')]: {
      padding: '15px 24px',
    },
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
  },
}));
