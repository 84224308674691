import { FormattedMessage } from 'react-intl';

import { IconTypes, Icon } from '../../../../../../common';
import { BonusChangeButton } from './bonus-change-button';
import { BonusCardHorizontal } from '../../../../../../common/bonus-card-horizontal';
import { IBonusProps } from './bonus.models';
import { useStyles } from './bonus.styles';
import { BonusType } from '../../../../../../../graphql/bonuses/models/get-bonuses.models';

export const Bonus = ({
  bonus, isActiveStatus, onBonusSelect, bonusId, bonusesData, isEditOrder,
}: IBonusProps): JSX.Element => {
  const  classes = useStyles();

  const bonusDescription = bonus.type === BonusType.discount
    ? (
      <FormattedMessage
        id="userActivities.discounts.discountCard.description"
      />
    ) : bonus.description;

  return (
    <>
      <div
        data-testid="bonus-wrapper"
        className={classes.wrapper}
      >
        <p
          data-testid="bonus-success-message"
          className={classes.messageWrapper}
        >
          <span
            data-testid="bonus-success-icon"
            className={classes.checkedIconWrapper}
            aria-hidden="true"
          >
            <Icon type={IconTypes.checked} />
          </span>
          <FormattedMessage id="order.bonuses.isBonus" />
        </p>
        {isActiveStatus && (
          <BonusChangeButton
            onBonusSelect={onBonusSelect}
            bonusId={bonusId}
            bonusValue={bonus?.value}
            bonusType={bonus?.type}
            bonusesData={bonusesData}
            isEditOrder={isEditOrder}
          />
        )}
      </div>
      <BonusCardHorizontal
        cardTitle={bonus.title}
        description={bonusDescription}
        bonusImage={(
          <Icon
            type={IconTypes.percentRoundedBlue}
          />
        )}
      />
    </>
  );
};
