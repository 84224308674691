import { IFormSubmitValues } from '../../../../../../../common/form';
import {
  IPackageParams,
  TFormValue,
} from '../add-lenses-step-form';
import { getPackSizes } from '../../confirm-step/utils/get-pack-sizes';
import { getAcuvuePoints } from '../../confirm-step/utils/get-acuvue-points';

export const getFormValues = (lenses: IPackageParams[]): TFormValue[] => {
  return [...lenses].map(({
    points,
    brandId,
    leftEye,
    rightEye,
    id,
  }) => ({
    productQuantity: '0',
    packSize: String(getPackSizes(points)[0]) || '',
    brand: brandId,
    eye: leftEye || rightEye!,
    singlePoints: String(getAcuvuePoints(points)[0]),
    // id: `${id}-${new Date().getTime()}`,
    id,
    points,
    packSizes: getPackSizes(points),
  }));
};

export const getRepeatConsumerLenses = (
  products: IPackageParams[], repeatProducts: IFormSubmitValues,
): TFormValue[] => {
  const productsArray = getFormValues(products);
  const repeatProductsArray = (Object.values(repeatProducts) as TFormValue[]).filter((
    repeatProduct,
  ) => Number(repeatProduct.productQuantity) > 0);

  const updateIdProducts = repeatProductsArray.map((product) => ({
    ...product,
    id: `${product.id}-${new Date().getTime()}`,
  }));

  return [...updateIdProducts, ...productsArray];
};

export const getChangedConsumerLenses = (
  fittedProducts: IPackageParams[], products?: IFormSubmitValues,
): TFormValue[] => {
  const stateProductsArray = products ? Object.values(products) as TFormValue[] : [];
  const fittedProductsArray = getFormValues(fittedProducts);

  return fittedProductsArray.map((fittedProduct) => {
    const sameStateProduct = stateProductsArray.find((
      stateProduct,
    ) => fittedProduct.id === stateProduct.id);

    return {
      ...fittedProduct,
      productQuantity: sameStateProduct?.productQuantity || fittedProduct.productQuantity,
      packSize: sameStateProduct?.packSize || String(fittedProduct.packSizes![0]) || '',
      eye: sameStateProduct?.eye || fittedProduct.eye,
      singlePoints: sameStateProduct?.singlePoints || String(getAcuvuePoints(fittedProduct.points!)[0]),
      packSizes: fittedProduct.packSizes!,
      points: fittedProduct.points,
      id: sameStateProduct?.id || fittedProduct.id,
    };
  });
};
