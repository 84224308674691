import React from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { CancelAppointmentModal as CancelAppointmentModalView } from './cancel-appointment-modal';
import { ICancelAppointmentModalContainerProps } from './cancel-appointment-modal.models';
import { CANCEL_APPOINTMENT } from '../../../../../graphql/appointments/mutations/cancel-appointment';
import { useActionsInProgress } from '../../../../../graphql/preloader/actions/actions-in-progress';
import { promiseErrorCallbacks } from '../../../../../utils/promise/set-promise-error-callbacks';

export const CancelAppointmentModal = ({
  appointmentId, ...props
}: ICancelAppointmentModalContainerProps): JSX.Element => {
  const navigate = useNavigate();
  const [cancelAppointment] = useMutation(CANCEL_APPOINTMENT);
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const handleCancelAppointmentClick = async () => {
    try {
      addActionInProgress();

      await cancelAppointment({
        variables: {
          id: appointmentId,
        },
      });

      navigate('/');
    } catch {
      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    }
    removeActionInProgress();
  };

  return (
    <CancelAppointmentModalView
      {...props}
      onCancelAppointment={handleCancelAppointmentClick}
    />
  );
};
