import { ToggleButton } from '../../../common/toggle-button';
import { IEcpToggleViewProps } from './ecp-toggle-view.models';
import { ecpToggleViewOptions } from './ecp-toggle-view.items';

export const EcpToggleView = ({
  value, options, onChange, buttonStyles,
}: IEcpToggleViewProps): JSX.Element => {

  return (
    <ToggleButton
      fullWidth
      value={value!}
      options={options || ecpToggleViewOptions}
      onChange={onChange!}
      buttonStyles={buttonStyles}
    />
  );
};
