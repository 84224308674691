import { handledErrors } from './handled-errors';

export const isUnhandledError = (status: number, request: XMLHttpRequest): boolean => {
  const baseUrl = process.env.REACT_APP_REST_API_URL || '';
  const requestUrl = request.responseURL.replace(`${baseUrl}/`, '');

  const handled = handledErrors[requestUrl];

  return !(handled?.includes(status));
};
