import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
}: Theme) => ({
  descriptionsWrapper: {
    marginBottom: 20,
    [breakpoints.up('md')]: {
      marginBottom: 32,
    },
  },
  description: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxs,
    lineHeight: lineHeights.xxs,
    color: palette.grey['500'],
    margin: 0,
    [breakpoints.up('md')]: {
      lineHeight: lineHeights.xs,
    },
  },
  actions: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 8,
    [breakpoints.up('md')]: {
      gap: 16,
    },
  },
  resetButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    [breakpoints.down('sm')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
}));
