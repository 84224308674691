import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
}: Theme) => ({
  howToLink: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.primary.main,
    textAlign: 'center',
  },
}));
