import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  iconWrapper: {
    display: 'block',
    margin: '0 auto',
    '& svg': {
      width: 100,
      height: 100,
    },
  },
  content: {
    marginBottom: 24,
    textAlign: 'center',
    '& p': {
      marginTop: 0,
      textAlign: 'center',
    },
  },
  title: {
    textAlign: 'center',
    [breakpoints.up('md')]: {
      paddingLeft: 44,
      paddingRight: 44,
    },
  },
}));
