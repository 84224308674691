import { ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { IFreepackBonusModalProps } from './freepack-bonus-modal.models';
import { useStyles } from './freepack-bonus-modal.styles';
import { BonusModal } from '../bonus-modal';

export const FreepackBonusModal = ({
  isActivationMode, image, fullName, packSize, isLenses,  ...props
}: IFreepackBonusModalProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const units = isLenses ? (
    <FormattedMessage
      id="myPointsDetailed.modals.freePackBonusActiveModal.step2.lensesUnits"
    />
  ) : (
    <FormattedMessage
      id="myPointsDetailed.modals.freePackBonusActiveModal.step2.mlUnits"
    />
  );

  const steps = useMemo(
    () => {
      const idChunk = isActivationMode ? 'Not' : '';
      const id = `myPointsDetailed.modals.freePackBonus${idChunk}ActiveModal.step1`;
  
      const firstStep = intl.formatMessage(
        { id },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      );

      const finalStep = isActivationMode ? (<FormattedMessage
        id="myPointsDetailed.modals.freePackBonusNotActiveModal.step2"
      />) : (
        <FormattedMessage
          id="myPointsDetailed.modals.freePackBonusActiveModal.step2"
          values={{
            fullName: fullName,
            packSize: packSize,
            units: units,
          }}
        />
      );

      return [firstStep, finalStep];
    }, [isActivationMode, fullName, packSize],
  );

  return (
    <BonusModal
      {...props}
      data-testid={`freepack-bonus-${isActivationMode ? '' : 'not-'}active-modal`}
      image={<div className={cx(classes.image,
        { [classes.lensesImage]: isLenses },
        { [classes.solutionsImage]: !isLenses },
      )}>{image}</div>}
      title={<FormattedMessage
        id="myPointsDetailed.modals.freePackBonusModal.title"
      />}
      steps={steps}
    />
  );
};
