import { EcpStoreType } from '../../rest/ecp-locator';

export const isOpticsStore = (type?: EcpStoreType | string) => type === EcpStoreType.retailer
  || type === EcpStoreType.retailerHybrid;

export const isClinicStore = (type?: EcpStoreType | string) => type === EcpStoreType.clinic
  || type === EcpStoreType.clinicHybrid
  || type === EcpStoreType.fitter;

export const isHybridStore = (type?: EcpStoreType | string) => type === EcpStoreType.clinicHybrid
  || type === EcpStoreType.retailerHybrid
  || type === EcpStoreType.hybrid;

export const isVendingMachine = (type?: EcpStoreType | string) => type === EcpStoreType.vending;
