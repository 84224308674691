import * as yupBase from 'yup';

import { emailLatinRegex } from '../../constants';

function phoneNumberLength(this: yupBase.StringSchema, message: string) {
  return this.min(11, message);
}

function emailLatin(this: yupBase.StringSchema, message: string) {
  return this.matches(emailLatinRegex, message);
}

yupBase.addMethod(yupBase.string, 'phoneNumberLength', phoneNumberLength);
yupBase.addMethod(yupBase.string, 'emailLatin', emailLatin);

export const yup = yupBase;
