import { Status } from 'use-timer/lib/types';

export interface IResetPasswordProps {
  onDone: (stepsState: IStepsState) => void;
  externalState: IExternalStateProps;
}

export interface IStepsState {
  username: TUserNameValues;
  oneTimePin: TOneTimePinValues;
  password: TPasswordValues;
}

export type TUserNameValues = {
  username: string;
};

export type TOneTimePinValues = {
  username: string;
  code: string;
};

export type TPasswordValues = {
  username: string;
  code: string;
  password: string;
};

export interface IExternalStateProps {
  time: number;
  reset: () => void;
  start: () => void;
  status: Status;
  isCaptcha: boolean;
}

export enum TimerStatusOptions {
  running = 'RUNNING',
}
