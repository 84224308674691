import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  palette, borderRadiuses, fontWeights, fontSizes, lineHeights, breakpoints,
}: Theme) => ({
  root: {
    position: 'relative',
    marginBottom: 8,
  },
  rootSolutions: {
    position: 'relative',
    marginBottom: 20,
    [breakpoints.up('sm')]: {
      marginBottom: 16,
    },
  },
  button: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    borderRadius: borderRadiuses.md,
    border: `1px solid ${palette.grey['400']}`,
    backgroundColor: 'transparent',
    color: palette.text.primary,
    fontWeight: fontWeights.regular,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  input: {
    opacity: 0,
    position: 'absolute',
    '& .MuiInput-underline': {
      '&::before, &::after': {
        display: 'none',
      },
    },
  },
  selectIcon: {
    display: 'block',
    height: 'auto',
    marginLeft: 4,
    lineHeight: 0,
    '& svg': {
      display: 'block',
      width: 16,
      [breakpoints.up('sm')]: {
        width: 20,
        height: 'auto',
      },
      '& path': {
        fill: palette.text.primary,
        '&:last-child': {
          display: 'none',
        },
      },
    },
  },
}));
