import { FormattedMessage } from 'react-intl';
import { cx } from '@emotion/css';

import { DeliverySection } from '../delivery-section';
import { IDeliveryStoreProps } from './delivery-store.models';
import { useStyles } from './delivery-store.styles';

export const DeliveryStore = ({
  name, address, hours, phoneNumber, withBorder, isFulfilledOrder,
}: IDeliveryStoreProps): JSX.Element => {
  const classes = useStyles();

  return (
    <DeliverySection
      name="store"
      title={isFulfilledOrder ? (
        <FormattedMessage id="order.delivery.fulfilled.message" />
      ) : <FormattedMessage id="order.create.confirm.delivery.message" />}
      withBorder={withBorder}
      withoutTopIndent
    >
      <p data-testid="name" className={classes.paragraph}>{name}</p>
      <p data-testid="address" className={classes.paragraph}>{address}</p>
      <p
        data-testid="hours"
        className={cx(classes.paragraph, classes.hours)}
      >
        {hours}
      </p>
      <p data-testid="phone-wrapper" className={classes.paragraph}>
        <a
          data-testid="phone-link"
          href={`tel:${phoneNumber}`}
          className={classes.linkNoUnderline}
        >
          {phoneNumber}
        </a>
      </p>
    </DeliverySection>
  );
};
