import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { format } from 'date-fns';

import { IConsumerBanModalProps } from './login-consumer-ban-modal.models';
import { useStyles } from './login-consumer-ban-modal.styles';
import { UI_DATE_FORMAT } from '../../../../../constants';
import { ConsumerBanModal } from '../../../../common/consumer-ban-modal';

export const LoginConsumerBanModal = ({ expirationDate, ...props }: IConsumerBanModalProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  const limit = expirationDate ? (
    <FormattedMessage
      id="loginConsumerBanModal.description.limited"
      values={{ date: format(new Date(expirationDate), UI_DATE_FORMAT) }}
    />
  ) : <FormattedMessage id="loginConsumerBanModal.description.unlimited" />;

  return (
    <ConsumerBanModal
      {...props}
      data-testid="login-consumer-ban-modal"
      title={(
        <FormattedMessage
          id="loginConsumerBanModal.title"
        />
      )}
      description={intl.formatMessage(
        { id: 'loginConsumerBanModal.description' },
        {
          limit,
          p: (chunks: ReactNode) => (
            <p
              className={classes.partial}
              data-testid="consumer-ban-modal-partial"
            >
              {chunks}
            </p>
          ),
        },
      )}
      actionButtonProps={{
        href: intl.formatMessage({ id: 'common.phoneNumber.support.href' }),
        children: (
          <FormattedMessage
            id="common.phoneNumber.support"
          />
        ),
      }}
    />
  );
};
