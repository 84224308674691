import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  iconWrapper: {
    display: 'block',
    width: 40,
    height: 40,
    [breakpoints.up('md')]: {
      width: 80,
      height: 80,
    },
    '& img': {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
    },
  },
  item: {
    width: 'calc(100% - 52px)',
    [breakpoints.up('md')]: {
      width: 'calc(100% - 104px)',
    },
  },
}));
