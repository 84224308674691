import { useQuery } from '@apollo/client';

import {
  IVisionProfileData,
} from '../../../../../graphql/profile/models/get-vision-profile.models';
import {
  GET_VISION_PROFILE,
} from '../../../../../graphql/profile/queries/get-vision-profile';
import { VisionProfilePage as VisionProfilePageView } from './vision-profile-page';

export const VisionProfilePage = (): JSX.Element => {
  const { data, error, loading } = useQuery<IVisionProfileData>(GET_VISION_PROFILE);

  return (
    <VisionProfilePageView
      visionProfileData={data}
      isError={!!error}
      isLoading={loading}
    />
  );
};
