import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useModal } from 'mui-modal-provider';

import { OrderModal as OrderModalView } from './order-modal';
import { IOrderModalContainerProps } from './order-modal.models';
import { GET_IS_ORDER_MODAL_SHOWN } from '../../../../../graphql/order/queries/get-is-order-modal-shown';

export const OrderModal = ({ isFitted }: IOrderModalContainerProps): null => {
  const { data: { order: { isOrderModalShown } } } = useQuery(GET_IS_ORDER_MODAL_SHOWN);
  const { showModal } = useModal();

  useEffect(() => {
    if (!isOrderModalShown) {
      showModal(OrderModalView, { isFitted });
    }
  }, [isOrderModalShown]);

  return null;
};
