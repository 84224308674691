import {
  WhatDoYouLike,
} from '../../../../../../graphql/profile/models/get-vision-profile.models';

export const getActivityOptions = (activity: string) => {
  for (const key in WhatDoYouLike) {
    if (activity === WhatDoYouLike[key as keyof typeof WhatDoYouLike]) {
      return `vp.whatDoYouLike.${key}`;
    }
  }
};
