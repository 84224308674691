import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { useBind } from '../../../../../../hooks/use-bind';
import { useSnackbar } from '../../../../../../hooks/use-snackbar';
import { useCancellablePromise } from '../../../../../../hooks/use-cancellable-promise';
import { PhoneStepForm } from './phone-step-form';
import { yup } from '../../../../../../services/yup';
import { usePhoneNumberValidation } from '../../../../../../hooks/use-phone-number-validation';
import {
  useActionsInProgress,
} from '../../../../../../graphql/preloader/actions/actions-in-progress';
import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import {
  IStepsState,
  TimerStatusOptions,
  TUserNameValues,
} from '../../reset-password.models';
import { consumerApi, RecaptchaActions } from '../../../../../../rest/consumer';
import { IExternalStateProps } from '../../reset-password.models';
import { useResetPasswordError } from '../../hooks/use-reset-password-error';

export const PhoneStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  stepsState,
  onGoNextSuccess,
  onGoNextFail,
  onGoNext,
  isNavigationAllowed,
  externalState,
}, ref): JSX.Element => {
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const { username: phoneStepState } = stepsState as IStepsState;
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const { enqueueSnackbar } = useSnackbar();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const userNameValidation = usePhoneNumberValidation();

  const validationSchema = useMemo(() => yup.object({
    username: userNameValidation,
  }), [userNameValidation]);

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return {
        formValues: formRef.current!.getValues(),
      };
    },
  }));

  const defaultValues = useMemo(
    () => phoneStepState || {
      username: '',
    },
    [phoneStepState],
  );

  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const onGoNextSuccessBind = useBind(onGoNextSuccess);
  const onGoNextFailBind = useBind(onGoNextFail);
  const onGoNextBind = useBind(onGoNext);
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const isNavigationAllowedBind = useBind(isNavigationAllowed);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);

  const { start, reset, status: timerStatus, time, isCaptcha } = externalState as IExternalStateProps;

  const handleAfterSend = (value: string) => {
    removeActionInProgressBind.current();

    enqueueSnackbarBind.current(
      <FormattedMessage id="resetPassword.successRequest" />,
      { variant: 'success' },
    );

    reset();
    start();

    onGoNextSuccessBind.current({ username: value });
  };

  const { handleError } = useResetPasswordError();

  const handleSubmit = useCallback(async ({ username }: TUserNameValues) => {
    if (!isNavigationAllowedBind.current) {
      return;
    }

    if (timerStatus === TimerStatusOptions.running) {
      return;
    }

    onGoNextBind.current();
    addActionInProgressBind.current();

    try {
      if (isCaptcha) {
        const recaptcha = (window as any).grecaptcha.enterprise;

        recaptcha.ready(() => {
          recaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
            action: RecaptchaActions.resetPassword,
          }).then(async (token: string) => {
            await makeCancellablePromiseBind.current(consumerApi.sendPinCodeRecaptcha({ username }, token));

            handleAfterSend(username);
          }).catch((error: any) => {
            removeActionInProgressBind.current();

            handleError(error);
            onGoNextFailBind.current();
          });
        });
      } else {
        await makeCancellablePromiseBind.current(consumerApi.sendPinCode({ username }));

        handleAfterSend(username);
      }
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        return;
      }

      handleError(error);
      onGoNextFailBind.current();
    }
  }, [
    reset,
    start,
    timerStatus,
    onGoNextBind,
    onGoNextSuccessBind,
    onGoNextFailBind,
    isNavigationAllowedBind,
    enqueueSnackbarBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    addActionInProgressBind,
    removeActionInProgressBind,
    isCaptcha,
  ]);

  return (
    <PhoneStepForm
      ref={formRef}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultValues={defaultValues}
      time={time}
      isTimerRunning={timerStatus === TimerStatusOptions.running}
    />
  );
});
