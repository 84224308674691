import React from 'react';
import { cx } from '@emotion/css';
import { FormattedMessage } from 'react-intl';

import { useStyles } from './novelty-label.styles';
import { INoveltyLabelProps } from './novelty-label.models';
import { ProductLabelNames } from '../../../graphql/lenses/models/get-lenses.models';

export const NoveltyLabel = ({ className, label }: INoveltyLabelProps): JSX.Element => {
  const classes = useStyles();
  const isNewSoonLabel = label === ProductLabelNames.new || label === ProductLabelNames.soon;

  return (
    <>
      {isNewSoonLabel && (
        <div className={cx(classes.root, className)} data-testid="product-label">
          <FormattedMessage
            id={`${label}.description`}
          />
        </div>
      )}
    </>
  );
};
