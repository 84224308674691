import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, palette, borderRadiuses }: Theme) => ({
  iconButtonRoot: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
    /*
      buttons in Safari cut off child svg icons
      because button have border radius.
      reset border radius to fix it.
    */
    borderRadius: borderRadiuses.xs,
  },
  marked: {
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 8,
      right: 8,
      width: 7,
      height: 7,
      borderRadius: 9,
      backgroundColor: palette.error.main,
      [breakpoints.up('md')]: {
        paddingRight: 8,
        width: 8,
        height: 8,
        borderRadius: 10,
      },
    },
  },
}));
