import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useModal } from 'mui-modal-provider';

import { useStyles } from './contact-us-list.styles';
import { Button, Icon, IconSizes, IconTypes } from '../../../../../common';
import { IContactUsListItem } from './contact-us-list.models';
import { CallbackModal } from '../../../callback-modal';

export const ContactUsList = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const { showModal } = useModal();

  const handleCallback = () => {
    showModal(CallbackModal, {});
  };

  const contactLinks: IContactUsListItem[] = [{
    item: 'outOfSalesModal.contactUs.phone',
    icon: IconTypes.phoneRetro,
    iconSize: IconSizes.xl,
    href: intl.formatMessage({ id: 'common.phoneNumber.support.href' }),
    isPhone: true,
  }, {
    item: 'outOfSalesModal.contactUs.callback',
    icon: IconTypes.callbackRoundedBlue,
    iconSize: IconSizes.xl,
    handleClick: handleCallback,
  }, {
    item: 'outOfSalesModal.contactUs.email',
    icon: IconTypes.emailRoundedBlue,
    iconSize: IconSizes.xl,
    href: intl.formatMessage({ id: 'common.email' }),
  }];

  const ItemInner = ({ icon, iconSize, item }: IContactUsListItem) => (
    <>
      <span
        aria-hidden="true"
        className={classes.iconWrapper}
        data-testid="icon-wrapper"
      >
        <Icon
          type={icon}
          size={iconSize}
        />
      </span>
      <span data-testid="contact-us-list-item-text">
        {intl.formatMessage(
          { id: item },
          {
            strong: (chunks: ReactNode) => (<span
              className={classes.strongText}
              data-testid="contact-us-item-strong"
            >{chunks}</span>),
            span: (chunks: ReactNode) => (<span
              className={classes.listNote}
              data-testid="contact-us-item-regular"
            >{chunks}</span>),
          },
        )}
      </span>
    </>
  );

  return (
    <ul
      className={classes.root}
      data-testid="contact-us-list"
    >
      {contactLinks.map(({ icon, iconSize, item, href, handleClick, isPhone }, index) => (
        <li
          className={classes.item}
          data-testid="contact-us-list-item"
          key={`contact-item-${index}`}
        >
          {href ? (
            <a
              data-testid="contact-us-list-link"
              href={href}
              className={classes.action}
              target={!isPhone ? '_blank' : undefined}
            >
              <ItemInner
                icon={icon}
                iconSize={iconSize}
                item={item}
              />
            </a>
          ) : (
            <Button
              data-testid="contact-us-list-button"
              onClick={handleClick}
              variant="text"
              className={classes.action}
            >
              <ItemInner
                icon={icon}
                iconSize={iconSize}
                item={item}
              />
            </Button>
          )}
        </li>
      ))}
    </ul>
  );
};
