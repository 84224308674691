export const DEFAULT_CENTER = { latitude: 55.7558, longitude: 37.6173 };
export const CHILD_ZOOM = 15;
export const MIN_ZOOM = 3;
export const LIST_ZOOM = 12;
export const DEFAULT_RANGE = 20;
export const MARKER_HEIGHT = 58;
export const MARKER_HEIGHT_ACTIVE = 85;
export const MARKER_WIDTH = 50;
export const MARKER_WIDTH_ACTIVE = 74;
export const MAP_OPTIONS = {
  zoomControlOptions: {
    position: window?.google?.maps.ControlPosition.RIGHT_BOTTOM,
  },
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  minZoom: MIN_ZOOM,
};
