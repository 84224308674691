import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  palette, fontFamilies, fontSizes, lineHeights, breakpoints,
}: Theme) => ({
  root: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    gap: 7,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.grey['800'],
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  alert: {
    color: palette.error.main,
  },
  icon: {
    marginTop: 2,
    '& path': {
      fill: palette.grey['800'],
    },
    [breakpoints.up('md')]: {
      marginTop: 3,
    },
  },
  iconAlert: {
    '& path': {
      fill: palette.error.main,
    },
  },
}));
