import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  palette, fontWeights, fontSizes, lineHeights, borderRadiuses,
}: Theme) => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '40px max-content 40px',
    columnGap: 8,
    alignItems: 'center',
    '&& label': {
      transform: 'none',
    },
  },
  packField: {
    marginRight: 4,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
  },
  adornment: {
    color: palette.text.primary,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 54,
  },
  addPackButton: {
    width: 40,
    height: 40,
    padding: '8px 16px',
    border: 'none',
    borderRadius: borderRadiuses.md,
    color: palette.grey['800'],
    background: palette.grey['100'],
    fontSize: fontSizes.xxl,
    lineHeight: 1,
    fontWeight: fontWeights.regular,
    '&:hover': {
      background: palette.grey['500'],
      '&.Mui-disabled': {
        background: palette.grey['100'],
      },
    },
  },
}));
