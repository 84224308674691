import { useQuery } from '@apollo/client';

import { MyOrders as MyOrdersView }  from './my-orders';
import { GET_ORDERS } from '../../../../../graphql/order/queries/get-orders';
import { IMyOrdersContainerProps } from './my-orders.models';
import { IOrders } from '../../../../../graphql/order/models/get-orders.models';

export const MyOrders = ({
  isFitted, lensesData, c2CMode,
}: IMyOrdersContainerProps): JSX.Element => {
  const { data, loading, error } = useQuery<IOrders>(GET_ORDERS);

  return (
    <MyOrdersView
      ordersData={data}
      isError={!!error}
      isLoading={loading}
      isFitted={isFitted}
      lensesData={lensesData}
      c2CMode={c2CMode}
    />
  );
};
