import React from 'react';

import { IExternalLinkPanel } from './external-link-panel.models';
import { useStyles } from './external-link-panel.styles';
import { Icon, IconTypes } from '../icon';

export const ExternalLinkPanel = ({
  link, iconType,
}: IExternalLinkPanel): JSX.Element => {
  const classes = useStyles();
  const icon = iconType || IconTypes.externalLink;

  return (
    <a
      target="_blank"
      href={link}
      className={classes.root}
    >
      <p className={classes.link}>{link}</p>
      <Icon
        className={classes.icon}
        type={IconTypes[icon]}
        data-testid="panel-icon"
      />
    </a>
  );
};
