import React, { forwardRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { TextField } from '../../../../../../common/form/text-field';
import { IPhoneStepFormProps } from './phone-step-form.models';
import { ErrorAuth } from '../../../error-auth/error-auth';
import { useStyles } from './phone-step-form.styles';

export const PhoneStepForm = forwardRef<IFormImperativeHandleProps, IPhoneStepFormProps>(({
  onSubmit,
  validationSchema,
  defaultValues,
  onPhoneChange,
}, ref): JSX.Element => {
  const resultDefaultValues = useMemo(() => defaultValues || {
    phoneNumber: '',
  }, [defaultValues]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();

  return (
    <>
      <Form
        ref={ref}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        defaultValues={resultDefaultValues}
      >
        {isMobile && <ErrorAuth textId="registration.phoneStep.error.existing" rootStyles={classes.error} />}
        <TextField
          onChangeDependecies={onPhoneChange}
          name="phoneNumber"
          type="tel"
          fullWidth
          autoFocus
          label={<FormattedMessage id="common.phone.label" />}
        />
      </Form>
    </>
  );
});
