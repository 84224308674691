import { useMutation } from '@apollo/client';

import { IAftercareSurveyModalProps } from './aftercare-survey-modal.models';
import { AftercareSurveyModal as AftercareSurveyModalView } from './aftercare-survey-modal';
import { useCancellablePromise } from '../../../../../../../hooks/use-cancellable-promise';
import { CREATE_SURVEY_RESPONSE } from '../../../../../../../graphql/notifications/mutations/create-survey-response';
import { useActionsInProgress } from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { AftercareSurveyResponse, NotificationTargetId } from '../../../../../../../models/notification.models';
import { promiseErrorCallbacks } from '../../../../../../../utils/promise/set-promise-error-callbacks';

export const AftercareSurveyModal = ({
  sourceObjectId,
  notificationId,
  consumerId,
  updateNotifications: updateNotificationsInCache,
  onClose,
  ...props
}: IAftercareSurveyModalProps): JSX.Element => {
  const [createSurveyResponse] = useMutation(CREATE_SURVEY_RESPONSE);
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const handleSurveyResponse = async (response: AftercareSurveyResponse) => {
    try {
      addActionInProgress();

      await makeCancellablePromise(
        createSurveyResponse({
          variables: {
            notificationId,
            surveyResponse: {
              consumerId,
              response,
              sourceObjectId,
              type: 'SurveyResponse',
              surveyId: +NotificationTargetId.AFTERCARE,
            },
          },
        }),
      );

      updateNotificationsInCache(notificationId);
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    } finally {
      onClose!();
      removeActionInProgress();
    }
  };

  return (
    <AftercareSurveyModalView
      {...props}
      onClose={onClose}
      onConfirm={() => handleSurveyResponse(AftercareSurveyResponse.CONFIRM)}
      onReject={() => handleSurveyResponse(AftercareSurveyResponse.NOTCONFIRMED)}
    />
  );
};
