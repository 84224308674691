import { useIntl } from 'react-intl';
import { ReactNode } from 'react';
import { useReactiveVar } from '@apollo/client';
import { cx } from '@emotion/css';

import { ErrorProps } from './error-auth.models';
import { useStyles } from './error-auth.styles';
import { authErrors } from '../../../../../graphql/user/user.cache';
import { InternalLinks } from '../../../../../constants';

export const ErrorAuth = ({ textId, rootStyles }: ErrorProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const isPhoneNumberExists = useReactiveVar(authErrors)?.phoneNumberExists;

  return (
    <>{isPhoneNumberExists && (
      <p className={cx(classes.root, rootStyles)}>
        {intl.formatMessage(
          { id: textId },
          {
            a: (chunks: ReactNode) => <a href={InternalLinks.login} className={classes.link}>{chunks}</a>,
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
          },
        )}
      </p>
    )}</>
  );
};
