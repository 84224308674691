import React, { useMemo, useCallback } from 'react';

import { ChangePhoneModal as ChangePhoneModalView } from './change-phone-modal';
import { IChangePhoneModalProps, IStepsState } from './change-phone-modal.models';
import { IConsumerProfileData } from '../../../../../../graphql/profile/models/get-profile.models';

export const ChangePhoneModal = ({
  phoneNumber, onClose, updateProfileQuery, ...props
}: IChangePhoneModalProps): JSX.Element => {
  const externalState = useMemo(() => ({
    phoneNumber,
  }), [phoneNumber]);

  const handleChangePhoneDone = useCallback((stepsState: IStepsState) => {
    updateProfileQuery((prevProfile) => {
      const prevProfileQuery = prevProfile as IConsumerProfileData;

      return {
        ...prevProfileQuery,
        profile: {
          ...prevProfileQuery.profile,
          mobile: stepsState.confirmationCode.responsePhoneNumber,
        },
      };
    });

    onClose!();
  }, [updateProfileQuery, onClose]);

  return (
    <ChangePhoneModalView
      {...props}
      onClose={onClose}
      externalState={externalState}
      onChangePhoneDone={handleChangePhoneDone}
    />
  );
};
