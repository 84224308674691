import { useIntl } from 'react-intl';

import { useStyles } from './benefits.styles';
import { Benefit } from './benefit';
import { benefits } from './benefits.items';
import { ReactNode } from 'react';

export const Benefits = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div
      className={classes.root}
      data-testid="benefits-wrapper"
    >
      <h2
        className={classes.title}
        data-testid="benefits-title"
      >{intl.formatMessage(
        { id: 'regGuide.benefits.title.old' },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      )}</h2>
      <ul
        className={classes.list}
        data-testid="benefits-list"
      >
        {benefits.map(({
          item,
          icon,
        }, index) => (
          <li
            className={classes.item}
            data-testid="benefits-list-item"
            key={`benefit-${index}`}
          >
            <Benefit
              item={item}
              icon={icon}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};