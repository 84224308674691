import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  fontSizes, fontWeights, palette, breakpoints,
}: Theme) => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '100%',
    listStyle: 'none',
    padding: 0,
    margin: '0 0 55px',
    [breakpoints.up('md')]: {
      marginBottom: 60,
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    height: 64,
    width: 230,
    padding: 12,
    border: `2px solid ${palette.common.white}`,
    borderRadius: 12,
    background: `linear-gradient(180deg, ${palette.common.white} 0%, #b6eafe 100%)`,
    color: '#a51890',
    fontSize: fontSizes.xs,
    lineHeight: 1.4,
    fontWeight: fontWeights.medium,
    [breakpoints.up('md')]: {
      height: 116,
      width: 364,
      gap: 24,
      padding: '18px 25px',
      borderRadius: 30,
      fontSize: fontSizes.lg,
    },
    '&:nth-of-type(2)': {
      paddingRight: 30,
      [breakpoints.up('md')]: {
        paddingRight: 40,
      },
    },
  },
}));
