import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import errorIcon from '../../../../../assets/images/icons/error.svg';

export const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    margin: 0,
    padding: '0.75rem 0.75rem 0.75rem 3rem',
    borderRadius: '0.75rem',
    background: `no-repeat ${palette.common.white} url(${errorIcon})`,
    backgroundSize: '1.5rem',
    backgroundPositionX: '0.75rem',
    backgroundPositionY: '0.75rem',
    boxShadow:' 3px 4px 13px 0 rgba(5, 41, 103, 0.1)',
  },
  link: {
    color: palette.primary.dark,
  },
}));
