import { HTMLAttributes, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Modal, ResponsiveWidth } from '../../../../../../../../../common/modal';
import { ICompliancePeriodModalProps } from './compliance-period-modal.models';
import { useStyles } from './compliance-period-modal.styles';
import { PackageCardsList } from './package-cards-list';
import buyAtTimeImage from
  '../../../../../../../../../../assets/images/compliance-period-article-pictures/buy-in-time.png';
import pointsScaleTablet from
  '../../../../../../../../../../assets/images/compliance-period-article-pictures/points-scale-tablet.png';
import pointsScale from
  '../../../../../../../../../../assets/images/compliance-period-article-pictures/points-scale.png';

export const CompliancePeriodModal = ({
  bonusAmount, additionalBonus, loyaltyPoints, ...props
}: ICompliancePeriodModalProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
  const pointsScaleImage = isTablet ? pointsScaleTablet : pointsScale;

  return (
    <Modal
      {...props}
      responsiveWidth={ResponsiveWidth.lg}
      propsPaperStyles={classes.propsPaperStyles}
      data-testid="compliance-modal"
      PaperProps={{ 'data-testid': 'compliance-modal-paper' } as HTMLAttributes<HTMLDivElement>}
      title={(
        <div className={classes.header}>
          <div className={classes.headerImageRound}>
            <span
              className={classes.headerImageText}
              data-testid="compliance-modal-title"
            >
              <FormattedMessage
                id="compliancePeriodArticle.headerText"
                values={{
                  linebreak: <br />,
                }}
              />
            </span>
          </div>
        </div>
      )}
      titleStyles={classes.titleStyles}
      content={(
        <>
          <h3
            className={classes.title}
            data-testid="what-title"
          >
            {intl.formatMessage(
              { id: 'compliancePeriodArticle.bonusPoints.title' },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </h3>
          <p
            className={classes.description}
            data-testid="what-description"
          >
            {intl.formatMessage(
              { id: 'compliancePeriodArticle.bonusPoints.description' },
              {
                strong: (chunks: ReactNode) => <span className={classes.strongText}>{chunks}</span>,
                sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                bonusAmount,
                additionalBonus,
              },
            )}
          </p>
          <h3
            className={classes.title}
            data-testid="how-many-title"
          >
            <FormattedMessage
              id="compliancePeriodArticle.howMatchPoints.title"
            />
          </h3>
          <p
            className={classes.description}
            data-testid="how-many-description"
          >
            <FormattedMessage
              id="compliancePeriodArticle.howMatchPoints.description"
            />
          </p>
          <PackageCardsList loyaltyPoints={loyaltyPoints} />
          <h3
            className={classes.title}
            data-testid="when-title"
          >
            <FormattedMessage
              id="compliancePeriodArticle.whenToBuy.title"
            />
          </h3>
          <p
            className={classes.description}
            data-testid="when-description"
          >
            <FormattedMessage
              id="compliancePeriodArticle.whenToBuy.description"
            />
          </p>
          <img
            className={classes.image}
            src={buyAtTimeImage}
            alt=""
            data-testid="buy-in-time-img"
          />
          <h3
            className={classes.title}
            data-testid="how-title"
          >
            <FormattedMessage
              id="compliancePeriodArticle.howElseToGetPoints.title"
              values={{ additionalBonus }}
            />
          </h3>
          <p
            className={classes.description}
            data-testid="how-description"
          >
            {intl.formatMessage(
              { id: 'compliancePeriodArticle.howElseToGetPoints.description' },
              {
                strong: (chunks: ReactNode) => <span className={classes.strongText}>{chunks}</span>,
                sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                additionalBonus,
              },
            )}
          </p>
          <img
            className={classes.pointsScale}
            src={pointsScaleImage}
            alt=""
            data-testid="points-scale-img"
          />
        </>
      )}
    />
  );
};
