import { useIntl } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';

import brandImageMobile from '../../../../assets/images/brand-pages/ao1dmax-mf-30-front-new-mobile.png';
import brandImage from '../../../../assets/images/brand-pages/ao1dmax-mf-30-front-new-3.png';
import uv from '../../../../assets/images/brand-pages/uv.svg';
import { BrandPage } from './brand-page';

export const BrandPageUv = (): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const intl = useIntl();

  return (
    <BrandPage
      title={intl.formatMessage(
        { id: 'brandPages.uv.title' },
        {
          linebreak: <br />,
          sup: (chunks: React.ReactNode) => <sup>{chunks}</sup>,
        },
      )}
      brandImage={isMobile ? brandImageMobile : brandImage}
      topIcon={uv}
      description={intl.formatMessage(
        { id: 'brandPages.uv.details' },
        {
          linebreak: !isMobile && <br />,
          sup: (chunks: React.ReactNode) => <sup>{chunks}</sup>,
        },
      )}
      legal={intl.formatMessage(
        { id: 'brandPages.uv.description' },
        { sup: (chunks: React.ReactNode) => <sup>{chunks}</sup> },
      )}
    />
  );
};
