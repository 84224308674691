import { startOfDay } from 'date-fns';

import { AppointmentStatusType } from '../../graphql/appointments/models/get-appointments.models';
import { convertStringToDate } from '../date/convert-string-to-date';

const isAppointmentActual = (date: string): boolean => (
  convertStringToDate(date)!.valueOf() - startOfDay(new Date()).valueOf() >= 0
);

export const isAppointmentContentShow = (date: string, status?: string): boolean => {
  const isAppointmentStatusGood = !!status && (
    status === AppointmentStatusType.confirmed || status === AppointmentStatusType.unconfirmed
  );

  return isAppointmentActual(date) && isAppointmentStatusGood;
};
