import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
  fontWeights,
}: Theme) => ({
  root: {
    marginBottom: 12,
    [breakpoints.up('sm')]: {
      marginBottom: 20,
    },
  },
  card: {
    padding: '20px 16px 16px',
    [breakpoints.up('sm')]: {
      padding: 32,
    },
  },
  title: {
    margin: '0 0 24px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxl,
    lineHeight: lineHeights.xxl,
    fontWeight: fontWeights.medium,
    color: palette.text.secondary,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.xxxl,
      lineHeight: lineHeights.xxxl,
    },
    [breakpoints.up('lg')]: {
      margin: '0 0 32px',
    },
  },
}));
