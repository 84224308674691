import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette, fontWeights }: Theme) => ({
  root: {
    height: '100%',
    '&.active-link': {
      borderBottom: '4px solid rgb(10, 124, 193)',
      color: palette.common.black,
      fontWeight: fontWeights.medium,
      '& svg path': {
        fill: palette.common.black,
      },
    },
  },
}));
