import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  headerAndContentWrapper: {
    flex: '1 0 auto',
  },
  footerWrapper: {
    flex: '0 0 auto',
  },
  secondary: {
    backgroundColor: palette.secondary.light,
  },
}));
