import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  imageLink: {
    display: 'block',
  },
  image: {
    display: 'block',
    maxWidth: '100%',
  },
}));
