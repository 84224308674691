import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { GET_APP_LEVEL_MODALS_DATA } from '../../../../graphql/app-level-modals/queries/get-modals-data';
import { IAppLevelModalsData } from '../../../../graphql/app-level-modals/models/get-modals-data.model';
import { usePurchasesConsumerBanModal } from './modals/purchases-consumer-ban/purchases-consumer-ban';
import { useBirthdayPointsModal } from './modals/birthday-points';
import { withAuthorization } from './hocs/with-authorization';
import { useAftercareSurveyModal } from './modals/aftercare-survey';

const AppLevelModalsComponent = (): null => {
  const { data } = useQuery<IAppLevelModalsData>(GET_APP_LEVEL_MODALS_DATA);
  const { handleAftercareSurveyModal } = useAftercareSurveyModal();
  const { handleBirthdayPointsModal } = useBirthdayPointsModal();
  const { handlePurchasesConsumerBanModal } = usePurchasesConsumerBanModal();

  useEffect(() => {
    if (data) {
      const {
        fittingByAuthId,
        notificationByAuthId,
        pointsTransactionByAuthIdAndType,
      } = data;

      // modals should appear in certain order
      handleAftercareSurveyModal(fittingByAuthId, notificationByAuthId);
      handleBirthdayPointsModal(notificationByAuthId, pointsTransactionByAuthIdAndType);
      handlePurchasesConsumerBanModal(notificationByAuthId);
    }
  }, [data]);

  return null;
};

export const AppLevelModals = withAuthorization(AppLevelModalsComponent);
