import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format } from 'date-fns';

import { IOrderCardProps } from './order-card.models';
import { useStyles } from './order-card.styles';
import { Card } from '../../../../common/card';
import { Divider } from '../../../../common/divider';
import { Products } from './products';
import { OrderStatus } from '../../../../../graphql/order/models/get-orders.models';
import { OrderRepeatButton } from './order-repeat-button';
import { getOrderStatusTranslation } from '../../../../../utils/order/get-order-status';
import { Icon, IconSizes } from '../../../../common';
import { getStatusIcon } from '../../../../../utils/order/get-status-icon';
import { CustomBreakpoints } from '../../../../../themes/default';

export const OrderCard = ({
  orderNumber, orderStatus, orderDate, orderProducts, id, onClick, storeData, rating,
}: IOrderCardProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const formattedOrderDate = format(new Date(orderDate), 'dd.MM.yyyy');
  const iconType = useMemo(() =>
    getStatusIcon(orderStatus), [orderStatus]);
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down(CustomBreakpoints.smallMobileDown));
  const isLandscapeMobile = useMediaQuery(theme.breakpoints.between(
    CustomBreakpoints.landscapeMobileStart, CustomBreakpoints.landscapeMobileEnd,
  ));
  const isShowRepeat = orderStatus === OrderStatus.fulfilled
    && (!isSmallMobile && !isLandscapeMobile);
  const isShowRepeatSmall = orderStatus === OrderStatus.fulfilled
    && (isSmallMobile || isLandscapeMobile);

  return (
    <Card
      className={classes.root}
      data-testid="order-card"
      id={id}
      onClick={onClick}
    >
      <div className={classes.titleBar}>
        <h3
          className={classes.title}
          data-testid="order-title"
        >
          {intl.formatMessage(
            { id: 'order.card.title' },
            { orderNumber },
          )}
        </h3>
        {isShowRepeat && (
          <div
            data-testid="order-repeat-wrapper"
            className={classes.repeatWrapper}
          >
            <OrderRepeatButton
              orderNumber={orderNumber}
              storeData={storeData}
              orderProducts={orderProducts}
              rating={rating!}
            />
          </div>
        )}
      </div>
      <p
        className={classes.date}
        data-testid="order-date"
      >
        {orderDate && formattedOrderDate}
      </p>
      {isShowRepeatSmall && (
        <div
          data-testid="order-repeat-wrapper-small"
          className={classes.repeatWrapperSmall}
        >
          <OrderRepeatButton
            orderNumber={orderNumber}
            storeData={storeData}
            orderProducts={orderProducts}
            rating={rating!}
          />
        </div>
      )}
      <Products
        products={orderProducts}
      />
      <Divider />
      <p
        className={classes.status}
        data-testid="order-status"
      >
        <span
          className={classes.statusIcon}
          data-testid="order-status-icon"
          aria-hidden={true}
        >
          <Icon
            type={iconType}
            size={IconSizes.xsm}
          />
        </span>
        <span
          className={classes.statusNote}
          data-testid="order-status-note"
        >
          <FormattedMessage id={getOrderStatusTranslation(orderStatus)} />
        </span>
      </p>
    </Card>
  );
};
