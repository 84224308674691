import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontFamilies, fontWeights, fontSizes, palette, borderRadiuses, siteMediumWidth, spaceMin,
}: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    maxWidth: siteMediumWidth,
    margin: '2rem auto',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    background: `linear-gradient(270deg, #00a3e0 0%, ${palette.primary.dark} 35.5%, ${palette.primary.dark} 65%, #00a3e0 100%)`,
    boxShadow: '3px 4px 13px 0 rgba(5, 41, 103, 0.1)',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      marginBottom: '4rem',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textDecoration: 'none',
    [breakpoints.up('md')]: {
      gap: '1.5rem',
      flexDirection: 'row',
      padding: spaceMin,
    },
    '&:focus-visible': {
      outline: `1px dashed ${palette.primary.dark}`,
      outlineOffset: 2,
    },
  },
  sectionTitle: {
    width: '100%',
    maxWidth: '15rem',
    margin: 0,
    color: palette.common.white,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.lg,
    lineHeight: 1.4,
    [breakpoints.up('md')]: {
      maxWidth: '20rem',
      fontSize: fontSizes.xxl,
      lineHeight: 1.35,
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: spaceMin,
    width: '100%',
    padding: spaceMin,
    [breakpoints.up('md')]: {
      padding: 0,
      overflow: 'visible',
      gap: spaceMin,
      width: 'calc((100% - 3rem) / 2)',
      height: 'fit-content',
    },
  },
  imgContainer: {
    width: '100%',
    [breakpoints.up('md')]: {
      width: 'calc((100% - 1.5rem) / 2)',
    },
    '& img': {
      display: 'block',
      width: '100%',
      objectFit: 'contain',
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    padding: '0.75rem 1.25rem',
    borderRadius: borderRadiuses.lg,
    background: palette.common.white,
    color: palette.primary.dark,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.sm,
    lineHeight: 1.5,
    textAlign: 'center',
  },
  newLine: {
    display: 'block',
  },
}));
