import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useStyles } from './parameter.styles';
import { getParameterValues } from '../../../utils/get-parameter-values';
import { ToggleButtonGroup } from '../../../../../../../../common/form/toggle-button-group';
import {
  getParamItems,
  getParamSelectItems,
} from '../../../form-select-items/param-items';
import {
  getOrderParamLabelTranslationId,
  getUnits,
} from '../../../../../../../../common/eye-params/utils';
import { getSelectedValue } from '../../../utils/get-selected-value';
import { Select } from '../../../../../../../../common/form/select';
import { IParameterProps } from './parameter.models';
import {
  getFormattedValue,
} from '../../../../../../../../common/eye-params/utils/get-formatted-value';
import { EyeParamsType } from '../../../../../../../../../graphql/lenses/models/get-lenses.models';

export const Parameter = ({
  brandParameter, onValueChange, parameters, cylinderValue, axisValue,
}: IParameterProps): JSX.Element => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState<string>('');

  const isAxis = brandParameter.name === EyeParamsType.axis;
  const isCylinder = brandParameter.name === EyeParamsType.cylinder;

  const titleValue = useMemo(() => {
    const value = getSelectedValue(brandParameter.name, parameters, selectedValue);
    const formattedValue = getFormattedValue(value, brandParameter.name, true);

    return `: ${formattedValue} ${getUnits(brandParameter.name)}`;
  }, [selectedValue]);

  const handleValueChange = (selectValue: string) => {
    setSelectedValue(selectValue);
    onValueChange(selectValue, brandParameter);
  };

  const handleValueToggle = (...values: unknown[]) => {
    const value = ((values[0] as PointerEvent).target as HTMLButtonElement)!.value;

    setSelectedValue(value);
    onValueChange(value, brandParameter);
  };

  useEffect(() => {
    if (isAxis && axisValue.length) {
      setSelectedValue(axisValue);
    }
  }, [axisValue]);

  useEffect(() => {
    if (isCylinder && cylinderValue.length) {
      setSelectedValue(cylinderValue);
    }
  }, [cylinderValue]);

  return (
    <div
      data-testid={`params-${brandParameter.name.toLowerCase()}-wrapper`}
    >
      <h3
        className={classes.title}
        data-testid="params-title"
      >
        <span>
          <FormattedMessage
            id={getOrderParamLabelTranslationId(brandParameter.name)}
          />
        </span>
        <span data-testid="params-title-value">
          {titleValue}
        </span>
      </h3>
      {getParameterValues(brandParameter.configuration).length < 5 ? (
        <ToggleButtonGroup
          name={brandParameter.name}
          options={getParamItems({
            paramValues: getParameterValues(brandParameter.configuration),
            property: brandParameter.name,
            isOrder: true,
          })}
          exclusiveExactly
          withoutMargins
          standalone
          size="medium"
          variant="outlined"
          selectedStyles={classes.selected}
          buttonStyles={classes.toggleButton}
          onCustomChange={handleValueToggle}
          data-testid={`params-${brandParameter.name.toLowerCase()}`}
        />
      ) : (
        <Select
          name={brandParameter.name}
          options={getParamSelectItems({
            paramValues: getParameterValues(brandParameter.configuration),
            property: brandParameter.name,
            isOrder: true,
          })}
          data-testid={`params-${brandParameter.name.toLowerCase()}`}
          className={classes.select}
          formControlClasses={classes.selectFormControl}
          paperStyles={classes.selectPaperStyles}
          listStyles={classes.selectListStyles}
          itemStyles={classes.selectItemStyles}
          onCustomChange={handleValueChange}
        />
      )}
    </div>
  );
};
