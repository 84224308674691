import { IListProps } from './list.models';
import { Icon } from '../../../../../common';

export const List = ({ items, listStyles, itemStyles, ...props }: IListProps): JSX.Element => (
  <ul
    {...props}
    className={listStyles}
  >
    {items.map((item) => (
      <li
        key={item.id}
        className={itemStyles}
      >
        {item.icon && (
          <Icon
            type={item.icon}
            size={item.iconSize}
          />
        )}
        {item.item}
      </li>
    ))}
  </ul>
);
