import React from 'react';
import { cx } from '@emotion/css';
import { capitalize } from 'lodash';

import { BoxProps } from './box.models';
import { useStyles } from './box.styles';
import { BackButton } from '../back-button-new';

export const Box = ({
  children,
  footerDescription,
  title,
  description,
  responsiveMargins,
  externalRootClasses,
  isBackButton,
  isBackButtonState = true,
}: BoxProps): JSX.Element => {
  const classes = useStyles();
  const hasDescription = Boolean(description);

  return (
    <div
      className={cx(
        classes.root,
        { [classes[
          `rootResponsiveMargins${capitalize(responsiveMargins as string)}` as 'rootResponsiveMarginsSm'
          | 'rootResponsiveMarginsXs'
        ]]: !!responsiveMargins },
        externalRootClasses,
      )}
    >
      <div className={classes.paperRoot}>
        {isBackButton && (
          <BackButton isState={isBackButtonState} />
        )}
        {title && (
          <h1
            className={cx(classes.title, {
              [classes.titleWithoutDescription]: !hasDescription,
            })}
            data-testid="box-title"
          >
            {title}
          </h1>
        )}
        {hasDescription && (
          <p className={classes.description}>
            {description}
          </p>
        )}
        {children}
      </div>
      {footerDescription && (
        <footer className={classes.footer}>
          <p className={classes.footerDescription}>
            {footerDescription}
          </p>
        </footer>
      )}
    </div>
  );
};
