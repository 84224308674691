import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    border: `1px solid ${palette.grey['200']}`,
    [breakpoints.up('md')]: {
      padding: 20,
    },
  },
  title: {
    height: '100%',
    margin: '0 auto',
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    color: palette.text.primary,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  description: {
    marginTop: 2,
    marginBottom: 0,
    color: palette.text.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  bonusImage: {
    width: 40,
    height: 40,
    marginRight: 12,
    lineHeight: 0,
    [breakpoints.up('sm')]: {
      width: 48,
      height: 48,
      marginRight: 16,
    },
    '& svg': {
      width: 40,
      height: 40,
      [breakpoints.up('sm')]: {
        width: 48,
        height: 48,
      },
    },
  },
}));
