import { useMutation } from '@apollo/client';
import { useModal } from 'mui-modal-provider';

import { CancelOrderButton as CancelOrderButtonView } from './cancel-order-button';
import { UPDATE_ORDER } from '../../../../../../../graphql/order/mutations/update-order';
import {
  useActionsInProgress,
} from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import {
  promiseErrorCallbacks,
} from '../../../../../../../utils/promise/set-promise-error-callbacks';
import { OrderStatus } from '../../../../../../../graphql/order/models/get-orders.models';
import { useCallback } from 'react';
import { ICancelOrderButtonContainerProps } from './cancel-order-button.models';
import { CancelOrderModal } from '../cancel-order-modal';
import { GET_ORDERS } from '../../../../../../../graphql/order/queries/get-orders';

export const CancelOrderButton = ({
  orderId, updateStatus,
}: ICancelOrderButtonContainerProps): JSX.Element => {
  const { showModal } = useModal();
  const [updateOrder] = useMutation(UPDATE_ORDER, {
    refetchQueries: [{
      query: GET_ORDERS,
    }],
  });
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const handleCancelOrder = useCallback(async () => {
    try {
      addActionInProgress();

      await updateOrder({
        variables: {
          orderId,
          order: {
            orderStatus: OrderStatus.canceledByPatient,
          },
        },
      });

      updateStatus(OrderStatus.canceledByPatient);
    } catch {
      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    }

    removeActionInProgress();
  }, [
    updateOrder,
    addActionInProgress,
    removeActionInProgress,
  ]);

  const handleButtonClick = () => {
    showModal(CancelOrderModal, {
      onCancelConfirm: handleCancelOrder,
    });
  };

  return (
    <CancelOrderButtonView onButtonClick={handleButtonClick} />
  );
};
