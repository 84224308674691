import { FormattedMessage } from 'react-intl';

import { ConfirmationModal } from '../../../../../../common/confirmation-modal';
import { Icon, IconTypes, ResponsiveWidth } from '../../../../../../common';
import { ICancelOrderModal } from './cancel-order-modal.models';
import { useStyles } from './cancel-order-modal.styles';

export const CancelOrderModal = ({
  onCancelConfirm, onClose, ...props
}: ICancelOrderModal): JSX.Element => {
  const handleConfirm = () => {
    onCancelConfirm();
    onClose!();
  };

  const handleReject = () => {
    onClose!();
  };

  const classes = useStyles();

  return (
    <ConfirmationModal
      {...props}
      data-testid="cancel-order-modal"
      topIcon={(
        <span
          data-testid="icon-wrapper"
          aria-hidden='true'
          className={classes.iconWrapper}
        >
          <Icon type={IconTypes.cartWithCross} />
        </span>
      )}
      title={<FormattedMessage id="order.cancel.modal.title" />}
      content={(
        <>
          <p data-testid="paragraph-first">
            <FormattedMessage id="order.cancel.modal.content.first" />
          </p>
          <p data-testid="paragraph-second">
            <FormattedMessage id="order.cancel.modal.content.second" />
          </p>
        </>
      )}
      confirmButtonLabel={<FormattedMessage id="order.cancel.modal.confirm" />}
      rejectButtonLabel={<FormattedMessage id="order.cancel.modal.reject" />}
      onConfirm={handleConfirm}
      onReject={handleReject}
      rejectOnClose
      isMultiContent
      responsiveWidth={ResponsiveWidth.mdWide}
      contentStyles={classes.content}
      titleStyles={classes.title}
      propsContentStyles={classes.contentWrapper}
    />
  );
};
