import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, lineHeights, fontWeights,
}: Theme) => ({
  title: {
    '& sup': {
      lineHeight: 0,
    },
  },
  subtitleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 32,
    [breakpoints.up('sm')]: {
      marginTop: 48,
    },
  },
  subtitle: {
    marginBottom: 16,
    marginTop: 0,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      marginBottom: 20,
      fontSize: fontSizes.lg,
      lineHeight: lineHeights.lg,
    },
  },
}));
