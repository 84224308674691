import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  fontWeights, fontSizes, lineHeights, palette,
}: Theme) => ({
  root: {
    position: 'absolute',
    maxWidth: 104,
    padding: '8px 0',
    boxShadow: '0 1px 2px rgb(0, 0, 0, 0.1), 0 8px 16px rgb(0, 0, 0, 0.2)',
  },
  rootMobile: {
    paddingTop: 32,
  },
  backdropRoot: {
    backgroundColor: 'transparent',
  },
  content: {
    padding: 0,
  },
  title: {
    maxWidth: 200,
    marginTop: 0,
    marginBottom: 16,
    color: palette.common.black,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
  },
  selected: {
    border: 0,
    '&&': {
      color: palette.common.black,
      backgroundColor: palette.grey['200'],
      '&:hover, &:focus': {
        backgroundColor: palette.grey['200'],
      },
    },
  },
  selectedMobile: {
    borderColor: palette.common.black,
    '&&': {
      color: palette.common.black,
      backgroundColor: palette.common.white,
      '&&:not(:first-child)': {
        borderLeftColor: palette.common.black,
      },
      '&:hover, &:focus': {
        backgroundColor: palette.common.white,
      },
    },
  },
  formControl: {
    width: '100%',
  },
  toggleButton: {
    justifyContent: 'left',
    width: '100%',
    margin: 0,
    borderRadius: 0,
    color: palette.custom.greyNeutral,
    '&&&:not(:last-child), &&&:not(:first-child)': {
      marginRight: 0,
      border: 0,
      borderRadius: 0,
    },
  },
  toggleButtonMobile: {
    marginBottom: 0,
    color: palette.custom.greyNeutral,
    '&&&:not(:last-child)': {
      marginRight: 8,
    },
  },
}));
