import { useMemo, ReactElement } from 'react';

import { OrderCard, IOrderCardProps } from '../order-card';
import { IOrders } from '../../../../../graphql/order/models/get-orders.models';
import { sortByDateDesc } from '../../../../../utils/date/sort-by-date';
import { getBrandsPoints } from '../../../../../utils/order/get-brands-points';

export const useGetOrders = (ordersData?: IOrders): { orders: ReactElement<IOrderCardProps>[] } => {
  const result = useMemo(() => {
    let orders: ReactElement<IOrderCardProps>[] = [];

    if (ordersData && ordersData?.ordersByAuthId.length) {
      const ordersList = ordersData.ordersByAuthId;
      const brandsPoints = getBrandsPoints(ordersData);

      orders = sortByDateDesc([...ordersList], 'createdDateTime')
        .map(({
          orderDate,
          orderStatus,
          systemId,
          products,
          store,
          id,
          deliveryDate,
          voucher,
          voucherId,
          purchases,
          salesforceStoreInfo,
        }) => (
          <OrderCard
            orderDate={orderDate}
            orderStatus={orderStatus}
            orderNumber={systemId}
            orderProducts={products}
            id={systemId}
            brandsPoints={brandsPoints}
            storeData={store}
            orderId={id}
            deliveryDate={deliveryDate}
            bonus={voucher}
            bonusId={voucherId}
            purchases={purchases}
            rating={salesforceStoreInfo?.rating}
          />
        ));
    }

    return {
      orders,
    };
  }, [ordersData]);

  return result;
};
