import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, borderRadiuses, fontSizes }: Theme) => ({
  root: {},
  menuBox: {
    minWidth: 280,
    borderRadius: borderRadiuses.sm,
  },
  menuPaper: {
    boxShadow: '0px 5px 7px 0px rgba(0,0,0,0.3)',
    [breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      left: '0 !important',
    },
    '&&': {
      maxHeight: 'calc(100% - 96px)',
    },
  },
  menuItemIconRoot: {
    '&&': {
      minWidth: 0,
      marginRight: 4,
    },
  },
  menuListItem: {
    minWidth: 'unset',
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: fontSizes.sm,
    lineHeight: 1.5,
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    '& .MuiTypography-root': {
      minWidth: 'unset',
      background: 'transparent',
    },
  },
  menuInnerText: {
    [breakpoints.down('sm')]: {
      flex: '0 1 auto',
    },
    '& .MuiTypography-root': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
  },
  listItemHidden: {
    display: 'none',
  },
}));
