import React, { forwardRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../../../../common/form';
import { TextField } from '../../../../../../../../common/form/text-field';
import { IPhoneStepFormProps } from './phone-step-form.models';

export const PhoneStepForm = forwardRef<IFormImperativeHandleProps, IPhoneStepFormProps>(({
  onSubmit,
  validationSchema,
  defaultValues,
}, ref): JSX.Element => {
  const resultDefaultValues = useMemo(() => defaultValues || {
    newPhoneNumber: '',
  }, [defaultValues]);

  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      defaultValues={resultDefaultValues}
    >
      <TextField
        name="newPhoneNumber"
        type="tel"
        fullWidth
        autoFocus
        label={(
          <FormattedMessage
            id="changePhone.phoneStep.phoneTextField.label"
          />
        )}
      />
    </Form>
  );
});
