import { FormattedMessage } from 'react-intl';

import { Modal, ResponsiveWidth } from '../../../../../common';
import { Order } from '../../../user-activity-items';
import { IOrderModal } from './order-modal.models';
import { setOrderNumber } from '../../../../../../graphql/order/order.cache';
import { useEffect } from 'react';

export const OrderModal = ({
  points, storeData, products, title, orderNumber, orderStatus, orderId,
  deliveryDate, bonus, bonusId, purchaseDate, rating, ...props
}: IOrderModal): JSX.Element => {
  useEffect(() => {
    return () => {
      setOrderNumber('');
    };
  }, []);

  return (
    <Modal
      {...props}
      title={<FormattedMessage
        id="order.card.title"
        values={{
          orderNumber,
        }}
      />}
      responsiveWidth={ResponsiveWidth.xsFull}
      content={(
        <Order
          isEditMode={false}
          points={points}
          storeData={storeData}
          products={products}
          status={orderStatus}
          orderId={orderId}
          deliveryDate={deliveryDate}
          bonus={bonus}
          bonusId={bonusId}
          purchaseDate={purchaseDate}
          rating={rating!}
        />
      )}
    />
  );
};
