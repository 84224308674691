import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, lineHeights, fontWeights, borderRadiuses, palette,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 16px 0',
    [breakpoints.up('sm')]: {
      height: '100%',
      padding: '24px 24px 0',
    },
    '&&': {
      borderRadius: borderRadiuses.md,
      boxShadow: 'none',
      borderColor: palette.grey['400'],
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    margin: 0,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  date: {
    margin: 0,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0 20px',
    margin: 0,
    [breakpoints.up('sm')]: {
      padding: '16px 0',
    },
  },
  statusIcon: {
    marginRight: 8,
    marginTop: 2,
  },
  statusNote: {
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    letterSpacing: 0,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  titleBar: {
    position: 'relative',
  },
  repeatWrapper: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
  },
  repeatWrapperSmall: {
    marginTop: 8,
  },
}));
