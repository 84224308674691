import React from 'react';
import { useBarcode } from 'react-barcodes';
import { IBarcodeProps } from './barcode.models';

export const Barcode = ({ value, className }: IBarcodeProps) => {
  const { inputRef } = useBarcode({
    value,
    options: {
      displayValue: false,
      background: '#ffffff',
      margin: 0,
    },
  });

  return (
    <svg
      ref={inputRef}
      className={className}
      data-testid="barcode"
    />
  );
};
