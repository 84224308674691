import { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ConfirmationModal } from '../../../../../../../common/confirmation-modal';
import { DeleteProfileProps } from './delete-profile-modal.models';
import { Icon, IconTypes } from '../../../../../../../common';
import { useStyles } from './delete-profile.styles';

export const DeleteProfileModal: FC<DeleteProfileProps> = ({ onConfirm, ...props }): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <ConfirmationModal
      {...props}
      onConfirm={onConfirm}
      hasChangedButtonsPriority
      propsPaperStyles={classes.modal}
      topIcon={<span className={classes.icon}><Icon type={IconTypes.deleteModal} /></span>}
      title={<FormattedMessage id="profileDeletion.modal.title" />}
      titleStyles={classes.title}
      propsContentStyles={classes.title}
      content={intl.formatMessage(
        { id: 'profileDeletion.modal.description' },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      )}
      confirmButtonLabel={
        <FormattedMessage id="profileDeletion.modal.confirmButton.label" />
      }
      rejectButtonLabel={
        <FormattedMessage id="profileDeletion.modal.rejectButton.label" />
      }
    />
  );
};
