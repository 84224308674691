import { ElementType, ReactNode } from 'react';
import { PaperProps } from '@mui/material';

import { TLinkHref } from '../../../models/link.models';

export enum CardTypes {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
  bonus = 'bonus',
}

export interface IAdditionalProps {
  to?: TLinkHref;
  state?: unknown;
  component?: ElementType;
  type?: CardTypes;
  href?: string | null;
  target?: string;
}

export type TCardProps = PaperProps & Omit<IAdditionalProps, 'target'>;

export interface ItemWithIcon {
  item: string;
  icon: ReactNode;
}
