import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useStyles } from './ecp-locator.styles';
import { InternalLinks } from '../../../../../constants';
import ecpPreviewMobile from '../../../../../assets/images/ecp-preview/ecp-preview-mobile.png';
import ecpPreview from '../../../../../assets/images/ecp-preview/ecp-preview.png';
import ecpPreviewTablet from '../../../../../assets/images/ecp-preview/ecp-preview-tablet.png';
import { ContentWrapper } from '../../../../common/content-wrapper';

export const EcpLocator = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

  const image = useMemo(() => {
    if (isMobile) {
      return ecpPreviewMobile;
    } else if (isDesktop) {
      return ecpPreview;
    } else {
      return ecpPreviewTablet;
    }
  }, [isMobile, isDesktop]);

  return (
    <ContentWrapper>
      <section
        data-testid="ecp-locator-landing-wrapper"
        aria-label={intl.formatMessage({ id: 'ecp.landing.section.ariaLabel' })}
        className={classes.root}
      >
        <a
          data-testid="inner-container"
          className={classes.container}
          href={InternalLinks.ecpLocator}
        >
          <div className={classes.content}>
            <h2
              data-testid="ecp-locator-landing-title"
              className={classes.sectionTitle}
            >
              {intl.formatMessage({ id: 'ecp.preview.title' })}
            </h2>
            <span
              data-testid="ecp-locator-landing-button"
              className={classes.button}
            >
              <FormattedMessage id="ecp.preview.button" />
            </span>
          </div>
          <div
            className={classes.imgContainer}
            data-testid="ecp-map-image"
            aria-hidden="true"
          >
            <img src={image} alt="" />
          </div>
        </a>
      </section>
    </ContentWrapper>
  );
};
