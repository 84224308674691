import { IConsumerBanModalProps } from './consumer-ban-modal.models';
import { useStyles } from './consumer-ban-modal.styles';
import { Modal } from '../modal';
import { Icon, IconTypes } from '../icon';
import { Button } from '../button';

export const ConsumerBanModal = ({
  icon, title, description, actionButtonProps, ...props
}: IConsumerBanModalProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Modal
      {...props}
      className={classes.root}
      propsPaperStyles={classes.modal}
      propsContentStyles={classes.root}
      content={(
        <>
          <div
            className={classes.icon}
            data-testid="consumer-ban-icon"
          >
            <Icon type={icon || IconTypes.consumerBan} />
          </div>
          <h2
            data-testid="consumer-ban-modal-title"
            className={classes.title}
          >
            {title}
          </h2>
          <div
            data-testid="consumer-ban-modal-description"
            className={classes.description}
          >
            {description}
          </div>
        </>
      )}
      footer={(
        <Button
          {...actionButtonProps}
          data-testid="consumer-ban-modal-action"
          variant="contained"
          fullWidth
        />
      )}
    />
  );
};
