import { getIsAppUpdateScheduledFromStorage } from './get-is-app-update-scheduled-from-storage';
import { setIsAppUpdateScheduledToStorage } from './set-is-app-update-scheduled-to-storage';

export const scheduleAppUpdate = (): void => {
  const isAppUpdateScheduled = getIsAppUpdateScheduledFromStorage();

  if (isAppUpdateScheduled === null) {
    setIsAppUpdateScheduledToStorage(true);
    window.location.reload();
  }
};
