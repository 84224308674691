import { ReactNode } from 'react';
import { ClassNameMap } from '@mui/styles';
import { FormattedMessage, IntlShape } from 'react-intl';

import { ExternalLinkPanel } from '../../../../../../common/external-link-panel';
import { IPolicyConfig, IPolicyItem } from './items.models';

const getPolicyTitle = (id: string): JSX.Element => (
  <FormattedMessage
    id={id}
    values={{
      linebreak: <br />,
    }}
  />
);

const getPolicyContent = (
  id: string, classes: ClassNameMap, intl: IntlShape, withLink = false,
): ReactNode => intl.formatMessage(
  { id },
  {
    h4: (chunks: ReactNode) => <h4 className={classes.strongTitle}>{chunks}</h4>,
    p: (chunks: ReactNode) => <p className={classes.paragraph}>{chunks}</p>,
    ...(withLink ? {
      link: (chunks: ReactNode) => <ExternalLinkPanel link={chunks as string} />,
    } : {}),
  },
);

const getPolicyItems = (
  config: IPolicyConfig[], classes: ClassNameMap, intl: IntlShape,
): IPolicyItem[] => config.map((configItem) => ({
  title: getPolicyTitle(`${configItem.id}.title`),
  content: getPolicyContent(`${configItem.id}.text`, classes, intl, configItem.withLink),
}));

const jnjPolicies: IPolicyConfig[] = [
  { id: 'cookiePolicy.necessary' },
  { id: 'cookiePolicy.additional' },
];

export const getJnjPolicies = (classes: ClassNameMap, intl: IntlShape) => (
  getPolicyItems(jnjPolicies, classes, intl)
);

const thirdPartyPolicies: IPolicyConfig[] = [
  { id: 'cookiePolicy.ga', withLink: true },
  { id: 'cookiePolicy.marketing' },
  { id: 'cookiePolicy.fb.gate' },
  { id: 'cookiePolicy.fb.ref' },
  { id: 'cookiePolicy.yt', withLink: true },
  { id: 'cookiePolicy.gpref' },
];

export const getThirdPartyPolicies = (
  classes: ClassNameMap, intl: IntlShape,
) => (getPolicyItems(thirdPartyPolicies, classes, intl));
