import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box } from '../../../common/box';
import { LoginForm } from '../login-form';
import { ILoginProps } from './login.models';

export const Login = ({ onLogin }: ILoginProps): JSX.Element => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'loginPage.title' })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'loginPage.description' })}
        />
      </Helmet>
      <Box
        title={<FormattedMessage id="login.title" />}
        isBackButton
        isBackButtonState={false}
      >
        <LoginForm onSubmit={onLogin} />
      </Box>
    </>
  );
};
