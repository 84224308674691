import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes,
}: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    '&&': {
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none',
    },
  },
  brand: {
    position: 'relative',
    maxWidth: 'calc(100% - 56px)',
    margin: 0,
    paddingLeft: 30,
    fontSize: fontSizes.xs,
    lineHeight: 1.3,
    [breakpoints.up('sm')]: {
      maxWidth: 'calc(100% - 72px)',
    },
  },
  image: {
    objectFit: 'contain',
    width: 48,
    marginTop: 2,
    marginRight: 8,
    [breakpoints.up('sm')]: {
      width: 60,
      marginRight: 12,
    },
  },
  imageSolutionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: 48,
    marginTop: 2,
    marginRight: 8,
    [breakpoints.up('sm')]: {
      width: 60,
      marginRight: 12,
    },
  },
  imageSolutions: {
    objectFit: 'contain',
    width: 'auto',
    height: 45,
    [breakpoints.up('sm')]: {
      height: 54,
    },
  },
  number: {
    position: 'absolute',
    left: 0,
    top: 3,
  },
}));
