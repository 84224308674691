import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    marginBottom: 12,
    [breakpoints.up('md')]: {
      marginBottom: 16,
    },
  },
}));
