import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { IChangePasswordModalViewProps } from './change-password-modal.models';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { Modal, ResponsiveWidth } from '../../../../../common/modal';
import { Button } from '../../../../../common/button';
import { ChangePasswordForm } from '../change-password-form';

export const ChangePasswordModal = forwardRef<
IFormImperativeHandleProps, IChangePasswordModalViewProps>(({
  validationSchema, onChangePassword, onSubmitButtonClick, ...props
}, ref): JSX.Element => (
  <Modal
    {...props}
    responsiveWidth={ResponsiveWidth.xs}
    title={(
      <FormattedMessage
        id="changePassword.title"
      />
    )}
    content={(
      <ChangePasswordForm
        ref={ref}
        onSubmit={onChangePassword}
        validationSchema={validationSchema}
      />
    )}
    footer={(
      <Button
        variant="contained"
        fullWidth
        onClick={onSubmitButtonClick}
        data-testid="change-password-submit"
      >
        <FormattedMessage
          id="changePassword.submitButton.label"
        />
      </Button>
    )}
  />
));
