import { FormattedMessage } from 'react-intl';

import { LoyaltyType } from '../../../../../graphql/promotions/models/get-promotions.models';
import { BonusType, IBonus } from '../../../../../graphql/bonuses/models/get-bonuses.models';

const getSecondVoucher = (value: number, id: string): IBonus => ({
  type: BonusType.loyalty,
  name: LoyaltyType.discountSecondPurchase,
  endDate: <FormattedMessage id="promotions.secondVoucher.endDate" />,
  description: <FormattedMessage id="promotions.secondVoucher.shortDescription" />,
  value,
  id: `${id}-second`,
  brands: [],
  packSize: null,
  externalLink: null,
  voucherType: null,
});

export const replaceWelcomeVoucher = (promotions: IBonus[]) => {
  const firstVoucher = promotions?.find((
    promotion,
  ) => promotion.name === LoyaltyType.discountFirstPurchase);
  const welcomeVouchers = firstVoucher ? [firstVoucher, getSecondVoucher(firstVoucher.value as number, firstVoucher.id)] : [];
  const promotionsNoWelcome = promotions?.filter((
    promotion,
  ) => promotion.name !== LoyaltyType.discountFirstPurchase);

  return [...promotionsNoWelcome, ...welcomeVouchers];
};
