import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { DeliverySection } from './delivery-section';
import { DeliveryStoreEdit } from './delivery-store-edit';
import { DeliveryStoreNoEdit } from './delivery-store-no-edit';
import { DeliveryChangeButton } from './delivery-change-button/delivery-change-button';
import { IDeliveryProps, IInlineContent } from './delivery.models';
import { useStyles } from './delivery.styles';

export const Delivery = ({
  storeData, isEditMode, deliveryDate, onStoreChange, isActiveStatus, isCancelledOrder,
  isFulfilledOrder, purchaseDate, isConfirmedOrder,
}: IDeliveryProps): JSX.Element => {

  const priceContent: IInlineContent[] = [{
    id: 'order.create.confirm.price.dependency',
    content: <FormattedMessage id="order.create.confirm.price.dependency" />,
  }, {
    id: 'order.create.confirm.price.check',
    content: <FormattedMessage id="order.create.confirm.price.check" />,
  }];

  const dateInlineContent: IInlineContent[] = [{
    id: 'order.create.confirm.date.ready1',
    content: <FormattedMessage id="order.create.confirm.date.ready1" />,
  }, {
    id: 'order.create.confirm.date.ready2',
    content: <FormattedMessage id="order.create.confirm.date.ready2" />,
  }];

  const dateContent = useMemo(() => {
    let content: IInlineContent[] | undefined;

    switch (true) {
      case isConfirmedOrder:
        content = [{
          id: 'order.create.confirm.date.ready1',
          content: <FormattedMessage id="order.delivery.date.ready.noTime"/>,
        }];
        break;
      case !deliveryDate:
        content = dateInlineContent;
        break;
      default:
        content = undefined;
    }

    return content;
  }, [isConfirmedOrder, deliveryDate]);

  const classes = useStyles();
  const intl = useIntl();

  const dateMessage = isConfirmedOrder ? (
    <p
      data-testid="date-message-ready"
      className={classes.paragraphWithMargin}
    >
      <FormattedMessage id="order.delivery.date.ready" />
    </p>
  ) : (
    <p
      data-testid="date-message"
      className={classes.paragraphWithMargin}
    >
      <FormattedMessage id="order.create.confirm.date.emphasis" />
    </p>
  );

  return (
    <div
      data-testid="delivery-wrapper"
      className={cx(classes.root, {
        [classes.rootWithBottomMargin]: isActiveStatus,
      })}
    >
      <div
        data-testid="delivery-title-bar"
        className={classes.titleBar}
      >
        <h3
          data-testid="delivery-title"
          className={classes.title}
        >
          <FormattedMessage id="order.create.confirm.delivery.title" />
        </h3>
        {isEditMode && <DeliveryChangeButton onStoreChange={onStoreChange} />}
      </div>
      {isEditMode ? (
        <DeliveryStoreEdit storeData={storeData} />
      ) : (
        <DeliveryStoreNoEdit
          storeData={storeData}
          withBorder={!isCancelledOrder}
          isFulfilledOrder={isFulfilledOrder}
        />
      )}
      {isActiveStatus && (
        <DeliverySection
          name="date"
          title={<FormattedMessage id="order.create.confirm.date.title" />}
          withBorder={isActiveStatus}
          inlineContent={dateContent}
        >
          {deliveryDate && !isConfirmedOrder ? (
            <p
              data-testid="date-message"
              className={classes.paragraphWithMargin}
            >
              {new Date(deliveryDate).toLocaleDateString(intl.locale, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </p>
          ) : dateMessage}
        </DeliverySection>
      )}
      {(isFulfilledOrder && purchaseDate) && (
        <DeliverySection
          name="date"
          title={<FormattedMessage id="order.purchaseDate" />}
        >
          <p
            data-testid="date-message"
            className={classes.paragraphWithMargin}
          >
            {new Date(purchaseDate).toLocaleDateString(intl.locale, {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </p>
        </DeliverySection>
      )}
      {isActiveStatus && (
        <DeliverySection
          name="price"
          title={<FormattedMessage id="order.create.confirm.price.title" />}
          inlineContent={priceContent}
        >
          <p
            data-testid="price-link-wrapper"
            className={classes.paragraphWithMargin}
          >
            <a
              href={`tel:${storeData?.phonenumber}`}
              data-testid="price-link"
              className={classes.linkNoUnderline}
            >
              <FormattedMessage id="order.create.confirm.price.button" />
            </a>
          </p>
        </DeliverySection>
      )}
    </div>
  );
};
