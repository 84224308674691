import { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { ILensesCardViewProps } from './lenses-card.models';
import { useStyles } from './lenses-card.styles';
import { LensesBrandLogo } from '../../../../../common/lenses-brand-logo';
import { EyeParams } from '../../../../../common/eye-params';
import { useEyeParamsByEye } from '../../../../../../hooks/use-eye-params-by-eye';
import { NoveltyLabel } from '../../../../../common/novelty-label';
import { LensesBrandType } from '../../../../../../graphql/lenses/models/get-lenses.models';
import { checkLabelEnabled } from '../utils/check-label-enabled';
import { OutOfSalesLabel } from '../../../out-of-sales/out-of-sales-label';

export const LensesCard = ({ cardData }: ILensesCardViewProps): JSX.Element => {
  const classes = useStyles();
  const {
    brandId, leftEye, rightEye, brand: { labels },
  } = cardData;
  const {
    leftEyeColumnData,
    rightEyeColumnData,
    isOneEye,
  } = useEyeParamsByEye(leftEye, rightEye);
  const [isLabelEnabled, setIsLabelEnabled] = useState<boolean>(false);
  const [labelName, setLabelName] = useState<string>('');
  const intl = useIntl();
  const isAOM1DBrand = brandId === LensesBrandType.aom1d || brandId === LensesBrandType.aomaxmf;

  useEffect(() => {
    if (labels.length) {
      const label = labels.find(checkLabelEnabled);
      if (label && label.name.length) {
        setLabelName(label.name);
        setIsLabelEnabled(true);
      }
    }
  }, [labels]);

  return (
    <li className={classes.root} data-testid="lenses-card">
      <div>
        <div className={classes.logoContainer}>
          <LensesBrandLogo
            className={cx(classes.lensesBrandLogo,
              { [classes.lensesBrandLogoAOM1D]: isAOM1DBrand },
            )}
            brand={brandId as LensesBrandType}
          />
        </div>
        <div className={classes.eyeParamsContainer}>
          <EyeParams {...rightEyeColumnData} />
          {!isOneEye && (
            <EyeParams {...leftEyeColumnData} />
          )}
        </div>
      </div>
      {isLabelEnabled && (
        <div>
          <NoveltyLabel
            className={classes.noveltyLabel}
            label={labelName}
          />
        </div>
      )}
      {brandId === LensesBrandType.oneDate && (
        <OutOfSalesLabel
          message={intl.formatMessage({
            id: 'lenses.1DATE.outOfSales.message',
          },
          {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
          },
          )}
          messageStyles={classes.messageStyles}
        />
      )}
    </li>
  );
};
