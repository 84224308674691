import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../../../common';
import { useStyles } from './cancel-order-button.styles';
import { ICancelOrderButtonProps } from './cancel-order-button.models';

export const CancelOrderButton = ({
  onButtonClick, ...props
}: ICancelOrderButtonProps): JSX.Element => {
  const classes = useStyles();

  const handleButtonClick = () => {
    onButtonClick!();
  };

  return (
    <Button
      {...props}
      variant="text"
      className={classes.root}
      onClick={handleButtonClick}
      data-testid="cancel-order-button"
    >
      <FormattedMessage id="order.cancel.button" />
    </Button>
  );
};
