import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { useQuery, useMutation } from '@apollo/client';
import { isEqual } from 'lodash';

import { yup } from '../../../../../../../services/yup';
import { IStepComponentProps, IStepperImperativeHandleProps } from '../../../../../../common/stepper';
import { IFormImperativeHandleProps, IFormSubmitValues } from '../../../../../../common/form';
import { IStepsState } from '../../vision-profile.models';
import { isAllFieldsEmpty } from './utils/is-all-fields-empty';
import { GET_VISION_PROFILE } from '../../../../../../../graphql/profile/queries/get-vision-profile';
import { UPDATE_VISION_PROFILE } from '../../../../../../../graphql/profile/mutations';
import { useCancellablePromise } from '../../../../../../../hooks/use-cancellable-promise';
import { useActionsInProgress } from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { IVisionProfileData } from '../../../../../../../graphql/profile/models/get-vision-profile.models';
import { promiseErrorCallbacks } from '../../../../../../../utils/promise/set-promise-error-callbacks';
import {
  convertArrayToObject,
} from '../../../../../../../utils/data-structure-convertations/convert-array-to-object';
import {
  ACTIVITY_MAX_LENGTH,
  oneTypeOfAlphabetEmptyRegex,
} from '../../../../../../../constants';
import { WhatDoYouLikeView } from '../../../vision-profile-views/what-do-you-like-view';
import { TWhatDoYouLikeValues } from '../../../vision-profile-views/what-do-you-like-view/what-do-you-like-form';

export const WhatDoYouLikeStep = forwardRef<
IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  stepsState,
  onNextButtonDisabled,
}, ref): JSX.Element => {
  const intl = useIntl();
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const { whatDoYouLike: whatDoYouLikeState } = stepsState as IStepsState;
  const { updateQuery } = useQuery<IVisionProfileData>(GET_VISION_PROFILE);
  const [updateVisionProfile] = useMutation(UPDATE_VISION_PROFILE);
  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const defaultValues = useMemo(() => whatDoYouLikeState || {
    whatDoYouLikePredefined: [],
    whatDoYouLikeCustom: '',
  }, []);

  const validationSchema = useMemo(() => yup.object({
    whatDoYouLikeCustom: yup.string().nullable().trim().max(
      ACTIVITY_MAX_LENGTH, intl.formatMessage({ id: 'common.errors.max' }))
      .matches(oneTypeOfAlphabetEmptyRegex, { message: intl.formatMessage({
        id: 'common.errors.oneTypeOfAlphabet' }) }),
  }), []);

  const handleUpdateWhatDoYouLike = async ({
    whatDoYouLikeCustom: whatDoYouLikeCustomCurrent,
    whatDoYouLikePredefined: whatDoYouLikePredefinedCurrent,
  }: TWhatDoYouLikeValues) => {
    const whatDoYouLikeCustom = whatDoYouLikeState?.whatDoYouLikeCustom;
    const whatDoYouLikePredefined = whatDoYouLikeState?.whatDoYouLikePredefined;
    const isCustomFieldsEqual = whatDoYouLikeCustom === whatDoYouLikeCustomCurrent;
    const isPredefinedFieldsEqual = isEqual(
      convertArrayToObject(whatDoYouLikePredefined ?? []),
      convertArrayToObject(whatDoYouLikePredefinedCurrent ?? []),
    );

    if (isCustomFieldsEqual && isPredefinedFieldsEqual) {
      onGoNextSuccess({ whatDoYouLikeCustom, whatDoYouLikePredefined });
      return;
    }

    addActionInProgress();

    try {
      const { data: { patchVisionProfile } } = await makeCancellablePromise(
        updateVisionProfile({
          variables: {
            visionProfile: {
              whatDoYouLikeCustom: whatDoYouLikeCustomCurrent,
              whatDoYouLikePredefined: whatDoYouLikePredefinedCurrent,
            },
          },
        }),
      );

      updateQuery((prevState) => ({
        visionProfile: {
          ...prevState.visionProfile,
          ...patchVisionProfile,
        },
      }));

      removeActionInProgress();
      onGoNextSuccess({
        whatDoYouLikeCustom: patchVisionProfile.whatDoYouLikeCustom,
        whatDoYouLikePredefined: patchVisionProfile.whatDoYouLikePredefined,
      });
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }

      removeActionInProgress();
    }
  };

  const handleValidateDependencies = (isValid: boolean) => {
    const values = formRef.current!.getValues() as TWhatDoYouLikeValues;

    if (!isAllFieldsEmpty(values)) {
      onNextButtonDisabled(!isValid);
    }
  };

  const handleChangeValuesDependencies = (formValues: IFormSubmitValues) => {
    const disabledState = isAllFieldsEmpty(formValues as TWhatDoYouLikeValues);

    onNextButtonDisabled(disabledState);
  };

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return formRef.current!.getValues();
    },
  }));

  useEffect(() => {
    handleChangeValuesDependencies(defaultValues);
  }, [defaultValues]);

  return (
    <WhatDoYouLikeView
      ref={formRef}
      formProps={{
        onSubmit: handleUpdateWhatDoYouLike,
        onValidateDependencies: handleValidateDependencies,
        onChangeValuesDependencies: handleChangeValuesDependencies,
        defaultValues,
        validationSchema,
        validationMode: 'onChange',
      }}
    />
  );
});
