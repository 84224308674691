import { EyeParamsType } from '../../../../graphql/lenses/models/get-lenses.models';

const paramsLabelTranslationIds = {
  [EyeParamsType.power]: 'power',
  [EyeParamsType.axis]: 'axis',
  [EyeParamsType.baseCurve]: 'baseCurve',
  [EyeParamsType.cylinder]: 'cylinder',
  [EyeParamsType.addition]: 'addition',
  [EyeParamsType.variant]: 'variant',
};

export const getParamLabelTranslationId = (name: EyeParamsType): string => `eyeParams.params.${paramsLabelTranslationIds[name]}`;

export const getOrderParamLabelTranslationId = (name: EyeParamsType): string => `order.changeParamsModal.params.${paramsLabelTranslationIds[name]}`;
