import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';

import { SupportBlock } from '../support-block';
import { useStyles } from './footer.styles';
import { DownloadApp } from './download-app';
import { PolicyLinks } from '../policy-links';
import logo from '../../../assets/images/logo/logo-light.svg';
import { ExternalLinks } from '../../../constants';

export const Footer = ({ legal }: { legal?: string }): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));


  return (
    <footer
      className={classes.footer}
      data-testid="footer"
    >
      <div className={classes.inner}>
        <div className={classes.logoGroup}>
          <div className={classes.logoWrapper} aria-hidden="true">
            <img src={logo} alt=""/>
          </div>
          {!isMobile && <div className={classes.policy}><PolicyLinks /></div>}
        </div>
        <div className={classes.supportContainer}>
          <SupportBlock />
        </div>
        {isMobile && <div className={classes.policy}><PolicyLinks /></div>}
        <DownloadApp />
        <div className={classes.separator} data-testid="separator" />
        <div className={classes.legal}>
          <p>{intl.formatMessage(
            { id: 'footer.disclaimer' },
            {
              sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
              nonBreakSpace: <>&nbsp;</>,
            },
          )}</p>
          <p>{intl.formatMessage(
            { id: 'footer.campaigns' },
            {
              sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
              a: (chunks: ReactNode) => <a href={ExternalLinks.myAcuvueProgramPolicyLink} className={classes.link}>{chunks}</a>,
            },
          )}</p>
          <p>{intl.formatMessage(
            { id: 'footer.terms' },
            {
              sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
              a: (chunks: ReactNode) => <a href={ExternalLinks.myAcuvueProgramPolicyLink} className={classes.link}>{chunks}</a>,
            },
          )} {legal && intl.formatMessage({ id: legal })}</p>
        </div>
      </div>
    </footer>
  );
};
