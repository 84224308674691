import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../../common';
import { IChangeButtonProps } from './change-button.models';
import { useStyles } from './change-button.styles';

export const ChangeButton = ({
  onButtonClick, ariaLabel, labelId, ...props
}: IChangeButtonProps): JSX.Element => {
  const classes = useStyles();

  const handleButtonClick = () => {
    onButtonClick!();
  };

  return (
    <Button
      {...props}
      variant="text"
      aria-label={ariaLabel}
      className={classes.root}
      onClick={handleButtonClick}
    >
      <FormattedMessage id={labelId ? labelId : 'order.create.confirm.changeButton.label'} />
    </Button>
  );
};
