import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { useStyles } from './how-to-switch-list.styles';
import { List } from '../list';
import { IconTypes } from '../../../../../common';
import { InternalLinks } from '../../../../../../constants';

export const HowToSwitchList = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  const bonuses = [{
    id: 'switch-bonuses-item-1',
    item: <>{intl.formatMessage(
      { id: 'outOfSalesModal.howToSwitch.list.item2.list.item1' },
      {
        p: (chunks: ReactNode) => (
          <p
            data-testid="partial-switch"
            className={cx(classes.partial, classes.inline)}
          >{chunks}</p>
        ),
        link: (chunks: ReactNode) => (
          <a
            className={classes.innerLink}
            data-testid="bonuses-inner-link-1"
            href={InternalLinks.myBonusesPage}
          >
            {chunks}
          </a>
        ),
      },
    )}</>,
  }, {
    id: 'switch-bonuses-item-2',
    item: <>{intl.formatMessage(
      { id: 'outOfSalesModal.howToSwitch.list.item2.list.item2' },
      {
        p: (chunks: ReactNode) => (
          <p
            data-testid="partial-switch"
            className={cx(classes.partial, classes.inline)}
          >{chunks}</p>
        ),
        link: (chunks: ReactNode) => (
          <a
            className={classes.innerLink}
            data-testid="bonuses-inner-link-2"
            href={InternalLinks.myBonusesPage}
          >
            {chunks}
          </a>
        ),
      },
    )}</>,
  }];

  const switchSteps = [{
    id: 'switch-step-1',
    item: <div className={classes.second} data-testid="first-item">{intl.formatMessage(
      { id: 'outOfSalesModal.howToSwitch.list.item1' },
      {
        p: (chunks: ReactNode) => (
          <p
            data-testid="partial-switch"
            className={cx(classes.partial, classes.inline)}
          >{chunks}</p>
        ),
        strong: (chunks: ReactNode) => <span className={classes.strongText}>{chunks}</span>,
        link: (chunks: ReactNode) => (
          <a
            className={classes.innerLink}
            data-testid="ecp-inner-link"
            href={InternalLinks.ecpLocator}
          >
            {chunks}
          </a>
        ),
      },
    )}</div>,
    icon: IconTypes.store,
  }, {
    id: 'switch-step-2',
    item: <div className={classes.second} data-testid="second-item">
      {intl.formatMessage(
        { id: 'outOfSalesModal.howToSwitch.list.item2.title' },
        {
          p: (chunks: ReactNode) => (
            <p
              className={classes.partialWithMargin}
            >
              {chunks}
            </p>
          ),
          strong: (chunks: ReactNode) => <span className={classes.strongText}>{chunks}</span>,
        },
      )}
      <List
        items={bonuses}
        data-testid="switch-bonuses-list"
        listStyles={classes.bonusesList}
        itemStyles={classes.bonusesItem}
      />
    </div>,
    icon: IconTypes.discount,
  }];

  return (
    <List
      items={switchSteps}
      data-testid="switch-list"
      listStyles={classes.root}
      itemStyles={classes.item}
    />
  );
};
