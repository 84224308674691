import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { useStyles } from './list.styles';

export const List = ({ items, style }: { items: string[], style?: string }): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <ul className={cx(classes.list, style)} data-testid="list">
      {items.map(item => (
        <li key={item} data-testid="list-item" className={classes.item}>
          {intl.formatMessage(
            { id: item },
            { sup: (chunks: ReactNode) => <sup>{chunks}</sup> })}
        </li>))}
    </ul>
  );
};
