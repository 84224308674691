import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ITutorialProps } from './tutorial.models';
import { useStyles } from './tutorial.styles';
import { Modal, ResponsiveWidth } from '../modal';
import { Button } from '../button';

export const Tutorial = ({
  icon, title, description, addLink, onClose, ...props
}: ITutorialProps): JSX.Element => {
  const classes = useStyles();

  const handleButtonClick = () => {
    onClose!();
  };

  return (
    <Modal
      {...props}
      responsiveWidth={ResponsiveWidth.xl}
      onClose={onClose}
      content={(
        <div className={classes.contentWrapper}>
          <div className={classes.icon}>
            {icon}
          </div>
          <div className={classes.content}>
            <div className={classes.title}>
              {title}
            </div>
            <div className={classes.description}>
              {description}
            </div>
          </div>
        </div>
      )}
      footer={(
        <div className={classes.actions}>
          <Button
            variant="contained"
            data-testid="tutorial-see-button"
            fullWidth
            onClick={handleButtonClick}
          >
            <FormattedMessage
              id="tutorial.button.label"
            />
          </Button>
          {addLink}
        </div>
      )}
    />
  );
};
