import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  title: {
    [breakpoints.up('sm')]: {
      marginBottom: 32,
    },
  },
}));
