import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { Card, CardTypes } from '../../../../../../../../../../common/card';
import { IPackagesListProps } from './package-cards-list.models';
import { useStyles } from './package-cards-list.styles';
import { LensesBrandLogo } from '../../../../../../../../../../common';
import { getBrandFullName } from '../../../../../../../../../../../utils/lenses/get-brand-full-name';
import {
  LensesBrandType,
} from '../../../../../../../../../../../graphql/lenses/models/get-lenses.models';
import { Carousel } from '../../../../../../../../../../common/carousel';

export const PackageCardsList = ({ loyaltyPoints: brandLoyaltyPoints }: IPackagesListProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.root} data-testid="brand-cards-wrapper">
      <Carousel
        spaceBetween={16}
        draggable
        slidesPerView={'auto'}
      >
        {brandLoyaltyPoints.length && brandLoyaltyPoints
          // Remove 1DATE - out of sales
          .filter(({ brandId }) => !(brandId === LensesBrandType.oneDate))
          .map(({ brandId, loyaltyPoints }) => (
            <Card
              type={CardTypes.primary}
              className={classes.card}
              key={brandId}
              data-testid="package-card"
            >
              <LensesBrandLogo
                brand={brandId as LensesBrandType}
                className={classes.brandLogo}
              />
              <h4
                className={cx(classes.text, classes.title)}
                data-testid="package-card-title"
              >
                {intl.formatMessage(
                  { id: getBrandFullName(brandId) },
                  { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
                )}
              </h4>
              <ul
                className={classes.packageParamsList}
                data-testid="package-card-params"
              >
                {loyaltyPoints
                  .map(({ packSize, value }) => (
                    <li
                      key={`${packSize}${value}`}
                      className={classes.packageParamsItem}
                      data-testid="package-card-params-item"
                    >
                      <span
                        className={classes.text}
                        data-testid="package-card-pack-size"
                      >
                        <FormattedMessage
                          id="compliancePeriodArticle.howMatchPoints.lenses"
                          values={{ lensesPackageAmount: packSize }}
                        />
                      </span>
                      <span
                        className={cx(classes.text, classes.strongText)}
                        data-testid="package-card-points"
                      >
                        +{value}
                      </span>
                    </li>
                  ))}
              </ul>
            </Card>
          ))}
      </Carousel>
    </div>
  );
};
