import { FormattedMessage } from 'react-intl';

import { useStyles } from './confirm-step-title.styles';
import { IconButton } from '../../../../../../../common/icon-button';
import { IconSizes, IconTypes } from '../../../../../../../common';
import { Breakpoints } from '../../../../../../../../themes/default';
import { IConfirmStepTitleProps } from './confirm-step-title.models';
import { useGoBack } from '../../../../../../../../hooks/use-go-back';
import { useCallback } from 'react';

export const ConfirmStepTitle = ({ onGoBack, isRepeatOrder }: IConfirmStepTitleProps): JSX.Element => {
  const classes = useStyles();

  const width = window.innerWidth;
  const isTablet = width > Breakpoints.md || width === Breakpoints.md;

  const { goBack } = useGoBack();

  const handleBackButtonClick = useCallback(() => goBack(), [goBack]);

  return (
    <h3
      className={classes.title}
      data-testid="confirm-step-title"
    >
      <IconButton
        onClick={isRepeatOrder ? handleBackButtonClick : onGoBack}
        iconProps={{
          type: IconTypes.arrowLeft,
          size: isTablet ? IconSizes.md : IconSizes.sm,
        }}
        className={classes.backButton}
        data-testid="back-button"
      />
      <span data-testid="title-content">
        <FormattedMessage id="order.create.confirm.title" />
      </span>
    </h3>
  );
};
