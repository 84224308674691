import { FormattedMessage } from 'react-intl';

import { IEyeParametersProps } from './eye-parameters.models';
import { EyeParams } from '../../../../../../common/eye-params';

export const EyeParameters = ({ eye, updatedParameters }: IEyeParametersProps): JSX.Element => {
  const parameters = {
    title: (
      <FormattedMessage id="order.create.parameters.title" />),
    params: updatedParameters ? updatedParameters : eye!,
    isOrder: true,
  };

  return (
    <div
      data-testid="eye-params-wrapper"
    >
      <EyeParams {...parameters} isOrder />
    </div>
  );
};
