import { IEcpStore } from '../../rest/ecp-locator';

export const sortByRaiting = (stores: IEcpStore[]): IEcpStore[] => {
  const storesToSort = [...stores];
  if (storesToSort?.length) {
    return storesToSort.sort((
      storeFirst, storeSecond,
    ) => storeSecond.rating! - storeFirst.rating!
      || +((storeFirst.distance)?.toFixed(1)) - +((storeSecond.distance)?.toFixed(1)));
  }
  return [];
};
