import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useModal } from 'mui-modal-provider';

import { GET_APP_LEVEL_MODALS_DATA } from '../../../../../../graphql/app-level-modals/queries/get-modals-data';
import {
  IAppLevelModalsData,
  IFittingsForSurvey,
  INotification,
} from '../../../../../../graphql/app-level-modals/models/get-modals-data.model';
import { getAftercareSurveyNotifications } from './utils/get-aftercare-survey-notifications';
import { AftercareSurveyModal } from './aftercare-survey-modal';
import {
  checkAftercareModalActive,
} from '../../../../../../utils/notifications/check-aftercare-modal-active';

export const useAftercareSurveyModal = () => {
  const [shouldModalUpdate, setModalUpdateState] = useState(true);
  const { updateQuery } = useQuery<IAppLevelModalsData>(GET_APP_LEVEL_MODALS_DATA);
  const { showModal } = useModal();

  const updateNotifications = useCallback((notificationId: string) => {
    setModalUpdateState(true);

    updateQuery((prevState) => ({
      ...prevState,
      notificationByAuthId: prevState.notificationByAuthId
        .filter(({ id }) => !(notificationId === id)),
    }));
  }, [
    updateQuery,
    setModalUpdateState,
  ]);

  const handleAftercareSurveyModal = useCallback((
    fittings: IFittingsForSurvey[], notifications: INotification[],
  ) => {
    if (shouldModalUpdate) {
      const aftercareSurveyNotifications = getAftercareSurveyNotifications(notifications);

      if (aftercareSurveyNotifications.length) {
        const { sourceObjectIds, id: notificationId, activeOn } = aftercareSurveyNotifications[0];
        const isSurveyShow = checkAftercareModalActive(activeOn);

        if (sourceObjectIds.length && isSurveyShow) {
          const sourceObjectId = sourceObjectIds[0];
          const aftercareFitting = fittings.find(({ id }) => id === sourceObjectId);

          if (aftercareFitting) {
            const { consumerId, store: { displayName: storeName } } = aftercareFitting;

            showModal(AftercareSurveyModal, {
              consumerId,
              storeName,
              sourceObjectId,
              notificationId,
              updateNotifications,
            });

            setModalUpdateState(false);
          }
        }
      }
    }
  }, [
    shouldModalUpdate,
    showModal,
    setModalUpdateState,
  ]);

  return { handleAftercareSurveyModal };
};
