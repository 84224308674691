import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  borderRadiuses,
  fontFamilies,
  fontSizes,
  palette,
  fontWeights,
  lineHeights,
}: Theme) => ({
  tooltip: {
    width: '90vw',
    maxWidth: 'unset',
    marginRight: 10,
    padding: 12,
    borderRadius: borderRadiuses.sm,
    background: palette.common.white,
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxs,
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights.xs,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)',
    [breakpoints.up('sm')]: {
      marginRight: 0,
      padding: 16,
      maxWidth: 340,
    },
  },
  tooltipArrow: {
    color: palette.common.white,
  },
}));
