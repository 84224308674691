import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  iconWrapper: {
    display: 'block',
    textAlign: 'right',
    marginLeft: 45,
    lineHeight: 0,
    '& img': {
      width: 120,
      height: 'auto',
      objectFit: 'contain',
    },
  },
  item: {
    maxWidth: 150,
    textAlign: 'center',
  },
}));
