import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { INoActiveAppointmentModalProps } from './no-active-appointment-modal.models';
import { useStyles } from './no-active-appointment-modal.styles';
import { Button, Modal, ResponsiveWidth } from '../../../../common';
import { InternalLinks } from '../../../../../constants';
import { useGoBack } from '../../../../../hooks/use-go-back';

export const NoActiveAppointmentModal = ({
  ...props
}: INoActiveAppointmentModalProps): JSX.Element => {
  const classes = useStyles();
  const { hasPreviousLocationState } = useGoBack();
  const intl = useIntl();

  return (
    <Modal
      {...props}
      data-testid="no-active-appointment-modal"
      responsiveWidth={ResponsiveWidth.lg}
      propsPaperStyles={classes.propsPaperStyles}
      propsContentStyles={classes.propsContentStyles}
      disableClosing
      content={(
        <>
          <h1
            className={classes.title}
            data-testid="no-active-appointment-modal-title"
          >
            {intl.formatMessage(
              { id: 'appointment.noActive.modal.title' },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </h1>
          <p
            className={classes.description}
            data-testid="no-active-appointment-modal-description"
          >
            {intl.formatMessage(
              { id: 'appointment.noActive.modal.description' },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </p>
        </>
      )}
      footer={(
        <div
          className={classes.actions}
          data-testid="no-active-appointment-modal-buttons"
        >
          <Button
            variant="contained"
            to={InternalLinks.ecpLocator}
            state={hasPreviousLocationState}
            className={classes.register}
            data-testid="no-active-appointment-ecp"
          >
            <FormattedMessage
              id="appointment.noActive.modal.ecpButton.label"
            />
          </Button>
          <Button
            variant="outlined"
            to="/"
            state={hasPreviousLocationState}
            className={classes.login}
            data-testid="no-active-appointment-home"
          >
            <FormattedMessage
              id="appointment.noActive.modal.homeButton.label"
            />
          </Button>
        </div>
      )}
    />
  );
};
