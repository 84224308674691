import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
  borderRadiuses,
}: Theme) => ({
  root: {
    display: 'inline-block',
    padding: '4px 8px',
    backgroundColor: palette.secondary.main,
    color: palette.common.white,
    borderRadius: borderRadiuses.md,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxs,
    lineHeight: lineHeights.xxs,
    zIndex: 1,
  },
}));
