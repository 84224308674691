import {
  forwardRef,
  MutableRefObject,
  useCallback,
  useState,
} from 'react';

import { OrderCreateCard as OrderCreateCardView } from './order-create-card';
import { IOrderCreateCardContainerProps } from './order-create-card.models';
import { IEyeParamsOption } from '../../../../../../graphql/lenses/models/get-lenses.models';
import {
  FormValues,
} from './change-parameters/change-parameters-modal/change-parameters-modal.models';
import { getEyeParams } from '../utils/get-eye-params';
import { IFormImperativeHandleProps } from '../../../../../common/form';

export const OrderCreateCard = forwardRef<IFormImperativeHandleProps, IOrderCreateCardContainerProps>(({
  brand,
  packSizes,
  index,
  points,
  brandParameters,
  onIncreasePackageValue,
  onDecreasePackageValue,
  stepState,
  eye,
  productQuantity,
  packSize,
  id,
}, ref): JSX.Element => {
  const formRef = ref as MutableRefObject<IFormImperativeHandleProps>;
  const [quantity, setQuantity] = useState<number>(productQuantity);
  const [packSizeSelected, setPackSizeSelected] = useState<number>(packSize);
  const [parameters, setParameters] = useState<IEyeParamsOption[]>(eye!);

  const handleChangeQuantity = useCallback((value: number) => {
    setQuantity(value);
    formRef.current?.setValue(`${index}.productQuantity`, String(value), {
      shouldDirty: true,
    });
  }, [setQuantity]);

  const handleChangePackSize = (value: string, isMobile?: boolean) => {
    setPackSizeSelected(Number(value));

    const acuvuePoints = points.find(({ packSize: size }) => size === Number(value))!.acuvuePoints;
    formRef.current?.setValue(`${index}.singlePoints`, String(acuvuePoints), {
      shouldDirty: true,
    });

    if (isMobile) {
      formRef.current?.setValue(`${index}.packSize`, value, {
        shouldDirty: true,
      });
    }
  };

  const handleUpdateParameters = (values: FormValues) => {
    setParameters(getEyeParams(values));
    formRef.current?.setValue(`${index}.eye`, getEyeParams(values), {
      shouldDirty: true,
    });
  };

  return (
    <OrderCreateCardView
      brand={brand}
      packSizes={packSizes}
      points={points}
      onUpdateParameters={handleUpdateParameters}
      onChangeQuantity={handleChangeQuantity}
      onChangePackSize={handleChangePackSize}
      productQuantity={quantity}
      packSizeSelected={packSizeSelected}
      eye={eye}
      parameters={parameters}
      formValues={formRef?.current?.getValues()}
      onDecreasePackageValue={onDecreasePackageValue}
      onIncreasePackageValue={onIncreasePackageValue}
      stepState={stepState}
      brandParameters={brandParameters}
      id={id}
    />
  );
});
