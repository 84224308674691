export enum PromotionType {
  freepack = 'FREEPACK',
  discount = 'DISCOUNT',
  giftCertificate = 'GIFT_CERTIFICATE',
}

export enum LoyaltyType {
  discountFirstPurchase = 'WelcomeDiscountForFirstPurchase',
  discountSecondPurchase = 'WelcomeDiscountForSecondPurchase',
}
