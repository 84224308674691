import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontSizes,
  fontWeights,
  palette,
  lineHeights,
}: Theme) => ({
  root: {
    flexDirection: 'column',
    padding: '16px 0 12px 12px',
    boxShadow: 'none',
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: 20,
    },
    '&&': {
      display: 'flex',
    },
  },
  content: {
    maxWidth: 'calc(100% - 104px)',
  },
  title: {
    margin: '0 0 2px',
    color: palette.text.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.lg,
      lineHeight: lineHeights.lg,
    },
  },
  status: {
    display: 'flex',
    margin: '0 0 16px',
    color: palette.secondary.main,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      marginBottom: 10,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  statusIcon: {
    display: 'flex',
    marginTop: 2,
    marginRight: 4,
    '& svg': {
      width: 14,
      height: 14,
      [breakpoints.up('sm')]: {
        width: 16,
        height: 16,
      },
    },
    '& svg path': {
      fill: palette.secondary.main,
    },
  },
  showButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  showButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    padding: '12px 12px 14px 0',
    background: 'transparent',
    [breakpoints.up('sm')]: {
      paddingRight: 24,
      paddingBottom: 16,
    },
  },
  paragraph: {
    margin: 0,
    color: palette.text.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  dateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: 0,
    paddingLeft: 12,
    borderLeft: `1px solid ${palette.custom.secondaryLight}`,
    color: palette.text.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.regular,
    [breakpoints.up('sm')]: {
      paddingLeft: 24,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  month: {
    textTransform: 'capitalize',
  },
  day: {
    marginTop: 2,
    marginBottom: 2,
    color: palette.common.black,
    fontSize: fontSizes.xxl,
    lineHeight: lineHeights.xl,
    [breakpoints.up('sm')]: {
      marginTop: 4,
      marginBottom: 4,
      fontSize: fontSizes['3xl'],
      lineHeight: lineHeights.xl,
    },
  },
}));
