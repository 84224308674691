import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { IWikiCardProps } from './wiki-card.models';
import { useStyles } from './wiki-card.styles';
import { Card, CardTypes } from '../card';

export const WikiCard = ({ title, description }: IWikiCardProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Card
      type={CardTypes.quaternary}
      data-testid="wiki-card"
    >
      <h3
        className={classes.title}
        data-testid="wiki-card-title"
      >
        {intl.formatMessage(
          { id: title },
          { nonBreakSpace: <>&nbsp;</> },
        )}
      </h3>
      <div
        className={classes.description}
        data-testid="wiki-card-description"
      >
        <ul
          className={classes.list}
          data-testid="wiki-card-list"
        >
          {description.map((wikiItem) => (
            <li
              key={wikiItem.name}
              className={classes.listItem}
              data-testid="wiki-card-list-item"
            >
              {
                wikiItem.link ? (
                  <a
                    href={wikiItem.link}
                    className={classes.link}
                    data-testid="wiki-card-list-item-link"
                    target="_blank"
                  >
                    {intl.formatMessage(
                      { id: wikiItem.name },
                      { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
                    )}
                  </a>
                ) : <>{intl.formatMessage(
                  { id: wikiItem.name },
                  { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
                )}</>
              }
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};
