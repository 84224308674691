import { IOrderStore } from '../../components/business/app/order/order-create-page/order-create-page.models';
import { IEcpStore } from '../../rest/ecp-locator';

export const mapEcpStoreToOrderStore = (ecpStore: IEcpStore): IOrderStore => ({
  name: ecpStore.name,
  street: ecpStore.street,
  phonenumber: ecpStore.phonenumber,
  openinghours: ecpStore.openinghours,
  type: ecpStore.type,
  rating: ecpStore.rating !== null ? ecpStore.rating.toString() : null,
  latitude: ecpStore.latitude,
  longitude: ecpStore.longitude,
  code: ecpStore.code,
  city: ecpStore.city,
  distance: ecpStore.distance,
});
