import { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useBind } from './use-bind';
import { useSnackbar } from './use-snackbar';

export const useEmailExitValidation = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const enqueueSnackbarBind = useBind(enqueueSnackbar);

  const handleEmailExist = useCallback(() => {
    enqueueSnackbarBind.current(
      intl.formatMessage(
        { id: 'registration.emailStep.errors.email.exist' },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      ),
      { variant: 'error' },
    );
  }, [enqueueSnackbarBind]);

  return {
    handleEmailExist,
  };
};
