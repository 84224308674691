import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  fontSizes, lineHeights, breakpoints,
}: Theme) => ({
  content: {
    marginTop: 0,
    marginBottom: '0.8em',
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:last-child': {
      marginBottom: 24,
    },
  },
}));
