import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { ICmsBannerProps } from './cms-banner.models';
import { useStyles } from './cms-banner.styles';

export interface WithLinkComponentProps {
  children: ReactElement;
  link?: string;
  className: string;
}

const WithLink = ({ link, children, className }: WithLinkComponentProps): JSX.Element => {
  if (link) {
    return link.startsWith('pwa://')
      ? (
        <Link
          to={link.replace('pwa:/', '')}
          className={className}
          data-testid="banner"
        >
          {children}
        </Link>
      ) : (
        <a
          className={className}
          href={link}
          data-testid="banner"
          target="_blank"
        >
          {children}
        </a>
      );
  }

  return children;
};

export const CmsBannerView = ({ source, link }: ICmsBannerProps): JSX.Element => {
  const classes = useStyles();

  return (
    <WithLink
      link={link}
      className={classes.imageLink}
    >
      <img
        className={classes.image}
        src={source}
        alt=""
      />
    </WithLink>
  );
};
