import { useIntl, FormattedMessage } from 'react-intl';
import { useModal } from 'mui-modal-provider';

import {
  BirthdayDisableChangeModal,
  BirthdayModal,
  GadgetsUseDurationModal,
  LastVisionCheckModal,
  TimeOutsideDurationModal,
  WearRegularityModal,
} from '../../vision-profile-update-modals';
import { IPersonalInfoProps } from './personal-info.models';
import { PersonalInfo as PersonalInfoView } from './personal-info';
import { IconTypes } from '../../../../../common';
import { getWearRegularity } from '../utils/get-wear-regularity';
import { getTimeOptions } from '../utils/get-time-options';
import { convertShortStringToDate, convertStringToDate } from '../../../../../../utils/date/convert-string-to-date';

export const PersonalInfo = ({ visionProfileData }: IPersonalInfoProps): JSX.Element => {
  const intl = useIntl();
  const { showModal } = useModal();

  const {
    dateOfBirth,
    meta: { canUpdateDateOfBirth },
    wearRegularity,
    gadgetsUseDuration,
    timeOutsideDuration,
    lastVisionCheck,
  } = visionProfileData;

  const handleBirthdayCardClick = () => {
    if (canUpdateDateOfBirth) {
      showModal(BirthdayModal, { dateOfBirth: convertStringToDate(dateOfBirth) });
    } else {
      showModal(BirthdayDisableChangeModal);
    }
  };

  const handleGadgetUseDurationCardClick = () => {
    showModal(GadgetsUseDurationModal, { gadgetsUseDuration });
  };

  const handleLastVisionCheckCardClick = () => {
    showModal(LastVisionCheckModal, {
      lastVisionCheck: convertShortStringToDate(lastVisionCheck) as Date,
      dateOfBirth: dateOfBirth as string,
    });
  };

  const handleTimeOutsideDurationCardClick = () => {
    showModal(TimeOutsideDurationModal, { timeOutsideDuration });
  };

  const handleWearRegularityCardClick = () => {
    showModal(WearRegularityModal, { wearRegularity });
  };

  const personalInfoCards = [
    {
      title: 'vp.page.birthdayDate',
      description: new Date(dateOfBirth!).toLocaleDateString(intl.locale, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),
      buttonLabel: 'vp.page.birthdayDate.buttonLabel',
      icon: IconTypes.giftRoundedBlue,
      onItemClick: handleBirthdayCardClick,
    },
    {
      title: 'vp.page.wearRegularity',
      description: <FormattedMessage id={getWearRegularity(wearRegularity!)} />,
      buttonLabel: 'vp.page.wearRegularity.buttonLabel',
      icon: IconTypes.calendarRoundedBlue,
      onItemClick: handleWearRegularityCardClick,
    },
    {
      title: 'vp.page.gadgetsUseDuration',
      description:  <FormattedMessage id={getTimeOptions(gadgetsUseDuration!)} />,
      buttonLabel: 'vp.page.gadgetsUseDuration.buttonLabel',
      icon: IconTypes.phoneRoundedBlue,
      onItemClick: handleGadgetUseDurationCardClick,
    },
    {
      title: 'vp.page.timeOutsideDuration',
      description: <FormattedMessage id={getTimeOptions(timeOutsideDuration!)} />,
      buttonLabel: 'vp.page.timeOutsideDuration.buttonLabel',
      icon: IconTypes.treeRoundedBlue,
      onItemClick: handleTimeOutsideDurationCardClick,
    },
    {
      title: 'vp.page.lastVisionCheck',
      description: new Date(lastVisionCheck!).toLocaleDateString(intl.locale, {
        month: 'long',
        year: 'numeric',
      }),
      buttonLabel: 'vp.page.lastVisionCheck.buttonLabel',
      icon: IconTypes.glassesRoundedBlue,
      onItemClick: handleLastVisionCheckCardClick,
    },
  ];

  return (
    <PersonalInfoView personalInfoCards={personalInfoCards}/>
  );
};
