import { forwardRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { subYears } from 'date-fns';

import { useStyles } from './birthday-view.styles';
import { BirthdayViewForm } from './birthday-view-form';
import { IBirthdayViewProps } from './birthday-view.models';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { ReactComponent as BirthdayPieImage } from '../../../../../../assets/images/birthday-pie.svg';
import { toDate } from '../../../../../../utils/date/to-date';

export const BirthdayView = forwardRef<IFormImperativeHandleProps, IBirthdayViewProps>(({
  canUpdateDateOfBirth,
  formProps,
}, ref): JSX.Element => {
  const classes = useStyles();
  const maxDateOfBirth = useMemo(() => toDate(subYears(new Date(), 18)), [])!;

  return (
    <>
      <p className={classes.description}>
        <FormattedMessage
          id="vp.dateOfBirth.description"
        />
      </p>
      <div
        className={classes.pieImage}
        data-testid="birthday-pie-image"
      >
        <BirthdayPieImage />
      </div>
      <BirthdayViewForm
        ref={ref}
        formProps={formProps}
        maxDateOfBirth={maxDateOfBirth}
        canUpdateDateOfBirth={canUpdateDateOfBirth}
      />
    </>
  );
});
