import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './why-my-acuvue.styles';
import { List } from './list';
import { Button } from '../../../../common/button-new';
import whyAcuvue from '../../../../../assets/images/why-acuvue.png';
import { InternalLinks } from '../../../../../constants';
import { ContentWrapper } from '../../../../common/content-wrapper';

export const WhyMyAcuvue = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const benefits = [
    'whyMyAcuvue.benefit-1',
    'whyMyAcuvue.benefit-2',
    'whyMyAcuvue.benefit-3',
  ];

  return (
    <ContentWrapper>
      <div className={classes.root}>
        <div className={classes.content}>
          <h2 className={classes.title} data-testid="why-title">
            {intl.formatMessage(
              { id: 'whyMyAcuvue.title' },
              {
                sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                span: (chunks: ReactNode) => <span className={classes.newLine}>{chunks}</span>,
              },
            )}
          </h2>
          <List items={benefits} style="checked" />
          <Button
            style="primary"
            to={InternalLinks.registration}
          >
            <FormattedMessage id="common.button.register" />
          </Button>
        </div>
        <div className={classes.image} aria-hidden="true">
          <img src={whyAcuvue} alt=""/>
        </div>
      </div>
    </ContentWrapper>
  );
};
