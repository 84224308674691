import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette, fontSizes, breakpoints }: Theme) => ({
  root: {
    listStyle: 'none',
    margin: 0,
    marginTop: 40,
    paddingLeft: 0,
    lineHeight: 1.1,
    [breakpoints.up('md')]: {
      marginTop: 44,
    },
  },
  item: {
    display: 'inline',
    margin: 0,
    marginRight: 3,
    '&&': {
      color: palette.grey['500'],
      fontSize: fontSizes['3xs'],
      lineHeight: 1.1,
    },
  },
}));
