import { useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';

import { IBonusesData } from '../../../../../../graphql/bonuses/models/get-bonuses-preview.models';
import { useDocumentVisibility } from '../../../../../../hooks/use-document-visibility';
import { MyBonusesCard } from './my-bonuses-card';
import { GET_BONUSES_PREVIEW } from '../../../../../../graphql/bonuses/queries/get-bonuses-preview';
import { Preloader } from '../../../../../common/preloader';

export const MyBonuses = (): JSX.Element | null => {
  const isFirstRun = useRef(true);
  const isVisible = useDocumentVisibility();

  const {
    data, loading, error, refetch,
  } = useQuery<IBonusesData>(GET_BONUSES_PREVIEW, { fetchPolicy: 'network-only' });

  const renderBonusesCard = () => {
    if (loading) {
      return <Preloader />;
    }
    if (error) {
      return null;
    }
    return (
      <MyBonusesCard points={data!} />
    );
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (isVisible) {
      refetch();
    }
  }, [isVisible, refetch]);

  return renderBonusesCard();
};
