import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import star from '../../../../../../../../assets/images/backgrounds/star.svg';

export const useStyles = makeStyles(({
  fontWeights,
  fontFamilies,
  palette,
  fontSizes,
  breakpoints,
  lineHeights,
  borderRadiuses,
}: Theme) => ({
  root: {
    position: 'relative',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '16px 20px 24px',
    background: palette.common.white,
    [breakpoints.up('sm')]: {
      padding: 44,
      borderTopLeftRadius: borderRadiuses.lg,
      borderTopRightRadius: borderRadiuses.lg,
    },
  },
  closeIconButton: {
    position: 'absolute',
    top: 12,
    right: 12,
    zIndex: 10,
    [breakpoints.up('md')]: {
      top: 40,
      right: 32,
    },
  },
  title: {
    position: 'relative',
    marginTop: 0,
    marginBottom: 8,
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      marginBottom: 16,
      fontSize: '21px',
      lineHeight: lineHeights.xl,
    },
  },
  content: {
    marginBottom: 16,
    [breakpoints.up('sm')]: {
      display: 'flex',
      marginBottom: 20,
    },
    [breakpoints.up('xl')]: {
      flexDirection: 'column',
    },
  },
  ratingWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '0 0 8px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  rating: {
    paddingLeft: 20,
    backgroundImage: `url(${star})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '16px 16px',
    backgroundPosition: 'left center',
  },
  pointSeparator: {
    position: 'relative',
    paddingRight: 10,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: 3,
      top: '50%',
      transform: 'translateY(-50%)',
      height: 2,
      width: 2,
      borderRadius: borderRadiuses.xxl,
      background: palette.text.primary,
    },
  },
  street: {
    position: 'relative',
    paddingLeft: 28,
    margin: '0 0 6px',
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '& svg': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: 22,
      margin: '-2px 8px 0 -2px',
    },
  },
  phoneWrapper: {
    position: 'relative',
    paddingLeft: 28,
    marginBottom: 0,
    '& svg': {
      position: 'absolute',
      left: 0,
      top: 0,
      marginRight: 12,
      marginTop: 2,
    },
  },
  phone: {
    margin: 0,
    fontFamily: fontFamilies.primary,
    color: palette.primary.main,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    textDecoration: 'none',
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      outline: '1px dotted',
      outlineColor: palette.primary.main,
    },
  },
  hours: {
    position: 'relative',
    paddingLeft: 28,
    maxWidth: 171,
    margin: '0 0 6px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      maxWidth: 193,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '& svg': {
      position: 'absolute',
      left: 0,
      top: 0,
      marginRight: 12,
      marginTop: 2,
    },
  },
  submitButton: {
    marginTop: 20,
    [breakpoints.up('sm')]: {
      marginTop: 36,
    },
  },
}));
