import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  palette, borderRadiuses, fontFamilies, fontSizes, breakpoints, lineHeights,
}: Theme) => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    display: 'block',
    width: 'fit-content',
    padding: '8px 12px',
    marginBottom: 8,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: palette.grey['200'],
    borderRadius: borderRadiuses.sm,
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      padding: '12px 24px',
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:not(:last-child)': {
      marginRight: 8,
    },
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
}));
