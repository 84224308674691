import { ReactNode, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { useStyles } from './product-card.styles';
import { Card } from '../../../../../common/card';
import { IProductCardProps } from './product-card.models';
import { LensesBrandLogo } from '../../../../../common';
import { getBrandFullName } from '../../../../../../utils/lenses/get-brand-full-name';
import { SolutionsBrandLogo } from '../../../../../common/solutions-brand-logo';
import {
  LensesBrandType,
  SolutionsBrandType,
} from '../../../../../../graphql/lenses/models/get-lenses.models';

export const ProductCard = ({
  brand, packSize, productQuantity,
}: IProductCardProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  const productName = useMemo(() => {
    return getBrandFullName(brand);
  }, [brand]);

  const isSolutionsBrand = brand === SolutionsBrandType.arl;

  return (
    <Card
      className={classes.root}
      data-testid="product-card"
    >
      {isSolutionsBrand ? (
        <span className={classes.imageSolutionsWrapper}>
          <SolutionsBrandLogo
            brand={brand as SolutionsBrandType}
            className={classes.imageSolutions}
          />
        </span>
      ) : (
        <LensesBrandLogo
          brand={brand as LensesBrandType}
          className={classes.image}
        />
      )}
      <p
        className={classes.brand}
        data-testid="product-card-brand"
      >
        <span
          data-testid="product-card-number"
          className={classes.number}
        >
          <FormattedMessage
            id="order.card.products.number"
            values={{
              value: productQuantity,
            }}
          />
        </span>
        <span data-testid="product-brand-name">
          {productName && intl.formatMessage(
            { id: productName },
            { 'sup': (chunks: ReactNode) => <sup>{chunks}</sup> },
          )}
        </span>
        <span data-testid="product-card-pack-size">
          <FormattedMessage
            id={isSolutionsBrand ? 'order.card.products.unit.ml' : 'order.card.products.unit'}
            values={{
              value: packSize,
              nonBreakSpace: <>&nbsp;</>,
            }}
          />
        </span>
      </p>
    </Card>
  );
};
