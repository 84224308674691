import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IBonusModalProps } from './bonus-modal.models';
import { useStyles } from './bonus-modal.styles';
import { Button, Modal, ResponsiveWidth } from '../../../../../common';
import { InternalLinks } from '../../../../../../constants';
import { useGoBack } from '../../../../../../hooks/use-go-back';

export const BonusModal = ({ image, title, steps, ...props }: IBonusModalProps): JSX.Element => {
  const classes = useStyles();
  const { hasPreviousLocationState } = useGoBack();

  return (
    <Modal
      {...props}
      propsPaperStyles={classes.paper}
      propsContentStyles={classes.content}
      responsiveWidth={ResponsiveWidth.xs}
      content={(
        <>
          <div
            className={classes.image}
            data-testid="bonus-modal-image"
          >
            {image}
          </div>
          <h2
            className={classes.title}
            data-testid="bonus-modal-title"
          >
            {title}
          </h2>
          <ol
            className={classes.steps}
            data-testid="bonus-modal-steps"
          >
            {
              steps.map((step, index) => (
                <li
                  className={classes.step}
                  data-testid="bonus-modal-step"
                  key={index}
                >
                  {step}
                </li>
              ))
            }
          </ol>
        </>
      )}
      footer={(
        <Button
          variant="contained"
          data-testid="bonus-modal-button"
          to={InternalLinks.ecpLocator}
          state={hasPreviousLocationState}
          fullWidth
        >
          <FormattedMessage
            id="myPointsDetailed.modals.bonus.button"
          />
        </Button>
      )}
    />
  );
};
