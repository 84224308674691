import React, { forwardRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { TextField } from '../../../../../../common/form/text-field';
import { INameStepFormProps } from './name-step-form.models';

export const NameStepForm = forwardRef<IFormImperativeHandleProps, INameStepFormProps>(({
  onSubmit,
  validationSchema,
  defaultValues,
}, ref): JSX.Element => {
  const resultDefaultValues = useMemo(() => defaultValues || {
    firstName: '',
    lastName: '',
  }, [defaultValues]);
  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      defaultValues={resultDefaultValues}
    >
      <TextField
        name="firstName"
        fullWidth
        autoFocus
        label={(
          <FormattedMessage
            id="common.firstName"
          />
        )}
      />
      <TextField
        name="lastName"
        fullWidth
        label={(
          <FormattedMessage
            id="common.lastName"
          />
        )}
      />
    </Form>
  );
});
