import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ToggleButtonProps } from '@mui/material';

import { EcpStoreType } from '../../../../../rest/ecp-locator';
import {
  ShopTypeFilters,
  SpecializationTypeFilters,
} from '../../../../../graphql/ecp-locator/models/filter-params.models';

export const serviceTypeFilteringItems: ToggleButtonProps[] = [{
  children: (
    <FormattedMessage
      id="ecp.filters.modal.serviceType.items.checkPickUpLenses.label"
    />
  ),
  value: EcpStoreType.fitter,
}, {
  children: (
    <FormattedMessage
      id="ecp.filters.modal.serviceType.items.buyLenses.label"
    />
  ),
  value: EcpStoreType.retailer,
}];

export const serviceTypeFilteringItemsAohm: ToggleButtonProps[] = [{
  children: (
    <FormattedMessage
      id="ecp.filters.modal.serviceType.items.checkPickUpLenses.label"
    />
  ),
  value: EcpStoreType.fitter,
}, {
  children: (
    <FormattedMessage
      id="ecp.filters.modal.serviceType.items.buyLenses.label"
    />
  ),
  value: EcpStoreType.retailer,
}, {
  children: (
    <FormattedMessage
      id="order.create.button.label"
    />
  ),
  value: EcpStoreType.c2cActivated,
}];

export const specializationTypeFilteringItems: ToggleButtonProps[] = [{
  children: (
    <FormattedMessage
      id="ecp.filters.modal.specializationType.items.astigmatism"
    />
  ),
  value: SpecializationTypeFilters.astigmatism,
}, {
  children: (
    <FormattedMessage
      id="ecp.filters.modal.specializationType.items.presbyopia"
    />
  ),
  value: SpecializationTypeFilters.presbyopia,
}, {
  children: (
    <FormattedMessage
      id="ecp.filters.modal.specializationType.items.consultation"
    />
  ),
  value: SpecializationTypeFilters.children,
}];

export const shopTypeFilteringItems: ToggleButtonProps[] = [{
  children: (
    <FormattedMessage
      id="ecp.filters.modal.shopType.items.optics"
    />
  ),
  value: ShopTypeFilters.optics,
}, {
  children: (
    <FormattedMessage
      id="ecp.filters.modal.shopType.items.clinics"
    />
  ),
  value: ShopTypeFilters.clinics,
}, {
  children: (
    <FormattedMessage
      id="ecp.filters.modal.shopType.items.vendings"
    />
  ),
  value: ShopTypeFilters.vendings,
}];
