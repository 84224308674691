import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../common/form';
import { TextField } from '../../../../../common/form/text-field';
import { IRegisterUserFormViewProps } from './register-user-form.models';

export const RegisterUserForm = forwardRef<
IFormImperativeHandleProps, IRegisterUserFormViewProps>(({ 
  defaultValues, validationSchema, onSubmit,
}, ref): JSX.Element => (
  <Form
    ref={ref}
    onSubmit={onSubmit}
    defaultValues={defaultValues}
    validationSchema={validationSchema}
  >
    <TextField
      name="name"
      fullWidth
      label={(
        <FormattedMessage
          id="callback.modal.nameField.label"
        />
      )}
    />
    <TextField
      name="mobile"
      type="tel"
      fullWidth
      label={(
        <FormattedMessage
          id="callback.modal.phoneField.label"
        />
      )}
    />
  </Form>
));
