import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  icon: {
    textAlign: 'center',
    '& svg': {
      width: 'auto',
      height: 120,
    },
  },
  title: {
    textAlign: 'center',
    letterSpacing: 'normal',
    marginBottom: 12,
    [breakpoints.up('md')]: {
      paddingLeft: 44,
      paddingRight: 44,
    },
  },
  content: {
    [breakpoints.up('md')]: {
      paddingLeft: 44,
      paddingRight: 44,
    },
  },
}));
