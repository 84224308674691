import React, { forwardRef, useImperativeHandle } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useIntl } from 'react-intl';

import { IStepComponentProps, IStepperImperativeHandleProps } from '../../../../../common/stepper';
import { IStepsState, ExternalState } from '../../appointment-registration-modal.models';
import { AppointmentCheckStep as AppointmentCheckStepView } from './appointment-check-step';
import { setStoreDetail } from '../../../../../../graphql/ecp-locator/ecp-locator.cache';
import { useActionsInProgress } from '../../../../../../graphql/preloader/actions/actions-in-progress';
import { CREATE_APPOINTMENT } from '../../../../../../graphql/appointments/mutations/create-appointment';
import { convertDateToString } from '../../../../../../utils/date/convert-date-to-string';
import { AppointmentStatusType } from '../../../../../../graphql/appointments/models/get-appointments.models';
import { promiseErrorCallbacks } from '../../../../../../utils/promise/set-promise-error-callbacks';
import { IEcpStore } from '../../../../../../rest/ecp-locator';

export const AppointmentCheckStep = forwardRef<
IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  onGoBack,
  stepsState,
  externalState,
}, ref): JSX.Element => {
  const intl = useIntl();
  const { appointmentDate } = stepsState as IStepsState;
  const storeInfo = useReactiveVar(setStoreDetail);
  const [createAppointment] = useMutation(CREATE_APPOINTMENT);
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const { updateAppointments } = externalState as ExternalState;

  const handleRequestAppointment = async () => {
    try {
      addActionInProgress();

      await createAppointment({
        variables: {
          date: convertDateToString(appointmentDate!.date!),
          storeCode: (storeInfo as IEcpStore)?.code,
          status: AppointmentStatusType.unconfirmed,
          requestedTime: 'MORNING',
        },
      });

      await updateAppointments();

      onGoNextSuccess();
    } catch {
      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    }
    removeActionInProgress();
  };

  const pickedDate = appointmentDate?.date.toLocaleDateString(intl.locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })!;

  useImperativeHandle(ref, () => ({
    goNext() {
      handleRequestAppointment();
    },
    goBack() { },
  }));

  return (
    <AppointmentCheckStepView
      onGoToPicker={onGoBack}
      pickedDate={pickedDate}
      storeInfo={storeInfo! as IEcpStore}
    />
  );
});
