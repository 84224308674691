import { forwardRef } from 'react';

import { IFormImperativeHandleProps } from '../../../../../common/form';
import { Icon, IconTypes } from '../../../../../common/icon';
import { TimeOutsideDurationForm } from './time-outside-duration-form';
import { ITimeOutsideDurationViewProps } from './time-outside-duration-view.models';
import { useStyles } from './time-outside-duration-view.styles';

export const TimeOutsideDurationView = forwardRef<
IFormImperativeHandleProps, ITimeOutsideDurationViewProps>(({
  formProps,
}, ref): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.icon}>
        <Icon type={IconTypes.timeOutside} />
      </div>
      <TimeOutsideDurationForm
        ref={ref}
        formProps={formProps}
      />
    </>
  );
});
