export const statusItems = [{
  id: 'order.status.new',
}, {
  id: 'order.status.contactPatient',
}, {
  id: 'order.status.processing',
}, {
  id: 'order.status.confirmed',
}, {
  id: 'order.status.fulfilled',
}];
