import { IShopsListProps } from './shops-list.models';
import { useStyles } from './shops-list.styles';

export const ShopsList = ({ shops }: IShopsListProps): JSX.Element => {
  const classes = useStyles();

  return (
    <ul
      className={classes.shopList}
      data-testid="internet-shops-list"
    >
      {shops.map(({ name }, index) => (
        <li
          key={index}
          data-testid="internet-shop"
          className={classes.shopItem}
        >
          {name}
        </li>
      ))}
    </ul>
  );
};
