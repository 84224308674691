import { gql } from '@apollo/client';

export const GET_VISION_PROFILE = gql`
  query GetVisionProfileQuery {
    visionProfile {
      dateOfBirth
      gadgetsUseDuration
      lastVisionCheck
      meta {
        canUpdateDateOfBirth
      }
      timeOutsideDuration
      wearRegularity
      whatDoYouLikeCustom
      whatDoYouLikePredefined
    }
  }
`;
