import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, palette }: Theme) => ({
  cards: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    padding: 0,
    margin: 0,
    [breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 50%)',
      columnGap: 0,
      rowGap: '2rem',
    },
    [breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(3, 33.3%)',
    },
  },
  card: {
    [breakpoints.between('md', 'xl')]: {
      '&&:nth-child(5)': {
        paddingBottom: 0,
        borderBottom: 0,
      },
      '&:nth-child(odd)': {
        paddingRight: '1.5rem',
      },
    },
    [breakpoints.up('xl')]: {
      paddingRight: '1.5rem',
      '&&:nth-child(5), &&:nth-child(4)': {
        paddingBottom: 0,
        borderBottom: 0,
      },
      '&:nth-child(3n + 3)': {
        paddingRight: 0,
      },
    },
    '&:not(:last-child)': {
      paddingBottom: '2rem',
      borderBottom: `1px solid ${palette.grey['400']}`,
    },
  },
}));
