import { gql } from '@apollo/client';

export const GET_APP_LEVEL_MODALS_DATA = gql`
  query GetApplevelModalsDataQuery {
    fittingByAuthId {
      id
      isRated
      isAutoFitted
      consumerId
      createdDateTime
      store {
        displayName
      }
      fitType
    }
    notificationByAuthId {
      id
      notificationType
      activeOn
      sourceObjectIds
      data {
        lockedUntil
      }
    }
    pointsTransactionByAuthIdAndType(
      transactionType: [BIRTHDAY],
      after: "0",
      sort: [{
        sort: "DESC",
        name:"createdDateTime"
      }],
      first: 10000
    ) {
      edges {
        node {
          points
          transactionType
        }
      }
    }
  }
`;
