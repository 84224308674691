import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { InternalLinks } from '../constants';

export const useGoBack = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = useCallback((isState = true) => {
    if (isState) {
      navigate(-1);
    } else {
      navigate(InternalLinks.home);
    }
  }, [location.pathname, history.length]);

  return useMemo(() => ({
    goBack,
    hasPreviousLocationState: {
      hasPreviousLocation: true,
    },
  }), [goBack]);
};
