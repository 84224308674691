import {
  useCallback,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { useBind } from '../../../../../../hooks/use-bind';
import { useSnackbar } from '../../../../../../hooks/use-snackbar';
import { useCancellablePromise } from '../../../../../../hooks/use-cancellable-promise';
import { OneTimePinStepForm } from './one-time-pin-step-form';
import { yup } from '../../../../../../services/yup';
import { useOtpCodeValidation } from '../../../../../../hooks/use-otp-code-validation';
import {
  useActionsInProgress,
} from '../../../../../../graphql/preloader/actions/actions-in-progress';
import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import {
  IStepsState,
  IExternalStateProps,
  TOneTimePinValues,
} from '../../reset-password.models';
import { consumerApi, RecaptchaActions } from '../../../../../../rest/consumer';
import { useResetPasswordError } from '../../hooks/use-reset-password-error';

export const OneTimePinStep = forwardRef<
IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  onGoNextFail,
  onGoNext,
  isNavigationAllowed,
  stepsState,
  externalState,
}, ref): JSX.Element => {
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const { oneTimePin, username: { username } } = stepsState as IStepsState;

  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();

  const { enqueueSnackbar } = useSnackbar();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const onGoNextFailBind = useBind(onGoNextFail);
  const onGoNextSuccessBind = useBind(onGoNextSuccess);
  const isNavigationAllowedBind = useBind(isNavigationAllowed);
  const onGoNextBind = useBind(onGoNext);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);

  const otpCodeValidation = useOtpCodeValidation();

  const { time, start, reset, status: timerStatus, isCaptcha } = externalState as IExternalStateProps;

  const validationSchema = useMemo(() => yup.object({
    code: otpCodeValidation,
  }), [otpCodeValidation]);

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {},
  }));

  const defaultValues = useMemo(
    () => oneTimePin || {
      code: '',
    },
    [oneTimePin],
  );

  const handleAfterRequestCode = () => {
    enqueueSnackbarBind.current(
      <FormattedMessage id="resetPassword.successRequest" />,
      { variant: 'success' },
    );

    reset();
    start();

    removeActionInProgressBind.current();
  };

  const { handleError } = useResetPasswordError();

  const handleRequestCode = useCallback(async () => {
    if (!isNavigationAllowedBind.current) {
      return;
    }

    addActionInProgressBind.current();

    try {
      if (isCaptcha) {
        const recaptcha = (window as any).grecaptcha.enterprise;

        recaptcha.ready(() => {
          recaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
            action: RecaptchaActions.resetPassword,
          }).then(async (token: string) => {
            await makeCancellablePromiseBind.current(consumerApi.sendPinCodeRecaptcha({ username }, token));

            handleAfterRequestCode();
          }).catch((error: any) => {
            removeActionInProgressBind.current();

            handleError(error);
            onGoNextFailBind.current();
          });
        });
      } else {
        await makeCancellablePromiseBind.current(consumerApi.sendPinCode({ username }));

        handleAfterRequestCode();
      }
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        return;
      }

      handleError(error);
      onGoNextFailBind.current();
    }
  }, [
    onGoNextBind,
    onGoNextSuccessBind,
    onGoNextFailBind,
    isNavigationAllowedBind,
    enqueueSnackbarBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    addActionInProgressBind,
    removeActionInProgressBind,
    isCaptcha,
  ]);

  const handleSubmit = useCallback(async (formValues: TOneTimePinValues) => {
    if (!isNavigationAllowedBind.current) {
      return;
    }

    onGoNextBind.current();
    addActionInProgressBind.current();

    try {
      await makeCancellablePromiseBind.current(consumerApi.confirmPinCode({
        ...formValues,
        username,
      }));

      removeActionInProgressBind.current();
      onGoNextSuccessBind.current(formValues);
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        return;
      }

      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 400:
            enqueueSnackbarBind.current(
              <FormattedMessage
                id="resetPassword.oneTimePinStep.errorRequest"
              />,
              { variant: 'error' },
            );
            break;
          default:
            break;
        }
      }

      onGoNextFailBind.current();
    }
  }, [
    reset,
    start,
    enqueueSnackbarBind,
    onGoNextFailBind,
    onGoNextSuccessBind,
    onGoNextBind,
    isNavigationAllowedBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    addActionInProgressBind,
    removeActionInProgressBind,
  ]);

  return (
    <OneTimePinStepForm
      ref={formRef}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      requestCodeTime={time}
      timerStatus={timerStatus}
      onRequestCode={handleRequestCode}
    />
  );
});
