import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
  borderRadiuses,
}: Theme) => ({
  root: {
    borderRadius: borderRadiuses.md,
    padding: 8,
    marginBottom: 12,
    backgroundColor: palette.grey['100'],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textDecoration: 'none',
    '&:hover > p': {
      textDecoration: 'underline',
    },
    [breakpoints.up('md')]: {
      padding: 16,
    },
  },
  link: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.primary.light,
    margin: 0,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    minWidth: 0,
  },
  icon: {
    marginLeft: 10,
    flexShrink: 0,
  },
}));
