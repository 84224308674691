import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, palette }: Theme) => ({
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  item: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: `1px solid ${palette.grey['200']}`,
    [breakpoints.up('sm')]: {
      paddingBottom: 32,
      paddingTop: 32,
    },
    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      borderBottom: 0,
    },
  },
  button: {
    marginTop: 4,
    [breakpoints.up('sm')]: {
      marginTop: 16,
    },
  },
  modalContent: {
    marginBottom: 16,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      paddingLeft: 44,
      paddingRight: 44,
    },
  },
  modalTitle: {
    textAlign: 'center',
    [breakpoints.up('md')]: {
      paddingLeft: 44,
      paddingRight: 44,
    },
  },
  iconWrapper: {
    display: 'block',
    margin: '0 auto',
    '& svg': {
      width: 120,
      height: 120,
    },
  },
}));
