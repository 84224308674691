import { FormattedMessage } from 'react-intl';

import { GadgetsUseDuration } from '../../../../../../../graphql/profile/models/get-vision-profile.models';

export const gadgetsUseDurationOptions = [
  {
    label: (
      <FormattedMessage
        id="vp.gadgetsUseDuration.options.lessThanOneHour"
      />
    ),
    value: GadgetsUseDuration.lessThanOneHour,
  },
  {
    label: (
      <FormattedMessage
        id="vp.gadgetsUseDuration.options.fromOneToThreeHours"
      />
    ),
    value: GadgetsUseDuration.fromOneToThreeHours,
  },
  {
    label: (
      <FormattedMessage
        id="vp.gadgetsUseDuration.options.moreThanThreeHours"
      />
    ),
    value: GadgetsUseDuration.moreThanThreeHours,
  },
];
