import { restClient } from '../rest-client';
import { CmsRequestUrls } from './cms.request-urls';

export const cmsApi = {
  getTranslations() {
    return restClient.get<object>(CmsRequestUrls.translations);
  },

  getBanner(url: string) {
    return restClient.get<Blob>(url, { responseType: 'blob' });
  },
};
