import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const mobileWidth = 234;
const desktopWidth = 274;

export const useStyles = makeStyles(({
  palette,
  lineHeights,
  fontSizes,
  borderRadiuses,
  breakpoints,
}: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,
  },
  pointsValue: {
    display: 'inline-block',
    marginRight: 4,
    padding: '4px 8px',
    backgroundColor: palette.secondary.main,
    color: palette.common.white,
    borderRadius: borderRadiuses.lg,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.xs,
    zIndex: 1,
  },
  tooltip: {
    top: -16,
    marginLeft: 16,
    marginTop: 0,
    minWidth: 'unset',
    width: mobileWidth,
    [breakpoints.between('sm', 'xl')]: {
      '@media (-webkit-min-device-pixel-ratio: 2)': {
        top: -16,
      },
    },
    [breakpoints.up('sm')]: {
      width: desktopWidth,
    },
    [breakpoints.up('xl')]: {
      top: 0,
    },
  },
  tooltipContent: {
    margin: 0,
  },
}));
