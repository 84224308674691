import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import drop from '../../../../assets/images/arl/drop.png';
import dropDesktop from '../../../../assets/images/arl/drop-desktop.png';

export const useStyles = makeStyles(({
  fontWeights, fontSizes, palette, breakpoints, siteMaxWidth,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
  },
  title: {
    marginTop: 0,
    marginBottom: 30,
    color: palette.info.dark,
    fontSize: 22,
    lineHeight: 1.4,
    fontWeight: fontWeights.medium,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      marginBottom: 50,
      fontSize: 80,
    },
    '& sup': {
      fontSize: '60%',
    },
  },
  footnote: {
    margin: 0,
    color: palette.info.dark,
    fontSize: fontSizes.xxxs,
    lineHeight: 1.2,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxs,
    },
  },
  content: {
    maxWidth: siteMaxWidth,
    background: `linear-gradient(180deg, ${palette.common.white} 14.18%, ${palette.primary.main} 100%)`,
    [breakpoints.up('md')]: {
      background: `linear-gradient(180deg, ${palette.common.white} 31%, ${palette.primary.main} 100%)`,
    },
  },
  contentInner: {
    width: '100%',
    height: '100%',
    padding: '30px 10px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '35% auto',
    backgroundImage:  `url(${drop})`,
    backgroundPositionY: 40,
    backgroundPositionX: 0,
    [breakpoints.up('md')]: {
      padding: 50,
      backgroundImage: `url(${dropDesktop})`,
      backgroundPositionY: 90,
      backgroundSize: '30% auto',
    },
  },
}));
