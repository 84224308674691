import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontSizes,
  lineHeights,
  fontWeights,
  palette,
  borderRadiuses,
}: Theme) => ({
  paper: {
    [breakpoints.up('sm')]: {
      padding: '64px 32px 32px',
    },
    '& footer': {
      [breakpoints.up('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  content: {
    [breakpoints.up('sm')]: {
      padding: 0,
    },
  },
  image: {
    margin: '0 auto 32px',
    textAlign: 'center',
    lineHeight: 0,
    [breakpoints.up('sm')]: {
      marginBottom: 24,
    },
  },
  title: {
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    textAlign: 'center',
    margin: '0 0 24px 0',
    [breakpoints.up('sm')]: {
      marginBottom: 20,
    },
  },
  steps: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    counterReset: 'list',
  },
  step: {
    position: 'relative',
    paddingLeft: 44,
    marginBottom: 20,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      paddingLeft: 56,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:last-of-type': {
      marginBottom: 100,
    },
    '&::before': {
      counterIncrement: 'list',
      content: 'counter(list)',
      position: 'absolute',
      top: 0,
      left: 0,
      padding: '8px 12px',
      border: `1px solid ${palette.grey['400']}`,
      borderRadius: borderRadiuses.md,
      color: palette.grey['800'],
      fontSize: fontSizes.xxs,
      lineHeight: 1.3,
      [breakpoints.up('sm')]: {
        padding: '10px 16px',
      },
    },
  },
}));
