import React from 'react';
import { Accordion as MuiAccordion } from '@mui/material';
import { AccordionDetails } from '@mui/material';
import { AccordionSummary } from '@mui/material';

import { IAccordion } from './accordion.models';
import { useStyles } from './accordion.styles';
import { Icon, IconTypes } from '../icon';
import { Divider } from '../divider';

export const Accordion = ({ items }: IAccordion): JSX.Element => {
  const classes = useStyles();

  const addClassesIf = (
    newClass: string | undefined, currentClass: string,
  ): string => (newClass ? `${newClass} ${currentClass}` : currentClass);

  return (
    <>
      {items.map(({
        title,
        content,
        icon,
        newClasses,
      }, i) => (
        <MuiAccordion
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          classes={{
            root: addClassesIf(newClasses?.accordionRoot, classes.accordionRoot),
            expanded: classes.accordionExpanded,
          }}
          data-testid="accordion-item-group"
        >
          <AccordionSummary
            expandIcon={<Icon type={IconTypes.arrowDown} />}
            classes={{
              root: addClassesIf(newClasses?.summaryRoot, classes.summaryRoot),
              content: classes.summaryContent,
              focusVisible: classes.summaryFocused,
              expanded: classes.summaryExpanded,
              expandIconWrapper: addClassesIf(newClasses?.expandIcon, classes.expandIcon),
            }}
          >
            {icon && <span className={classes.summaryIcon}>{icon}</span>}
            <h2 className={classes.title}>
              {title}
            </h2>
          </AccordionSummary>
          <AccordionDetails
            classes={{ root: classes.detailsRoot }}
          >
            <Divider className={classes.divider} />
            {content}
          </AccordionDetails>
        </MuiAccordion>
      ))}
    </>
  );
};
