import { ReactNode } from 'react';

import { LensesBrandType, ProductType, SolutionsBrandType } from '../../lenses/models/get-lenses.models';

export interface IBonusesData {
  voucherByAuthId: IBonus[];
  pointByAuthId: IBalance;
  consumerLensesByAuthID: IFittings[];
  purchasesByAuthId: IPurchases[];
}

export interface IVouchersData {
  voucherByAuthId: IBonus[];
}

export interface IBonus {
  id: string;
  value?: number;
  packSize?: number | null;
  type: BonusType;
  brands?: IBonusBrand[];
  name: string;
  description: string | ReactNode;
  endDate: string | ReactNode;
  startDate?: string | null;
  externalLink: string | null;
  voucherType: CustomVoucherType | null;
}

export interface IOrderVouchersData {
  voucherByAuthId: IOrderBonus[];
  pointByAuthId: IBalance;
}

export interface IOrderBonus {
  name: string;
  title: string;
  description: string | null;
  status: BonusStatus | null,
  value: number;
  type: BonusType;
}

export interface IBonusBrand {
  product: LensesBrandType | SolutionsBrandType;
  productType: ProductType;
  fullName: string;
  labels: NoveltyLabelType[];
}

export type NoveltyLabelType = {
  name: string,
  labelStartDate: string,
  labelEndDate: string,
};

export interface IBalance {
  id: string;
  eligibleBalance: number;
}

export interface IFittings {
  id: string;
  createdManual: boolean;
}

export interface IPurchases {
  id: string;
}

export enum BonusType {
  freepack = 'freepack',
  discount = 'discount',
  custom = 'CUSTOM',
  loyalty = 'LOYALTY',
}

export enum CustomVoucherType {
  discount = 'DISCOUNT',
  cashback = 'CASHBACK',
}

export enum BonusStatus {
  active = 'ACTIVE',
}
