import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import headerImage from '../../../../../../../../../../assets/images/backgrounds/compliance-period.png';
import headerImageTablet from '../../../../../../../../../../assets/images/backgrounds/compliance-period-tablet.png';

export const useStyles = makeStyles(({
  breakpoints,
  palette,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
}: Theme) => ({
  propsPaperStyles: {
    '&&': {
      paddingTop: '50vw',
      position: 'relative',
      background: `url(${headerImage}), ${palette.common.white}`,
      backgroundSize: 'calc(100% + 1px)',
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: -1,
      [breakpoints.up('sm')]: {
        paddingTop: '40vw',
        background: `url(${headerImageTablet}), ${palette.common.white}`,
        backgroundSize: 'calc(100% + 3px)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '-1px -2px',
      },
      [breakpoints.up('md')]: {
        paddingTop: 310,
      },
      [breakpoints.between('xs', 'lg')]: {
        '@media (orientation: landscape)': {
          background: palette.common.white,
          paddingTop: 50,
        },
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.between('xs', 'lg')]: {
      '@media (orientation: landscape)': {
        display: 'none',
      },
    },
  },
  headerImageRound: {
    position: 'absolute',
    top: 12,
    left: '29%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    border: `1px solid ${palette.common.white}`,
    width: '40vw',
    height: '40vw',
    borderRadius: '50%',
    [breakpoints.up('sm')]: {
      top: 10,
      left: '35%',
      width: '32vw',
      height: '32vw',
    },
    [breakpoints.up('md')]: {
      top: 21,
      left: 'calc(50% - 102px)',
      width: 240,
      height: 240,
    },
  },
  headerImageText: {
    display: 'block',
    marginBlockEnd: '0.83em',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: palette.common.white,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.xs,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
  },
  title: {
    margin: '0 0 12px',
    color: palette.text.secondary,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    [breakpoints.up('sm')]: {
      margin: '0 0 16px',
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
  },
  description: {
    margin: '0 0 16px',
    color: palette.text.secondary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      margin: '0 0 24px',
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  image: {
    display: 'block',
    width: 250,
    margin: '0 auto 32px',
    [breakpoints.up('sm')]: {
      width: 343,
    },
  },
  pointsScale: {
    display: 'block',
    margin: '0 auto 32px',
    width: '100%',
    maxWidth: 310,
    [breakpoints.up('sm')]: {
      margin: '0 auto 65px',
      maxWidth: '100%',
    },
  },
  strongText: {
    fontWeight: fontWeights.medium,
  },
  titleStyles: {
    margin: 0,
  },
}));
