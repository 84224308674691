import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { Box } from '../../../common/box';
import { IResetPasswordProps } from './reset-password.models';
import { Stepper } from '../../../common/stepper';
import { resetPasswordSteps } from './reset-password.steps';

export const ResetPassword = ({ onDone, externalState }: IResetPasswordProps): JSX.Element => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <script src={process.env.REACT_APP_RECAPTCHA_API_URL} />
        <title>
          {intl.formatMessage({ id: 'resetPasswordPage.title' })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'resetPasswordPage.description' })}
        />
      </Helmet>
      <Box>
        <Stepper
          disableStepIndicators
          steps={resetPasswordSteps}
          onDone={onDone}
          externalState={externalState}
        />
      </Box>
    </>
  );
};
