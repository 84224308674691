import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  footer: {
    textAlign: 'center',
  },
  actionButton: {
    marginBottom: 16,
    [breakpoints.up('sm')]: {
      marginBottom: 24,
    },
  },
}));
