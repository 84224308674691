import {
  IBrandConfiguration,
  IBrandConfigurationRange,
} from '../../../../../../../graphql/lenses/models/get-brands.models';

const generateValuesRange = (rangeConfiguration: IBrandConfigurationRange): number[] => {
  const newRange = [];

  for (let i = rangeConfiguration.min; i <= rangeConfiguration.max; i += rangeConfiguration.step) {
    newRange.push(i);
  }

  return newRange;
};

export const getParameterValues = (configuration: string | IBrandConfiguration): any[] => {
  const formattedConfiguration: IBrandConfiguration = typeof configuration === 'string'
    ? JSON.parse(configuration)
    : configuration;

  if (formattedConfiguration.values?.length) {
    return formattedConfiguration.values;
  }

  if (formattedConfiguration.range) {
    return formattedConfiguration.range.reduce((
      acc, val,
    ) => acc.concat(generateValuesRange(val)), [] as number[]);
  }

  return [];
};
