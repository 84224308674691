import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  errorWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '34.875rem',
    margin: '0 auto',
  },
  error: {
    '&&': {
      marginTop: '4rem',
      marginBottom: '-2.5rem',
    },
  },
  box: {
    '& .title': {
      display: 'block',
      width: '19rem',
    },
  },
}));
