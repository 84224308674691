import { EcpLocator } from '../ecp-locator-new';
import { Wiki } from '../wiki';
import { SignUpBanner } from '../sign-up-banner';
import { WhyMyAcuvue } from '../why-my-acuvue';
import { PrimaryBannerUnregistered } from '../primary-banner-unregistered';
import { DownloadApp } from '../../download-app';
import { useStyles } from './landing-page-not-logged-in.styles';
import { Separator } from '../../../../common';

export const LandingPageNotLoggedIn = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <SignUpBanner />
      <DownloadApp />
      <Separator />
      <WhyMyAcuvue />
      <PrimaryBannerUnregistered />
      <EcpLocator />
      <div className={classes.coloredSection}>
        <Wiki />
      </div>
    </>
  );
};
