import React, {
  useCallback, useLayoutEffect, useEffect, useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';

import { IApiSettingsProps, ApiErrors } from './api-settings.models';
import { setCallback as setGraphqlCallback } from '../../../graphql/graphql-client';
import { setCallback as setRestCallback } from '../../../rest/rest-client';
import { setPromiseErrorCallback } from '../../../utils/promise/set-promise-error-callbacks';
import { useLogout } from '../../../graphql/user/actions/logout';
import { useSnackbar } from '../../../hooks/use-snackbar';
import { GET_IS_USER_LOGGED_IN } from '../../../graphql/user/queries';
import {
  getTokenExpiredFromStorage,
  removeTokenExpiredFromStorage,
  setTokenExpiredToStorage,
} from '../../../utils/user';
import { useRefreshToken } from '../../../hooks/use-refresh-token';

export const ApiSettings = ({ children }: IApiSettingsProps): JSX.Element => {
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);
  const { logout } = useLogout();
  const { enqueueSnackbar } = useSnackbar();
  const tokenExpired = useMemo(() => getTokenExpiredFromStorage(), []);
  const { refreshToken } = useRefreshToken();

  const handleTokenExpired = useCallback(async () => {
    if (!isLoggedIn) {
      return;
    }

    try {
      const newToken = await refreshToken();

      return newToken;
    } catch {
      setTokenExpiredToStorage();
      logout();
    }
  }, [logout, isLoggedIn, refreshToken]);

  const handleUnknownErrorReceived = useCallback(() => {
    enqueueSnackbar(
      <FormattedMessage id="common.errors.unknownError" />,
      { variant: 'error', key: 'unknownError', preventDuplicate: true },
    );
  }, [enqueueSnackbar]);

  useLayoutEffect(() => {
    setGraphqlCallback(ApiErrors.onTokenExpired, handleTokenExpired);
    setRestCallback(ApiErrors.onTokenExpired, handleTokenExpired);
    setGraphqlCallback(ApiErrors.onUnknownErrorReceived, handleUnknownErrorReceived);
    setRestCallback(ApiErrors.onUnknownErrorReceived, handleUnknownErrorReceived);
    setPromiseErrorCallback(ApiErrors.anyError, handleUnknownErrorReceived);
  }, [handleUnknownErrorReceived, handleTokenExpired]);

  useEffect(() => {
    if (tokenExpired) {
      enqueueSnackbar(
        <FormattedMessage id="login.errors.tokenExpired" />,
        { variant: 'error', key: 'tokenExpiredError', preventDuplicate: true },
      );
      removeTokenExpiredFromStorage();
    }
  }, [tokenExpired, enqueueSnackbar]);

  return children as JSX.Element;
};
