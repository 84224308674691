import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  image: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    [breakpoints.up('sm')]: {
      margin: '0 auto',
    },
  },
  lensesImage: {
    '& img': {
      height: 64,
      [breakpoints.up('sm')]: {
        height: 80,
      },
    },
  },
  solutionsImage: {
    '& img': {
      height: 70,
      [breakpoints.up('sm')]: {
        height: 120,
      },
    },
  },
}));
