import {
  WearRegularityOptions,
} from '../../../../../../graphql/profile/models/get-vision-profile.models';

export const getWearRegularity = (wearRegularityOption: string) => {
  for (const key in WearRegularityOptions) {
    if (wearRegularityOption === WearRegularityOptions[key as keyof typeof WearRegularityOptions]) {
      return `vp.wearRegularity.options.${key}`;
    }
  }
};
