import { gql } from '@apollo/client';

export const GET_VOUCHERS = gql`
  query getVouchers {
    voucherByAuthId {
      title
      description
      status
      value
      type
      name
    }
    pointByAuthId {
      id
      eligibleBalance
    }
  }
`;
