import { ReactNode, forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TextField } from '../../../../../common/form/text-field';
import { Form, IFormImperativeHandleProps } from '../../../../../common/form';
import { IUnregisteredUserFormViewProps } from './unregistered-user-form.models';
import { Checkbox } from '../../../../../common/form/checkbox';
import { ExternalLinks, InternalLinks } from '../../../../../../constants';

export const UnregisteredUserForm = forwardRef<
IFormImperativeHandleProps, IUnregisteredUserFormViewProps>(({
  onSubmit, validationSchema,
}, ref): JSX.Element => {
  const intl = useIntl();

  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      defaultValues={{
        mobile: '',
        name: '',
        authoriseJnj: false,
      }}
    >
      <TextField
        name="name"
        fullWidth
        label={(
          <FormattedMessage
            id="callback.modal.nameField.label"
          />
        )}
      />
      <TextField
        name="mobile"
        type="tel"
        fullWidth
        label={(
          <FormattedMessage
            id="callback.modal.phoneField.label"
          />
        )}
      />
      <Checkbox
        name="authoriseJnj"
        label={intl.formatMessage(
          { id: 'callback.modal.agreements.authoriseJnj' },
          {
            personalDataPolicyLink: (chunks: ReactNode) => (
              <a href={ExternalLinks.personalDataPolicyLink} target="_blank">{chunks}</a>
            ),
            privacyPolicyLink: (chunks: ReactNode) => (
              <a href={ExternalLinks.privacyPolicyLink} target="_blank">{chunks}</a>
            ),
            cookiePolicyLink: (chunks: ReactNode) => (
              <a href={InternalLinks.cookiePage} target="_blank">{chunks}</a>
            ),
          },
        )}
      />
    </Form>
  );
});

