import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { useGoBack } from '../../../../../hooks/use-go-back';
import { ContentWrapper } from '../../../../common/content-wrapper';
import { useStyles } from './header.styles';
import { Logo } from '../../../../common/logo';
import { Button } from '../../../../common/button';
import { Icon, IconTypes } from '../../../../common/icon';
import { UserMenu } from './user-menu';
import { IHeaderProps } from './header.models';
import { BarcodeAction } from '../../barcode-action';
import { InternalLinks } from '../../../../../constants';
import { NavLink } from './nav-link/nav-link';

export const Header = ({ isLoggedIn }: IHeaderProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { hasPreviousLocationState } = useGoBack();


  return (
    <header
      className={classes.root}
      data-testid="header"
    >
      {!isLoggedIn && (
        <div className={classes.container}>
          <Link to="/" className={classes.logoWrapper}>
            <Logo size="sm" />
          </Link>
          <NavLink
            testId="header-about-program-link"
            href={InternalLinks.programPage}
            startIcon={<Icon type={IconTypes.infoBlue} className={classes.infoIcon} />}
            hideText={!isTablet}
          >
            {intl.formatMessage({ id: 'header.about' })}
          </NavLink>
          <NavLink
            rootClasses={classes.mapLink}
            textClasses={classes.mapText}
            testId="header-ecp-link"
            href={InternalLinks.ecpLocator}
            startIcon={<Icon className={classes.mapIcon} type={IconTypes.location} />}
            hideText={!isTablet}
          >
            {intl.formatMessage({ id: 'header.menuItem.findOptics.notLoggedIn' })}
          </NavLink>
          <div className={classes.userLink}>
            <NavLink
              testId="header-login-link"
              href={InternalLinks.login}
              startIcon={<Icon className={classes.userIcon} type={IconTypes.userBlack} />}
              hideText={!isDesktop}
              activePath={InternalLinks.registration}
            >
              {intl.formatMessage({ id: 'header.login' })}
            </NavLink>
          </div>
        </div>
      )}
      {isLoggedIn && (
        <ContentWrapper>
          <div className={classes.containerLoggedIn}>
            <div className={classes.primaryNavContainer}>
              <Link to="/" className={classes.logoWrapper}>
                <Logo size="md" />
              </Link>
              <Button
                color="info"
                startIcon={<Icon type={IconTypes.help} />}
                data-testid="header-help-link"
                hideText={!isTablet}
                labelClassName="normal"
                underline
                to={{
                  hash: '#help',
                }}
              >
                <FormattedMessage id="header.help" />
              </Button>
              <Button
                color="info"
                to={InternalLinks.programPage}
                state={hasPreviousLocationState}
                data-testid="header-about-program-link"
                startIcon={<Icon type={IconTypes.aboutProgram} />}
                hideText={!isTablet}
                labelClassName="normal"
                underline
              >
                <FormattedMessage id="header.about" />
              </Button>
            </div>
            <div className={classes.secondaryNavContainer}>
              <BarcodeAction isLabelHidden={!isTablet} />
              {isDesktop && (
                <Button
                  color="info"
                  data-testid="header-ecp-link"
                  to={InternalLinks.ecpLocator}
                  state={hasPreviousLocationState}
                  startIcon={<Icon type={IconTypes.map} />}
                  labelClassName="normal"
                  underline
                >
                  {intl.formatMessage(
                    { id: 'header.menuItem.findOptics' },
                    { sup: (chunks: ReactNode) => <sup className={classes.sup}>{chunks}</sup> },
                  )}
                </Button>
              )}
              <UserMenu />
            </div>
          </div>
        </ContentWrapper>
      )}
    </header>
  );
};
