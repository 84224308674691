export const getIsAppUpdateScheduledFromStorage = (): boolean | null => {
  const isAppUpdateScheduled = localStorage.getItem('isAppUpdateScheduled');

  if (isAppUpdateScheduled === 'true') {
    return true;
  }

  if (isAppUpdateScheduled === 'false') {
    return false;
  }

  return null;
};
