import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  fontSizes, fontWeights, palette, breakpoints,
}: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    width: '100%',
    height: 80,
    margin: 0,
    padding: '16px 12px',
    border: `2px solid ${palette.common.white}`,
    borderRadius: 16,
    background: `linear-gradient(180deg, ${palette.common.white} 0, #b6eafe 100%)`,
    [breakpoints.up('md')]: {
      minHeight: 130,
      height: 'fit-content',
      gap: 28,
      padding: '24px 80px 24px 16px',
      borderRadius: 30,
    },
  },
  newLine: {
    display: 'block',
  },
  iconWrapper: {
    display: 'block',
    width: 48,
    height: 48,
    [breakpoints.up('md')]: {
      width: 80,
      height: 80,
    },
    '& img': {
      width: '100%',
      objectFit: 'contain',
    },
  },
  item: {
    color: palette.secondary.main,
    fontSize: fontSizes.sm,
    lineHeight: 1.3,
    fontWeight: fontWeights.medium,
    textAlign: 'left',
    [breakpoints.up('lg')]: {
      fontSize: 30,
    },
  },
}));
