import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { EyeParamsType } from '../../../../graphql/lenses/models/get-lenses.models';

export const useFormattedValue = () => {
  const intl = useIntl();

  const getFormattedValue = useCallback((
    value: string, property: EyeParamsType, isOrder?: boolean,
  ): string => {
    let resultValue: string;

    switch (property) {
      case EyeParamsType.baseCurve:
      case EyeParamsType.power:
      case EyeParamsType.cylinder:
        if (property === EyeParamsType.power && value.slice(0, 1) !== '-' && value !== '0') {
          resultValue = `+${intl.formatNumber(Number(value), { minimumFractionDigits: 1 })}`;
        } else {
          resultValue = intl.formatNumber(Number(value), { minimumFractionDigits: 1 });
        }
        break;
      case EyeParamsType.addition:
        if (value === 'Средняя (MID)' && isOrder) {
          resultValue = 'MID';
        } else if (value === 'Слабая (LOW)' && isOrder) {
          resultValue = 'LOW';
        } else if (value === 'Высокая (HIGH)' && isOrder) {
          resultValue = 'HIGH';
        } else {
          resultValue = value;
        }
        break;
      default:
        resultValue = value;
        break;
    }

    return resultValue;
  }, [intl]);

  return { getFormattedValue };
};
