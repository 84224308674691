import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, lineHeights, borderRadiuses, palette,
}: Theme) => ({
  icon: {
    display: 'block',
    width: 140,
    height: 140,
    margin: '0 auto 16px',
  },
  list: {
    listStyle: 'none',
    padding: 0,
    marginBottom: 28,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      marginBottom: 20,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  itemIcon: {
    width: 40,
    height: 40,
    marginRight: 8,
    borderRadius: borderRadiuses.xxl,
    background: palette.primary.main,
    [breakpoints.up('sm')]: {
      width: 48,
      height: 48,
      marginRight: 16,
    },
  },
  itemText: {
    width: 'calc(100% - 48px)',
    [breakpoints.up('sm')]: {
      width: 'calc(100% - 54px)',
    },
  },
  title: {
    [breakpoints.up('sm')]: {
      paddingRight: 44,
      paddingLeft: 44,
    },
    '& h2': {
      textAlign: 'center',
    },
  },
}));
