import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  fontSizes, fontWeights, borderRadiuses, palette, lineHeights,
}: Theme) => ({
  iconWrapper: {
    '& img': {
      display: 'block',
      width: 140,
    },
  },
  actionLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
    padding: '8px 40px',
    borderRadius: borderRadiuses.xl,
    color: palette.common.white,
    background: palette.primary.main,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
    textDecoration: 'none',
  },
  actionButton: {
    marginTop: 12,
    color: palette.primary.dark,
    fontWeight: fontWeights.regular,
  },
  title: {
    margin: '8px 0',
    fontSize: fontSizes.md,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    textAlign: 'center',
  },
  description: {
    margin: 0,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
    textAlign: 'center',
  },
  link: {
    color: palette.primary.dark,
  },
  index: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    marginTop: 10,
    borderRadius: borderRadiuses.xxl,
    background: palette.primary.main,
    color: palette.common.white,
    fontSize: fontSizes.lg,
  },
  questionWrapper: {
    marginLeft: 4,
    lineHeight: 0,
  },
}));