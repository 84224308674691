import {
  TimeOutsideDuration,
} from '../../../../../../graphql/profile/models/get-vision-profile.models';

export const getTimeOptions = (timeOption: string) => {
  for (const key in TimeOutsideDuration) {
    if (timeOption === TimeOutsideDuration[key as keyof typeof TimeOutsideDuration]) {
      return `vp.timeOutsideDuration.options.${key}`;
    }
  }
};
