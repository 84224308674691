import {
  BrandParamsType,
  IBrandParams,
  BrandConfigurationConditionType,
  IBrandConfigurationCondition,
  IBrandConfigurationRange,
  IBrandConfiguration,
  BrandConfigurationConditionAction,
  IBrandConfigurationConditionOnSelect,
} from '../../../../../../../graphql/lenses/models/get-brands.models';
import { IEyeParamsOption } from '../../../../../../../graphql/lenses/models/get-lenses.models';
import { getFormInputValue, getSelectedValue } from './get-selected-value';
import { IFormSubmitValues } from '../../../../../../common/form';

const isValueInRange = (newVal: number, paramConfig: IBrandConfigurationRange): boolean => {
  const { min, max } = paramConfig;
  return newVal >= min && newVal <= max;
};

const getParameterCondition = (
  value: string, parameter: IBrandParams,
): IBrandConfigurationCondition | undefined => {
  let condition: IBrandConfigurationCondition | undefined;
  const configuration: IBrandConfiguration = typeof parameter.configuration === 'string'
    ? JSON.parse(parameter.configuration) : parameter.configuration;
  const conditionRange = configuration.range?.find(range => isValueInRange(Number(value), range));

  switch (parameter.type) {
    case BrandParamsType.range:
      if (conditionRange) {
        condition = conditionRange.condition;
      }
      break;
    case BrandParamsType.exact:
      condition = configuration.condition;
      break;
    default:
      break;
  }

  return condition;
};

const checkExact = (newValue: any, paramConfig: IBrandConfiguration): boolean => {
  const { values } = paramConfig;

  return values?.length ? values.some(value => value === Number(newValue)) : false;
};

const getAdditionalPayload = (
  param: IBrandParams,
  actionPayload: IBrandParams[],
  parameters: IEyeParamsOption[],
  formParameters?: IFormSubmitValues,
) => {
  let payload = actionPayload;
  const additionalPayload = (param.configuration as unknown as IBrandParams)
    .condition?.onSelect as IBrandConfigurationConditionOnSelect[];

  if (additionalPayload) {
    const additionalParamValue = formParameters
      ? getFormInputValue(param.name, formParameters)
      : getSelectedValue(param.name, parameters!);
    const additionalParams = additionalPayload?.filter((additionalParam) =>
      checkExact(additionalParamValue, additionalParam));

    payload = [...actionPayload, ...additionalParams[0].replace!];
  }

  return payload;
};

export const getActionPayload = (
  value: string,
  parameter: IBrandParams,
  parameters: IEyeParamsOption[],
  formParameters?: IFormSubmitValues,
): IBrandParams[] | undefined => {
  let actionPayload: IBrandParams[] | undefined;

  const fulfilledCondition = getParameterCondition(value, parameter);

  if (fulfilledCondition) {
    if (fulfilledCondition.type === BrandConfigurationConditionType.select) {
      const meetCondition = fulfilledCondition.onSelect?.filter(options => checkExact(value, options))[0];

      actionPayload = meetCondition ? meetCondition.replace : undefined;
    } else {
      actionPayload = fulfilledCondition[fulfilledCondition.action] as IBrandParams[];

      actionPayload.forEach((param) => {
        if (param.conditional
          && (param.configuration as unknown as IBrandParams)
            .condition?.action === BrandConfigurationConditionAction.onSelect
        ) {
          actionPayload = getAdditionalPayload(param, actionPayload!, parameters, formParameters);
        }
      });
    }
  }

  return actionPayload;
};
