import {
  ConfigurationNames,
  IConfigurationParams,
} from '../../../../graphql/configuration/models/configuration.models';
import { C2CMode } from '../configuration-settings.models';

export const getC2CMode = (configuration: IConfigurationParams[]): C2CMode => {
  let result: C2CMode = C2CMode.disabled;

  const c2CMode = configuration.find((
    params,
  ) => params.name === ConfigurationNames.c2c);

  if (c2CMode) {
    result = JSON.parse(c2CMode.configuration).mode;
  }

  return result;
};
