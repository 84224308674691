import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  palette,
  fontSizes,
  fontWeights,
  fontFamilies,
  borderRadiuses,
}: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    border: 'none',
    fontFamily: fontFamilies.primary,
    lineHeight: 1.4,
    textDecoration: 'none',
    textTransform: 'unset',
    cursor: 'pointer',
    [breakpoints.up('md')]: {
      padding: '0.75rem 1.5rem',
    },
    '&:focus-visible': {
      outlineWidth: 1,
      outlineStyle: 'dashed',
      outlineOffset: 2,
    },
    '&.primary': {
      justifyContent: 'center',
      padding: '0.75rem 1.25rem',
      borderRadius: '0.75rem',
      background: palette.custom.main,
      color: palette.common.white,
      fontSize: fontSizes.sm,
      lineHeight: 1.5,
      fontWeight: fontWeights.medium,
      '&:focus-visible': {
        outlineColor: palette.primary.dark,
      },
    },
    '&.light': {
      justifyContent: 'center',
      padding: '0.75rem 1.25rem',
      borderRadius: borderRadiuses.lg,
      background: palette.common.white,
      color: palette.primary.dark,
      fontSize: fontSizes.sm,
      lineHeight: 1.5,
      fontWeight: fontWeights.medium,
      '&:focus-visible': {
        outlineColor: palette.common.white,
      },
    },
    '&.text': {
      width: 'fit-content',
      padding: 0,
      borderRadius: 0,
      background: 'transparent',
      color: palette.primary.dark,
      fontSize: fontSizes.sm,
      lineHeight: 1.25,
      [breakpoints.up('md')]: {
        width: 'fit-content',
      },
      '&:focus-visible': {
        outlineColor: palette.primary.dark,
      },
    },
    '&.dark': {
      padding: '0.75rem 1.25rem',
      background: `${palette.primary.dark}`,
      color: palette.common.white,
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.medium,
      lineHeight: 1.5,
      justifyContent: 'center',
      borderRadius: '1rem',
      '&:focus-visible': {
        outlineColor: palette.primary.main,
      },
      '&:disabled': {
        background: `${palette.grey['400']}`,
      },
    },
    '&.outlined': {
      justifyContent: 'center',
      width: 'fit-content',
      padding: '0.75rem 1.25rem',
      border: `2px solid ${palette.primary.dark}`,
      borderRadius: '1rem',
      background: palette.common.white,
      color: palette.primary.dark,
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.medium,
      lineHeight: 1.5,
      '&:focus-visible': {
        outlineColor: palette.primary.dark,
      },
    },
  },
  isUnderline: {
    textDecoration: 'underline',
  },
}));
