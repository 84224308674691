import { ReactNode } from 'react';

export interface IApiSettingsProps {
  children: ReactNode;
}

export enum ApiErrors {
  anyError = 'anyError',
  onTokenExpired = 'onTokenExpired',
  onUnknownErrorReceived = 'onUnknownErrorReceived',
}
