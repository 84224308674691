import { useEffect } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { OrderCreatePage as OrderCreatePageView } from './order-create-page';
import { IConsumerLenses } from '../../../../../graphql/lenses/models/get-lenses.models';
import { IBrands } from '../../../../../graphql/lenses/models/get-brands.models';
import { GET_LENSES } from '../../../../../graphql/lenses/queries/get-lenses';
import { GET_BRANDS } from '../../../../../graphql/lenses/queries/get-brands';
import { InternalLinks } from '../../../../../constants';
import { useGoBack } from '../../../../../hooks/use-go-back';
import { setC2CMode } from '../../../../../graphql/configuration/configuration.cache';
import { C2CMode } from '../../../../common/configuration-settings/configuration-settings.models';

export const OrderCreatePage = (): JSX.Element => {
  const { data, loading, error } = useQuery<IConsumerLenses>(GET_LENSES);
  const { data: brandsData } = useQuery<IBrands>(GET_BRANDS);
  const navigate = useNavigate();
  const { goBack } = useGoBack();

  const c2CMode = useReactiveVar(setC2CMode);
  const handleBack = () => goBack();

  const handleOrderCreated = () => {
    navigate(InternalLinks.myOrdersPage, { state: { hasPreviousLocation: true } });
  };

  useEffect(() => {
    if (c2CMode === C2CMode.disabled) {
      handleBack();
    }
  }, []);

  return (
    <>{c2CMode !== C2CMode.disabled && (
      <OrderCreatePageView
        isError={!!error}
        isLoading={loading}
        lensesData={data}
        onDone={handleOrderCreated}
        brandsData={brandsData}
      />
    )}</>
  );
};
