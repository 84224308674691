import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, palette }: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
    cursor: 'pointer',
  },
  storeItem: {
    listStyle: 'none',
    borderBottom: '1px solid',
    borderBottomColor: palette.grey['400'],
    '&:last-child': {
      paddingBottom: 80,
      borderBottomStyle: 'none',
      [breakpoints.up('sm')]: {
        paddingBottom: 0,
        borderBottomStyle: 'solid',
      },
    },
  },
  storeContentPartial: {
    [breakpoints.up('sm')]: {
      width: '47%',
    },
    [breakpoints.up('xl')]: {
      width: 'unset',
    },
    '&:nth-of-type(2)': {
      [breakpoints.up('sm')]: {
        marginLeft: '3vh',
      },
      [breakpoints.up('xl')]: {
        marginLeft: 0,
      },
    },
  },
}));
