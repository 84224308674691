import { TFormValue } from '../../add-lenses-step/add-lenses-step-form';
import { IReplaceQuantity } from '../../../order-create-page.models';

export const updateQuantity = (products: TFormValue[], replaceValue: IReplaceQuantity): TFormValue[] => {
  const newProducts = products;
  const { id, productQuantity } = replaceValue;
  const brand = newProducts.find((product) => product.id === id);

  return newProducts.map((product) => {
    if (product.id === id) {
      return {
        ...brand!,
        productQuantity,
      };
    }

    return product;
  });
};
