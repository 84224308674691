import { LensesCard } from './lenses-card';
import { LensesListProps } from './lenses-list.models';
import { useStyles } from './lenses-list.styles';

export const LensesList = ({
  products, isEditMode, onUpdateQuantity,
}: LensesListProps): JSX.Element => {
  const classes = useStyles();

  return (
    <ul
      data-testid="lenses-list"
      className={classes.list}
    >
      {products.length ? products.map(({
        eye,
        productQuantity,
        packSize,
        brand,
        id }, index) => (
        <li
          key={isEditMode ? id : index}
          data-testid="lenses-list-item"
          className={classes.listItem}
        >
          <LensesCard
            eye={eye}
            productQuantity={productQuantity}
            brand={brand!}
            packSize={packSize}
            isEditMode={isEditMode}
            onUpdateQuantity={onUpdateQuantity}
            id={id as string}
          />
        </li>
      )) : null}
    </ul>
  );
};
