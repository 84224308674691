import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  title: {
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    margin: '0 0 12px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      marginBottom: 16,
    },
  },
  subTitle: {
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    margin: '12px 0 12px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.lg,
      lineHeight: lineHeights.xl,
      margin: '32px 0 32px',
    },
  },
}));
