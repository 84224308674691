import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    marginBottom: 12,
    [breakpoints.up('sm')]: {
      marginBottom: 20,
    },
  },
}));
