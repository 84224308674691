import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { PrimaryBanner as PrimaryBannerView } from './primary-banner';
import { GET_CURRENT_PROMOTIONS } from '../../../../../graphql/promotions/queries/get-promotions';
import { Preloader } from '../../../../common/preloader';
import { CampaignBanner } from './campaign-banner';
import { getCampaignBannerId } from './utils/get-campaign-banner-id';
import { IBonus } from '../../../../../graphql/bonuses/models/get-bonuses.models';

export const PrimaryBanner = (): JSX.Element | null => {
  const bannerData = useQuery<{ voucherByAuthId: IBonus[] }>(GET_CURRENT_PROMOTIONS);
  const { data, loading, error } = bannerData;
  const [campaignBannerId, setCampaignBannerId] = useState<string>('');

  useEffect(() => {
    const bannerId = () => {
      let id: string = '';

      if (data?.voucherByAuthId.length) {
        id = getCampaignBannerId(data.voucherByAuthId);
      }

      return id;
    };

    setCampaignBannerId(bannerId());
  }, [data]);

  const renderPrimaryBanner = useCallback(() => {
    if (loading) {
      return <Preloader />;
    }

    if (error) {
      return null;
    }

    return campaignBannerId ? (
      <CampaignBanner campaignBannerId={campaignBannerId} bannerData={data} />
    ) : <PrimaryBannerView bannerData={data} />;
  }, [loading, error, data, campaignBannerId]);

  return <>{renderPrimaryBanner()}</>;
};
