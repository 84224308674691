import { FormattedMessage, useIntl } from 'react-intl';

import {
  PIN_CODE_BLOCKED,
  INVALID_RECAPTCHA,
  CONSUMER_ALREADY_EXIST,
  RECAPTCHA_REQUEST_DENIED,
} from '../../../../../constants/errors';
import { useBind } from '../../../../../hooks/use-bind';
import { useSnackbar } from '../../../../../hooks/use-snackbar';
import { authErrors } from '../../../../../graphql/user/user.cache';

export const useRegistrationError = () => {
  const { enqueueSnackbar } = useSnackbar();
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const intl = useIntl();

  const handleError = (error: any) => {
    if (error.response) {
      const { status, data: { code } } = error.response;

      switch (status) {
        case 400:
          if (code === PIN_CODE_BLOCKED) {
            enqueueSnackbarBind.current(
              intl.formatMessage(
                { id: 'resetPassword.errors.oneInMinute' },
              ),
              { variant: 'error' },
            );
          } else if (code === CONSUMER_ALREADY_EXIST) {
            authErrors({ phoneNumberExists: true });
          } else if (code === INVALID_RECAPTCHA || code === RECAPTCHA_REQUEST_DENIED) {
            enqueueSnackbarBind.current(
              <FormattedMessage id="common.errors.unknownError" />,
              { variant: 'error' },
            );
          }
          break;
        default:
          enqueueSnackbarBind.current(
            <FormattedMessage id="common.errors.unknownError" />,
            { variant: 'error' },
          );
          break;
      }
    }
  };

  return { handleError };
};
