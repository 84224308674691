import { FormattedMessage } from 'react-intl';

import { ResponsiveWidth, Modal } from '../../../../../../../common';
import { Preloader } from '../../../../../../../common/preloader';
import { BonusesList } from './bonuses-list';
import { IBonusChangeModal } from './bonus-change-modal.models';
import { useStyles } from './bonus-change-modal.styles';
import { getAvailableBonuses } from './utils/get-available-bonuses';

export const BonusChangeModal = ({
  data, isLoading, isError, onBonusSelect, onClose, bonusId, bonusValue, bonusType, isEditOrder,
  bonusesData, ...props
}: IBonusChangeModal): JSX.Element => {
  const classes = useStyles();

  const renderContent = () => {
    if (isLoading) {
      return <Preloader />;
    }

    if (isError) {
      return null;
    }

    const balance = bonusesData?.pointByAuthId?.eligibleBalance;
    const availableBonuses = getAvailableBonuses(
      bonusesData?.voucherByAuthId, balance, bonusId, bonusValue, bonusType, isEditOrder,
    );

    if (bonusesData?.voucherByAuthId.length) {
      return (
        <Modal
          {...props}
          data-testid="bonus-change-modal"
          title={<FormattedMessage id="order.bonuses.add.modal.title" />}
          content={(
            <BonusesList
              bonuses={availableBonuses!}
              onBonusSelect={onBonusSelect}
              isBonus={!!bonusId}
            />
          )}
          responsiveWidth={ResponsiveWidth.lgWide}
          titleStyles={classes.title}
          propsContentStyles={classes.contentWrapper}
          onClose={onClose}
        />
      );
    }
  };

  return (
   <>{renderContent()}</>
  );
};
