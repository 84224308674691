import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `1px solid ${palette.grey['400']}`,
    borderRadius: 16,
    paddingTop: 24,
    [breakpoints.up('md')]: {
      paddingTop: 40,
    },
    [breakpoints.up('lg')]: {
      paddingTop: 48,
    },
  },
  lensesImage: {
    marginBottom: 20,
    [breakpoints.up('md')]: {
      marginBottom: 14,
    },
    [breakpoints.up('lg')]: {
      marginBottom: 24,
    },
  },
  description: {
    width: 207,
    margin: '0 0 24px',
    [breakpoints.up('md')]: {
      width: 395,
      margin: '0 0 42px',
    },
    [breakpoints.up('lg')]: {
      margin: '0 0 48px',
    },
    '& p': {
      margin: 0,
      textAlign: 'center',
      fontWeight: fontWeights.medium,
      fontSize: fontSizes.xs,
      lineHeight: lineHeights.sm,
      color: palette.grey['400'],
      [breakpoints.up('md')]: {
        fontSize: fontSizes.lg,
        lineHeight: lineHeights.xl,
      },
    },
  },
}));
