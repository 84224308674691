import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, palette, fontSizes, fontWeights, lineHeights,
}: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
    cursor: 'pointer',
  },
  storeItem: {
    listStyle: 'none',
    borderBottom: '1px solid',
    borderBottomColor: palette.grey['400'],
    '&:last-child': {
      paddingBottom: 80,
      borderBottomStyle: 'none',
      [breakpoints.up('sm')]: {
        paddingBottom: 0,
        borderBottomStyle: 'solid',
      },
    },
  },
  storeContent: {
    [breakpoints.up('xl')]: {
      flexDirection: 'row',
    },
  },
  storeTitle: {
    marginTop: 0,
    marginBottom: 8,
    color: palette.text.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.lg,
      lineHeight: lineHeights.lg,
    },
  },
}));
