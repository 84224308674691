import { FormattedMessage, useIntl } from 'react-intl';

import { IOrderRepeatButtonProps } from './order-repeat-button.models';
import { useStyles } from './order-repeat-button.styles';
import { Button } from '../../../../../common';
import { InternalLinks } from '../../../../../../constants';

export const OrderRepeatButton = ({
  orderNumber, onClick,
}: IOrderRepeatButtonProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Button
      className={classes.root}
      data-testid="repeat-button"
      variant="contained"
      aria-label={intl.formatMessage(
        { id: 'order.card.repeat.ariaLabel' },
        { orderNumber },
      )}
      onClick={onClick}
      to={InternalLinks.orderCreate}
    >
      <FormattedMessage
        id="order.card.repeat.label"
      />
    </Button>
  );
};
