import {
  IConsumerLensesParams,
  IEyeParamsOption,
  LensesBrandType,
} from '../../../../../../../../graphql/lenses/models/get-lenses.models';
import { IPackageParams } from '../add-lenses-step-form/add-lenses-step-form.models';
import { compareEyeParams } from './compare-eye-params';
import { getFilteredPackageParams } from './get-filtered-package-params';

export interface IFilteredLenses extends Omit<IPackageParams, 'eye'> {
  leftEye: IEyeParamsOption[];
  rightEye: IEyeParamsOption[];
}

export const getParamsByPackage = (lenses: IConsumerLensesParams[]): IPackageParams[] => {
  const lensesPackages = getFilteredPackageParams(lenses)
    .map(({ points, brandId, id, leftEye, rightEye }) => {
      if (!leftEye.length && rightEye.length) {
        return ({
          id,
          brandId,
          points,
          rightEye: rightEye,
        });
      } else if (!rightEye.length && leftEye.length) {
        return ({
          id,
          brandId,
          points,
          leftEye: leftEye,
        });
      } else if (compareEyeParams(leftEye, rightEye)) {
        return ({
          id,
          brandId,
          points,
          leftEye: leftEye,
        });
      } else {
        return ([{
          id: `${id}-leftEye`,
          brandId,
          points,
          leftEye: leftEye,
        }, {
          id: `${id}-rightEye`,
          brandId: brandId as LensesBrandType,
          points,
          rightEye: rightEye,
        }]);
      }
    });

  return lensesPackages.reduce((initialArray, lensesPackage) => {
    return Array.isArray(lensesPackage) ? [...initialArray, ...lensesPackage] : [...initialArray, lensesPackage];
  }, [] as any[]);
};
