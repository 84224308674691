import { ITokenData } from '../../../../rest/oauth/oauth.models';
import { Status } from 'use-timer/lib/types';

export interface IRegistrationProps {
  onDone: (stepsState: IStepsState) => void;
  externalState: IExternalStateProps;
}

export interface IExternalStateProps {
  time: number;
  reset: () => void;
  start: () => void;
  status: Status;
  isCaptcha: boolean;
}

export interface IStepsState {
  phone: TPhoneValues;
  confirmationCode: TConfirmationCodeValues;
  name: TNameValues;
  lensesExperience: TLensesExperienceValues;
  email: TEmailValues;
  password: TPasswordValues;
}

export type TPhoneValues = {
  formValues: TPhoneFormValues;
  token: ITokenData;
};

export type TPhoneFormValues = {
  phoneNumber: string;
};

export type TConfirmationCodeValues = {
  otpCode: string;
};

export type TNameValues = {
  firstName: string;
  lastName: string;
};

export type TLensesExperienceValues = {
  clExperience: LensesExperience;
};

export type TPasswordValues = {
  pin: string;
  authoriseJnj: boolean;
  marketing: boolean;
  tnc: boolean;
};

export type TEmailValues = {
  formValues: TEmailFormValues;
  token: ITokenData;
};

export type TEmailFormValues = {
  email: string;
};

export enum LensesExperience {
  newWear = 'NEWWEAR',
  lessThanOneYear = 'LESSTHANONEYEAR',
  moreThanOneYear = 'MORETHANONEYEAR',
  dropOut = 'DROPOUT',
}

export enum RegistrationErrorCodes {
  emailExist = 'SY.REGISTRATION_002',
}
