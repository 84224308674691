import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import campaignIcon from '../../../../../assets/images/backgrounds/campaign/campaign.png';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
}: Theme) => ({
  description: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    margin: '0 0 32px',
    color: palette.text.primary,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
      marginBottom: 48,
    },
  },
  descriptionLinebreak: {
    display: 'none',
    [breakpoints.up('sm')]: {
      display: 'unset',
    },
  },
  hint: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.grey['700'],
    margin: '0 0 4px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  barcode: {
    marginBottom: 0,
  },
  id: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.md,
    lineHeight: lineHeights.md,
    color: palette.common.black,
    margin: '0 0 24px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.lg,
      lineHeight: lineHeights.lg,
    },
  },
  dialogPaper: {
    textAlign: 'center',
  },
  dialogPaperCampaign: {
    overflow: 'visible',
    '& .MuiDialogContent-root': {
      overflow: 'visible',
      [breakpoints.up('sm')]: {
        overflowY: 'auto',
        overflowX: 'unset',
      },
      '@media (orientation: landscape)': {
        overflowY: 'auto',
        overflowX: 'unset',
      },
    },
  },
  propsContentStyles: {
    padding: '0 16px',
    [breakpoints.up('sm')]: {
      padding: '0 32px',
    },
    [breakpoints.up('md')]: {
      padding: '0 64px',
    },
  },
  campaign: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translate(50%, 80%)',
    width: 82,
    height: 118,
    backgroundImage: `url(${campaignIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    '@media (orientation: landscape)': {
      display: 'none',
    },
    [breakpoints.up('sm')]: {
      transform: 'translate(50%, 50%)',
      width: 172,
      height: 247,
    },
    [breakpoints.up('md')]: {
      '@media (orientation: landscape)': {
        display: 'block',
      },
    },
  },
  rootAbsolute: {
    position: 'absolute',
  },
}));
