import {
  IConsumerLensesParams,
  LensesBrandType,
} from '../../../../../../../../graphql/lenses/models/get-lenses.models';
import { IFilteredLenses } from './get-params-by-package';

export const getFilteredPackageParams = (lenses: IConsumerLensesParams[]): IFilteredLenses[] => {
  return lenses.map(({
    brand, brandId, id, leftEye, rightEye,
  }) => ({
    id,
    brandId: brandId as LensesBrandType,
    points: brand.points,
    leftEye: leftEye.map(({ property, value }) => ({
      property,
      value,
    })),
    rightEye: rightEye.map(({ property, value }) => ({
      property,
      value,
    })),
  }));
};
