import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  spacing,
  palette,
  breakpoints,
  fontFamilies,
  fontSizes,
  lineHeights,
}: Theme) => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    gap: spacing(2),
  },
  content: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    textAlign: 'start',
    color: palette.grey[800],
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxs,
    lineHeight: lineHeights.xs,
    gap: spacing(1.5),
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.lg,
      gap: spacing(3),
    },
  },
  bonusAmount: {
    padding: '4px 8px',
    borderRadius: 53,
    backgroundColor: palette.secondary.main,
    color: palette.common.white,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      padding: 16,
      fontSize: fontSizes.lg,
    },
  },
  description: {
    margin: 0,
  },
  button: {
    position: 'relative',
    zIndex: 1,
    height: 'fit-content',
    '&:focus': {
      outline: `1px dotted ${palette.text.secondary}`,
      background: 'transparent',
    },
  },
}));
