import React from 'react';

import { ecpLocatorApi, TInternetShopResponse } from '../../../../rest/ecp-locator';
import { OnlineShops as InternetShopsView } from './online-shops';
import { useRequest } from '../../../../hooks/use-request';
import { FittingProps } from '../../../common/with-fitting-props/with-fitting-props.models';
import { withLoggedInProps } from '../../../common/with-logged-in-props/with-logged-in-props';

export const OnlineShopsBase = (props: FittingProps): JSX.Element => {
  const { data, loading, error } = useRequest<TInternetShopResponse>(
    ecpLocatorApi.getInternetShops,
  );

  return (
    <InternetShopsView
      {...props}
      shops={data}
      isLoading={loading}
      isError={!!error}
    />
  );
};

export const OnlineShops = withLoggedInProps(OnlineShopsBase);
