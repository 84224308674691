import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    listStyle: 'none',
    padding: 0,
    marginTop: 12,
    [breakpoints.up('sm')]: {
      marginTop: 16,
    },
  },
  item: {
    marginBottom: 12,
    [breakpoints.up('sm')]: {
      marginBottom: 16,
    },
  },
}));
