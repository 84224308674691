import React, { useCallback } from 'react';
import { useFormContext, useController } from 'react-hook-form';

import { ToggleButtonGroup as ToggleButtonGroupBase, IToggleButtonGroup } from '../../toggle-button-group';
import { TFormExtendedProps, IAdditionalFormProps } from '..';

export const ToggleButtonGroup = ({
  name,
  helperText,
  onValidateDependencies,
  onCustomChange,
  ...props
}: TFormExtendedProps<IToggleButtonGroup>): JSX.Element => {
  const { control, trigger, formState: { errors, isSubmitted } } = useFormContext();
  const {
    field: { ref, onChange, ...restField },
    fieldState: { invalid },
  } = useController({
    name,
    control,
  });
  const additionalProps: IAdditionalFormProps = {};

  if (invalid) {
    additionalProps.error = true;
    additionalProps.helperText = errors[name]?.message;
  } else if (helperText) {
    additionalProps.helperText = helperText;
  }

  const handleChange = useCallback((...values: unknown[]) => {
    onChange(...values);

    if (isSubmitted && onValidateDependencies) {
      onValidateDependencies(trigger);
    }

    if (onCustomChange) {
      onCustomChange(...values);
    }
  }, [onChange, onValidateDependencies, trigger, isSubmitted]);

  return (
    <ToggleButtonGroupBase
      {...props}
      {...restField}
      {...additionalProps}
      onChange={handleChange}
    />
  );
};
