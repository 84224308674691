import { filterParams } from '../ecp-locator.cache';
import { TFiltersData } from '../models/filter-params.models';

const setFilterParamsToCache = (value: TFiltersData) => {
  filterParams(value);
};

export const useSetFilterParams = () => ({
  setFilterParamsToCache,
});
