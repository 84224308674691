import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, palette, fontWeights, fontSizes, lineHeights,
}: Theme) => ({
  title: {
    fontSize: fontSizes.lg,
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.lg,
    color: palette.text.secondary,
    margin: '0 0 16px',
    textAlign: 'center',
    [breakpoints.up('md')]: {
      marginBottom: '0 0 20px',
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 150,
    [breakpoints.up('sm')]: {
      paddingBottom: 174,
    },
  },
  pieImage: {
    textAlign: 'center',
    marginBottom: 48,
    [breakpoints.up('md')]: {
      marginBottom: 36,
    },
    '& svg': {
      width: 88,
      height: 120,
      [breakpoints.up('md')]: {
        width: 105,
        height: 144,
      },
    },
  },
  partial: {
    display: 'inline',
    marginTop: 0,
    textAlign: 'center',
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      display: 'block',
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:not(:last-of-type)': {
      marginBottom: 0,
    },
  },
}));
