import { TabPanelProps } from './tab-panel.types';
import { useStyles } from './tab-panel.styles';

export const TabPanel = ({ children, value, index }: TabPanelProps) => {
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={classes.root}
    >
      {value === index && <>{children}</>}
    </div>
  );
};
