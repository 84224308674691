import { BonusesListProps } from './bonuses-list.models';
import { BonusCard } from '../bonus-card';
import { PointsCard } from '../points-card';
import { useStyles } from './bonuses-list.styles';

export const BonusesList = ({ bonuses, onBonusSelect, isBonus }: BonusesListProps): JSX.Element => {
  const classes = useStyles();

  return (
    <ul
      data-testid="bonuses-list"
      className={classes.list}
    >
      {bonuses.map(({ type, description, name, title, value }) => (
        <li
          data-testid="bonuses-list-item"
          className={classes.item}
          key={`${name}-${title}-${description}`}
        >
          <BonusCard
            type={type}
            description={description}
            id={name}
            title={title}
            onBonusSelect={onBonusSelect}
            name={name}
            value={value}
          />
        </li>
      ))}
      {isBonus && (
        <li data-testid="points-item">
          <PointsCard onBonusSelect={onBonusSelect} />
        </li>
      )}
    </ul>
  );
};
