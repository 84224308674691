import { useNavigate } from 'react-router-dom';
import { useTimer } from 'use-timer';
import { useReactiveVar } from '@apollo/client';

import { InternalLinks } from '../../../../constants';
import { ResetPassword as ResetPasswordView } from './reset-password';
import { REQUEST_CODE_TIME } from '../../../../constants/one-time-pin';
import { setIsCaptcha } from '../../../../graphql/configuration/configuration.cache';

export const ResetPassword = (): JSX.Element => {
  const navigate = useNavigate();

  const handleResetPasswordDone = () => {
    navigate(InternalLinks.login, { state: { hasPreviousLocation: true } });
  };

  const { time, reset, start, status } = useTimer({
    initialTime: REQUEST_CODE_TIME,
    timerType: 'DECREMENTAL',
    autostart: false,
    endTime: 0,
  });

  const isCaptcha = useReactiveVar(setIsCaptcha);

  return (
    <ResetPasswordView
      onDone={handleResetPasswordDone}
      externalState={{ time, reset, start, status, isCaptcha }}
    />
  );
};
