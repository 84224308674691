import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from './app-benefits.styles';
import { AppBenefit } from './app-benefit';
import { appBenefits } from './app-benefits.items';
import { InternalLinks, ExternalLinks } from '../../../../../constants';
import { useMediaQuery, useTheme } from '@mui/material';

export const AppBenefits = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <h2
        data-testid="app-benefits-title"
        className={classes.title}
      >
        {intl.formatMessage(
          { id: 'arl.app.title' },
          {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
          },
        )}
      </h2>
      <ul
        data-testid="app-benefits-list"
        className={classes.list}
      >
        {appBenefits.map(({
          item,
          icon,
        }) => (
          <li
            className={classes.item}
            data-testid="app-benefits-item"
            key={`reg-item-${item}`}
          >
            <AppBenefit
              item={item}
              icon={icon}
            />
          </li>
        ))}
      </ul>
      <p
        data-testid="app-bonus"
        className={classes.bonus}
      >
        {intl.formatMessage(
          { id: 'arl.bonus' },
          {
            span: (chunks: ReactNode) => <span className={classes.emphasis}>{chunks}</span>,
          },
        )}
      </p>
      <a
        data-testid="signup-link"
        className={classes.button}
        href={isMobile ? ExternalLinks.mobileLinkRevitalLens : InternalLinks.registration}
        target='_blank'
      >
        <span>
          {intl.formatMessage(
            { id: 'arl.cta' },
            {
              sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            },
          )}
        </span>
      </a>
    </div>
  );
};
