import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from './reg-step.styles';
import { IRegStepProps } from './reg-step.models';
import { Button } from '../../../../../common';
import { ReactComponent as QuestionMark } from '../../../../../../assets/images/reg-guide/question-mark.svg';

export const RegStep = ({
  icon, title, description, actionHref, handleAction, index, actionContent,
}: IRegStepProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  const handleActionClick = () => {
    handleAction!();
  };

  return (
    <>
      <span
        aria-hidden="true"
        className={classes.iconWrapper}
        data-testid="step-icon-wrapper"
      >
        {icon}
      </span>
      <span
        data-testid="step-icon-index"
        className={classes.index}
      >
        {index}
      </span>
      <h2
        data-testid="step-title"
        className={classes.title}
      >
        {intl.formatMessage(
          { id: title },
          {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
          },
        )}
      </h2>
      <p
        data-testid="step-description"
        className={classes.description}
      >
        {intl.formatMessage(
          { id: description },
          {
            a: (chunks: ReactNode) => <a className={classes.link} href="mailto:acuvue@jnj.ru">{chunks}</a>,
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
          },
        )}
      </p>
      {actionHref && (
        <a
          data-testid="action-link"
          href={intl.formatMessage({ id: actionHref })}
          className={classes.actionLink}
        >
          <span>
            {intl.formatMessage(
              { id: actionContent },
              {
                sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
              },
            )}
          </span>
        </a>
      )}
      {handleAction && (
        <Button
          data-testid="action-button"
          color="secondary"
          variant="text"
          className={classes.actionButton}
          onClick={handleActionClick}
        >
          <span>
            {intl.formatMessage(
              { id: actionContent },
              {
                sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
              },
            )}
          </span>
          <span
            aria-hidden="true"
            className={classes.questionWrapper}
            data-testid="question-mark-wrapper"
          >
            <QuestionMark />
          </span>
        </Button>
      )}
    </>
  );
};
