import { FormattedMessage } from 'react-intl';

import { Icon, IconTypes, ResponsiveWidth } from '../../../../../common';
import { IUnsavedConfirmProps } from '../../../../../common/form';

export const getLeaveModalProps = (
  iconStyles?: string,
  contentStyles?: string,
  titleStyles?: string,
): IUnsavedConfirmProps => {
  return {
    topIcon: (
      <span
        data-testid="icon-wrapper"
        aria-hidden='true'
        className={iconStyles ? iconStyles : undefined}
      >
        <Icon type={IconTypes.cartWithCross} />
      </span>
    ),
    title: <FormattedMessage id="order.leave.modal.title" />,
    content: <FormattedMessage id="order.leave.modal.description" />,
    confirmButtonLabel: <FormattedMessage id="order.leave.modal.confirm.button" />,
    rejectButtonLabel: <FormattedMessage id="order.leave.modal.reject.button" />,
    hasChangedButtonsPriority: true,
    responsiveWidth: ResponsiveWidth.mdWide,
    contentStyles,
    titleStyles,
  };
};
