import video from '../../../../../assets/images/reg-guide/edu-video.svg';
import location from '../../../../../assets/images/reg-guide/location.svg';
import tips from '../../../../../assets/images/reg-guide/tips.svg';
import discounts from '../../../../../assets/images/reg-guide/discounts.svg';
import { IBenefitProps } from './benefit';

export const benefits: IBenefitProps[] = [{
  item: 'regGuide.benefits.list.benefit1',
  icon: <img alt="" src={video} />,
}, {
  item: 'regGuide.benefits.list.benefit2',
  icon: <img alt="" src={tips} />,
}, {
  item: 'regGuide.benefits.list.benefit3',
  icon: <img alt="" src={location} />,
}, {
  item: 'regGuide.benefits.list.benefit4',
  icon: <img alt="" src={discounts} />,
}];
