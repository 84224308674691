import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, lineHeights, fontWeights,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modal: {
    width: 482,
    maxWidth: '90vw',
    [breakpoints.down('sm')]: {
      height: '90vh',
    },
  },
  icon: {
    lineHeight: 0,
    '& svg': {
      width: 90,
      height: 90,
      [breakpoints.up('md')]: {
        width: 120,
        height: 120,
      },
    },
  },
  title: {
    margin: '20px 0 16px',
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
  },
  description: {
    marginBottom: 150,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      marginBottom: 48,
    },
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
}));
