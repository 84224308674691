import { ComponentType } from 'react';
import { useQuery } from '@apollo/client';

import { GET_IS_USER_LOGGED_IN } from '../../../../../graphql/user/queries';

export const withAuthorization = <T extends object>(WrappedComponent: ComponentType<T>) => (
  props: T): JSX.Element | null => {
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);
  
  if (!isLoggedIn) {
    return null;
  }
  
  return <WrappedComponent {...props} />;
};