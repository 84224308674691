import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useReactiveVar } from '@apollo/client';
import { cx } from '@emotion/css';
import { useNavigate } from 'react-router-dom';

import { DISTANCE_UNIT, NameStore } from '../../../../../constants/ecp-locator';
import { useStyles } from './store-detail.styles';
import { IStoreDetailProps } from './store-detail.models';
import { useRoundDistance } from '../../../../../hooks/use-round-distance';
import { useStoreByType } from '../../../../../hooks/use-store-by-type';
import { roundRating } from '../../../../../utils/ecp-locator/round-rating';
import { Icon, IconSizes, IconTypes } from '../../../../common';
import { EcpStoreType } from '../../../../../rest/ecp-locator';
import { scrollToTop } from '../../../../../utils/scroll/scroll-to-top';
import { filterParams } from '../../../../../graphql/ecp-locator/ecp-locator.cache';
import { StoreServices } from '../../../../common/store-services';
import { Appointment } from './appointment';
import { isVendingMachine } from '../../../../../utils/ecp-locator/define-store-types';
import { OrderCreateButtonComponent } from '../../order/order-create-button';
import { C2CMode } from '../../../../common/configuration-settings/configuration-settings.models';
import { mapEcpStoreToOrderStore } from '../../../../../utils/ecp-locator/convert-store-types';
import { orderStore } from '../../../../../graphql/order/order.cache';
import { BackButton } from '../../../../common/back-button-new';
import { InternalLinks } from '../../../../../constants';

export const StoreDetail = ({
  storeData,
  onStoreClose,
  updateStores,
  isFitted,
  isSingleAohm,
  c2CMode,
}: IStoreDetailProps): JSX.Element => {
  const classes = useStyles();
  const currentFilters = useReactiveVar(filterParams);

  const {
    name,
    rating,
    type,
    distance,
    street,
    openinghours,
    phonenumber,
    speciality,
    detailedLocation,
    appointmentUrl,
    c2cActivated,
  } = storeData;

  const titleRef = useRef<HTMLButtonElement>(null);
  const isNormalStore = !isVendingMachine(type);
  const address =
    isVendingMachine(type) && detailedLocation?.length ? `${street}, ${detailedLocation}` : street;
  const isVendingMachineShow = isVendingMachine(type);

  useEffect(() => {
    titleRef.current?.focus();
    scrollToTop();

    return () => onStoreClose(storeData.code);
  }, []);

  const handleFilterOptics = () => {
    updateStores({
      filters: {
        ...currentFilters,
        services: EcpStoreType.fitter,
        isFiltersChanged: true,
      },
    });

    onStoreClose(storeData.code);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    const mappedStoreData = mapEcpStoreToOrderStore(storeData);
    orderStore(mappedStoreData);
    navigate(InternalLinks.orderCreate);
  };

  const handleBackButtonClick = () => {
    onStoreClose(storeData.code);
  };

  return (
    <div data-testid="store-detail" className={classes.root}>
      <BackButton
        onClick={handleBackButtonClick}
      />
      <h1>{name}</h1>
      <div className={cx(classes.content, { [classes.contentWithBorder]: isNormalStore })}>
        <div className={classes.contentPartial}>
          <p data-testid="store-detail-rating-bar" className={classes.ratingWrapper}>
            {rating && (
              <span
                data-testid="store-detail-rating"
                className={cx(classes.pointSeparator, classes.rating)}
              >
                {roundRating(rating)}
              </span>
            )}
            <span data-testid="store-detail-type" className={classes.pointSeparator}>
              {useStoreByType(type)}
            </span>
            {distance && (
              <span data-testid="store-detail-distance">
                {`${useRoundDistance(distance)} ${DISTANCE_UNIT}`}
              </span>
            )}
          </p>
          {isVendingMachineShow && (
            <p data-testid="points-only" className={classes.pointsOnly}>
              <Icon type={IconTypes.attention} aria-hidden="true" />
              <FormattedMessage id="ecp.pointsOnly.label" />
            </p>
          )}
          <p data-testid="store-detail-address" className={classes.street}>
            {street && <Icon type={IconTypes.mapMarkerBlack} aria-hidden="true" />}
            {address}
          </p>
          {isVendingMachineShow && (
            <p data-testid="pay-card" className={classes.payCard}>
              <Icon type={IconTypes.creditCard} aria-hidden="true" />
              <FormattedMessage id="ecp.payCard.label" />
            </p>
          )}
        </div>
        <div className={classes.contentPartial}>
          <p data-testid="store-detail-hours" className={classes.hours}>
            {openinghours && (
              <Icon type={IconTypes.clockBlack} size={IconSizes.xs} aria-hidden="true" />
            )}
            {openinghours}
          </p>
          <p className={classes.phoneWrapper}>
            {phonenumber && (
              <Icon type={IconTypes.phoneBlack} size={IconSizes.xs} aria-hidden="true" />
            )}
            <a
              data-testid="store-detail-phone"
              href={`tel:${phonenumber}`}
              className={classes.phone}
            >
              {phonenumber}
            </a>
          </p>
        </div>
      </div>
      {isNormalStore && <StoreServices type={type} speciality={speciality} />}
      {isNormalStore && (
        <div data-testid="call-to-action-block" className={classes.callToAction}>
          <Appointment
            appointmentUrl={name === NameStore.ochkaric ? appointmentUrl : undefined}
            type={type}
            handleFilterOptics={handleFilterOptics}
          />
          {c2cActivated &&
            !isSingleAohm && // Hide C2C feature from aohm-only fitted users
            c2CMode !== C2CMode.disabled && (
              <div className={classes.orderButtonWrapper} data-testid="order-button-wrapper">
                <OrderCreateButtonComponent
                  isFitted={isFitted}
                  onClick={handleClick}
                  appointmentUrl={name === NameStore.ochkaric ? appointmentUrl : undefined}
                />
              </div>
          )}
        </div>
      )}
      {isVendingMachineShow && appointmentUrl && (
        <a
          data-testid="vending-stock-link"
          href={appointmentUrl}
          className={classes.vendingLink}
          target="_blank"
        >
          <FormattedMessage id="ecp.vending.watchStock" />
        </a>
      )}
    </div>
  );
};
