import { ITokenData } from '../../rest/oauth/oauth.models';

export const getTokenFromStorage = (): ITokenData | null => {
  const tokenData = localStorage.getItem('token');

  if (tokenData) {
    return JSON.parse(tokenData);
  }

  return null;
};
