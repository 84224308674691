import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontFamilies, fontWeights, fontSizes, lineHeights, palette,
}: Theme) => ({
  root: {
    minHeight: 275,
    [breakpoints.up('md')]: {
      minHeight: 0,
    },
  },
  opticAdress: {
    marginBottom: 20,
    [breakpoints.up('md')]: {
      marginBottom: 32,
    },
  },
  fittingDate: {
    [breakpoints.up('md')]: {
      marginBottom: 100,
    },
  },
  title: {
    margin: '0 0 4px',
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
    color: palette.text.secondary,
  },
  description: {
    margin: '0 0 4px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.primary,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  dateText: {
    marginRight: 10,
  },
  helperText: {
    margin: 0,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.grey['500'],
  },
}));
