import { forwardRef, useMemo } from 'react';

import { useStyles } from './last-vision-check-view.styles';
import { LastVisionCheckForm } from './last-vision-check-form';
import { ILastVisionCheckViewProps } from './last-vision-check-view.models';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { IconTypes, Icon } from '../../../../../common/icon';
import { toDate } from '../../../../../../utils/date/to-date';

export const LastVisionCheckView = forwardRef<
IFormImperativeHandleProps, ILastVisionCheckViewProps>(({
  formProps,
  dateOfBirth,
}, ref): JSX.Element => {
  const classes = useStyles();
  const maxDateOfCheck = useMemo(() => new Date(), []);
  const minDateOfCheck = useMemo(() => {
    if (dateOfBirth) {
      return toDate(dateOfBirth) as Date;
    }
  }, [dateOfBirth]);

  return (
    <>
      <Icon
        type={IconTypes.visionCheckTable}
        className={classes.icon}
      />
      <LastVisionCheckForm
        ref={ref}
        formProps={formProps}
        minDateOfCheck={minDateOfCheck}
        maxDateOfCheck={maxDateOfCheck}
      />
    </>
  );
});
