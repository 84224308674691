import { gql } from '@apollo/client';

export const GET_BARCODE = gql`
  query GetBarcodeQuery {
    profile {
      id
      myAcuvueId,
      myAcuvueToken {
        code,
        codeExpiresAt
      }
    }
  }
`;
