import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import girlBg from '../../../../../assets/images/arl/girl-bg.png';

export const useStyles = makeStyles(({
  breakpoints,
  palette,
  fontSizes,
  fontWeights,
  borderRadiuses,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 30,
    padding: '0 12px 30px',
    borderRadius: 24,
    background: `linear-gradient(284.93deg, #a6e7ff 2.21%, ${palette.common.white} 79.8%, #d5f8ff 98.46%)`,
    [breakpoints.up('md')]: {
      marginBottom: 50,
      padding: '50px 8%',
      borderRadius: 48,
    },
  },
  title: {
    margin: 0,
    padding: '30px 0 50px 35%',
    color: palette.info.dark,
    fontSize: fontSizes.xl,
    fontWeight: fontWeights.medium,
    lineHeight: 1.3,
    textAlign: 'center',
    backgroundImage: `url(${girlBg})`,
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 20,
    [breakpoints.up('md')]: {
      padding: 0,
      marginBottom: 44,
      fontSize: 50,
      background: 'none',
    },
    '& sup': {
      fontSize: '60%',
    },
  },
  list: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
    width: '100%',
    margin: 0,
    padding: '0 0 24px',
    [breakpoints.up('md')]: {
      paddingBottom: 30,
      paddingLeft: '40%',
      gap: 25,
      backgroundImage: `url(${girlBg})`,
      backgroundSize: '35% auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left bottom',
    },
    [breakpoints.up('xl')]: {
      backgroundSize: 'auto 100%',
      backgroundPosition: 'left top',
    },
  },
  item: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  bonus: {
    marginTop: 0,
    marginBottom: 24,
    fontSize: fontSizes.lg,
    lineHeight: 1.2,
    fontStyle: 'italic',
    textAlign: 'center',
    [breakpoints.up('md')]: {
      marginBottom: 30,
      fontSize: 40,
    },
  },
  emphasis: {
    color: palette.secondary.main,
    fontWeight: fontWeights.medium,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 15,
    lineHeight: 1,
    fontWeight: fontWeights.medium,
    marginBottom: 0,
    padding: '16px 15px',
    borderRadius: borderRadiuses.xl,
    [breakpoints.up('md')]: {
      padding: '22px 30px',
      fontSize: 25,
    },
    [breakpoints.up('xl')]: {
      padding: '22px 56px',
      fontSize: 35,
    },
    '&, &:hover, &:focus, &:active': {
      border: `2px solid ${palette.common.white}`,
      background: 'linear-gradient(245.79deg, #fde030 8.65%, #d008b2 90.3%)',
      color: palette.common.white,
      textDecoration: 'none',
    },
    '& span': {
      textAlign: 'center',
    },
  },
}));
