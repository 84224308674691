import React from 'react';
import { useModal } from 'mui-modal-provider';

import { IChangeBirthdayFieldProps } from './change-birthday-field.models';
import { ChangeBirthdayField as ChangeBirthdayFieldView } from './change-birthday-field';
import { convertStringToDate } from '../../../../../utils/date/convert-string-to-date';
import { isChangeDateAllowed } from '../../../../../utils/date/is-vpdate-allowed';
import { BirthdayModal } from '../../vision-profile/vision-profile-update-modals/birthday-modal';
import { BirthdayDisableChangeModal } from '../../vision-profile/vision-profile-update-modals/bithday-disable-change-modal';

export const ChangeBirthdayField = ({
  birthdayData,
}: IChangeBirthdayFieldProps): JSX.Element => {
  const { showModal } = useModal();

  const isChangeAllowed = isChangeDateAllowed(birthdayData);

  const handleBirthdayChange = () => {
    if (isChangeAllowed) {
      let dateOfBirth: Date | null = null;

      if (birthdayData?.dateOfBirth) {
        dateOfBirth = convertStringToDate(birthdayData.dateOfBirth);
      }

      showModal(BirthdayModal, { dateOfBirth });
    } else {
      showModal(BirthdayDisableChangeModal);
    }
  };

  return (
    <ChangeBirthdayFieldView
      onBirthdayChange={handleBirthdayChange}
    />
  );
};
