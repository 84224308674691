import { FormattedMessage } from 'react-intl';

import { ConfirmationModal } from '../../../../../../../../../common/confirmation-modal';
import {
  Icon,
  IconTypes,
  ResponsiveWidth,
} from '../../../../../../../../../common';
import { IDeleteBonusModalProps } from './delete-bonus-modal.models';
import { useStyles } from './delete-bonus-modal.styles';

export const DeleteBonusModal = ({
  onDeleteConfirm, onClose, ...props
}: IDeleteBonusModalProps): JSX.Element => {
  const handleConfirm = () => {
    onDeleteConfirm('', null);
    onClose!();
  };

  const handleReject = () => {
    onClose!();
  };

  const classes = useStyles();

  return (
    <ConfirmationModal
      {...props}
      topIcon={(
        <span
          data-testid="icon-wrapper"
          aria-hidden='true'
          className={classes.iconWrapper}
        >
          <Icon type={IconTypes.cartWithCross} />
        </span>
      )}
      title={<FormattedMessage id="order.bonuses.delete.title" />}
      content={(
        <FormattedMessage id="order.bonuses.delete.description" />
      )}
      confirmButtonLabel={<FormattedMessage id="order.bonuses.delete.button" />}
      onConfirm={handleConfirm}
      onReject={handleReject}
      rejectOnClose
      contentStyles={classes.content}
      titleStyles={classes.title}
      responsiveWidth={ResponsiveWidth.mdWide}
    />
  );
};
