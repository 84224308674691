import { ISurveyState } from '../../components/business/app/vision-profile';

export const getStepStatus = (state: ISurveyState, stepId: string): boolean => {
  if (!Object.prototype.hasOwnProperty.call(state, stepId)) {
    return false;
  }

  if (stepId === 'whatDoYouLike') {
    const { whatDoYouLike: { whatDoYouLikeCustom, whatDoYouLikePredefined } } = state;
    return !whatDoYouLikeCustom && !whatDoYouLikePredefined?.length;
  }

  return !state[stepId as keyof ISurveyState];
};
