import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontFamilies, fontSizes, fontWeights, lineHeights, palette, borderRadiuses,
}: Theme) => ({
  root: {
    textAlign: 'center',
  },
  lensesImage: {
    marginBottom: 12,
    [breakpoints.up('md')]: {
      marginBottom: 24,
    },
  },
  title: {
    margin: '0 0 12px',
    color: palette.text.secondary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xl,
      lineHeight: lineHeights.xl,
    },
  },
  description: {
    margin: '0 0 16px',
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.xs,
    [breakpoints.up('md')]: {
      margin: '0 0 24px',
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.lg,
    },
  },
  howToFitLinkPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textDecoration: 'none',
    border: `1px solid ${palette.grey['200']}`,
    borderRadius: borderRadiuses.md,
    padding: '20px 16px',
    marginBottom: 30,
    [breakpoints.up('md')]: {
      padding: 8,
      margin: '0 0 90px -20px',
      width: 'calc(100% + 40px)',
    },
  },
  linkPanelContent: {
    display: 'flex',
    alignItems: 'center',
  },
  linkPanelContentText: {
    marginLeft: 8,
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.xs,
    [breakpoints.up('md')]: {
      marginLeft: 16,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.lg,
    },
  },
  linkPanelIcon: {
    height: 23,
    marginRight: 4,
  },
}));
