import React, { FC } from 'react';
import { useQuery } from '@apollo/client';

import { GET_IS_USER_LOGGED_IN } from '../../../graphql/user/queries';
import { withFittingProps } from './with-fitting-props/with-fitting-props';
import { withDefaultFiltersAndStoresList } from './with-default-filters-and-stores-list/with-default-filters-and-stores-list';
import { IEcpLocatorSettingsProps } from './ecp-locator-settings.models';

export const ecpLocatorSettings = <T extends unknown>(Component: FC<T>) => (
  props: T,
): JSX.Element => {
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);

  const ComponentWithSettings = withDefaultFiltersAndStoresList(Component);

  const WrappedComponent = isLoggedIn
    ? withFittingProps(ComponentWithSettings)
    : ComponentWithSettings;

  return <WrappedComponent {...props as T & IEcpLocatorSettingsProps} />;
};
