import React, { FC, forwardRef, ReactNode, useImperativeHandle, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './notification-step.styles';
import { ScrollToTop } from '../../../../../common/scroll-to-top';
import { IStepComponentProps, IStepperImperativeHandleProps } from '../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../common/form';

export const NotificationStep: FC = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({ onGoBack }, ref) => {
  const intl = useIntl();
  const classes = useStyles();
  const formRef = useRef<IFormImperativeHandleProps>(null);

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef?.current?.submit();
    },
    goBack() {
      onGoBack();
    },
  }));

  return (
    <div data-testid="notification-step" className={classes.root}>
      <ScrollToTop />
      <h2 data-testid="notification-step-title" className={classes.title}>
        {intl.formatMessage(
          { id: 'profileDelete.notificationModal.title' },
          { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },

        )}
      </h2>
      <p data-testid="notification-step-description" className={classes.description}>
        <FormattedMessage id="profileDelete.notificationModal.description" />
      </p>
      <a
        href={intl.formatMessage({ id: 'common.phoneNumber.support.href' })}
        data-testid="notification-step-action"
        className={classes.button}
      >
        <FormattedMessage id="common.phoneNumber.support" />
      </a>
    </div>
  );
});
