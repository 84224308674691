import { IFormSubmitValues } from '../../../../../../../common/form';
import { IPackageParams, TFormValue } from '../add-lenses-step-form';

export const getConsumerLenses = (products: IFormSubmitValues, lenses: IPackageParams[]) => {
  if (products) {
    const updatedProducts = Object.values(products) as TFormValue[];

    return updatedProducts.map(({
      brand,
      eye,
      packSize,
      productQuantity,
      id,
    }) => ({
      brandId: brand,
      leftEye: eye,
      packSize,
      productQuantity,
      id: String(id),
      points: lenses ? lenses.find((item) => item.brandId === brand)!.points : [],
    }));
  }
};
