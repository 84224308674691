import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, borderRadiuses, palette }: Theme) => ({
  apps: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'fit-content',
    gap: '0.75rem',
    [breakpoints.up('md')]: {
      alignSelf: 'flex-end',
    },
    [breakpoints.up('xl')]: {
      alignSelf: 'flex-start',
    },
  },
  stores: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& a': {
      lineHeight: 0,
    },
  },
  appStore: {
    width: 135,
    height: 'auto',
  },
  googlePlay: {
    width: 135,
    height: 'auto',
  },
  qrCode: {
    padding: '0.375rem',
    width: '5.75rem',
    height: '5.75rem',
    borderRadius: borderRadiuses.sm,
    background: palette.common.white,
    [breakpoints.up('xl')]: {
      marginLeft: 0,
    },
    '& img': {
      display: 'block',
      width: '100%',
      objectFit: 'contain',
    },
  },
}));
