import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { yup } from '../services/yup';

export const useEmailValidation = () => {
  const intl = useIntl();

  return useMemo(
    () => yup.string()
      .trim()
      .required(intl.formatMessage({ id: 'registration.emailStep.errors.required' }))
      .emailLatin(intl.formatMessage({ id: 'common.errors.emailLatin' })),
    [intl],
  );
};
