import React, {
  useCallback,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useTimer } from 'use-timer';

import { useBind } from '../../../../../../../../hooks/use-bind';
import { useSnackbar } from '../../../../../../../../hooks/use-snackbar';
import { useCancellablePromise } from '../../../../../../../../hooks/use-cancellable-promise';
import { ConfirmationCodeStepForm } from './confirmation-code-step-form';
import { yup } from '../../../../../../../../services/yup';
import { consumerApi } from '../../../../../../../../rest/consumer';
import { useOtpCodeValidation } from '../../../../../../../../hooks/use-otp-code-validation';
import { REQUEST_CODE_TIME } from './confirmation-code-step.constants';
import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../../../common/form';
import { IExternalState, IStepsState, TFormValues } from '../../change-phone-modal.models';
import { useActionsInProgress } from '../../../../../../../../graphql/preloader/actions/actions-in-progress';

export const ConfirmationCodeStep = forwardRef<
IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  onGoNextFail,
  onGoNext,
  isNavigationAllowed,
  stepsState: stepsStateProp,
  incrementStepActionsInProgress,
  decrementStepActionsInProgress,
  externalState: externalStateProp,
}, ref): JSX.Element => {
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const stepsState = stepsStateProp as IStepsState;
  const externalState = externalStateProp as IExternalState;
  const {
    time: requestCodeTime,
    reset: resetRequestCodeTimer,
    start: startRequestCodeTimer,
  } = useTimer({
    initialTime: REQUEST_CODE_TIME,
    timerType: 'DECREMENTAL',
    autostart: true,
    endTime: 0,
  });

  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const { enqueueSnackbar } = useSnackbar();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const restartRequestCodeTimer = useCallback(() => {
    resetRequestCodeTimer();
    startRequestCodeTimer();
  }, [resetRequestCodeTimer, startRequestCodeTimer]);

  const incrementStepActionsInProgressBind = useBind(incrementStepActionsInProgress);
  const decrementStepActionsInProgressBind = useBind(decrementStepActionsInProgress);
  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const stepsStateBind = useBind(stepsState);
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const onGoNextFailBind = useBind(onGoNextFail);
  const onGoNextSuccessBind = useBind(onGoNextSuccess);
  const isNavigationAllowedBind = useBind(isNavigationAllowed);
  const onGoNextBind = useBind(onGoNext);
  const externalStateBind = useBind(externalState);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);

  const handleRequestCode = useCallback(async () => {
    if (!isNavigationAllowedBind.current) {
      return;
    }

    incrementStepActionsInProgressBind.current!();
    addActionInProgressBind.current();

    try {
      await makeCancellablePromiseBind.current(
        consumerApi.changePhoneNumber({
          phoneNumber: externalStateBind.current.phoneNumber,
          newPhoneNumber: stepsStateBind.current.phone.newPhoneNumber,
        }),
      );

      removeActionInProgressBind.current();
      restartRequestCodeTimer();
      decrementStepActionsInProgressBind.current!();
    } catch (error) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        // eslint-disable-next-line no-useless-return
        return;
      }

      decrementStepActionsInProgressBind.current!();
    }
  }, [
    restartRequestCodeTimer,
    stepsStateBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    incrementStepActionsInProgressBind,
    decrementStepActionsInProgressBind,
    isNavigationAllowedBind,
    externalStateBind,
    addActionInProgressBind,
    removeActionInProgressBind,
  ]);

  const otpCodeValidation = useOtpCodeValidation();

  const validationSchema = useMemo(() => yup.object({
    otpCode: otpCodeValidation,
  }), [otpCodeValidation]);

  const defaultValues = useMemo(
    () => stepsState.confirmationCode && stepsState.confirmationCode.formValues,
    [stepsState],
  );

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return {
        formValues: formRef.current!.getValues(),
      };
    },
  }));

  const handleSubmit = useCallback(async (formValues: TFormValues) => {
    if (!isNavigationAllowedBind.current) {
      return;
    }

    onGoNextBind.current();
    addActionInProgressBind.current();

    try {
      const { data } = await makeCancellablePromiseBind.current(
        consumerApi.confirmPhoneNumber({
          phoneNumber: externalStateBind.current.phoneNumber,
          newPhoneNumber: stepsStateBind.current.phone.newPhoneNumber,
          otpCode: formValues.otpCode,
        }),
      );

      removeActionInProgressBind.current();
      onGoNextSuccessBind.current({
        formValues,
        responsePhoneNumber: data.mobile,
      });
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        return;
      }

      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 400:
            enqueueSnackbarBind.current(
              <FormattedMessage
                id="common.errors.otpCode.invalid"
              />,
              { variant: 'error' },
            );
            break;
          default:
            break;
        }
      }

      onGoNextFailBind.current();
    }
  }, [
    stepsStateBind,
    enqueueSnackbarBind,
    onGoNextFailBind,
    onGoNextSuccessBind,
    onGoNextBind,
    isNavigationAllowedBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    externalStateBind,
    addActionInProgressBind,
    removeActionInProgressBind,
  ]);

  return (
    <ConfirmationCodeStepForm
      ref={formRef}
      onRequestCode={handleRequestCode}
      requestCodeTime={requestCodeTime}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultValues={defaultValues}
    />
  );
});
