import React from 'react';

import { INoEntriesProps } from './no-entries.models';
import { useStyles } from './no-entries.styles';

export const NoEntries = ({ icon, description }: INoEntriesProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      data-testid="no-entries-component"
    >
      <span
        className={classes.lensesImage}
        data-testid="no-entries-logo"
      >
        {icon}
      </span>
      <div className={classes.description}>
        {description}
      </div>
    </div>
  );
};
