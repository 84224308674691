import {
  AppointmentStoreSubType,
  AppointmentStoreType,
} from '../../graphql/appointments/models/get-appointments.models';

export const isClinicStore = (
  type: AppointmentStoreSubType | AppointmentStoreType | string,
) => type === AppointmentStoreType.clinic
  || type === AppointmentStoreSubType.clinicHybrid
  || type === AppointmentStoreType.fitter;
