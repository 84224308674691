import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    display: 'block',
    width: '100%',
    height: 1,
    borderBottom: `1px solid ${palette.grey['400']}`,
    margin: '2rem 0',
  },
}));
