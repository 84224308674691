import { omit } from 'lodash';

import { IVisionProfileFields } from '../../graphql/profile/models/get-vision-profile.models';
import { ISurveyState } from '../../components/business/app/vision-profile';

export const getVPStateObject = (fields?: IVisionProfileFields | null): ISurveyState => {
  if (!fields) {
    return {
      dateOfBirth: null,
      meta: {
        canUpdateDateOfBirth: true,
      },
      gadgetsUseDuration: null,
      lastVisionCheck: null,
      timeOutsideDuration: null,
      wearRegularity: null,
      whatDoYouLike: {
        whatDoYouLikeCustom: '',
        whatDoYouLikePredefined: null,
      },
    };
  }

  const { whatDoYouLikeCustom, whatDoYouLikePredefined } = fields;

  return {
    ...omit(fields, 'whatDoYouLikeCustom', 'whatDoYouLikePredefined'),
    whatDoYouLike: {
      whatDoYouLikeCustom,
      whatDoYouLikePredefined,
    },
  };
};
