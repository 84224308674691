import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useStyles } from './add-lenses-step-title.styles';
import { IconButton } from '../../../../../../../common/icon-button';
import { IconSizes, IconTypes } from '../../../../../../../common';
import { Breakpoints } from '../../../../../../../../themes/default';
import { useGoBack } from '../../../../../../../../hooks/use-go-back';
import { getEnding } from '../../../utils/get-ending';
import { IAddLensesStepTitleProps } from './add-lenses-step-title.models';

export const AddLensesStepTitle = ({
  packagesValue,
  isRepeatOrder,
  onGoLast,
  isChangeProducts,
}: IAddLensesStepTitleProps): JSX.Element => {
  const classes = useStyles();

  const addLensesTitle = packagesValue ? (
    <span data-testid="add-lenses-title-no-products">
      <FormattedMessage
        id="order.create.title.lensesNumber"
        values={{
          value: packagesValue,
          ending: getEnding(packagesValue),
        }}
      />
    </span>
  ) : (
    <span data-testid="add-lenses-title-no-products">
      <FormattedMessage id="order.create.title.addLenses" />
    </span>
  );

  const width = window.innerWidth;
  const isTablet = width > Breakpoints.md || width === Breakpoints.md;

  const { goBack } = useGoBack();

  const handleBackButtonClick = useCallback(() => goBack(), [goBack]);
  const handleGoLast = useCallback(() => onGoLast!(), [onGoLast]);

  return (
    <>
      <h3
        className={classes.title}
        data-testid="add-lenses-title"
      >
        <IconButton
          onClick={isRepeatOrder || isChangeProducts ? handleGoLast : handleBackButtonClick}
          iconProps={{
            type: IconTypes.arrowLeft,
            size: isTablet ? IconSizes.md : IconSizes.sm,
          }}
          className={classes.backButton}
          data-testid="back-button"
        />
        <span data-testid="title-content">{addLensesTitle}</span>
      </h3>
      {!packagesValue && (
        <p
          className={classes.description}
          data-testid="package"
        >
          <FormattedMessage id="order.create.description.addLenses" />
        </p>
      )}
    </>
  );
};
