import { FormattedMessage, useIntl } from 'react-intl';
import { useModal } from 'mui-modal-provider';

import { Button } from '../../../../../common/button';
import { IActivitiesChangeButtonProps } from './activities-change-button.models';
import { useStyles } from './activities-change-button.styles';
import { WhatDoYouLikeModal } from '../../vision-profile-update-modals';

export const ActivitiesChangeButton = ({
  whatDoYouLikeCustom, whatDoYouLikePredefined,
}: IActivitiesChangeButtonProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const { showModal } = useModal();

  const handleButtonClick = () => {
    showModal(WhatDoYouLikeModal, { whatDoYouLikeCustom, whatDoYouLikePredefined });
  };

  return (
    <Button
      variant="text"
      aria-label={intl.formatMessage({
        id: 'vp.page.activities.changeButton.buttonLabel',
      })}
      data-testid="change-activities-button"
      className={classes.root}
      onClick={handleButtonClick}
    >
      <FormattedMessage id="vp.page.activities.changeButton" />
    </Button>
  );
};
