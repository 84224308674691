import { EyeParamsType, LensesBrandType } from './get-lenses.models';

export interface IBrands {
  brands: IBrand[];
}

export interface IBrand {
  id: string;
  product: LensesBrandType;
  parameters: IBrandParams[];
}

export interface IBrandParams {
  configuration: string;
  name: EyeParamsType;
  unit: EyeParamsUnits;
  conditional: boolean;
  type: BrandParamsType;
  condition?: IBrandConfigurationCondition;
}

export enum EyeParamsUnits {
  number = 'NUMBER',
  none = 'NONE',
  millimeters = 'MILLIMETERS',
  degree = 'DEGREE',
}

export enum BrandParamsType {
  exact = 'EXACT',
  range = 'RANGE',
}

export enum BrandConfigurationConditionAction {
  replace = 'replace',
  onSelect = 'onSelect',
}

export enum BrandConfigurationConditionType {
  in = 'IN',
  select = 'SELECT',
}

export interface IBrandConfigurationConditionOnSelect {
  values?: any[];
  replace?: IBrandParams[];
}

export interface IBrandConfigurationRange {
  max: number;
  min: number;
  step: number;
  condition: IBrandConfigurationCondition;
}

export interface IBrandConfiguration {
  range?: IBrandConfigurationRange[];
  values?: any[];
  condition?: IBrandConfigurationCondition;
}

export interface IBrandConfigurationCondition {
  action: BrandConfigurationConditionAction;
  type: BrandConfigurationConditionType;
  replace?: IBrandParams[];
  onSelect?: IBrandConfigurationConditionOnSelect[];
}
