import { FormattedMessage } from 'react-intl';

import { ICardFooterProps } from './card-footer.models';
import { useStyles } from './card-footer.styles';
import { CompliancePeriodField } from './compliance-period-field';

export const CardFooter = ({
  isCompliancePeriod, complianceDate, loyaltyPoints, isOneDateBrand,
}: ICardFooterProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      data-testid="my-lenses-card-footer"
    >
      {(isCompliancePeriod && !isOneDateBrand) ? (
        <CompliancePeriodField
          complianceDate={complianceDate}
          loyaltyPoints={loyaltyPoints}
        />
      ) : (
        <p
          className={classes.button}
          data-testid="my-lenses-card-footer-button"
        >
          <FormattedMessage
            id="myLensesPreview.fittingLink.label"
          />
        </p>
      )}
    </div>
  );
};
