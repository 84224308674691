import React from 'react';
import { useQuery } from '@apollo/client';

import { MyLenses as MyLensesView } from './my-lenses';
import { IConsumerLenses } from '../../../../../../graphql/lenses/models/get-lenses-preview.models';
import { GET_LENSES_PREVIEW } from '../../../../../../graphql/lenses/queries/get-lenses-preview';

export const MyLenses = (): JSX.Element => {
  const data = useQuery<IConsumerLenses>(GET_LENSES_PREVIEW, { fetchPolicy: 'network-only' });

  return <MyLensesView previewData={data} />;
};
