import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  list: {
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    marginBottom: 16,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
