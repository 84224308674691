import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';

import { Box } from '../../../common/box';
import { Stepper } from '../../../common/stepper';
import { useRegistrationSteps } from './registration.steps';
import { IRegistrationProps } from './registration.models';
import { ErrorAuth } from './error-auth/error-auth';
import { useStyles } from './registration.styles';

export const Registration = ({ onDone, externalState }: IRegistrationProps): JSX.Element => {
  const intl = useIntl();
  const registrationSteps = useRegistrationSteps();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <script src={process.env.REACT_APP_RECAPTCHA_API_URL} />
        <title>
          {intl.formatMessage({ id: 'registrationPage.title' })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'registrationPage.description' })}
        />
      </Helmet>
      <div className={classes.errorWrapper}>
        {!isMobile && <ErrorAuth textId="registration.phoneStep.error.existing" rootStyles={classes.error} />}
      </div>
      <Box externalRootClasses={classes.box}>
        <Stepper
          steps={registrationSteps}
          onDone={onDone}
          externalState={externalState}
        />
      </Box>
    </>
  );
};
