import { gql } from '@apollo/client';

export const GET_LENSES_PREVIEW = gql`
  query GetConsumerLensesQuery {
    consumerLensesByAuthID {
      id
      brandId
      createdManual
      brand {
        id
        fullName
      }
      leftEye {
        value
        property
      }
      rightEye {
        value
        property
      }
      loyaltyPoints {
        brandId
        packSize
        value
      }
    }
    profile {
      id
      compliancePeriodEndDate
    }
    purchasesByAuthId {
      id
      type
      createdDateTime
      scannedProduct {
        myAcuvueBrandCode
      }
    }
    fittingByAuthId {
      id
      type
      createdDateTime
      fittedBrands {
        brand {
          id
          product
        }
      }
    }
  }
`;
