import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  fontWeights, fontSizes, lineHeights, breakpoints, palette,
}: Theme) => ({
  icon: {
    textAlign: 'center',
    '& svg': {
      width: 'auto',
      height: 120,
    },
  },
  title: {
    textAlign: 'center',
    letterSpacing: 'normal',
  },
  content: {
    marginBottom: 16,
    textAlign: 'center',
    '& p': {
      display: 'inline',
      margin: 0,
      fontSize: fontSizes.xs,
      lineHeight: lineHeights.sm,
      [breakpoints.up('md')]: {
        fontSize: fontSizes.sm,
        lineHeight: lineHeights.md,
      },
    },
  },
  callButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
    padding: '8px 16px',
    borderRadius: 60,
    backgroundColor: palette.primary.main,
    color: palette.grey.A100,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    textDecoration: 'none',
    [breakpoints.up('md')]: {
      padding: '12px 24px',
      lineHeight: lineHeights.md,
    },
    '&:hover': {
      backgroundColor: palette.primary.light,
      color: palette.common.white,
    },
    '&:active': {
      backgroundColor: palette.action.active,
    },
  },
}));
