import { setTokenToStorage } from '../../../utils/user';
import { ITokenData } from '../../../rest/oauth/oauth.models';
import { isLoggedIn } from '../user.cache';

export const login = (tokenData: ITokenData, isSamePage: boolean = false) => {
  setTokenToStorage(tokenData);
  isLoggedIn(true);
  if (!isSamePage) {
    location.href = '/';
  }
};

export const relogin = (tokenData: ITokenData) => {
  setTokenToStorage(tokenData);
};

export const useLogin = () => ({
  login,
  relogin,
});
