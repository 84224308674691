import { forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { DatePicker } from '../../../../../../common/form/date-picker';
import { IBirthdayViewFormProps } from './birthday-view-form.models';

export const BirthdayViewForm = forwardRef<IFormImperativeHandleProps, IBirthdayViewFormProps>(({
  formProps,
  maxDateOfBirth,
  canUpdateDateOfBirth,
}, ref): JSX.Element => {
  const intl = useIntl();

  return (
    <Form
      ref={ref}
      {...formProps}
    >
      <DatePicker
        dateOpenPickerEnable
        name="dateOfBirth"
        maxDate={maxDateOfBirth}
        readOnly={!canUpdateDateOfBirth}
        InputProps={{
          fullWidth: true,
          placeholder: intl.formatMessage({
            id: 'vp.dateOfBirth.birthdayInput.placeholder',
          }),
          'data-testid': 'change-date-input',
        }}
        defaultCalendarMonth={maxDateOfBirth}
        toolbarTitle={(
          <FormattedMessage id="vp.dateOfBirth.birthdayInput.placeholder" />
        )}
      />
    </Form>
  );
});
