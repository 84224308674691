import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    margin: '20px auto 0',
    [breakpoints.up('sm')]: {
      marginTop: 24,
    },
  },
}));
