import { gql } from '@apollo/client';

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($orderId: String!, $order: OrderContractInput!) {
    updateOrder(orderId: $orderId, order: $order) {
      id
      orderStatus
      orderDate
      systemId
      deliveryDate
      products {
        brand
        eye {
          property
          value
        }
        materialCode
        packSize
        productQuantity
      }
      store {
        name
        address
        workingHours
        phone
        code
      }
      voucherId
    }
  }
`;
