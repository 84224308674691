import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      appearance: 'none',
    },
    '& input': {
      '-moz-appearance': 'textfield',
    },
  },
}));
