import { forwardRef, useMemo } from 'react';

import { IWearRegularityFormProps } from './wear-regularity-form.models';
import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { RadioGroup } from '../../../../../../common/form/radio-group';
import { wearRegularityOptions as wearRegularityOptionsBase } from './wear-regularity-options';

export const WearRegularityForm = forwardRef<
IFormImperativeHandleProps, IWearRegularityFormProps>(({
  formProps,
}, ref): JSX.Element => {
  const wearRegularityOptions = useMemo(() => wearRegularityOptionsBase, []);

  return (
    <Form
      ref={ref}
      {...formProps}
    >
      <RadioGroup
        name="wearRegularity"
        options={wearRegularityOptions}
      />
    </Form>
  );
});
