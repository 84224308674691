import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  root: {
    marginBottom: 40,
    [breakpoints.up('md')]: {
      marginBottom: 64,
    },
  },
  title: {
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontFamily: fontFamilies.primary,
    color: palette.text.secondary,
    fontWeight: fontWeights.medium,
    margin: '0 0 24px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      margin: '0 0 40px',
    },
    [breakpoints.up('lg')]: {
      fontSize: fontSizes.xxxl,
      lineHeight: lineHeights.xxxl,
    },
  },
  cardsList: {
    padding: 0,
    margin: 0,
    listStyleType: 'none',
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: '1fr',
    [breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  cardsItem: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    '&:nth-child(3n)': {
      [breakpoints.up('sm')]: {
        gridColumn: '1 / 3',
      },
      [breakpoints.up('lg')]: {
        gridColumn: 'auto',
      },
    },
  },
  footer: {
    paddingTop: 32,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      paddingTop: 48,
    },
    [breakpoints.up('lg')]: {
      paddingTop: 64,
    },
  },
}));
