import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  spacing,
  palette,
  lineHeights,
  fontSizes,
  fontWeights,
}: Theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  content: {
    textAlign: 'center',
    [breakpoints.up('lg')]: {
      textAlign: 'start',
    },
  },
  icon: {
    width: 188,
    flexShrink: 0,
    marginBottom: 24,
    [breakpoints.up('md')]: {
      marginBottom: 32,
    },
    [breakpoints.up('lg')]: {
      margin: '0 38px 0 0',
    },
  },
  title: {
    margin: '0 0 16px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxl,
    lineHeight: lineHeights.xxl,
    fontWeight: fontWeights.medium,
    color: palette.text.secondary,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxxl,
      lineHeight: lineHeights.xxxl,
    },
  },
  description: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.primary,
    margin: '0 0 100px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
      margin: '0 0 114px',
    },
    [breakpoints.up('lg')]: {
      margin: 0,
    },
  },
  actions: {
    display: 'grid',
    gap: spacing(2),
    margin: '24px auto 0',
    [breakpoints.up('lg')]: {
      width: 365,
    },
  },
}));
