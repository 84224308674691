import { FormattedMessage } from 'react-intl';
import { useModal } from 'mui-modal-provider';

import { WarningModal } from '../warning-modal';
import { IChangeBonusWarningModalProps } from './change-bonus-warning-modal.models';
import { Icon, IconTypes, IconSizes } from '../../../../../../../common';
import { BonusChangeModal } from '../bonus-change-modal';

export const ChangeBonusWarningModal = ({
  onBonusSelect, bonusId, bonusValue, bonusType, bonusesData, onClose, isEditOrder,  ...props
}: IChangeBonusWarningModalProps): JSX.Element => {
  const { showModal } = useModal();

  const handleClose = () => {
    onClose!();
  };

  const handleConfirmChangeBonus = () => {
    showModal(BonusChangeModal, {
      onBonusSelect,
      bonusId,
      bonusValue,
      bonusType,
      bonusesData,
      onWarningClose: handleClose,
      isEditOrder,
    });
  };

  return (
    <WarningModal
      {...props}
      data-testid="change-bonus-warning-modal"
      topIcon={(
        <Icon
          type={IconTypes.infoVoucher}
          size={IconSizes['3xl']}
        />
      )}
      title={<FormattedMessage id="order.bonuses.change.modal.title" />}
      description={(
        <>
          <p>
            <FormattedMessage id="order.bonuses.change.modal.description.first" />
          </p>
          <p>
            <FormattedMessage id="order.bonuses.change.modal.description.second" />
          </p>
        </>
      )}
      confirmButtonLabel={<FormattedMessage id="order.bonuses.change.modal.button" />}
      isMultiContent
      onConfirm={handleConfirmChangeBonus}
      onClose={onClose}
    />
  );
};
