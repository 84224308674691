import React, { RefObject, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { cx } from '@emotion/css';
import { useModal } from 'mui-modal-provider';

import { EcpSearch as EcpSearchView } from '../../../../ecp-search';
import { EcpMap } from './ecp-map';
import { EcpStoresList } from './ecp-stores-list/ecp-stores-list';
import { EcpToggleView } from '../../../../ecp-toggle-view';
import { StoreDetail } from './store-detail';
import { LeaveModal } from '../../leave-modal';
import { LeaveSameRouteModal } from '../../leave-same-route-modal';

import { MobileViewTypes } from '../../../../../../../graphql/ecp-locator/models/map.models';
import {
  withOrderFiltersAndStoresList,
} from '../../../../../../common/ecp-locator-settings/with-order-filters-and-stores-list/with-order-filters-and-stores-list';
import { useStyles } from './ecp-step.styles';
import { IEcpStepProps } from './ecp-step.models';
import {
  IExternalState,
  IOrderStore,
  IStepsState,
} from '../../order-create-page.models';
import { scrollToTop } from '../../../../../../../utils/scroll-to-top';
import {
  setOrderStoreCode,
  setOrderStores,
} from '../../../../../../../graphql/order/order.cache';
import { EcpStepTitle } from './ecp-step-title';
import { IEcpStore } from '../../../../../../../rest/ecp-locator';

const EcpSearch = withOrderFiltersAndStoresList(EcpSearchView);

export const EcpStep = ({
  onStoreSubmit,
  stepsState,
  onGoBack,
  externalState,
  onGoLast,
  onGoLastSuccess,
}: IEcpStepProps): JSX.Element => {
  const classes = useStyles();

  const ecpStores = useReactiveVar(setOrderStores);
  const storeCode = useReactiveVar(setOrderStoreCode);

  const state = stepsState as IStepsState;

  const [store, setStore] = useState<IOrderStore | null>(state.store || null);

  const [isListView, setIsListView] = useState<boolean>(false);
  const isEcpStoresShow = isListView && !!ecpStores.length;

  const handleSetStore = (orderStore: IOrderStore) => {
    setStore(orderStore);
  };

  const handleToggleView = () => {
    setIsListView(!isListView);
  };

  const handleStoreClose = (code: string) => {
    setStore(null);
    // To manage scroll to store card.
    setOrderStoreCode(code);
  };

  const handleStoreSelect = (orderStore: IEcpStore): void => {
    setStore({
      ...orderStore,
      rating: String(orderStore.rating),
    });
    handleToggleView();
  };

  const handleScrollToStore = (
    currentStoreId: string,
    storeTitle: RefObject<HTMLHeadingElement>,
    storeId?: string,
  ) => {
    if (storeId?.length && storeId === currentStoreId) {
      storeTitle.current?.focus();
      storeTitle.current?.scrollIntoView();
      setOrderStoreCode('');
    }
  };

  const { showModal } = useModal();

  const handleGoBack = () => {
    if ((externalState as IExternalState).isRepeatOrder || state.isChange) {
      (stepsState as IStepsState).isChange = false;

      if (store === (externalState as IExternalState).store) {
        onGoLast();
      } else {
        showModal(LeaveSameRouteModal, {
          onConfirm: onGoLastSuccess,
        });
      }
    } else {
      onGoBack();
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <EcpStepTitle
        onGoBack={handleGoBack}
      />
      <div
        className={cx(classes.root,
          { [classes.rootListView]: isListView },
          { [classes.rootMapView]: !isListView },
          { [classes.rootStoreDetail]: !!store },
        )}
      >
        <LeaveModal />
        {!isListView && (
          <div
            className={classes.searchWrapper}
            data-testid="search-wrapper"
          >
            <EcpSearch
              rootInputStyles={classes.searchInputRoot}
              inputStyles={classes.searchInput}
              endAdornmentStyles={classes.searchInputEndAdornment}
            />
          </div>
        )}
        <EcpMap
          ecpStores={ecpStores}
          storeDetail={store ? store : undefined}
          isMapHidden={isListView}
          onSetStore={handleSetStore}
        />
        {store && (
          <StoreDetail
            storeData={store}
            onStoreClose={handleStoreClose}
            onStoreSubmit={onStoreSubmit}
          />
        )}
        {isEcpStoresShow && (
          <EcpStoresList
            stores={ecpStores}
            onStoreSelect={handleStoreSelect}
            storeCode={storeCode}
            onScrollToStore={handleScrollToStore}
          />
        )}
      </div>
      {!store && (
        <div className={classes.toggleView}>
          <EcpToggleView
            value={isListView ? MobileViewTypes.list : MobileViewTypes.map}
            fullWidth
            onChange={handleToggleView}
            buttonStyles={classes.toggleViewButton}
          />
        </div>
      )}
    </>
  );
};
