import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { cx } from '@emotion/css';
import { useReactiveVar } from '@apollo/client';

import { useStyles } from './ecp-locator.styles';
import { Card, CardTypes } from '../../../../common/card';
import { InternalLinks } from '../../../../../constants';
import { useGoBack } from '../../../../../hooks/use-go-back';
import { ContentWrapper } from '../../../../common/content-wrapper';
import { Button } from '../../../../common';
import {
  setIsLocatorIconsCampaign,
} from '../../../../../graphql/configuration/configuration.cache';

export const EcpLocator = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
  const { hasPreviousLocationState } = useGoBack();
  const isCampaignIcon = useReactiveVar(setIsLocatorIconsCampaign);

  return (
    <section
      data-testid="ecp-locator-landing-wrapper"
      aria-label={intl.formatMessage({ id: 'ecp.landing.section.ariaLabel' })}
      className={classes.container}
    >
      <ContentWrapper>
        <Card
          className={classes.root}
          type={CardTypes.secondary}
          data-testid="ecp-locator-landing-card"
        >
          <div className={classes.content}>
            <h2
              data-testid="ecp-locator-landing-title"
              className={classes.sectionTitle}
            >
              <FormattedMessage id="ecpPreview.title" />
            </h2>
            <p
              data-testid="ecp-locator-landing-description"
              className={classes.description}
            >
              {intl.formatMessage(
                { id: 'ecpPreview.description' },
                { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
              )}
            </p>
            {!isTablet && (
              <div
                className={cx(classes.imgContainer,
                  { [classes.imgContainerCampaign]: isCampaignIcon },
                )}
                data-testid="ecp-locator-landing-image"
              />
            )}
            <Button
              data-testid="ecp-locator-landing-button"
              color="info"
              to={InternalLinks.ecpLocator}
              state={hasPreviousLocationState}
              className={classes.button}
            >
              <span>
                <FormattedMessage id="ecpPreview.searchCard.searchButton.label" />
              </span>
            </Button>
          </div>
          {isTablet && (
            <div
              className={cx(classes.imgContainer,
                { [classes.imgContainerCampaign]: isCampaignIcon },
              )}
              data-testid="ecp-locator-landing-image"
            />
          )}
        </Card>
      </ContentWrapper>
    </section>
  );
};
