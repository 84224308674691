import { useMutation } from '@apollo/client';

import { IBirthdayPointsModalProps } from './birthday-points-modal.models';
import { BirthdayPointsModal as BirthdayPointsModalView } from './birthday-points-modal';
import { UPDATE_NOTIFICATION } from '../../../../../../../graphql/notifications/mutations/update-notification';
import { useActionsInProgress } from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { promiseErrorCallbacks } from '../../../../../../../utils/promise/set-promise-error-callbacks';
import { useCancellablePromise } from '../../../../../../../hooks/use-cancellable-promise';

export const BirthdayPointsModal = ({
  notificationId, updateNotifications: updateNotificationsInCache, onClose, ...props
}: IBirthdayPointsModalProps): JSX.Element => {
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const handleModalClose = async () => {
    try {
      addActionInProgress();

      await makeCancellablePromise(
        updateNotification({
          variables: {
            notificationId,
          },
        }),
      );

      updateNotificationsInCache(notificationId);
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    } finally {
      onClose!();
      removeActionInProgress();
    }
  };
  
  return (
    <BirthdayPointsModalView
      {...props}
      onClose={handleModalClose}
    />
  );
};
