export const sortByDateDesc = (arrayToSort: any[], criterion: string): any[] => {
  let result: any[] = arrayToSort;

  if (arrayToSort.length) {
    result = [...arrayToSort].sort((first, second) =>
      Number(new Date(second[criterion])) - Number(new Date(first[criterion])));
  }

  return result;
};

export const sortByDateAsc = (arrayToSort: any[], criterion: string): any[] => {
  let result: any[] = arrayToSort;

  if (arrayToSort.length) {
    result = [...arrayToSort].sort((first, second) =>
      Number(new Date(first[criterion])) - Number(new Date(second[criterion])));
  }

  return result;
};
