import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontFamilies, fontSizes, lineHeights, palette, breakpoints, spacing,
}: Theme) => ({
  description: {
    margin: '0 0 8px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.primary,
    [breakpoints.up('md')]: {
      margin: '0 0 16px',
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  actions: {
    display: 'grid',
    gap: spacing(1.5),
    [breakpoints.up('md')]: {
      gap: spacing(2),
    },
  },
}));
