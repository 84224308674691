import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { PurchasesWarningModal as PurchasesWarningModalView } from './purchases-warning-modal';
import { IPurchasesWarningModalProps } from './purchases-warning-modal.models';
import { InternalLinks } from '../../../../../../../constants';
import { UPDATE_NOTIFICATION } from '../../../../../../../graphql/notifications/mutations/update-notification';
import { useActionsInProgress } from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { useCancellablePromise } from '../../../../../../../hooks/use-cancellable-promise';
import { promiseErrorCallbacks } from '../../../../../../../utils/promise/set-promise-error-callbacks';

export const PurchasesWarningModal = ({
  notificationId, updateNotifications: updateNotificationsInCache, onClose, ...props
}: IPurchasesWarningModalProps): JSX.Element => {
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);
  const navigate = useNavigate();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const handleWarningModalClose = async () => {
    try {
      addActionInProgress();

      await makeCancellablePromise(
        updateNotification({
          variables: {
            notificationId,
          },
        }),
      );
      
      updateNotificationsInCache([notificationId]);
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    } finally {
      removeActionInProgress();
      onClose!();
    }
  };

  const handleActionButtonClick = async () => {
    await handleWarningModalClose();
    navigate(InternalLinks.ecpLocator);
  };

  return (
    <PurchasesWarningModalView
      {...props}
      onClose={handleWarningModalClose}
      onActionButtonClick={handleActionButtonClick}
    />
  );
};
