import React from 'react';

import {
  Appointment as AppointmentView,
} from './appointment';
import { appointmentSettings, IAppointmentSettingsProps } from '../../../../common/appointment-settings';

const AppointmentContainer = ({
  appointments, isLoading, isError,
}: IAppointmentSettingsProps): JSX.Element => (
  <AppointmentView
    appointments={appointments}
    isLoading={isLoading}
    isError={isError}
  />
);

export const Appointment = appointmentSettings(AppointmentContainer);
