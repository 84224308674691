import {
  LensesBrandType,
  SolutionsBrandType,
} from '../../graphql/lenses/models/get-lenses.models';

export const getBrandFullName = (brand: string) => {
  if (brand !== SolutionsBrandType.arl) {
    for (const key in LensesBrandType) {
      if (brand === LensesBrandType[key as keyof typeof LensesBrandType]) {
        return `lenses.${key}`;
      }
    }
  }

  return 'lenses.arl';
};
