import { useStyles } from './benefits.styles';
import { Benefit } from './benefit';
import { benefits } from './benefits.items';

export const Benefits = (): JSX.Element => {
  const classes = useStyles();

  return (
    <ul
      className={classes.list}
      data-testid="benefits-list"
    >
      {benefits.map(({
        item,
        icon,
      }, index) => (
        <li
          data-testid="benefits-list-item"
          key={`benefit-${index}`}
        >
          <Benefit
            item={item}
            icon={icon}
          />
        </li>
      ))}
    </ul>
  );
};
