import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import campaignIcon
  from '../../../../../assets/images/backgrounds/campaign/campaign.png';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  campaign: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    right: 0,
    transform: 'translateY(-90%)',
    width: 60,
    height: 86,
    backgroundImage: `url(${campaignIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    [breakpoints.up('sm')]: {
      transform: 'translateY(-93%)',
      width: 86,
      height: 123,
    },
    [breakpoints.up('lg')]: {
      width: 115,
      height: 165,
    },
  },
}));
