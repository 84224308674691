import React, { forwardRef, useImperativeHandle } from 'react';

import { IStepComponentProps, IStepperImperativeHandleProps } from '../../../../../common/stepper';
import { AppointmentSuccessStep as AppointmentSuccessStepView } from './appointment-success-step';

export const AppointmentSuccessStep = forwardRef<
IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
}, ref): JSX.Element => {
  useImperativeHandle(ref, () => ({
    goNext() {
      onGoNextSuccess();
    },
    goBack() { },
  }));

  return (
    <AppointmentSuccessStepView />
  );
});
