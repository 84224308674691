import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
  query GetCustomerQuery {
    profile {
      id
      username
      myAcuvueId
      firstName
      lastName
      email
      mobile
      gender
      commPermissions
      consent {
        consent
        date
        value
      }
    }
    visionProfile {
      dateOfBirth
      meta {
        canUpdateDateOfBirth
      }
    }
  }
`;
