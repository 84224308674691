import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  mapWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
  },
  mapHidden: {
    display: 'none',
  },
  mapWrapperStoreDetail: {
    position: 'relative',
    top: 'unset',
    bottom: 'unset',
    left: 'unset',
    right: 'unset',
    display: 'flex',
    flexGrow: 1,
    marginBottom: -100,
  },
  mapContainerStyle: {
    width: '100%',
    height: '100%',
  },
}));
