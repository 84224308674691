import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  fontSizes, lineHeights, breakpoints, palette,
}: Theme) => ({
  paragraph: {
    margin: 0,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  linkNoUnderline: {
    color: palette.primary.dark,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  hours: {
    maxWidth: 135,
    [breakpoints.up('sm')]: {
      maxWidth: 165,
    },
  },
}));
