import React, { useCallback } from 'react';
import { useModal } from 'mui-modal-provider';

import { ChangePasswordField as ChangePasswordFieldView } from './change-password-field';
import { ChangePasswordModal } from './change-password-modal';
import { IChangePasswordFieldProps } from './change-password-field.models';

export const ChangePasswordField = ({ phoneNumber }: IChangePasswordFieldProps) => {
  const { showModal } = useModal();

  const handleChangePassword = useCallback(() => {
    showModal(ChangePasswordModal, { phoneNumber });
  }, [showModal, phoneNumber]);

  return <ChangePasswordFieldView onChangePassword={handleChangePassword} />;
};
