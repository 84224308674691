export const APPOINTMENT_HOW_TO = `${process.env.REACT_APP_REST_API_URL}/cms/en/pages/how_is_the_fitting`;

export enum ExternalLinks {
  personalDataPolicyLink = 'https://www.acuvue.com/ru-ru/accessibility-policy/',
  privacyPolicyLink = 'https://www.acuvue.com/ru-ru/privacy-policy/',
  myAcuvueProgramPolicyLink = 'https://www.acuvue.com/ru-ru/pravila-programmy-myacuvue/',
  legalNoticeLink = 'https://www.acuvue.com/ru-ru/terms-of-use/',
  newOrder = 'https://sample.acuvue.ru/visioncare/new-order',
  mobileMarket = 'https://myacuvue.onelink.me/o8U8/acuvueru',
  locator = 'https://myacuvue.onelink.me/o8U8/ECPLocator',
  // Wiki links
  visionAndGadgets = 'https://www.acuvue.com/ru-ru/eye-health/digital-eye-strain/',
  visionAndWork = 'https://www.acuvue.com/ru-ru/eye-health/tips-for-work/',
  visionAndUV = 'https://www.acuvue.com/ru-ru/considering-contacts/uv-blocking-benefits/',
  popularProblems = 'https://www.acuvue.com/ru-ru/eye-health/common-vision-issues/',
  astigmatism = 'https://www.acuvue.com/ru-ru/eye-health/astigmatism/',
  presbyopia = 'https://www.acuvue.com/ru-ru/eye-health/presbyopia/',
  weeklyLenses = 'https://www.acuvue.com/ru-ru/products/?page=1&replacement_schedule%5B0%5D=biweekly',
  dailyLenses = 'https://www.acuvue.com/ru-ru/products/?page=1&replacement_schedule%5B0%5D=daily',
  typesOfLenses = 'https://www.acuvue.com/ru-ru/products/types-of-contacts/',
  cleanAndCare = 'https://www.acuvue.com/ru-ru/new-wearer/contact-lens-cleaning-and-care/',
  takeOutLenses = 'https://www.acuvue.com/ru-ru/new-wearer/new-wearer-guide/',
  usefulTips = 'https://www.acuvue.com/ru-ru/new-wearer/new-wearer-guide/',
  putInLenses = 'https://www.acuvue.com/ru-ru/new-wearer/new-wearer-guide/',
  commonMyths = 'https://www.acuvue.com/ru-ru/considering-contacts/contact-lens-myths/',
  lensesBenefits = 'https://www.acuvue.com/ru-ru/considering-contacts/new-contact-lenses/',
  contactLensesFacts = 'https://www.acuvue.com/ru-ru/considering-contacts/why-acuvue/',
  fittingPreparation = 'https://www.acuvue.com/ru-ru/get-contacts/prepare-for-appointment/',
  lensesFitting = 'https://www.acuvue.com/ru-ru/get-contacts/how-to-get-contacts/',
  // Social media
  mailLink = 'mailto:acuvue@jnj.ru',
  phoneNumber = '88006007997',
  mobileLinkRegGuide = 'https://myacuvue.onelink.me/o8U8/yakdfgmk',
  mobileLinkRevitalLens = 'https://myacuvue.onelink.me/o8U8/dsvm7p8c',
}
