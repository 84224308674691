import { useCallback, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useQuery } from '@apollo/client';

import { useCancellablePromise } from '../../../hooks/use-cancellable-promise';
import { useBind } from '../../../hooks/use-bind';
import { cmsApi } from '../../../rest/cms/cms.api';
import { IAppTranslationSettings } from './app-translation-settings.models';
import { GET_TRANSLATIONS } from '../../../graphql/cms/queries/get-translations';
import { useSetTranslations } from '../../../graphql/cms/actions/set-translations';
import { LOCALE } from '../../../constants';

export const AppTranslationSettings = ({ children }: IAppTranslationSettings): JSX.Element => {
  const { data: { cms: { translations } } } = useQuery(GET_TRANSLATIONS);
  const { setTranslations } = useSetTranslations();

  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const cmsTranslationsEnabled = process.env.REACT_APP_CMS_TRANSLATIONS_ENABLED === 'true';

  const setTranslationsBind = useBind(setTranslations);
  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);

  const applyCmsTranslations = useCallback(async () => {
    try {
      const { data } = await makeCancellablePromiseBind.current(
        cmsApi.getTranslations(),
      );

      setTranslationsBind.current(data);
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        // eslint-disable-next-line no-useless-return
        return;
      }
    }
  }, [
    setTranslationsBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
  ]);

  useEffect(() => {
    if (cmsTranslationsEnabled) {
      applyCmsTranslations();
    }
  }, [applyCmsTranslations, cmsTranslationsEnabled]);

  return (
    <IntlProvider messages={translations} locale={LOCALE} defaultLocale={LOCALE}>
      {children}
    </IntlProvider>
  );
};
