import { useReactiveVar } from '@apollo/client';
import { useModal } from 'mui-modal-provider';
import { useCallback } from 'react';
import { UnsavedChangesConfirmationModal } from '../../../components/common/unsaved-changes-confirmation-modal';

import { removeTokenFromStorage } from '../../../utils/user';
import { screenState } from '../user.cache';

export const logoutActions = () => {
  removeTokenFromStorage();
  location.reload();
};

export const useLogout = () => {
  const { showModal } = useModal();
  const { isScreenBlocked, unblock } = useReactiveVar(screenState);

  const logout = useCallback(() => {
    if (isScreenBlocked) {
      const { hide } = showModal(UnsavedChangesConfirmationModal, {
        onConfirm: () => {
          if (unblock) {
            unblock();
          }

          logoutActions();
          hide();
        },
        onReject: () => {
          hide();
        },
      });
    } else {
      logoutActions();
    }
  }, [
    isScreenBlocked,
    showModal,
  ]);

  return { logout };
};
