type LensesInfo = {
  consumerLensesByAuthID: {
    createdManual: boolean;
  }[]
};

export const isUserFitted = (
  data: LensesInfo | undefined,
): boolean => Boolean(
  data?.consumerLensesByAuthID?.length
  && !data.consumerLensesByAuthID[0].createdManual,
);
