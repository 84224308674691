import { useStyles } from './facts-about-brand.styles';
import brandUv from '../../../../../assets/images/brand-pages/facts-about-brand/brand-uv.png';
import { Footer } from '../../../../common/footer';
import { Header } from '../../layout-primary/header';
import { useMediaQuery, useTheme } from '@mui/material';
import brandUvMobile from '../../../../../assets/images/brand-pages/facts-about-brand/brand-uv-mobile.png';

export const BrandUv = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isMobile && <Header />}
      <section className={classes.root} data-testid="winners-content">
        <img src={isMobile ? brandUvMobile : brandUv} alt="" />
        {isMobile && <Footer />}
      </section>
    </>
  );
};
