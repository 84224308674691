import { ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { useStyles } from './order-create-card.styles';
import { Card } from '../../../../../common/card';
import { IOrderCreateCardProps } from './order-create-card.models';
import { LensesBrandLogo } from '../../../../../common';
import { getBrandFullName } from '../../../../../../utils/lenses/get-brand-full-name';
import { ProductQuantity } from './product-quantity';
import { EyeParameters } from './parameters';
import { PackSize } from './pack-size';
import { Points } from './points';
import { LENSES_AMOUNT_MIN } from '../../../../../../constants/validation/lenses-amount';
import { getPoints } from '../../../../../../utils/order';
import { ChangeParametersButton } from './change-parameters/change-parameters-button';
import {
  LensesBrandType,
  SolutionsBrandType,
} from '../../../../../../graphql/lenses/models/get-lenses.models';
import { SolutionsBrandLogo } from '../../../../../common/solutions-brand-logo';

export const OrderCreateCard = ({
  brand, packSizes, points, parameters, onChangePackSize, onChangeQuantity,
  packSizeSelected, productQuantity, onIncreasePackageValue, onDecreasePackageValue,
  formValues, stepState, brandParameters, onUpdateParameters, eye, id,
}: IOrderCreateCardProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  const isProductQuantity = useMemo(() => {
    return productQuantity > Number(LENSES_AMOUNT_MIN);
  }, [LENSES_AMOUNT_MIN, productQuantity]);

  const acuvuePoints = useMemo(() => {
    return getPoints(
      packSizeSelected,
      points,
      productQuantity,
    );
  }, [packSizeSelected, points, productQuantity]);

  const singlePoints = useMemo(() => {
    return productQuantity ? acuvuePoints / productQuantity : acuvuePoints;
  }, [acuvuePoints, productQuantity]);

  const isSolutionsBrand = brand === SolutionsBrandType.arl;

  return (
    <Card
      className={classes.root}
      data-testid="order-create-card"
    >
      {isSolutionsBrand ? (
        <span className={classes.imageSolutionsWrapper}>
          <SolutionsBrandLogo
            brand={brand as SolutionsBrandType}
            className={classes.imageSolutions}
          />
        </span>
      ) : (
        <LensesBrandLogo
          brand={brand as LensesBrandType}
          className={cx(classes.image,
            { [classes.imageAom1d]: brand === LensesBrandType.aom1d },
          )}
        />
      )}
      <div className={classes.contentWrapper}>
        {(isProductQuantity && acuvuePoints > 0) && (
          <Points
            value={acuvuePoints}
            tooltipValue={singlePoints}
          />
        )}
        <h3
          className={classes.brand}
          data-testid="brand-wrapper"
          id={id}
          data-name={brand}
        >
          <span data-testid="brand-name">
            {intl.formatMessage(
              { id: getBrandFullName(brand) },
              { 'sup': (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </span>
        </h3>
        <PackSize
          packSizes={packSizes}
          onChangePackSize={onChangePackSize}
          formValues={formValues}
          stepState={stepState}
          packSizeSelected={packSizeSelected}
          isSolutionsBrand={isSolutionsBrand}
        />
        {!isSolutionsBrand && (
          <>
            <EyeParameters
              eye={eye}
              updatedParameters={parameters}
            />
            <ChangeParametersButton
              onUpdateParameters={onUpdateParameters}
              parameters={parameters}
              brandParameters={brandParameters}
            />
          </>
        )}
        <ProductQuantity
          brand={brand}
          onChangeProductQuantity={onChangeQuantity}
          onDecreasePackageValue={onDecreasePackageValue}
          onIncreasePackageValue={onIncreasePackageValue}
          productQuantity={productQuantity}
        />
      </div>
    </Card>
  );
};
