import { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import { useStyles } from './not-fitted-user-modal.styles';
import { Button, Modal, ResponsiveWidth } from '../../../../common';
import { INotFittedUserModalProps } from './not-fitted-user-modal.models';
import { InternalLinks } from '../../../../../constants';
import { useModal } from 'mui-modal-provider';
import { AppointmentRegistrationModal } from '../../appointment-registration-modal';

export const NotFittedUserModal = ({ updateAppointments, ...props }: INotFittedUserModalProps): JSX.Element => {
  const classes = useStyles();
  const { showModal } = useModal();
  const handleButtonClick = () => {
    showModal(AppointmentRegistrationModal, { updateAppointments: updateAppointments! });
  };
  
  return (
    <Modal
      {...props}
      data-testid="not-fitted-user-modal"
      responsiveWidth={ResponsiveWidth.mdWide}
      PaperProps={{ 'data-testid': 'not-fitted-user-content' } as HTMLAttributes<HTMLDivElement>}
      title={<FormattedMessage id="order.notFittedUser.modal.title" />}
      titleStyles={classes.title}
      content={(
        <div
          className={classes.description}
          data-testid="not-fitted-user-modal-description"
        >
          <p data-testid="not-fitted-user-modal-partial">
            <FormattedMessage id="order.notFittedUser.modal.description.first" />
          </p>
          <p data-testid="not-fitted-user-modal-partial">
            <FormattedMessage id="order.notFittedUser.modal.description.second" />
          </p>
        </div>
      )}
      footer={(
        <Button
          variant="contained"
          fullWidth
          to={InternalLinks.ecpLocator}
          data-testid="ecp-button"
          onClick={handleButtonClick}
        >
          <FormattedMessage id="order.notFittedUser.modal.button" />
        </Button>
      )}
    />
  );
};
