import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import headerImage from '../../../../../assets/images/backgrounds/out-of-sales/switch-to-new.png';
import headerImageMobile from '../../../../../assets/images/backgrounds/out-of-sales/switch-to-new-mobile.png';

export const useStyles = makeStyles(({
  breakpoints, palette, fontSizes, lineHeights, fontWeights,
}: Theme) => ({
  header: {
    display: 'block',
    position: 'relative',
    marginLeft: -24,
    marginRight: -24,
    padding: '40px 24px 16px',
    background: `url(${headerImageMobile}), ${palette.common.white}`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    [breakpoints.up('sm')]: {
      marginLeft: -44,
      marginRight: -44,
      paddingBottom: 4,
      backgroundImage: `url(${headerImage})`,
    },
  },
  headerAdditional: {
    paddingBottom: 20,
    [breakpoints.up('sm')]: {
      paddingBottom: 4,
    },
  },
  propsPaperStyles: {
    '&&': {
      paddingTop: '0',
    },
  },
  propsContentStyles: {
    '&&': {
      paddingLeft: 16,
      paddingRight: 16,
      [breakpoints.up('sm')]: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      [breakpoints.up('md')]: {
        paddingLeft: 44,
        paddingRight: 44,
      },
    },
    '& p': {
      marginTop: 0,
      fontSize: fontSizes.xs,
      lineHeight: lineHeights.sm,
      color: palette.text.primary,
      [breakpoints.up('md')]: {
        fontSize: fontSizes.sm,
        lineHeight: lineHeights.md,
      },
    },
    '& li': {
      marginTop: 0,
      fontSize: fontSizes.xs,
      lineHeight: lineHeights.sm,
      color: palette.text.primary,
      [breakpoints.up('md')]: {
        fontSize: fontSizes.sm,
        lineHeight: lineHeights.md,
      },
    },
  },
  packageImage: {
    objectFit: 'contain',
    display: 'block',
    width: 190,
    maxWidth: '100%',
    height: 'auto',
    margin: '0 auto',
  },
  packageImageLarger: {
    width: 244,
  },
  title: {
    margin: '32px 0 16px',
    color: palette.text.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    textTransform: 'uppercase',
    [breakpoints.up('md')]: {
      marginTop: 44,
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
    '&:first-of-type': {
      [breakpoints.between('xs', 'lg')]: {
        '@media (orientation: landscape)': {
          marginTop: 0,
        },
      },
    },
  },
  titleSwitch: {
    [breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  noBreak: {
    whiteSpace: 'nowrap',
  },
  strongText: {
    fontWeight: fontWeights.medium,
  },
  partial: {
    marginBottom: 0,
  },
  inline: {
    display: 'inline',
  },
  switchSection: {
    display: 'flex',
    flexWrap: 'wrap',
    [breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },
  switchSectionInner: {},
  switchGeneral: {
    marginBottom: 20,
    [breakpoints.up('md')]: {
      maxWidth: 316,
    },
  },
  brandImage: {
    order: -1,
    width: '100%',
    height: 'auto',
    [breakpoints.up('sm')]: {
      width: 194,
    },
    [breakpoints.up('md')]: {
      order: 'unset',
    },
  },
  ecpLink: {
    width: '100%',
    marginTop: 20,
    backgroundColor: palette.secondary.dark,
    '&:hover, &:focus': {
      backgroundColor: palette.secondary.dark,
    },
    '&:focus': {
      outline: `1px dotted ${palette.secondary.dark}`,
    },
  },
  contactDescription: {
    marginBottom: 16,
    [breakpoints.up('md')]: {
      marginBottom: 20,
    },
  },
  newBrand: {
    order: -1,
    margin: '0 auto',
    [breakpoints.up('md')]: {
      order: 'unset',
      margin: 0,
      marginRight: 22,
    },
    '& svg': {
      width: 343,
      maxWidth: '100%',
      height: 'auto',
      [breakpoints.up('md')]: {
        width: 195,
      },
    },
  },
  titleStyles: {
    margin: 0,
  },
}));
