import { gql } from '@apollo/client';

export const GET_CONFIGURATION = gql`
  query GetConfiguration {
    configuration {
      name
      configuration
    }
  }
`;
