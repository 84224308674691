import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ palette }: Theme) => ({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  item: {
    padding: '20px 0 8px',
    borderBottom: `1px solid ${palette.grey['200']}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}));
