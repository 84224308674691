import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, lineHeights, palette,
}: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  messageWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  checkedIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
    '& svg': {
      width: 18,
      height: 18,
      [breakpoints.up('sm')]: {
        width: 20,
        height: 20,
      },
      '& path': {
        fill: palette.success.main,
      },
    },
  },
}));
