import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import GTM from 'react-gtm-module';
import { AppSettings } from './components/common/app-settings';
import { App } from './components/business/app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { updateApp } from './utils/app-update/update-app';
import { cleanAppUpdate } from './utils/app-update/clean-app-update';
import { gtmArguments } from './constants/gtm/gtm-arguments';

cleanAppUpdate();

GTM.initialize(gtmArguments);

ReactDOM.render(
  <AppSettings>
    <App />
  </AppSettings>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    interface ServiceWorkerEvent extends Event {
      target: Partial<ServiceWorker> & EventTarget | null;
    }

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: ServiceWorkerEvent) => {
        if (event.target && event.target.state === 'activated') {
          updateApp();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
