import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, fontWeights, lineHeights,
}: Theme) => ({
  root: {
    marginBottom: 20,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.regular,
    [breakpoints.up('sm')]: {
      marginBottom: 16,
    },
  },
}));
