import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import {
  ICmsBannerData,
  ICmsBannerUrl,
  ICmsBanner,
} from '../components/common/cms-banner';
import { GET_BANNERS } from '../graphql/cms/queries/get-banners';

export const useGetBanners = (bannerId: string) => {
  const [isBanner, setIsBanner] = useState<boolean>(false);
  const [url, setUrl] = useState<ICmsBannerUrl>({
    desktopUrl: '',
    tabletUrl: '',
    url: '',
  });

  const [pwaCallToActionLink, setPwaCallToActionLink] =  useState<string | undefined>(undefined);

  const { data, loading } = useQuery<ICmsBannerData>(GET_BANNERS, {
    variables: { id: bannerId },
    context: { clientName: 'anonymous' },
    fetchPolicy: 'network-only',
  });

  const getCurrentBannerData = (banner: ICmsBanner): void => {
    setPwaCallToActionLink(banner.pwaCallToActionLink);
    setUrl({
      desktopUrl: banner.desktopUrl,
      tabletUrl: banner.tabletUrl,
      url: banner.url,
    });
  };

  useEffect(() => {
    if (data && data.banners.length) {
      setIsBanner(true);

      const { banners } = data;
      getCurrentBannerData(banners[0]);
    }
  }, [data]);

  return {
    isBanner,
    pwaCallToActionLink,
    url,
    loading,
  };
};
