import { forwardRef } from 'react';

import { IChangeParametersFormProps } from './change-parameters-form.models';
import { useStyles } from './change-parameters-form.styles';
import { Form, IFormImperativeHandleProps } from '../../../../../../../common/form';
import { Parameter } from './parameter';

export const ChangeParametersForm = forwardRef<IFormImperativeHandleProps, IChangeParametersFormProps>(({
  brandParameters,
  parameters,
  defaultValues,
  onParametersSubmit,
  onValueChange,
  axisValue,
  cylinderValue,
}, ref): JSX.Element => {
  const classes = useStyles();

  return (
    <Form
      ref={ref}
      onSubmit={onParametersSubmit}
      validationMode="onChange"
      defaultValues={defaultValues}
    >
      <ul
        data-testid="change-params-modal-list"
        className={classes.list}
      >
        {brandParameters?.map((brandParameter) => (
          <li
            key={brandParameter.name}
            className={classes.item}
          >
            <Parameter
              brandParameter={brandParameter}
              onValueChange={onValueChange}
              parameters={parameters}
              axisValue={axisValue}
              cylinderValue={cylinderValue}
            />
          </li>
        ))}
      </ul>
    </Form>
  );
});
