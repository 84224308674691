import { DeliveryStore } from '../delivery-store';
import { IDeliveryStoreEditProps } from './delivery-store-edit.models';

export const DeliveryStoreEdit = ({ storeData }: IDeliveryStoreEditProps): JSX.Element => (
  <DeliveryStore
    name={storeData?.name!}
    address={storeData?.street}
    hours={storeData?.openinghours}
    phoneNumber={storeData?.phonenumber}
    withBorder
  />
);
