import React, {
  useMemo, useState, useCallback, useRef,
} from 'react';
import { omit } from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';

import { yup } from '../../../../../../services/yup';
import { useBind } from '../../../../../../hooks/use-bind';
import { ChangePasswordModal as ChangePasswordModalView } from './change-password-modal';
import { consumerApi } from '../../../../../../rest/consumer';
import { useCancellablePromise } from '../../../../../../hooks/use-cancellable-promise';
import { IChangePasswordModalProps } from './change-password-modal.models';
import { IFormData } from '../change-password-form';
import { useSnackbar } from '../../../../../../hooks/use-snackbar';
import { usePasswordValidation } from '../../../../../../hooks/use-password-validation';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { useActionsInProgress } from '../../../../../../graphql/preloader/actions/actions-in-progress';
import { passwordFormatRegx } from '../../../../../../constants';

export const ChangePasswordModal = ({
  phoneNumber, onClose, ...props
}: IChangePasswordModalProps) => {
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const [isPasswordChanging, setPasswordChanging] = useState(false);
  const changePasswordFormRef = useRef<IFormImperativeHandleProps>(null);

  const oldPinValidation = usePasswordValidation({ requiredErrorMessageId: 'changePassword.oldPin.errors.required' });
  const newPinValidation = usePasswordValidation({ requiredErrorMessageId: 'changePassword.newPin.errors.required' });

  const validationSchema = useMemo(() => {
    const newPinConfirm = yup
      .string()
      .required(intl.formatMessage({ id: 'changePassword.newPinConfirm.errors.required' }))
      .test('password-compare',
        intl.formatMessage({ id: 'changePassword.newPinConfirm.errors.notMatch' }), (
          value, { parent: { newPin: newPinValue } },
        ) => !newPinValue || newPinValue === value)
      .max(6, intl.formatMessage({ id: 'common.errors.password.maxLength' }))
      .min(6, intl.formatMessage({ id: 'common.errors.password.minLength' }))
      .matches(passwordFormatRegx, intl.formatMessage({ id: 'common.errors.password.format' }));

    return yup
      .object({
        oldPin: oldPinValidation,
        newPin: newPinValidation,
        newPinConfirm,
      });
  }, [intl, newPinValidation, oldPinValidation]);

  const isPasswordChangingBind = useBind(isPasswordChanging);
  const setPasswordChangingBind = useBind(setPasswordChanging);
  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const onCloseModalBind = useBind(onClose);
  const phoneNumberBind = useBind(phoneNumber);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);

  const handleSubmitButtonClick = useCallback(() => {
    if (changePasswordFormRef.current) {
      changePasswordFormRef.current.submit();
    }
  }, [changePasswordFormRef]);

  const onChangePassword = useCallback(async (values: IFormData) => {
    if (isPasswordChangingBind.current) {
      return;
    }

    setPasswordChangingBind.current(true);
    addActionInProgressBind.current();

    try {
      await makeCancellablePromiseBind.current(consumerApi.changePassword({
        ...omit(values, ['newPinConfirm']),
        phoneNumber: phoneNumberBind.current,
      }));
      removeActionInProgressBind.current();
      enqueueSnackbarBind.current(<FormattedMessage id="changePassword.changePasswordSuccess" />, { variant: 'success' });
      onCloseModalBind.current!();
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        return;
      }

      if (error.response) {
        const { status, data = {} } = error.response;

        switch (status) {
          case 400:
            switch (data.statusCode) {
              case '400':
                enqueueSnackbarBind.current(<FormattedMessage id="changePassword.errors.notNewPassword" />, { variant: 'error' });
                break;
              case '401':
                enqueueSnackbarBind.current(<FormattedMessage id="changePassword.errors.invalidCredentials" />, { variant: 'error' });
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      }

      setPasswordChangingBind.current(false);
    }
  }, [
    CancelledPromiseOnUnmountErrorBind,
    enqueueSnackbarBind,
    isPasswordChangingBind,
    makeCancellablePromiseBind,
    onCloseModalBind,
    phoneNumberBind,
    setPasswordChangingBind,
    addActionInProgressBind,
    removeActionInProgressBind,
  ]);

  return (
    <ChangePasswordModalView
      {...props}
      ref={changePasswordFormRef}
      onClose={onClose}
      onChangePassword={onChangePassword}
      onSubmitButtonClick={handleSubmitButtonClick}
      validationSchema={validationSchema}
    />
  );
};
