import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, palette, siteMediumWidth }: Theme) => ({
  coloredSection: {
    padding: '12px 0 24px',
    backgroundColor: palette.grey.A100,
    [breakpoints.up('sm')]: {
      padding: '32px 0 12px',
    },
    [breakpoints.up('lg')]: {
      padding: '40px 0 20px',
    },
  },
  wikiWrapper: {
    width: '100%',
    maxWidth: siteMediumWidth,
    margin: '0 auto 2rem',
    padding: '0 1rem',
    [breakpoints.up('sm')]: {
      marginBottom: '4rem',
      padding: '0 1.75rem',
    },
  },
}));
