import React from 'react';
import { useQuery } from '@apollo/client';

import { Promotions as PromotionsView } from './promotions';
import { GET_CURRENT_PROMOTIONS } from '../../../../../graphql/promotions/queries/get-promotions';
import { IBonus } from '../../../../../graphql/bonuses/models/get-bonuses.models';
import { Preloader } from '../../../../common/preloader';

export const Promotions = (): JSX.Element => {
  const promotionsData = useQuery<{ voucherByAuthId: IBonus[] }>(GET_CURRENT_PROMOTIONS);
  const { data, loading, error } = promotionsData;

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <></>;
  }

  const voucherByAuthId = data?.voucherByAuthId || [];

  return (
    <PromotionsView voucherByAuthId={voucherByAuthId} />
  );
};
