import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  fontWeights, breakpoints, fontSizes, lineHeights,
}: Theme) => ({
  root: {
    padding: '4px 12px',
    fontWeight: fontWeights.regular,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
}));
