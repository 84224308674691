import {
  IBrand,
  IBrandParams,
} from '../../../../../../../../graphql/lenses/models/get-brands.models';
import {
  LensesBrandType,
} from '../../../../../../../../graphql/lenses/models/get-lenses.models';

export const getParamsByCard = (brandName: LensesBrandType, brands?: IBrand[]): IBrandParams[] => {
  let params: IBrandParams[] = [];

  if (brands?.length) {
    const product = brands.find((brand) => brand.product === brandName);

    if (product) {
      params = product.parameters;
    }
  }

  return params;
};
