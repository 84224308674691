import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ borderRadiuses, palette }: Theme) => ({
  root: {
    paddingTop: 48,
    paddingBottom: 24,
  },
  content: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    marginRight: 8,
    borderRadius: borderRadiuses.xxl,
    border: `1px solid ${palette.grey['400']}`,
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: '0 0 36px',
  },
  listItem: {
    display: 'flex',
    '&:not(:last-child)': {
      marginBottom: 12,
    },
  },
  topIcon: {
    display: 'block',
    maxWidth: 200,
    margin: '0 auto',
  },
  title: {
    maxWidth: 190,
    margin: '24px auto 12px',
    padding: 0,
    textAlign: 'center',
  },
  itemContent: {
    width: 'calc(100% - 40px)',
  },
}));
