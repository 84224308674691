import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './cookie-policy.styles';
import { ContentWrapper } from '../../../common/content-wrapper';
import { GeneralCookieSection, AppendixCookieSection } from './cooke-policy-sections';

export const CookiePolicy = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'cookiePolicyPage.title' })}
        </title>
        <meta name="description" content={
          intl.formatMessage({ id: 'cookiePolicyPage.description' })} />
      </Helmet>
      <ContentWrapper>
        <h1
          data-testid="cookie-title"
          className={classes.heading}
        >
          <FormattedMessage id="cookiePolicy.title" />
        </h1>
        <GeneralCookieSection />
        <AppendixCookieSection />
      </ContentWrapper>
    </div>
  );
};
