import { ReactNode } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './sign-up-banner.styles';
import { Button } from '../../../../common/button-new';
import { InternalLinks } from '../../../../../constants';
import signUpMobile from '../../../../../assets/images/sign-up-banner/sign-up-mobile.png';
import signUpTablet from '../../../../../assets/images/sign-up-banner/sign-up-tablet.png';
import signUp from '../../../../../assets/images/sign-up-banner/sign-up.png';

export const SignUpBanner = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <div className={classes.root} data-testid="slide">
      <div className={classes.inner}>
        <div className={classes.content} data-testid="slide-content">
          <h1 className={classes.title} data-testid="title">
            {intl.formatMessage(
              { id: 'signUpBanner.title' },
              {
                span: (chunks: ReactNode) => <span className={classes.newLine}>{chunks}</span>,
                sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
              },
            )}
          </h1>
          {isMobile && (
            <div className={classes.image} aria-hidden="true" data-testid="slide-img">
              <img src={signUpMobile} alt="" />
            </div>
          )}
          <Button
            data-testid="signup-button"
            to={InternalLinks.ecpLocator}
            style="light"
          >
            <FormattedMessage
              id="signUpBanner.button"
            />
          </Button>
        </div>
        {!isMobile && (
          <div className={classes.image} aria-hidden="true" data-testid="slide-img">
            <img src={isDesktop ? signUp : signUpTablet} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};
