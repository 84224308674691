import { FormattedMessage } from 'react-intl';

import {
  Modal,
  ResponsiveWidth,
} from '../../../../../../../common';
import { AddModalList } from './add-modal-list';
import { IAddModalProps } from './add-modal.models';
import { useStyles } from './add-modal.styles';

export const AddModal = ({ onCardSelect, products, onClose, ...props }: IAddModalProps): JSX.Element => {
  const classes = useStyles();

  const handleClose = () => {
    onClose!();
  };

  return (
    <Modal
      {...props}
      onClose={handleClose}
      data-testid="add-modal"
      title={<FormattedMessage id="add.modal.title" />}
      titleStyles={classes.title}
      responsiveWidth={ResponsiveWidth.mdWide}
      content={(
        <AddModalList
          products={products}
          onCardClick={onCardSelect}
          onModalClose={handleClose}
        />
      )}
    />
  );
};
