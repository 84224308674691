type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];

interface IGoogleMapOptions {
  googleMapsApiKey: string;
  libraries?: Libraries;
}

export const GOOGLE_MAP_OPTIONS: IGoogleMapOptions = {
  googleMapsApiKey: 'AIzaSyB-KzBPD8ky5HMoM-efq81IgXo0WQuvfE8',
  libraries: ['places'],
};
