import { useModal } from 'mui-modal-provider';

import { IPromotionCardProps } from './promotion-card.models';
import { DateExpiredLabel } from '../../../../../common/date-expired-label';
import { BonusCard } from '../../../../../common/bonus-card';
import { Icon, IconSizes } from '../../../../../common/icon';
import { getPromoIconType } from '../../../../../../utils/promotions/get-promo-icon-type';
import {
  FirstVoucherModal,
} from '../../../my-bonuses/modals/welcome-voucher-modal/first-voucher-modal/first-voucher-modal';
import { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomVoucherType } from '../../../../../../graphql/bonuses/models/get-bonuses.models';

export const PromotionCard: FC<IPromotionCardProps> = ({
  promotion, isFirstWelcomeVoucher, isAvailable = true, notClickable,
}) => {
  const { type, description, endDate, externalLink, value, voucherType } = promotion;
  const { showModal } = useModal();

  const handleWelcomeVouchersClick = () => {
    if (isFirstWelcomeVoucher) {
      showModal(FirstVoucherModal, { endDate });
    }
  };

  const title = useMemo(() => {
    return voucherType === CustomVoucherType.cashback ? (
        <FormattedMessage
          id="userActivities.discounts.cashBackCard.title"
          values={{ amount: value }}
        />
    ) : (
      <FormattedMessage
        id="userActivities.discounts.discountCard.title"
        values={{ amount: value }}
      />
    );
  }, [promotion]);

  return (
    <BonusCard
      data-testid="promotion-card"
      isAvailable={isAvailable}
      href={externalLink}
      cardTitle={title}
      description={description}
      onClick={handleWelcomeVouchersClick}
      bonusImage={(
        <Icon
          type={getPromoIconType(type, isAvailable)}
          size={IconSizes['3xl']}
        />
      )}
      footer={endDate && <DateExpiredLabel endDate={endDate as string} />}
      notClickable={notClickable}
    />
  );
};
