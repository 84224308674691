import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import bannerBackgroundUrl from '../../../../../../assets/images/backgrounds/lenses-not-fitted-banner.png';
import bannerBackgroundUrlTablet from '../../../../../../assets/images/backgrounds/lenses-not-fitted-banner-tablet.png';
import bannerBackgroundUrlDesktop from '../../../../../../assets/images/backgrounds/lenses-not-fitted-banner-desktop.png';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  root: {
    background: `url(${bannerBackgroundUrl}) right top no-repeat`,
    backgroundColor: palette.info.main,
    padding: '24px 16px 16px',
    marginBottom: 24,
    [breakpoints.up('md')]: {
      background: `url(${bannerBackgroundUrlTablet}) right top no-repeat`,
      backgroundColor: palette.info.main,
      padding: '24px 16px 10px',
      marginBottom: 32,
    },
    [breakpoints.up('lg')]: {
      background: `url(${bannerBackgroundUrlDesktop}) right top no-repeat`,
      backgroundColor: palette.info.main,
      padding: '50px 70px 24px',
    },
  },
  title: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.lg,
    color: palette.text.primary,
    width: '80%',
    margin: 0,
    marginBottom: 12,
    [breakpoints.up('md')]: {
      width: 320,
      lineHeight: lineHeights.xl,
    },
    [breakpoints.up('lg')]: {
      width: 420,
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      marginBottom: 16,
    },
  },
  description: {
    width: '80%',
    marginBottom: 12,
    [breakpoints.up('md')]: {
      width: 483,
    },
    [breakpoints.up('lg')]: {
      width: 570,
      marginBottom: 24,
    },
  },
  descriptionParagraph: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    color: palette.text.primary,
    margin: 0,
    marginBottom: 8,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
    },
  },
  ecpLink: {
    color: palette.text.primary,
  },
}));
