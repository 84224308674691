import { isEqual } from 'lodash';

import { TFormValue } from '../order-create-steps/add-lenses-step/add-lenses-step-form';
import { IProduct } from '../../../../../../graphql/order/models/get-orders.models';
import {
  getConcatFormValues,
} from '../order-create-steps/confirm-step/utils/get-concat-form-values';
import { IFormItemValues } from '../order-create-page.models';
import { EyeParamsType } from '../../../../../../graphql/lenses/models/get-lenses.models';

const formatStringEnding = (value: string): string => {
  let result: string = value;

  if (value.includes('.')) {
    const index = value.indexOf('.') + 1;

    if (value.slice(index) === '00') {
      result = value.slice(0, -3);
    }
  }

  return result;
};

export const updateRepeatQuantity = (products:  IProduct[], formValues: IFormItemValues[]) => {
  const values: TFormValue[] = formValues.map((value, index) => value[index]);
  let resultArray = [...values];

  const updatedProducts = products.map((product) => {
    const sameBrand = values.find((item) => item.brand === product.brand);
    const singlePoints = sameBrand?.singlePoints || '0';
    const id = `${sameBrand?.id}-${new Date().getTime()}`;
    const points = sameBrand?.points;
    const packSizes = sameBrand?.packSizes;

    return {
      brand: product.brand,
      productQuantity: String(product.productQuantity),
      packSize: String(product.packSize),
      eye: product.eye.map((eyeParams) => {
        if (eyeParams.property === EyeParamsType.power) {
          return {
            property: eyeParams.property,
            value: formatStringEnding(eyeParams.value),
          };
        } else {
          return {
            property: eyeParams.property,
            value: eyeParams.value,
          };
        }
      }),
      singlePoints,
      id,
      points,
      packSizes,
    };
  });

  updatedProducts.forEach((product) => {
    const sameProduct = values.find((item) => {
      return item.brand === product.brand && isEqual(item.eye, product.eye);
    });
    if (!sameProduct) {
      resultArray = [
        ...resultArray,
        product,
      ];
    } else {
      const sameProductIndex = values.findIndex((productItem) => {
        return productItem.brand === product.brand && isEqual(productItem.eye, product.eye);
      });

      resultArray[sameProductIndex] = product;
    }
  });

  const updatedResultArray = resultArray.map((item, index) => ({
    [`${index}`]: item,
  }));

  return getConcatFormValues(updatedResultArray);
};
