import { cx } from '@emotion/css';

import {
  LensesBrandType,
  SolutionsBrandType,
} from '../../../graphql/lenses/models/get-lenses.models';
import { IBrandLogoProps } from './lenses-brand-logo.models';
import { useStyles } from './lenses-brand-logo.styles';

import AOH1DPackage from '../../../assets/images/lenses-brands-packagings/aoh1d.png';
import ONEDATEPackage from '../../../assets/images/lenses-brands-packagings/1date.png';
import ONEDAMPackage from '../../../assets/images/lenses-brands-packagings/1dam.png';
import AOH1DFAPackage from '../../../assets/images/lenses-brands-packagings/aoh1dfa.png';
import ONEDAMFAPackage from '../../../assets/images/lenses-brands-packagings/1damfa.png';
import ONEDAMMPackage from '../../../assets/images/lenses-brands-packagings/1damm.png';
import AOHPackage from '../../../assets/images/lenses-brands-packagings/aoh.png';
import AOHFAPackage from '../../../assets/images/lenses-brands-packagings/aohfa.png';
import AOwTPackage from '../../../assets/images/lenses-brands-packagings/aowt.png';
import ONEDADFPackage from '../../../assets/images/lenses-brands-packagings/1dadf.png';
import AOHMPackage from '../../../assets/images/lenses-brands-packagings/aohm.png';
import AOM1DPackage from '../../../assets/images/lenses-brands-packagings/aom1d.png';
import ARLPackage from '../../../assets/images/solutions-brands-packagings/arl.png';
import AOMAXMFPackage from '../../../assets/images/lenses-brands-packagings/aomaxmf.png';

const packagingImageSources = {
  [LensesBrandType.aoh1d]: AOH1DPackage,
  [LensesBrandType.oneDate]: ONEDATEPackage,
  [LensesBrandType.oneDam]: ONEDAMPackage,
  [LensesBrandType.aoh1dfa]: AOH1DFAPackage,
  [LensesBrandType.oneDamfa]: ONEDAMFAPackage,
  [LensesBrandType.oneDamm]: ONEDAMMPackage,
  [LensesBrandType.aoh]: AOHPackage,
  [LensesBrandType.aohfa]: AOHFAPackage,
  [LensesBrandType.aowt]: AOwTPackage,
  [LensesBrandType.oneDadf]: ONEDADFPackage,
  [LensesBrandType.aohm]: AOHMPackage,
  [LensesBrandType.aom1d]: AOM1DPackage,
  [SolutionsBrandType.arl]: ARLPackage,
  [LensesBrandType.aomaxmf]: AOMAXMFPackage,
};

export const LensesBrandLogo = ({
  brand, className, ...props
}: IBrandLogoProps): JSX.Element => {
  const classes = useStyles();

  const imageSource = packagingImageSources[brand];

  return (
    <img
      {...props}
      className={cx(classes.root, className)}
      src={imageSource as string}
      alt=""
      data-testid="lenses-brand-logo"
      data-brand={brand}
    />
  );
};
