import { useQuery } from '@apollo/client';

import { GET_IS_USER_LOGGED_IN } from '../../../../graphql/user/queries';
import { MyAcuvueId } from './my-acuvue-id';

export const MyAcuvueIdContainer = (): JSX.Element => {
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);

  return (
    <>{isLoggedIn ? <MyAcuvueId /> : <></>}</>
  );
};
