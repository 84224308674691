import { makeStyles } from '@mui/styles';

import ellipse from '../../../../../assets/images/backgrounds/ellipse.png';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, lineHeights, fontWeights, palette,
}: Theme) => ({
  root: {
    background: `url(${ellipse}) 50% -390px no-repeat`,
    [breakpoints.up('sm')]: {
      backgroundPositionY: -310,
    },
    [breakpoints.up('md')]: {
      backgroundPositionY: -270,
    },
  },
  region: {
    width: '100%',
    maxWidth: 1140,
    padding: '24px 16px 32px',
    margin: '0 auto',
    [breakpoints.up('sm')]: {
      paddingTop: 32,
      paddingBottom: 40,
    },
    [breakpoints.up('lg')]: {
      padding: '40px 16px 64px',
    },
  },
  title: {
    margin: '0 0 24px',
    fontSize: fontSizes.xxl,
    lineHeight: lineHeights.xxl,
    fontWeight: fontWeights.medium,
    color: palette.text.secondary,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxxl,
      lineHeight: lineHeights.xxxl,
    },
    [breakpoints.up('lg')]: {
      margin: '0 0 32px',
    },
  },
}));
