import React, { FC } from 'react';
import { useQuery } from '@apollo/client';

import { IConsumerAppointments } from '../../../../graphql/appointments/models/get-appointments.models';
import { GET_APPOINTMENTS } from '../../../../graphql/appointments/queries/get-appointments';
import { IAppointmentSettingsProps } from '../appointment-settings.models';

export function loggedUserWrapper<T extends IAppointmentSettingsProps>(Component: FC<T>) {
  return (props: T): JSX.Element => {
    const { data, loading, error, refetch } = useQuery<IConsumerAppointments>(GET_APPOINTMENTS);

    return (
      <Component
        {...props}
        appointments={data}
        isLoading={loading}
        isError={error}
        updateAppointments={refetch}
      />
    );
  };
}
