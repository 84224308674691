import {
  SnackbarProvider as MuiSnackbarProvider,
  SnackbarProps as ISnackbarProps,
} from 'notistack';

import { useStyles } from './snackbar-provider.styles';

export const SnackbarProvider = ({ children }: ISnackbarProps): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiSnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      classes={{
        containerRoot: classes.containerRoot,
      }}
    >
      {children}
    </MuiSnackbarProvider>
  );
};
