import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormattedMessage } from 'react-intl';

import { IconSizes, IconTypes } from '../../../common/icon';
import { IconButton } from '../../../common/icon-button';
import { ICookieBanner } from './cookie-banner.models';
import { useStyles } from './cookie-banner.styles';
import { InternalLinks } from '../../../../constants/internal-links';

export const CookieBanner = ({ onCloseButtonClick }: ICookieBanner): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hidden = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div
      className={classes.root}
      data-testid="cookie-banner"
    >
      <div className={classes.content}>
        <div className={classes.container}>
          <div>
            <p className={classes.cookieDescription}>
              <FormattedMessage id="cookieBanner.description" />
              {!hidden && <br />}
              <a
                href={InternalLinks.cookiePage}
                target="_blank"
                className={classes.cookieLink}
              >
                <FormattedMessage id="cookieBanner.moreDetailsLink" />
              </a>
            </p>
          </div>
          <IconButton
            iconProps={{
              type: IconTypes.closeMenu,
              size: isMobile ? IconSizes.sm : IconSizes.md,
            }}
            onClick={onCloseButtonClick}
          />
        </div>
      </div>
    </div>
  );
};
