import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { useStyles } from './title.styles';
import { ITitleProps } from './title.models';

export const Title = ({ isAlternative }: ITitleProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>{isAlternative ? (
      <h2
        className={classes.headerTitle}
        data-testid="out-of-sales-modal-alt-title"
      >{
        intl.formatMessage(
          { id: 'outOfSalesModal.alt.title' },
          {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            span: (chunks: ReactNode) => <span className={classes.block}>{chunks}</span>,
          },
        )
      }</h2>
    ) : (
      <h2
        className={cx(classes.headerTitle, classes.headerTitleAdditional)}
        data-testid="out-of-sales-modal-title"
      >
        <FormattedMessage
          id="outOfSalesModal.title"
        />
      </h2>
    )}</>
  );
};
