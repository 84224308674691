import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  rootCampaign: {
    position: 'relative',
    overflow: 'visible',
  },
  bannerWrapperCampaign: {
    position: 'relative',
    zIndex: 1,
  },
  cardPlaceholderWrapper: {
    padding: '0 16px',
    marginBottom: 24,
    [breakpoints.up('md')]: {
      padding: '0 40px',
      marginBottom: 32,
    },
    [breakpoints.up('lg')]: {
      padding: '0 75px',
    },
  },
  campaignIcon: {
    marginRight: 4,
    [breakpoints.up('lg')]: {
      marginRight: 0,
    },
  },
}));
