import { useReactiveVar } from '@apollo/client';
import { cx } from '@emotion/css';

import { useStyles } from './my-lenses-not-fitted-content.styles';
import { CardPlaceholder } from './card-placeholder';
import { DiscountBanner } from './discount-banner';
import { CampaignIcon } from '../campaign-icon';
import {
  setIsLocatorIconsCampaign,
} from '../../../../../graphql/configuration/configuration.cache';

export const MyLensesNotFittedContent = (): JSX.Element => {
  const classes = useStyles();
  const isCampaignIcon = useReactiveVar(setIsLocatorIconsCampaign);

  return (
    <div
      className={cx({ [classes.rootCampaign]: isCampaignIcon })}
      data-testid="my-lenses-not-fitted"
    >
      {isCampaignIcon && <CampaignIcon externalStyles={classes.campaignIcon} />}
      <div
        data-testid="banner-wrapper"
        className={cx({ [classes.bannerWrapperCampaign]: isCampaignIcon })}
      >
        <DiscountBanner />
      </div>
      <div className={classes.cardPlaceholderWrapper}>
        <CardPlaceholder />
      </div>
    </div>
  );
};
