import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontWeights, fontSizes, lineHeights, breakpoints, siteMaxWidth,
}: Theme) => ({
  root: {
    paddingLeft: 16,
    paddingRight: 16,
    [breakpoints.up('md')]: {
      width: '100%',
      maxWidth: siteMaxWidth,
      margin: '0 auto',
    },
  },
  strong: {
    fontWeight: fontWeights.medium,
  },
  paragraph: {
    marginTop: 0,
    marginBottom: 16,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
    '&:last-of-type': {
      marginBottom: 36,
    },
  },
}));