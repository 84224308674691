import { gql } from '@apollo/client';

export const CREATE_APPOINTMENT = gql`
mutation CreateAppointmentMutation($date: String!, $storeCode: String!, $status: StatusInput!, $requestedTime: RequestedTimeInput!) {
    createAppointmentByStoreCode(appointment: { type: "Appointment", date: $date, storeCode: $storeCode, status: $status, requestedTime: $requestedTime}) {
      id
      requestedTime
      date
      time
      status
      consumerId
      createdDateTime
      store {
        id
        name
        storeType
        storeSubType
        country
        city
        phone
        workingHours
        address
        code
        specialties
        displayName
        latitude
        longitude
      }
      salesforceStoreInfo {
        rating
      }
    }
  }
`;
