import { createContext, Dispatch, useState, SetStateAction } from 'react';

import { IRefreshTokenProps } from './refresh-token.models';

export const RefreshTokenContext = createContext({
  isTokenRefreshing: false,
  setRefreshTokenState: (() => {}) as Dispatch<SetStateAction<boolean>>,
});

export const RefreshTokenProvider = ({ children }: IRefreshTokenProps): JSX.Element => {
  const [isTokenRefreshing, setRefreshTokenState] = useState(false);
  const { Provider } = RefreshTokenContext;

  return (
    <Provider value={{ isTokenRefreshing, setRefreshTokenState }}>
      {children}
    </Provider>
  );
};
