import { HTMLAttributes, useCallback, useMemo, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { UseFormTrigger } from 'react-hook-form';

import {
  WhatDoYouLikeTitle,
  WhatDoYouLikeView,
} from '../../vision-profile-views/what-do-you-like-view';
import { IWhatDoYouLikeModalProps } from './what-do-you-like-modal.models';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { IVisionProfileData, WhatDoYouLike } from '../../../../../../graphql/profile/models/get-vision-profile.models';
import { useCancellablePromise } from '../../../../../../hooks/use-cancellable-promise';
import { useActionsInProgress } from '../../../../../../graphql/preloader/actions/actions-in-progress';
import { promiseErrorCallbacks } from '../../../../../../utils/promise/set-promise-error-callbacks';
import { VpUpdateModal } from '../../../../../common/vp-update-modal';
import { GET_VISION_PROFILE } from '../../../../../../graphql/profile/queries/get-vision-profile';
import { UPDATE_VISION_PROFILE } from '../../../../../../graphql/profile/mutations';
import { TWhatDoYouLikeValues } from '../../vision-profile-views/what-do-you-like-view/what-do-you-like-form';
import { yup } from '../../../../../../services/yup';
import { ACTIVITY_MAX_LENGTH, oneTypeOfAlphabetEmptyRegex } from '../../../../../../constants';

export const WhatDoYouLikeModal = ({
  whatDoYouLikeCustom, whatDoYouLikePredefined, onClose, ...props
}: IWhatDoYouLikeModalProps): JSX.Element => {
  const intl = useIntl();
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const { updateQuery } = useQuery<IVisionProfileData>(GET_VISION_PROFILE);
  const [updateVisionProfile] = useMutation(UPDATE_VISION_PROFILE);
  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  
  const defaultValues = useMemo(() => ({ whatDoYouLikeCustom, whatDoYouLikePredefined }), []);

  const validationSchema = useMemo(() => yup.object({
    whatDoYouLikeCustom: yup
      .string()
      .nullable()
      .trim()
      .max(ACTIVITY_MAX_LENGTH, intl.formatMessage({ id: 'common.errors.max' }))
      .matches(oneTypeOfAlphabetEmptyRegex, { message: intl.formatMessage({
        id: 'common.errors.oneTypeOfAlphabet' }) })
      .when('whatDoYouLikePredefined', {
        is: (value: WhatDoYouLike[]) => !value.length,
        then: yup
          .string()
          .required(intl.formatMessage({ id: 'vp.whatDoYouLike.ownVariant.errors.emptyFields' })),
      }),
  }), [intl]);

  const handleActivitiesValidateDependencies = useCallback(
    (trigger: UseFormTrigger<TWhatDoYouLikeValues>) => trigger('whatDoYouLikeCustom'), [],
  );
  
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  
  const handleWhatDoYouLikeChange = async ({
    whatDoYouLikeCustom: whatDoYouLikeCustomCurrent,
    whatDoYouLikePredefined: whatDoYouLikePredefinedCurrent, 
  }: TWhatDoYouLikeValues) => {
    addActionInProgress();
  
    try {
      const { data: { patchVisionProfile } } = await makeCancellablePromise(
        updateVisionProfile({
          variables: {
            visionProfile: {
              whatDoYouLikeCustom: whatDoYouLikeCustomCurrent,
              whatDoYouLikePredefined: whatDoYouLikePredefinedCurrent, 
            },
          },
        }),
      );
  
      updateQuery((prevState) => ({
        visionProfile: {
          ...prevState.visionProfile,
          ...patchVisionProfile,
        },
      }));
  
      onClose!();
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }
  
      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    }
  
    removeActionInProgress();
  };
  
  return (
    <VpUpdateModal
      {...props}
      PaperProps={{
        'data-testid': 'vp-what-do-you-like-modal',
      } as HTMLAttributes<HTMLDivElement>}
      title={<WhatDoYouLikeTitle />}
      content={(
        <WhatDoYouLikeView
          ref={formRef}
          onActivitiesValidateDependencies={handleActivitiesValidateDependencies}
          formProps={{
            onSubmit: handleWhatDoYouLikeChange,
            defaultValues,
            validationSchema,
          }}
        />
      )}
      onVPUpdate={handleSubmit}
      onClose={onClose}
    />
  );
};
