import { makeVar } from '@apollo/client';

import {
  getBonusesTutorialFromStorage,
  getCookiePolicyFromStorage,
  getLensesTutorialFromStorage,
  getTokenFromStorage,
} from '../../utils/user';
import { IScreenState } from './models/screen-block.model';
import { TPhoneValues } from '../../components/business/app/registration';
import { IStepProps } from '../../components/common/stepper';

export const token = makeVar(getTokenFromStorage());
export const isLoggedIn = makeVar(!!token());

export const cookiePolicy = makeVar(getCookiePolicyFromStorage());
export const isCookiePolicyAgreed = makeVar(!!cookiePolicy());

export const lensesTutorial = makeVar(getLensesTutorialFromStorage());
export const isLensesTutorialShown = makeVar(!!lensesTutorial());

export const bonusesTutorial = makeVar(getBonusesTutorialFromStorage());
export const isBonusesTutorialShown = makeVar(!!bonusesTutorial());

export const screenState = makeVar<IScreenState>({
  isScreenBlocked: false,
  unblock: null,
});

export const setPhoneStepValues = makeVar<TPhoneValues | null>(null);

export const authErrors = makeVar<{ phoneNumberExists: boolean }>({
  phoneNumberExists: false,
});

export const vpSurveySteps = makeVar<IStepProps[]>([]);
