import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation UpdateUserProfile(
    $dateOfBirth: String,
    $myAcuvueId: String!,
    $username: String!,
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $gender : GenderInput,
    $consent: [ConsentInput],
    $commPermissions: [CommPermissionInput]!) {
      updateConsumer(consumer: {
      type: "Consumer",
      myAcuvueId: $myAcuvueId,
      username: $username,
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      gender: $gender,
      dateOfBirth: $dateOfBirth,
      consent: $consent,
      commPermissions: $commPermissions})
      {
        id
        myAcuvueId
        username
        firstName
        lastName
        email
        gender
        dateOfBirth
        mobile
        consent {
          consent
          date
          value
        }
        commPermissions,
      }
    }
`;
