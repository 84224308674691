import React, { useMemo, forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { useActionsInProgress } from '../../../../../../graphql/preloader/actions/actions-in-progress';
import { useCancellablePromise } from '../../../../../../hooks/use-cancellable-promise';
import { useFirstNameValidation } from '../../../../../../hooks/use-first-name-validation';
import { usePhoneNumberValidation } from '../../../../../../hooks/use-phone-number-validation';
import { consumerApi } from '../../../../../../rest/consumer';
import { yup } from '../../../../../../services/yup';
import { IFormImperativeHandleProps } from '../../../../../common/form';

import { UnregisteredUserForm as UnregisteredUserFormView } from './unregistered-user-form';
import { IUnregisteredUserFormProps, TValues } from './unregistered-user-form.models';

export const UnregisteredUserForm = forwardRef<
IFormImperativeHandleProps, IUnregisteredUserFormProps>(({
  onRequestSuccess,
}, ref): JSX.Element => {
  const intl = useIntl();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const firstNameValidation = useFirstNameValidation();
  const phoneNumberValidation = usePhoneNumberValidation();

  const handleSubmit = async ({ authoriseJnj, ...values }: TValues) => {
    const consent = [{
      consent: 'authoriseJnj',
      value: authoriseJnj!,
      date: new Date(),
    }];

    addActionInProgress();

    try {
      await makeCancellablePromise(consumerApi.callbackRequest({
        ...values,
        consent,
      }));

      onRequestSuccess();
      removeActionInProgress();
    } catch (error) {
      removeActionInProgress();

      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }
    }
  };

  const validationSchema = useMemo(() => yup.object({
    name: firstNameValidation,
    mobile: phoneNumberValidation,
    authoriseJnj: yup.bool()
      .oneOf([true], intl.formatMessage({ id: 'callback.modal.errors.authoriseJnj.required' })),
  }), [intl, firstNameValidation]);

  return (
    <UnregisteredUserFormView
      ref={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    />
  );
});
