import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  spacing,
  siteMaxWidth,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
}: Theme) => ({
  root: {
    position: 'fixed',
    // Should be higher than form label z-index
    zIndex: 2,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: palette.grey['200'],
  },
  content: {
    margin: '0 auto',
    maxWidth: siteMaxWidth,
    padding: spacing(1.5, 2),
    [breakpoints.up('md')]: {
      padding: spacing(4, 2.5),
    },
    [breakpoints.up('lg')]: {
      padding: spacing(3, 2.5),
    },
  },
  container: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    gap: spacing(2),
    fontFamily: `${fontFamilies.primary}`,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.primary,
    [breakpoints.up('md')]: {
      alignItems: 'center',
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    [breakpoints.up('lg')]: {
      fontSize: fontSizes.lg,
      lineHeight: lineHeights.xl,
    },
  },
  cookieDescription: {
    margin: 0,
  },
  cookieLink: {
    color: palette.primary.main,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
    [breakpoints.up('md')]: {
      marginLeft: spacing(2),
    },
    [breakpoints.up('lg')]: {
      marginLeft: spacing(3),
    },
  },
}));
