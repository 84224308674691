import React, { FC } from 'react';
import { useQuery } from '@apollo/client';

import { IConsumerLenses } from '../../../graphql/lenses/models/get-lenses.models';
import { GET_LENSES } from '../../../graphql/lenses/queries/get-lenses';
import { isUserFitted } from '../../../utils/lenses/is-user-fitted';
import { FittingProps } from './with-fitting-props.models';

export const withFittingProps = <T extends unknown>(Component: FC<T>) => (
  props: T & FittingProps,
): JSX.Element => {
  const { data, loading, error } = useQuery<IConsumerLenses>(GET_LENSES);
  const isFitted = isUserFitted(data);
  const isFittingsLoaded = !!data && !error;

  return (
    <Component
      {...props}
      isLoading={loading }
      isError={error}
      isFitted={isFitted}
      isFittingsLoaded={isFittingsLoaded}
    />
  );
};
