import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import ellipse from '../../../../assets/images/backgrounds/ellipse.png';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
  fontWeights,
}: Theme) => ({
  root: {
    paddingTop: 24,
    [breakpoints.up('md')]: {
      paddingTop: 32,
    },
    [breakpoints.up('lg')]: {
      paddingTop: 40,
    },
  },
  rootBackgroundFitted: {
    background: `url(${ellipse}) 50% -360px no-repeat`,
    [breakpoints.up('md')]: {
      background: `url(${ellipse}) 50% -280px no-repeat`,
    },
    [breakpoints.up('lg')]: {
      background: `url(${ellipse}) 50% -270px no-repeat`,
    },
  },
  rootBackgroundNotFitted: {
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '50%',
    backgroundPositionY: -280,
    backgroundImage: `url(${ellipse})`,
  },
  title: {
    margin: '0 0 24px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxl,
    lineHeight: lineHeights.xxl,
    fontWeight: fontWeights.medium,
    color: palette.text.secondary,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxxl,
      lineHeight: lineHeights.xxxl,
    },
    [breakpoints.up('lg')]: {
      margin: '0 0 32px',
    },
  },
}));
