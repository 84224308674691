import { HTMLAttributes, useCallback } from 'react';
import { CalendarPicker, MobileDatePicker } from '@mui/x-date-pickers';
import { InputAdornment as MuiInputAdornment } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { cx } from '@emotion/css';
import { isValid } from 'date-fns';

import { UI_DATE_FORMAT, UI_DATE_FORMAT_PLACEHOLDER } from '../../../constants';
import { toDate } from '../../../utils/date/to-date';
import { Icon, IconSizes, IconTypes } from '../icon';
import { TextField as TextFieldBase } from '../form/text-field';
import { useStyles } from './date-picker.styles';
import { TFormExtendedCommonProps } from '../form';
import { IDatePickerProps, TDatePickerTextField } from './date-picker.models';
import { Button } from '../button';
import { TTextFieldProps } from '../text-field';

export const DatePickerTextField = ({
  formError,
  formHelperText,
  dateOpenPicker,
  dateOpenPickerChange,
  InputProps,
  InputLabelProps,
  ...props
}: TDatePickerTextField) => {
  const classes = useStyles();

  const textFieldCommonProps: TTextFieldProps = {
    ...props as TTextFieldProps,
    InputProps: {
      ...InputProps,
    },
    InputLabelProps: {
      ...InputLabelProps,
    },
  };
  const { name } = props;

  if (dateOpenPicker) {
    textFieldCommonProps.InputProps!.endAdornment = (
      <MuiInputAdornment
        position="end"
        className={classes.inputAdornmentDate}
      >
        {dateOpenPicker}
      </MuiInputAdornment>
    );
  }

  if (dateOpenPickerChange) {
    textFieldCommonProps.InputProps!.endAdornment = (
      <MuiInputAdornment
        position="end"
        className={classes.inputAdornmentDate}
      >
        <Button
          data-testid="change-open-picker-button"
        >
          <FormattedMessage id="common.datePicker.change" />
        </Button>
      </MuiInputAdornment>
    );
  }
  return (
    <TextFieldBase
      {...textFieldCommonProps}
      error={formError}
      helperText={formHelperText}
      name={name as string}
    />
  );
};

export const DatePicker = ({
  value, onChange, error, helperText, variant, dateOpenPicker, dateOpenPickerChange,
  views, InputAdornmentProps, labelProps, name, inputRef, renderInput, dateOpenPickerEnable,
  inputFormat, ...rest
}: TFormExtendedCommonProps<IDatePickerProps>): JSX.Element => {
  const classes = useStyles();
  const { components } = rest;
  const handleChange = useCallback((newDate: unknown) => {
    let resultDate: Date | null;
    if (!newDate) {
      resultDate = newDate as Date;
    } else if (isValid(newDate)) {
      resultDate = toDate(newDate as Date);
    } else {
      resultDate = (newDate as any).toDate();
    }

    onChange(resultDate);
  }, [onChange]);

  return (
    <>
      {
        variant === 'static' ? (
          <CalendarPicker
            {...rest}
            className={cx(classes.calendar)}
            date={value || null}
            onChange={handleChange}
            views={['day']}
            components={{
              ...components,
              LeftArrowIcon: () => (
                <Icon
                  type={IconTypes.arrowLeft}
                  size={IconSizes.sm}
                />
              ),
              RightArrowIcon: () => (
                <Icon
                  type={IconTypes.arrowRight}
                  size={IconSizes.sm}
                />
              ),
            }}
          />
        ) : (
          <MobileDatePicker
            {...rest}
            renderInput={renderInput ? renderInput : (props) => (
              <DatePickerTextField
                {...props}
                placeholder={UI_DATE_FORMAT_PLACEHOLDER}
                formHelperText={helperText}
                fullWidth
                dateOpenPicker={dateOpenPickerEnable
                  ? <Icon type={IconTypes.datePicker} size={IconSizes.xsm} /> : dateOpenPicker}
                dateOpenPickerChange={dateOpenPickerChange}
                className={cx(
                  { [classes.inputWithAdornment]: !!dateOpenPicker || dateOpenPickerChange },
                )}
                InputLabelProps={labelProps}
                name={name}
              />
            )}
            InputAdornmentProps={InputAdornmentProps}
            inputFormat={inputFormat || UI_DATE_FORMAT}
            value={value || null}
            onChange={handleChange}
            views={views ? views : ['year', 'month', 'day']}
            DialogProps={{
              'data-testid': 'date-picker',
              classes: {
                root: cx(classes.calendar, classes.actionButtons),
                paper: classes.paper,
              },
            } as HTMLAttributes<HTMLDivElement>}
            components={{
              ...components,
              LeftArrowIcon: () => (
                <Icon
                  type={IconTypes.arrowLeft}
                  size={IconSizes.sm}
                />
              ),
              RightArrowIcon: () => (
                <Icon
                  type={IconTypes.arrowRight}
                  size={IconSizes.sm}
                />
              ),
            }}
            orientation="portrait"
            OpenPickerButtonProps={{
              className: classes.changeButton,
            }}
          />
        )
      }
    </>
  );
};
