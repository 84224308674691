import React from 'react';
import { cx } from '@emotion/css';
import { capitalize } from 'lodash';

import { useStyles } from './icon.styles';
import { IIconProps } from './icon.models';
import { icons } from './icons';

export const Icon = ({ type, size, className }: IIconProps) => {
  const classes = useStyles();

  const IconComponent = icons[type];

  const sizeClass = classes[
    `rootSize${capitalize(size as string)}` as
        | 'rootSizeXs'
        | 'rootSizeSm'
        | 'rootSizeMd'
        | 'rootSizeLg'
        | 'rootSizeXl'
        | 'rootSizeXxl'
        | 'rootSize3xl'
  ];

  return (
    <IconComponent
      className={cx(classes.root, sizeClass, className)}
      data-testid={`icon-${type}`}
    />
  );
};

Icon.defaultProps = {
  size: 'sm',
} as Partial<IIconProps>;
