import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  ReactNode,
} from 'react';
import { useIntl } from 'react-intl';

import { EmailStepForm } from './email-step-form';
import { yup } from '../../../../../../services/yup';
import {
  useEmailValidation,
} from '../../../../../../hooks/use-email-validation';
import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import {
  IStepsState,
  TEmailFormValues,
  RegistrationErrorCodes,
} from '../../registration.models';
import { useBind } from '../../../../../../hooks/use-bind';
import { oauthApi } from '../../../../../../rest/oauth';
import { useCancellablePromise } from '../../../../../../hooks/use-cancellable-promise';
import { useSnackbar } from '../../../../../../hooks/use-snackbar';
import { useActionsInProgress } from '../../../../../../graphql/preloader/actions/actions-in-progress';
import { getAuthorizationHeader } from '../../../../../../utils/request-headers/get-authorization-header';

export const EmailStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  stepsState,
  onGoNextFail,
  onGoNext,
  isNavigationAllowed,
}, ref): JSX.Element => {
  const intl = useIntl();
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const stepsStateRegistration = stepsState as IStepsState;
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const { enqueueSnackbar } = useSnackbar();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const emailValidation = useEmailValidation();

  const validationSchema = useMemo(() => yup.object({
    email: emailValidation,
  }), [emailValidation]);

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return formRef.current!.getValues();
    },
  }));

  const defaultValues = useMemo(
    () => stepsStateRegistration.email && stepsStateRegistration.email.formValues,
    [stepsStateRegistration.email],
  );

  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const onGoNextSuccessBind = useBind(onGoNextSuccess);
  const onGoNextFailBind = useBind(onGoNextFail);
  const onGoNextBind = useBind(onGoNext);
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const isNavigationAllowedBind = useBind(isNavigationAllowed);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);

  const handleEmailExist = useCallback(() => {
    enqueueSnackbarBind.current(
      intl.formatMessage(
        { id: 'registration.emailStep.errors.email.exist' },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      ),
      { variant: 'error' },
    );
  }, [enqueueSnackbarBind]);

  const handleSubmit = useCallback(async (formValues: TEmailFormValues) => {
    if (!isNavigationAllowedBind.current) {
      return;
    }

    onGoNextBind.current();
    addActionInProgressBind.current();

    try {
      const { data: token } = await makeCancellablePromiseBind.current(
        oauthApi.registerEmail({
          email: formValues.email,
        }, {
          headers: {
            Authorization: getAuthorizationHeader(stepsStateRegistration.phone.token.access_token),
          },
        }),
      );

      removeActionInProgressBind.current();
      onGoNextSuccessBind.current({
        formValues,
        token,
      });
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        return;
      }

      if (error.response) {
        const { status, data: { errors: { 0: { code } } } } = error.response;

        switch (status) {
          case 403:
            if (code === RegistrationErrorCodes.emailExist) {
              handleEmailExist();
            }
            break;
          default:
            break;
        }
      }

      onGoNextFailBind.current();
    }
  }, [
    onGoNextBind,
    onGoNextSuccessBind,
    onGoNextFailBind,
    isNavigationAllowedBind,
    enqueueSnackbarBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    addActionInProgressBind,
    removeActionInProgressBind,
  ]);

  return (
    <EmailStepForm
      ref={formRef}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultValues={defaultValues}
    />
  );
});
