import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Icon, IconTypes } from '../../../../../../../common';
import { useStyles } from './add-button.styles';
import { AddModal } from '../add-modal';
import { useModal } from 'mui-modal-provider';
import { IAddButtonProps } from './add-button.models';

export const AddButton = ({ products, onCardClick }: IAddButtonProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const { showModal } = useModal();

  const handleAdd = () => {
    showModal(AddModal, {
      products,
      onCardSelect: onCardClick,
    });
  };

  return (
    <Button
      variant="text"
      data-testid="add-button"
      aria-label={intl.formatMessage({ id: 'order.create.add.addButton.ariaLabel' })}
      className={classes.button}
      onClick={handleAdd}
    >
      <span
        data-testid="icon-wrapper"
        aria-hidden="true"
        className={classes.icon}
      >
        <Icon type={IconTypes.add} />
      </span>
      <span
        data-testid="add-button-content"
      >
        <FormattedMessage id="order.create.confirm.addButton.label" />
      </span>
    </Button>
  );
};
