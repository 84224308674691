import React from 'react';
import { useQuery } from '@apollo/client';

import { useCookiePolicy } from '../../../../graphql/user/actions/cookiePolicy';
import { GET_IS_COOKIE_POLICY_AGREED } from '../../../../graphql/user/queries';
import { CookieBanner as CookieBannerView } from './cookie-banner';

export const CookieBanner = (): JSX.Element => {
  const { data: { user: { isCookiePolicyAgreed } } } = useQuery(GET_IS_COOKIE_POLICY_AGREED);

  const { setCookiePolicyAgreed } = useCookiePolicy();

  const handleCloseButtonClick = () => {
    setCookiePolicyAgreed();
  };

  return (
    isCookiePolicyAgreed
      ? null
      : <CookieBannerView onCloseButtonClick={handleCloseButtonClick} />
  ) as JSX.Element;
};
