import { ChangeEvent, useCallback } from 'react';
import { useFormContext, useController } from 'react-hook-form';

import { Checkbox as CheckboxBase, ICheckboxProps } from '../../checkbox';
import { TFormExtendedProps, IAdditionalFormProps } from '..';

export const Checkbox = ({
  name,
  helperText,
  ...props
}: TFormExtendedProps<ICheckboxProps>): JSX.Element => {
  const { control, formState: { errors } } = useFormContext();
  const {
    field: {
      ref, value, onChange, ...restField
    },
    fieldState: { invalid },
  } = useController({
    name,
    control,
  });
  const additionalProps: IAdditionalFormProps = {};

  if (invalid) {
    additionalProps.error = true;
    additionalProps.helperText = errors[name]?.message;
  } else if (helperText) {
    additionalProps.helperText = helperText;
  }

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked), [onChange],
  );

  return (
    <CheckboxBase
      {...props}
      {...restField}
      {...additionalProps}
      onChange={handleChange}
      checked={value}
    />
  );
};
