import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ConfirmationCodeStep } from './change-phone-steps/confirmation-code-step';
import { ConfirmationCodeStepDescription } from './change-phone-steps/confirmation-code-step/confirmation-code-step-description';
import { PhoneStep } from './change-phone-steps/phone-step';
import { IStepProps } from '../../../../../common/stepper';

export const changePhoneSteps: IStepProps[] = [
  {
    title: (
      <span data-testid="phone-step-title">
        <FormattedMessage id="changePhone.phoneStep.title" />
      </span>
    ),
    StepComponent: PhoneStep,
    id: 'phone',
  },
  {
    title: (
      <span data-testid="confirmation-code-step-title">
        <FormattedMessage id="changePhone.confirmationCodeStep.title" />
      </span>
    ),
    description: ConfirmationCodeStepDescription,
    StepComponent: ConfirmationCodeStep,
    nextButtonLabel: <FormattedMessage id="common.activationButton.label" />,
    id: 'confirmationCode',
  },
];
