import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, palette, borderRadiuses, fontSizes, lineHeights, fontWeights, fontFamilies }: Theme) => ({
  title: {
    position: 'relative',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    color: palette.text.secondary,
    margin: '0 0 8px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      marginBottom: 16,
    },
  },
  description: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.lg,
    color: palette.text.primary,
    margin: '0 0 24px',
    [breakpoints.up('md')]: {
      lineHeight: lineHeights.md,
      marginBottom: 32,
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    background: palette.grey.A100,
    padding: '20px 12px',
    borderRadius: 12,
    marginTop: 0,
    marginBottom: 24,
    [breakpoints.up('sm')]: {
      borderRadius: borderRadiuses.lg,
      gap: 20,
      padding: '32px 24px',
      marginBottom: 48,
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    [breakpoints.up('sm')]: {
      gap: 12,
    },
  },
  text: {
    width: 'calc(100% - 44px)',
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    [breakpoints.up('sm')]: {
      width: 44,
      height: 44,
    },
    '& svg': {
      width: 28,
      height: 28,
      [breakpoints.up('sm')]: {
        width: 44,
        height: 44,
      },
    },
  },
}));
