import { forwardRef, useMemo } from 'react';

import { ITimeOutsideDurationFormProps } from './time-outside-duration-form.models';
import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { RadioGroup } from '../../../../../../common/form/radio-group';
import {
  timeOutsideDurationOptions as timeOutsideDurationOptionsBase,
} from './time-outside-duration-options';

export const TimeOutsideDurationForm = forwardRef<
IFormImperativeHandleProps, ITimeOutsideDurationFormProps>(({
  formProps,
}, ref): JSX.Element => {
  const timeOutsideDurationOptions = useMemo(() => timeOutsideDurationOptionsBase, []);

  return (
    <Form
      ref={ref}
      {...formProps}
    >
      <RadioGroup
        name="timeOutsideDuration"
        options={timeOutsideDurationOptions}
      />
    </Form>
  );
});
