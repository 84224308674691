import { forwardRef, MutableRefObject } from 'react';

import {
  Form,
  IFormImperativeHandleProps,
} from '../../../../../../../common/form';
import { OrderCreateCard } from '../../../order-create-card';
import { IAddLensesFormProps } from './add-lenses-step-form.models';
import { useStyles } from './add-lenses-step-form.styles';
import { SolutionsBrandType } from '../../../../../../../../graphql/lenses/models/get-lenses.models';
import { getParamsByCard } from '../utils/get-params-by-card';

export const AddLensesStepForm = forwardRef<IFormImperativeHandleProps, IAddLensesFormProps>(({
  consumerProducts,
  formProps,
  brands,
  onIncreasePackageValue,
  onDecreasePackageValue,
  stepState,
}, ref): JSX.Element => {
  const classes = useStyles();
  const formRef = ref as MutableRefObject<IFormImperativeHandleProps>;

  return (
    <Form
      {...formProps}
      ref={ref}
      data-testid="create-order-form"
    >
      <ul
        className={classes.list}
        data-testid="cards-list"
        id="consumer-products"
      >
        {consumerProducts?.length && consumerProducts.map(({
          brand,
          id,
          packSizes,
          points,
          eye,
          productQuantity,
          packSize,
        }, index) => (
          <li
            key={id}
            className={classes.item}
            data-testid="cards-list-item"
          >
            <OrderCreateCard
              ref={formRef}
              index={index}
              brand={brand}
              eye={eye}
              packSizes={packSizes!}
              points={points!}
              brandParameters={brand === SolutionsBrandType.arl ? undefined : getParamsByCard(brand, brands)}
              onIncreasePackageValue={onIncreasePackageValue}
              onDecreasePackageValue={onDecreasePackageValue}
              stepState={stepState}
              productQuantity={Number(productQuantity)}
              packSize={Number(packSize)}
              id={id!}
            />
          </li>
        ))}
      </ul>
    </Form>
  );
});
