import { FormattedMessage, useIntl } from 'react-intl';

import { IPointsProps } from './points.models';
import { useStyles } from './points.styles';
import { IconSizes, IconTypes, Tooltip } from '../../../../../../common';
import { IconButton } from '../../../../../../common/icon-button';

export const Points = ({ value, tooltipValue }: IPointsProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div
      className={classes.root}
      data-testid="points-bar"
    >
      <div
        className={classes.pointsValue}
        data-testid="points-value"
      >
        <FormattedMessage
          id="order.create.points"
          values={{
            value,
          }}
        />
      </div>
      <Tooltip
        placement="bottom-end"
        title={(
          <p
            data-testid="tooltip-content"
            className={classes.tooltipContent}
          >
            <FormattedMessage
              id="order.create.points.tooltip"
              values={{
                value: tooltipValue,
              }}
            />
          </p>
        )}
        withArrow
        tooltipClass={classes.tooltip}
      >
        <IconButton
          data-testid="tooltip-button"
          aria-label={intl.formatMessage({
            id: 'order.create.points.tooltip.button.ariaLabel',
          })}
          iconProps={{
            type: IconTypes.info,
            size: IconSizes.xs,
          }}
        />
      </Tooltip>
    </div>
  );
};
