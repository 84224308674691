import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import star from '../../../../../assets/images/backgrounds/star.svg';

export const useStyles = makeStyles(({
  fontWeights,
  fontFamilies,
  palette,
  fontSizes,
  breakpoints,
  lineHeights,
  borderRadiuses,
}: Theme) => ({
  root: {
    marginBottom: 80,
    [breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  title: {
    position: 'relative',
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      fontSize: '21px',
      lineHeight: lineHeights.xl,
    },
  },
  titleButton: {
    display: 'block',
    position: 'relative',
    padding: '0 0 0 25px',
    border: 'none',
    background: 'transparent',
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    textAlign: 'left',
    [breakpoints.up('sm')]: {
      fontSize: '21px',
      lineHeight: lineHeights.xl,
    },
    [breakpoints.up('xl')]: {
      padding: 0,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  backButton: {
    position: 'absolute',
    paddingRight: 5, // to cover gap between title & icon
    top: -4,
    left: -10,
    [breakpoints.up('md')]: {
      top: -2,
      left: -36,
    },
  },
  content: {
    marginBottom: 16,
    [breakpoints.up('sm')]: {
      display: 'flex',
      marginBottom: 20,
    },
    [breakpoints.up('xl')]: {
      flexDirection: 'column',
    },
  },
  contentWithBorder: {
    paddingBottom: 16,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderColor: palette.grey['200'],
    [breakpoints.up('sm')]: {
      paddingBottom: 20,
    },
  },
  contentPartial: {
    [breakpoints.up('sm')]: {
      width: '47%',
    },
    [breakpoints.up('xl')]: {
      width: 'unset',
    },
    '&:nth-of-type(2)': {
      [breakpoints.up('sm')]: {
        marginLeft: '3vh',
      },
      [breakpoints.up('xl')]: {
        marginLeft: 0,
      },
    },
  },
  ratingWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '0 0 8px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  rating: {
    paddingLeft: 20,
    backgroundImage: `url(${star})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '16px 16px',
    backgroundPosition: 'left center',
  },
  pointSeparator: {
    position: 'relative',
    paddingRight: 10,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: 3,
      top: '50%',
      transform: 'translateY(-50%)',
      height: 2,
      width: 2,
      borderRadius: borderRadiuses.xxl,
      background: palette.text.primary,
    },
  },
  street: {
    position: 'relative',
    paddingLeft: 28,
    margin: '0 0 6px',
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '& svg': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: 22,
      margin: '-2px 8px 0 -2px',
    },
  },
  phoneWrapper: {
    position: 'relative',
    paddingLeft: 28,
    marginBottom: 0,
    '& svg': {
      position: 'absolute',
      left: 0,
      top: 0,
      marginRight: 12,
      marginTop: 2,
    },
  },
  phone: {
    margin: 0,
    fontFamily: fontFamilies.primary,
    color: palette.primary.main,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    textDecoration: 'none',
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      outline: '1px dotted',
      outlineColor: palette.primary.main,
    },
  },
  hours: {
    position: 'relative',
    paddingLeft: 28,
    maxWidth: 171,
    margin: '0 0 6px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      maxWidth: 193,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '& svg': {
      position: 'absolute',
      left: 0,
      top: 0,
      marginRight: 12,
      marginTop: 2,
    },
  },
  pointsOnly: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 6px',
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '& svg': {
      marginLeft: -2,
      marginRight: 7,
    },
  },
  payCard: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 6px',
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '& svg': {
      width: 18,
      marginRight: 10,
    },
  },
  vendingLink: {
    display: 'flex',
    width: 'fit-content',
    marginTop: 20,
    padding: '11px 24px',
    borderRadius: 60,
    color: palette.grey.A100,
    backgroundColor: palette.primary.main,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    textDecoration: 'none',
    [breakpoints.up('md')]: {
      padding: '11px 60px',
      lineHeight: lineHeights.md,
    },
    '&:hover': {
      backgroundColor: palette.primary.light,
      color: palette.common.white,
    },
    '&:active': {
      backgroundColor: palette.action.active,
    },
  },
  callToAction: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    marginTop: 20,
    [breakpoints.up('lg')]: {
      flexDirection: 'column',
    },
  },
  orderButtonWrapper: {
    marginTop: '0.75rem',
    '& a, & button': {
      minWidth: 261,
      [breakpoints.up('sm')]: {
        minWidth: 278,
      },
    },
  },
}));
