import { useMemo } from 'react';

import { Lenses } from '../lenses';
import { Bonuses } from '../bonuses';
import { Delivery } from '../delivery';
import { getFormPackages, getOrderPoints } from '../utils';
import {
  TFormValue,
} from '../../../order/order-create-page/order-create-steps/add-lenses-step/add-lenses-step-form';
import { IOrderEditProps } from './order-edit.models';

export const OrderEdit = ({
  storeData, products, onStoreChange, onUpdateQuantity, onBonusSelect, bonus,
  bonusId, onProductsChange,
}: IOrderEditProps): JSX.Element => {
  const points = useMemo(() => getOrderPoints(products as TFormValue[]), [products]);

  return (
    <>
      <Lenses
        products={products}
        isEditMode
        onUpdateQuantity={onUpdateQuantity}
        onProductsChange={onProductsChange}
      />
      <Bonuses
        packageNumber={getFormPackages(products as TFormValue[])}
        points={points}
        isActiveStatus
        onBonusSelect={onBonusSelect}
        bonus={bonus}
        bonusId={bonusId}
        isEditOrder
      />
      <Delivery
        storeData={storeData}
        isEditMode
        onStoreChange={onStoreChange}
        isActiveStatus
      />
    </>
  );
};
