import React, { useMemo, useCallback, ReactNode } from 'react';
import {
  useSnackbar as useNotistackSnackbar,
  OptionsObject,
} from 'notistack';

import { Alert } from '../components/common/alert';

export const useSnackbar = () => {
  const snackbarMethods = useNotistackSnackbar();

  const enqueueSnackbar = useCallback((
    message: ReactNode,
    options?: OptionsObject & Partial<{ variant: 'success' | 'error' | 'warning' }>,
  ) => {
    snackbarMethods.enqueueSnackbar(message, {
      ...options,
      content: () => {
        const { variant } = options || { variant: undefined };

        return (
          <Alert
            severity={variant}
            data-testid={variant ? `${variant}-snackbar` : 'snackbar'}
          >
            {message}
          </Alert>
        );
      },
    });
  }, [snackbarMethods]);

  return useMemo(() => ({
    ...snackbarMethods,
    enqueueSnackbar,
  }), [snackbarMethods, enqueueSnackbar]);
};
