import { LensesBrandLogo } from '../../../../../../common/lenses-brand-logo';
import { SolutionsBrandLogo } from '../../../../../../common/solutions-brand-logo';
import { IBonusImageProps } from './bonus-image.models';
import { useStyles } from './bonus-image.styles';
import {
  LensesBrandType,
  ProductType,
  SolutionsBrandType,
} from '../../../../../../../graphql/lenses/models/get-lenses.models';

export const BonusImage = ({ type, brand, packSize }: IBonusImageProps): JSX.Element => {
  const classes = useStyles();

  return (type === ProductType.lenses
    ? (
      <LensesBrandLogo
        className={classes.root}
        brand={brand as LensesBrandType}
      />
    )
    : (
      <SolutionsBrandLogo
        className={classes.root}
        brand={brand as SolutionsBrandType}
        packSize={packSize}
      />
    )
  );
};
