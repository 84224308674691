import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  palette, fontWeights, breakpoints,
}: Theme) => ({
  root: {
    listStyle: 'none',
    margin: 0,
    paddingLeft: 0,
    [breakpoints.up('md')]: {
      marginTop: 24,
    },
  },
  item: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 16,
    [breakpoints.up('md')]: {
      marginBottom: 24,
    },
    '& svg': {
      marginRight: 20,
      height: 'auto',
    },
    '&:first-child': {
      '& svg': {
        marginLeft: 11,
        width: 31,
        [breakpoints.up('md')]: {
          width: 44,
          marginLeft: 18,
        },
      },
    },
    '&:last-child': {
      '& svg': {
        width: 42,
        [breakpoints.up('md')]: {
          width: 62,
        },
      },
    },
  },
  strongText: {
    fontWeight: fontWeights.medium,
  },
  partial: {
    marginTop: 0,
    marginBottom: 0,
  },
  partialWithMargin: {
    marginTop: 0,
    marginBottom: 8,
  },
  inline: {
    display: 'inline',
  },
  innerLink: {
    color: palette.primary.dark,
  },
  bonusesList: {
    margin: 0,
    paddingLeft: 24,
    listStyleType: 'disc',
  },
  bonusesItem: {
    marginBottom: 12,
    [breakpoints.up('md')]: {
      marginBottom: 8,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  second: {
    width: 'calc(100% - 62px)',
    [breakpoints.up('md')]: {
      width: 'calc(100% - 82px)',
    },
  },
}));
