import { ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from './first-voucher-modal.styles';
import { BonusModal } from '../../bonus-modal';
import discount300  from '../../../../../../../assets/images/icons/discount/discount-300.svg';
import { FIRST_WELCOME_VOUCHER_VALUE } from '../../../../../../../constants';
import { IFirstVoucherModalProps } from './first-voucher-modal.models';

export const FirstVoucherModal = ({
  value, endDate, ...props
}: IFirstVoucherModalProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const voucherValue = value || FIRST_WELCOME_VOUCHER_VALUE;

  const steps = useMemo(
    () => {
      const firstStep = intl.formatMessage(
        { id: 'promotions.firstVoucher.modal.firstMessage' },
        {
          sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
          endDate,
        },
      );

      const secondStep = intl.formatMessage(
        { id: 'promotions.firstVoucher.modal.secondMessage' },
        {
          endDate,
          value: voucherValue,
        },
      );

      const thirdStep = intl.formatMessage(
        { id: 'promotions.firstVoucher.modal.thirdMessage' },
        {
          value: voucherValue,
        },
      );

      return [firstStep, secondStep, thirdStep];
    }, [voucherValue, endDate],
  );

  return (
    <BonusModal
      {...props}
      data-testid="first-voucher-modal"
      image={(
        <img
          src={discount300}
          alt=""
          className={classes.image}
        />
      )}
      steps={steps}
      title={intl.formatMessage(
        { id: 'promotions.firstVoucher.modal.title' },
        {
          linebreak: <br />,
          value: voucherValue,
          sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
        },
      )}
    />
  );
};
