import { useMutation } from '@apollo/client';

import { IPurchasesBanModalProps } from './purchases-ban-modal.models';
import { PurchasesBanModal as PurchasesBanModalView } from './purchases-ban-modal';
import { UPDATE_NOTIFICATION } from '../../../../../../../graphql/notifications/mutations/update-notification';
import { useActionsInProgress } from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { useCancellablePromise } from '../../../../../../../hooks/use-cancellable-promise';
import { promiseErrorCallbacks } from '../../../../../../../utils/promise/set-promise-error-callbacks';

export const PurchasesBanModal = ({
  activeBanNotificationIds, updateNotifications: updateNotificationsInCache, onClose, ...props
}: IPurchasesBanModalProps): JSX.Element => {
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const handleBanModalClose = async () => {
    try {
      addActionInProgress();

      for (let notificationId of activeBanNotificationIds) {
        await makeCancellablePromise(
          updateNotification({
            variables: {
              notificationId,
            },
          }),
        );
      }
      
      updateNotificationsInCache(activeBanNotificationIds);
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    } finally {
      removeActionInProgress();
      onClose!();
    }
  };

  return (
    <PurchasesBanModalView
      {...props}
      onClose={handleBanModalClose}
    />
  );
};
