import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  breakpoints, palette,
}: Theme) => ({
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    paddingTop: 16,
    paddingBottom: 16,
    borderBottom: `1px solid ${palette.grey['200']}`,
    [breakpoints.up('sm')]: {
      paddingTop: 24,
      paddingBottom: 24,
    },
    '&:first-child': {
      paddingTop: 0,
    },
  },
}));
