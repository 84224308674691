import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import balloon from '../../../../assets/images/reg-guide/steps-bg.png';

export const useStyles = makeStyles(({
  fontWeights, fontSizes, palette, breakpoints, siteMaxWidth,
}: Theme) => ({
  root: {
    paddingLeft: 16,
    paddingRight: 16,
    [breakpoints.up('md')]: {
      width: '100%',
      maxWidth: siteMaxWidth,
      margin: '0 auto',
    },
  },
  title: {
    marginTop: 0,
    marginBottom: 16,
    color: palette.info.dark,
    fontSize: fontSizes.lg,
    lineHeight: 1.35,
    fontWeight: fontWeights.medium,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      marginBottom: 34,
      fontSize: 22,
    },
    [breakpoints.up('lg')]: {
      fontSize: 32,
    },
    [breakpoints.up('xl')]: {
      fontSize: 36,
    },
  },
  paragraph: {
    margin: 0,
    color: palette.info.dark,
    fontSize:fontSizes.md,
    lineHeight: 1.3,
    textAlign: 'center',
    [breakpoints.up('md')]:{
      fontSize: fontSizes.xxl,
    },
  },
  emphasis: {
    color: '#a51890',
    fontSize: 26,
    [breakpoints.up('md')]: {
      fontSize: 30,
    },
    [breakpoints.up('lg')]: {
      fontSize: 46,
    },
  },
  newLine: {
    display: 'block',
  },
  stepsWrapper: {
    paddingTop: 36,
    backgroundImage: `url(${balloon})`,
    backgroundSize: 'auto 60px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '99%',
    backgroundPositionY: '52%',
    [breakpoints.up('md')]: {
      backgroundPositionY: 'top',
      backgroundPositionX: 'center',
    },
    [breakpoints.up('lg')]: {
      paddingTop: 52,
      backgroundSize: '100px auto',
    },
    [breakpoints.up(1180)]: {
      backgroundPositionX: '100%',
    },
    [breakpoints.up(1400)]: {
      backgroundSize: '211px auto',
    },
  },
}));
