import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { yup } from '../services/yup';
import { oneTypeOfAlphabetRegex } from '../constants';

export const useFirstNameValidation = () => {
  const intl = useIntl();

  return useMemo(
    () => yup.string()
      .trim()
      .required(intl.formatMessage({ id: 'registration.nameStep.firstName.required' }))
      .matches(oneTypeOfAlphabetRegex, { message: intl.formatMessage({ id: 'common.errors.oneTypeOfAlphabet' }) })
      .min(2, intl.formatMessage({ id: 'common.errors.firstName.minMax' }, { min: 2, max: 20 }))
      .max(20, intl.formatMessage({ id: 'common.errors.firstName.minMax' }, { min: 2, max: 20 })),
    [intl],
  );
};
