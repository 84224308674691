import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, lineHeights, fontWeights,
}: Theme) => ({
  title: {
    marginTop: 32,
    marginBottom: '1.5rem',
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      marginTop: 40,
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
  },
  descriptionWrapper: {
    marginBottom: 44,
    [breakpoints.up('sm')]: {
      marginBottom: 64,
    },
  },
  description: {
    display: 'inline',
    marginTop: 0,
    marginBottom: 0,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      display: 'block',
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:not(:last-of-type)': {
      marginBottom: '0.75rem',
    },
  },
  icon: {
    textAlign: 'center',
    '& svg': {
      width: 160,
      height: 160,
      [breakpoints.up('sm')]: {
        width: 218,
        height: 218,
      },
    },
  },
}));
