import { FormattedMessage } from 'react-intl';

import { useStyles } from './ecp-step-title.styles';
import { IconButton } from '../../../../../../../common/icon-button';
import { IconSizes, IconTypes } from '../../../../../../../common';
import { Breakpoints } from '../../../../../../../../themes/default';
import { IEcpStepTitleProps } from './ecp-step-title.models';

export const EcpStepTitle = ({ onGoBack }: IEcpStepTitleProps): JSX.Element => {
  const classes = useStyles();

  const width = window.innerWidth;
  const isTablet = width > Breakpoints.md || width === Breakpoints.md;

  return (
    <h3
      className={classes.title}
      data-testid="ecp-step-title"
    >
      <IconButton
        onClick={onGoBack}
        iconProps={{
          type: IconTypes.arrowLeft,
          size: isTablet ? IconSizes.md : IconSizes.sm,
        }}
        className={classes.backButton}
        data-testid="back-button"
      />
      <span data-testid="title-content">
        <FormattedMessage id="order.create.title.ecp" />
      </span>
    </h3>
  );
};
