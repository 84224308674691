import React from 'react';

import { Card } from '../../card';
import { IHowToCard } from './how-to-card.models';
import { useStyles } from './how-to-card.styles';

export const HowToCard = ({ imageSource, description }: IHowToCard): JSX.Element => {
  const classes = useStyles();

  return (
    <Card
      className={classes.card}
      data-testid="how-to-card"
    >
      <img
        className={classes.image}
        src={imageSource}
        data-testid="how-to-image"
        alt=""
      />
      <p className={classes.description}>
        {description}
      </p>
    </Card>
  );
};
