import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import { IMyPointsTutorialProps } from './my-points-tutorial.models';
import { ReactComponent as PointsTutorialIcon } from '../../../../../assets/images/points-tutorial.svg';
import { useStyles } from './my-points-tutorial.styles';
import { Tutorial } from '../../../../common/tutorial';
import { usePointsTutorial } from '../../../../../graphql/user/actions/pointsTutorial';
import { InternalLinks } from '../../../../../constants';
import { useGoBack } from '../../../../../hooks/use-go-back';
import { setIsFreepacks } from '../../../../../graphql/configuration/configuration.cache';

export const MyPointsTutorial = ({ onClose, ...props }: IMyPointsTutorialProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const { setPointsTutorialShown } = usePointsTutorial();
  const { hasPreviousLocationState } = useGoBack();
  const isFreepacksEnabled = useReactiveVar(setIsFreepacks);
  const tutorialDescription = isFreepacksEnabled
    ? 'myPointsDetailed.tutorial.description.withFreepacks'
    : 'myPointsDetailed.tutorial.description';

  const handleTutorialClose = () => {
    setPointsTutorialShown();
    onClose!();
  };

  const handleHowToLinkClick = () => {
    setPointsTutorialShown();
  };

  return (
    <Tutorial
      {...props}
      data-testid="points-tutorial"
      onClose={handleTutorialClose}
      icon={(
        <PointsTutorialIcon
          data-testid="points-tutorial-logo"
        />
      )}
      title={(
        <FormattedMessage
          id="myPointsDetailed.tutorial.title"
        />
      )}
      description={intl.formatMessage(
        { id: tutorialDescription },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      )}
      addLink={(
        <Link
          to={InternalLinks.pointsHowToPage}
          onClick={handleHowToLinkClick}
          state={hasPreviousLocationState}
          className={classes.howToLink}
          data-testid="lenses-tutorial-howTo-link"
        >
          <FormattedMessage
            id="myPointsDetailed.tutorial.howToLink.label"
          />
        </Link>
      )}
    />
  );
};
