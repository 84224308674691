import { IEcpStore } from '../../../rest/ecp-locator';
import { setStoreDetail } from '../ecp-locator.cache';

const setStoreDetailToCache = (storeDetail: IEcpStore | null) => {
  setStoreDetail(storeDetail);
};

export const useStoreDetailFromCache = () => ({
  setStoreDetailToCache,
});
