import { HTMLAttributes, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Modal, ResponsiveWidth } from '../../../../../common/modal';
import { IBirthdayDisableChangeModalProps } from './birthday-disable-change-modal.models';
import { useStyles } from './birthday-disable-change-modal.styles';

export const BirthdayDisableChangeModal = (props: IBirthdayDisableChangeModalProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Modal
      {...props}
      responsiveWidth={ResponsiveWidth.mdWide}
      PaperProps={{
        'data-testid': 'birthday-disable-change-modal',
      } as HTMLAttributes<HTMLDivElement>}
      content={(
        <h2
          className={classes.title}
          data-testid="disable-change-modal-title"
        >
          {intl.formatMessage(
            { id: 'vp.birthdayDisableChangeModal.title' },
            {
              nonBreakSpace: <>&nbsp;</>,
              sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            },
          )}
        </h2>
      )}
    />
  );
};
