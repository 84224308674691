import { ConfirmationModal } from '../../../../../../../common/confirmation-modal';
import {
  Icon,
  IconTypes,
  ResponsiveWidth,
} from '../../../../../../../common';
import { IWarningModalProps } from './warning-modal.models';
import { useStyles } from './warning-modal.styles';

export const WarningModal = ({
  title, description, confirmButtonLabel, rejectButtonLabel, isMultiContent, onClose,
  onConfirm, ...props
}: IWarningModalProps): JSX.Element => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  const classes = useStyles();

  return (
    <ConfirmationModal
      {...props}
      topIcon={(
        <span
          data-testid="icon-wrapper"
          aria-hidden='true'
          className={classes.iconWrapper}
        >
          <Icon type={IconTypes.infoVoucher} />
        </span>
      )}
      title={title}
      content={description}
      confirmButtonLabel={confirmButtonLabel}
      onConfirm={handleConfirm}
      rejectOnClose
      contentStyles={classes.content}
      titleStyles={classes.title}
      responsiveWidth={ResponsiveWidth.mdWide}
      isMultiContent={isMultiContent}
      onClose={onClose}
    />
  );
};
