import {
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react';

type TCancel = () => void;

function CancelledPromiseOnUnmountError(this: Error) {
  this.name = 'CancelledPromiseError';
  this.message = 'Promise is cancelled on component unmount';
  this.stack = (new Error()).stack;
}

CancelledPromiseOnUnmountError.prototype = Object.create(Error.prototype);
CancelledPromiseOnUnmountError.prototype.constructor = CancelledPromiseOnUnmountError;

const createCancellablePromise = <T>(promise: Promise<T>) => {
  let isCancelled = false;

  const wrappedPromise = new Promise<T>((res, rej) => {
    promise
      .then((data) => {
        if (isCancelled) {
          rej(new (CancelledPromiseOnUnmountError as FunctionConstructor)());
        } else {
          res(data);
        }
      })
      .catch((error) => {
        rej(isCancelled ? new (CancelledPromiseOnUnmountError as FunctionConstructor)() : error);
      });
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled = true;
    },
  };
};

export const useCancellablePromise = () => {
  const promisesCancelFunctions = useRef<TCancel[]>([]);

  useEffect(() => () => promisesCancelFunctions.current.forEach((cancel) => cancel()), []);

  const makeCancellablePromise = useCallback(<T>(promise: Promise<T>) => {
    const newCancellablePromise = createCancellablePromise<T>(promise);

    promisesCancelFunctions.current = [
      ...promisesCancelFunctions.current,
      newCancellablePromise.cancel,
    ];

    return newCancellablePromise.promise;
  }, []);

  return useMemo(() => ({
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  }), [makeCancellablePromise]);
};
