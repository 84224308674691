import { useEffect, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useModal } from 'mui-modal-provider';

import { OrderCard as OrderCardView } from './order-card';
import { OrderModal } from './order-modal';
import { IOrderCardContainerProps } from './order-card.models';
import { getOrderPoints } from '../../../../../utils/order/get-order-points';
import { setOrderNumber } from '../../../../../graphql/order/order.cache';

export const OrderCard = ({
  orderNumber, orderStatus, orderDate, orderProducts, id, brandsPoints, storeData, orderId,
  deliveryDate, bonus, bonusId, purchases, rating,
}: IOrderCardContainerProps ) => {
  const cashedOrderNumber = useReactiveVar(setOrderNumber);
  const points = useMemo(() => {
    return getOrderPoints(orderProducts, brandsPoints);
  }, [brandsPoints, orderProducts]);
  const purchaseDate = useMemo(() => {
    if (purchases && purchases.length) {
      return purchases[0].purchaseDate;
    }
  }, [purchases]);

  const { showModal } = useModal();

  const handleOrderClick = () => {
    showModal(OrderModal, {
      orderNumber,
      products: orderProducts,
      storeData,
      points,
      orderStatus,
      orderId,
      deliveryDate,
      bonus,
      bonusId,
      purchaseDate,
      rating,
    });
  };

  useEffect(() => {
    if (orderNumber === cashedOrderNumber) {
      handleOrderClick();
    }
  }, []);

  return (
    <OrderCardView
      orderNumber={orderNumber}
      orderStatus={orderStatus}
      orderDate={orderDate}
      orderProducts={orderProducts}
      id={id}
      onClick={handleOrderClick}
      storeData={storeData}
      rating={rating}
    />
  );
};
