import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  fontWeights, fontSizes, lineHeights, breakpoints,
}: Theme) => ({
  title: {
    marginTop: 0,
    marginBottom: 8,
    fontSize: fontSizes.md,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      marginBottom: 16,
      fontSize: fontSizes.lg,
    },
  },
  statusInactive: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 20,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      marginBottom: 40,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  list: {
    listStyle: 'none',
    padding: 0,
    marginBottom: 20,
    [breakpoints.up('sm')]: {
      marginBottom: 40,
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      marginBottom: 12,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  icon: {
    marginRight: 6,
    lineHeight: 0,
    '& svg': {
      width: 16,
      height: 16,
      [breakpoints.up('sm')]: {
        width: 20,
        height: 20,
      },
    },
  },
}));
