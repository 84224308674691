import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
  fontWeights,
  borderRadiuses,
}: Theme) => ({
  textFieldRoot: {
    marginBottom: '2rem',
    '& .MuiFormControl-root': {
      minWidth: 'unset',
    },
  },
  labelRoot: {
    minWidth: 'unset',
    zIndex: 2,
    fontFamily: fontFamilies.primary,
    color: palette.common.black,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    transform: 'translate(0, 28px) scale(1)',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
      transform: 'translate(0, 30px) scale(1)',
    },
    '& + $inputRoot': {
      marginTop: '20px',
      [breakpoints.up('md')]: {
        marginTop: '22px',
      },
    },
    '&$labelFocused': {
      color: palette.common.black,
      transform: 'translate(0, 0) scale(1)',
    },
    '&$labelShrink': {
      transform: 'translate(0, 0) scale(1)',
    },
    '&$labelError': {
      color: palette.common.black,
    },
  },
  labelFocused: {},
  labelShrink: {},
  labelError: {},
  inputRoot: {
    minWidth: 'unset',
    marginTop: 0,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.sm,
    color: palette.common.black,
    fontFamily: fontFamilies.primary,
    [breakpoints.up('md')]: {
      lineHeight: lineHeights.md,
    },
    '&.MuiOutlinedInput-root': {
      borderRadius: borderRadiuses.md,
      padding: '4px 12px 2px',
      '& fieldset': {
        borderColor: palette.grey['500'],
      },
      '& textarea': {
        fontWeight: fontWeights.regular,
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary.main,
      },
    },
  },
  inputFocused: {},
  inputInput: {
    position: 'relative',
    zIndex: 1,
    paddingTop: 8,
    paddingBottom: 10,
    height: '20px',
    '&::placeholder': {
      color: palette.grey['500'],
      opacity: 1,
    },
    // fix https://stackoverflow.com/questions/61449079/how-to-hide-the-eye-from-a-password-input-in-ms-edge-and-ie/61450596
    '&::-ms-reveal, &::-ms-clear': {
      display: 'none',
    },
    [breakpoints.up('md')]: {
      height: '22px',
    },
  },
  inputUnderline: {
    minWidth: 'unset',
    '&:before': {
      borderTop: `1px ${palette.grey['400']} solid`,
      borderBottomColor: palette.grey['400'],
      borderBottomWidth: '1px',
      borderRadius: '10px',
      content: '""',
    },
    '&:after': {
      borderBottom: `1px solid ${palette.primary.dark}`,
      borderTop: `1px solid ${palette.primary.dark}`,
      borderRadius: '10px',
    },
    '&:hover': {
      '&:not($inputDisabled)': {
        '&:before': {
          borderBottomColor: palette.grey['400'],
          borderBottomWidth: '1px',
        },
      },
    },
  },
  inputDisabled: {},
  inputError: {
    '&$inputUnderline': {
      '&:after': {
        borderTopColor: palette.error.main,
        borderBottomColor: palette.error.main,
      },
    },
  },
  helperTextRoot: {
    margin: 0,
    fontFamily: fontFamilies.primary,
    color: palette.grey['500'],
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  helperTextError: {
    '&$helperTextRoot': {
      color: palette.error.main,
    },
  },
  helperTextContent: {
    margin: '4px 0 0',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '8px 0 0',
  },
  inputAdornmentRoot: {
    marginBottom: 2,
  },
  actionButtonRoot: {},
  footerActionButtonRoot: {},
}));
