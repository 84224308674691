import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontFamilies, fontSizes, lineHeights, palette,
}: Theme) => ({
  description: {
    margin: '0 0 48px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.primary,
    [breakpoints.up('md')]: {
      margin: '0 0 64px',
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  pieImage: {
    textAlign: 'center',
    marginBottom: 48,
    [breakpoints.up('md')]: {
      marginBottom: 64,
    },
  },
}));
