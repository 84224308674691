import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IMyOrdersPageProps } from './my-orders-page.models';
import { useStyles } from './my-orders-page.styles';
import { BackButton } from '../../../../common/back-button';
import { Preloader } from '../../../../common/preloader';
import { MyOrdersList } from './my-orders-list';
import { isUserFitted } from '../../../../../utils/lenses/is-user-fitted';
import { useGetOrders } from '../hooks/use-get-orders';
import { useGoBack } from '../../../../../hooks/use-go-back';

export const MyOrdersPage = ({ isLoading, ordersData }: IMyOrdersPageProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const isFitted = isUserFitted(ordersData);
  const orderCards = useGetOrders(ordersData);
  const { goBack } = useGoBack();

  const handleBack = () => goBack();

  useEffect(() => {
    if (ordersData?.ordersByAuthId && !ordersData?.ordersByAuthId.length) {
      handleBack();
    }
  }, [ordersData?.ordersByAuthId]);

  return (
    <>
      {isLoading && <Preloader />}
      {ordersData?.ordersByAuthId.length && (
        <div className={classes.root}>
          <section
            aria-label={intl.formatMessage({ id: 'myOrdersPreview.title' })}
            className={classes.region}
          >
            <BackButton/>
            <h1
              className={classes.title}
              data-testid="my-orders-title"
            >
              <FormattedMessage
                id="myOrdersPreview.title"
              />
            </h1>
            <MyOrdersList
              isFitted={isFitted}
              orders={orderCards.orders}
            />
          </section>
        </div>
      )}
    </>
  );
};
