import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { EcpStoreType } from '../rest/ecp-locator';

const getStoreByType = (storeType: string | undefined): JSX.Element => {
  let storeByType: JSX.Element;
  if (storeType === EcpStoreType.clinicHybrid
    || storeType === EcpStoreType.clinic
    || storeType === EcpStoreType.fitter) {
    storeByType = <FormattedMessage id="ecp.spec.clinic" />;
  } else if (storeType === EcpStoreType.vending) {
    storeByType = <FormattedMessage id="ecp.spec.vending" />;
  } else {
    storeByType = <FormattedMessage id="ecp.spec.optics" />;
  }
  return storeByType;
};

export const useStoreByType = (
  storeType: string,
): JSX.Element => useMemo(() => getStoreByType(storeType), [storeType]);
