import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, lineHeights,
}: Theme) => ({
  root: {
    width: 'auto',
    height: 48,
    [breakpoints.up('sm')]: {
      height: 64,
    },
    '& + span': {
      bottom: 2,
      padding: '2px 5px',
      lineHeight: lineHeights.xxs,
    },
  },
}));
