import { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import { useStyles } from './aftercare-survey-modal.styles';
import { IAftercareSurveyModalViewProps } from './aftercare-survey-modal.models';
import { ConfirmationModal } from '../../../../../../common/confirmation-modal';

export const AftercareSurveyModal = ({
  storeName, ...props
}: IAftercareSurveyModalViewProps): JSX.Element => {
  const classes = useStyles();

  return (
    <ConfirmationModal
      {...props}
      className={classes.aftercareModal}
      PaperProps={{
        'data-testid': 'after-care-survey',
      } as HTMLAttributes<HTMLDivElement>}
      title={(
        <FormattedMessage
          id="notification.aftercareSurvey.title"
        />
      )}
      content={(
        <FormattedMessage
          id="notification.aftercareSurvey.description"
          values={{ storeName }}
        />
      )}
      confirmButtonLabel={(
        <FormattedMessage
          id="notification.aftercareSurvey.confirmButton.label"
        />
      )}
      rejectButtonLabel={(
        <FormattedMessage
          id="notification.aftercareSurvey.rejectButton.label"
        />
      )}
    />
  );
};
