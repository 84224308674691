import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useModal } from 'mui-modal-provider';

import { GET_APP_LEVEL_MODALS_DATA } from '../../../../../../graphql/app-level-modals/queries/get-modals-data';
import { IAppLevelModalsData, INotification } from '../../../../../../graphql/app-level-modals/models/get-modals-data.model';
import { PurchasesWarningModal } from './purchases-warning-modal';
import { NotificationType } from '../../../../../../models/notification.models';
import { PurchasesBanModal } from './purchases-ban-modal';
import { getIncorrectPurchasesNotifications } from './utils/get-incorrect-purchases-notifications';

export const usePurchasesConsumerBanModal = () => {
  const [shouldModalUpdate, setModalUpdateState] = useState(true);
  const { updateQuery } = useQuery<IAppLevelModalsData>(GET_APP_LEVEL_MODALS_DATA);
  const { showModal } = useModal();

  const updateNotifications = useCallback((notificationIds: string[]) => {
    setModalUpdateState(true);

    updateQuery((prevState) => ({
      ...prevState,
      notificationByAuthId: prevState.notificationByAuthId
        .filter(({ id }) => !notificationIds.includes(id)),
    }));
  }, [
    updateQuery,
    setModalUpdateState,
  ]);

  const handlePurchasesConsumerBanModal = useCallback((notifications: INotification[]) => {
    if (shouldModalUpdate) {
      const incorrectPurchasesNotifications = getIncorrectPurchasesNotifications(notifications);

      if (incorrectPurchasesNotifications.length) {
        const banModalNotification = incorrectPurchasesNotifications.find(({ notificationType }) =>
          notificationType === NotificationType.USER_BLOCKED);

        if (banModalNotification) {
          const { activeOn, data } = banModalNotification;
          
          showModal(PurchasesBanModal, {
            startBanDate: activeOn,
            endBanDate: data!.lockedUntil,
            activeBanNotificationIds: incorrectPurchasesNotifications.map(({ id }) => id),
            updateNotifications,
          });
        } else {
          showModal(PurchasesWarningModal, {
            notificationId: incorrectPurchasesNotifications[0].id,
            updateNotifications,
          });
        }

        setModalUpdateState(false);
      }
    }
  }, [
    shouldModalUpdate,
    showModal,
    setModalUpdateState,
  ]);

  return { handlePurchasesConsumerBanModal };
};