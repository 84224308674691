import { IEcpStore } from '../../rest/ecp-locator';

export const sortByDistance = (stores: IEcpStore[]): IEcpStore[] => {
  const storesToSort = [...stores];
  if (storesToSort?.length) {
    return storesToSort.sort((
      storeFirst, storeSecond,
    ) => +((storeFirst.distance)?.toFixed(1)) - +((storeSecond.distance)?.toFixed(1))
      || storeSecond.rating! - storeFirst.rating!);
  }
  return [];
};
