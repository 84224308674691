import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
}: Theme) => ({
  root: {
    margin: '16px 0',
    [breakpoints.up('md')]: {
      margin: '20px 0',
    },
  },
}));
