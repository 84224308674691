import React, {
  forwardRef,
  useImperativeHandle, useMemo,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';

import { LensesExperienceStepForm } from './lenses-experience-step-form';
import { yup } from '../../../../../../services/yup';
import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { IStepsState } from '../../registration.models';

export const LensesExperienceStep = forwardRef<
IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  stepsState,
}, ref): JSX.Element => {
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const intl = useIntl();

  const validationSchema = useMemo(() => yup.object({
    clExperience: yup.string().required(intl.formatMessage({ id: 'common.errors.requiredPickOne' })),
  }), [intl]);

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return formRef.current!.getValues();
    },
  }));

  return (
    <LensesExperienceStepForm
      ref={formRef}
      onSubmit={onGoNextSuccess}
      validationSchema={validationSchema}
      defaultValues={(stepsState as IStepsState).lensesExperience}
    />
  );
});
