import { useQuery } from '@apollo/client';

import { NoBonus } from './no-bonus';
import { Bonus } from './bonus';
import { IBonusesProps } from './bonuses.models';
import { useStyles } from './bonuses.styles';
import { IOrderVouchersData } from '../../../../../../graphql/bonuses/models/get-bonuses.models';
import { GET_VOUCHERS } from '../../../../../../graphql/bonuses/queries/get-vouchers';

export const Bonuses = ({
  packageNumber, points, bonus, isActiveStatus, isCancelledOrder, onBonusSelect, bonusId, isEditOrder,
}: IBonusesProps): JSX.Element => {
  const  classes = useStyles();
  const { data } = useQuery<IOrderVouchersData>(GET_VOUCHERS, {
    fetchPolicy: 'network-only',
  });

  return (
    <div
      data-testid="bonuses-section-wrapper"
      className={classes.wrapper}
    >
      {bonus ? (
        <Bonus
          bonus={bonus!}
          isActiveStatus={isActiveStatus}
          onBonusSelect={onBonusSelect}
          bonusId={bonusId}
          bonusesData={data}
          isEditOrder={isEditOrder}
        />
      ) : (
        <>{!isCancelledOrder && (
          <NoBonus
            packageNumber={packageNumber}
            points={points}
            isActiveStatus={isActiveStatus}
            onBonusSelect={onBonusSelect}
            bonusesData={data}
            isEditOrder={isEditOrder}
          />
        )}
        </>
      )}
    </div>
  );
};
