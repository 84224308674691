import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
}: Theme) => ({
  root: {
    position: 'relative',
  },
  packSizeLabel: {
    position: 'absolute',
    bottom: 10,
    right: -10,
    backgroundColor: palette.common.white,
    padding: '0 5px',
    borderRadius: 15,
    boxShadow: '0px 4px 10px rgba(1, 62, 126, 0.3)',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxs,
    color: palette.text.primary,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xs,
    },
  },
}));
