import { BonusChangeModal as BonusChangeModalView } from './bonus-change-modal';
import { IBonusChangeContainerProps } from './bonus-change-modal.models';
import { IOrderSelectedBonus } from '../../../../../../../../graphql/order/models/get-orders.models';

export const BonusChangeModal = ({
  onBonusSelect, onClose, bonusId, bonusValue, bonusType, bonusesData, onWarningClose,
  isEditOrder, ...props
}: IBonusChangeContainerProps): JSX.Element => {
  const handleBonusSelect = (id: string, bonus: IOrderSelectedBonus | null) => {
    onBonusSelect(id, bonus);
    if (onWarningClose) {
      onWarningClose();
    }
    onClose!();
  };

  return (
    <BonusChangeModalView
      {...props}
      onBonusSelect={handleBonusSelect}
      onClose={onClose!}
      bonusId={bonusId}
      bonusValue={bonusValue}
      bonusType={bonusType}
      bonusesData={bonusesData}
      isEditOrder={isEditOrder}
    />
  );
};
