import React from 'react';
import { FormattedMessage } from 'react-intl';
import { cx } from '@emotion/css';

import { useStyles } from './error-plain.styles';
import { ErrorTypes, IErrorPlainProps } from './error-plain.models';

export const ErrorPlain = ({
  content,
  variant,
  externalClass,
  isolated,
}: IErrorPlainProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      {
        isolated ? (
          <p
            data-testid="error-plain-isolated"
            className={cx(
              { [classes.plainText]: variant === ErrorTypes.plainText },
              { [classes.error]: variant === ErrorTypes.error },
              { [`${externalClass}`]: !!externalClass },
            )}
          >
            <FormattedMessage
              id={content}
              values={{
                linebreak: <br />,
              }}
            />
          </p>
        ) : (
          <span
            data-testid="error-plain"
            className={cx(
              { [classes.plainText]: variant === ErrorTypes.plainText },
              { [classes.error]: variant === ErrorTypes.error },
              { [`${externalClass}`]: !!externalClass },
            )}
          >
            <FormattedMessage id={content} />
          </span>
        )
      }
    </>
  );
};
