import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useReactiveVar } from '@apollo/client';

import { useBind } from '../../../../../../hooks/use-bind';
import { useSnackbar } from '../../../../../../hooks/use-snackbar';
import { useCancellablePromise } from '../../../../../../hooks/use-cancellable-promise';
import { PhoneStepForm } from './phone-step-form';
import { yup } from '../../../../../../services/yup';
import {
  usePhoneNumberValidation,
} from '../../../../../../hooks/use-phone-number-validation';
import { oauthApi } from '../../../../../../rest/oauth';
import { useActionsInProgress } from '../../../../../../graphql/preloader/actions/actions-in-progress';
import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { IStepsState, TPhoneFormValues } from '../../registration.models';
import { TimerStatusOptions } from '../../../reset-password/reset-password.models';
import { IExternalStateProps } from '../../registration.models';
import { authErrors, setPhoneStepValues } from '../../../../../../graphql/user/user.cache';
import { PHONE_LENGTH } from '../../../../../../constants';
import { RecaptchaActions } from '../../../../../../rest/consumer';
import { ITokenData } from '../../../../../../rest/oauth';
import { useRegistrationError } from '../../hooks/use-registration-error';

export const PhoneStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  stepsState,
  onGoNextSuccess,
  onGoNextFail,
  onGoNext,
  isNavigationAllowed,
  externalState,
  onNextButtonDisabled,
}, ref): JSX.Element => {
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const stepsStateRegistration = stepsState as IStepsState;
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const { enqueueSnackbar } = useSnackbar();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const phoneNumberValidation = usePhoneNumberValidation();

  const validationSchema = useMemo(() => yup.object({
    phoneNumber: phoneNumberValidation,
  }), [phoneNumberValidation]);

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return {
        formValues: formRef.current!.getValues(),
      };
    },
  }));

  const defaultValues = useMemo(
    () => stepsStateRegistration.phone && stepsStateRegistration.phone.formValues,
    [stepsStateRegistration.phone],
  );

  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const onGoNextSuccessBind = useBind(onGoNextSuccess);
  const onGoNextFailBind = useBind(onGoNextFail);
  const onGoNextBind = useBind(onGoNext);
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const isNavigationAllowedBind = useBind(isNavigationAllowed);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);

  const { start, reset, status: timerStatus, isCaptcha } = externalState as IExternalStateProps;

  const [isNewNumber, setIsNewNumber] = useState<boolean>(false);

  const disableSubmitButton = () => {
    if (formRef?.current?.getValues()) {
      const { phoneNumber: phoneFieldValue } = formRef.current.getValues();
      const isSubmitDisabled = (phoneFieldValue as string).length !== PHONE_LENGTH;

      onNextButtonDisabled(isSubmitDisabled);
    }
  };

  const handlePhoneChange = useCallback(() => {
    if (formRef?.current?.getValues() && timerStatus === TimerStatusOptions.running) {
      const { phoneNumber: phoneFieldNewValue } = formRef.current.getValues();
      const phone = stepsStateRegistration?.phone.formValues.phoneNumber;

      if (phoneFieldNewValue !== phone) {
        setIsNewNumber(true);
      }

      if (phoneFieldNewValue === phone) {
        setIsNewNumber(false);
      }
    }

    disableSubmitButton();
  }, [setIsNewNumber, formRef, stepsStateRegistration?.phone]);

  const phoneStepValues = useReactiveVar(setPhoneStepValues);

  const handleNoSubmit = useCallback((formValues: TPhoneFormValues) => {
    onGoNextBind.current();
    onGoNextSuccessBind.current({
      ...phoneStepValues,
      formValues,
    });
  }, []);

  const handleAfterSubmit = (formValues: TPhoneFormValues, token: ITokenData) => {
    setPhoneStepValues({
      formValues,
      token,
    });

    reset();
    start();

    removeActionInProgressBind.current();

    onGoNextSuccessBind.current({
      formValues,
      token,
    });
  };

  const { handleError } = useRegistrationError();

  const handleSubmit = useCallback(async (formValues: TPhoneFormValues) => {
    if (!isNavigationAllowedBind.current) {
      return;
    }

    onGoNextBind.current();
    addActionInProgressBind.current();

    try {
      if (isCaptcha) {
        const recaptcha = (window as any).grecaptcha.enterprise;

        recaptcha.ready(() => {
          recaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
            action: RecaptchaActions.registration,
          }).then(async (recaptchaToken: string) => {
            const { data: token } = await makeCancellablePromiseBind.current(
              oauthApi.registerPhoneNumberRecaptcha({
                phoneNumber: formValues.phoneNumber,
              }, recaptchaToken),
            );

            handleAfterSubmit(formValues, token);
            authErrors({ phoneNumberExists: false });
          }).catch((error: any) => {
            removeActionInProgressBind.current();

            handleError(error);
            onGoNextFailBind.current();
          });
        });
      } else {
        const { data: token } = await makeCancellablePromiseBind.current(
          oauthApi.registerPhoneNumber({
            phoneNumber: formValues.phoneNumber,
          }),
        );

        handleAfterSubmit(formValues, token);
        authErrors({ phoneNumberExists: false });
      }
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        return;
      }

      handleError(error);
      onGoNextFailBind.current();
    }
  }, [
    reset,
    start,
    timerStatus,
    onGoNextBind,
    onGoNextSuccessBind,
    onGoNextFailBind,
    isNavigationAllowedBind,
    enqueueSnackbarBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    addActionInProgressBind,
    removeActionInProgressBind,
    isCaptcha,
  ]);

  useEffect(() => {
    disableSubmitButton();
  }, []);

  return (
    <PhoneStepForm
      ref={formRef}
      onSubmit={(timerStatus === TimerStatusOptions.running) && !isNewNumber ? handleNoSubmit : handleSubmit}
      validationSchema={validationSchema}
      defaultValues={defaultValues}
      onPhoneChange={handlePhoneChange}
    />
  );
});
