import { useEffect, useState } from 'react';

import { IProductQuantityContainerProps } from './product-quantity.models';
import { ProductQuantity as ProductQuantityView } from './product-quantity';
import {
  LENSES_AMOUNT_MAX,
  LENSES_AMOUNT_ONE,
  LENSES_AMOUNT_MIN,
} from '../../../../../../../../../constants/validation/lenses-amount';

export const ProductQuantity = ({
  brand, productQuantity, onUpdateQuantity, onDeleteButtonClick,
}: IProductQuantityContainerProps): JSX.Element => {
  const [isAddActive, setIsAddActive] = useState<boolean>(true);
  const [isRemoveActive, setIsRemoveActive] = useState<boolean>(false);

  const handleAddLenses = () => {
    const newAmount = Number(productQuantity) + 1;

    if (!isRemoveActive) {
      setIsRemoveActive(true);
    }

    if (newAmount === Number(LENSES_AMOUNT_MAX)) {
      setIsAddActive(false);
    }

    onUpdateQuantity!(newAmount);
  };

  const handleRemoveLenses = () => {
    const newAmount = Number(productQuantity) - 1;

    if (newAmount < LENSES_AMOUNT_ONE) {
      onDeleteButtonClick!();
    } else {
      onUpdateQuantity!(newAmount);
    }

    if (!isAddActive) {
      setIsAddActive(true);
    }
  };

  useEffect(() => {
    if (Number(productQuantity) > Number(LENSES_AMOUNT_MIN)) {
      setIsRemoveActive(true);
    }

    if (Number(productQuantity) === Number(LENSES_AMOUNT_MAX)) {
      setIsAddActive(false);
    }
  }, []);

  return (
    <ProductQuantityView
      onRemoveLenses={handleRemoveLenses}
      onAddLenses={handleAddLenses}
      brand={brand}
      isAddActive={isAddActive}
      isRemoveActive={isRemoveActive}
      productQuantity={productQuantity}
    />
  );
};
