import { forwardRef } from 'react';

import { IFormImperativeHandleProps } from '../../../../../common/form';
import { Icon, IconTypes } from '../../../../../common/icon';
import { GadgetsUseDurationForm } from './gadgets-use-duration-form';
import { IGadgetsUseDurationViewProps } from './gadgets-use-duration-view.models';
import { useStyles } from './gadgets-use-duration-view.styles';

export const GadgetsUseDurationView = forwardRef<
IFormImperativeHandleProps, IGadgetsUseDurationViewProps>(({
  formProps,
}, ref): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.icon}>
        <Icon type={IconTypes.gadgets} />
      </div>
      <GadgetsUseDurationForm
        ref={ref}
        formProps={formProps}
      />
    </>
  );
});
