import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, lineHeights, fontWeights, borderRadiuses,
}: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    padding: 12,
    [breakpoints.up('sm')]: {
      padding: 16,
    },
    '&&': {
      borderRadius: borderRadiuses.md,
      boxShadow: 'none',
    },
  },
  content: {},
  title: {
    marginTop: 0,
    marginBottom: 4,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.regular,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  description: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  button: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'flex-end',
    padding: 12,
    [breakpoints.up('sm')]: {
      padding: 16,
    },
  },
  icon: {},
}));
