import { useCallback, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';

import { setUserLocation } from '../graphql/ecp-locator/ecp-locator.cache';
import { TFiltersData } from '../graphql/ecp-locator/models/filter-params.models';
import { ecpLocatorApi } from '../rest/ecp-locator';
import { EcpMapUserLocation, filteringByStores } from '../utils/ecp-locator';
import { useEcpUserLocation } from '../graphql/ecp-locator/actions/user-location';
import { DEFAULT_RANGE } from '../constants/ecp-locator';
import { EcpStoreType } from '../rest/ecp-locator';
import { setOrderStores } from '../graphql/order/order.cache';
import { DEFAULT_FILTERS } from '../constants/ecp-locator';
import {
  shopTypeFilteringItems,
} from '../components/business/app/ecp-filters-modal/filtering-criteria-items/filtering-criteria-items';

export interface IUpdateStores {
  filters?: TFiltersData;
  userLocation?: EcpMapUserLocation;
}

export const useUpdateOrderStores = () => {
  const { setEcpUserLocationToCache } = useEcpUserLocation();
  const filtersCurrent = {
    ...DEFAULT_FILTERS,
    isFirstTimeLoading: true,
  };
  const userLocationCurrent = useReactiveVar(setUserLocation);
  const [isStoresLoading, setLoadingState] = useState(false);
  const [isStoresError, setErrorState] = useState(false);

  const updateStores = useCallback(async ({ userLocation }: IUpdateStores) => {
    let filtersApplied = filtersCurrent;
    let userLocationApplied = userLocationCurrent;

    if (userLocation) {
      userLocationApplied = userLocation;
    }

    const { specializations, shops } = filtersApplied;
    const { latitude, longitude } = userLocationApplied;

    try {
      setLoadingState(true);
      const { data } = await ecpLocatorApi.getStores({
        lat: latitude,
        long: longitude,
        range: DEFAULT_RANGE,
        sorting: 'rating',
        speciality: specializations.join(','),
        type: EcpStoreType.retailer,
        c2cActivated: true,
      });

      if (userLocation) {
        setEcpUserLocationToCache(userLocation);
      }

      setOrderStores(filteringByStores(data, shops, shopTypeFilteringItems.length));
    } catch {
      setErrorState(true);
    }
    setLoadingState(false);
  }, [
    filtersCurrent,
    userLocationCurrent,
    setOrderStores,
    setEcpUserLocationToCache,
  ]);

  useEffect(() => () => {
    setLoadingState(false);
  }, []);

  return { updateStores, isStoresLoading, isStoresError };
};
