import { capitalize } from 'lodash';
import { cx } from '@emotion/css';

import { Card } from '../card';
import { ITitleSize, IUserActivitiesListProps } from './user-activities-list.models';
import { useStyles } from './user-activities-list.styles';

export const UserActivitiesList = <T extends { id: number | string }>({
  title, activities, placeholder = null, footer, action, isStickyFooter,
}: IUserActivitiesListProps<T>): JSX.Element => {
  const classes = useStyles();
  let titleSizeClass = '';

  if (title) {
    const { size = ITitleSize.md } = title;

    titleSizeClass = classes[
      `titleSize${capitalize(size) as 'Md' | 'Lg'}`
    ];
  }

  return (
    <Card
      className={classes.root}
      data-testid="user-activities-list"
    >
      <div
        className={classes.titleBar}
        data-testid="activities-title-bar"
      >
        {title && (
          <h2
            data-testid="activities-list-title"
            className={cx(classes.title, titleSizeClass)}
          >
            {title.label}
          </h2>
        )}
        {!!action && action}
      </div>
      {activities.length ? (
        <ul
          className={classes.list}
          data-testid="activities-list"
        >
          {activities.map((activityItem) => (
            <li
              className={classes.bonusCardsItem}
              key={activityItem.props.id}
            >
              {activityItem}
            </li>
          ))}
        </ul>
      ) : placeholder}
      {footer && (
        <div
          className={cx(classes.footer,
            { [classes.stickyFooter]: isStickyFooter },
          )}
          data-testid="activities-footer"
        >
          {footer}
        </div>
      )}
    </Card>
  );
};
