import {
  IConfigurationParams,
  ConfigurationNames,
} from '../../../../graphql/configuration/models/configuration.models';

export const getLocatorIconsEnabled = (configuration: IConfigurationParams[]): boolean => {
  let result: boolean = false;

  const locatorIconsParams = configuration.find((
    params,
  ) => params.name === ConfigurationNames.locatorIcons);

  if (locatorIconsParams) {
    result = JSON.parse(locatorIconsParams.configuration).enabled;
  }

  return result;
};
