import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
  fontWeights,
  borderRadiuses,
}: Theme) => ({
  root: {
    marginBottom: 30,
  },
  slide: {
    position: 'relative',
    height: 380,
    overflow: 'hidden',
    background: 'radial-gradient(circle at right bottom, #06b7e2 0%, #06aadb 15%, #0b7dc1 70%)',
    '& img': {
      position: 'absolute',
      bottom: 0,
      right: -20,
      width: 203,
      height: 'auto',
      objectFit: 'contain',
      margin: 0,
    },
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    padding: '36px 16px 8px',
  },
  title: {
    maxWidth: 290,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxl,
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.xxl,
    textAlign: 'left',
    color: palette.custom.regSlide,
    marginTop: 0,
    marginBottom: 16,
    '& sup': {
      position: 'relative',
      lineHeight: 0,
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
    fontWeight: fontWeights.medium,
    width: 216,
    marginBottom: 32,
    padding: '8px 16px',
    borderRadius: borderRadiuses.xl,
    '&, &:hover, &:focus, &:active': {
      border: 'none',
      background: palette.common.white,
      color: palette.primary.dark,
      textDecoration: 'none',
    },
  },
  disclaimer: {
    width: 'auto',
    maxWidth: '100%',
    height: 11,
    objectFit: 'contain',
  },
  titleEmphasis: {
    display: 'block',
    color: palette.primary.contrastText,
  },
  programNote: {
    margin: 0,
    color: palette.custom.regSlideTransparent,
    fontSize: 6,
    lineHeight: 1.2,
  },
}));