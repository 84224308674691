import React, { useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { omit } from 'lodash';
import { format } from 'date-fns';

import {
  ICompareFormValues,
  IProfileFormProps,
  IProfileRawFormData,
} from './profile-form.models';
import { IConsent, IProfileData } from '../../../../../graphql/profile/models/get-profile.models';
import { Form } from '../../../../common/form';
import { TextField } from '../../../../common/form/text-field';
import { Button } from '../../../../common/button';
import { ChangePasswordField } from '../change-password-field';
import { ChangePhoneField } from '../change-phone-field';
import { ProfileNotifications } from '../profile-notifications';
import { GenderToggleGroup } from '../gender-toggle-group';
import { convertArrayToObject } from '../../../../../utils/data-structure-convertations/convert-array-to-object';
import { convertObjectToArray } from '../../../../../utils/data-structure-convertations/convert-object-to-array';
import { useStyles } from './profile-form.styles';
import { ChangeBirthdayField } from '../change-birthday-field';
import { UI_DATE_FORMAT, InternalLinks } from '../../../../../constants';

export const ProfileForm = ({
  onSubmit, profileData, validationSchema, updateProfileQuery,
}: IProfileFormProps): JSX.Element => {
  const classes = useStyles();
  const {
    id,
    username,
    myAcuvueId,
    firstName,
    lastName,
    email,
    gender,
    commPermissions,
    mobile: mobileFullFormat,
    consent: consentFullFormat,
  } = profileData.profile;
  const { visionProfile: birthdayData } = profileData;
  const mobile = mobileFullFormat.slice(1);
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const consent = consentFullFormat.map(({ consent, date, value }: IConsent) => ({
    consent, date, value,
  }));

  const defaultValues = useMemo(() => ({
    myAcuvueId,
    firstName,
    lastName,
    email,
    mobile,
    gender,
    commPermissions: convertArrayToObject(commPermissions),
    password: '******',
    dateOfBirth: birthdayData?.dateOfBirth
      ? format(new Date(birthdayData.dateOfBirth), UI_DATE_FORMAT)
      : '',
  }), [
    commPermissions,
    email,
    firstName,
    gender,
    lastName,
    mobile,
    myAcuvueId,
    birthdayData,
  ]);

  const handleSubmit = useCallback((values: IProfileRawFormData) => {
    onSubmit({
      ...omit(values, ['password', 'dateOfBirth']),
      id,
      username: mobile,
      consent,
      commPermissions: convertObjectToArray(values.commPermissions),
      email: values.email,
    } as IProfileData, {
      email: defaultValues.email,
    } as ICompareFormValues);
  }, [onSubmit, id, username, consent]);

  return (
    <Form
      onSubmit={handleSubmit}
      withLeavePrompt
      unsavedChangesConfirmationModalProps={{
        pageName: (
          <FormattedMessage
            id="profile.unsavedChanges.pageName"
          />
        ),
      }}
      validationSchema={validationSchema}
      defaultValues={defaultValues}
    >
      <TextField
        name="myAcuvueId"
        fullWidth
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
        label={(
          <FormattedMessage
            id="profile.idTextField.label"
          />
        )}
      />
      <TextField
        name="firstName"
        fullWidth
        label={(
          <FormattedMessage
            id="profile.nameTextField.label"
          />
        )}
      />
      <TextField
        name="lastName"
        fullWidth
        label={(
          <FormattedMessage
            id="profile.surnameTextField.label"
          />
        )}
      />
      <TextField
        name="email"
        fullWidth
        label={(
          <FormattedMessage
            id="profile.emailTextField.label"
          />
        )}
      />
      <ChangeBirthdayField birthdayData={birthdayData} />
      <GenderToggleGroup />
      <ChangePhoneField
        phoneNumber={mobile}
        updateProfileQuery={updateProfileQuery}
      />
      <ChangePasswordField phoneNumber={mobile} />
      <ProfileNotifications name="commPermissions" />
      <div className={classes.actions}>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          data-testid="profile-submit"
        >
          <FormattedMessage id="profile.saveButton.label" />
        </Button>
        <Button to={InternalLinks.deleteAccount} variant="text">
          <FormattedMessage id="profile.deleteProfileButton.label" />
        </Button>
      </div>
    </Form>
  );
};
