import { ReactNode } from 'react';

export interface BoxProps {
  title?: ReactNode;
  description?: ReactNode;
  footerDescription?: ReactNode;
  children?: ReactNode;
  responsiveMargins?: ResponsiveMargins;
  isBackButton?: boolean;
  isBackButtonState?: boolean;
  paperStyles?: string;
  externalRootClasses?: string;
}

export enum ResponsiveMargins {
  sm = 'sm',
  xs = 'xs',
}
