import { ecpStoresCached } from '../ecp-locator.cache';
import { IEcpStore } from '../../../rest/ecp-locator';

const setEcpStoresToCache = (ecpStores: IEcpStore[]) => {
  ecpStoresCached(ecpStores);
};

export const useEcpStoresFromCache = () => ({
  setEcpStoresToCache,
});
