import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {},
  rootSizeXs: {
    width: 16,
    height: 16,
  },
  rootSizeSm: {
    width: 24,
    height: 24,
  },
  rootSizeMd: {
    width: 32,
    height: 32,
  },
  rootSizeLg: {
    width: 40,
    height: 40,
  },
  rootSizeXl: {
    width: 48,
    height: 48,
  },
  rootSizeXxl: {
    width: 56,
    height: 56,
  },
  rootSize3xl: {
    width: 64,
    height: 64,
  },
  rootSizeXsm: {
    width: 20,
    height: 20,
  },
}));
