import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { toInternationalFormat } from '../../../../../../../../../utils/phone/to-international-format';
import { IConfirmationCodeStepDescriptionProps } from './confirmation-code-step-description.models';

export const ConfirmationCodeStepDescription = ({
  externalState: {
    phoneNumber: phoneNumber,
  },
}: IConfirmationCodeStepDescriptionProps): JSX.Element => {
  const formattedPhoneNumber = useMemo(
    () => toInternationalFormat(phoneNumber),
    [phoneNumber],
  );

  return (
    <FormattedMessage
      id="changePhone.confirmationCodeStep.description"
      values={{ phoneNumber: formattedPhoneNumber }}
    />
  );
};
