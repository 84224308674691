import React from 'react';
import {
  FormControl as MuiFormControl,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  InputLabel as MuiInputLabel,
  Input as MuiInput,
} from '@mui/material';
import { cx } from '@emotion/css';

import { ISelectProps } from './select.models';
import { useStyles } from './select.styles';
import { Icon, IconTypes } from '../icon';
import { TFormExtendedCommonProps } from '../form';

const IconComponent = (iconProps: unknown) => (
  <Icon
    {...iconProps}
    type={IconTypes.arrowDown}
  />
);

export const Select = ({
  options, label: selectLabel, fullWidth, formControlClasses, paperStyles,
  listStyles, itemStyles, ...props
}: TFormExtendedCommonProps<ISelectProps>): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiFormControl
      fullWidth={fullWidth}
      classes={{
        root: cx(classes.formControl, formControlClasses),
      }}
    >
      <MuiInputLabel
        classes={{
          root: classes.label,
        }}
      >
        {selectLabel}
      </MuiInputLabel>
      <MuiSelect
        {...props}
        IconComponent={IconComponent}
        classes={{
          select: classes.root,
          icon: classes.icon,
        }}
        input={(
          <MuiInput
            classes={{
              underline: classes.inputUnderline,
            }}
          />
        )}
        MenuProps={{
          classes: {
            paper: cx(classes.selectMenu, paperStyles),
            list: listStyles,
          },
        }}
      >
        {options.map(({ value, label }) => (
          <MuiMenuItem
            key={value}
            value={value}
            classes={{
              root: cx(classes.selectMenuItem, itemStyles),
            }}
          >
            {label}
          </MuiMenuItem>
        ))}
      </MuiSelect>
    </MuiFormControl>
  );
};
