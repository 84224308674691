import { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { yup } from '../../../../services/yup';
import { Button } from '../../../common/button-new';
import { TextField } from '../../../common/form/text-field';
import { Form } from '../../../common/form';
import {
  usePhoneNumberValidation,
} from '../../../../hooks/use-phone-number-validation';
import { usePasswordValidation } from '../../../../hooks/use-password-validation';
import { ILoginFormProps } from './login-form.models';
import { useStyles } from './login-form.styles';
import { InternalLinks } from '../../../../constants';

export const LoginForm = ({
  onSubmit, isRegistration = true,
}: ILoginFormProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const defaultValues = useMemo(() => ({
    username: '',
    password: '',
  }), []);

  const phoneNumberValidation = usePhoneNumberValidation();
  const passwordValidation = usePasswordValidation();

  const validationSchema = useMemo(() => yup.object({
    username: phoneNumberValidation,
    password: passwordValidation,
  }), [passwordValidation, phoneNumberValidation]);

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      <TextField
        autoFocus
        name="username"
        type="tel"
        fullWidth
        label={(
          <FormattedMessage
            id="login.phoneTextField.label"
          />
        )}
      />
      <TextField
        name="password"
        type="password"
        fullWidth
        label={(
          <FormattedMessage
            id="login.passwordTextField.label"
          />
        )}
        placeholder={intl.formatMessage({ id: 'login.passwordTextField.placeholder' })}
        footerActionProps={{
          style: 'text',
          className: classes.resetLink,
          testId: 'password-forget-link',
          to: InternalLinks.resetPassword,
          children: (
            <FormattedMessage
              id="login.resetPasswordLink.label"
            />
          ),
        }}
      />
      <div className={classes.actions}>
        <Button
          type="submit"
          style="dark"
          testId="login-submit"
        >
          <FormattedMessage
            id="login.loginButton.label"
          />
        </Button>
        {isRegistration && (
          <Button
            testId="register-link"
            style="outlined"
            to={InternalLinks.registration}
          >
            <FormattedMessage id="login.registrationButton.label" />
          </Button>
        )}
      </div>
    </Form>
  );
};
