import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
  borderRadiuses,
}: Theme) => ({
  root: {
    paddingBottom: '1rem',
    [breakpoints.up('sm')]: {
      paddingBottom: '1.25rem',
      width: '90vw',
      maxWidth: '58rem',
      margin: '0 auto',
    },
    '& > .MuiButtonBase-root svg path': {
      fill: palette.grey['800'],
    },
  },
  title: {
    textAlign: 'center',
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontFamily: fontFamilies.primary,
    color: palette.text.secondary,
    fontWeight: fontWeights.medium,
    margin: '0 0 1rem',
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      marginBottom: '0.5rem',
    },
  },
  text: {
    marginTop: 0,
    marginBottom: '1.25rem',
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      marginBottom: '2.25rem',
      fontSize: fontSizes.sm,
    },
    '&:first-of-type': {
      marginBottom: 0,
      [breakpoints.up('sm')]: {
        marginBottom: 0,
      },
    },
  },
  content: {
    padding: '0 1rem',
    [breakpoints.up('sm')]: {
      padding: '0 1.25rem',
    },
  },
  cardsList: {
    padding: 0,
    margin: 0,
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      overflowX: 'auto',
    },
  },
  cardsItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0.5rem 1rem',
    border: `1px solid ${palette.grey['400']}`,
    borderRadius: borderRadiuses.sm,
    background: palette.grey['100'],
    [breakpoints.up('sm')]: {
      width: '10.25rem',
    },
  },
  imgContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: '1.25rem 1rem 0',
    marginBottom: '1rem',
    [breakpoints.up('sm')]: {
      padding: '0.75rem 1rem 0',
    },
    '& img': {
      width: '10rem',
      height: 'auto',
      objectFit: 'contain',
      [breakpoints.up('sm')]: {
        width: '100%',
      },
    },
  },
  index: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2rem',
    height: '2rem',
    borderRadius: borderRadiuses.xxl,
    background: palette.custom.main,
    color: palette.common.white,
    [breakpoints.up('sm')]: {
      bottom: '0.25rem',
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  link: {
    color: palette.text.primary,
    fontSize: fontSizes.xs,
    fontWeight: fontWeights.medium,
    wordBreak: 'break-word',
  },
  cardText: {
    fontSize: fontSizes.xs,
    lineHeight: 1.3,
    textAlign: 'center',
  },
}));
