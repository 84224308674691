import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
}: Theme) => ({
  formControlRoot: {},
  formControlLabelRoot: {
    minWidth: 'unset',
    margin: '0 0 18px',
    alignItems: 'flex-start',
    '& .MuiTypography-root': {
      minWidth: 'unset',
    },
  },
  formControlRootIconType: {
    margin: 0,
  },
  formControlLabelLabel: {
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontFamily: fontFamilies.primary,
    color: palette.text.secondary,
    paddingTop: 3,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
      paddingTop: 1,
    },
    '& a': {
      color: palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  checkboxRoot: {
    padding: 0,
    marginRight: 4,
    '&$checkboxRoot:hover': {
      backgroundColor: 'transparent',
    },
    [breakpoints.up('md')]: {
      marginRight: 12,
    },
  },
  checkboxRootIcontype: {
    marginRight: 0,
  },
  formHelperTextRoot: {
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontFamily: fontFamilies.primary,
    color: palette.text.secondary,
    margin: '0 0 18px',
    '&$formHelperTextError': {
      color: palette.error.main,
    },
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  formHelperTextError: {},
}));
