import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, spacing, palette, borderRadiuses,
}: Theme) => ({
  root: {
    marginBottom: 32,
    [breakpoints.up('sm')]: {
      marginBottom: 64,
    },
    [breakpoints.up('lg')]: {
      marginBottom: 80,
    },
  },
  rootCampaign: {
    position: 'relative',
    overflow: 'visible',
  },
  cardsList: {
    display: 'grid',
    gap: `${spacing(3)} ${spacing(2)}`,
    padding: '24px 16px 32px',
    marginTop: 0,
    listStyleType: 'none',
    [breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      gap: `${spacing(4)} ${spacing(2)}`,
      padding: '40px 32px 48px',
    },
    [breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      padding: 32,
    },
  },
  cardsListCampaign: {
    position: 'relative',
    zIndex: 1,
    borderRadius: borderRadiuses.lg,
    backgroundColor: palette.common.white,
  },
}));
