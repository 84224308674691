import { FormattedMessage } from 'react-intl';

import {
  ONE,
  TWO,
  THREE,
  FOUR,
  DECIMAL_ONE,
  DECIMAL_TWO,
  DECIMAL_THREE,
  DECIMAL_FOUR,
} from '../constants';

const lastNumber = (value: number): string => {
  return String(value).slice(-1);
};
const lastTwoNumbers = (value: number): string => {
  return String(value).slice(-2);
};

const getIsDecimalEnd = (value: number): boolean => {
  return lastTwoNumbers(value) === DECIMAL_ONE
    || lastTwoNumbers(value) === DECIMAL_TWO
    || lastTwoNumbers(value) === DECIMAL_THREE
    || lastTwoNumbers(value) === DECIMAL_FOUR;
};

const getIsOneEnd = (value: number): boolean => {
  return lastNumber(value) === ONE;
};

const getIsTwoFourRangeEnd = (value: number): boolean => {
  return lastNumber(value) === TWO || lastNumber(value) === THREE
    || lastNumber(value) === FOUR;
};

export const getIsFourEnd = (value: number): boolean => {
  return lastNumber(value) === FOUR;
};

export const getEnding = (value: number, isAccusative?: boolean) => {
  if (getIsDecimalEnd(value)) {
    return (
      <FormattedMessage
        id="order.create.title.lensesNumber.more"
      />
    );
  } else if (getIsOneEnd(value)) {
    return isAccusative ? (
      <FormattedMessage
        id="order.create.title.lensesNumber.one.accusative"
      />
    ) : (
      <FormattedMessage
        id="order.create.title.lensesNumber.one"
      />
    );
  } else if (getIsTwoFourRangeEnd(value)) {
    return (
      <FormattedMessage
        id="order.create.title.lensesNumber.two"
      />
    );
  } else {
    return (
      <FormattedMessage
        id="order.create.title.lensesNumber.more"
      />
    );
  }
};
