import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
  borderRadiuses,
  fontWeights,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '16px 12px',
    border: `1px solid ${palette.grey['400']}`,
    borderRadius: borderRadiuses.md,
    [breakpoints.up('sm')]: {
      padding: '20px 24px',
    },
  },
  cardContent: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  noveltyLabel: {
    marginTop: 20,
  },
  solutionsBrandLogo: {
    maxWidth: '40%',
    maxHeight: 150,
    marginRight: 20,
  },
  brandName: {
    margin: '0 0 5px 0',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.sm,
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.md,
    color: palette.text.secondary,
  },
  description: {
    margin: 0,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxs,
    color: palette.text.primary,
    lineHeight: lineHeights.xxs,
  },
}));
