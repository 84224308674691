import { CmsBannerType } from '../../../../../common/cms-banner';
import { LoyaltyType } from '../../../../../../graphql/promotions/models/get-promotions.models';
import { IBonus } from '../../../../../../graphql/bonuses/models/get-bonuses.models';

const isType = (data: IBonus[], type: LoyaltyType): boolean => {
  return !!(data.find((promotion) => {
    return promotion.name === type;
  }));
};

export const getBannerType = (data: IBonus[]) => {
  let bannerType: CmsBannerType | string = '';

  switch (true) {
    case isType(data, LoyaltyType.discountFirstPurchase):
      bannerType = CmsBannerType.userWithFirstWelcomeVoucher;
      break;
    case isType(data, LoyaltyType.discountSecondPurchase):
      bannerType = CmsBannerType.userWithSecondWelcomeVoucher;
      break;
    default:
      bannerType = CmsBannerType.userWithoutVouchers;
      break;
  }

  return bannerType;
};
