import {
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  FormControl as MuiFormControl,
  FormControlLabel as MuiFormControlLabel,
  FormHelperText as MuiFormHelperText,
} from '@mui/material';

import { Icon, IconTypes } from '../icon';
import { IRadioGroupProps } from './radio-group.models';
import { useStyles } from './radio-group.styles';
import { TFormExtendedCommonProps } from '../form';

export const RadioGroup = ({
  options,
  error,
  helperText,
  ...props
}: TFormExtendedCommonProps<IRadioGroupProps>): JSX.Element => {
  const classes = useStyles();

  const checkedIcon = <Icon type={IconTypes.radioChecked} />;
  const uncheckedIcon = <Icon type={IconTypes.radioUnchecked} />;
  const radio = (
    <MuiRadio
      checkedIcon={checkedIcon}
      icon={uncheckedIcon}
      classes={{
        root: classes.radioRoot,
      }}
    />
  );

  return (
    <MuiFormControl
      error={error}
      fullWidth
      classes={{
        root: classes.formControlRoot,
      }}
    >
      <MuiRadioGroup {...props}>
        {options.map((option) => (
          <MuiFormControlLabel
            key={option.value}
            {...option}
            classes={{
              root: classes.formControlLabelRoot,
              label: classes.formControlLabelLabel,
            }}
            control={radio}
          />
        ))}
      </MuiRadioGroup>
      {helperText && (
        <MuiFormHelperText
          classes={{
            root: classes.formHelperTextRoot,
            error: classes.formHelperTextError,
          }}
        >
          {helperText}
        </MuiFormHelperText>
      )}
    </MuiFormControl>
  );
};
