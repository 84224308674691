import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette, fontSizes, breakpoints }: Theme) => ({
  button: {
    margin: '0 auto',
    padding: '8px 48px',
  },
  notEarned: {
    margin: '0 auto',
    color: palette.grey['800'],
    fontSize: fontSizes.xs,
    lineHeight: 2.6,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: 2.4,
    },
  },
}));
