import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { GET_BARCODE } from '../../../../graphql/profile/queries';
import { IBarcodeData } from '../../../../graphql/profile/models/get-barcode.models';
import { addEvent } from '../../../../utils/analytics/add-event';
import { setMyAcuvueId } from '../../../../graphql/configuration/configuration.cache';
import { removeEvent } from '../../../../utils/analytics/remove-event';

export const MyAcuvueId = (): JSX.Element => {
  const { data } = useQuery<IBarcodeData>(GET_BARCODE);

  useEffect(() => {
    if (data?.profile?.myAcuvueId) {
      setMyAcuvueId(data.profile.myAcuvueId);
      addEvent('user_id', 'myacuvue_id', data.profile.myAcuvueId);
    }

    return () => {
      removeEvent('user_id', 'myacuvue_id');
    };
  }, [data]);

  return <></>;
};
