import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IStepProps } from '../../../../common/stepper';
import { WhatDoYouLikeStep } from './survey-steps/what-do-you-like-step';
import { BirthdayStep } from './survey-steps/birthday-step';
import { GadgetsUseDurationStep } from './survey-steps/gadgets-use-duration-step';
import { WearRegularityStep } from './survey-steps/wear-regularity-step';
import { TimeOutsideDurationStep } from './survey-steps/time-outside-duration-step';
import { LastVisionCheckStep } from './survey-steps/last-vision-check-step';
import { BirthdayTitle } from '../vision-profile-views/birthday-view/birthday-title';
import { GadgetsUseDurationTitle } from '../vision-profile-views/gadgets-use-duration-view/gadgets-use-duration-title';
import { LastVisionCheckTitle } from '../vision-profile-views/last-vision-check-view/last-vision-check-title';
import { TimeOutsideDuraionTitle } from '../vision-profile-views/time-outside-duration-view/time-outside-duration-title';
import { WearRegularityTitle } from '../vision-profile-views/wear-regularity-view/wear-regularity-title';
import { WhatDoYouLikeTitle } from '../vision-profile-views/what-do-you-like-view/what-do-you-like-title';

export const visionProfileSurveySteps:  IStepProps[] = [{
  id: 'dateOfBirth',
  title: <BirthdayTitle />,
  StepComponent: BirthdayStep,
  nextButtonLabel: <FormattedMessage id="vp.survey.next" />,
}, {
  id: 'wearRegularity',
  title: <WearRegularityTitle />,
  StepComponent: WearRegularityStep,
  nextButtonLabel: <FormattedMessage id="vp.survey.next" />,
}, {
  id: 'timeOutsideDuration',
  title: <TimeOutsideDuraionTitle />,
  StepComponent: TimeOutsideDurationStep,
  nextButtonLabel: <FormattedMessage id="vp.survey.next" />,
}, {
  id: 'gadgetsUseDuration',
  title: <GadgetsUseDurationTitle />,
  StepComponent: GadgetsUseDurationStep,
  nextButtonLabel: <FormattedMessage id="vp.survey.next" />,
}, {
  id: 'whatDoYouLike',
  title: <WhatDoYouLikeTitle />,
  StepComponent: WhatDoYouLikeStep,
  nextButtonLabel: <FormattedMessage id="vp.survey.next" />,
}, {
  id: 'lastVisionCheck',
  title: <LastVisionCheckTitle />,
  StepComponent: LastVisionCheckStep,
  nextButtonLabel: <FormattedMessage id="vp.survey.next" />,
}];
