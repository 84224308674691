import { IBrandParams } from '../../../../../../../graphql/lenses/models/get-brands.models';

export const replaceParameter = (
  actionPayload: IBrandParams[], brandParameters: IBrandParams[],
): IBrandParams[] => {
  let newBrandParameters = [...brandParameters];

  actionPayload.forEach(param => {
    const oldParamIndex = newBrandParameters.findIndex((brandParameter) => brandParameter.name === param.name);
    newBrandParameters[oldParamIndex] = param;
  });

  return  newBrandParameters;
};
