import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../../common/button';
import { IAppointmentCheckStepProps } from './appointment-check-step.models';
import { useStyles } from './appointment-check-step.styles';

export const AppointmentCheckStep = ({
  onGoToPicker, pickedDate, storeInfo,
}: IAppointmentCheckStepProps): JSX.Element => {
  const classes = useStyles();
  const {
    name, street, openinghours, phonenumber,
  } = storeInfo;

  const renderContent = () => {
    return (
      <>
        <div className={classes.opticAdress}>
          <h3 className={classes.title}>
            <FormattedMessage
              id="appointmentRegistration.appointmentCheckStep.opticAdress.title"
            />
          </h3>
          <p className={classes.description}>{name}</p>
          <p className={classes.description}>{street}</p>
          <p className={classes.description}>{openinghours}</p>
          <Button
            href={`tel:${phonenumber}`}
            labelClassName="normal"
          >
            {phonenumber}
          </Button>
        </div>
        <div className={classes.fittingDate}>
          <h3 className={classes.title}>
            <FormattedMessage
              id="appointmentRegistration.appointmentCheckStep.fittingsDate.title"
            />
          </h3>
          <div className={classes.description}>
            <span className={classes.dateText}>
              {pickedDate}
            </span>
            <Button
              to={onGoToPicker}
              labelClassName="normal"
              data-testid="change-date-button"
            >
              <FormattedMessage
                id="appointmentRegistration.appointmentCheckStep.fittingsDate.changeButton.label"
              />
            </Button>
          </div>
          <p className={classes.helperText}>
            <FormattedMessage
              id="appointmentRegistration.appointmentCheckStep.fittingsDate.helperText"
            />
          </p>
        </div>
      </>
    );
  };

  return (
    <div className={classes.root}>
      {renderContent()}
    </div>
  );
};
