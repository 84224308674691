import { IStore } from '../../graphql/order/models/get-orders.models';
import {
  IOrderStore,
} from '../../components/business/app/order/order-create-page/order-create-page.models';

export const getOrderStore = (store: IStore, rating: string): IOrderStore => ({
  name: store?.displayName,
  street: store?.address,
  phonenumber: store?.phone,
  openinghours: store?.workingHours,
  type: store?.storeSubType,
  rating,
  latitude: store?.latitude,
  longitude: store?.longitude,
  code: store?.code,
  city: store?.city,
});

export const getOrderStoreWithRating = (store: IOrderStore, rating: string): IOrderStore => ({
  name: store?.name,
  street: store?.street,
  phonenumber: store?.phonenumber,
  openinghours: store?.openinghours,
  type: store?.type,
  rating,
  latitude: store?.latitude,
  longitude: store?.longitude,
  code: store?.code,
  city: store?.city,
});
