import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import fittingProcess from '../../../../../assets/images/backgrounds/fitting-process.png';

export const useStyles = makeStyles(({
  breakpoints, palette, fontWeights, fontFamilies, fontSizes, lineHeights,
}: Theme) => ({
  title: {
    margin: '36px 0 12px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    color: palette.text.secondary,
    '@media (orientation: landscape)': {
      margin: '28px 0 4px',
    },
    [breakpoints.up('md')]: {
      margin: '42px 0 16px',
      fontSize: fontSizes.xl,
      lineHeight: 1.3,
    },
    [breakpoints.up('xl')]: {
      margin: '42px 0 16px',
      fontSize: fontSizes.xxl,
      lineHeight: 1.3,
    },
  },
  description: {
    marginBottom: 0,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.secondary,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: 1.4,
    },
  },
  propsPaperStyles: {
    paddingTop: 143,
    paddingBottom: 24,
    background: `linear-gradient(${palette.primary.main} 0, ${palette.primary.main} 143px, ${palette.common.white} 143px)`,
    '@media (orientation: landscape)': {
      paddingTop: 80,
      paddingBottom: 16,
      backgroundImage: `linear-gradient(${palette.primary.main} 0, ${palette.primary.main} 80px, ${palette.common.white} 80px)`,
    },
    [breakpoints.up('md')]: {
      paddingTop: 230,
      paddingBottom: 46,
      backgroundImage: `linear-gradient(${palette.primary.main} 0, ${palette.primary.main} 230px, ${palette.common.white} 230px)`,
    },
    [breakpoints.between('md', 813)]: {
      '@media (orientation: landscape)': {
        paddingTop: 80,
        paddingBottom: 16,
        backgroundImage: `linear-gradient(${palette.primary.main} 0, ${palette.primary.main} 80px, ${palette.common.white} 80px)`,
      },
    },
    '& button': {
      color: palette.common.white,
    },
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 'calc(100% - 34px)',
      height: 143,
      backgroundColor: 'transparent',
      backgroundImage: `url(${fittingProcess})`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 24,
      backgroundPositionY: 28,
      backgroundSize: 160,
      '@media (orientation: landscape)': {
        height: 80,
        backgroundSize: 72,
      },
      [breakpoints.up('md')]: {
        width: 'calc(100% - 64px)',
        height: 230,
        backgroundPositionX: 64,
        backgroundPositionY: 52,
        backgroundSize: 250,
      },
      [breakpoints.between('md', 813)]: {
        '@media (orientation: landscape)': {
          height: 80,
          backgroundSize: 72,
          backgroundPositionY: 28,
        },
      },
    },
    '& footer': {
      padding: '24px 24px 0',
      '@media (orientation: landscape)': {
        paddingTop: 16,
      },
      [breakpoints.up('md')]: {
        padding: '24px 64px 0',
      },
    },
  },
  propsContentStyles: {
    padding: '0 24px',
    [breakpoints.up('md')]: {
      padding: '0 64px',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    '@media (orientation: landscape)': {
      flexDirection: 'row',
    },
    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  register: {
    marginBottom: 12,
    '@media (orientation: landscape)': {
      minWidth: 236,
      marginBottom: 0,
      marginRight: 12,
    },
    [breakpoints.up('md')]: {
      minWidth: 236,
      marginBottom: 0,
      marginRight: 12,
    },
  },
  login: {
    minWidth: 236,
  },
}));
