import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import url from '../../../../../assets/images/internet-shops.svg';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
  fontWeights,
}: Theme) => ({
  root: {
    backgroundColor: palette.info.light,
    padding: '24px 0 24px 16px',
    marginBottom: 16,
    background: `url(${url}) right center no-repeat`,
    backgroundSize: 'auto 80%',
    [breakpoints.up('md')]: {
      padding: '32px 80px 32px 32px',
      marginBottom: 24,
    },
  },
  title: {
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
    margin: '0 0 12px',
  },
  description: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    margin: 0,
  },
  button: {
    marginTop: 28,
  },
}));
