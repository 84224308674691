import { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from './program-card.styles';
import { ProgramItem, Item } from '../items';
import { ExternalLinks, InternalLinks } from '../../../../../constants';
import { Button } from '../../../../common/button-new';
import { Content } from '../items';

export const ProgramCard = ({ title, content, icon }: ProgramItem): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  const getText = useCallback((textContent: string, url?: InternalLinks | ExternalLinks) => {
    return <>{intl.formatMessage(
      { id: textContent },
      {
        noBreakSpace: <>&nbsp;</>,
        a: (chunks: ReactNode) => <Button rootClasses={classes.link} style="text" isUnderline to={url} >{chunks}</Button>,
        sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
      },
    )}</>;
  }, [content]);

  const getContent = useCallback((itemContent: Content): ReactNode => {
    return itemContent.map((item) => {
      // @ts-ignore
      return item.paragraph?.text
        // @ts-ignore
        ? <p className={classes.paragraph}>{getText(item.paragraph.text, item.paragraph.url)}</p>
        : (
        // @ts-ignore
          <ul className={classes.list}>{(item.list as Item[]).map(
            (listItem, index: number) => <li key={index}>{getText(listItem.text, listItem.url)}</li>,
          )}</ul>
        );
    });
  }, [content]);

  return (
    <>
      <div className={classes.root}>
       <div className={classes.icon}><img src={icon} alt="" /></div>
        <h2 className={classes.title}>{intl .formatMessage(
          { id: title },
          { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
        )}</h2>
        <div>
          {getContent(content)}
        </div>
      </div>
    </>
  );
};
