import React, { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import GTM from 'react-gtm-module';
import { useTimer } from 'use-timer';

import { useBind } from '../../../../hooks/use-bind';
import { consumerApi } from '../../../../rest/consumer';
import { ILoginData, oauthApi } from '../../../../rest/oauth';
import { useCancellablePromise } from '../../../../hooks/use-cancellable-promise';
import { useLogin } from '../../../../graphql/user/actions/login';
import { getAuthorizationHeader } from '../../../../utils/request-headers/get-authorization-header';
import { Registration as RegistrationView } from './registration';
import { useSnackbar } from '../../../../hooks/use-snackbar';
import { useActionsInProgress } from '../../../../graphql/preloader/actions/actions-in-progress';
import { IStepsState } from './registration.models';
import { REQUEST_CODE_TIME } from '../../../../constants/one-time-pin';
import { setIsCaptcha } from '../../../../graphql/configuration/configuration.cache';
import { clearRedirectUrl, getRedirectUrl } from '../../../../utils/redirect/redirect';
import { useNavigate } from 'react-router-dom';

export const Registration = (): JSX.Element => {
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const { login } = useLogin();
  const { enqueueSnackbar } = useSnackbar();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const loginBind = useBind(login);
  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);
  const navigate = useNavigate();


  const onDone = useCallback(async (stepsState: IStepsState) => {
    const {
      phone: {
        formValues: { phoneNumber },
        token: { access_token: accessToken },
      },
      name,
      lensesExperience: { clExperience },
      email: {
        formValues: { email },
      },
      password: {
        pin, authoriseJnj, marketing, tnc,
      },
    } = stepsState;

    const consentData = new Date();

    const registrationData = {
      consent: [
        {
          consent: 'authoriseJnj',
          value: authoriseJnj,
          date: consentData,
        },
        {
          consent: 'marketing',
          value: marketing,
          date: consentData,
        },
        {
          consent: 'tnc',
          value: tnc,
          date: consentData,
        },
      ],
      ...name,
      email,
      username: phoneNumber,
      clExperience,
      pin,
      commPermissions: [],
    };

    addActionInProgressBind.current();

    try {
      await makeCancellablePromiseBind.current(consumerApi.register(registrationData, {
        headers: {
          Authorization: getAuthorizationHeader(accessToken),
        },
      }));
      try {
        GTM.dataLayer({
          dataLayer: {
            event: 'dl_event',
            event_category: 'success_registration',
          },
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Something wrong with GTM: ${e}`);
      }
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        // eslint-disable-next-line no-useless-return
        return;
      }

      throw new Error();
    }

    const loginData: ILoginData = {
      username: phoneNumber,
      password: pin,
    };

    try {
      const {
        data,
      } = await makeCancellablePromiseBind.current(oauthApi.login(loginData));

      removeActionInProgressBind.current();
      const redirectUrl = getRedirectUrl();
      if (redirectUrl) {
        navigate(redirectUrl);
        loginBind.current(data, true);
        clearRedirectUrl();
      } else {
        loginBind.current(data);
      }
    } catch (error) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        // eslint-disable-next-line no-useless-return
        return;
      }

      throw new Error();
    }
  }, [
    loginBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    enqueueSnackbarBind,
    addActionInProgressBind,
    removeActionInProgressBind,
  ]);

  const { time, reset, start, status } = useTimer({
    initialTime: REQUEST_CODE_TIME,
    timerType: 'DECREMENTAL',
    autostart: false,
    endTime: 0,
  });

  const isCaptcha = useReactiveVar(setIsCaptcha);

  return (
    <RegistrationView
      onDone={onDone}
      externalState={{ time, reset, start, status, isCaptcha }}
    />
  );
};
