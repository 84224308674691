import { forwardRef } from 'react';

import { DatePicker } from '../../../../../../common/form/date-picker';
import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { IDateStepFormProps } from './date-step-form.models';

export const DateStepForm = forwardRef<IFormImperativeHandleProps, IDateStepFormProps>(({
  minDate, maxDate, defaultValues, onSubmit,
}, ref): JSX.Element => (
  <Form
    ref={ref}
    defaultValues={defaultValues}
    onSubmit={onSubmit}
  >
    <DatePicker
      name="date"
      variant="static"
      minDate={minDate}
      maxDate={maxDate}
    />
  </Form>
));
