import { FormattedMessage } from 'react-intl';

import { Icon, IconSizes, IconTypes } from '../../../common/icon';
import { IToggleButtonOptions } from '../../../common/toggle-button';
import { MobileViewTypes } from '../../../../graphql/ecp-locator/models/map.models';

export const ecpToggleViewOptions: IToggleButtonOptions[] = [{
  value: MobileViewTypes.map,
  label: (
    <FormattedMessage
      id="ecp.mobileViewToggleButton.list.label"
    />
  ),
  icon: (
    <Icon
      type={IconTypes.list}
      size={IconSizes.sm}
    />
  ),
}, {
  value: MobileViewTypes.list,
  label: (
    <FormattedMessage
      id="ecp.mobileViewToggleButton.map.label"
    />
  ),
  icon: (
    <Icon
      type={IconTypes.mapView}
      size={IconSizes.sm}
    />
  ),
}];
