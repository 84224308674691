import { FormattedMessage } from 'react-intl';

import { LensesChangeButton } from './lenses-change-button';
import { LensesList } from './lenses-list';
import { ILensesProps } from './lenses.models';
import { useStyles } from './lenses.styles';

export const Lenses = ({
  products, isEditMode, onUpdateQuantity, onProductsChange,
}: ILensesProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      data-testid="lenses-wrapper"
    >
      <div
        data-testid="lenses-title-bar"
        className={classes.titleBar}
      >
        <h3
          data-testid="lenses-title"
          className={classes.title}
        >
          <FormattedMessage id="order.create.confirm.lenses.title" />
        </h3>
        {isEditMode && <LensesChangeButton
          onProductsChange={onProductsChange}
          isChangeButton={!!products.length}
        />}
      </div>
      <LensesList
        products={products}
        isEditMode={isEditMode}
        onUpdateQuantity={onUpdateQuantity}
      />
    </div>
  );
};
