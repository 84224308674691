import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  palette, lineHeights, fontSizes, borderRadiuses, fontWeights,
}: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: 16,
    padding: 16,
    borderRadius: borderRadiuses.md,
    backgroundColor: palette.error.light,
    '&:hover, &:focus': {
      backgroundColor: palette.error.light,
    },
  },
  exclamation: {
    marginRight: 12,
    lineHeight: 1,
  },
  contentWrapper: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 42px)',
  },
  message: {
    margin: 0,
    marginRight: 8,
    color: palette.text.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.regular,
    textAlign: 'left',
  },
  buttonInner: {
    height: 34,
    marginTop: 8,
    padding: '10px 12px',
    border: `1px solid ${palette.error.main}`,
    borderRadius: '2.1em',
    color: palette.error.main,
    fontSize: fontSizes.xxs,
    lineHeight: lineHeights.xxs,
  },
}));
