import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  root: {
    marginBottom: 12,
    [breakpoints.up('sm')]: {
      marginBottom: 20,
    },
  },
  container: {
    display: 'grid',
    rowGap: spacing(1.5),
    [breakpoints.up('sm')]: {
      rowGap: spacing(2.5),
    },
    [breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
      columnGap: spacing(2.5),
    },
  },
}));
