import { FC } from 'react';
import { useQuery } from '@apollo/client';

import { MyBonuses as MyBonusesView } from './my-bonuses';
import { IBonusesData } from '../../../../graphql/bonuses/models/get-bonuses.models';
import { isUserFitted } from '../../../../utils/lenses/is-user-fitted';
import { useGetBonuses } from './hooks/use-get-bonuses';
import { GET_POINTS_BONUSES } from '../../../../graphql/bonuses/queries/get-bonuses';

export const MyBonuses: FC = () => {
  const { data: bonuses, loading, error } = useQuery<IBonusesData>(GET_POINTS_BONUSES, {
    fetchPolicy: 'no-cache',
  });
  const hasPurchases = !!(bonuses?.purchasesByAuthId.length);
  const hasFittings = isUserFitted(bonuses);
  const balance = bonuses?.pointByAuthId.eligibleBalance;
  const bonusCards = useGetBonuses({ bonuses, balance, hasFittings, hasPurchases });

  return (
    <MyBonusesView
      isLoading={loading}
      isError={error}
      balance={balance}
      bonusCards={bonusCards}
    />
  );
};
