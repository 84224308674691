import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, fontWeights, lineHeights, palette,
}: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    '&&': {
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none',
    },
  },
  contentWrapper: {
    width: 'calc(100% - 96px)',
    marginLeft: 16,
    [breakpoints.up('sm')]: {
      width: 'calc(100% - 160px)',
      marginLeft: 20,
    },
  },
  brand: {
    position: 'relative',
    margin: '0 0 4px',
    fontSize: fontSizes.xs,
    lineHeight: 1.3,
    fontWeight: fontWeights.regular,
    [breakpoints.up('sm')]: {
      marginBottom: 8,
      fontSize: fontSizes.sm,
    },
  },
  image: {
    objectFit: 'contain',
    width: 80,
    marginTop: 2,
    [breakpoints.up('sm')]: {
      width: 140,
    },
  },
  imageAom1d: {
    width: 85,
    [breakpoints.up('sm')]: {
      width: 148,
    },
  },
  packSize: {
    marginLeft: 4,
  },
  packSizeFormControl: {
    marginBottom: 8,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 12,
    [breakpoints.up('sm')]: {
      marginTop: 16,
    },
  },
  packageNumber: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  deleteButton: {
    '& svg': {
      width: 14,
      height: 'auto',
    },
  },
  imageSolutionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: 80,
    marginTop: 2,
    [breakpoints.up('sm')]: {
      width: 140,
    },
  },
  imageSolutions: {
    objectFit: 'contain',
    width: 'auto',
    height: 74,
    [breakpoints.up('sm')]: {
      height: 90,
    },
  },
  volume: {
    margin: 0,
    color: palette.custom.greyNeutral,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
  },
}));
