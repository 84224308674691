import { forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { DatePicker } from '../../../../../../common/form/date-picker';
import { ILastVisionCheckFormProps } from './last-vision-check-form.models';
import { UI_DATE_FORMAT_NO_DAY } from '../../../../../../../constants';

export const LastVisionCheckForm = forwardRef<
IFormImperativeHandleProps, ILastVisionCheckFormProps>(({
  formProps,
  minDateOfCheck,
  maxDateOfCheck,
}, ref): JSX.Element => {
  const intl = useIntl();

  return (
    <Form
      ref={ref}
      {...formProps}
    >
      <DatePicker
        dateOpenPickerEnable
        inputFormat={UI_DATE_FORMAT_NO_DAY}
        name="lastVisionCheck"
        openTo={'year'}
        minDate={minDateOfCheck}
        maxDate={maxDateOfCheck}
        InputProps={{
          fullWidth: true,
          placeholder: intl.formatMessage({
            id: 'vp.lastVisionCheck.dataInput.placeholder',
          }),
          'data-testid': 'change-date-input',
        }}
        views={['year', 'month']}
        toolbarTitle={(
          <FormattedMessage id="vp.lastVisionCheck.dataInput.placeholder" />
        )}
      />
    </Form>
  );
});
