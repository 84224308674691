import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  palette, fontWeights,
}: Theme) => ({
  emphasis: {
    color: palette.primary.main,
    fontWeight: fontWeights.medium,
  },
}));
