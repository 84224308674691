import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TextField } from '../../../../common/form/text-field';
import { IChangePhoneFieldViewProps } from './change-phone-field.models';

export const ChangePhoneField = ({ onChangePhone }: IChangePhoneFieldViewProps) => (
  <TextField
    name="mobile"
    type="tel"
    fullWidth
    InputProps={{
      readOnly: true,
    }}
    label={(
      <FormattedMessage
        id="profile.telephoneTextField.label"
      />
    )}
    actionProps={{
      'data-testid': 'change-phone-button',
      onClick: onChangePhone,
      children: (
        <FormattedMessage
          id="profile.telephoneTextField.changeTelephoneButton.label"
        />
      ),
    }}
  />
);
