import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import fitLensesUrl from '../../../../../assets/images/how-to/fit-lenses.svg';
import buyFittedLensesUrl from '../../../../../assets/images/how-to/buy-fitted-lenses.svg';
import checkPointsUrl from '../../../../../assets/images/how-to/check-points.svg';
import { HowTo } from '../../../../common/how-to';

export const HowToAccumulatePoints = (): JSX.Element => {
  const intl = useIntl();

  return (
    <HowTo
      title={<FormattedMessage id="pointsHowTo.accumulatePoints.title" />}
      items={[{
        imageSource: fitLensesUrl,
        description: intl.formatMessage(
          { id: 'pointsHowTo.accumulatePoints.fitLenses.description' },
          { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
        ),
      }, {
        imageSource: buyFittedLensesUrl,
        description: intl.formatMessage(
          { id: 'pointsHowTo.accumulatePoints.buyFittedLenses.description' },
          { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
        ),
      }, {
        imageSource: checkPointsUrl,
        description: intl.formatMessage(
          { id: 'pointsHowTo.accumulatePoints.checkPoints.description' },
          {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            pointsAmountLink: (chunks: ReactNode) => (
              <a
                href={intl.formatMessage({ id: 'common.myAcuvuePolicy' })}
                target="_blank">
                  {chunks}
              </a>
            ),
          },
        ),
      }]}
    />
  );
};

