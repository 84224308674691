import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
}: Theme) => ({
  root: {
    backgroundColor: palette.common.white,
    boxShadow: `0 4px 8px ${palette.grey['300']}`,
    borderRadius: '12px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.common.black,
    padding: 16,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  icon: {
    padding: 0,
    marginRight: 8,
  },
  message: {
    padding: 0,
  },
}));
