import {
  ComponentType,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { CmsBannerView } from './cms-banner';
import {
  ICmsBannerContainerProps,
  ICmsBannerProps,
} from './cms-banner.models';
import { useRefreshToken } from '../../../hooks/use-refresh-token';
import { useBind } from '../../../hooks/use-bind';
import { useCancellablePromise } from '../../../hooks/use-cancellable-promise';
import { cmsApi } from '../../../rest/cms/cms.api';
import { Breakpoints } from '../../../themes/default';

const withBanner = <P extends object>(WrappedComponent: ComponentType<P>) => (
  (props: P): JSX.Element | null => {
    const cmsBannersEnabled = process.env.REACT_APP_CMS_BANNERS_ENABLED === 'true';

    if (!cmsBannersEnabled) {
      return null;
    }

    return <WrappedComponent {...props} />;
  }
);

export const CmsBanner = withBanner((
  { url, pwaCallToActionLink }: ICmsBannerContainerProps,
): JSX.Element | null => {
  const { isTokenRefreshing, refreshTokenPromise } = useRefreshToken();
  const [imageData, setImageData] = useState<ICmsBannerProps | null>(null);
  const setImageDataBind = useBind(setImageData);
  const [bannerUrl, setBannerUrl] = useState<string>('');

  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);

  const getImage = useCallback(
    async () => {
      try {
        if (isTokenRefreshing) {
          await makeCancellablePromiseBind.current(refreshTokenPromise);
        }

        const image = await makeCancellablePromiseBind.current(
          cmsApi.getBanner(bannerUrl),
        );

        const blob = URL.createObjectURL(image.data);

        setImageDataBind.current({
          source: blob,
          link: pwaCallToActionLink,
        });
      } catch (error) {
        if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
          // eslint-disable-next-line no-useless-return
          return;
        }
      }
    }, [
      makeCancellablePromiseBind,
      CancelledPromiseOnUnmountErrorBind,
      setImageDataBind,
      isTokenRefreshing,
      refreshTokenPromise,
      bannerUrl,
    ],
  );

  const getBannerUrl = () => {
    const { url: mobileUrl, desktopUrl, tabletUrl } = url;

    const width = window.innerWidth;
    const isDesktopWidth = width > Breakpoints.lg || width === Breakpoints.lg;
    const isTabletWidth =  width < Breakpoints.lg && width > Breakpoints.sm;
    const isMobileWidth = width < Breakpoints.sm || width === Breakpoints.sm;

    if (isDesktopWidth) {
      setBannerUrl(desktopUrl);
    } else if (isTabletWidth) {
      setBannerUrl(tabletUrl);
    } else if (isMobileWidth) {
      setBannerUrl(mobileUrl);
    }
  };

  useEffect(() => {
    getBannerUrl();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', getBannerUrl);

    return () => window.removeEventListener('resize', getBannerUrl);
  }, []);

  useEffect(() => {
    if (bannerUrl) {
      getImage();
    }
  }, [bannerUrl]);

  const renderCmsBanner = () => {
    if (!imageData) {
      return null;
    }
    return <CmsBannerView {...imageData} />;
  };

  return <>{renderCmsBanner()}</>;
});
