import { useCallback, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';

import { useEcpStoresFromCache } from '../graphql/ecp-locator/actions/ecp-stores';
import { filterParams, setUserLocation } from '../graphql/ecp-locator/ecp-locator.cache';
import { TFiltersData } from '../graphql/ecp-locator/models/filter-params.models';
import { ecpLocatorApi } from '../rest/ecp-locator';
import { useSetFilterParams } from '../graphql/ecp-locator/actions/set-filter-params';
import { useEcpUserLocation } from '../graphql/ecp-locator/actions/user-location';
import { DEFAULT_RANGE } from '../constants/ecp-locator';
import { filteringByStores, EcpMapUserLocation } from '../utils/ecp-locator';
import { EcpStoreType } from '../rest/ecp-locator';
import { shopTypeFilteringItems } from '../components/business/app/ecp-filters-modal/filtering-criteria-items/filtering-criteria-items';

export interface IUpdateStores {
  filters?: TFiltersData;
  userLocation?: EcpMapUserLocation;
}

export const useUpdateEcpStores = () => {
  const { setEcpStoresToCache } = useEcpStoresFromCache();
  const { setFilterParamsToCache } = useSetFilterParams();
  const { setEcpUserLocationToCache } = useEcpUserLocation();
  const filtersCurrent = useReactiveVar(filterParams);
  const userLocationCurrent = useReactiveVar(setUserLocation);
  const [isStoresLoading, setLoadingState] = useState(false);
  const [isStoresError, setErrorState] = useState(false);

  const updateStores = useCallback(async ({ filters, userLocation }: IUpdateStores) => {
    let filtersApplied = filtersCurrent;
    let userLocationApplied = userLocationCurrent;

    if (filters) {
      filtersApplied = filters;
    }

    if (userLocation) {
      userLocationApplied = userLocation;
    }

    const { specializations, services, shops } = filtersApplied;
    const { latitude, longitude } = userLocationApplied;
    const isC2cStores = services === EcpStoreType.c2cActivated;

    try {
      setLoadingState(true);
      const { data } = await ecpLocatorApi.getStores({
        lat: latitude,
        long: longitude,
        range: DEFAULT_RANGE,
        sorting: 'rating',
        speciality: specializations.join(','),
        type: isC2cStores ? '' : services || '',
        c2cActivated: isC2cStores,
      });

      if (filters) {
        setFilterParamsToCache(filters);
      }

      if (userLocation) {
        setEcpUserLocationToCache(userLocation);
      }

      setEcpStoresToCache(filteringByStores(data, shops, shopTypeFilteringItems.length));
    } catch {
      setErrorState(true);
    }
    setLoadingState(false);
  }, [
    filtersCurrent,
    userLocationCurrent,
    setEcpStoresToCache,
    setEcpUserLocationToCache,
    setFilterParamsToCache,
  ]);

  useEffect(() => () => {
    setLoadingState(false);
  }, []);

  return { updateStores, isStoresLoading, isStoresError };
};
