import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    justifyContent: 'flex-end',
    height: '100%',
    [breakpoints.up('sm')]: {
      paddingTop: 28,
    },
  },
}));
