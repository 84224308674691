import { parse } from './get-match-substring';
import { IPlace } from '../google-map-autocomplete.models';

export const parseMatchedSubstrings = (
  placeOption: string | IPlace,
): { text: string; highlight: boolean }[] | undefined => {
  if (typeof placeOption === 'string') {
    return;
  }
  const matches = placeOption.structured_formatting.main_text_matched_substrings;
  return parse(
    placeOption.structured_formatting.main_text,
    matches?.map(
      (match: { offset: number, length: number }) => [
        match.offset, match.offset + match.length,
      ],
    ),
  );
};
