import { FormattedMessage, useIntl } from 'react-intl';

import { SolutionsErrorModal } from '../solutions-error-modal';
import { useStyles } from './solutions-contract-modal.styles';
import { ISolutionsContractModalProps } from './solutions-contract-modal.models';
import { ReactNode } from 'react';

export const SolutionsContractModal = ({ ...props }: ISolutionsContractModalProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <SolutionsErrorModal
      {...props}
      data-testid="solutions-limit-modal"
      title={<FormattedMessage id="order.solutions.contract.modal.title" />}
      content={(
        <p
          data-testid="content-message"
          className={classes.content}
        >
          {intl.formatMessage({
            id: 'order.solutions.contract.modal.content',
          }, {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
          })}
        </p>
      )}
      button={<FormattedMessage id="order.solutions.contract.modal.button" />}
    />
  );
};
