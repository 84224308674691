import { gql } from '@apollo/client';

export const GET_POINTS_BONUSES = gql`
  query getPointsBonuses {
    voucherByAuthId {
      id
      packSize
      name
      value
      type
      endDate
      externalLink
      description
      voucherType
      brands {
        product
        productType
        fullName
        labels {
          name
          labelEndDate
          labelStartDate
        }
      }
    }
    pointByAuthId {
      id
      eligibleBalance
    }
    consumerLensesByAuthID {
      id
      createdManual
    }
    purchasesByAuthId {
      id
    }
  }
`;
