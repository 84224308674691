import { FormattedMessage } from 'react-intl';

import { WarningModal } from '../warning-modal';
import { ICheckBonusModalProps } from './check-bonus-modal.models';
import { Icon, IconSizes, IconTypes } from '../../../../../../../common';

export const CheckBonusModal = ({ onClose, ...props }: ICheckBonusModalProps): JSX.Element => {
  const handleConfirm = () => {
    onClose!();
  };

  return (
    <WarningModal
      {...props}
      data-testid="check-bonus-modal"
      topIcon={(
        <Icon
          type={IconTypes.infoVoucher}
          size={IconSizes['3xl']}
        />
      )}
      title={<FormattedMessage id="order.bonuses.check.modal.title" />}
      description={(
        <>
          <p>
            <FormattedMessage id="order.bonuses.check.modal.description.first" />
          </p>
          <p>
            <FormattedMessage id="order.bonuses.check.modal.description.second" />
          </p>
        </>
      )}
      confirmButtonLabel={<FormattedMessage id="order.bonuses.modal.gotItButton" />}
      isMultiContent
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
};
