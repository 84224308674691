import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { useStyles } from './general-cookie-section.styles';

export const GeneralCookieSection = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <section
      className={classes.policySection}
      data-testid="cookie-general"
      aria-label={intl.formatMessage({ id: 'cookiePolicy.generalSection.ariaLabel' })}
    >
      <h2
        data-testid="cookie-subtitle-2"
        className={classes.title}
      >
        <FormattedMessage id="cookiePolicy.subtitle2.welcome" />
      </h2>
      <div
        data-testid="cookie-text-welcome"
        className={classes.paragraphGroup}
      >
        {intl.formatMessage(
          { id: 'cookiePolicy.text.welcome' },
          {
            p: (chunks: ReactNode) => <p className={cx(classes.paragraph, classes.paragraphInline)}>{chunks}</p>,
            a: (chunks: ReactNode) => (
              <a
                className={classes.link}
                href={`http://${chunks}`}
                target="_blank"
              >
                {chunks}
              </a>
            ),
          },
        )}
      </div>
      <h2
        data-testid="cookie-subtitle-2"
        className={classes.title}
      >
        <FormattedMessage id="cookiePolicy.subtitle2.consentDescription" />
      </h2>
      <div data-testid="cookie-text-consent-description">
        {intl.formatMessage(
          { id: 'cookiePolicy.text.consentDescriptionPlain' },
          { p: (chunks: ReactNode) => <p className={classes.paragraph}>{chunks}</p> },
        )}
      </div>
      <ol
        className={classes.orderedRulesList}
        data-testid="cookie-text-consent-list-ordered"
      >
        {intl.formatMessage(
          { id: 'cookiePolicy.text.consentDescriptionList' },
          { li: (chunks: ReactNode) => <li data-testid="cookie-consent-list-item">{chunks}</li> },
        )}
      </ol>
      <h2
        data-testid="cookie-subtitle-2"
        className={classes.title}
      >
        <FormattedMessage id="cookiePolicy.subtitle2.consentGettingOptions" />
      </h2>
      <div data-testid="cookie-text-consent-getting">
        {intl.formatMessage(
          { id: 'cookiePolicy.text.consentGettingOptions1' },
          { p: (chunks: ReactNode) => <p className={classes.paragraph}>{chunks}</p> },
        )}
      </div>
      <ul
        className={classes.unorderedRulesList}
        data-testid="cookie-text-consent-list-unordered"
      >
        {intl.formatMessage(
          { id: 'cookiePolicy.text.consentGettingWayList' },
          { li: (chunks: ReactNode) => (
              <li data-testid="cookie-consent-list-item-unordered">{chunks}</li>
          ) },
        )}
      </ul>
      <p
        className={classes.paragraph}
        data-testid="cookie-text-consent-getting"
      >
        <FormattedMessage id="cookiePolicy.text.consent.threeLevelApproach" />
      </p>
      <ul
        className={classes.unorderedRulesList}
        data-testid="cookie-text-consent-list-unordered"
      >
        {intl.formatMessage(
          { id: 'cookiePolicy.text.consent.threeLevelApproachList' },
          {
            li: (chunks: ReactNode) => (
              <li data-testid="cookie-consent-list-item-unordered">{chunks}</li>
            ),
            strong: (chunks: ReactNode) => (
              <strong className={classes.strongText}>{chunks}</strong>
            ),
          },
        )}
      </ul>
      <div data-testid="cookie-text-consent-getting">
        {intl.formatMessage(
          { id: 'cookiePolicy.text.consentGettingOptions2' },
          {
            p: (chunks: ReactNode) => (
              <p className={classes.paragraph}>{chunks}</p>
            ),
            strong: (chunks: ReactNode) => (
              <strong className={classes.strongText}>{chunks}</strong>
            ),
          },
        )}
      </div>
      <h3 className={classes.strongTitle} data-testid="cookie-subtitle-3">
        <FormattedMessage id="cookiePolicy.subtitle3.links" />
      </h3>
      <p
        className={classes.paragraph}
        data-testid="cookie-text-consent-getting"
      >
        <FormattedMessage id="cookiePolicy.text.links" />
      </p>
      <h3 className={classes.strongTitle} data-testid="cookie-subtitle-3">
        <FormattedMessage id="cookiePolicy.subtitle3.contactUs" />
      </h3>
      <div data-testid="cookie-text-consent-getting">
        {intl.formatMessage(
          { id: 'cookiePolicy.text.contactUs' },
          {
            p: (chunks: ReactNode) => <p className={classes.paragraph}>{chunks}</p>,
            linebreak: <br/>,
          },
        )}
      </div>
      <h3 className={classes.strongTitle} data-testid="cookie-subtitle-3">
        <FormattedMessage id="cookiePolicy.subtitle3.changeNotice" />
      </h3>
      <p
        className={classes.paragraph}
        data-testid="cookie-text-consent-getting"
      >
        <FormattedMessage id="cookiePolicy.text.changeNotice" />
      </p>
    </section>
  );
};
