import { FormattedMessage, useIntl } from 'react-intl';
import { cx } from '@emotion/css';
import { useModal } from 'mui-modal-provider';

import { Button, IconTypes, Tooltip, Icon } from '../../../../../../common';
import { IconButton } from '../../../../../../common/icon-button';
import { BonusChangeModal } from '../bonus/bonus-change-modal';
import { INoBonusProps } from './no-bonus.models';
import { useStyles } from './no-bonus.styles';
import { getEnding } from '../../../../../../../utils/get-ending';

export const NoBonus = ({
  packageNumber, points, isActiveStatus, onBonusSelect, bonusesData, isEditOrder,
}: INoBonusProps): JSX.Element => {
  const  classes = useStyles();
  const intl = useIntl();
  const { showModal } = useModal();

  const handleBonusAdd = () => {
    showModal(BonusChangeModal, {
      onBonusSelect,
      bonusesData,
      isEditOrder,
    });
  };

  return (
    <>
      <div
        data-testid="points"
        className={cx(classes.innerWrapper, classes.pointsWrapper)}
      >
        <p
          className={classes.tooltipWrapper}
        >
          <FormattedMessage id="order.create.confirm.bonuses.points" />
          <Tooltip
            placement="bottom"
            title={(
              <p
                data-testid="points-tooltip-content"
                className={classes.tooltipContent}
              >
                <FormattedMessage
                  id="order.create.confirm.bonuses.points.tooltip"
                  values={{
                    value: points,
                    packageNumber,
                    ending: getEnding(packageNumber, true),
                  }}
                />
              </p>
            )}
            withArrow
            rootStyles={classes.tooltipButton}
            tooltipClass={classes.tooltip}
          >
            <IconButton
              className={classes.infoIcon}
              data-testid="points-tooltip-button"
              aria-label={intl.formatMessage({
                id: 'order.create.points.tooltip.button.ariaLabel',
              })}
              iconProps={{
                type: IconTypes.info,
              }}
            />
          </Tooltip>
        </p>
        <p
          data-testid="points-value"
          className={classes.points}
        >
          <FormattedMessage
            id="order.create.confirm.bonuses.points.value"
            values={{
              value: points,
            }}
          />
        </p>
      </div>
      {(isActiveStatus && bonusesData?.voucherByAuthId?.length) && (
        <div
          data-testid="add-bonus-wrapper"
          className={classes.innerWrapper}
        >
          <Button
            data-testid="add-bonus-button"
            className={classes.addBonusButton}
            onClick={handleBonusAdd}
          >
          <span
            aria-hidden={true}
            className={classes.plusIconWrapper}
          >
            <Icon
              type={IconTypes.plusRoundedBlue}
              className={classes.plusIcon}
            />
          </span>
            <FormattedMessage id="order.create.confirm.bonuses.addBonus" />
          </Button>
          <Tooltip
            placement="bottom"
            title={(
              <p
                data-testid="bonuses-tooltip-content"
                className={classes.tooltipContent}
              >
                <FormattedMessage
                  id="order.create.confirm.bonuses.addBonus.tooltip"
                  values={{
                    value: 100,

                  }}
                />
              </p>
            )}
            withArrow
            rootStyles={classes.tooltipButton}
            tooltipClass={classes.tooltip}
          >
            <IconButton
              className={classes.infoIcon}
              data-testid="bonuses-tooltip-button"
              aria-label={intl.formatMessage({
                id: 'order.create.points.tooltip.button.ariaLabel',
              })}
              iconProps={{
                type: IconTypes.info,
              }}
            />
          </Tooltip>
        </div>
      )}
    </>
  );
};
