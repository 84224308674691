import { AxiosRequestConfig } from 'axios';
import { restClient, restClientRecaptcha } from '../rest-client';
import {
  IConfirmPhoneNumberData,
  IConfirmPhoneNumberResponse,
  ILoginData,
  IRegisterPhoneNumberData,
  ITokenData,
  IRegisterEmailData,
  IRefreshTokenFailedData,
} from './oauth.models';
import { OAuthRequestUrls } from './oauth.request-urls';

export const oauthApi = {
  login(data: ILoginData) {
    return restClient.post<ITokenData>(OAuthRequestUrls.login, {
      ...data,
      grant_type: 'password',
    });
  },
  registerPhoneNumber(data: IRegisterPhoneNumberData) {
    return restClient.post<ITokenData>(OAuthRequestUrls.registerMobile, data);
  },
  registerPhoneNumberRecaptcha(data: IRegisterPhoneNumberData, token: string) {
    return restClientRecaptcha(token).post<ITokenData>(OAuthRequestUrls.registerMobile, data);
  },
  confirmPhoneNumber(data: IConfirmPhoneNumberData, config: AxiosRequestConfig) {
    return restClient.post<IConfirmPhoneNumberResponse>(
      OAuthRequestUrls.confirmMobile, data, config,
    );
  },
  refreshToken(data: ITokenData | null) {
    return restClient.post<ITokenData | IRefreshTokenFailedData>(OAuthRequestUrls.refresh, {
      ...data,
      grant_type: 'refresh_token',
    });
  },
  registerEmail(data: IRegisterEmailData, config: AxiosRequestConfig) {
    return restClient.post<ITokenData>(OAuthRequestUrls.registerEmail, data, config);
  },
};
