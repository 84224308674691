import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ fontWeights, breakpoints }: Theme) => ({
  root: {
    marginBottom: 20,
    [breakpoints.up('md')]: {
      marginBottom: 16,
    },
  },
  noBreak: {
    whiteSpace: 'nowrap',
  },
  strongText: {
    fontWeight: fontWeights.medium,
  },
  partial: {
    marginTop: 0,
    marginBottom: 0,
  },
  item: {
    marginBottom: 16,
  },
}));
