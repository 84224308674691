import { useCallback } from 'react';
import { useModal } from 'mui-modal-provider';

import { ChangeParametersButton as ChangeParametersButtonView } from './change-parameters-button';
import { IChangeButtonProps } from './change-parameters-button.models';
import { ChangeParametersModal } from '../change-parameters-modal';

export const ChangeParametersButton = ({
  onUpdateParameters, parameters, brandParameters,
}: IChangeButtonProps): JSX.Element => {
  const { showModal } = useModal();

  const handleChangeParameters = useCallback(() => {
    showModal(ChangeParametersModal, {
      parameters: parameters,
      brandParameters: brandParameters,
      onUpdateParameters: onUpdateParameters,
    });
  }, [
    showModal,
    parameters,
    brandParameters,
    onUpdateParameters,
  ]);

  return (
    <ChangeParametersButtonView
      onClick={handleChangeParameters}
    />
  );
};
