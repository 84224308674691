import { setTranslationsToStorage } from '../../../utils/cms/set-translations-to-storage';
import { translations } from '../cms.cache';

export const setTranslations = (translationsData: object) => {
  setTranslationsToStorage(translationsData);
  translations(translationsData);
};

export const useSetTranslations = () => ({
  setTranslations,
});
