import { useEffect, useContext } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { useModal } from 'mui-modal-provider';

import { UnsavedChangesConfirmationModal } from '../unsaved-changes-confirmation-modal';
import { ILeavePrompt } from './leave-prompt.models';
import { useScreenState } from '../../../graphql/user/actions/screenBlock';

// TODO: temporary solution while react-router-dom doesn`t support Prompt
export const LeavePrompt = ({ confirmationModalProps }: ILeavePrompt): null => {
  const { setStateToBlocked, setStateToUnblock } = useScreenState();
  const { navigator } = useContext(NavigationContext);
  const { showModal } = useModal();

  useEffect(() => {
    // @ts-ignore
    const unblock = navigator.block((tx) => {
      const { hide } = showModal(UnsavedChangesConfirmationModal, {
        ...confirmationModalProps,
        onConfirm: () => {
          unblock();
          setStateToUnblock();
          tx.retry();
          hide();
        },
        onReject: () => {
          hide();
        },
      });
    });

    setStateToBlocked(unblock);

    return () => {
      unblock();
      setStateToUnblock();
    };
  }, []);

  return null;
};
