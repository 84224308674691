import { useRef, useMemo } from 'react';
import { useModal } from 'mui-modal-provider';
import { FormattedMessage } from 'react-intl';

import { IPackSizeProps } from './pack-size.models';
import { PackSizeModal } from './pack-size-modal';
import { Breakpoints } from '../../../../../../../themes/default';
import { getIsFourEnd } from '../../utils/get-ending';
import { Icon, IconSizes, IconTypes } from '../../../../../../common';
import { useStyles } from './pack-size.styles';

export const PackSize = ({
  packSizes, onChangePackSize, packSizeSelected, isSolutionsBrand,
}: IPackSizeProps): JSX.Element => {
  const classes = useStyles();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const packSizeValue = String(packSizeSelected);

  const handleSetValue = (selectedValue: string) => {
    onChangePackSize(selectedValue, true);
  };

  const { showModal } = useModal();

  const width = window.innerWidth;
  const isMobileWidth = width < Breakpoints.sm || width === Breakpoints.sm;

  const handleButtonClick = () => {
    if (isMobileWidth) {
      showModal(PackSizeModal, {
        packSizeActive: String(packSizeSelected),
        packSizes,
        onValueSelected: handleSetValue,
        isMobile: true,
        isSolutionsBrand,
      });
    } else {
      showModal(PackSizeModal, {
        packSizeActive: String(packSizeSelected),
        packSizes,
        onValueSelected: handleSetValue,
        position: {
          top: buttonRef.current?.getBoundingClientRect().top! + 38,
          left: buttonRef.current?.getBoundingClientRect().left,
        },
        isSolutionsBrand,
      });
    }
  };

  const packSizeContent = useMemo(() => {
    return getIsFourEnd(Number(packSizeValue)) ? (
      <FormattedMessage
        id={isSolutionsBrand ? 'order.create.solutionsNumber.unit' : 'order.create.lensesNumber.unit.four'}
        values={{
          nonBreakSpace: <>&nbsp;</>,
          value: packSizeValue,
        }}
      />
    ) : (
      <FormattedMessage
        id={isSolutionsBrand ? 'order.create.solutionsNumber.unit' : 'order.create.lensesNumber.unit'}
        values={{
          nonBreakSpace: <>&nbsp;</>,
          value: packSizeValue,
        }}
      />
    );
  }, [getIsFourEnd, isSolutionsBrand, packSizeValue]);

  return (
    <div
      className={isSolutionsBrand ? classes.rootSolutions : classes.root}
      data-testid="pack-size"
    >
      <button
        type="button"
        data-testid="select-button"
        onClick={handleButtonClick}
        className={classes.button}
        ref={buttonRef}
      >
        <span data-testid="mobile-select-label">
          {packSizeContent}
        </span>
        <span
          className={classes.selectIcon}
          data-testid="pack-size-icon-wrapper"
          aria-hidden="true"
        >
          <Icon
            size={IconSizes.xs}
            type={IconTypes.arrowDown}
          />
        </span>
      </button>
    </div>
  );
};
