import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';

import { MyOrdersPage as MyOrdersPageView } from './my-orders-page';
import { IOrders } from '../../../../../graphql/order/models/get-orders.models';
import { GET_ORDERS } from '../../../../../graphql/order/queries/get-orders';
import { useGoBack } from '../../../../../hooks/use-go-back';
import { C2CMode } from '../../../../common/configuration-settings/configuration-settings.models';
import { setC2CMode } from '../../../../../graphql/configuration/configuration.cache';

export const MyOrdersPage = (): JSX.Element => {
  const { data, loading, error } = useQuery<IOrders>(GET_ORDERS);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { goBack } = useGoBack();

  const handleBack = () => goBack();
  const c2CMode = useReactiveVar(setC2CMode);

  useEffect(() => {
    if (c2CMode === C2CMode.disabled) {
      handleBack();
    }

    if (loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }

    if (error) {
      handleBack();
    }
  }, [error, loading, c2CMode]);

  return (
    <>{c2CMode !== C2CMode.disabled && (
      <MyOrdersPageView
        isLoading={isLoading}
        ordersData={data}
      />
    )}</>
  );
};
