import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ fontWeights }: Theme) => ({
  root: {
    marginBottom: '0.75rem',
  },
  icon: {
    marginRight: '0.5rem',
  },
  text: {
    fontWeight: fontWeights.medium,
  },
}));
