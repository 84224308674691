import { FormattedMessage, useIntl } from 'react-intl';
import { differenceInCalendarDays } from 'date-fns';

import { ConsumerBanModal } from '../../../../../../common/consumer-ban-modal';
import { IPurchasesBanModalViewProps } from './purchases-ban-modal.models';
import { convertStringToDate } from '../../../../../../../utils/date/convert-string-to-date';
import { convertDateToUIString } from '../../../../../../../utils/date/convert-date-to-string';

export const PurchasesBanModal = ({
  startBanDate, endBanDate, ...props
}: IPurchasesBanModalViewProps): JSX.Element => {
  const intl = useIntl();
  const banEndDate = convertDateToUIString(convertStringToDate(endBanDate));
  const banDaysAmount = differenceInCalendarDays(
    convertStringToDate(endBanDate)!, convertStringToDate(startBanDate)!,
  );

  return (
    <ConsumerBanModal
      {...props}
      data-testid="purchases-ban-modal"
      title={(
        <FormattedMessage
          id="purchasesConsumerBan.banModal.title"
          values={{ banDaysAmount }}
        />
      )}
      description={(
        <FormattedMessage
          id="purchasesConsumerBan.banModal.description"
          values={{ banEndDate }}
        />
      )}
      actionButtonProps={{
        href: intl.formatMessage({ id: 'common.phoneNumber.support.href' }),
        children: <FormattedMessage id="common.phoneNumber.support" />,
      }}
    />
  );
};
