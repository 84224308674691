import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperCore, { A11y } from 'swiper';

import { ICarouselProps } from './carousel.models';

SwiperCore.use([A11y]);

export const Carousel = ({ children, ...props }: ICarouselProps): JSX.Element => {
  return (
    <Swiper
      {...props}
      data-testid="carousel"
    >
      {
        children.map(
          (child: JSX.Element, index: number) => (
            <SwiperSlide data-testid="carousel-slide" key={index}>
              {child}
            </SwiperSlide>
          ),
        )
      }
    </Swiper>
  );
};
