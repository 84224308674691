import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import star from '../../../../assets/images/backgrounds/star.svg';

export const useStyles = makeStyles(({
  fontWeights,
  fontFamilies,
  palette,
  fontSizes,
  breakpoints,
  lineHeights,
  borderRadiuses,
  siteMinWidth,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    width: '100%',
    minWidth: siteMinWidth,
    [breakpoints.up('xl')]: {
      flexDirection: 'row',
    },
  },
  appointmentInfo: {
    padding: '32px 16px 40px',
    borderRadius: '16px 16px 0 0',
    marginTop: -16,
    zIndex: 1,
    background: palette.common.white,
    [breakpoints.up('sm')]: {
      padding: '44px 40px',
    },
    [breakpoints.up('xl')]: {
      width: '42%',
      maxWidth: 605,
      padding: 48,
      marginTop: 0,
      zIndex: 'unset',
      borderRadius: 0,
    },
  },
  appointmentMap: {
    height: '43vh',
    maxHeight: '100%',
    width: '100%',
    [breakpoints.up('sm')]: {
      height: '50.5vh',
      display: 'flex',
    },
    [breakpoints.up('xl')]: {
      flexGrow: 1,
      position: 'sticky',
      top: 0,
      width: '58%',
      height: 'calc(100vh - 120px)',
    },
  },
  title: {
    position: 'relative',
    marginTop: 0,
    marginBottom: 32,
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      fontSize: '21px',
      lineHeight: lineHeights.xl,
    },
  },
  titleButton: {
    display: 'block',
    position: 'relative',
    padding: '0 0 0 25px',
    border: 'none',
    background: 'transparent',
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    textAlign: 'left',
    [breakpoints.up('sm')]: {
      fontSize: '21px',
      lineHeight: lineHeights.xl,
    },
    [breakpoints.up('md')]: {
      padding: 0,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  backButton: {
    position: 'absolute',
    paddingRight: 5, // to cover gap between title & icon
    top: -4,
    left: -10,
    [breakpoints.up('md')]: {
      top: -2,
      left: -36,
    },
  },
  content: {
    marginBottom: 24,
    [breakpoints.up('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 20,
    },
    [breakpoints.up('xl')]: {
      flexDirection: 'column',
    },
  },
  bottomBorderSeparator: {
    [breakpoints.up('sm')]: {
      paddingBottom: 24,
      marginBottom: 24,
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderColor: palette.grey['200'],
    },
    [breakpoints.up('xl')]: {
      paddingBottom: 0,
      marginBottom: 20,
      borderBottom: 'none',
    },
  },
  contentPartial: {
    [breakpoints.up('sm')]: {
      width: '47%',
    },
    [breakpoints.up('xl')]: {
      width: 'unset',
    },
    '&:nth-of-type(2)': {
      [breakpoints.up('sm')]: {
        marginLeft: '3vh',
      },
      [breakpoints.up('xl')]: {
        marginLeft: 0,
      },
    },
  },
  ratingWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '0 0 8px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  rating: {
    paddingLeft: 26,
    backgroundImage: `url(${star})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '16px 16px',
    backgroundPosition: 'left 4px top 2px',
  },
  pointSeparator: {
    position: 'relative',
    paddingRight: 11,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: 4,
      top: '50%',
      transform: 'translateY(-50%)',
      height: 2,
      width: 2,
      borderRadius: borderRadiuses.xxl,
      background: palette.text.primary,
    },
  },
  street: {
    position: 'relative',
    margin: '0 0 4px',
  },
  phoneWrapper: {
    position: 'relative',
    marginTop: 0,
    marginBottom: 0,
  },
  phone: {
    margin: 0,
    color: palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      outline: '1px dotted',
      outlineColor: palette.primary.main,
    },
  },
  hours: {
    position: 'relative',
    maxWidth: 171,
    margin: '0 0 4px',
    [breakpoints.up('sm')]: {
      maxWidth: 193,
    },
  },
  secondaryTitle: {
    width: '100%',
    marginTop: 0,
    marginBottom: 4,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
  },
  paragraph: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  date: {
    marginTop: 0,
    marginBottom: 24,
    [breakpoints.up('sm')]: {
      marginBottom: 16,
    },
    [breakpoints.up('xl')]: {
      marginBottom: 20,
    },
  },
  state: {
    marginTop: 0,
    marginBottom: 28,
    [breakpoints.up('sm')]: {
      marginBottom: 16,
    },
    [breakpoints.up('xl')]: {
      marginBottom: 28,
    },
  },
  cancelButtonWrapper: {
    marginTop: 20,
  },
}));
