import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { IconTypes } from '../../../../../common';
import { NoEntriesPlaceholder } from '../../../../../common/no-entries-placeholder';

export const PromotionsPlaceholder = (): JSX.Element => {
  const intl = useIntl();

  return (
    <NoEntriesPlaceholder
      data-testid="no-promotion-bonuses"
      iconType={IconTypes.noPromo}
      description={intl.formatMessage(
        { id: 'userActivities.promotions.placeholder.description' },
        { p: (chunks: ReactNode) => <p data-testid="partial">{chunks}</p> },
      )}
    />
  );
};
