import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Tutorial } from '../../../../common/tutorial';
import { ReactComponent as LensesTutorialIcon } from '../../../../../assets/images/lenses-tutorial.svg';
import { IMyLensesTutorialProps } from './my-lenses-tutorial.models';
import { useLensesTutorial } from '../../../../../graphql/user/actions/lensesTutorial';

export const MyLensesTutorial = ({ onClose, ...props }: IMyLensesTutorialProps): JSX.Element => {
  const intl = useIntl();
  const { setLensesTutorialShown } = useLensesTutorial();

  const handleTutorialClose = () => {
    setLensesTutorialShown();
    onClose!();
  };

  return (
    <Tutorial
      {...props}
      data-testid="lenses-tutorial"
      onClose={handleTutorialClose}
      icon={(
        <LensesTutorialIcon
          data-testid="lenses-tutorial-logo"
        />
      )}
      title={(
        <FormattedMessage
          id="myLensesDetailed.tutorial.title"
        />
      )}
      description={intl.formatMessage(
        { id: 'myLensesDetailed.tutorial.description' },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      )}
    />
  );
};
