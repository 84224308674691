import { useEffect } from 'react';

import { useGoBack } from '../../../../../../hooks/use-go-back';

export const VisionProfileIncomplete = (): JSX.Element | null => {
  const { goBack } = useGoBack();

  useEffect(() => {
    goBack();
  }, []);

  return null;
};
