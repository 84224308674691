import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReactiveVar } from '@apollo/client';

import { useStyles } from './discount-banner.styles';
import { InternalLinks } from '../../../../../../constants';
import { setIsFreepacks } from '../../../../../../graphql/configuration/configuration.cache';


export const DiscountBanner = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const isFreepacksEnabled = useReactiveVar(setIsFreepacks);
  const bannerTitle = isFreepacksEnabled
    ? 'myLensesDetailed.notFitted.banner.title.withFreepacks'
    : 'myLensesDetailed.notFitted.banner.title';
  const bannerDescription = isFreepacksEnabled
    ? 'myLensesDetailed.notFitted.description.withFreepacks'
    : 'myLensesDetailed.notFitted.description';

  return (
    <div
      className={classes.root}
      data-testid="discount-banner"
    >
      <h3 className={classes.title}>
        <FormattedMessage
          id={bannerTitle}
        />
      </h3>
      <div className={classes.description}>
        {intl.formatMessage(
          { id: bannerDescription },
          {
            p: (chunk: ReactNode) => <p className={classes.descriptionParagraph}>{chunk}</p>,
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            link: (chunk: ReactNode) => (
              <a
                data-testid="ecp-link"
                className={classes.ecpLink}
                href={InternalLinks.ecpLocator}
              >
                {chunk}
              </a>
            ),
          },
        )}
      </div>
    </div>
  );
};
