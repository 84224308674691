import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  formControlRoot: {
    marginBottom: 14,
    [breakpoints.up('md')]: {
      paddingTop: 24,
      marginBottom: 30,
    },
  },
  formLabelRoot: {
    minWidth: 'unset',
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    color: palette.text.secondary,
    marginBottom: 18,
    '&$formLabelFocused, &$formLabelError': {
      color: palette.text.secondary,
    },
    [breakpoints.up('md')]: {
      marginBottom: 24,
      lineHeight: lineHeights.xl,
    },
  },
  formLabelFocused: {},
  formLabelError: {},
  formHelperTextRoot: {
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontFamily: fontFamilies.primary,
    color: palette.text.secondary,
    margin: '0 0 18px',
    '&$formHelperTextError': {
      color: palette.error.main,
    },
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  formHelperTextError: {},
}));
