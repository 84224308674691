import { useState, SyntheticEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useModal } from 'mui-modal-provider';

import { useStyles } from './tabs-content.styles';
import { Button } from '../../../../common/button-new';
import { TabPanel } from '../../../../common/tab-panel';
import { NoPlayStore } from '../no-play-store';
import qrCode from '../../../../../assets/images/qr-code-eye.svg';
import appStore from '../../../../../assets/images/mobile-markets/app-store.svg';
import playStore from '../../../../../assets/images/mobile-markets/google-play.svg';
import { ExternalLinks } from '../../../../../constants';

export const TabsContent = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const classes = useStyles();
  const intl = useIntl();

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  const panels = [
    {
      icon: playStore,
      platform: 'Android',
    },
    {
      icon: appStore,
      platform: 'iOS',
    },
  ];

  const { showModal } = useModal();

  const handleNoPlayStoreClick = () => {
    showModal(NoPlayStore, {});
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label={intl.formatMessage({ id: 'downloadApp.button.android' })} {...a11yProps(0)} />
        <Tab label={intl.formatMessage({ id: 'downloadApp.button.ios' })} {...a11yProps(1)} />
      </Tabs>
      {panels.map((panel, index) => (
        <TabPanel value={value} index={index} key={index}>
          <div className={classes.panelInner}>
            <div className={classes.content}>
              <div className={classes.qrCode}><img src={qrCode} alt={intl.formatMessage({ id: 'common.qrCode' })} /></div>
              <a className={classes.link} href={ExternalLinks.mobileMarket} aria-label={intl.formatMessage(
                { id: 'downloadApp.link' },
                { platform: panel.platform },
              )}><img src={panel.icon} alt=""/></a>
              <Button
                style="text"
                onClick={handleNoPlayStoreClick}
                isUnderline
              >
                <FormattedMessage id="downloadApp.no-play-store" />
              </Button>
            </div>
            <div className={classes.image} />
          </div>
        </TabPanel>
      ))}
    </div>
  );
};
