import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { IDiscountCardProps } from './discount-card.models';
import { Icon, IconSizes, IconTypes } from '../../../../../common/icon';
import { BonusCard } from '../../../../../common/bonus-card';

export const DiscountCard = ({
  isAvailable, value, footer, onClick,
}: IDiscountCardProps): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <BonusCard
      data-testid="discount-card"
      onClick={onClick}
      isAvailable={isAvailable}
      bonusImage={(
        <Icon
          size={isMobile ? IconSizes.xl : IconSizes['3xl']}
          type={isAvailable
            ? IconTypes.percentRoundedBlue
            : IconTypes.percentRoundedDotted
          }
        />
      )}
      cardTitle={(
        <FormattedMessage
          id="userActivities.discounts.discountCard.title"
          values={{
            amount: value,
          }}
        />
      )}
      description={(
        <FormattedMessage
          id="userActivities.discounts.discountCard.description"
        />
      )}
      footer={footer}
    />
  );
};
