import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IBonusActivationNoteProps } from './bonus-activation-note.models';
import { useStyles } from './bonus-activation-note.styles';
import { Button } from '../../../../../common';

export const BonusActivationNote = ({
  isActive, onButtonClick, ariaLabel, ariaLabelVariable, buttonLabel, labelNotEarned, value,
  balance,
}: IBonusActivationNoteProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      {isActive ? (<Button
        variant="contained"
        className={classes.button}
        aria-label={intl.formatMessage({
          id: ariaLabel,
        }, { variable: ariaLabelVariable })}
        data-testid="get-bonus-button"
        onClick={onButtonClick}
      >
        <FormattedMessage
          id={buttonLabel}
        />
      </Button>) : (
        <p
          className={classes.notEarned}
          data-testid="bonus-not-earned"
        >
          <FormattedMessage
            id={labelNotEarned}
            values={{
              amount: value! - balance!,
            }}
          />
        </p>
      )}
    </>
  );
};
