import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  UserActivitiesList,
} from '../../../../../common/user-activities-list/user-activities-list';
import { ITitleSize } from '../../../../../common/user-activities-list/user-activities-list.models';
import { PromotionCard, PromotionsPlaceholder } from '../../../user-activity-items/promotions';
import { LoyaltyType } from '../../../../../../graphql/promotions/models/get-promotions.models';
import { replaceWelcomeVoucher } from '../../../my-bonuses/utils/replace-welcome-voucher';
import { BonusType, IBonus } from '../../../../../../graphql/bonuses/models/get-bonuses.models';

export const PromotionsList: FC<{ promotions: IBonus[] }> = ({ promotions }) => {
  const promotionsFiltered = [...promotions].filter(promotion => promotion.type !== BonusType.discount);
  const firstVoucher = promotionsFiltered?.find((
    promotion,
  ) => promotion.name === LoyaltyType.discountFirstPurchase);

  return (
    <UserActivitiesList
      title={{
        size: ITitleSize.lg,
        label: (
          <FormattedMessage
            id="promotions.title"
          />
        ),
      }}
      activities={firstVoucher ? replaceWelcomeVoucher(promotionsFiltered)
        .map((promotion) => (
          <PromotionCard
            id={`${promotion?.id}${promotion?.endDate}`}
            promotion={promotion!}
            isAvailable={!(promotion?.name === LoyaltyType.discountSecondPurchase)}
            isFirstWelcomeVoucher={promotion?.name === LoyaltyType.discountFirstPurchase}
          />
        )) : promotionsFiltered
        .map((promotion) => (
          <PromotionCard
            id={`${promotion?.id}${promotion?.endDate}`}
            promotion={promotion}
            notClickable={promotion?.name === LoyaltyType.discountSecondPurchase}
          />
        ))
      }
      placeholder={<PromotionsPlaceholder/>}
    />
  );
};
