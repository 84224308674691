import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TextField } from '../../../../common/form/text-field';
import { IChangePasswordFieldViewProps } from './change-password-field.models';

export const ChangePasswordField = ({ onChangePassword }: IChangePasswordFieldViewProps) => (
  <TextField
    type="password"
    name="password"
    data-testid="password-field"
    fullWidth
    InputProps={{
      readOnly: true,
    }}
    label={(
      <FormattedMessage
        id="profile.passwordTextField.label"
      />
    )}
    actionProps={{
      'data-testid': 'change-password-button',
      onClick: onChangePassword,
      children: (
        <FormattedMessage
          id="profile.passwordTextField.changePasswordButton.label"
        />
      ),
    }}
  />
);
