import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  palette,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
}: Theme) => ({
  root: {
    margin: '0 0 24px',
    [breakpoints.up('sm')]: {
      margin: '0 0 32px',
    },
    '& .swiper-slide': {
      width: 'unset',
      height: 'unset',
    },
  },
  card: {
    boxSizing: 'border-box',
    padding: 24,
    maxWidth: 198,
    height: '100%',
    [breakpoints.up('sm')]: {
      padding: 16,
    },
  },
  brandLogo: {
    width: 'auto',
    height: 56,
    margin: '0 auto 8px',
  },
  text: {
    margin: 0,
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.regular,
  },
  title: {
    minHeight: 90,
    [breakpoints.up('sm')]: {
      minHeight: 75,
    },
  },
  packageParamsList: {
    padding: '12px 0 0',
    margin: 0,
    listStyleType: 'none',
    [breakpoints.up('sm')]: {
      padding: '24px 0 0',
    },
  },
  packageParamsItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  strongText: {
    fontWeight: fontWeights.medium,
  },
}));
