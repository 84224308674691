import { FormattedMessage } from 'react-intl';

import { IFreepackCardProps } from './freepack-card.models';
import { useStyles } from './freepack-card.styles';
import { BonusCard } from '../../../../../common/bonus-card';

export const FreepackCard = ({
  isAvailable,
  value,
  noveltyLabel,
  bonusImage,
  fullName,
  onClick,
  footer,
}: IFreepackCardProps): JSX.Element => {
  const classes = useStyles();

  return (
    <BonusCard
      data-testid="freepack-card"
      onClick={onClick}
      externalContentClasses={classes.root}
      bonusImage={bonusImage}
      cardTitle={fullName}
      isAvailable={isAvailable}
      pointsAmount={(
        <FormattedMessage
          id="userActivities.freepacks.freepackCard.description"
          values={{
            amount: value,
          }}
        />
      )}
      footer={footer}
      noveltyLabel={noveltyLabel}
    />
  );
};
