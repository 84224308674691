import { FormattedMessage } from 'react-intl';

import { IconTypes } from '../../../../common/icon';
import { NoEntriesPlaceholder } from '../../../../common/no-entries-placeholder';
import { IOrdersPlaceholderProps } from './orders-placeholder.models';

export const OrdersPlaceholder = ({ action }: IOrdersPlaceholderProps): JSX.Element => (
  <NoEntriesPlaceholder
    data-testid="no-orders"
    iconType={IconTypes.ordersCart}
    description={(
      <FormattedMessage
        id="userActivities.orders.placeholder.description"
      />
    )}
    action={action}
  />
);
