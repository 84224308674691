import React, { ReactNode, useEffect } from 'react';
import { cx } from '@emotion/css';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './pre-survey-modal.styles';
import {
  ReactComponent as PreSurveyIcon,
} from '../../../../../../assets/images/icons/acuvue-life.svg';
import { IconTypes, Icon, Modal } from '../../../../../common';
import { PreSurveyModalProps } from './pre-survey-modal.models';
import { Button } from '../../../../../common/button-new';
import { InternalLinks } from '../../../../../../constants';
import { vpSurveySteps } from '../../../../../../graphql/user/user.cache';

export const PreSurveyModal = ({ surveySteps, onClose, ...props }: PreSurveyModalProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  const benefitItems = [
    {
      title: 'vp.benefits.birthdayPoints',
      icon: IconTypes.giftRoundedBlue,
    },
    {
      title: 'vp.benefits.discountsFreePacks',
      icon: IconTypes.lensesRoundedBlue,
    },
    {
      title: 'vp.benefits.news',
      icon: IconTypes.bellRoundedBlue,
    },
  ];

  const navigate = useNavigate();
  const handleButtonClick = () => {
    onClose!();
    navigate(InternalLinks.visionProfileSurvey);
  };

  const handleSimpleClose = () => {
    onClose!();
  };

  useEffect(() => {
    vpSurveySteps(surveySteps);
  }, []);

  return (
    <>
      <Modal
        {...props}
        onClose={handleSimpleClose}
        content={(
          <>
            <div
              data-testid="pre-survey-step-icon"
              className={classes.icon}
              aria-hidden="true"
            >
              <PreSurveyIcon />
            </div>
            <h2
              data-testid="pre-survey-step-title"
              className={classes.title}
            >
              {intl.formatMessage(
                { id: 'vp.startPage.title' },
                {
                  sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                  span: (chunks: ReactNode) => <span className={classes.titlePartial}>{chunks}</span>,
                },
              )}
            </h2>
            <ul
              data-testid="pre-survey-step-list"
              className={cx(classes.benefitsList)}
            >
              {
                benefitItems.map((benefit) => (
                  <li
                    className={classes.benefitsItem}
                    data-testid="pre-survey-step-benefit"
                    key={benefit.title}
                  >
                    <Icon
                      type={benefit.icon}
                      className={classes.benefitIcon}
                      aria-hidden="true"
                    />
                    <span className={classes.benefitText}>
                {intl.formatMessage(
                  { id: benefit.title },
                  { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
                )}
              </span>
                  </li>
                ))
              }
            </ul>
            <Button
              style="dark"
              onClick={handleButtonClick}
            >
              <FormattedMessage id="vp.startPage.button" />
            </Button>
            <p className={classes.helper}><FormattedMessage id="vp.startPage.buttonHelper" /></p>
          </>
        )}
      />
    </>
  );
};
