import {
  EyeParamsType,
  IEyeParamsOption,
} from '../../../../../../../graphql/lenses/models/get-lenses.models';
import { IFormSubmitValues } from '../../../../../../common/form';

export const getSelectedValue = (
  propertyName: EyeParamsType,
  parameters: IEyeParamsOption[],
  selectedValue?: string,
) => {
  let value: string = '';

  if (selectedValue) {
    value = selectedValue;
  } else {
    const parameter = parameters.find(({ property }) => property === propertyName);

    if (parameter) {
      value = parameter.value;
    }
  }

  return value;
};

export const getFormInputValue = (
  propertyName: EyeParamsType, parameters?: IFormSubmitValues,
) => {
  let value: string = '';

  if (parameters) {
    for (const property in parameters) {
      if (property === propertyName) {
        value = parameters[property] as string;
      }
    }
  }

  return value;
};

export const updateSelectedValue = (
  propertyName: EyeParamsType,
  actionPropertyName: EyeParamsType,
  actionValue: string,
) => {
  let value: string = '';

  if (actionPropertyName === propertyName) {
    value = actionValue;
  }

  return value;
};
