import React, { useEffect } from 'react';
import { useModal } from 'mui-modal-provider';

import {
  Appointment as AppointmentView,
} from './appointment';
import { useGoBack } from '../../../../hooks/use-go-back';
import { appointmentSettings, IAppointmentSettingsProps } from '../../../common/appointment-settings';
import { CancelAppointmentModal } from './cancel-appointment-modal';
import { NoActiveAppointmentModal } from './no-active-appointment-modal';
import { isAppointmentContentShow } from '../../../../utils/appointment/is-appoinment-content-show';

const AppointmentContainer = ({
  appointments, isLoading, isError,
}: IAppointmentSettingsProps): JSX.Element => {
  const { showModal } = useModal();
  const { goBack } = useGoBack();

  const handleCancelButtonClick = () => {
    showModal(CancelAppointmentModal, {
      appointmentId: appointments!.allAppointmentsByAuthId.edges[0].node.id,
    });
  };

  const handleAppointmentBack = () => {
    goBack();
  };

  useEffect(() => {
    const userAppointment = appointments?.allAppointmentsByAuthId?.edges;
    if ((appointments && !userAppointment?.length)
    || (userAppointment?.length && !isAppointmentContentShow(
      userAppointment[0].node.date, userAppointment[0].node.status,
    ))) {
      showModal(NoActiveAppointmentModal, {});
    }
  }, [appointments, showModal]);

  return (
    <AppointmentView
      appointments={appointments}
      isLoading={isLoading}
      isError={isError}
      onCancelClick={handleCancelButtonClick}
      onAppointmentClose={handleAppointmentBack}
    />
  );
};

export const Appointment = appointmentSettings(AppointmentContainer);
