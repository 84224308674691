import React from 'react';

import { IChangePhoneModalViewProps } from './change-phone-modal.models';
import { Modal, ResponsiveWidth } from '../../../../../common/modal';
import { Stepper } from '../../../../../common/stepper';
import { changePhoneSteps } from './change-phone-modal.steps';

export const ChangePhoneModal = ({
  onChangePhoneDone,
  externalState,
  ...props
}: IChangePhoneModalViewProps): JSX.Element => (
  <Modal
    {...props}
    responsiveWidth={ResponsiveWidth.md}
    content={(
      <Stepper
        steps={changePhoneSteps}
        onDone={onChangePhoneDone}
        externalState={externalState}
      />
    )}
  />
);
