import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
}: Theme) => ({
  shopList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  shopItem: {
    padding: '16px 0',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
    borderBottom: `1px solid ${palette.grey['200']}`,
  },
}));
