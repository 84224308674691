import { useStyles } from './banner.styles';
import { useGetBanners } from '../../../../../hooks/use-get-banners';
import { CmsBannerType, CmsBanner } from '../../../../common/cms-banner';

export const Banner = (): JSX.Element => {
  const classes = useStyles();
  const { isBanner, url } = useGetBanners(CmsBannerType.arlBanner);

  return (
    <div className={classes.root}>
      {(isBanner && !!url) ? <CmsBanner url={url} /> : null}
    </div>
  );
};
