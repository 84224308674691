import {
  BonusType,
  IOrderBonus,
} from '../../../../../../../../../graphql/bonuses/models/get-bonuses.models';

export const getAvailableBonuses = (
  bonuses?: IOrderBonus[], balance?: number, bonusId?: string, bonusValue?: number,
  bonusType?: BonusType, isEditOrder?: boolean,
) => {
  const availableBalance = balance || 0;
  const value = (bonusType === BonusType.discount && bonusValue) ? bonusValue : 0;

  if (bonuses?.length) {
    const isAvailableBonuses = (bonus: IOrderBonus) => {
      const isAvailable = !isEditOrder
        ? (bonus.type === BonusType.discount
          && bonus.value > availableBalance + value) || (bonus.name === bonusId)
        : (bonus.type === BonusType.discount
        && bonus.value > availableBalance) || (bonus.name === bonusId);

      return !isAvailable;
    };

    return bonuses.filter(isAvailableBonuses);
  }

  return bonuses;
};
