import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  spacing,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  root: {
    paddingTop: 24,
    [breakpoints.up('md')]: {
      paddingTop: 32,
    },
    [breakpoints.up('lg')]: {
      paddingTop: 40,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  balance: {
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    color: palette.text.primary,
    margin: '0 0 10px 0',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
  },
  howToButton: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: spacing(1),
    alignItems: 'center',
    textDecoration: 'none',
    marginBottom: 35,
    [breakpoints.up('md')]: {
      marginBottom: 50,
    },
  },
  requestCallButton: {
    width: 288,
    marginBottom: 35,
    [breakpoints.up('md')]: {
      marginBottom: 64,
    },
  },
}));
