import React from 'react';

import { ReactComponent as RegGuideLogoV1 } from '../../../../../assets/images/reg-guide/logo-reg-guide-v1.svg';
import { ContentWrapper } from '../../../../common/content-wrapper';
import { useStyles } from './header.styles';

export const Header = (): JSX.Element => {
  const classes = useStyles();

  return (
    <header
      className={classes.root}
      data-testid="reg-guide-header"
    >
      <ContentWrapper>
        <div
          data-testid="reg-guide-logo-wrapper"
          className={classes.logo}
        >
          <RegGuideLogoV1 />
        </div>
      </ContentWrapper>
    </header>
  );
};
