import {
  IConsumerLenses,
  LensesBrandType,
} from '../../graphql/lenses/models/get-lenses.models';

export const getLensesWithoutAohm = (lensesData: IConsumerLenses) => {
  return lensesData?.consumerLensesByAuthID.filter((
    lenses,
  ) => lenses.brandId !== LensesBrandType.aohm);
};
