import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  fontWeights, fontSizes, lineHeights, breakpoints,
}: Theme) => ({
  title: {
    marginTop: 24,
    marginBottom: 0,
    padding: '0 16px',
    fontSize: fontSizes.xxl,
    lineHeight: lineHeights.xxl,
    fontWeight: fontWeights.medium,
    [breakpoints.up('md')]: {
      marginTop: 32,
      fontSize: fontSizes.xxxl,
      lineHeight: lineHeights.xxxl,
      textAlign: 'center',
    },
  },
}));
