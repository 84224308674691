import { FC } from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from './promotions.styles';
import { ContentWrapper } from '../../../../common/content-wrapper';
import { PromotionsList } from './promotions-list';
import { IBonus } from '../../../../../graphql/bonuses/models/get-bonuses.models';

export const Promotions: FC<{ voucherByAuthId: IBonus[] }> = ({ voucherByAuthId }): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <section
      className={classes.root}
      data-testid="promotions"
      aria-label={intl.formatMessage({ id: 'promotions.title' })}
    >
      <ContentWrapper>
        {voucherByAuthId?.length ? <PromotionsList promotions={voucherByAuthId} /> : <></>}
      </ContentWrapper>
    </section>
  );
};
