import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useStyles } from './appointment-success-step.styles';
import { ReactComponent as LenseImage } from '../../../../../../assets/images/appointment-success.svg';
import { Icon, IconSizes, IconTypes } from '../../../../../common/icon';
import movieIcon from '../../../../../../assets/images/movie-icon.png';
import { APPOINTMENT_HOW_TO } from '../../../../../../constants';

export const AppointmentSuccessStep = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <LenseImage className={classes.lensesImage} />
      <h2
        className={classes.title}
        data-testid="appointment-success-step-title"
      >
        <FormattedMessage
          id="appointmentRegistration.appointmentSuccessStep.title"
        />
      </h2>
      <p className={classes.description}>
        <FormattedMessage
          id="appointmentRegistration.appointmentSuccessStep.description"
        />
      </p>
      <a
        href={APPOINTMENT_HOW_TO}
        className={classes.howToFitLinkPanel}
        target="_blank"
      >
        <div className={classes.linkPanelContent}>
          {isMobile
            ? <Icon type={IconTypes.play} size={IconSizes.xs} />
            : <img src={movieIcon} alt="" data-testid="movie-icon" />}
          <span className={classes.linkPanelContentText}>
            <FormattedMessage
              id="appointmentRegistration.appointmentSuccessStep.howToFitLink.text"
            />
          </span>
        </div>
        {!isMobile && (
          <div className={classes.linkPanelIcon}>
            <Icon type={IconTypes.externalLink} />
          </div>
        )}
      </a>
    </div>
  );
};
