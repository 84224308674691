import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({
  palette, fontSizes, fontWeights, lineHeights, breakpoints, borderRadiuses,
}: Theme) => ({
  title: {
    marginTop: 0,
    marginBottom: 12,
    color: palette.text.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  selected: {
    borderColor: palette.common.black,
    '&&': {
      color: palette.common.black,
      backgroundColor: palette.common.white,
      '&&:not(:first-child)': {
        borderLeftColor: palette.common.black,
      },
      '&:hover, &:focus': {
        backgroundColor: palette.common.white,
      },
    },
  },
  toggleButton: {
    color: palette.custom.greyNeutral,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
  },
  select: {
    border: `1px solid ${palette.common.black}`,
    borderRadius: borderRadiuses.md,
    '& .MuiSelect-select': {
      padding: '16px 8px 16px 12px',
      color: palette.common.black,
      fontSize: fontSizes.xs,
      lineHeight: lineHeights.sm,
      fontWeight: fontWeights.regular,
    },
    '&.MuiInput-underline': {
      marginTop: 0,
      '&::before, &::after': {
        display: 'none',
      },
    },
    '& svg': {
      right: 8,
      width: 18,
      '& path': {
        fill: palette.common.black,
        '&:last-child': {
          display: 'none',
        },
      },
    },
  },
  selectFormControl: {
    marginBottom: 8,
  },
  selectPaperStyles: {
    padding: 8,
    [breakpoints.up('sm')]: {
      padding: 16,
    },
  },
  selectListStyles: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gap: 8,
    padding: 0,
    [breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(4, auto)',
      padding: 10,
    },
  },
  selectItemStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 20px',
    border: `1px solid ${palette.grey['500']}`,
    borderRadius: borderRadiuses.md,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    '&.Mui-selected': {
      borderColor: palette.common.black,
      background: palette.common.white,
    },
  },
}));
