import { useEffect } from 'react';

import { useStyles } from './ecp-stores-list.styles';
import { EcpStore } from '../../../../../ecp-store';
import { IEcpStore } from '../../../../../../../../rest/ecp-locator';
import { IEcpStoresListProps } from './ecp-stores-list.models';
import { sortByRaiting } from '../../../../../../../../utils/ecp-locator/sort-by-rating';
import { scrollToTop } from '../../../../../../../../utils/scroll-to-top';

export const EcpStoresList = ({
  stores,
  onStoreSelect,
  storeCode,
  onScrollToStore,
}: IEcpStoresListProps): JSX.Element => {
  const classes = useStyles();
  const resultStores = stores && sortByRaiting([...stores!]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <ul
      className={classes.root}
      role="list"
      data-testid="stores-list"
    >
      {
        resultStores?.map((store: IEcpStore) => (
          <li
            key={store.code}
            role="listitem"
            className={classes.storeItem}
          >
            <EcpStore
              storeData={store}
              onStoreSelect={onStoreSelect}
              storeCode={storeCode}
              onScrollToStore={onScrollToStore}
              contentStyles={classes.storeContent}
              titleStyles={classes.storeTitle}
            />
          </li>
        ))
      }
    </ul>
  );
};
