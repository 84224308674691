import { SortingTypes } from '../../../components/business/app/ecp-sort/ecp-sort.models';
import { sortingType } from '../ecp-locator.cache';
import { MouseEvent as MouseEventReact } from 'react';

const setSortingTypeToCache = (event: MouseEventReact<HTMLElement>, value: SortingTypes) => {
  if (value) {
    sortingType(value);
  }
};

export const useSortingType = () => ({
  setSortingTypeToCache,
});
