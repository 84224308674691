import { useEffect, useMemo, useRef, useState } from 'react';

import {
  FormValues,
  IParametersContainerProps,
  IParameterSelectedValue,
} from './change-parameters-modal.models';
import {
  ChangeParametersModal as ChangeParametersModalView,
} from './change-parameters-modal';
import { IFormImperativeHandleProps } from '../../../../../../../common/form';
import { EyeParamsType } from '../../../../../../../../graphql/lenses/models/get-lenses.models';
import { getSelectedValue } from '../../utils/get-selected-value';
import {
  IBrandConfiguration,
  IBrandParams,
} from '../../../../../../../../graphql/lenses/models/get-brands.models';
import { getActionPayload } from '../../utils/get-action-payload';
import { replaceParameter } from '../../utils/replace-parameter';

export const ChangeParametersModal = ({
  parameters, brandParameters, onClose, onUpdateParameters, ...props
}: IParametersContainerProps): JSX.Element => {
  const parametersFormRef = useRef<IFormImperativeHandleProps>(null);

  const defaultFormValues = useMemo(() => ({
    [EyeParamsType.baseCurve]: getSelectedValue(EyeParamsType.baseCurve, parameters),
    [EyeParamsType.power]: getSelectedValue(EyeParamsType.power, parameters),
    [EyeParamsType.addition]: getSelectedValue(EyeParamsType.addition, parameters),
    [EyeParamsType.axis]: getSelectedValue(EyeParamsType.axis, parameters),
    [EyeParamsType.cylinder]: getSelectedValue(EyeParamsType.cylinder, parameters),
    [EyeParamsType.variant]: getSelectedValue(EyeParamsType.variant, parameters),
  }), []);

  const handleSubmitButtonClick = () => {
    if (parametersFormRef.current) {
      parametersFormRef.current.submit();
    }
    onClose!();
  };

  const [newBrandParameters, setNewBrandParameters] = useState<IBrandParams[]>(brandParameters!);
  const [parameterSelectedValue, setParameterSelectedValue] = useState<IParameterSelectedValue>({
    value: '',
    brandParameter: null,
  });

  const setBrandParams = (actionPayload:  IBrandParams[], params: IBrandParams[]) => {
    const brandParams = replaceParameter(actionPayload, params);
    setNewBrandParameters(brandParams);
  };

  const handleClickReplaceParameter = (
    params: IBrandParams[], value: string, brandParameter: IBrandParams,
  ) => {
    const isConditional = brandParameter.conditional;

    if (value && isConditional) {
      const actionPayload = getActionPayload(
        value,
        brandParameter,
        parameters,
        parametersFormRef.current?.getValues(),
      );

      if (actionPayload) {
        setBrandParams(actionPayload, params);
      }
    }
  };

  const handleInitReplaceParameter = (params: IBrandParams[]) => {
    params?.forEach((brandParameter) => {
      const selectedValue = getSelectedValue(brandParameter.name, parameters);
      const isConditional = brandParameter.conditional;

      if (selectedValue && isConditional) {
        const actionPayload = getActionPayload(selectedValue, brandParameter, parameters);

        if (actionPayload) {
          setBrandParams(actionPayload, params);
        }
      }
    });
  };

  const getValidValues = (name: EyeParamsType): number[] | undefined => {
    const parameterConfiguration = newBrandParameters.find((
      parameter,
    ) => parameter.name === name)?.configuration as IBrandConfiguration;

    return parameterConfiguration?.values;
  };

  const getIsValidValue = (value: string, name: EyeParamsType) => {
    const validValues = getValidValues(name);

    return !!(validValues as unknown as number[])?.find((
      validValue,
    ) => validValue === Number(value));
  };

  const handleParametersSubmit = (values: FormValues) => {
    onUpdateParameters(values);
  };

  const handleValueChange = (
    value: string,
    brandParameter: IBrandParams,
  ) => {
    setParameterSelectedValue({
      value,
      brandParameter,
    });
    parametersFormRef.current?.setValue(brandParameter.name, value, {
      shouldDirty: true,
    });
  };

  const [axisValue, setAxisValue] = useState<string>('');
  const [cylinderValue, setCylinderValue] = useState<string>('');

  const handleChangeInvalidValue = (brandParameter: IBrandParams) => {
    // Change 'CYLINDER' parameter if invalid.
    if (brandParameter.name === EyeParamsType.power) {
      const isValueValid = getIsValidValue(
        parametersFormRef.current?.getValues()[EyeParamsType.cylinder] as string, EyeParamsType.cylinder,
      );

      const validValues = getValidValues(EyeParamsType.cylinder);
      const valueToSet = validValues?.length ? validValues[0] : '';

      if (!isValueValid) {
        setCylinderValue(String(valueToSet));
        parametersFormRef.current?.setValue(EyeParamsType.cylinder, String(valueToSet), {
          shouldDirty: true,
        });
      } else {
        setCylinderValue('');
      }
    }
    // Change 'AXIS' parameter if invalid.
    if ((brandParameter.name === EyeParamsType.power || brandParameter.name === EyeParamsType.cylinder)
      && parametersFormRef.current?.getValues()[EyeParamsType.axis]) {
      const isValueValid = getIsValidValue(
        parametersFormRef.current?.getValues()[EyeParamsType.axis] as string, EyeParamsType.axis,
      );

      const validValues = getValidValues(EyeParamsType.axis);
      const valueToSet = validValues?.length ? validValues[0] : '';

      if (!isValueValid) {
        setAxisValue(String(valueToSet));
        parametersFormRef.current?.setValue(EyeParamsType.axis, String(valueToSet), {
          shouldDirty: true,
        });
      } else {
        setAxisValue('');
      }
    }
  };

  useEffect(() => {
    if (parameterSelectedValue?.brandParameter && parameterSelectedValue?.value) {
      const targetBrandParameter = typeof parameterSelectedValue.brandParameter !== 'string'
        ? newBrandParameters.find((param) => param.name === parameterSelectedValue.brandParameter!.name)
        : parameterSelectedValue.brandParameter;
      handleClickReplaceParameter(
        newBrandParameters,
        parameterSelectedValue.value,
        targetBrandParameter!,
      );
    }
  }, [parameterSelectedValue?.brandParameter, parameterSelectedValue?.value]);

  useEffect(() => {
    if (newBrandParameters && parameterSelectedValue?.brandParameter) {
      handleChangeInvalidValue(parameterSelectedValue.brandParameter);
    }
  }, [newBrandParameters]);

  useEffect(() => {
    if (brandParameters) {
      handleInitReplaceParameter(brandParameters);
    }
  }, []);

  return (
    <ChangeParametersModalView
      {...props}
      ref={parametersFormRef}
      parameters={parameters}
      brandParameters={newBrandParameters}
      defaultValues={defaultFormValues}
      onSubmitButtonClick={handleSubmitButtonClick}
      onParametersSubmit={handleParametersSubmit}
      onClose={onClose}
      onValueChange={handleValueChange}
      axisValue={axisValue}
      cylinderValue={cylinderValue}
    />
  );
};
