import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from './conditions-list.styles';
import { List } from '../list';

export const ConditionsList = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  const conditions = [{
    id: 'conditions-item-1',
    item: <>{intl.formatMessage(
      { id: 'outOfSalesModal.list.conditions.item1' },
      {
        p: (chunks: ReactNode) => <p data-testid="partial-condition" className={classes.partial}>{chunks}</p>,
        strong: (chunks: ReactNode) => <span className={classes.strongText}>{chunks}</span>,
        sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
        noBreak: (chunks: ReactNode) => (
          <span className={classes.noBreak} data-testid="no-break">{chunks}</span>
        ),
      },
    )}</>,
  }, {
    id: 'conditions-item-2',
    item: <>{intl.formatMessage(
      { id: 'outOfSalesModal.list.conditions.item2' },
      {
        strong: (chunks: ReactNode) => <span className={classes.strongText}>{chunks}</span>,
      },
    )}</>,
  }];

  return (
    <List
      items={conditions}
      data-testid="conditions-list"
      listStyles={classes.root}
      itemStyles={classes.item}
    />
  );
};
