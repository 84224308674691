import { AxiosRequestConfig } from 'axios';
import { getBasicAuthorizationHeader } from '../../utils/request-headers/get-basic-authorization-header';

export const isCmsUrl = (url: string | undefined): boolean => !!(url && url.includes('/myacuvue/cms'));

export const getCmsConfig = (config: AxiosRequestConfig): AxiosRequestConfig => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: getBasicAuthorizationHeader(),
  },
});
