import React, { useCallback } from 'react';
import { useModal } from 'mui-modal-provider';

import { ChangePhoneField as ChangePhoneFieldView } from './change-phone-field';
import { ChangePhoneModal } from './change-phone-modal';
import { IChangePhoneFieldProps } from './change-phone-field.models';

export const ChangePhoneField = ({
  phoneNumber,
  updateProfileQuery,
}: IChangePhoneFieldProps) => {
  const { showModal } = useModal();

  const handleChangePhone = useCallback(() => {
    showModal(ChangePhoneModal, { phoneNumber, updateProfileQuery });
  }, [showModal, phoneNumber, updateProfileQuery]);

  return <ChangePhoneFieldView onChangePhone={handleChangePhone} />;
};
