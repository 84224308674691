import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  modalContent: {
    marginBottom: 16,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      paddingLeft: 44,
      paddingRight: 44,
    },
  },
  modalTitle: {
    textAlign: 'center',
    [breakpoints.up('md')]: {
      paddingLeft: 44,
      paddingRight: 44,
    },
  },
  iconWrapper: {
    display: 'block',
    margin: '0 auto',
    '& svg': {
      width: 120,
      height: 120,
    },
  },
}));
