import { forwardRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { UseFormTrigger } from 'react-hook-form';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { TextField } from '../../../../../../common/form/text-field';
import { IPasswordStepFormProps, TPasswordFormValues } from './password-step-form.models';

export const PasswordStepForm = forwardRef<IFormImperativeHandleProps, IPasswordStepFormProps>(({
  onSubmit,
  validationSchema,
  defaultValues,
}, ref): JSX.Element => {
  const handleNewPinValidateDependencies = useCallback(
    (trigger: UseFormTrigger<TPasswordFormValues>) => trigger(
      'passwordConfirm',
    ), [],
  );
  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      defaultValues={defaultValues}
    >
      <TextField
        name="password"
        type="password"
        fullWidth
        label={(
          <FormattedMessage
            id="resetPassword.passwordStep.newPassword.label"
          />
        )}
        onValidateDependencies={handleNewPinValidateDependencies}
      />
      <TextField
        name="passwordConfirm"
        type="password"
        fullWidth
        label={(
          <FormattedMessage
            id="resetPassword.passwordStep.confirmNewPassword.label"
          />
        )}
      />
    </Form>
  );
});
