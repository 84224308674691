import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { useModal } from 'mui-modal-provider';

import { Stepper } from '../../../../common/stepper';
import { Box } from '../../../../common/box';
import { InternalLinks } from '../../../../../constants';
import { vpSurveySteps } from '../../../../../graphql/user/user.cache';
import { CompleteSurveyModal } from './complete-survey-modal';

export const VisionProfile = (): JSX.Element => {
  const { showModal } = useModal();
  const navigate = useNavigate();

  const handleVisionProfileDone = async () => {
    // @ts-ignore
    const modal = await showModal(CompleteSurveyModal, { onConfirm: () => modal.hide() });
  };

  const intl = useIntl();
  const steps = useReactiveVar(vpSurveySteps);

  useEffect(() => {
    if (!steps?.length) {
      navigate(InternalLinks.home);
    }

    return () => {
      vpSurveySteps([]);
    };
  }, [steps]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'vp.create.page.title' })}
        </title>
      </Helmet>
      <Box>
        {steps?.length && (
          <Stepper
            disableStepIndicators
            steps={steps}
            onDone={handleVisionProfileDone}
            hideBackOnFirstStep
          />
        )}
      </Box>
    </>
  );
};
