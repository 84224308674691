import {
  IConsumerLensesParams,
  LensesBrandType,
  SolutionsBrandType,
} from '../../graphql/lenses/models/get-lenses.models';
import { IFittingFields } from '../../graphql/order/models/get-orders.models';
import { IFittedLensesParams } from '../../graphql/lenses/models/get-fitted-lenses.models';

export const isSingleBrand = (lenses: IConsumerLensesParams[]
| IFittingFields[] | IFittedLensesParams[], brand: LensesBrandType): boolean => {
  // @ts-ignore
  const filteredLenses = lenses?.filter((
    lensesBrand: IConsumerLensesParams | IFittingFields,
  ) => lensesBrand.brandId !== SolutionsBrandType.arl);
  return filteredLenses?.length === 1 && filteredLenses[0]?.brandId === brand;
};
