import { FormattedMessage } from 'react-intl';

import { useStyles } from './benefit.styles';
import { IBenefitProps } from './benefit.models';

export const Benefit = ({ icon, item }: IBenefitProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <span
        aria-hidden="true"
        className={classes.iconWrapper}
        data-testid="benefit-icon-wrapper"
      >
        {icon}
      </span>
      <span
        data-testid="benefit-item"
        className={classes.item}
      >
        <FormattedMessage id={item} />
      </span>
    </>
  );
};