import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../common/button';
import { Modal, ResponsiveWidth } from '../../../../common/modal';
import { ICancelAppointmentModalProps } from './cancel-appointment-modal.models';
import { useStyles } from './cancel-appointment-modal.styles';

export const CancelAppointmentModal = ({
  onClose, onCancelAppointment, ...props
}: ICancelAppointmentModalProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Modal
      {...props}
      onClose={onClose}
      responsiveWidth={ResponsiveWidth.md}
      title={(
        <FormattedMessage
          id="appointmentCancel.title"
        />
      )}
      content={(
        <p className={classes.description}>
          <FormattedMessage
            id="appointmentCancel.description"
          />
        </p>
      )}
      footer={(
        <div className={classes.actions}>
          <Button
            fullWidth
            variant="contained"
            onClick={onCancelAppointment}
            data-testid="cancel-button"
          >
            <FormattedMessage
              id="appointmentCancel.cancelButton.label"
            />
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={onClose as () => void}
            data-testid="close-button"
          >
            <FormattedMessage
              id="appointmentCancel.closeButton.label"
            />
          </Button>
        </div>
      )}
    />
  );
};
