import { forwardRef, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { IWhatDoYouLikeFormProps } from './what-do-you-like-form.models';
import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { whatDoYouLikeOptions as whatDoYouLikeOptionsBase } from './what-do-you-like-options';
import { ToggleButtonGroup } from '../../../../../../common/form/toggle-button-group';
import { TextField } from '../../../../../../common/form/text-field';
import { ACTIVITY_MAX_LENGTH } from '../../../../../../../constants';

export const WhatDoYouLikeForm = forwardRef<
IFormImperativeHandleProps, IWhatDoYouLikeFormProps>(({
  formProps, onActivitiesValidateDependencies,
}, ref): JSX.Element => {
  const intl = useIntl();
  const whatDoYouLikeOptions = useMemo(() => whatDoYouLikeOptionsBase, []);

  return (
    <Form
      ref={ref}
      {...formProps}
    >
      <ToggleButtonGroup
        name="whatDoYouLikePredefined"
        options={whatDoYouLikeOptions}
        standalone
        size="small"
        variant="outlined"
        onValidateDependencies={onActivitiesValidateDependencies}
      />
      <TextField
        name="whatDoYouLikeCustom"
        fullWidth
        multiline
        placeholder={intl.formatMessage({ id: 'vp.whatDoYouLike.ownVariant.placeholder' })}
        inputProps={{
          maxLength: ACTIVITY_MAX_LENGTH,
        }}
      />
    </Form>
  );
});
