import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';

import { setUserLocation } from '../graphql/ecp-locator/ecp-locator.cache';

interface IUpdateUserLocation {
  searchValue: string;
}

export const useSearchLocation = () => {
  const userLocationCurrent = useReactiveVar(setUserLocation);

  const updateLocationCoordinates = useCallback(async ({ searchValue }: IUpdateUserLocation) => {
    let userLocation = userLocationCurrent;

    if ((window as any).google) {
      const geocoder = new google.maps.Geocoder();

      await geocoder.geocode({ address: searchValue }, (results: any, status: any) => {
        if (status === 'OK') {
          const { location } = results[0].geometry;
          userLocation = {
            latitude: location.lat(),
            longitude: location.lng(),
          };
        }
      });
    }

    return userLocation;
  }, [userLocationCurrent, setUserLocation]);

  return { updateLocationCoordinates };
};
