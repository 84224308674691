import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Icon, IconSizes } from '../../../../../../../common/icon';
import { IBonusesListProps } from './bonuses-list.models';
import { useStyles } from './bonuses-list.styles';

export const BonusesList = ({ bonuses }: IBonusesListProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <ul
      className={classes.bonusesList}
      data-testid="preview-bonuses-list"
    >
      {bonuses.map(({ icon, description }, index) => (
        <li
          key={index}
          className={classes.bonusesItem}
          data-testid="preview-bonuses-item"
        >
          <Icon
            type={icon}
            size={isTablet ? IconSizes.md : IconSizes.sm}
          />
          <span className={classes.description}>
            {description}
          </span>
        </li>
      ))}
    </ul>
  );
};
