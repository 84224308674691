import { OrderEdit } from './order-edit';
import { OrderNoEdit } from './order-no-edit';
import { IOrderProps } from './order.models';
import { IOrderStore } from '../../order/order-create-page/order-create-page.models';
import {
  getOrderStore,
  getOrderStoreWithRating,
} from '../../../../../utils/order';
import { IStore } from '../../../../../graphql/order/models/get-orders.models';

export const Order = ({
  storeData, products, isEditMode, points, status, orderId, deliveryDate,
  onStoreChange, onUpdateQuantity, bonus, onBonusSelect, purchaseDate, bonusId, onProductsChange,
  rating,
}: IOrderProps): JSX.Element => (
  <>
    {isEditMode ? (
      <OrderEdit
        storeData={getOrderStoreWithRating(storeData as IOrderStore, rating!)}
        products={products}
        onStoreChange={onStoreChange}
        onUpdateQuantity={onUpdateQuantity}
        onBonusSelect={onBonusSelect!}
        bonus={bonus}
        bonusId={bonusId}
        onProductsChange={onProductsChange}
      />
    ) : (
      <OrderNoEdit
        storeData={getOrderStore(storeData as IStore, rating!)}
        products={products}
        points={points}
        status={status}
        orderId={orderId}
        deliveryDate={deliveryDate}
        bonus={bonus}
        purchaseDate={purchaseDate}
        bonusId={bonusId}
      />
    )}
  </>
);
