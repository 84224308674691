import {
  forwardRef,
  useMemo,
  ReactNode,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { TextField } from '../../../../../../common/form/text-field';
import { IPasswordStepFormProps } from './password-step-form.models';
import { Checkbox } from '../../../../../../common/form/checkbox';
import { ExternalLinks, InternalLinks } from '../../../../../../../constants';

export const PasswordStepForm = forwardRef<IFormImperativeHandleProps, IPasswordStepFormProps>(({
  onSubmit,
  validationSchema,
  defaultValues,
}, ref): JSX.Element => {
  const intl = useIntl();

  const resultDefaultValues = useMemo(() => defaultValues || {
    pin: '',
    tnc: false,
    authoriseJnj: false,
    marketing: false,
  }, [defaultValues]);
  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      defaultValues={resultDefaultValues}
    >
      <TextField
        name="pin"
        type="password"
        fullWidth
        autoFocus
        label={(
          <FormattedMessage
            id="registration.passwordStep.passwordTextField.label"
          />
        )}
      />
      <Checkbox
        name="authoriseJnj"
        label={intl.formatMessage(
          { id: 'registration.passwordStep.agreements.authoriseJnj' },
          {
            personalDataPolicyLink: (chunks: ReactNode) => <a href={ExternalLinks.personalDataPolicyLink} target="_blank">{chunks}</a>,
            privacyPolicyLink: (chunks: ReactNode) => <a href={ExternalLinks.privacyPolicyLink} target="_blank">{chunks}</a>,
            cookiePolicyLink: (chunks: ReactNode) => <a href={InternalLinks.cookiePage} target="_blank">{chunks}</a>,
          },
        )}
      />
      <Checkbox
        name="marketing"
        label={(
          <FormattedMessage
            id="registration.passwordStep.agreements.marketing"
          />
        )}
      />
      <Checkbox
        name="tnc"
        label={intl.formatMessage(
          { id: 'registration.passwordStep.agreements.tnc' },
          {
            myAcuvueProgramPolicyLink: (chunks: ReactNode) => <a
              href={intl.formatMessage({ id: 'common.myAcuvuePolicy' })}
              target="_blank"
            >{chunks}</a>,
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
          },
        )}
      />
    </Form>
  );
});
