import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  containerRoot: {
    '&&': {
      minWidth: 256,
      width: 256,
      transform: 'translateX(-50%)',
      left: '50% !important',
      [breakpoints.up('md')]: {
        width: 422,
      },
      '& > div > div': {
        marginTop: 0,
        marginBottom: 16,
      },
    },
  },
}));
