import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '../../../../common/button';
import { useStyles } from './points-description-card.styles';
import { InternalLinks } from '../../../../../constants';

export const PointsDescriptionCard = ({ isFitted }: { isFitted: boolean }): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <div
        className={classes.root}
        data-testid="points-description-card"
      >
        <h2
          className={classes.title}
          data-testid={isFitted ? 'fitted-card-title' : 'not-fitted-card-title'}
        >
          <FormattedMessage
            id={isFitted ? 'onlineShops.card.fitted.title' : 'onlineShops.card.notFitted.title'}
          />
        </h2>
        <p className={classes.description}>
          {intl.formatMessage(
            { id: isFitted ? 'onlineShops.card.fitted.description' : 'onlineShops.card.notFitted.description' },
            { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
          )}
        </p>
        {!isFitted && (
          <div className={classes.button}>
            <Button variant="outlined" to={InternalLinks.ecpLocator}>
              <FormattedMessage
                id="onlineShops.card.notFitted.button.label"
              />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
