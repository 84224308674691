import { MouseEventHandler, SyntheticEvent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { OrderRepeatButton as OrderRepeatButtonView } from './order-repeat-button';
import { IOrderRepeatProps } from './order-repeat-button.models';
import {
  setDeliveryStore,
  setIsRepeatOrder,
  setRepeatOrderProducts,
} from '../../../../../../graphql/order/order.cache';
import { InternalLinks } from '../../../../../../constants';
import { getOrderStore } from '../../../../../../utils/order';

export const OrderRepeatButton = ({
  orderNumber, orderProducts, storeData, rating,
}: IOrderRepeatProps): JSX.Element => {
  const navigate = useNavigate();

  const store = useMemo(() => getOrderStore(storeData!, rating!), [storeData, rating]);

  const handleRepeatClick = (e: MouseEventHandler<HTMLButtonElement> | SyntheticEvent<Element, Event>) => {
    // Stop propagation of Order Card click
    (e as SyntheticEvent<Element, Event>).stopPropagation();
    // Prevent immediate redirect
    (e as SyntheticEvent<Element, Event>).preventDefault();
    setIsRepeatOrder(true);
    setRepeatOrderProducts(orderProducts!);
    setDeliveryStore(store);
    navigate(InternalLinks.orderCreate, { state: { hasPreviousLocation: true } });
  };

  return (
    <OrderRepeatButtonView
      orderNumber={orderNumber}
      onClick={handleRepeatClick}
    />
  );
};
