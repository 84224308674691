export interface IErrorPlainProps {
  content: string;
  variant?: string;
  externalClass?: string;
  isolated?: boolean;
}

export enum ErrorTypes {
  plainText = 'plainText',
  error = 'error',
}
