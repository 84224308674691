import { useEffect, useState } from 'react';

import { IProductQuantityContainerProps } from './product-quantity.models';
import { ProductQuantity as ProductQuantityView } from './product-quantity';
import {
  LENSES_AMOUNT_MAX,
  LENSES_AMOUNT_MIN,
} from '../../../../../../../constants/validation/lenses-amount';

export const ProductQuantity = ({
  brand, onChangeProductQuantity, onDecreasePackageValue, onIncreasePackageValue,
  productQuantity,
}: IProductQuantityContainerProps): JSX.Element => {
  const [isAddActive, setIsAddActive] = useState<boolean>(true);
  const [isRemoveActive, setIsRemoveActive] = useState<boolean>(false);

  const handleAddLenses = () => {
    const newAmount = productQuantity + 1;

    onChangeProductQuantity(newAmount);

    if (!isRemoveActive) {
      setIsRemoveActive(true);
    }

    if (newAmount === Number(LENSES_AMOUNT_MAX)) {
      setIsAddActive(false);
    }

    onIncreasePackageValue();
  };

  const handleRemoveLenses = () => {
    const newAmount = productQuantity - 1;
    onChangeProductQuantity(newAmount);

    if (newAmount < Number(LENSES_AMOUNT_MIN) || newAmount === Number(LENSES_AMOUNT_MIN)) {
      setIsRemoveActive(false);
    }

    if (!isAddActive) {
      setIsAddActive(true);
    }

    onDecreasePackageValue();
  };

  useEffect(() => {
    if (productQuantity > Number(LENSES_AMOUNT_MIN)) {
      setIsRemoveActive(true);
    }

    if (productQuantity === Number(LENSES_AMOUNT_MAX)) {
      setIsAddActive(false);
    }
  }, []);

  return (
    <ProductQuantityView
      onRemoveLenses={handleRemoveLenses}
      onAddLenses={handleAddLenses}
      brand={brand}
      isAddActive={isAddActive}
      isRemoveActive={isRemoveActive}
      productQuantity={productQuantity}
    />
  );
};
