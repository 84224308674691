import {
  forwardRef,
  useImperativeHandle,
} from 'react';

import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../../common/stepper';
import {
  IExternalState,
  IOrderStore,
  IStepsState,
} from '../../order-create-page.models';
import { EcpStep as EcpStepView } from './ecp-step';

export const EcpStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  stepsState,
  onGoNextSuccess,
  externalState,
  onGoBack,
  onGoLast,
  onGoLastSuccess,
}, ref): JSX.Element => {
  const { store: ecpStepState } = stepsState as IStepsState;
  const state = (externalState as IExternalState);

  useImperativeHandle(ref, () => ({
    goNext() {},
    goBack() {},
    goLast() {},
  }));

  const handleEcpSubmit = (store: IOrderStore) => {
    (stepsState as IStepsState).isChange = false;
    onGoNextSuccess(store);
    state.store = store;
  };

  const handleGoLastSuccess = () => {
    onGoLastSuccess(state.store);
  };

  return (
    <EcpStepView
      onStoreSubmit={handleEcpSubmit}
      ecpStepState={ecpStepState}
      externalState={state}
      stepsState={stepsState as IStepsState}
      onGoBack={onGoBack}
      onGoLast={onGoLast}
      onGoLastSuccess={handleGoLastSuccess}
    />
  );
});
