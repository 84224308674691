import { LeavePrompt } from '../../../../../common/leave-prompt';
import { useStyles } from './leave-modal.styles';
import { getLeaveModalProps } from '../utils/get-leave-modal-props';

export const LeaveModal = (): JSX.Element => {
  const classes = useStyles();

  const leaveModalProps = getLeaveModalProps(
    classes.iconWrapper,
    classes.modalContent,
    classes.modalTitle,
  );

  return (
    <LeavePrompt
      confirmationModalProps={leaveModalProps}
    />
  );
};
