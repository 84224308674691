import React, { useMemo, FC } from 'react';
import { useQuery } from '@apollo/client';

import { GET_IS_USER_LOGGED_IN } from '../../../graphql/user/queries';
import { loggedUserWrapper } from './logged-user-wrapper';
import { IAppointmentSettingsProps } from './appointment-settings.models';

export function appointmentSettings<T = {}>(Component: FC<T & IAppointmentSettingsProps>) {
  return (props: T) => {
    const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);
    const WrappedComponent = useMemo(() => isLoggedIn
      ? loggedUserWrapper(Component)
      : Component,
    [isLoggedIn]);

    return (
      <WrappedComponent
        {...props}
        isLoggedIn={isLoggedIn}
      />
    );
  };
}
