import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import girlBg from '../../../../../assets/images/reg-guide/girl-benefits.png';
import girlBgLg from '../../../../../assets/images/reg-guide/girl-benefits-lg.png';

export const useStyles = makeStyles(({
  fontSizes, fontWeights, palette, lineHeights, breakpoints,
}: Theme) => ({
  root: {
    width: '100%',
    background: 'linear-gradient(245.79deg, rgba(0, 163, 224, 0.8) 8.65%, rgba(10, 124, 193, 0.8) 90.3%)',
    [breakpoints.up('md')]: {
      maxWidth: 1344,
      margin: '0 auto',
    },
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 16px 32px',
    backgroundImage: `url(${girlBg})`,
    backgroundSize: 'auto 360px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '100%',
    [breakpoints.up('md')]: {
      padding: '36px 7% 30px',
      backgroundImage: `url(${girlBgLg})`,
      backgroundPositionX: '-5%',
      backgroundSize: 'auto 430px',
    },
  },
  title: {
    marginTop: 0,
    marginBottom: 32,
    color: palette.common.white,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      marginBottom: 48,
      fontSize: 36,
    },
    [breakpoints.up('xl')]: {
      transform: 'translateX(35%)',
    },
  },
  list: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    gap: 16,
    padding: 0,
    margin: 0,
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      maxWidth: '70%',
      gap: 20,
    },
    [breakpoints.up('xl')]: {
      gap: 80,
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    height: 64,
    width: 230,
    padding: 12,
    border: `2px solid ${palette.common.white}`,
    borderRadius: 12,
    background: `linear-gradient(180deg, ${palette.common.white} 0%, #b6eafe 100%)`,
    color: '#a51890',
    fontSize: fontSizes.xs,
    lineHeight: 1.4,
    fontWeight: fontWeights.medium,
    [breakpoints.up('md')]: {
      height: 116,
      width: 364,
      gap: 24,
      padding: '18px 25px',
      borderRadius: 30,
      fontSize: fontSizes.lg,
    },
    '&:nth-of-type(2)': {
      paddingRight: 30,
      [breakpoints.up('md')]: {
        paddingRight: 40,
      },
    },
  },
}));
