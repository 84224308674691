import { useEffect, useState } from 'react';

import { IVisionProfileData } from '../graphql/profile/models/get-vision-profile.models';
import { IStepProps } from '../components/common/stepper';
import { visionProfileSurveySteps } from '../components/business/app/vision-profile/vision-profile-survey/vision-profile.steps';
import { getStepStatus } from '../utils/vision-profile/get-step-status';
import { getVPStateObject } from '../utils/vision-profile/get-VP-state-object';

export enum VPStatus {
  UNDEFINED = 'undefined',
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
}

export interface IVisionProfileDataParams {
  status: VPStatus;
  surveySteps: IStepProps[];
}

export const useVisionProfileData = (data?: IVisionProfileData): IVisionProfileDataParams => {
  const [vpData, setVPData] = useState<IVisionProfileDataParams>({
    status: VPStatus.UNDEFINED,
    surveySteps: [],
  });

  useEffect(() => {
    if (data) {
      const surveyState = getVPStateObject(data.visionProfile);

      const surveySteps = visionProfileSurveySteps
        .reduce((stepsArr, step) => {
          const isIncompleteStep = getStepStatus(surveyState, step.id);

          return isIncompleteStep ? [...stepsArr, step] : stepsArr;
        }, [] as IStepProps[]);

      const status = surveySteps.length ? VPStatus.INCOMPLETE : VPStatus.COMPLETE;

      setVPData({
        status,
        surveySteps,
      });
    }
  }, [data]);

  return vpData;
};
